import { Injectable } from '@angular/core';
import { PostModel } from './post-model';

export class PostsModel {
  public SessionGuid: string;
  public SelectedNetworks: Array<number>;
  public SelectedNetworkGroups: Array<number>;
  public Posts: Array<PostModel>;

  constructor(init: any) {
    this.SessionGuid = init.SessionGuid;
    this.SelectedNetworks = init.SelectedNetworks;
    this.SelectedNetworkGroups = init.SelectedNetworkGroups;
    this.Posts = init.Posts;
  }
}

@Injectable({
  providedIn: 'root'
})

export class PostsModelAdapter {
  adapt(item: any): PostsModel {
    return new PostsModel(item);
  }
}
