import { BaseListModel } from './base-list-model';
import { ListFieldsModel } from './list-fields-model';

export class ListModel extends BaseListModel {
  public ListFields: Array<ListFieldsModel>;

  constructor(init: any) {
    super();
    this.ListFields = init.ListFields;
    this.ListID = init.ListID;
    this.FolderID = init.FolderID;
    this.FolderName = init.FolderName;
    this.ListName = init.ListName;
    this.Comment = init.Comment;
    this.CreatedByID = init.CreatedByID;
    this.UpdatedByID = init.UpdatedByID;
    this.TableName = init.TableName;
    this.IsDeleted = init.IsDeleted;
    this.Folder = init.Folder;
    this.ClientID = init.ClientID;
    this.ListType = init.ListType;
  }
}
