import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebFormPageViewModel } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-page-view-model';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  private _baseUrl = environment.apiURL;

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  getListWebForms(listID: number): Observable <ExecutionResultDto> {
    return this.http
    .get(this._baseUrl + 'WebForm/GetFormsWithURL?listId=' + listID)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getFormTypes(): Observable <ExecutionResultDto> {
    return this.http
    .get(this._baseUrl + 'WebForm/GetFormTypes')
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  editFormName(listID: number, webFormID: number, webFormName: string) {

    const query = `?listID=${listID}
                   &webFormID=${webFormID}
                   &webFormName=${webFormName}`;

    return this.http
    .post(this._baseUrl + 'WebForm/EditFormName' + query, '')
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  copyForm(webFormID: number, webFormName: string) {

    const query = `?webFormID=${webFormID}
                   &webFormName=${webFormName}`;

    return this.http
    .post(this._baseUrl + 'WebForm/CopyForm' + query, '')
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  deleteForm(webFormID: number, webFormName: string) {
    const query = `?webFormID=${webFormID}
                   &webFormName=${webFormName}`;

    return this.http
    .post(this._baseUrl + 'WebForm/RemoveForm' + query, '')
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  newForm(listID: number, webFormTypeID: number, webFormName: string) {

    const query = `?listID=${listID}
                   &webFormTypeID=${webFormTypeID}
                   &webFormName=${webFormName}`;

    return this.http
    .post(this._baseUrl + 'WebForm/AddForm' + query, '')
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  updateFormContent(webPage: WebFormPageViewModel) {
    return this.http
    .post(this._baseUrl + 'WebForm/UpdatePageContent', webPage)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

}
