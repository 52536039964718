import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { PagerModelAdapter } from 'src/app/_models/system/pager-model';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { ListFolderModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model.adapter';
import { ContactsMessagePreviewModel } from 'src/app/_models/messaging/messages/email-messages/contacts-message-preview-model';
import { ContactsMessagePreviewModelAdapter } from 'src/app/_models/messaging/messages/email-messages/contacts-message-preview-model.adapter';
import { MessagePreviewModel } from 'src/app/_models/messaging/messages/email-messages/message-preview-model';
import { MessagePreviewModelAdapter } from 'src/app/_models/messaging/messages/email-messages/message-preview-model.adapter';
import { ListDtoModel } from 'src/app/_models/messaging/messages/email-messages/list-dto-model';
import { ListDtoModelAdapter } from 'src/app/_models/messaging/messages/email-messages/list-dto-model.adapter';
import { MessagePreviewDto } from 'src/app/_models/messaging/messages/email-messages/message-preview-dto';
import { MessagePreviewDtoAdapter } from 'src/app/_models/messaging/messages/email-messages/message-preview-dto.adapter';
import { environment } from 'src/environments/environment';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { PublicPreviewTokensAdapter } from 'src/app/_models/messaging/messages/email-messages/public-preview-tokens-dto.adapter';
import { PublicPreviewTokensDto } from 'src/app/_models/messaging/messages/email-messages/public-preview-tokens-dto';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { QueryBuilderComponent } from 'src/app/views/shared/query-builder/query-builder.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QueryBuilderStorageService } from 'src/app/_services/query-builder-filters/query-builder-storage.service';
import { QueryBuilderHelper } from 'src/app/views/shared/query-builder/query-builder-helper';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit {

  @ViewChild('filterModal', { static: true }) filterModal: ModalDirective;
  @ViewChild('queryBuilder', { static: true}) queryBuilder: QueryBuilderComponent;

  _listFolder: number;
  _listID: number;
  _messageID: number;
  _listFolders: Array<ListFolderModel>;
  _lists: Array<ListDtoModel>;
  _listFolderDropdown: Array<any> = [];
  _listDropdown: Array<any> = [];
  _messageDetails: MessagePreviewModel;
  _showMobileView: boolean = false;
  _contacts: Array<MessagePreviewDto> = [];
  _currentContact: MessagePreviewDto;
  _defaultContact: MessagePreviewDto;
  _pageSize: number = 10;
  _page: number = 1;
  _totalContacts: number = 0;
  _selectedListID: number = 0;
  _loading: boolean;
  _publicPreviewUrl = '';
  _publicPreviewTokens: PublicPreviewTokensDto;
  _filterRules = '';
  _pageTitle = 'Email Preview';

  _folderListSelectForm = new UntypedFormGroup({
    selectedList: new UntypedFormControl(),
    selectedFolder: new UntypedFormControl(),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private emailMessageService: EmailMessagesService,
    private contactsMessagePreviewModelAdapter: ContactsMessagePreviewModelAdapter,
    private pagerModelAdapter: PagerModelAdapter,
    private listFolderModelAdapter: ListFolderModelAdapter,
    private messagePreviewModelAdapter: MessagePreviewModelAdapter,
    private listDtoModelAdapter: ListDtoModelAdapter,
    private messagePreviewDtoAdapter: MessagePreviewDtoAdapter,
    private publicPreviewTokensAdapter: PublicPreviewTokensAdapter,
    private notificationService: NotificationService,
    private queryBuilderStorageService: QueryBuilderStorageService,
    private queryBuilderHelper: QueryBuilderHelper
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      this._messageID = params.messageID;
      this._listFolder = params.listFolder;
      this._listID = params.listID;
    });

    this.getPreviewMessage();
    this.getListFolders();
    //this.getPublicPreviewUrl();

    //Get SQL Filter from Storage
    this.queryBuilderStorageService.GetSQLFilterFromLocalStorage();

    //Used to Auto Select Folder and List based on parameter
    this._listFolder != undefined ? this._folderListSelectForm.get('selectedFolder').setValue(this._listFolder) : this._folderListSelectForm.get('selectedFolder').setValue(0);
    if (this._listID == undefined) this.getLists(0);
    else {
      this.getLists(this._listFolder);
      this._folderListSelectForm.get('selectedList').setValue(this._listID);
      this.listSelected(null, this._listID);
    }
  }

  getListFolders() {
    this.emailMessageService.getListFolders().subscribe(result => {
      this._listFolders = this.listFolderModelAdapter.adaptArray(result.data);
      this.populateListFolderDropdown();
    }, error => {
      console.log(error);
    });
  }

  getLists(folderID: number) {
    this.emailMessageService.getLists(folderID).subscribe(result => {
      this._lists = this.listDtoModelAdapter.adaptArray(result.data);
      this.populateListDropdown();
    }, error => {
      console.log(error);
    });
  }

  getPreviewMessage() {
    this._loading = true;
    this._messageDetails = this.messagePreviewModelAdapter.createEmpty();
    this.emailMessageService.getPreviewMessage(this._messageID).subscribe(result => {
      this._messageDetails = this.messagePreviewModelAdapter.adapt(result.data);
      this._publicPreviewUrl = this._messageDetails.PreviewUrl;
      this.createDefaultContact();
      this.showDefaultContact();
      this._loading = false;
    }, error => {
    this._loading = false;
    console.log(error);
    });
  }

  showDefaultContact() {
    this._currentContact = this._defaultContact;
  }

  createDefaultContact() {
    this._defaultContact = this.messagePreviewDtoAdapter.createEmpty();
    this._defaultContact.HtmlContent = this._messageDetails.HtmlContent;
    this._defaultContact.Subject = this._messageDetails.Subject;
    this._contacts.unshift(this._defaultContact);
  }

  getContactsPreviewMessages(paginatingDirection?: string) {
    this._loading = true;
    var messagePreviewData: ContactsMessagePreviewModel;
    messagePreviewData = this.contactsMessagePreviewModelAdapter.createEmpty();
    messagePreviewData.PagerModel = this.pagerModelAdapter.createEmpty();
    messagePreviewData = this.populatePreviewMessageData(messagePreviewData);

    this.emailMessageService.getContactsPreviewMessage(messagePreviewData).subscribe( result => {
      this._contacts = this.messagePreviewDtoAdapter.adaptArray(result.data.Item1);
      this._totalContacts = result.data.Item2;
      if (paginatingDirection === 'Forward') {
        const index = this._contacts.findIndex(contact => contact.ContactID === this._currentContact.ContactID);
        this._currentContact = this._contacts[index + 1];
      } else if (paginatingDirection === 'Back') {
        const end = this._contacts.length - 1;
        this._currentContact = this._contacts[end];
      }
      if (this._page === 1) {
        this.createDefaultContact();
      }
      this._loading = false;
    }, error => {
      console.log(error);
      this._loading = false;
    });
  }

  populatePreviewMessageData(messagePreviewData: ContactsMessagePreviewModel) {
    messagePreviewData.ListID = this._selectedListID;
    messagePreviewData.MessageID = this._messageID;
    messagePreviewData.PagerModel.Page = this._page;
    messagePreviewData.PagerModel.PageSize = this._pageSize;
    messagePreviewData.PagerModel.SortColumn = 'firstName';
    messagePreviewData.PagerModel.SortDirection = 'desc';
    messagePreviewData.Filter = this._filterRules;

    return messagePreviewData;
  }

  populateListFolderDropdown() {
    this._listFolderDropdown = [];
    this._listFolderDropdown.push({
      value: 0,
      label: '<All>'
    });
    this._listFolders.forEach((folder: ListFolderModel) => {
      this._listFolderDropdown = [... this._listFolderDropdown, {
        value: folder.FolderID,
        label: folder.FolderName
      }];
    });
  }

  populateListDropdown() {
    this._listDropdown = [];
    this._lists.forEach((list: ListDtoModel) => {
      this._listDropdown = [... this._listDropdown, {
        value: list.ListId,
        label: list.ListName
      }];
    });
  }

  folderSelected($event) {
    let value = +$event.target.options[$event.target.options.selectedIndex].value;
    this.getLists(value);
    if (value !== this._selectedListID) {
      this._contacts = [];
      this.showDefaultContact();
    }
  }

  //Check if List is coming from the FE or Parameter and select contacts
  listSelected($event?, listID?: number) {
    if ($event) {
      let value = +$event.target.options[$event.target.options.selectedIndex].value;
      this._selectedListID = value;
    } else this._selectedListID = listID;

    this._page = 1;
    this.showDefaultContact();

    if (this.queryBuilderStorageService.sqlFilter != null || this.queryBuilderStorageService.sqlFilter != undefined) {
      this._filterRules = this.queryBuilderHelper.removeIsNullFromSQL(this.queryBuilderStorageService.sqlFilter);
    }

    this.getContactsPreviewMessages();

    if (this._selectedListID > 0) {
      this.queryBuilder.getListFields(this._selectedListID, 'contacts');
    }
  }

  nextContactClick() {
    const index = this._contacts.findIndex(contact => contact.ContactID === this._currentContact.ContactID);
    if (this._contacts[index + 1] !== undefined ) {
      this._currentContact = this._contacts[index + 1];
    } else {
      if ((this._pageSize * this._page) < this._totalContacts) {
        this._page = this._page + 1;
        this.getContactsPreviewMessages('Forward');
      }
    }
  }

  previousContactClick() {
    if (this._currentContact.ContactID !== 0) {
      const index = this._contacts.findIndex(contact => contact.ContactID === this._currentContact.ContactID);
      if (this._contacts[index - 1] !== undefined) {
        this._currentContact = this._contacts[index - 1];
      } else if (this._contacts[index - 1] === undefined && this._page > 1) {
        this._page = this._page - 1;
        this.getContactsPreviewMessages('Back');
      }
    }
  }

  switchView(event) {
    this._showMobileView = event.target.checked;
  }

  getPublicPreviewUrl() {
    this.emailMessageService.generatePublicPreviewTokens(this._messageID).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._publicPreviewTokens = this.publicPreviewTokensAdapter.adapt(executionResultDto.data);
        const clientId = this._publicPreviewTokens.clientIdToken;
        const messageId = this._publicPreviewTokens.messageIdToken;
        this._publicPreviewUrl = `${environment.apiURL}public/messages/checkemailonline/${clientId}/${messageId}`;
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  copyLink(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this._publicPreviewUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showSuccess('Link copied to clipboard');
  }

  filterSave(event) {
    if (!event[1]) {
      this._filterRules = '';
    } else {
      this._filterRules = event[1].sql;
    }
    this.getContactsPreviewMessages();
    this.filterModal.hide();
  }
}
