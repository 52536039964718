export class MessageCodeDto {

    folderId: number;
    messageCodeId: number;
    title: boolean;

    constructor(init: any) {
        this.folderId = init.FolderID;
        this.messageCodeId = init.MessageCodeID;
        this.title = init.Title;
    }
}