export class ContactPlanDto {

    id: number;
    folderId: number;
    title: boolean;

    constructor(init: any) {
        
        this.id = init.ContactPlanID;
        this.folderId = init.FolderID;
        this.title = init.Title;
    }
}