export class AllFoldersAllListsAllFieldsModel  {

    public folderId: number;
    public listId: number;
    public folderName: string;
    public listName: string;
    public displayName: string;
    public wildcard: string; 
    public isWildcard: boolean 
            
    constructor(init: any) {
        this.folderId = init.FolderID;
        this.listId = init.ListID;        
        this.folderName = init.FolderName;
        this.listName = init.ListName;        
        this.displayName = init.DisplayName;
        this.wildcard = init.Wildcard;
        this.isWildcard = init.Wildcard
    }
}


