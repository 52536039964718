import { Component, OnInit } from '@angular/core';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { onPageSlide } from '../../../../animations/route-animations';

@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss'],
  animations: [
    onPageSlide,
  ]
})

export class ClientSettingsComponent implements OnInit {

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  client: ClientDto;
  _clientId: number;

  constructor(
    private route: ActivatedRoute,
    public clientManagementService: ClientManagementService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
     this._clientId = +params['clientId']; // FFR (+) converts string to a number
    });

    this.clientManagementService.getClient(this._clientId).subscribe(data => {
      this.client = data;

      this.sendClient(this.client);
    });
  }

  sendClient(client) {
    this.clientManagementService.setCurrentClient(client);
  }
}
