export class HistoryViewDto {

    fileId: string;
    listId: number;
    folderId: number;
    listHistoryUploadId: number;
    uploadedDate: Date;
    uploadType: string;
    listName: string;
    folderName: string;
    fileName: string;
    contentType: string;
    updatedContacts: number;
    processedRecords: number;
    createdContacts: number;
    failedRecords: number;
    uploadedBy: string;
    errorsFileName: string;
    errorsContentType: string;
    errorsFileId: string;

constructor(init) {

        this.listId = init.ListID;
        this.folderId = init.FolderID;
        this.listHistoryUploadId = init.ListHistoryUploadID;
        this.uploadedDate = init.UploadedDate;
        this.uploadType = init.UploadType;
        this.listName = init.ListName;
        this.folderName = init.FolderName;
        this.fileName = init.FileName;
        this.contentType = init.ContentType;
        this.fileId = init.FileId;
        this.updatedContacts = init.UpdatedContacts;
        this.processedRecords = init.ProcessedRecords;
        this.createdContacts = init.CreatedContacts;
        this.failedRecords = init.FailedRecords;
        this.uploadedBy = init.UploadedBy;
        this.errorsFileName = init.ErrorsFileName;
        this.errorsContentType = init.ErrorsContentType;
        this.errorsFileId = init.ErrorsFileId;

    }
}
