export class SelectedFieldsFiltersDto {
    selectedFieldsFiltersId: number;
    filterName: string;
    dataType: number;
    selectedFields: string[];

    constructor(init: any){
        this.selectedFieldsFiltersId = init.SelectedFieldsFiltersId;
        this.filterName = init.FilterName;
        this.dataType = init.DataType;
        this.selectedFields = init.SelectedFields;
    }
}