<div class="contact-interactions-page">
  <div class="contact-interactions-table-section">
    <div class="d-flex align-items-center">
      <div class="mr-auto">
        <span class="title-table-section">Contact Interactions</span>
      </div>
      <!-- Table Header -->
      <button *ngIf="this._filterInteractionRules.length === 0" mdbBtn
        size="lg" type="button" class="header-button table-button no-box-shadow mr-4"
        (click)="filterInteractionsModal.show()">
        <mdb-icon fas size="lg" class="blue-icon" icon="search"></mdb-icon>
      </button>
      <button *ngIf="this._filterInteractionRules.length > 0" mdbBtn
        size="lg" type="button" mdbTooltip="An active filter is set, click here to clear or edit it."
        placement="top" class="header-button table-button no-box-shadow mr-4"
        (click)="filterInteractionsModal.show()">
        <mdb-icon fas size="lg" class="orange-icon" icon="search"></mdb-icon>
      </button>
      <button *ngIf="!_exporting" [disabled]="!_tableHasRows" mdbBtn
        size="lg" type="button" class="header-button table-button no-box-shadow mr-2" (click)="export()">
        <mdb-icon fas size="lg" class="blue-icon" icon="file-download"></mdb-icon>
      </button>
      <button *ngIf="_exporting" disabled mdbBtn
        class="header-button table-button no-box-shadow mr-2">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
    <hr class="hr-reports">
    <!-- Table -->
    <div *ngIf="_tableHasRows" class="mat-table-container">
      <ngx-datatable
              class="contact-interactions-table"
              [columnMode]="_columnMode.force"
              [headerHeight]="40"
              [footerHeight]="0"
              [rowHeight]="50"
              [scrollbarV]="false"
              [scrollbarH]="true"
              [rows]="_rows"
              [count]="_pageCount"
              [offset]="_pageOffset"
              [limit]="_pageLimit"
              (sort)="onSort($event)"
              (activate)="onActivate($event)">

              <ngx-datatable-column
              *ngFor="let col of _columns"
              [width]="150"
              [headerClass]="'lists-table-headers'"
              [name]="col.name"
              [prop]="col.prop">
              </ngx-datatable-column>

                  <ngx-datatable-column name="" [width]="80" [maxWidth]="80"
                    [cellClass]="'table-right-cells'" [headerClass]="'lists-table-headers'" [frozenRight]="'true'"
                    prop="name">
                    <ng-template let-row="data" let-value="value" ngx-datatable-cell-template>
                      <div class="row button-section rounded mt-1 mr-1 ml-1 justify-content-center text-center">
                        <div class="col-md-4 icon-padding p-0 mt-2 mb-1">
                          <a placement="left">
                            <i class="fas fa-arrow-alt-circle-right contact-interactions-icon mb-1 mt-0"></i>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
                <mat-paginator [hidden]="!_tableHasRows"
                               [length]="_pageCount"
                               [pageSize]="_pageLimit"
                               [pageSizeOptions]="_pageSizeOptions"
                               [pageIndex]="_pageOffset"
                               (page)="paginateTable($event)"
                               class="mat-paginator">
                </mat-paginator>
          </div>
          <div *ngIf="!_tableHasRows">
            <span class="label-no-data">No data to display.</span>
          </div>
      </div>
</div>

<div mdbModal #filterInteractionsModal class="modal fade modal-overflow" tabindex="-1" role="dialog"
  aria-labelledby="filterInteractionsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="filterInteractionsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="filterInteractionsModalLabel">Filter Interactions</h4>
      </div>

      <div class="modal-body">
        <app-query-builder #queryBuilder [(_parentQuery)]="this._filterInteractionRules"
          [_parentSource]="'interactions'" [(_parentListID)]="this._selectedListId"
          (queryBuilderSave)="interactionsFilterSave($event)"
          [(_parentPageTitle)]="this._pageTitle">
        </app-query-builder>
      </div>
    </div>
  </div>
</div>
