import { Component, OnInit, ViewChild } from '@angular/core';
import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';
import { NetworkGroupService } from 'src/app/_services/social-media/network-groups/network-group.service';
import { NetworkGroupAdapter } from 'src/app/_models/social-media/network-groups/network-group-adapter';
import { NetworkAdapter } from 'src/app/_models/social-media/networks/network-adapter';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { NetworkGroupDto } from 'src/app/_models/social-media/network-groups/network-group-dto';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AddNetworkGroupDtoRequest } from 'src/app/_models/social-media/network-groups/add-network-group-dto-request';
import { Masonry } from 'ng-masonry-grid';
import { NetworkService } from 'src/app/_services/social-media/networks/network.service';
import { NetworkTypeEnum } from 'src/app/_models/social-media/posts/network-type-enum';
import { IconPath } from '../../constants/social-media-constants';

@Component({
  selector: 'app-network-group-management',
  templateUrl: './network-group-management.component.html',
  styleUrls: ['./network-group-management.component.scss']
})

export class NetworkGroupManagementComponent implements OnInit {
  addNetworkGroupDtoRequest: AddNetworkGroupDtoRequest;
  filteredNetworkGroups: NetworkGroupDto[] = [];
  modalTitleAction = '';
  networkGroupDtos: NetworkGroupDto[];
  networkGroupIdForDeletion = 0;
  networks: NetworkDto[] = [];
  account: NetworkDto[] = [];
  networksMultiSelectListItems: any[] = [];
  networkGroupsForm: UntypedFormGroup;
  text = '';
  selectedNetworkDetailIds: number[] = [];
  submitting = false;
  userPermissions: PermissionsModel;
  masonry: Masonry;

  @ViewChild('networkGroupModal') networkGroupModal: ModalDirective;
  @ViewChild('confirmDeleteNetworkGroupModal', { static: true }) confirmDeleteNetworkGroupModal: ModalDirective;

  get searchText(): string {
    return this.text;
  }

  set searchText(value: string) {
    this.text = value;
    this.filteredNetworkGroups = this.filter(value);
    setTimeout(() => {
      this.masonry.removeAllItems();
      this.masonry.reloadItems();
      this.masonry.layout();
    }, 1);
  }

  constructor(
    private networkService: NetworkService,
    private networkGroupService: NetworkGroupService,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
    private networkGroupAdapter: NetworkGroupAdapter,
    private networkAdapter: NetworkAdapter,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.initModalForm();
    this.getNetworks();
    this.getNetworkGroups();
    this.getPermissions();
    this.subscribeToNetworksMultiSelectList();
  }

  clearNetworkGroupsForm(): void {
    this.modalTitleAction = 'Create';
    this.networkGroupsForm.get('name').setValue('');
    this.networkGroupsForm.get('networkGroupId').setValue(0);
    this.networkGroupsForm.get('networks').setValue([]);
  }

  createEditNetworkGroup(): void {
    if (this.networkGroupsForm.valid) {
      this.submitting = true;
      this.addNetworkGroupDtoRequest = new AddNetworkGroupDtoRequest(
        this.networkGroupsForm.value.networkGroupId,
        this.networkGroupsForm.value.name,
        this.selectedNetworkDetailIds
      );

      this.networkGroupService.post(this.addNetworkGroupDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          const networkGroupDto = this.networkGroupAdapter.adaptSingle(executionResultDto.data);
          this.networkGroupDtos = this.networkGroupDtos.filter(x => x.id !== this.networkGroupsForm.value.networkGroupId);
          this.networkGroupDtos.push(networkGroupDto);
          this.filteredNetworkGroups = this.networkGroupDtos;
          this.searchText = this.text;
          this.networkGroupModal.hide();
          this.notificationService.showSuccess(executionResultDto.message);
        } else if (executionResultDto.executionResult === ExecutionResult.warning) {
          this.notificationService.showWarning(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
        this.submitting = false;
      });
    }
  }

  deleteNetworkGroup(): void {
    this.networkGroupService.delete(this.networkGroupIdForDeletion).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.confirmDeleteNetworkGroupModal.hide();
        this.networkGroupDtos = this.networkGroupDtos.filter(x => x.id !== this.networkGroupIdForDeletion);
        this.filteredNetworkGroups = this.networkGroupDtos;
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  initModalForm(): void {
    this.networkGroupsForm = this.fb.group({
      name: ['', Validators.required],
      networkGroupId: [0],
      networks: new UntypedFormControl('', [Validators.required])
    });
  }

  filter(searchText: string): NetworkGroupDto[] {
    return this.networkGroupDtos.filter(networkGroup => {
      return networkGroup.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });
  }

  getPermissions(): void {
    this.userPermissions = this.permissionService.getPermissionsModel();
    this.permissionService.permissionsModel.subscribe((permissions: PermissionsModel) => {
      this.userPermissions = permissions;
    });
  }

  getNetworks(): void {
    this.networkService.get().subscribe((result: ExecutionResultDto) => {  
      result.data.Networks.forEach((networks: NetworkDto) => {
        this.account = [...this.account, this.networkAdapter.adapt(networks)];
      });

      this.account.forEach((account: NetworkDto) => {
        this.networks = [...this.networks, account];
        account.subNetworks.forEach((network: NetworkDto) => {
            this.networks = [...this.networks, network];
          });
      });

      this.networks.forEach((network) => {
        if (this.userPermissions.Social.NetworkGroups.Create) {
          this.networksMultiSelectListItems = [...this.networksMultiSelectListItems, {
            value: network.networkDetailId,
            label: network.name,
            type: network.type,
            icon: IconPath[network.type],
          }];
        }
      });

      this.networksMultiSelectListItems.sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }

        return 0;
      });
    });
  }

  getNetworkGroups(): void {
    this.networkGroupService.get().subscribe((executionResultDto: ExecutionResultDto) => {
      this.networkGroupDtos = this.networkGroupAdapter.adapt(executionResultDto.data);
      this.filteredNetworkGroups = this.networkGroupDtos;
    });
  }

  populateNetworkGroupsModalForm(networkGroupId: number): void {
    this.networkGroupsForm.reset();
    const networkGroupDto = this.filteredNetworkGroups.find(networkGroup => networkGroup.id === networkGroupId);
    if (networkGroupDto) {
      this.modalTitleAction = 'Edit';
      this.networkGroupsForm.get('name').setValue(networkGroupDto.name);
      this.networkGroupsForm.get('networkGroupId').setValue(networkGroupId);
      this.selectedNetworkDetailIds = networkGroupDto.networks.map(x => x.networkDetailId);

      this.networkGroupsForm.get('networks').setValue(this.selectedNetworkDetailIds);
    }
  }

  showConfirmDeleteNetworkGroupModal(networkGroupId: number): void {
    this.networkGroupIdForDeletion = networkGroupId;
    this.confirmDeleteNetworkGroupModal.show();
  }

  showModalForm(networkGroupId?: number): void {
    this.networkGroupsForm.reset();
    this.clearNetworkGroupsForm();
    if (networkGroupId) {
      this.populateNetworkGroupsModalForm(networkGroupId);
    }

    this.networkGroupModal.show();
  }

  subscribeToNetworksMultiSelectList(): void {
    this.networkGroupsForm.get('networks').valueChanges.subscribe((value) => {
      this.selectedNetworkDetailIds = value;
    });
  }
}

