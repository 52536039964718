import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent, Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
  })
  export class LastActiveService {
    private localStorageKey: string = '__lastActive';
    private events: string[] = ['keydown', 'click', 'wheel', 'mousemove'];
  
    private lastActive: BehaviorSubject<Date>;
    public lastActive$: Observable<Date>;
  
    constructor() {
      const lastActiveDate = this.getLastActiveFromLocalStorage() ?? new Date();
      this.lastActive = new BehaviorSubject<Date>(lastActiveDate);
      this.lastActive$ = this.lastActive.asObservable();
    }
  
    public setUp() {
      this.events.forEach(event =>
        fromEvent(document, event).subscribe(_ => this.recordLastActiveDate())
      );
    }
    
    private recordLastActiveDate() {
      var currentDate = new Date(); 
      localStorage.setItem(this.localStorageKey, currentDate.toString());
      this.lastActive.next(currentDate);
    }
  
    private getLastActiveFromLocalStorage(): Date | null {
      const valueFromStorage = localStorage.getItem(this.localStorageKey);
      if (!valueFromStorage) {
        return null;
      }
  
      return new Date(valueFromStorage);
    }
  }