import { Injectable } from '@angular/core';
import { FacebookInitializationVariablesDto } from './facebook-initialization-variables-dto';

@Injectable({
  providedIn: 'root'
})

export class FacebookInitializationVariablesAdapter {
  public adapt(item: any) {
    return new FacebookInitializationVariablesDto(item);
  }
}
