import { MessageTypeEnum } from './message-type-enum';

export class MessageCopyMoveDetailsDto {
  public MessageId: number;
  public MessageName: string;
  public MessageType: MessageTypeEnum;


  constructor(init: any) {
    this.MessageId = init.MessageId;
    this.MessageName = init.MessageName;
    this.MessageType = init.MessageType;
  }
}
