<label *ngIf="visibleLabel" class="input-label">{{ label }}</label>
<select #select class="mb-2 form-control select-input"
        [ngClass]="{ 'disabled': disabled }"
        [formControl]="selector">
  <option *ngIf="showPlaceholder" disabled selected hidden [ngValue]="0">{{ placeholder }}</option>
  <ng-container *ngFor="let option of options; let i = index">
    <option [ngValue]="option.value">
      {{option.label}}
    </option>
  </ng-container>
</select>

