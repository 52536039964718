export class MessageReportLinkClickModel {
  public LinkId: number;
  public ReportId: number;
  public URL: string;
  public LinkTitle: string;
  public NonUniqueClicks: number;
  public UniqueClicks: number;
  public TotalNonUniqueClicksOnAllLinks: number;
  public MaxNonUniqueClicks: number;

  constructor(init: any) {
    this.LinkId = init.LinkId;
    this.ReportId = init.ReportId;
    this.URL = init.URL;
    this.LinkTitle = init.LinkTitle;
    this.NonUniqueClicks = init.NonUniqueClicks;
    this.UniqueClicks = init.UniqueClicks;
    this.TotalNonUniqueClicksOnAllLinks = init.TotalNonUniqueClicksOnAllLinks;
    this.MaxNonUniqueClicks = init.MaxNonUniqueClicks;
  }
}
