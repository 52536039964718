import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { AppComponent } from 'src/app/app.component';
import { NavigationService } from 'src/app/_services/system/Navigations/navigation.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';

@Component({
  selector: 'app-two-factor-input',
  templateUrl: './two-factor-input.component.html',
  styleUrls: ['./two-factor-input.component.scss']
})
export class TwoFactorInputComponent implements OnInit {
  _codeCheckResult: string;
  _loggingIn: boolean;
  _codeCheckForm: UntypedFormGroup;
  _removeAuthenticatorResult: string;
  _localSignUpResult: string;
  _awsSignUpResult: string;
  _inactiveUser: any = {};

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private appComponent: AppComponent,
    private navService: NavigationService,
    private permissionsService: PermissionsService) { }

  ngOnInit() {
    this.createCodeCheckForm();
    if (!this.authService.cognitoUserSession) {
      this.notificationService.showError('Cognito session expired - please sign in again.');
      this.router.navigate(['/dashboard']);
    }
  }

  createCodeCheckForm() {
    this._codeCheckForm = this.fb.group({
      tfaCode: ['', Validators.required]
    });
  }

  // check the 2FA code entered by the user
  async check2FACode(userCode: string) {
    try {
      this._loggingIn = true;
      await this.authService.awsTwoFactorAuthentication(this.authService.cognitoUserSession, userCode).then(submit => this._codeCheckResult = submit);
      switch (this._codeCheckResult) {
        // success - log them in
        case "true":
          {
            this.authService.login(this.authService.localUserModel, true).subscribe(response => {
              this.permissionsService.getPermission().subscribe(() => {
                this.appComponent.reset();
              });
              this.navService.updateUserClients();
            }, async error => {
              // Check if the user can log in - if not, they are not registered locally and need to be set up
              // If the user is not registered locally, set them up using info from their Cognito user
              if (error.error.Message === "Invalid username or password") {
                await this.authService.addUserToLocalDB();
                this.notificationService.showInfo("User does not exist in Voicebox - registering user now...");
                this.notificationService.showSuccess("User added to Voicebox!");
                this.notificationService.showError("User must be activated by an admin before continuing.");
                this.logoutLocal();
                this.authService.awsLogOut();
              }
              else if (error.error.Message.indexOf("is not active") !== -1) {
                // grab the required information from the data in the error
                this.logoutLocal();
                this.authService.awsLogOut();
              }
              this._loggingIn = false;
            }, async () => {
              this.router.navigate(['/dashboard']);
              this._loggingIn = false;
            });
            break;
          }
        // Incorrect code entered by the user
        case "Invalid code received for user":
          {
            this.notificationService.showError('Incorrect code entered - please try again!');
            this._loggingIn = false;
            break;
          }
        default:
          {
            this.notificationService.showError('Code check failed: ' + this._codeCheckResult);
            this._loggingIn = false;
            break;
          }
      }
    } catch (error) {
      console.log(error);
      this.notificationService.showError('Sign in failed: ' + error.message);
      this._loggingIn = false;
    }
  }

  // log out of the local DB
  logoutLocal() {
    localStorage.clear();
    this.appComponent.stopIdle();
    this.permissionsService.setPermissionsModel = null;
    this.router.navigate(['/login']);
  }
}
