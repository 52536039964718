// constant to handle the row limit options offered to each table with these options enabled
const ConstTableRowLimitOptions = [
  {value: 10},
  {value: 25},
  {value: 50},
  {value: 100}
];

const SocialMediaErrorMessages = {
  TooManyVideoFilesError: 'Too many videos selected, only one video per post is allowed.',
  TooManyGifsError: 'Too many GIFs selected, some networks only allow one per post.',
  RequiresPhotosVideosForInstagramError: 'Instagram requires at least one Video or Photo to be attached.',
  TooManyPhotosVideosForTwitterError: 'Too many Photos or Videos selected, Twitter only allows four per post.',
  TooManyPhotosVideosForInstagramError: 'Too many Photos or Videos selected, Instagram only allows one per post.',
  InstagramCanOnlyAcceptTenSelectedMediaWhenPostingToCarouselsError: 'Instagram can only accept 10 selected media (image and/or video) when posting to carousels.',
  TooManyPhotosVideosForFacebookError: 'Too many Photos or Videos selected, Facebook only allows six per post.',
  TooManyPhotosVideosForLinkedInError: 'Too many Photos or Videos selected, LinkedIn only allows nine per post.',
  FileTypeError: 'One of the file selected is not of an acceptable type. Allowed files types are .jpg, .jpeg, .png, .gif, .mov, .mp4.',
  TwitterFileTypeError: 'One of the file selected is not of an acceptable type. Allowed files types are .jpg, .jpeg, .png, .gif, .mp4.',
  LinkedInFileTypeError: 'One of the file selected is not of an acceptable type. Allowed files types are .jpg, .jpeg, .png, .mov, .mp4.',
  TooManyFileFormatsError: 'Only one video, animated gif or collection of photos can be attached to this post.',
  FacebookImageFileSizeError: 'One of the image files selected exceeds the 45MB file limit for Facebook.',
  FacebookVideoFileSizeError: 'One of the video files selected exceeds the 10GB file limit for Facebook.',
  TwitterImageFileSizeError: 'One of the image files selected exceeds the 5MB file limit for Twitter.',
  TwitterVideoFileSizeError: 'One of the video files selected exceeds the 1GB file limit for Twitter.',
  InstagramImageFileSizeError: 'One of the image files selected exceeds the 4GB file limit for Instagram.',
  InstagramVideoFileSizeError: 'One of the video files selected exceeds the 30GB file limit for Instagram.',
  LinkedInImageFileSizeError: 'One of the image files selected exceeds the 8MB file limit for LinkedIn.',
  LinkedInVideoFileSizeError: 'One of the video files selected exceeds the 200MB file limit for LinkedIn.',
  FacebookVideoLengthError: 'One of the video files selected exceeds the 240 seconds video limit for Facebook.',
  TwitterVideoLengthError: 'One of the video files selected exceeds the 140 seconds video limit for Twitter.',
  InstagramVideoLengthError: 'One of the video files selected exceeds the 60 seconds video limit for Instagram.',
  LinkedInVideoLengthError: 'One of the video files selected exceeds the 600 seconds video limit for LinkedIn.',
  InstagramMediaPortraitAspectRatioError: 'Your selected Image or Video is portrait, therefore it must have an aspect ratio of 4:5.',
  InstagramMediaLandScapeAspectRatioError: 'Your selected Image or Video is landscape, therefore it must have an aspect ratio of 1.91:1.',
  InstagramMediaSquareAspectRatioError: 'Your selected Image or Video is a square, therefore it must have an aspect ratio of 1:1.',
  FacebookTooManyFileFormatsError: 'Only one video, animated gif or collection of photos can be attached to Facebook posts.',
  TwitterTooManyFileFormatsError: 'Only one video, animated gif or four photos can be attached to Twitter posts.',
  FacebookTooManyChars: 'You can only fit 63206 characters in this post',
  TwitterTooManyChars: 'You can only fit 279 characters in this post',
  InstagramTooManyChars: 'You can only fit 2199 characters in this post',
  LinkedInTooManyChars: 'You can only fit 2999 characters in this post'
};

const ReportingErrorMessages = {
  NoDataAvailableError: 'There is no data available for the network and dates selected'
};

export default {
  ConstTableRowLimitOptions,
  SocialMediaErrorMessages,
  ReportingErrorMessages
};

export const Selects = {
  selectGroup: 'Select Network Group',
  selectNetworks: 'Select Networks',
  postStatus: 'Post Status',
  selectPost: 'Select a Post Status',
  dateFrom: 'Select Date From',
  dateTo: 'Select Date To',
  emptyDate: '----/--/-- --:--',
};

export const MailEdit = {
  mosaico: 'mosaico',
  unlayer: 'unlayer'
};
