import { Injectable } from '@angular/core';
import { PaginationData } from 'src/app/_models/tables/pagination-data/pagination-data';

@Injectable({
  providedIn: 'root'
})
export class PaginationDataService {

  paginationData = new PaginationData();
  childPaginationData = new PaginationData();
  childModelPaginationData = new PaginationData();
  secondListChildModelPaginationData = new PaginationData();
  finalContactsListChildModelPaginationData = new PaginationData();

  // store pagination data in session variables
  SetPaginationDataToLocalStorage(page: number, pageLimit: number, sortByColumn: string, sortByDirection: string, lastPage: string) {
    localStorage.setItem('Page', page.toString());
    localStorage.setItem('PageLimit', pageLimit.toString());
    localStorage.setItem('SortByColumn', sortByColumn);
    localStorage.setItem('SortByDirection', sortByDirection);
    localStorage.setItem('LastPage', lastPage);
  }

  SetFolderIdToLocalStorage(folderId: number) {
    localStorage.setItem('FolderId', folderId.toString());
  }

  SetMessageFolderIdToLocalStorage(folderId: number) {
    localStorage.setItem('MessageFolderId', folderId.toString());
  }

  SetListIdToLocalStorage(listId: number) {
    localStorage.setItem('ListId', listId.toString());
  }

  GetPaginationDataFromLocalStorage() {
    this.paginationData.page = parseInt(localStorage.getItem('Page'));
    this.paginationData.pageLimit = parseInt(localStorage.getItem('PageLimit'));
    this.paginationData.sortByColumn = localStorage.getItem('SortByColumn');
    this.paginationData.sortByDirection = localStorage.getItem('SortByDirection');
    this.paginationData.lastPage = localStorage.getItem('LastPage');
  }

  GetFolderIdFromLocalStorage() {
    this.paginationData.folderId = parseInt(localStorage.getItem('FolderId'));
  }

  GetMessageFolderIdFromLocalStorage() {
    return parseInt(localStorage.getItem('MessageFolderId'));
  }

  GetListIdFromLocalStorage() {
    this.paginationData.listId = parseInt(localStorage.getItem('ListId'));
  }

  // set child pages as different variables so that we can go up and down pages
  // and still save the data correctly
  SetChildPaginationDataToLocalStorage(page: number, pageLimit: number, sortByColumn: string, sortByDirection: string, lastPage: string) {
    localStorage.setItem('ChildPage', page.toString());
    localStorage.setItem('ChildPageLimit', pageLimit.toString());
    localStorage.setItem('ChildSortByColumn', sortByColumn);
    localStorage.setItem('ChildSortByDirection', sortByDirection);
    localStorage.setItem('ChildLastPage', lastPage);
  }

  SetChildFolderIdToLocalStorage(folderId: number) {
    localStorage.setItem('ChildFolderId', folderId.toString());
  }

  SetChildListIdToLocalStorage(listId: number) {
    localStorage.setItem('ChildListId', listId.toString());
  }

  GetChildPaginationDataFromLocalStorage() {
    this.childPaginationData.page = parseInt(localStorage.getItem('ChildPage'));
    this.childPaginationData.pageLimit = parseInt(localStorage.getItem('ChildPageLimit'));
    this.childPaginationData.sortByColumn = localStorage.getItem('ChildSortByColumn');
    this.childPaginationData.sortByDirection = localStorage.getItem('ChildSortByDirection');
    this.childPaginationData.lastPage = localStorage.getItem('ChildLastPage');
  }

  GetChildFolderIdFromLocalStorage() {
    this.childPaginationData.folderId = parseInt(localStorage.getItem('ChildFolderId'));
  }

  GetChildListIdFromLocalStorage() {
    this.childPaginationData.listId = parseInt(localStorage.getItem('ChildListId'));
  }

  // set child pages as different variables so that we can go up and down pages
  // and still save the data correctly
  SetChildModelPaginationDataToLocalStorage(page: number, pageLimit: number, sortByColumn: string, sortByDirection: string, lastPage: string) {
    localStorage.setItem('ChildModelPage', page.toString());
    localStorage.setItem('ChildModelPageLimit', pageLimit.toString());
    localStorage.setItem('ChildModelSortByColumn', sortByColumn);
    localStorage.setItem('ChildModelSortByDirection', sortByDirection);
    localStorage.setItem('ChildModelLastPage', lastPage);
  }

  SetSecondListChildModelPaginationDataToLocalStorage(page: number, pageLimit: number, sortByColumn: string, sortByDirection: string, lastPage: string) {
    localStorage.setItem('SecondListChildModelPage', page.toString());
    localStorage.setItem('SecondListChildModelPageLimit', pageLimit.toString());
    localStorage.setItem('SecondListChildModelSortByColumn', sortByColumn);
    localStorage.setItem('SecondListChildModelSortByDirection', sortByDirection);
    localStorage.setItem('SecondListChildModelLastPage', lastPage);
  }

  SetFinalContactsListChildModelPaginationDataToLocalStorage(page: number, pageLimit: number, sortByColumn: string, sortByDirection: string, lastPage: string) {
    localStorage.setItem('FinalContactsListChildModelPage', page.toString());
    localStorage.setItem('FinalContactsListChildModelPageLimit', pageLimit.toString());
    localStorage.setItem('FinalContactsListChildModelSortByColumn', sortByColumn);
    localStorage.setItem('FinalContactsListChildModelSortByDirection', sortByDirection);
    localStorage.setItem('FinalContactsListChildModelLastPage', lastPage);
  }

  GetChildModelPaginationDataFromLocalStorage() {
    this.childModelPaginationData.page = parseInt(localStorage.getItem('ChildModelPage'));
    this.childModelPaginationData.pageLimit = parseInt(localStorage.getItem('ChildModelPageLimit'));
    this.childModelPaginationData.sortByColumn = localStorage.getItem('ChildModelSortByColumn');
    this.childModelPaginationData.sortByDirection = localStorage.getItem('ChildModelSortByDirection');
    this.childModelPaginationData.lastPage = localStorage.getItem('ChildModelLastPage');
  }

  ClearPaginationDataFromLocalStorage() {
    localStorage.removeItem('Page');
    localStorage.removeItem('PageLimit');
    localStorage.removeItem('SortByColumn');
    localStorage.removeItem('SortByDirection');
    localStorage.removeItem('LastPage');
    localStorage.removeItem('FolderId');
    localStorage.removeItem('ListId');
  }

  ClearChildDataFromLocalStorage() {
    localStorage.removeItem('ChildPage');
    localStorage.removeItem('ChildPageLimit');
    localStorage.removeItem('ChildSortByColumn');
    localStorage.removeItem('ChildSortByDirection');
    localStorage.removeItem('ChildLastPage');
    localStorage.removeItem('ChildFolderId');
    localStorage.removeItem('ChildListId');
  }

  ClearChildModelDataFromLocalStorage() {
    localStorage.removeItem('ChildModelPage');
    localStorage.removeItem('ChildModelPageLimit');
    localStorage.removeItem('ChildModelSortByColumn');
    localStorage.removeItem('ChildModelSortByDirection');
    localStorage.removeItem('ChildModelLastPage');
  }

  ClearSecondListChildModelDataFromLocalStorage() {
    localStorage.removeItem('SecondListChildModelPage');
    localStorage.removeItem('SecondListChildModelPageLimit');
    localStorage.removeItem('SecondListChildModelSortByColumn');
    localStorage.removeItem('SecondListChildModelSortByDirection');
    localStorage.removeItem('SecondListChildModelLastPage');
  }

  ClearFinalContactsListChildModelDataFromLocalStorage() {
    localStorage.removeItem('FinalContactsListChildModelPage');
    localStorage.removeItem('FinalContactsListChildModelPageLimit');
    localStorage.removeItem('FinalContactsListChildModelSortByColumn');
    localStorage.removeItem('FinalContactsListChildModelSortByDirection');
    localStorage.removeItem('FinalContactsListChildModelLastPage');
  }

  getFolderId(): number {
    return parseInt(localStorage.getItem('FolderId'), 10);
  }

  setPreviousPage(currentPage: number): void {
    localStorage.setItem('previousPage', currentPage.toString());
  }

  getPreviousPage(): number | string {
    return +localStorage.getItem('previousPage');
  }

  resetLocalStorage(field: string): void {
    localStorage.setItem(field, '0');
  }

  getListId(): number {
    return parseInt(localStorage.getItem('ListId'), 10);
  }

  setFilterRules(value: string): void {
    sessionStorage.setItem("FilterRules", value);
  }

  getFilterRules(): string {
    return sessionStorage.getItem("FilterRules");
  }

  resetFilterRules(): void {
    sessionStorage.setItem("FilterRules", '');
  }
}
