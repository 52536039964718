export class ListFolderModel {
  public FolderID: number;
  public FolderName: string;
  public IsDelete: boolean;

  constructor(init: any) {
    this.FolderID = init.FolderID;
    this.FolderName = init.FolderName;
    this.IsDelete = init.IsDelete;
  }
}




