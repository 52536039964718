import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, ProgressbarModule, WavesModule, PreloadersModule } from 'ng-uikit-pro-standard';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MdbSortableModule } from 'mdb-sortable';
import { MdbFileUploadModule } from 'mdb-file-upload';
import { MdbDraggableModule } from 'mdb-draggable';
import { AppComponent } from './app.component';
import { appRoutes } from './routes';
import { LoginComponent } from './views/public/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { NavComponent } from './views/nav/nav.component';
import { RegisterComponent } from './views/public/register/register.component';
import { CreateEditUserComponent } from './views/admin/user-management/create-edit-user/create-edit-user.component';
import { UserManagementComponent } from './views/admin/user-management/user-management.component';
import { AuthService } from './_services/admin/auth/auth.service';
import { FacebookPageService } from './_services/social-media/networks/facebook-page.service';
import { FacebookProfileService } from './_services/social-media/networks/facebook-profile.service';
import { NetworkService } from './_services/social-media/networks/network.service';
import { NotificationService } from './_services/notification.service';
import { UserPermissionTypeService } from './_services/admin/users/user-permission-type-service';
import { UserPermissionService } from './_services/admin/users/user-permission-service';
import { UserRolePermissionService } from './_services/admin/users/user-role-permission-service';
import { NetworkGroupService } from './_services/social-media/network-groups/network-group.service';
import { UserPermissionComponent } from './views/admin/user-permission/user-permission.component';
import { UserRolePermissionComponent } from './views/admin/user-role-permission/user-role-permission.component';
import { UsersComponent } from './views/admin/user-management/users/users.component';
import { ClientManagementComponent } from './views/admin/clients/client-management/client-management.component';
import { ClientCardComponent } from './views/admin/clients/client-card/client-card.component';
import { CreateEditUserService } from './_services/admin/users/create-edit-user.service';
import { UserManagementService } from './_services/admin/users/user-management.service';
import { ForgotPasswordComponent } from './views/public/forgot-password/forgot-password.component';
import { ClientCreateEditComponent } from './views/admin/clients/client-create-edit/client-create-edit.component';
import { UserRoleManagementComponent } from './views/admin/user-role-managment/user-role-management.component';
import { UserRoleService } from './_services/admin/users/user-role-service';
import { UserRolesComponent } from './views/admin/user-role-managment/user-roles/user-roles.component';
import { CreateEditUserRoleComponent } from './views/admin/user-role-managment/create-edit-user-role/create-edit-user-role.component';
import { ClientSettingsComponent } from './views/admin/clients/client-settings/client-settings.component';
import { DomainsComponent } from './views/admin/clients/client-settings/domains/domains.component';
import { AmazonSettingsComponent } from './views/admin/clients/client-settings/amazon-settings/amazon-settings.component';
import { ClientInboxesComponent } from './views/admin/clients/client-settings/client-inboxes/client-inboxes.component';
import { GlobalHttpInterceptorService } from './_services/system/Http/GlobalHttpInterceptor.service';
import { PostsComponent } from './views/social-media/posts/posts.component';
import { CreateEditPostComponent } from './views/social-media/posts/create-edit-post/create-edit-post.component';
import { NetworkCardComponent } from './views/social-media/networks/network-card/network-card.component';
import { NetworkGroupCardComponent } from './views/social-media/network-groups/network-group-card/network-group-card.component';
import { NetworkManagementComponent } from './views/social-media/networks/network-management/network-management.component';
import { NetworkGroupManagementComponent } from './views/social-media/network-groups/network-group-management/network-group-management.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LocalStorageService } from './_services/system/localStorage/localStorage.service';
import { NavigationService } from './_services/system/Navigations/navigation.service';
import { PermissionsService } from './_services/system/Permissions/permissions.service';
import { PermissionsGuard } from './_guards/permissions.guard';
import { ReportService } from './_services/social-media/reports/report.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { MediaServerComponent } from './views/media-server/media-server.component';
import { MediaServerService } from './_services/media-server/media-server.service';
import { MediaServerFolderTreeComponent } from './views/media-server/media-server-folder-tree/media-server-folder-tree.component';
import { ListsComponent } from './views/messaging/lists-and-contacts/lists/lists.component';
import { ContactService } from './_services/messaging/lists-and-contacts/contacts/contact.service';
import { ContactsComponent } from './views/messaging/lists-and-contacts/contacts/contacts.component';
import { EditListComponent } from './views/messaging/lists-and-contacts/lists/edit-list/edit-list.component';
import { EditFormsComponent } from './views/messaging/lists-and-contacts/lists/web-forms/edit-forms/edit-forms.component';
import { HtmlPipe } from './_models/messaging/lists-and-contacts/forms/html-pipe';
import { WebFormContainerComponent } from './views/messaging/lists-and-contacts/lists/web-forms/edit-forms/web-form-container/web-form-container.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContactsUploadComponent } from './views/messaging/lists-and-contacts/contacts/upload/contacts-upload.component';
import { ContactUploadService } from './_services/messaging/lists-and-contacts/contacts/contact.upload.service';
import { VehicleValidationComponent } from './views/tools/vehicle-validation/vehicle-validation/vehicle-validation.component';
import { VehicleValidationService } from './_services/tools/vehicle-validation.service';
import { ContactsUploadHistoryComponent } from './views/messaging/lists-and-contacts/contacts/upload-history/contacts-upload-history.component';
import { EmailBuilderUnlayerComponent } from './views/messaging/messages/email-messaging/email-builder/unlayer/email-builder-unlayer.component';
import { MessagesComponent } from './views/messaging/messages/messages.component';
import { MessageFolderService } from './_services/messaging/messages/message-folder.service';
import { EmailMessagesService } from './_services/messaging/messages/email-messages/email-messages.service';
import { MessageService } from './_services/messaging/messages/message.service';
import { EmailPreviewComponent } from './views/messaging/messages/email-messaging/email-preview/email-preview.component';
import { UrlPipe } from './_models/messaging/messages/email-messages/url-pipe.pipe';
import { SmsMessageComponent } from './views/messaging/messages/sms-messaging/sms-message.component';
import { SmsMessageService } from './_services/messaging/messages/sms-messages/sms-message.service';
import { MessageReportsComponent } from './views/messaging/messages/message-reports/message-reports.component';
import { MessageReportDetailsComponent } from './views/messaging/messages/message-reports/message-report-details/message-report-details.component';
import { QueryBuilderComponent } from './views/shared/query-builder/query-builder.component';
import { MessageSendComponent } from './views/messaging/messages/messages-send/message-send.component';
import { CreateEditTriggerComponent } from './views/messaging/automation/create-edit-trigger/create-edit-trigger.component';
import { ActionRelatesComponent } from './views/messaging/automation/create-edit-trigger/action-relates/action-relates.component';
import {
  ActionsToPerformComponent
} from './views/messaging/automation/create-edit-trigger/actions-to-perform/actions-to-perform.component';
import { TimingComponent } from './views/messaging/automation/create-edit-trigger/timing/timing.component';
import { TriggerComponent } from './views/messaging/automation/create-edit-trigger/trigger/trigger.component';
import { EventInfoResolver } from './_resolvers/messaging/automation/event-info-resolver';
import { TriggersService } from './_services/messaging/automation/triggers/triggers.service';
import { ContactPlanService } from './_services/messaging/automation/contact-plan.service';
import { SelectContactsComponent } from './views/messaging/automation/create-edit-trigger/actions-to-perform/select-contacts/select-contacts.component';
import { UpdateContactValuesComponent } from './views/messaging/automation/create-edit-trigger/actions-to-perform/update-contact-values/update-contact-values.component';
import { CreateEditMessageCodeComponent } from './views/messaging/automation/message-codes/edit-message-code/create-edit-message-code-step/create-edit-message-code.component';
import { MessageCodesComponent } from './views/messaging/automation/message-codes/message-codes.component';
import { MessageCodeService } from './_services/messaging/automation/message-codes/message-code.service';
import { MessageCodeFolderService } from './_services/messaging/automation/message-codes/message-code-folder.service';
import { ContactPlansComponent } from './views/messaging/automation/contact-plans/contact-plans.component';
import { EditContactPlanComponent } from './views/messaging/automation/contact-plans/edit-contact-plan/edit-contact-plan.component';
import { EditMessageCodeComponent } from './views/messaging/automation/message-codes/edit-message-code/edit-message-code.component';
import { MessageCodeStepService } from './_services/messaging/automation/message-codes/message-code-step.service';
import { LightListModelResolver } from './_resolvers/messaging/lists-and-contacts/light-lists-resolver';
import { MessageFolderResolver } from './_resolvers/messaging/messages/message-folder-resolver';
import { TriggerResolver } from './_resolvers/messaging/automation/trigger-resolver';
import { ClientEtlsComponent } from './views/admin/clients/client-settings/client-etls/client-etls.component';
import { ClientETLsResolver } from './_resolvers/admin/clients/client-etls-resolver';
import { PagerService } from './_services/system/pagination/pager.service';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { TwoFactorSetupComponent } from './views/public/cognito/two-factor-setup/two-factor-setup';
import { UserAuthenticationComponent } from './views/public/cognito/user-authentication/user-authentication.component';
import { NewPasswordComponent } from './views/public/cognito/new-password/new-password.component';
import { QRCodeModule } from 'angularx-qrcode';
import { TwoFactorInputComponent } from './views/public/cognito/two-factor-input/two-factor-input.component';
import { ContactInteractionsComponent } from './views/messaging/lists-and-contacts/contact-interactions/contact-interactions.component';
import { ConsentHistoryComponent } from './views/messaging/lists-and-contacts/consent-history/consent-history.component';
import { MessageReportDetailsFilteredComponent } from './views/messaging/messages/message-reports/message-report-details/message-report-details-filtered/message-report-details-filtered.component';
import { MessageHistoryComponent } from './views/messaging/messages/message-history/message-history.component';
import { DataTableColumnWidthManager } from './_models/system/data-table-column-width-manager/data-table-column-width-manager';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { LoaderComponent } from './views/shared/loader/loader.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateEditExtractComponent } from './views/messaging/automation/extracts/create-edit-extract.component';
import { QueryBuilderFilterComponent } from './views/shared/query-builder/query-builder-filter/query-builder-filter.component';
import { PreviewDataMessageComponent } from './views/messaging/automation/create-edit-trigger/preview-data-message/preview-data-message.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmailEditorModule } from 'angular-email-editor';
import { UnlayerEmailMessageService } from './_services/messaging/messages/email-messages/unlayer-email-message.service';
import { ErrorAlertComponent } from './views/shared/error-alert/error-alert.component';
import { DashboardService } from './_services/dashboard/dashboard.service';
import { StatsCardComponent } from './views/dashboard/stats-card.component';
import { SingleNetworkReportComponent } from './views/social-media/reports/single-network-report/single-network-report.component';
import { EmailBuilderMosaicoComponent } from './views/messaging/messages/email-messaging/email-builder/mosaico/email-builder-mosaico.component';
import { PostListComponent } from './views/social-media/social-media-table/post-list/post-list.component';
import { PostsViewComponent } from './views/social-media/social-media-table/posts-view/posts-view.component';
import { PostListContentComponent } from './views/social-media/social-media-table/post-list-content/post-list-content.component';
import { TableComponent } from './views/shared/table/table.component';
import { DeleteRestoreModalComponent } from './views/shared/modals/delete-restore/delete-restore-modal.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ModalContainerComponent } from './views/shared/containers/modal-container/modal-container.component';
import { CreateEditFolderModalComponent } from './views/shared/modals/create-edit-folder/create-edit-folder-modal.component';
import { DeleteFolderModalComponent } from './views/shared/modals/delete-folder/delete-folder-modal.component';
import { SelectEmailBuilderModalComponent } from './views/shared/modals/select-email-builder/select-email-builder-modal.component';
import { CreateAutomationModalComponent } from './views/shared/modals/create-automation/create-automation-modal.component';
import { ActivateContactPlanModalComponent } from './views/shared/modals/activate-contact-plan/activate-contact-plan-modal.component';
import { CopyMoveMessageModalComponent } from './views/shared/modals/copy-move-message/copy-move-message-modal.component';
import { MdSelectComponent } from './views/shared/md-select/md-select.component';
import {
  SearchReplaceMessageComponent
} from './views/shared/search-replace-message/search-replace-message.component';
import { MdInputComponent } from './views/shared/md-input/md-input.component';
import { FindAndReplaceModalComponent } from './views/shared/modals/find-and-replace/find-and-replace-modal.component';
import { SearchReplaceModalComponent } from './views/shared/modals/search-replace/search-replace-modal.component';
import { MessageToCopyMoveComponent } from './views/shared/message-to-copy-move/message-to-copy-move.component';
import { FilterModalComponent } from './views/shared/modals/filter/filter-modal.component';
import { TrialRunModalComponent } from './views/shared/modals/trial-run/trial-run-modal.component';
import { PageContainerComponent } from './views/shared/containers/page-container/page-container.component';
import { MessagingHeaderComponent } from './views/shared/messaging-header/messaging-header.component';
import { CreateListModalComponent } from './views/shared/modals/create-list/create-list-modal.component';
import { ClearListModalComponent } from './views/shared/modals/clear-list/clear-list-modal.component';
import { CopyMoveModalComponent } from './views/shared/modals/copy-move/copy-move-modal.component';
import { SmsReportsComponent } from './views/messaging/messages/sms-reports/sms-reports.component';
import { CreateEditContactModalComponent } from './views/shared/modals/create-contact/create-edit-contact-modal.component';
import { UploadContactsModalComponent } from './views/shared/modals/upload-contacts/upload-contacts-modal.component';
import { BlockContainerComponent } from './views/shared/containers/block-container/block-container.component';
import { SubscriptionCommentModalComponent } from './views/shared/modals/subscription-comment/subscription-comment-modal.component';
import { loadConfigProvider } from './app-initializer/app.initializer';
import { MatSelectModule } from '@angular/material/select';
import { AuditTrailComponent } from './views/audit-trail/audit-trail.component';
import { AuditTrailService } from './_services/audit-trail/audit-trail.service';
import { PendingChangesGuard } from './_guards/pending-changes-guard';
import { UnsavedChangesModalComponent } from './views/shared/modals/unsaved-changes/unsaved-changes-modal.component';
import { LastActiveService } from './_services/system/last-activity-service';
import { InactivityWarningModalComponent } from './views/shared/modals/unsaved-changes/inactivity-warning-modal.component';
import { CreateEditMultiListTriggerComponent } from './views/messaging/automation/create-edit-multi-list-trigger/create-edit-multi-list-trigger.component';
import { PreviewDataMessageMultiListComponent } from './views/messaging/automation/create-edit-multi-list-trigger/preview-data-message-multi-list/preview-data-message-multi-list.component';
import { TriggerMultiListComponent } from './views/messaging/automation/create-edit-multi-list-trigger/trigger-multi-list/trigger-multi-list.component';
import {MatTabsModule} from '@angular/material/tabs';
import { UpdateContactsComponent } from './views/messaging/automation/create-edit-multi-list-trigger/actions-to-perform-multi-list/update-contacts/update-contacts.component';
import { SecondListUpdateContactsComponent } from './views/messaging/automation/create-edit-multi-list-trigger/actions-to-perform-multi-list/second-list-update-contacts/second-list-update-contacts.component';
import { BulkMessageSendComponent } from './views/messaging/messages/bulk-messages-send/bulk-message-send.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NavComponent,
    RegisterComponent,
    CreateEditUserComponent,
    UserManagementComponent,
    UsersComponent,
    UserPermissionComponent,
    UserRolePermissionComponent,
    ClientManagementComponent,
    ClientCardComponent,
    ForgotPasswordComponent,
    ClientCreateEditComponent,
    UserRoleManagementComponent,
    UserRolesComponent,
    CreateEditUserRoleComponent,
    ClientSettingsComponent,
    DomainsComponent,
    AmazonSettingsComponent,
    ClientInboxesComponent,
    PostsComponent,
    CreateEditPostComponent,
    NetworkCardComponent,
    NetworkGroupCardComponent,
    NetworkManagementComponent,
    NetworkGroupManagementComponent,
    MediaServerComponent,
    MediaServerFolderTreeComponent,
    ListsComponent,
    ContactsComponent,
    EditListComponent,
    EditFormsComponent,
    WebFormContainerComponent,
    HtmlPipe,
    VehicleValidationComponent,
    ContactsUploadComponent,
    ContactsUploadHistoryComponent,
    EmailBuilderMosaicoComponent,
    EmailBuilderUnlayerComponent,
    MessagesComponent,
    EmailPreviewComponent,
    UrlPipe,
    SmsMessageComponent,
    MessageReportsComponent,
    MessageReportDetailsComponent,
    TriggerMultiListComponent,
    QueryBuilderComponent,
    MessageSendComponent,
    CreateEditTriggerComponent,
    PreviewDataMessageMultiListComponent,
    ActionRelatesComponent,
    ActionsToPerformComponent,
    TimingComponent,
    TriggerComponent,
    CreateEditMultiListTriggerComponent,
    UpdateContactsComponent,
    SecondListUpdateContactsComponent,
    ContactPlansComponent,
    EditContactPlanComponent,
    SelectContactsComponent,
    UpdateContactValuesComponent,
    CreateEditMessageCodeComponent,
    MessageCodesComponent,
    EditMessageCodeComponent,
    ClientEtlsComponent,
    TwoFactorSetupComponent,
    UserAuthenticationComponent,
    NewPasswordComponent,
    TwoFactorInputComponent,
    ContactInteractionsComponent,
    ConsentHistoryComponent,
    MessageReportDetailsFilteredComponent,
    MessageHistoryComponent,
    CreateEditExtractComponent,
    LoaderComponent,
    QueryBuilderFilterComponent,
    PreviewDataMessageComponent,
    ErrorAlertComponent,
    SingleNetworkReportComponent,
    StatsCardComponent,
    PostListComponent,
    PostsViewComponent,
    PostListContentComponent,
    TableComponent,
    DeleteRestoreModalComponent,
    ModalContainerComponent,
    CreateEditFolderModalComponent,
    DeleteFolderModalComponent,
    SelectEmailBuilderModalComponent,
    CreateAutomationModalComponent,
    ActivateContactPlanModalComponent,
    CopyMoveMessageModalComponent,
    MdSelectComponent,
    SearchReplaceMessageComponent,
    MdInputComponent,
    FindAndReplaceModalComponent,
    SearchReplaceModalComponent,
    CopyMoveModalComponent,
    MessageToCopyMoveComponent,
    FilterModalComponent,
    TrialRunModalComponent,
    PageContainerComponent,
    MessagingHeaderComponent,
    CreateListModalComponent,
    ClearListModalComponent,
    SmsReportsComponent,
    CreateEditContactModalComponent,
    UploadContactsModalComponent,
    BlockContainerComponent,
    SubscriptionCommentModalComponent,
    AuditTrailComponent,
    UnsavedChangesModalComponent,
    InactivityWarningModalComponent,
    BulkMessageSendComponent
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
    NgxCaptchaModule,
    MdbSortableModule,
    MdbFileUploadModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxDatatableModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MdbDraggableModule,
    InfiniteScrollModule,
    WavesModule,
    PreloadersModule,
    ProgressbarModule,
    AmplifyUIAngularModule,
    QRCodeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    PickerModule,
    EmailEditorModule,
    MatSelectModule,
    MatTabsModule
  ],
  providers: [
    MDBSpinningPreloader,
    AuthService,
    NetworkService,
    NetworkGroupService,
    NotificationService,
    FacebookPageService,
    FacebookProfileService,
    ContactService,
    ContactUploadService,
    UserPermissionService,
    UserRolePermissionService,
    CreateEditUserService,
    UserManagementService,
    UserRoleService,
    UserPermissionTypeService,
    LocalStorageService,
    MessageService,
    MessageFolderService,
    NavigationService,
    PermissionsService,
    PermissionsGuard,
    DatePipe,
    ReportService,
    MediaServerService,
    VehicleValidationService,
    MDBSpinningPreloader,
    EmailMessagesService,
    UnlayerEmailMessageService,
    SmsMessageService,
    ContactPlanService,
    TriggersService,
    EventInfoResolver,
    LightListModelResolver,
    MessageFolderResolver,
    MessageCodeService,
    MessageCodeStepService,
    MessageCodeFolderService,
    TriggerResolver,
    ClientETLsResolver,
    PagerService,
    DataTableColumnWidthManager,
    DashboardService,
    AuditTrailService,
    PendingChangesGuard,
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    loadConfigProvider,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LastActiveService],
      useFactory: (lastActiveService: LastActiveService) => () =>
        lastActiveService.setUp(),
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },    
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

