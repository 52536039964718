export class SMSMessageRunReportModel {
  public reportId: number;
  public listId: number;
  public templateId: number;
  public messageName: string;
  public messageType: number;
  public messageSourceName: string;
  public sentBy: string;
  public folderName: string;
  public sendDate: string;
  public folderId: number;
  public totalSent: number;
  public totalDelivered: number;
  public deliveredRate: any;
  public totalBounces: number;
  public bounceRate: any;

  constructor(init: any) {
    this.reportId = init.ReportID;
    this.listId = init.ListID;
    this.templateId = init.TemplateID;
    this.messageName = init.MessageName;
    this.messageType = init.MessageType;
    this.messageSourceName = init.MessageSourceName;
    this.sentBy = init.SentBy;
    this.folderName = init.FolderName;
    this.sendDate = init.SendDate;
    this.folderId = init.FolderID;
    this.totalSent = init.TotalRecipients;
    this.totalDelivered = init.TotalDelivered;
    this.deliveredRate = init.DeliveredRate;
    this.totalBounces = init.TotalBounces;
    this.bounceRate = init.BounceRate;
  }
}
