import { Injectable } from '@angular/core';
import { CopyListModel } from './copy-list-model';

@Injectable({
  providedIn: 'root'
})

export class CopyListModelAdapter {
  adapt(item: any): CopyListModel {
    return new CopyListModel(item);
  }
  createEmpty() {
    return new CopyListModel({
      CopyListName: '',
      ImportContactsRequired: false,
      DestinationFolderId: null,
      SourceListId: null,
      ListType: 0,
    });
  }
}
