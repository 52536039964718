import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-md-input',
  templateUrl: './md-input.component.html',
  styleUrls: ['./md-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MdInputComponent),
      multi: true
    }]
})
export class MdInputComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() maxLength = 255;

  inputText: UntypedFormControl;

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    this.inputText = new UntypedFormControl('', Validators.required);

    this.inputText.valueChanges.subscribe((value) => {
      this.onChange(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(text: string) {
    this.inputText.setValue(text);
  }
}
