import { Injectable } from '@angular/core';
import { FacebookConfigurationModelDto } from './facebook-configuration-model-dto';

@Injectable({
  providedIn: 'root'
})

export class FacebookConfigurationModelAdapter {
  public adapt(item: any) {
    return new FacebookConfigurationModelDto(item);
  }
}
