export class CreateETLDto {
  ClientId: number;
  DMS: string;
  Type: string;
  IsMigration: boolean;

  constructor(init: any) {
    this.ClientId = init.ClientId;
    this.DMS = init.DMS;
    this.Type = init.Type;
    this.IsMigration = init.IsMigration;
  }
}
