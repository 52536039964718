<app-modal-container [title]="title"
                     [actionButtonName]="data.row ? 'Save' : 'Create'"
                     [buttonValidation]="!contactFormGroup.valid"
                     (action)="create()">

  <form [formGroup]="contactFormGroup">
    <app-md-select label="Folder"
                   [disabled]="true"
                   formControlName="folderId"
                   [options]="folderOptions">
    </app-md-select>

    <app-md-select label="List"
                   [disabled]="true"
                   formControlName="listId"
                   [options]="listOptions">
    </app-md-select>

    <div class="form-scrollbar scrollbar-primary">
      <div class="force-overflow">
        <div *ngFor="let field of fields">
          <div class="custom-control-types" [ngSwitch]="field.controlTypeId">
            <div>
              <app-md-input *ngSwitchCase="listControlTypeEnum.Input"
                            [label]="field.displayName"
                            [formControlName]="field.name">
              </app-md-input>
              <div *ngIf="contactFormGroup.get(field.name) && contactFormGroup.get(field.name)?.errors?.pattern ||
                          contactFormGroup.get(field.name)?.errors?.email"
                   class="invalid-field">
                {{ field.displayName }} invalid.
                {{ field.fieldTypeName.toLowerCase() === 'mobile' ? 'Format: 447948123456' : '' }}
              </div>
            </div>

            <div class="form-group pt-2" *ngSwitchCase="listControlTypeEnum.Textarea">
              <label class="input-label ml-0 custom-label">{{ field.displayName }}</label>
              <textarea mdbInput id="{{ field.name }}" class="form-control"
                        formControlName="{{ field.name }}">
              </textarea>
            </div>

            <app-md-select *ngSwitchCase="listControlTypeEnum.Dropdown"
                           [label]="field.displayName"
                           [formControlName]="field.name"
                           [options]="field.fieldTypeId === listFieldTypeEnum.Boolean ? booleanOptions : findDropDownListOptions(field.fieldId)">
            </app-md-select>
            <div *ngIf="contactFormGroup.get(field.name) && contactFormGroup.get(field.name)?.errors?.required"
                 class="invalid-field">
              {{ field.displayName }} invalid
            </div>

            <div class="form-group pt-2" *ngSwitchCase="listControlTypeEnum.Checkbox">
              <label>{{ field.displayName }}</label><br/>
              <mdb-checkbox *ngFor="let option of field.options; let i = index"
                            [inline]="true"
                            formControlName="{{ field.name }}-{{ option.optionValue }}"
                            name="{{ field.name }}-{{ option.optionValue }}" id="{{ field.name + i }}"
                            [value]="option.optionValue">
                {{option.optionValue}}
              </mdb-checkbox>
            </div>

            <div class="form-group pt-2" *ngSwitchCase="listControlTypeEnum.RadioButton">
              <label>{{ field.displayName }}</label><br/>
              <div class="form-check form-check-inline"
                   *ngFor="let option of field.options; let i = index">
                <input type="radio" class="form-check-input" id="{{ field.name + i }}"
                       name="{{ field.name }}" formControlName="{{ field.name }}"
                       [value]="option.optionValue">
                <label class="form-check-label" for="{{ field.name + i }}">{{ option.optionValue }}</label>
              </div>
              <div *ngIf="contactFormGroup.get(field.name) && contactFormGroup.get(field.name)?.errors?.required"
                   class="invalid-field">
                {{ field.displayName }} invalid
              </div>
            </div>

            <div class="form-group pt-2" *ngSwitchCase="listControlTypeEnum.DatePicker">
              <label class="input-label ml-0" for="{{ field.name }}">{{ field.displayName }}</label>
              <input readonly autocomplete="nope" id="{{ field.name }}" class="form-control" type="text"
                     formControlName="{{ field.name }}"
                     (click)="onInputClickShowDatePicker(field.name)" mdbInput>
              <div class="hiddenDiv" id="div-{{ field.name }}">
                <mdb-date-picker id="dp-{{ field.name }}" name="dp-{{ field.name }}"
                                 formControlName="dp-{{ field.name }}"
                                 type="date"
                                 [options]="myDatePickerOptions"
                                 [config]="{backdrop: 'static', keyboard: false}"
                                 (dateChanged)="onDateChange(field.name, $event)">
                </mdb-date-picker>
              </div>
            </div>

            <div class="form-group pt-2" *ngSwitchCase="listControlTypeEnum.TimePicker">
              <label class="input-label ml-0" for="{{ field.name}}">{{ field.displayName }}</label>
              <input readonly autocomplete="nope" id="{{ field.name }}" class="form-control" type="text"
                     formControlName="{{ field.name }}"
                     (click)="onInputClickShowTimePicker(field.name)" [ngClass]="" mdbInput>

              <div class="hiddenDiv" id="div-{{ field.name }}">
                <mdb-time-picker id="tp-{{ field.name }}" name="tp-{{ field.name }}"
                                 [config]="{backdrop: 'static', keyboard: false}"
                                 (timeChanged)="onTimeChange(field.name, $event)">
                </mdb-time-picker>
              </div>
            </div>

            <div class="form-group pt-2" *ngSwitchCase="listControlTypeEnum.DateTimePicker">
              <label class="input-label ml-0" for="{{ field.name }}">{{ field.displayName }}</label>
              <input readonly autocomplete="nope" id="{{ field.name }}" class="form-control" type="text"
                     formControlName="{{ field.name }}"
                     (click)="onInputClickShowDatePicker(field.name)" mdbInput>

              <div class="hiddenDiv" id="div-{{ field.name }}">
                <mdb-date-picker id="dp-{{ field.name }}" name="dp-{{ field.name }}"
                                 formControlName="dp-{{ field.name }}" type="date" [options]="myDatePickerOptions"
                                 [config]="{backdrop: 'static', keyboard: false}"
                                 (dateChanged)="onDateChange(field.name, $event)">
                </mdb-date-picker>
                <mdb-time-picker id="tp-{{ field.name }}" name="tp-{{ field.name }}"
                                 [config]="{backdrop: 'static', keyboard: false}"
                                 (timeChanged)="onTimeChange(field.name, $event)">
                </mdb-time-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</app-modal-container>
