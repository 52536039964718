export class EnquiryMaxUpdateDealerInformationDto {
  clientId: number;
  ids: number[];
  dealerIds: string [];
  locationNames: string[];

  constructor(init: { clientId: number; ids: number[]; dealerIds: string []; locationNames: string[] }) {
    this.clientId = init.clientId;
    this.ids = init.ids;
    this.dealerIds = init.dealerIds;
    this.locationNames = init.locationNames;
  }
}