import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
// currently, this page is for Cognito passwords only - it is used when a user is either created in Cognito so needs to change their password on force login
// or we force a user to change their password in Cognito
// this may need updating to change the password locally, too, if required
export class NewPasswordComponent implements OnInit {
  _newPasswordForm: UntypedFormGroup;
  _submitting: boolean;
  _newPasswordResult: string;

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    public route: ActivatedRoute) { }

  ngOnInit() {
    this.createnewPasswordForm();
  }

  createnewPasswordForm() {
    this._newPasswordForm = this.fb.group({
      password: ['',
        [Validators.required, Validators.minLength(8), this.passwordStrengthValidator]],
      confirmPassword: ['', Validators.required]
    }, { validators: [this.passwordMatchValidator] });
  }

  // ensure that the password and confirm password fields match
  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : { 'mismatch': true };
  }

  // ensure that the new password adheres to our password strength requirements
  passwordStrengthValidator(g: UntypedFormGroup) {
    let hasNumber = /\d/.test(g.value);
    let hasUpper = /[A-Z]/.test(g.value);
    let hasLower = /[a-z]/.test(g.value);
    let hasSpecial = /[`!@#$%^&*()_\\[\]{};':"\\|,.<>\/?~]/.test(g.value);

    const valid = hasNumber && hasUpper && hasLower && hasSpecial;

    if (!valid) return { 'weak': true };
    return null;
  }

  // attempt to submit a new password to Cognito and update that password in Voicebox
  async submitNewPassword(newPassword: string) {
    try {
      this._submitting = true;
      this._newPasswordResult = await this.authService.awsNewPassword(newPassword).then((result => this._newPasswordResult = result));
      if (this._newPasswordResult === "true") {
        this.notificationService.showSuccess("Updated password");
        this.router.navigate(['/login']);
      }
      else {
        this.notificationService.showError('Error submitting new password: ' + this._newPasswordResult);
        this._submitting = false;
      }
    } catch (error) {
      this.notificationService.showError('Error submitting new password: ' + error.message);
      console.log(error);
      this._submitting = false;
    }

  }
}
