import { Injectable } from '@angular/core';
import { ListFolderModel } from './list-folder-model';

@Injectable({
  providedIn: 'root'
})

export class ListFolderModelAdapter {
  adapt(item: any): ListFolderModel {
    return new ListFolderModel(item);
  }

  adaptArray(init: Array<any>): ListFolderModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new ListFolderModel(table))
      : [new ListFolderModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new ListFolderModel({
      FolderID: 0,
      FolderName: '',
      IsDelete: false,
    });
  }
}
