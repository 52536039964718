<div class="report-selection">
  <div [formGroup]="searchForm" class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <span class="title-sub-section mr-3">{{ headLabel }}</span>
        <hr class="mt-1 mb-2">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-10">
        <label class="input-label ml-0">Filter by text</label>
        <input type="text" class="form-control" formControlName="textFilter" />
      </div>
      <div class="col-md-2">
        <label class="input-label ml-0">Order</label>
        <div>
          <button class="btn-filter d-inline-block" [disabled]="isAscending"
                  (click)="orderPostsByDate(true)">
            <mdb-icon fas icon="arrow-up"></mdb-icon>
          </button>
          <button class="btn-filter d-inline-block" [disabled]="!isAscending"
                  (click)="orderPostsByDate(false)">
            <mdb-icon fas icon="arrow-down"></mdb-icon>
          </button>
        </div>
      </div>
    </div>

    <app-post-list [pageLimit]="pageLimit"
                   [isReport]="isReport"
                   [filteredPosts]="filteredPosts"
                   [selectedNetworkName]="selectedNetworkName"
                   [selectedNetworkType]="selectedNetworkType">
    </app-post-list>

    <mat-paginator [length]="itemsCount" [pageSize]="pageLimit"
                   [pageSizeOptions]="sizeOptionsFiltered" [pageIndex]="pageOffSet"
                   (page)="pageInsightPaginateTable($event)" class="mat-paginator">
    </mat-paginator>
  </div>
</div>

