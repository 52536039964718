import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MediaServerAllFoldersModel } from 'src/app/_models/media-server/media-server-all-folders-model';
import { MediaServerService } from 'src/app/_services/media-server/media-server.service';

@Component({
  selector: 'app-media-server-folder-tree',
  templateUrl: './media-server-folder-tree.component.html',
  styleUrls: ['./media-server-folder-tree.component.scss']
})
export class MediaServerFolderTreeComponent implements OnInit {

  @Input() folders: MediaServerAllFoldersModel;
  @Input() hasSiblingSelected: MediaServerAllFoldersModel;


  @Output() childSelected = new EventEmitter();
  @Output() childDeselected = new EventEmitter();


  _expanded: boolean = false;
  _isSiblingSelected: boolean = false;

  constructor(private mediaServerService: MediaServerService) {

  }

  ngOnInit() {

  }

  toggleExpanded() {
    if (!this._expanded && !this.hasSiblingSelected) {
      this._expanded = true;
      this.childSelected.emit();
      this.mediaServerService.moveFileDestinationKey = this.folders.Path;
      return;
    } else if (this._expanded) {
      this._expanded = false;
      this.childDeselected.emit();
      return;
    }

  }

  selectChild() {
    this._isSiblingSelected = true;
  }

  deselectChild() {
    this._isSiblingSelected = false;
    this.mediaServerService.moveFileDestinationKey = this.folders.Path;
  }

}
