import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QueryBuilderStorageService {
    
    sqlFilter: string = '';

    constructor() { }

    SetSQLFilterToLocalStorage(sqlFilter: string) {
      localStorage.setItem('SQLFilter', sqlFilter);
    }

    GetSQLFilterFromLocalStorage() {
      this.sqlFilter = localStorage.getItem('SQLFilter');
    }

    ClearSQLFilterFromLocalStorage() {
      localStorage.removeItem('SQLFilter');
    }

}