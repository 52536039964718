import { Injectable } from '@angular/core';
import { MessageCopyMoveFindReplaceDto } from './message-copy-move-find-replace-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageCopyMoveFindReplaceDtoAdapter {
  public adapt(item: any): MessageCopyMoveFindReplaceDto {
    return new MessageCopyMoveFindReplaceDto(item);
  }

  public adaptArray(init: Array<any>): MessageCopyMoveFindReplaceDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(m => new MessageCopyMoveFindReplaceDto(m))
      : [new MessageCopyMoveFindReplaceDto(init[0])];
    } else {
      return [];
    }
  }
}
