<div class="container-fluid animated fadeIn">
  <div class="row m-4">
    <div class="col-lg-3">

      <!--Section: Profile Picture-->
      <section class="section team-section pb-3">

        <div class="row text-center">
          <div class="col-md-12 profile-photo-card">
            <mdb-card cascade="true" narrower="true">
              <!--Card image-->
              <div class="view view-cascade overlay waves-light profile-photo" mdbWavesEffect>
                <mdb-card-img class="" src="../../../../../assets/images/default-user-icon.jpg" *ngIf="!this.createEditUserForm.get('userIconUrl').value"></mdb-card-img>
                <mdb-card-img class="" src="{{createEditUserForm.get('userIconUrl').value}}" *ngIf="this.createEditUserForm.get('userIconUrl').value"></mdb-card-img>
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
              <!--/Card image-->

              <!--Card content-->
              <mdb-card-body class="card-body card-body-cascade">
                <h4>{{this.createEditUserForm.get('firstName').value}}
                    {{this.createEditUserForm.get('lastName').value}}</h4>
                <h5>UserRole</h5>

                <button type="button" mdbBtn color="secondary" class="rounded" size="sm" mdbWavesEffect (click)="openMediaServerModal()">Upload</button>
                <button type="button" mdbBtn color="secondary" class="rounded" size="sm" mdbWavesEffect (click)="deleteUserIcon()">Delete</button>

              </mdb-card-body>
              <!--/.Card content-->
            </mdb-card>
            <!--/.Card-->
          </div>
        </div>

      </section>
      <!--/.Section: Profile Picture-->

    </div>

    <div class="col-lg-9">

      <!--Card-->
      <mdb-card cascade="true" narrower="true">

        <!--Card image-->
        <div class="view view-cascade card-header text-center primary-color">
          <h4 class="h4-responsive card-header-text"
            *ngIf="this.createUser === true">Create User</h4>
          <h4 class="h4-responsive card-header-text"
            *ngIf="this.createUser === false">Edit User</h4>
        </div>
        <!--/Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="text-center">

          <form [formGroup]="createEditUserForm" (ngSubmit)="createEditUser()">

            <!-- Hidden Input for UserID -->

            <input
            type="number"
            id="userId"
            class="form-control"
            formControlName="userId"
            hidden
            mdbInput>

            <!-- /Hidden Input for UserID -->

            <!--First row-->
            <div class="row">
              <!--First column-->
              <div class="col-md-1">
                <div class="md-form">
                  <input
                    type="text"
                    id="title"
                    [ngClass]="{'is-invalid': createEditUserForm.get('title').errors
                      && createEditUserForm.get('title').touched}"
                    class="form-control"
                    formControlName="title"
                    mdbInput>
                  <label for="title">Title</label>
                  <div class="invalid-feedback text-left">Title required.</div>
                </div>
              </div>

              <!--Second column-->
              <div class="col-md-2">
                <div class="md-form">
                  <input
                    type="text"
                    id="first-name"
                    [ngClass]="{'is-invalid': createEditUserForm.get('firstName').errors
                      && createEditUserForm.get('firstName').touched}"
                    class="form-control"
                    formControlName="firstName"
                    mdbInput>
                  <label for="first-name">First Name</label>
                  <div class="invalid-feedback text-left">First Name required.</div>
                </div>
              </div>

              <!--Third column-->
              <div class="col-md-2">
                <div class="md-form">
                  <input
                    type="text"
                    id="last-name"
                    [ngClass]="{'is-invalid': createEditUserForm.get('lastName').errors
                      && createEditUserForm.get('lastName').touched}"
                    class="form-control"
                    formControlName="lastName"
                    mdbInput>
                  <label for="last-name">Last Name</label>
                  <div class="invalid-feedback text-left">Last Name required.</div>
                </div>
              </div>

              <!--Fourth column-->
              <div class="col-md-2">
                <div class="md-form">
                  <input
                    type="text"
                    id="userName"
                    [ngClass]="{'is-invalid': createEditUserForm.get('userName').errors
                      && createEditUserForm.get('userName').touched}"
                    class="form-control"
                    formControlName="userName"
                    mdbInput>
                  <label for="userName">Username</label>
                  <div class="invalid-feedback text-left">Username required.</div>
                </div>
              </div>

              <!--Fifth column-->
              <div class="col-md-5">
                <div class="md-form">
                  <input
                    type="text"
                    id="email"
                    [ngClass]="{'is-invalid': createEditUserForm.get('email').errors
                      && createEditUserForm.get('email').touched}"
                    class="form-control"
                    formControlName="email"
                    mdbInput>
                  <label for="email">Email</label>
                  <div class="invalid-feedback ml-5"
                          *ngIf="createEditUserForm.get('email').hasError('required')
                          && createEditUserForm.get('email').touched">Please enter an Email.
                          </div>
                          <div class="invalid-feedback ml-5"
                          *ngIf="createEditUserForm.get('email').hasError('email')
                          && createEditUserForm.get('email').touched">Email must be a valid Email Address.
                          </div>
                </div>
              </div>
            </div>
            <!--/.First row-->

            <!--Second row-->
            <div class="row">
              <!--First column-->
              <div class="col-md-5">
                <div class="md-form">
                  <input
                    type="text"
                    id="address-one"
                    class="form-control"
                    formControlName="address1"
                    mdbInput>
                  <label for="address-one">Address One</label>
                </div>
              </div>

              <!--Second column-->
              <div class="col-md-5">
                <div class="md-form">
                  <input
                    type="text"
                    id="address-two"
                    class="form-control"
                    formControlName="address2"
                    mdbInput>
                  <label for="address-two">Address Two</label>
                </div>
              </div>

              <!--Third column-->
              <div class="col-md-2">
                <div class="md-form">
                  <input
                    type="text"
                    id="postcode"
                    class="form-control"
                    formControlName="postcode"
                    mdbInput>
                  <label for="postcode">Postcode</label>
                </div>
              </div>
            </div>
            <!--/.Second row-->

            <!--Third row-->
            <div class="row">
              <!--First column-->
              <div class="col-md-4">
                <div class="md-form">
                  <mdb-select
                    id="defaultClient"
                    formControlName="defaultClient"
                    [options]="_clientsSelect"
                    [visibleOptions]="10"
                    [filterEnabled]="true"
                    label="Select Default Client"
                    [ngClass]="{'is-invalid': createEditUserForm.get('defaultClient').errors || 
                      createEditUserForm.hasError('noDefaultClient')}">
                  </mdb-select>
                </div>
              </div>
              <!--Second column-->
              <div class="col-md-4" *ngIf="createUser">
                <div class="md-form">
                  <input
                    type="password"
                    id="password"
                    [ngClass]="{'is-invalid': createEditUserForm.get('password').errors
                      && createEditUserForm.get('password').touched}"
                    class="form-control"
                    formControlName="password"
                    mdbInput>

                  <label class="active" for="password">Password</label>
                  <div class="invalid-feedback text-left" *ngIf="createEditUserForm.get('password').hasError('required')
                    && createEditUserForm.get('password').touched">Password required.
                  </div>
                  <div class="invalid-feedback text-left" *ngIf="createEditUserForm.get('password').hasError('minlength')
                    && createEditUserForm.get('password').touched">Password must be atleast 8 characters.
                  </div>
                </div>
              </div>

              <!--Third column-->
              <div class="col-md-4" *ngIf="createUser">
                <div class="md-form">
                  <input
                    type="password"
                    id="confirm-password"
                    [ngClass]="{'is-invalid': createEditUserForm.get('confirmPassword').errors
                      && createEditUserForm.get('confirmPassword').touched
                      || createEditUserForm.get('confirmPassword').touched
                      && createEditUserForm.hasError('mismatch')}"
                    class="form-control"
                    formControlName="confirmPassword"
                    mdbInput>
                  <label class="active" for="confirm-password">Confirm Password</label>
                  <div class="invalid-feedback text-left"
                    *ngIf="createEditUserForm.get('confirmPassword').hasError('required')
                    && createEditUserForm.get('confirmPassword').touched">Password Required.
                  </div>
                  <div class="invalid-feedback text-left"
                    *ngIf="createEditUserForm.hasError('mismatch')
                    && createEditUserForm.get('confirmPassword').touched">Passwords must match.
                  </div>
                </div>
              </div>
            </div>
            <!--/.Third row-->

            <div class="text-center"
              *ngIf="this.createUser === true">
              <button
                *ngIf="!_submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                [disabled]="!createEditUserForm.valid"
                mdbWavesEffect>Create
              </button>
              <button
                *ngIf="_submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                disabled
                mdbWavesEffect>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true">
                </span>
                Creating...
              </button>
            </div>

            <div class="text-center"
              *ngIf="this.createUser === false">
              <button
                *ngIf="!_submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                [disabled]="!createEditUserForm.valid"
                mdbWavesEffect>Update
              </button>
              
              <button
                *ngIf="this.createUser === false"
                type="button"
                mdbBtn
                color="primary"
                class="rounded"
                [routerLink]="['/user-permission', this.user.userId]"
                mdbWavesEffect>User Permissions
              </button>

              <button
                *ngIf="this.createUser === false"
                type="button"
                mdbBtn
                color="primary"
                class="rounded"
                (click)="basicModal.show()"
                mdbWavesEffect>User Roles
              </button>

              <button
                *ngIf="_submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                disabled
                mdbWavesEffect>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true">
                </span>
                Updating...
              </button>
            </div>
          </form>
          <!--Contact Form-->

        </mdb-card-body>
        <!--/.Card content-->

      </mdb-card>
      <!--/.Card-->


    </div>

  </div>

</div>

<!-- Modal -->
<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="modalLabel">User Roles</h4>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
              <div class="col-4" *ngFor="let userRoleDto of this._userRoleDtos">
                <div class="form-check form-check-inline">
                  <input type="checkbox" [checked]="this.userIsInRole(userRoleDto.id)" class="form-check-input" id="checkbox-{{userRoleDto.id}}"
                    (change)="this.handleCheckBoxChange($event)" value="{{userRoleDto.id}}">
                  <label class="form-check-label md-userRoleLabel" for="checkbox-{{userRoleDto.id}}">{{userRoleDto.userRoleName}}</label>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light rounded" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>


<div mdbModal #mediaServerModal="mdbModal" class="modal fade right modal-overflow" tabindex="-1" role="dialog" aria-labelledby="mediaServerModalLabel"
   aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeMediaServer()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Select Files From Media Server</h4>
            </div>
            <div class="modal-body">
                <app-media-server *ngIf="_showMediaServer"></app-media-server>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="closeMediaServer()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="setUserIcon()" mdbWavesEffect>OK!</button>
            </div>
        </div>
    </div >
</div >
