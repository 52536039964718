<div class="row mt-4">
  <div class="col-md-6 offset-3">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-2">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 mb-3 d-flex justify-content-center">
        <h4 class="page-title">{{_titleText}}</h4>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="">

        <section>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-8 offset-2">
                <form [formGroup]="this.triggerDetailsForm">
                  <div class="md-form remove-bottom-margin">
                    <input 
                    mdbInput 
                    type="text" 
                    class="form-control"
                    id="trigger-title"
                    max="500"
                    formControlName="title">
                    <label for="trigger-title">Title</label>
                    <div class="alert alert-warning remove-padding" *ngIf="this.triggerDetailsForm.get('title').invalid">
                            Please enter a title.
                     </div>
                  </div>                  
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mdb-stepper #stepper [linear]="true" [vertical]="true" class="trigger-container">
                  <mdb-step name="This action relates to" [stepForm]="stepForm.get('listForm')">                    
                    <form [formGroup]="stepForm.get('listForm')">

                      <div class="md-form remove-top-margin">
                        <div class="form-check">
                          <input 
                            type="radio" 
                            class="form-check-input" 
                            id="triggerActionType" 
                            [value]="'list'"
                            formControlName="triggerActionType" 
                            name="triggerActionType"
                            label="A trigger against the following list">
                          <label class="form-check-label" for="list-trigger-check-1">A trigger against the following list</label>
                        </div>
                      </div>
                    
                      <div class="offset" [@slide]>
                        <div class="md-form remove-vertical-margin">
                          <mdb-select
                            [options]="_listFolderDropdown"
                            [filterEnabled]="true"
                            [visibleOptions]="10"
                            label="Select Folder"
                            formControlName="listFolder"
                            (selected)="onListFolderSelected($event)">
                          </mdb-select>
                        </div>
                    
                        <div class="md-form remove-bottom-margin">
                          <mdb-select
                            [options]="_lightListsDropdown"
                            [filterEnabled]="true"
                            [visibleOptions]="10"
                            label="Select List"
                            formControlName="list"
                            (selected)="onListSelected($event)"
                            >
                          </mdb-select>
                          <div class="alert alert-warning remove-padding" *ngIf="this.stepForm.get('listForm').get('list').invalid && 
                                                                                 this.stepForm.get('listForm').get('list').touched">
                            Please select a list.
                          </div>
                        </div>

                        <div>
                          <p class="mt-2 text-dark font-weight-bold">And will only occur for contacts with the following criteria...</p>
                        </div>
                        <div class="scrollbar scrollbar-primary force-overflow contact-filter-list" (click)="this.showQueryBuilder()"> 
                          <input type="hidden" #contactFilterCriteria formControlName="contactFilterCriteria" id="contactFilterCriteria">
                          <p *ngIf="contactFilterCriteria.value === ''" class="contact-filter-placeholder remove-bottom-margin">Set Contact Criteria...</p>
                          <p *ngIf="contactFilterCriteria.value !== ''" class="contact-filter-string">{{getContactsFilterSaved()}}</p> 
                        </div>

                      </div>
                    </form>
                  </mdb-step> 

                  <mdb-step name="At this time" [stepForm]="stepForm.get('scheduleForm')">

                      <div [@slide]>
                        <form [formGroup]="stepForm.get('scheduleForm')">                          
                          <div class="row md-form">
                            <div class="col-6">
                              <input
                              autofocus           
                              mdbInput 
                              type="time"
                              id="time" 
                              class="form-control"
                              formControlName="time">
                              <div class="alert alert-warning remove-padding" *ngIf="!stepForm.get('scheduleForm').get('time').valid 
                                                                                    && stepForm.get('scheduleForm').get('time').touched">
                                  Please enter a time
                              </div>
                            </div>
                          </div>
                          <div class="row md-form">
                            <div class="col-6">
                              <mdb-select          
                              [options]="_frequencyDropdown"
                              [filterEnabled]="true"
                              [visibleOptions]="10"
                              label="Frequency"
                              formControlName="frequency">
                              </mdb-select>
                              <div class="alert alert-warning remove-padding" *ngIf="!stepForm.get('scheduleForm').get('frequency').valid 
                                                                                    && stepForm.get('scheduleForm').get('frequency').touched">
                              Please select a frequency
                              </div>
                            </div>
                          </div>
                          <div *ngIf="_showDaysOfWeek" class="row md-form">
                            <div class="col-12"> 
                              <div>
                                <label>Days Of Week</label>
                              </div>  
                              <br/>
                              <div class="row mt2">
                                <div formArrayName="daysOfWeek" *ngFor="let day of daysOfWeekArray.controls; let i = index" class="col-md-4">    
                                  <mdb-checkbox [formControlName]="i">{{_daysOfWeek[i].name}}</mdb-checkbox>  
                                </div>                              
                              </div>
                              <div class="alert alert-warning remove-padding" *ngIf="!stepForm.get('scheduleForm').get('daysOfWeek').valid && 
                                                                                      stepForm.get('scheduleForm').get('daysOfWeek').touched">
                                At least one day must be selected
                              </div>
                            </div>
                          </div>
                          <div *ngIf="_showDayOfMonth" class="row md-form">
                            <div class="col-6">        
                              <mdb-select                              
                                [options]="_dayOfMonthDropdown"
                                [filterEnabled]="true"
                                [visibleOptions]="10"
                                label="Day Of Month"                                
                                formControlName="dayOfMonth">
                              </mdb-select>
                              <div class="alert alert-warning remove-padding" *ngIf="!stepForm.get('scheduleForm').get('dayOfMonth').valid 
                                                                                      && stepForm.get('scheduleForm').get('dayOfMonth').touched">
                                Please select a day of month
                              </div>
                            </div>                            
                          </div>
                        </form>  
                      </div>

                  </mdb-step>

                  <mdb-step name="Select Fields To Include In Extract"  [stepForm]="stepForm.get('fieldsForm')">

                    <form [formGroup]="stepForm.get('fieldsForm')">
                      <div class="row">
                        <div formArrayName="fields" *ngFor="let field of fieldsArray.controls; let i = index" class="col-md-6">    
                          <mdb-checkbox [formControlName]="i">{{_fields[i].name}}</mdb-checkbox>  
                        </div>
                      </div>                     
                      <div class="alert alert-warning remove-padding" *ngIf="!stepForm.get('fieldsForm').valid && stepForm.get('fieldsForm').touched">
                        At least one field must be selected</div>                                            
                    </form>

                  </mdb-step>

                  <mdb-step name="SFTP Credentials"  [stepForm]="stepForm.get('sftpCredsForm')">                   

                    <form [formGroup]="stepForm.get('sftpCredsForm')">
                      <div *ngIf="!this._showAwsSecretFields" class="row">
                        <div class="col-md-6">
                          <mdb-select id="sftp-cred"                        
                              [options]="_awsSecretNamesDropdown"
                              [filterEnabled]="true"
                              [visibleOptions]="100"
                              label="Username"
                              formControlName="awsSecretName">
                            </mdb-select>
                            <div class="alert alert-warning remove-padding" *ngIf="!stepForm.get('sftpCredsForm').get('awsSecretName').valid 
                                                                                    && stepForm.get('sftpCredsForm').get('awsSecretName').touched">
                              Please select a username
                            </div>
                        </div> 
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <button *ngIf="!this._showAwsSecretFields" type="button" mdbBtn color="primary" class="relative waves-light rounded btn-sm" (click)="showAddAwsSecretFields(true)" mdbWavesEffect>Create New Sftp Credentials</button>                          
                        </div>
                      </div>

                      <div *ngIf="_showAwsSecretFields">
                        <div class="row">
                          <div class="col-md-6">
                            <label for="host">Host</label>
                            <input
                              type="text"
                              autocomplete="off"
                              data-lpignore="true"
                              id="host"
                              [ngClass]="{'is-invalid': stepForm.get('sftpCredsForm').get('host').errors && stepForm.get('sftpCredsForm').get('host').touched}"
                              class="form-control"
                              formControlName="host"
                              mdbInput>      
                            <div class="invalid-feedback text-left">Host required</div>                          
                          </div> 
                        </div>
                      
                        <div class="row">    
                          <div class="col-md-6">
                            <label for="username">Username</label>  
                            <input
                              type="text"
                              autocomplete="off"
                              data-lpignore="true"
                              id="username"
                              [ngClass]="{'is-invalid': stepForm.get('sftpCredsForm').get('username').errors && stepForm.get('sftpCredsForm').get('username').touched}"
                              class="form-control"
                              formControlName="username"
                              mdbInput>      
                            <div class="invalid-feedback text-left">Username required</div>
                          </div>
                        </div>
                    
                        <div class="row">
                          <div class="col-md-6">
                            <label for="password">Password</label>
                            <input
                              type="password"
                              autocomplete="off"
                              data-lpignore="true"
                              id="password"
                              [ngClass]="{'is-invalid': stepForm.get('sftpCredsForm').get('password').errors && stepForm.get('sftpCredsForm').get('password').touched}"
                              class="form-control"
                              formControlName="password"
                              mdbInput>      
                            <div class="invalid-feedback text-left">Password required</div>
                          </div>
                        </div>
                        
                        <div class="row">
                          <div class="col-md-6">
                            <label for="password">Confirm Password</label>
                            <input
                              type="password"
                              autocomplete="off"
                              data-lpignore="true"
                              id="confirmPassword"
                              [ngClass]="{'is-invalid': 
                              stepForm.get('sftpCredsForm').get('confirmPassword').errors 
                              && stepForm.get('sftpCredsForm').get('confirmPassword').touched  
                              || stepForm.get('sftpCredsForm').get('confirmPassword').touched
                              && stepForm.get('sftpCredsForm').hasError('mismatch') }"
                              class="form-control"
                              formControlName="confirmPassword"
                              mdbInput>      
                            <div class="invalid-feedback text-left">Passwords don't match</div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">                            
                            <button *ngIf="_showAwsSecretFields && !_savingAwsSecret" [disabled]="stepForm.get('sftpCredsForm').invalid" type="button" mdbBtn color="primary" class="relative waves-light rounded btn-sm" (click)="saveAwsSecret()" mdbWavesEffect>Save Credentials</button>
                            <button *ngIf="_showAwsSecretFields && !_savingAwsSecret" type="button" mdbBtn color="secondary" class="relative waves-light rounded btn-sm" (click)="showAddAwsSecretFields(false)" mdbWavesEffect>Cancel</button>
                            <div *ngIf="_savingAwsSecret" class="spinner-border" role="status"></div>
                          </div>
                        </div>
                      </div>
                    
                    </form>

                  </mdb-step>

                  <mdb-step name="Email Confirmation?" [stepForm]="stepForm.get('emailConfirmationForm')">

                    <form [formGroup]="stepForm.get('emailConfirmationForm')">
                      <div class="shorten">  
                    
                        <div class="switch">
                          <label class="slider-label">
                            <input formControlName="messageFormToggle" type="checkbox" checked>
                            <span class="lever"></span> Notify these Contacts, with the Message
                          </label>
                        </div>    
                    
                        <div *ngIf="this._showNotifyForm" class="offset" [@slide]>
                          <form [formGroup]="stepForm.get('messageForm')">
                    
                            <p class="mt-2 text-dark font-weight-bold">Contacts</p>
                            <div *ngIf="_selectedContactsEmail.length === 0" class="scrollbar scrollbar-primary force-overflow contact-list-empty" (click)="selectContactsModal.show();">                            
                              <p  class="contact-placeholder remove-bottom-margin">Select Contacts</p>
                            </div>
                            <div *ngIf="_selectedContactsEmail.length > 0" class="scrollbar scrollbar-primary force-overflow contact-list text-left" (click)="selectContactsModal.show();">
                            <span *ngFor="let contact of _selectedContactsEmail" class="contact-filter-string text-dark">{{contact.Email}}, </span>
                            </div>
                            <div class="alert alert-warning remove-padding mt-1" *ngIf="_selectedContactsEmail.length === 0">This field is required.</div>
                    
                            <div class="md-form remove-bottom-margin">
                              <mdb-select
                                [options]="this._messageFoldersDropdown"
                                [filterEnabled]="true"
                                [visibleOptions]="10"
                                label="Select Folder"
                                formControlName="messageFolder"
                                (selected)="onMessageFolderSelected($event)"
                                >
                              </mdb-select>
                            </div>
                            <div class="md-form remove-bottom-margin">
                    
                              <div class="row">
                                <div class="col-10">
                                  <mdb-select
                                    [options]="_notifyMessageDropdown"
                                    [filterEnabled]="true"
                                    [visibleOptions]="10"
                                    label="Select Message"
                                    formControlName="message"
                                    >
                                  </mdb-select>
                                </div>
                                <div class="col-2">
                                  <a *ngIf="this._selectedMessageId > 0" (click)="showNotifyContactsMessagePreviewModal()" mdbTooltip="preview message" placement="top">
                                    <mdb-icon far icon="eye" size="lg"></mdb-icon>
                                  </a>
                                </div>
                              </div>
                              <div class="alert alert-warning remove-padding" *ngIf="this.stepForm.get('messageForm').get('message').invalid && 
                                                                                     this.stepForm.get('messageForm').get('message').touched">
                                This field is required.
                              </div>
                            </div>        
                          </form>
                        </div> 
                    
                      </div>
                    </form>

                  </mdb-step>
                  
                </mdb-stepper>

                <div class="row">
                  <div class="col-md-12 text-center">                    
                    <button type="button" mdbBtn color="primary" class="rounded" [disabled]="this.stepForm.invalid || this.triggerDetailsForm.invalid" (click)="saveExtract()">Save</button>
                    <button type="button" mdbBtn color="secondary" class="rounded" (click)="onCancelClicked()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>

<div mdbModal #selectContactsModal="mdbModal" class="modal fade select-contacts-modal" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" (opened)="onOpened()" (closed)="onClosed()">
  <div style="max-width: 80%" class="modal-dialog modal-lg modal-dialog-scrollable-broken" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="selectContactsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="myModalLabel">Select Contacts</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div [formGroup]="stepForm.get('notifiedContactsForm')" class="dropdown-container">          
            <div class="md-form remove-vertical-margin">
              <mdb-select
                [options]="_listFolderDropdownForNotifiedContacts"
                [visibleOptions]="10"
                class="left-dropdown"
                [filterEnabled]="true"
                label="Select Folder"
                formControlName="listFolder"
                (selected)="onlistFolderForNotifiedContactsSelected($event)"                
                >
              </mdb-select>
            </div>          
            <div class="md-form remove-vertical-margin">
              <mdb-select
                [options]="_lightListsDropdownForContacts"
                [visibleOptions]="10"
                class="right-dropdown"
                [filterEnabled]="true"
                label="Select List"
                formControlName="list"
                >
              </mdb-select>
            </div>
          </div>
        </div>        
        <div class="row mt-4">
          <div class="col-md-12">
            <ngx-datatable  
            #contactTable                
            class="material striped"
            [columnMode]="_columnMode.force"
            [rowIdentity]="getId"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="50"
            [scrollbarV]="false"
            [scrollbarH]="true"
            [rows]="_rows"
            [externalPaging]="true"
            [count]="_pageCount"
            [offset]="_pageOffset"
            [limit]="_pageLimit"
            [selected]="_selectedContacts"
            [selectionType]="SelectionType.multiClick"
            (select)="onSelect($event)"
            (page)="getContacts($event)"                
            >                
              <ngx-datatable-column *ngFor="let col of _columns" [width]="150" [headerClass]="'lists-table-headers'" [name]="col.name" [prop]="col.prop">                                        
              </ngx-datatable-column>   
              
              <!-- Custom table footer -->
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="_pageCount" let-pageSize="_pageLimit"
                  let-curPage="_currentPage" let-offset="_pageOffset">
                  <div class="table-custom-footer">
                    <div>
                       Rows: {{ _pageCount }}
                    </div>                        
                    <select (change)="onLimitChange($event.target.value)" class="table-page-limit-select">
                      <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                        [selected]="option.value == _pageLimit">
                        {{option.value}} per page
                    </option>
                    </select>
                    <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                      [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                      [pagerNextIcon]="'datatable-icon-skip'" [page]="_currentPage" [size]="_pageLimit" [count]="_pageCount"
                      [hidden]="!((_pageCount / _pageLimit) > 1)" (change)="getContacts($event.page)">
                    </datatable-pager>
                  </div>
                </ng-template>
              </ngx-datatable-footer>
              
            </ngx-datatable>
            
          </div>
        </div>        
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect (click)="clearAll()">Clear All</button>
        <button type="button" mdbBtn color="secondary" mdbWavesEffect (click)="selectContactsModal.hide()">Done</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #emailPreviewModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="emailPreviewModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-dark" id="modalLabel">Message Preview</h4>
      </div>
      <div class="modal-body">
        <div class="scrollbar scrollbar-primary vh70">
          <div class="animated fadeIn desktop-view force-overflow" [innerHTML]="_messagePreviewHtml | sanitizeHtml"></div>
        </div>        
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" mdbWavesEffect (click)="emailPreviewModal.hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #queryBuilderModal="mdbModal" class="modal fade modal-overflow" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="queryBuilderModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Filter Contacts</h4>
      </div>
      <div class="modal-body">                
        <app-query-builder 
          #queryBuilder
          [_parentListID]="stepForm.get('listForm').get('list').value" 
          [_parentQuery]="stepForm.get('listForm').get('contactFilterCriteria').value"
          [_parentSource]="'createEditTrigger'"
          (queryBuilderSave)="contactsFilterSave($event)"
          [(_parentPageTitle)]="this._pageTitle">            
        </app-query-builder>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
