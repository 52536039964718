import { Injectable } from '@angular/core';
import { ClientETLsDto } from './client-etl-dto';


@Injectable({
  providedIn: 'root'
})

export class ClientETLsDtoAdapter {
  public adaptArray(init: Array<any>): ClientETLsDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(bucket => new ClientETLsDto(bucket))
      : [new ClientETLsDto(init[0])];
    } else {
      return [];
    }
  }
}
