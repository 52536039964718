import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { SelectListOption } from '../../../../_models/system/select-list-option';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableService } from '../../../../_services/tables/table.service';
import { NotificationService } from '../../../../_services/notification.service';
import { ContactPlanService } from '../../../../_services/messaging/automation/contact-plan.service';
import { MessageCodeRequestDto } from '../../../../_models/messaging/automation/message-codes/message-code-request-dto';
import { MessageCodeService } from '../../../../_services/messaging/automation/message-codes/message-code.service';
import { TableType } from '../../constants/table-constants';
import { MessagingHeaderService } from '../../../../_services/messaging/messaging-header/messaging-header.service';

@Component({
  selector: 'app-create-automation',
  templateUrl: './create-automation-modal.component.html',
  styleUrls: ['./create-automation-modal.component.scss']
})
export class CreateAutomationModalComponent implements OnInit {
  title: string;
  inputName: string;
  createAutomationFormGroup: UntypedFormGroup;
  optionList: SelectListOption[] = [];

  constructor(
    private contactPlanService: ContactPlanService,
    private messageCodeService: MessageCodeService,
    private tableService: TableService,
    private dialogRef: MatDialogRef<CreateAutomationModalComponent>,
    private notificationService: NotificationService,
    private messagingHeaderService: MessagingHeaderService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    this.title = 'Create ' + this.data.title.slice(0, -1);
    this.inputName = 'New ' + this.data.title.slice(0, -1) + ' Name';
    this.optionList = this.data.folders;
    this.createAutomationFormGroup = new UntypedFormGroup({
      folderId: new UntypedFormControl(this.data.selectedFolder && this.data.selectedFolder !== 0 ? this.data.selectedFolder : '', Validators.required),
      title: new UntypedFormControl('', Validators.required)
    });
  }

  createAutomation() {
    const condition = TableType[this.data.tableKey] === 4;
    const request = condition ? this.createAutomationFormGroup.value : new MessageCodeRequestDto(this.createAutomationFormGroup.value);
    const automationName = condition ? 'Contact Plan' : 'Contact Criteria';
    const service = condition ? this.contactPlanService : this.messageCodeService;
    if (this.data.data.some(x => x.title.toLowerCase() === request.title.toLowerCase() && x.folderId === request.folderId)) {
      this.notificationService.showWarning(
        `A ${automationName} with this name already exists in the selected folder. ${automationName} names must be unique per folder`
      );

      return;
    }

    service.post(request).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        const folderId = this.createAutomationFormGroup.get('folderId').value;
        this.createAutomationFormGroup.reset();
        this.messagingHeaderService.setFolder(folderId);
        this.dialogRef.close({ isNeedToUpdate: true, folderId });
      } else {
        this.notificationService.showError(executionResultDto.message);
        this.dialogRef.close();
      }
    });
  }
}
