<!--Card-->
<div class="network-card d-flex flex-column align-items-center">
  <div class="social-icons">
    <div [ngSwitch]="network.type">
      <img *ngSwitchCase="_networkTypeTwitter" class="social-icon" src="./assets/images/Icons/TwitterLogo.svg" alt="Twitter" />
      <img *ngSwitchCase="_instagramPage" class="social-icon" src="./assets/images/Icons/Instagram.png" alt="Instagram" />
      <img *ngSwitchCase="_linkedInProfile" class="social-icon" src="./assets/images/Icons/LinkedIn.png" alt="LinkedIn" />
      <img *ngSwitchCase="_facebookProfile" class="social-icon" src="./assets/images/Icons/Meta.png" alt="Facebook" />
      <img *ngSwitchCase="_facebookPage" class="social-icon" src="./assets/images/Icons/Meta.png" alt="Facebook" />
    </div>
  </div>
   
  <div class="d-flex w-100 justify-content-between">
      <div class="float-left">
        <img  class="img" width="24" height="24" src="{{network.pictureUrl}}" />
      </div>
      <div class="network-name">
        <h4>{{network.name}}</h4>
      </div>

      <div *ngIf="_userPermissions.Social.Networks.Delete || _userPermissions.Social.Networks.Edit" class="dropdown float-left pointer" mdbDropdown>
        <img src="./assets/images/Icons/Dots.svg" mdbDropdownToggle alt="Alternate Text" />
        <div class="dropdown-menu dropdown-primary move-dropdown">
          <div *ngIf="network.type === _networkTypeTwitter; then twitterMenu else otherMenu"></div>
          <ng-template #twitterMenu>
            <span class="dropdown-item" (click)="deleteNetwork(network.networkId)">Disconnect Account</span>
          </ng-template>
          <ng-template #otherMenu>
            <span *ngIf="_userPermissions.Social.Networks.Delete" class="dropdown-item" (click)="deleteNetwork(network.networkId)">Disconnect Profile</span>
            <span *ngIf="_userPermissions.Social.Networks.Edit" class="dropdown-item" (click)="refreshNetwork(network.networkId)">Refresh Profile</span>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="network?.subNetworks.length > 0" class="w-100">
      <div *ngIf="!_subNetworksIsCollapsed">
        <div class="py-2 subnetworks"
             *ngFor="let subNetwork of network?.subNetworks">
          <div class="float-left">
            <div [ngSwitch]="subNetwork.type">
              <img *ngSwitchCase="_networkTypeTwitter" width="24" class="img" height="24" src="{{subNetwork.pictureUrl}}" />
              <img *ngSwitchCase="_instagramPage" width="24" class="img" height="24" src="../../../../../assets/images/Icons/Instagram.png" />
              <img *ngSwitchCase="_linkedInProfile" width="24" class="img" height="24" src="{{subNetwork.pictureUrl}}" />
              <img *ngSwitchCase="_facebookProfile" width="24" class="img" height="24" src="{{subNetwork.pictureUrl}}" />
              <img *ngSwitchCase="_facebookPage" width="24" class="img" height="24" src="../../../../../assets/images/Icons/FacebookLogo.svg" />
            </div>
            
          </div>
          <div class="network-name">
            {{subNetwork.name}}
          </div>
          <!-- <div class="network-name" *ngIf="subNetwork.typeTitle !== 'Facebook Page' || subNetwork.typeTitle !== 'Instagram'">
            {{subNetwork.name}}
          </div> -->
          <div class="float-left">
            <div *ngIf="_userPermissions.Social.Networks.Delete || _userPermissions.Social.Networks.Edit" class="dropdown float-left pointer" mdbDropdown>
              <img src="./assets/images/Icons/Dots.svg" mdbDropdownToggle alt="Alternate Text" />
              <div class="dropdown-menu dropdown-primary">
                <span *ngIf="_userPermissions.Social.Networks.Delete" class="dropdown-item" (click)="this.deletePage(subNetwork)">Disconnect Page</span>
                <span *ngIf="_userPermissions.Social.Networks.Edit" class="dropdown-item" (click)="this.refreshPage(subNetwork)">Refresh Page</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow-wrapper" (click)="this.toggleSubNetworksIsCollapsed()">
        <img *ngIf="_subNetworksIsCollapsed" src="./assets/images/Icons/ArrowBottom.svg" alt="Alternate Text" />
        <div class="w-100 subnetworks d-flex justify-content-center align-items-center" *ngIf="!_subNetworksIsCollapsed">
          <img src="./assets/images/Icons/ArrowTop.svg" alt="Alternate Text" />
        </div>
      </div>
    </div>
  </div>
