import { ColumnWidth } from './column-width';

export class ScreenColumnWidth {

  screenName = '';
  columnWidths: ColumnWidth[] = [];

   constructor(screenName: string, columnWidths: ColumnWidth[]) {
    this.screenName = screenName;
    this.columnWidths = columnWidths;
   }
 }