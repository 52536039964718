export class ListFolderDto {
  public id: number;
  public name: string;

  constructor(init: any) {
    this.id = init.FolderID;
    this.name = init.FolderName;
  }
}




