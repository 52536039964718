import { Injectable } from '@angular/core';
import { MessageCodeStepDto } from './message-code-step-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageCodeStepDtoAdapter {

  adapt(item: any): MessageCodeStepDto {
    return new MessageCodeStepDto(item);
  }
}
