
export class ListFieldOptionDto {

  public optionId: number;
  public fieldId: number;
  public optionValue: any;
  public isSelected: boolean;

  constructor(init: any) {
    this.optionId = init.OptionID,
    this.fieldId = init.FieldID,
    this.optionValue = init.OptionValue;
    this.isSelected = init.IsSelected;
  }

}




