export class FacebookConfigurationModelDto {
    clientId: string;
    clientSecret: string;
    graphVersion: string;


constructor(init: any) {
    this.clientId = init.ClientID;
    this.clientSecret = init.ClientSecret;
    this.graphVersion = init.GraphVersion;
  }
}

