import { NetworkTypeEnum } from '../posts/network-type-enum';

export class NetworkDto {

  public id: string;
  public link: string;
  public hideStatus: boolean;
  public pictureUrl: string;
  public name: string;
  public networkId: number;
  public networkDetailId: number;
  public permissionNames: string[];
  public status: boolean;
  public type: NetworkTypeEnum;
  public typeTitle: string;
  public warnings: string[];
  public selected: boolean;
  public username: string;

  public get subNetworks(): NetworkDto[] {
    return this._subNetworks;
  }

  public set subNetworks(networkDtos: NetworkDto[]) {
    this._subNetworks = networkDtos;
  }

  private _subNetworks: NetworkDto[] = [];

  constructor(init: any) {

    this.id = init.ID;
    this.link = init.Link;
    this.pictureUrl = init.PictureUrl;
    this.name = init.Name;
    this.networkId = init.NetworkID;
    this.networkDetailId = init.NetworkDetailID;
    this.permissionNames = init.NetworkPermissions ? init.NetworkPermissions.map(np => np.PermissionName) : [];
    this.status = init.Status;
    this.type = init.Type;
    this.typeTitle = init.TypeTitle;
    this.setSubNetworks(init.SubNetworks);
    this.username = init.Username;


  }

  private setSubNetworks(init: any[]) {
    if (init) {
      init.map(subNetwork => this._subNetworks.push(
        new NetworkDto ({
          ID: subNetwork.ID,
          Link: subNetwork.Link,
          PictureUrl: subNetwork.PictureUrl,
          Name: subNetwork.Name,
          NetworkID: subNetwork.NetworkID,
          NetworkDetailID: subNetwork.NetworkDetailID,
          NetworkPermissions: subNetwork.NetworkPermissions,
          Status: subNetwork.Status,
          Type: subNetwork.Type,
          TypeTitle: subNetwork.TypeTitle,
          Username: subNetwork.Username
        })
      ));

    }
  }
}
