import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ModalDirective, UploadInput, UploadOutput, UploadFile } from 'ng-uikit-pro-standard';
import { UntypedFormGroup } from '@angular/forms';
import { UploaderOptions } from 'ng-uikit-pro-standard/lib/pro/file-input/classes/mdb-uploader.class';
import { Papa } from 'ngx-papaparse';
import { VehicleValidationService } from 'src/app/_services/tools/vehicle-validation.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as fileSaver from 'file-saver';
import * as jsonexport from "jsonexport/dist"
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/_services/notification.service';



@Component({
  selector: 'app-vehicle-validation',
  templateUrl: './vehicle-validation.component.html',
  styleUrls: ['./vehicle-validation.component.scss']
})
export class VehicleValidationComponent implements OnInit {

  _uploadingFiles: boolean = false;
  _uploadFiles: Array<any> = [];
  rows = [];
  _tableData = [];

  ColumnMode = ColumnMode;
  lastIndex = 15;

  _vehicleResultData: Array<any> = [];

  options: UploaderOptions;

  updateFile
  percentageCompleted: number = 0;
  originalExtractLength: number = 0;
  currentProcessedCount: number = 0;
  _showTable: boolean = false;

  // File uploader things
  humanizeBytes: Function;
  dragOver: boolean;
  uploadInput: EventEmitter<UploadInput>;
  formData: FormData;
  uploadForm: UntypedFormGroup;

  batchSize: number = 100;

  @ViewChild('uploadModal', { static: true }) uploadModal: ModalDirective;


  constructor(private papa: Papa,
    private vehicleValidationService: VehicleValidationService,
    private datePipe: DatePipe,
    private notificationService: NotificationService) {

    this.options = { allowedContentTypes: ['.csv'], concurrency: 1, maxUploads: 1 };
  }

  ngOnInit() {
  }

  chooseFileClicked() {
    this._uploadFiles = [];
    this.showFiles();
  }

  showFiles() {
    let files = '';
    for (let i = 0; i < this._uploadFiles.length; i ++) {
      files += this._uploadFiles[i].name;
       if (!(this._uploadFiles.length - 1 === i)) {
         files += ',';
      }
    }
    return files;
  }
  startUpload(): void {

    if (this._uploadFiles.length > 0) {
      this._uploadingFiles = true;

      this._uploadFiles.forEach((file: UploadFile, index: number) => {

        var options = {
          skipEmptyLines: true,
          complete: (results, file) => {

            this.originalExtractLength = 0;
            this.currentProcessedCount = 0;
            this.percentageCompleted = 0;
            this._tableData = [];
            this._vehicleResultData = [];
            this._showTable = false;



            var fullResult: Array<any> = [];

            for (let i = 1; i < results.data.length; i++) {
              var row = {};
              results.data[i].forEach((element, index) => {
                row[results.data[0][index]] = results.data[i][index]
              });
              fullResult.push(row);
            }

            if (!("regNbr" in fullResult[0])){
              this.notificationService.showError("rebNbr is missing from the file, please make sure it is there and spelt correctly!");
              this._uploadingFiles = false;
              return;
            }

            if(fullResult.length > this.batchSize) {
              new Promise(resolve => {
                this.originalExtractLength = fullResult.length;
                this.callApiBatch(0, fullResult, resolve, []);
              }).then((result: any) => {
                this._vehicleResultData = result;
                this.rows = result;
                this._uploadingFiles = false;
                this.closeUploadModal();
                this.notificationService.showSuccess("Records successfully returned.")
              })

            } else {
              this.vehicleValidationService.callVehicleValidatorAPI(fullResult).subscribe(result => {
                this._vehicleResultData = result;
                this.rows = result;
                this._uploadingFiles = false;
                this.closeUploadModal();
                this.notificationService.showSuccess("Records successfully returned.")
              }, (error) => {
                this.notificationService.showError("An error has occured whilst checking your records, please try again or contact an administrator.")
                console.log(error);
                this._uploadingFiles = false;
              })
            }
          }
        };

        this.papa.parse(file.nativeFile,options);


      });
    }
  }

  callApiBatch(batchNo: number, fullResult: Array<any>, resolve: any, resultsSoFar: any): any {
    console.log("Current Batch: " + batchNo + ", batches left: " + (Math.ceil(fullResult.length / this.batchSize) - 1), fullResult.length);
    var nextBatchSize;
    var isFinal = false;
    if ((this.batchSize) > fullResult.length - 1 ) {
      nextBatchSize = fullResult.length - 1;
      isFinal = true;
    } else {
      nextBatchSize = this.batchSize;
    }

    var startIndex = 0;
    var endIndex = nextBatchSize;

    var batch = fullResult.splice(startIndex, endIndex);

    this.vehicleValidationService.callVehicleValidatorAPI(batch).subscribe(result => {
      this.currentProcessedCount = this.currentProcessedCount + batch.length;
      this.percentageCompleted = Math.ceil(this.currentProcessedCount * (100 / this.originalExtractLength));
      batchNo++;
      // var isFinal = ((batchNo) * this.batchSize) > fullResult.length ? true : false;


      if (isFinal) {
        var results = resultsSoFar.concat(result);
        resolve(results);
      } else {
        var results = resultsSoFar.concat(result);

        this.callApiBatch(batchNo, fullResult, resolve, results);
      }

    }, (error) => {
      console.log(error);
      this._uploadingFiles = false;
      resolve([{errorMessage: error}])
    })


  }

  onUploadOutput(output: UploadOutput | any): void {

      if (output.type === 'allAddedToQueue') {
      } else if (output.type === 'addedToQueue') {
        this._uploadFiles.push(output.file); // add file to array when added
      } else if (output.type === 'uploading') {
        // update current data in files array for uploading file
        const index = this._uploadFiles.findIndex(file => file.id === output.file.id);
        this._uploadFiles[index] = output.file;
      } else if (output.type === 'removed') {
        // remove file from array when removed
        this._uploadFiles = this._uploadFiles.filter((file: UploadFile) => file !== output.file);
      } else if (output.type === 'dragOver') {
        this.dragOver = true;
      } else if (output.type === 'dragOut') {
      } else if (output.type === 'drop') {
        this.dragOver = false;
      }
      this.showFiles();
  }

  closeUploadModal() {
    this.uploadModal.hide()
    this._uploadFiles = [];
    this.showFiles();
  }

  buildTableData(showTable: boolean = false) {
    if (!this._tableData.length) {
      this._tableData = [];
      this._vehicleResultData.forEach(element => {
        if(!element) return;

        if(element['motTestExpiryDate']) {
          this._tableData.push(element);
          this._tableData[this._tableData.length - 1].motTestExpiryDate = this.datePipe.transform(new Date(this._tableData[this._tableData.length - 1].motTestExpiryDate), 'dd/MM/yyyy');
        } else {
            var date = this.findLatestDate(element.motTests)

            if(date == "Invalid Date") {
              date = '';
            }

            var data = {
              registration: element.registration,
              make: element.make,
              model: element.model,
              manufactureYear: element.manufactureYear,
              fuelType: element.fuelType,
              primaryColour: element.primaryColour,
              motTestExpiryDate: this.datePipe.transform(date, 'dd/MM/yyyy')
            }
            this._tableData.push(data);
        }

      });

    }

    if (showTable) {
      this._showTable = true;
    }
  }

  findLatestDate(motTests: any) {
    if (!motTests) return '';
    return new Date(Math.max.apply(null, motTests.map(function(e) {
      if(e['expiryDate']) return new Date(e.expiryDate);
      return '';
    })));
  }


  downloadFile() {

    jsonexport(this._vehicleResultData,function(err, csv){
      if(err) return console.log(err);
      var blob = new Blob([csv]);
      fileSaver.saveAs(blob, "RawMotData.csv");
  });
  }

  downloadTableDataFile() {

    if (!this._tableData.length) {
      this.buildTableData();
    }

    jsonexport(this._tableData,function(err, csv){
      if(err) return console.log(err);
      var blob = new Blob([csv]);
      fileSaver.saveAs(blob, "MotData.csv");
  });
  }



}
