import { ListFolderModel } from './list-folder-model';
import { ListTypeEnum } from './list-type-enum';

export class BaseListModel {

  public ListID: number;
  public FolderID: number;
  public FolderName: string;
  public ListName: string;
  public Comment: string;
  public CreateionDate: Date;
  public CreatedByID: number;
  public UpdatedDated: Date;
  public UpdatedByID: number;
  public TableName: string;
  public IsDeleted: boolean;
  public Folder: ListFolderModel;
  public ClientID: number;
  public ListType: ListTypeEnum;

  constructor() {

  }

}
