import { TriggerExecutionDataModel } from './trigger-execution-data-model';

export class TriggerModel {
  public TriggerId: number;
  public Title: string;
  public Description: string;
  public ActionType: string;
  public Parameters: TriggerExecutionDataModel;
  public WorkflowId: number;

  constructor(init: any) {
    this.TriggerId = init.TriggerId;
    this.Title = init.Title;
    this.Description = init.Description;
    this.ActionType = init.ActionType;
    this.Parameters = init.Parameters;
    this.WorkflowId = init.WorkflowId;
  }
}
