import { MessageTypeEnum } from './message-type-enum';

export class MessageGridRecordDto {
  messageId: number;
  messageType: string;
  messageTypeEnum: MessageTypeEnum;
  messageName: string;
  subject: string;
  folderName: string;
  isUnlayer: boolean;
  createDate: Date;
  createdBy: string;
  changeDate: Date;
  changedBy: string;
  previewUrl: string;

  constructor(init: any) {
    this.messageId = init.MessageID;
    this.messageType = init.MessageType;
    this.messageTypeEnum = init.MessageTypeEnum;
    this.messageName = init.MessageName;
    this.subject = init.Subject;
    this.folderName = init.FolderName;
    this.isUnlayer = init.IsUnlayer;
    this.createDate = init.CreateDate;
    this.createdBy = init.CreatedBy;
    this.changeDate = init.ChangeDate;
    this.changedBy = init.ChangedBy;
    this.previewUrl = init.PreviewUrl;
  }
}

export type MessageGridRecordType = {
  messageId: number;
  messageType: string;
  messageTypeEnum: MessageTypeEnum;
  messageName: string;
  subject: string;
  folderName: string;
  isUnlayer: boolean;
  createDate: Date;
  createdBy: string;
  changeDate: Date;
  changedBy: string;
  previewUrl: string;
};
