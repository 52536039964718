import { Injectable } from '@angular/core';
import { MessageCodeGridRecordDto } from './message-code-grid-record-dto';


@Injectable({
  providedIn: 'root'
})

export class MessageCodeGridRecordDtoAdapter {

  adapt(item: any): MessageCodeGridRecordDto {
    return new MessageCodeGridRecordDto(item);
  }
}
