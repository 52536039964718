import { Injectable } from '@angular/core';
import { TriggerTargetModel } from './trigger-target-model';
import { UpdateContactActionInfo } from './update-contact-action-info';

@Injectable({
  providedIn: 'root'
})

export class UpdateContactActionInfoAdapter {
  createEmpty() {
    return new UpdateContactActionInfo({
      Changes: null,
    });
  }
}
