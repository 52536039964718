export class SelectListOption {
  value: number;
  label: string;
  simpleName?: string;
  wildcard?: string;
  icon?: string;

  constructor(value: number, label: string, simpleName?: string, wildcard?: string, icon?: string) {
    this.value = value;
    this.label = label;
    this.simpleName = simpleName;
    this.wildcard = wildcard;
    this.icon = icon;
  }
}
