<app-modal-container [title]="title"
                     [actionButtonName]="'Save'"
                     [buttonValidation]="!subscriptionCommentFormGroup.valid"
                     (action)="save()">

  <form [formGroup]="subscriptionCommentFormGroup">
    <div class="form-group remove-top-margin">
      <label class="input-label ml-0">
        Please provide the reason for contact resubscription
      </label>
      <textarea mdbInput class="form-control" formControlName="comment"></textarea>
    </div>
  </form>

</app-modal-container>


