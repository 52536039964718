export const environment = {
  production: true,
  apiURL: "https://api.uat.voicebox.marketing.delivery/",
  siteURL: 'https://uat.voicebox.marketingdelivery.net/',
  formBuilderURL: 'https://formeditor.uat.voicebox.marketingdelivery.net/formeditor.html',
  formPublishURL: 'https://forms.uat.voicebox.marketingdelivery.net/?',
  emailBuilderURL: 'https://emaileditor.uat.voicebox.marketingdelivery.net/editor.html',

  sessionIdlePeriod: 3600,
  sessionTimeoutPeriod: 30,
  maxClientBuckets: 2,
  vehicleValidationAPIUrl: 'https://vehiclevalidator.marketing.delivery/regnbr/json',
  vehicleValidationAPIKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Ik1hcmtldGluZ0RlbGl2ZXJ5VXNlciIsInBhc3N3b3JkIjoiVSZrUnYzWWhiQ0FCbnhCZ3dwISFQVm5pV09Cak5AUjcjcE9uNzJtIVlYMFlPUUxeQVpObXBxZExkSVQ0VWZmI3l2dFdhbjBJIiwiaWF0IjoxNTg4MjU0MzYzfQ.ALiyLdz-uLXoL2aW3_6b8GJrp7zT3l3QjfO-MMZ6z_Y',
  
  unlayerS3Bucket: 'voiceboxclientstorageuat'
};
