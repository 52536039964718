import { Injectable } from '@angular/core';
import { LightListFieldDto } from './light-list-field-dto';

@Injectable({
  providedIn: 'root'
})

export class LightListFieldDtoAdapter {
  adapt(item: any): LightListFieldDto {
    return new LightListFieldDto(item);
  }

  adaptArray(init: Array<any>): LightListFieldDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new LightListFieldDto(table))
      : [new LightListFieldDto(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new LightListFieldDto({
      FolderID: 0,
      FolderName: '',
      IsDelete: false,
    });
  }
}
