import { DateService } from 'src/app/_services/system/date.service';

export class MessageHistoryDto {

  public contactId: number;
  public messageName: string;
  public subject: string;
  public type: string;
  public listName: string;
  public dateSent: string;
  
  public status: string;

  constructor(init: any) {
    this.contactId = init.ContactID;
    this.messageName = init.MessageName;
    this.subject = init.Subject;
    this.type = init.Type;
    this.listName = init.ListName;
    this.dateSent = init.DateSent.replace('Z', '').replace('T', ' ');
    this.status = init.Status;
  }
}
