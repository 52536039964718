<div class="container-fluid animated fadeIn">
  <div class="row m-4 d-flex justify-content-center">
    <div class="col-md-4">

      <!--Card-->
      <mdb-card cascade="true" narrower="true">

        <!--Card image-->
        <div class="view view-cascade card-header text-center primary-color">
          <h4 class="h4-responsive card-header-text" *ngIf="this.createUserRole === true">Create User Role</h4>
          <h4 class="h4-responsive card-header-text" *ngIf="this.createUserRole === false">Edit User Role</h4>
        </div>
        <!--/Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="text-center">

          <form [formGroup]="this._createEditUserRoleForm" (ngSubmit)="createEditUserRole()">

            <!-- Hidden Input for id -->
            <input
            type="number"
            id="id"
            class="form-control"
            formControlName="id"
            hidden
            mdbInput>
            <!-- /Hidden Input for id -->

            <!--First row-->
            <div class="row">
              <!--First column-->
              <div class="col-md-12">
                <div class="md-form">
                  <input
                    type="text"
                    maxlength="15"
                    id="userRoleName"
                    [ngClass]="{'is-invalid': this._createEditUserRoleForm.get('userRoleName').errors
                      && this._createEditUserRoleForm.get('userRoleName').touched}"
                    class="form-control"
                    formControlName="userRoleName"
                    mdbInput>
                  <label for="userRoleName">Name</label>
                  <div class="invalid-feedback text-left">Name is required.</div>
                </div>
              </div>
            </div>
            <!--/.First row-->
            <!--Second row-->

            <div class="text-center" *ngIf="this.createUserRole === true">
              <button
                *ngIf="!this._submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                [disabled]="!this._createEditUserRoleForm?.valid"
                mdbWavesEffect>Create
              </button>
              <button
                *ngIf="this._submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                disabled
                mdbWavesEffect>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true">
                </span>
                Creating...
              </button>
            </div>

            <div class="text-center" *ngIf="this.createUserRole === false">
              <button
                *ngIf="!this._submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                [hidden]="this.createUserRole !== false"
                [disabled]="!this._createEditUserRoleForm.valid"
                mdbWavesEffect>Update
              </button>

              <button
                *ngIf="this._submitting"
                type="submit"
                mdbBtn
                color="primary"
                class="rounded"
                disabled
                mdbWavesEffect>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true">
                </span>
                Updating...
              </button>

              <button
                  *ngIf="this.createUserRole === false"
                  type="button"
                  mdbBtn
                  color="primary"
                  class="rounded"
                  [disabled]="!this._createEditUserRoleForm?.valid"
                  [routerLink]="['/user-role-permission', this._userRole.id]"
                  mdbWavesEffect>User Role Permissions
                </button>
              </div>

            <!--/.First row-->

          </form>
          <!--Contact Form-->

        </mdb-card-body>
        <!--/.Card content-->

      </mdb-card>
      <!--/.Card-->


    </div>
  </div>
</div>
