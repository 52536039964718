import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ContactService } from '../../../../_services/messaging/lists-and-contacts/contacts/contact.service';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { DataTableColumn } from '../../../../_models/messaging/datatable-column';
import { PagerDtoRequest } from '../../../../_models/messaging/pager-dto-request';
import { TableService } from '../../../../_services/tables/table.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteRestoreModalComponent } from '../delete-restore/delete-restore-modal.component';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../../../_services/notification.service';
import * as moment from 'moment';
import { HelperService } from '../../../../_services/system/helpers/helper.service';
import { TableName, TableType } from '../../constants/table-constants';
import {
  MessageCodeGridRecordDto
} from '../../../../_models/messaging/automation/message-codes/message-code-grid-record-dto';

@Component({
  selector: 'app-trial-run',
  templateUrl: './trial-run-modal.component.html',
  styleUrls: ['./trial-run-modal.component.scss']
})
export class TrialRunModalComponent implements OnInit, AfterViewInit {
  rows: { [prop: string]: any }[] = [];
  exportRows: { [prop: string]: any }[] = [];
  columnsTrialRun: DataTableColumn[] = [];
  columnsToDisplay: string[] = [];
  length: number;
  tableKey = 'trial';
  selectedRow: MessageCodeGridRecordDto;
  exporting: boolean;
  title: string;

  constructor(
    private contactService: ContactService,
    private tableService: TableService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteRestoreModalComponent>,
  ) { }

  ngOnInit(): void {
    this.selectedRow = this.data.row;
    this.title = TableName[this.tableKey];
  }

  ngAfterViewInit() {
    this.tableService.requestTableData(TableType[this.tableKey]);
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  getRows(executionResultDto: ExecutionResultDto, exporting?) {
    let rows: { [prop: string]: any }[];
    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        } else {
          if (exporting) {
            row[key] = '';
          }
        }
      });
      return row;
    });
    return rows;
  }

  trialRun(data) {
    const pagerDtoRequest = new PagerDtoRequest({
      page: data.currentPage,
      pageSize: data.pageLimit,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection
    });

    this.contactService.getMessageValidationReport(pagerDtoRequest, this.selectedRow.messageCodeId)
      .subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.length = executionResultDto.data.Total;
          this.rows = this.getRows(executionResultDto);
          this.columnsTrialRun = executionResultDto.data.Columns.map(column => {
            return { name: column.Name, displayName: column.DisplayName };
          });
          this.columnsToDisplay = this.columnsTrialRun.map((column) => column.name);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
    });
  }

  export() {
    const getContactsPagerDtoRequest = new PagerDtoRequest({
      page: 1,
      pageSize: 1000000,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });

    this.exporting = true;
    this.contactService.getMessageValidationReport(getContactsPagerDtoRequest, this.selectedRow.messageCodeId)
      .subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.exportRows = this.getRows(executionResultDto, true);
          const today = new Date();
          const fileName = `MessageValidationReport-${ this.selectedRow.messageCodeId }_${ today.getDate() }${ today.getMonth() + 1 }${ today.getFullYear() }`;
          this.helperService.exportToCSV(fileName, this.exportRows);
          this.helperService.sentEmailWarningNotificationOfFileDownload("Message Validation Report - " + this.selectedRow.messageCodeId, today);
        } else {
          this.notificationService.showError(executionResultDto.message);
          return;
        }

        this.exporting = false;
      });
  }
}
