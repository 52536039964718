import { Injectable } from '@angular/core';
import { EnquiryMaxDealerInformationDto } from './enquiry-max-dealer-information-dto';


@Injectable({
  providedIn: 'root'
})

export class EnquiryMaxDealerInformationDtoAdapter {
  public adapt(item: any) {
    return new EnquiryMaxDealerInformationDto(item);
  }
}
