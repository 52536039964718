import { Injectable } from '@angular/core';
import { MediaServerBucketModel } from './media-server-buckets-model';

@Injectable({
  providedIn: 'root'
})

export class MediaServerBucketsModelAdapter {
  public adapt(init: any): MediaServerBucketModel {
    return new MediaServerBucketModel(init);
  }
  public adaptArray(init: Array<any>): MediaServerBucketModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(bucket => new MediaServerBucketModel(bucket))
      : [new MediaServerBucketModel(init[0])];
    } else {
      return [];
    }
  }
}
