
export class GetClientAuditTrailLogsRequestModel {
    public DatabaseName: string;
    public DMS: number;
    public Type: string;
    public Page: number;
    public PageSize: number;
    public Filter: string;
  
    constructor(init: any) {
      this.DatabaseName = init.DatabaseName;
      this.DMS = init.DMS;
      this.Type = init.Type;
      this.Page = init.Page;
      this.PageSize = init.PageSize
      this.Filter = init.Filter;
    }
  }