<div class="header pb-2">
  <div class="lead">{{ title }}</div>
  <i class="fas fa-times fa-lg cross" (click)="dialogRef.close({ isNeedToUpdate: false, isActivated: false })"></i>
</div>

<div *ngIf="warning" class="alert alert-warning">Please ensure the uploaded file has a header.</div>

<div [ngClass]="isTableModal ? '' : 'px-4 pb-2 pt-4'">

  <ng-content></ng-content>

  <div *ngIf="actionsOn" class="footer pt-4">
    <button type="button"
            class="btn-cancel-modal"
            data-dismiss="modal"
            (click)="dialogRef.close({ isNeedToUpdate: false, isActivated: false })">
      Close
    </button>
    <button (click)="actionClick()"
            [disabled]="buttonValidation"
            class="btn-save-modal">
      {{ actionButtonName }}
    </button>
  </div>
</div>


