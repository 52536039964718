import { Injectable } from '@angular/core';
import { FolderDto } from './folder-dto';

@Injectable({
  providedIn: 'root'
})

export class FolderDtoAdapter {

  adapt(item: any): FolderDto {
    return new FolderDto(item);
  }

  adaptArray(init: Array<any>): FolderDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new FolderDto(table))
      : [new FolderDto(init[0])];
    } else {
      return [];
    }
  }
}
