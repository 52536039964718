<div class="container-fluid animated fadeIn">
    <div class="row m-4">
      <div class="col-lg-3">

          <section class="section team-section pb-3">

              <div class="row text-center">


                  <div class="col-md-12">
                      <!--Card-->
                      <mdb-card cascade="true" narrower="true">
                          <!--Card image-->
                          <div class="view view-cascade gradient-card-header primary-color narrower">
                              <h4 class="h4-responsive">Client Logo</h4>
                          </div>
                          <!--/Card image-->
                          <!--Card content-->
                          <mdb-card-body cascade="true" class="text-center">
                              <div class="">
                                  <img *ngIf="this._createEditClientForm.get('clientImageUrl').value" 
                                    src="{{this._createEditClientForm.get('clientImageUrl').value}}" 
                                    class="img-fluid z-depth-1"
                                    alt="Client Logo Image">
                                  <img *ngIf="!this._clientId || !this._createEditClientForm.get('clientImageUrl').value" 
                                    src="../../../../../assets/images/default-user-icon.jpg" 
                                    class="img-fluid z-depth-1"
                                    alt="Default Client Logo Image">
                              </div>
                              <button type="button" mdbBtn color="secondary" class="rounded" size="sm" mdbWavesEffect (click)="openMediaServerModal()">Upload</button>
                              <button *ngIf="this._createEditClientForm.get('clientImageUrl').value" (click)="deleteClientLogo()" type="button" mdbBtn color="secondary" class="rounded" size="sm" mdbWavesEffect>Delete</button>
                              <br>
                              <h4>Client</h4>
                              <h6 *ngIf="!this._clientId">{{_createEditClientForm.get('knownAs').value}}</h6>
                              <h6 *ngIf="this._clientId">{{_knownAs}}</h6>

                          </mdb-card-body>
                          <!--/.Card content-->
                      </mdb-card>
                      <!--/.Card-->
                  </div>

              </div>
          </section>

      </div>

      <div class="col-lg-9">

        <!--Card-->
        <mdb-card cascade="true" narrower="true">

          <!--Card image-->
          <div class="view view-cascade gradient-card-header card-header text-center primary-color narrower">
            <h4 class="h4-responsive "
              *ngIf="!this._clientId">Create New Client</h4>
            <h4 class="h4-responsive "
              *ngIf="this._clientId">Edit {{_knownAs}}</h4>
          </div>
          <!--/Card image-->

          <!--Card content-->
          <mdb-card-body cascade="true" class="text-center">

            <form [formGroup]="_createEditClientForm" (ngSubmit)="createEditClient()">

              <!-- Hidden Input for ClientId -->

              <input
              type="number"
              id="clientId"
              class="form-control"
              formControlName="clientId"
              hidden
              mdbInput>

              <!-- /Hidden Input for ClientId -->

              <!--First row-->
              <div class="row">
                <!--First column-->
                <div class="col-md-5">
                  <div class="md-form">
                    <input
                      type="text"
                      id="clientName"
                      [ngClass]="{'is-invalid': _createEditClientForm.get('name').errors
                        && _createEditClientForm.get('name').touched}"
                      class="form-control"
                      formControlName="name"
                      mdbInput>
                    <label for="clientName">Client Name</label>
                    <div class="invalid-feedback text-left">Client Name required.</div>
                  </div>
                </div>

                <div class="col-md-2"></div>

                <!--Second column-->
                <div class="col-md-5">
                  <div class="md-form">
                    <input
                      type="text"
                      id="knownAs"
                      [ngClass]="{'is-invalid': _createEditClientForm.get('knownAs').errors
                        && _createEditClientForm.get('knownAs').touched}"
                      class="form-control"
                      formControlName="knownAs"
                      mdbInput>
                    <label for="knownAs">Known As</label>
                    <div class="invalid-feedback text-left">Known As required.</div>
                  </div>
                </div>
              </div>
              <!--/.First row-->

              <!--Second row-->
              <div class="row">
                  <!--First column-->
                  <div class="col-md-5">
                    <div class="md-form">
                      <input
                        type="text"
                        id="websiteUrl"
                        [ngClass]="{'is-invalid': _createEditClientForm.get('websiteUrl').errors
                          && _createEditClientForm.get('websiteUrl').touched}"
                        class="form-control"
                        formControlName="websiteUrl"
                        mdbInput>
                      <label for="websiteUrl">Website Url</label>
                    </div>
                  </div>

                  <div class="col-md-2"></div>

                  <!--Second column-->
                  <div class="col-md-5">
                    <div class="md-form">
                      <mdb-select
                        id="accountManagerUserId"
                        formControlName="accountManagerUserId"
                        [options]="_accountManagersSelect"
                        [visibleOptions]="10"
                        placeholder="Select User"
                        label="Account Manager"
                        [ngClass]="{'is-invalid': _createEditClientForm.get('accountManagerUserId').errors
                          && _createEditClientForm.get('accountManagerUserId').touched}"
                        editable="true"></mdb-select>
                    </div>
                  </div>
                </div>
              <!--/.Second row-->

              <!--Third row-->
              <div class="row">
                  <!--First column-->
                  <div class="col-md-5">
                    <div class="md-form">
                      <input
                        type="text"
                        id="primaryContactEmail"
                        [ngClass]="{'is-invalid': _createEditClientForm.get('primaryContactEmail').errors
                          && _createEditClientForm.get('primaryContactEmail').touched}"
                        class="form-control"
                        formControlName="primaryContactEmail"
                        mdbInput>
                      <label for="primaryContactEmail">Primary Client Contact Email</label>
                      <div class="invalid-feedback text-left">Primary Client Contact Email required and must be valid.</div>
                    </div>
                  </div>

                  <div class="col-md-2"></div>

                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-md-6">
                        <button
                          *ngIf="this._clientActive"
                          type="button"
                          mdbBtn color="primary"
                          class="rounded client-buttons"
                          (click)="confirmDeactivation.show()"
                          [disabled]="this._changingActiveStatus"
                          mdbWavesEffect>
                          <span
                            *ngIf="_changingActiveStatus"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true">
                          </span>Deactivate
                        </button>
                        <button
                          *ngIf="!this._clientActive && this._clientActive != undefined"
                          type="button"
                          mdbBtn color="primary"
                          class="rounded client-buttons"
                          (click)="activateClient()"
                          [disabled]="this._changingActiveStatus"
                          mdbWavesEffect>
                          <span
                            *ngIf="_changingActiveStatus"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true">
                          </span>Activate
                        </button>
                      </div>
                      <div class="col-md-6">
                        <button
                          *ngIf="this._clientId"
                          type="button"
                          mdbBtn color="primary"
                          class="rounded client-buttons"
                          data-toggle="modal"
                          (click)="basicModal.show()"
                          mdbWavesEffect>Manage Users
                        </button>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <button
                          type="button"
                          mdbBtn color="primary"
                          class="rounded client-buttons"
                          [routerLink]="['/client-management']"
                          mdbWavesEffect>Cancel
                        </button>
                      </div>
                      <div class="col-md-6"
                        *ngIf="this._clientId">
                        <button
                          *ngIf="!_submitting"
                          type="submit"
                          mdbBtn
                          color="primary"
                          class="rounded client-buttons"
                          [disabled]="!_createEditClientForm.valid || this._changingActiveStatus"
                          mdbWavesEffect>Update
                        </button>
                        <button
                          *ngIf="_submitting"
                          type="submit"
                          mdbBtn
                          color="primary"
                          class="rounded client-buttons"
                          disabled
                          mdbWavesEffect>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true">
                          </span>
                          Updating...
                        </button>
                      </div>
                      <div class="col-md-6"
                        *ngIf="!this._clientId">
                        <div class="text-center"
                          *ngIf="!this._clientId">
                          <button
                            *ngIf="!_submitting"
                            type="submit"
                            mdbBtn
                            color="primary"
                            class="rounded client-buttons"
                            [disabled]="!_createEditClientForm.valid"
                            mdbWavesEffect>Create
                          </button>
                          <button
                            *ngIf="_submitting"
                            type="submit"
                            mdbBtn
                            color="primary"
                            class="rounded client-buttons"
                            disabled
                            mdbWavesEffect>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true">
                            </span>
                            Creating...
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <!--/.Third row-->

            </form>
            <!--Contact Form-->

          </mdb-card-body>
          <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->


      </div>

    </div>
  </div>
  <div mdbModal #basicModal="mdbModal" class="modal fade up" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel " [config]="{backdrop: 'static', keyboard: false}"aria-hidden="true">
   <div class="modal-dialog modal-lg" role="document">
       <div class="modal-content">
           <div class="modal-header">
               <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                   <span aria-hidden="true">×</span>
               </button> -->
               <h4 class="modal-title w-100" id="myModalLabel">Manage Client Users</h4>
           </div>
           <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mx-auto">
                <div class="searchIcon text-left md-form">
                  <mdb-icon fas icon="search" class="icon-color"></mdb-icon>
                  <input type="text"
                    id="availableUserSearch"
                    class="form-control"
                    [(ngModel)]="searchAvailableUsersText"
                    mdbInput>
                  <label for="availableUserSearch" class="">Search Full Name</label>
                </div>
                <h5 class="center-text">Available Users</h5>
                <div class="col-md-12 mx-auto md-manager-users scrollbar scrollbar-primary">
                  <ul #list1="mdbSortableList" mdbSortableList [data]="_manageUsersAvailable" [connectedWith]="[list2]" class="list-group w-100 md-drop" (drop)="onDrop($event)">
                      <li *ngFor="let item of _manageUsersAvailable" class="list-group-item" mdbSortable>{{ item.FullName }}</li>
                  </ul>
                </div>
              </div>

              <div class="col-md-6 mx-auto">
                <div class="searchIcon text-left md-form">
                  <mdb-icon fas icon="search" class="icon-color"></mdb-icon>
                  <input type="text"
                    id="assignedUserSearch"
                    class="form-control"
                    [(ngModel)]="searchAssignedUsersText"
                    mdbInput>
                  <label for="assignedUserSearch" class="">Search Full Name</label>
                </div>
                <h5>Assigned Users</h5>
                <div class="col-md-12 mx-auto md-manager-users scrollbar scrollbar-primary">
                  <ul #list2="mdbSortableList" mdbSortableList [data]="_manageUsersAssigned" [connectedWith]="[list1]" class="list-group w-100 md-drop" (drop)="onDrop($event)">
                    <li *ngFor="let item of _manageUsersAssigned" class="list-group-item" mdbSortable>{{ item.FullName }}</li>
                  </ul>
                </div>
              </div>
            </div>
           </div>
           <div class="modal-footer justify-content-center">
               <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide(); saveManagedUsers()" mdbWavesEffect>Close</button>
               <!-- <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="saveManagedUsers();basicModal.hide()"mdbWavesEffect>Save</button> -->
           </div>
       </div>
   </div >
</div >
<div mdbModal #confirmDeactivation="mdb-modal" class="modal fade" id="confirmDeactivation" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Deactivate "{{this._knownAs}}"</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="confirmDeactivation.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="trash" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Are you sure want to deactivate "{{this._knownAs}}"? This will disable all automation scheduled and users will no longer be available to connect to this client.</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deactivateClient(); confirmDeactivation.hide()" mdbWavesEffect>Yes, I am sure
                </a>
                <a type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
                   (click)="confirmDeactivation.hide()" mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>

<div mdbModal #mediaServerModal="mdbModal" class="modal fade right modal-overflow" tabindex="-1" role="dialog" aria-labelledby="mediaServerModalLabel"
   aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeMediaServer()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Select Files From Media Server</h4>
            </div>
            <div class="modal-body">
                <app-media-server *ngIf="_showMediaServer"></app-media-server>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="closeMediaServer()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="setClientImageUrl()" mdbWavesEffect>OK!</button>
            </div>
        </div>
    </div >
</div >
