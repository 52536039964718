import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { MessageService } from 'src/app/_services/messaging/messages/message.service';
import { ContactMessageHistoryDtoRequest } from 'src/app/_models/messaging/messages/contact-message-history-dto-request';
import { MessageHistoryDtoRequest } from 'src/app/_models/messaging/messages/message-history-dto-request';
import { MessageHistoryDtoAdapter } from 'src/app/_models/messaging/messages/message-history-dto-adapter';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { MessageHistoryDto } from 'src/app/_models/messaging/messages/message-history-dto';
import { NotificationService } from 'src/app/_services/notification.service';
import { ContactFieldDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-field-dto';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { LoadingSpinnerService } from '../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-message-history',
  templateUrl: './message-history.component.html',
  styleUrls: ['./message-history.component.scss']
})
export class MessageHistoryComponent implements OnInit {
  @ViewChild('contactMessageHistory', { static: true }) contactMessageHistory: ModalDirective;

  _allColumns: DataTableColumn[] = [];
  _contactFieldDtos: ContactFieldDto[] = [];
  _columns: DataTableColumn[] = [];
  _columnMode = ColumnMode;
  _exporting = false;
  _exportRows: { [prop: string]: any }[] = [];
  _email: string;
  _folderId: number;
  _listId: number;
  _messageHistoryDtos: MessageHistoryDto[] = [];
  _mobile: string;
  _pageTitle = 'Message History';
  _rows: { [prop: string]: any }[] = [];
  _customerHistoryRows: { [prop: string]: any }[] = [];
  _tableHasRows = false;

  // -------------------- Contact Message History Attributes --------------------------------- /////
  _contactMessagingHistoryPageTitle = 'Contact Message History';
  _selectedContactMessageHistoryID: number;
  _contactMessagingHistoryRows: { [prop: string]: any }[] = [];
  _contactMessagingHistoryColumns: DataTableColumn[] = [];
  _contactMessageHistoryDtos: MessageHistoryDto[] = [];
  _contactMessagingHistoryHasRows = false;
  _contactMessagingHistoryExporting = false;
  _contactMessagingHistoryExportRows: { [prop: string]: any }[] = [];

  //Default column sorting value variables
  _sortContactMessagingHistoryColumn = 'DateSent';
  _sortContactMessagingHistoryDirection = 'Descending';

  // variables for the table
  _currentContactMessagingHistoryPage = 1;
  _contactMessagingHistoryPageCount = 0;
  _contactMessagingHistoryPageLimit = 10;
  _contactMessagingHistoryPageOffSet = 0;

  //--------------------------------------------------------------------------------------------/////

  //Default column sorting value variables
  _sortColumn = 'DateSent';
  _sortDirection = 'Descending';

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;
  _pageSizeOptions: number[] = [10, 25, 50, 100];

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private messageHistoryDtoAdapter: MessageHistoryDtoAdapter,
    private notificationService: NotificationService,
    private childPaginationDataService: PaginationDataService,
    private childModelPaginationDataService: PaginationDataService,
    private helperService: HelperService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) { }

  ngOnInit(): void {
    this._folderId = parseInt(this.route.snapshot.paramMap.get('folderId') || "0");
    this._listId = parseInt(this.route.snapshot.paramMap.get('listId') || "0");
    this._email = (this.route.snapshot.paramMap.get('email') || '').trim();
    this._mobile = (this.route.snapshot.paramMap.get('mobile') || '').trim();

    // Get pagination data stored in a session
    this.childPaginationDataService.GetChildPaginationDataFromLocalStorage();
    this.childModelPaginationDataService.GetChildModelPaginationDataFromLocalStorage();

    if (this.childPaginationDataService.childPaginationData.lastPage === this._pageTitle && this.childPaginationDataService.childPaginationData.page) {
      this._currentPage = this.childPaginationDataService.childPaginationData.page;
      this._pageLimit = this.childPaginationDataService.childPaginationData.pageLimit;
    }

    if (this.childPaginationDataService.childPaginationData.lastPage != this._pageTitle) {
      this.childPaginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, '', '', this._pageTitle);
    }

    if (this.childModelPaginationDataService.childModelPaginationData.lastPage === this._contactMessagingHistoryPageTitle && this.childModelPaginationDataService.childModelPaginationData.page) {
      this._currentContactMessagingHistoryPage = this.childModelPaginationDataService.childModelPaginationData.page;
      this._contactMessagingHistoryPageLimit = this.childModelPaginationDataService.childModelPaginationData.pageLimit;
    }

    if (this.childModelPaginationDataService.childModelPaginationData.lastPage != this._contactMessagingHistoryPageTitle) {
      this.childModelPaginationDataService.SetChildModelPaginationDataToLocalStorage(this._currentContactMessagingHistoryPage,
        this._contactMessagingHistoryPageLimit, '', '', this._contactMessagingHistoryPageTitle);
    }
  }

  ngAfterViewInit() {
    this.getMessageHistory();
    this.getContactMessageHistory();
  }

  // give table pagination functionality
  // as the sql behind the data does not do this for us
  paginateTable(event?: any) {

    if (event !== undefined) {
      this._pageLimit = event.pageSize;
      this._currentPage = + event.pageIndex + 1;
      this._pageOffset = + event.pageIndex;
    }

    this.childPaginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);
  }


  // export the data displayed on screen in csv format
  // ensure the report downloaded has a filename that makes sense
  export() {

    this._exporting = true;
    this._exportRows = this._rows;

    const today = new Date();
    const fileName = `${this._pageTitle} ${this._email} ${this._mobile} ${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

    this.helperService.exportToCSV(fileName, this._exportRows);
    this.helperService.sentEmailWarningNotificationOfFileDownload(this._pageTitle, today);

    this._exporting = false;

  }

  isChecked(col) {
    return (
      this._columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  // get a list of all email and sms messages ever sent to the email address and mobile number provided,
  // and sorts them in descending date order by default
  getMessageHistory() {
    this.loadingSpinnerService.loading();
    this.childPaginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);

    const messageHistoryDtoRequest = new MessageHistoryDtoRequest({ email: this._email,
                                                                    mobile: this._mobile,
                                                                    sortColumn: this._sortColumn,
                                                                    sortDirection: this._sortDirection });

    this.messageService.getMessageHistory(messageHistoryDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {

      if (executionResultDto.executionResult === ExecutionResult.success) {

        this._messageHistoryDtos = executionResultDto.data
                                   .map((history: MessageHistoryDto) => this.messageHistoryDtoAdapter.adapt(history));

        if (this._messageHistoryDtos.length > 0) {
          this.populateTable();
        }
      }
      else {
        this.notificationService.showError('Could not retrieve Message History');
      }
      this.loadingSpinnerService.stopLoading();
    });
  }

  // allow the user to change the max number of rows showed in the table
  // set the page back to 1
  onLimitChange(limit: any): void {
    this._pageLimit = parseInt(limit);
    this._currentPage = 1;
    this.getMessageHistory();
  }

  // give the user the ability to sort the table
  // when doing so, reset the page back to the first page
  onSort($event: any) {
    if ($event.sorts[0].dir === 'desc') {
      this._sortDirection = 'Descending';
    }
    else {
      this._sortDirection = 'Ascending';
    }

    this._sortColumn = $event.sorts[0].prop;
    this.getMessageHistory();
    this.setPage(1);
  }

  // populate the table with the message history
  populateTable() {

    this._rows = this._messageHistoryDtos;
    this._pageCount = this._rows.length;
    this._tableHasRows = this._rows.length > 0;

    const headers = Object.keys(this._rows[0]);
    this._allColumns = [];

    //make headers into Title Case, with space.
    headers.forEach(header => {
      var spacedHeader = header.replace(/([a-z])([A-Z])/g, '$1 $2');
      spacedHeader = spacedHeader[0].toUpperCase() + spacedHeader.substring(1);
      const headerObject = { name: spacedHeader, prop: header, visible: true };
      this._allColumns.push(headerObject);
    });
  }

  // allow the user to change the page of the table
  setPage(event: any) {
    this.loadingSpinnerService.loading();
    if (event !== undefined) {
      this._currentPage = event;
      this._pageOffset = this._currentPage - 1;
      this.childPaginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);
    }
    this.loadingSpinnerService.stopLoading();
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this._columns = this._columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this._columns = [...this._columns, col];
    }
  }

  // ------------------------------------ Contact Message History ---------------------- //

  // When Row is selected, bring up modal
  displayContactMessageHistoryModal(event) {
    if (event.type === "click") {
      this._selectedContactMessageHistoryID = event.row.contactId;
      this.getContactMessageHistory();
      this.contactMessageHistory.show();
    }
  }

  // get a list of all email and sms messages specific to the contact id provided,
  // and sorts them in descending date order by default
  async getContactMessageHistory() {
    const contactMessageHistoryDtoRequest = new ContactMessageHistoryDtoRequest({
      contactId: this._selectedContactMessageHistoryID,
      email: this._email,
      mobile: this._mobile,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    this.childModelPaginationDataService.SetChildModelPaginationDataToLocalStorage(this._currentContactMessagingHistoryPage,
      this._contactMessagingHistoryPageLimit, this._sortContactMessagingHistoryColumn, this._sortContactMessagingHistoryDirection,
      this._contactMessagingHistoryPageTitle);

    await this.messageService.getContactMessageHistory(contactMessageHistoryDtoRequest).then(result => {
      if (result.executionResult === ExecutionResult.success) {
        this._contactMessageHistoryDtos = result.data
          .map((history: MessageHistoryDto) => this.messageHistoryDtoAdapter.adapt(history));

          setTimeout(() => {
            this._contactMessagingHistoryRows = [...this._contactMessagingHistoryRows];
          }, 250);

        if (this._contactMessageHistoryDtos.length > 0) this.populateContactMessageHistoryTable();
      }
      else this.notificationService.showError('Could not retrieve Contact Message History');
    });
  }

  populateContactMessageHistoryTable() {

    this._contactMessagingHistoryRows = this._contactMessageHistoryDtos;

    this._contactMessagingHistoryPageCount = this._contactMessagingHistoryRows.length;
    this._contactMessagingHistoryHasRows = this._contactMessagingHistoryRows.length > 0;

    const headers = Object.keys(this._contactMessagingHistoryRows[0]);
    this._contactMessagingHistoryColumns = [];

    //make headers into Title Case, with space.
    headers.forEach(header => {
      var spacedHeader = header.replace(/([a-z])([A-Z])/g, '$1 $2');
      spacedHeader = spacedHeader[0].toUpperCase() + spacedHeader.substring(1);
      const headerObject = { name: spacedHeader, prop: header, visible: true };
      this._contactMessagingHistoryColumns.push(headerObject);
    });
  }

  //Export Table as CSV
  exportContactMessagingHistoryTable() {
    this._contactMessagingHistoryExporting = true;
    this._contactMessagingHistoryExportRows = this._contactMessagingHistoryRows;

    const today = new Date();
    const fileName = `Contact ${this._pageTitle} - ${this._email} - ${this._mobile} - ${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

    this.helperService.exportToCSV(fileName, this._exportRows);

    this._contactMessagingHistoryExporting = false;
  }

  // give the user the ability to sort the table
  // when doing so, reset the page back to the first page
  onContactMessagingHistorySort($event: any) {
    if ($event.sorts[0].dir === 'desc') {
      this._sortContactMessagingHistoryDirection = 'Descending';
    }
    else {
      this._sortContactMessagingHistoryDirection = 'Ascending';
    }

    this._sortContactMessagingHistoryColumn = $event.sorts[0].prop;
    this.getContactMessageHistory();
    this.setContactMessagingHistoryPage(1);
  }

  setContactMessagingHistoryPage(event: any) {
    if (event !== undefined) {
      this._currentContactMessagingHistoryPage = event;
      this._contactMessagingHistoryPageOffSet = this._currentContactMessagingHistoryPage - 1;
      this.childModelPaginationDataService.SetChildModelPaginationDataToLocalStorage(this._currentContactMessagingHistoryPage,
        this._contactMessagingHistoryPageLimit, this._sortContactMessagingHistoryColumn, this._sortContactMessagingHistoryDirection,
        this._contactMessagingHistoryPageTitle);
    }
  }

  // allow the user to change the max number of rows showed in the table
  // set the page back to 1
  onContactMessagingHistoryLimitChange(limit: any): void {
    this._contactMessagingHistoryPageLimit = parseInt(limit);
    this._currentContactMessagingHistoryPage = 1;
    this.getContactMessageHistory();
  }

}
