import { Injectable } from '@angular/core';
import { SendMessageActionInfo } from './send-message-action-info';

@Injectable({
  providedIn: 'root'
})

export class SendMessageActionInfoAdapter {
  adapt(item: any): SendMessageActionInfo {
    return new SendMessageActionInfo(item);
  }

  adaptArray(init: Array<any>): SendMessageActionInfo[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new SendMessageActionInfo(table))
      : [new SendMessageActionInfo(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new SendMessageActionInfo({
      MessageId: 0,
      MessageType: null,
    });
  }
}
