import { Injectable } from '@angular/core';

export class UserPermissionDto {

    public id: number
    public userId: number
    public pageSectionId: number
    public pageId: number
    public permissionKey: number
    

    constructor(init: any) {
      this.id = +init.Id
      this.userId = +init.UserId
      this.pageSectionId = +init.PageSectionId
      this.pageId = +init.PageId
      this.permissionKey = +init.PermissionKey      
    }    
}

@Injectable({
  providedIn: 'root'
})

export class UserPermissionAdapter { 

  adapt(item: any): UserPermissionDto {
    return new UserPermissionDto(item);
  }
}
