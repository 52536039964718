import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ModalDirective, MDBDatePickerComponent, ClockPickerComponent, IMyOptions } from 'ng-uikit-pro-standard';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { QueryBuilderComponent } from 'src/app/views/shared/query-builder/query-builder.component';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { LoadingSpinnerService } from '../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-consent-history',
  templateUrl: './consent-history.component.html',
  styleUrls: ['./consent-history.component.scss']
})
export class ConsentHistoryComponent implements OnInit {
  @ViewChild('subscriptionCommentModal', { static: true }) subscriptionCommentModal: ModalDirective;
  @ViewChildren(MDBDatePickerComponent) datepickers: QueryList<MDBDatePickerComponent>;
  @ViewChildren(ClockPickerComponent) timepickers: QueryList<ClockPickerComponent>;
  @ViewChild('filterHistoryModal', { static: true }) filterHistoryModal: ModalDirective;
  @ViewChild('queryBuilder', { static: true }) queryBuilder: QueryBuilderComponent;

  _allColumns: DataTableColumn[] = [];
  _columns: DataTableColumn[] = [];
  _columnMode = ColumnMode;
  _consentHistoryFormGroup: UntypedFormGroup;
  _exportRows: { [prop: string]: any }[] = [];
  _exporting = false;
  _myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy'
  };
  _userPermissions: PermissionsModel;
  _rows: { [prop: string]: any }[] = [];
  _selectedTarget: string;
  // set a default list id as there is no list id in the table
  // this is so that the query builder for the filter will work
  _selectedListId: number = 0;
  _tableHasRows = false;
  _filterHistoryRules = '';
  _pageTitle = 'Consent History';

  //Default column sorting value variables
  _sortColumn: string = 'UpdatedDate';
  _sortDirection: string = 'Descending';

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;
  _pageSizeOptions: number[] = [10, 25, 50, 100];

  constructor(
    private contactService: ContactService,
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,
    private datePipe: DatePipe,
    public route: ActivatedRoute,
    private loadingSpinnerService: LoadingSpinnerService,
    private helperService: HelperService) {
    route.params.subscribe(params => {
      this._selectedTarget = params['data'];
    });
  }

  ngOnInit() {

    this._userPermissions = this.permissionsService.getPermissionsModel();

    this.initQueryBuilder();
  }

  ngAfterViewInit(){
    this.getConsentFlagHistories();
  }

  ngOnDestroy() {
  }

  export(pageInfo?: any) {
    try {

      const pageNumber = pageInfo ? pageInfo : 1;

      const getHistoryPagerDtoRequest = new PagerDtoRequest({
        page: pageNumber,
        pageSize: this._pageLimit,
        sortColumn: this._sortColumn,
        sortDirection: this._sortDirection
      });

      this._exporting = true;

      this.contactService.getConsentFlagHistories(getHistoryPagerDtoRequest, this._selectedTarget, this._filterHistoryRules).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._exportRows = this.getRows(executionResultDto, true);
          const today = new Date();
          const fileName = `${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

          this.helperService.exportToCSV(fileName, this._exportRows);
          this.helperService.sentEmailWarningNotificationOfFileDownload(this._pageTitle, today);       

        } else {
          this.notificationService.showError(executionResultDto.message);
          return;
        }
        this._exporting = false;
      });
    } catch (error) {
      this.notificationService.showError(error.message);
      this._exporting = false;
    }
  }

  // give table pagination functionality
  // as the sql behind the data does not do this for us
  paginateTable(event?: any) {

    if (event !== undefined) {
      this._pageLimit = event.pageSize;
      this._currentPage = + event.pageIndex + 1;
      this._pageOffset = + event.pageIndex;
    }

    this.getConsentFlagHistories(this._currentPage);
  }

  // optional variables that allow dynamic sorting as data is retrieved from the database
  // rather than all stored client side
  getConsentFlagHistories(pageInfo?: any, sortDirection?: string, sortColumn?: string) {
    this.loadingSpinnerService.loading();
    if (sortDirection !== undefined) this._sortDirection = sortDirection;
    if (sortColumn !== undefined) this._sortColumn = sortColumn;

    const pageNumber = pageInfo ? pageInfo : 1;

    const getHistoryPagerDtoRequest = new PagerDtoRequest({
      page: pageNumber,
      pageSize: this._pageLimit,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    this.contactService.getConsentFlagHistories(getHistoryPagerDtoRequest, this._selectedTarget, this._filterHistoryRules).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._pageCount = executionResultDto.data.length;
        this._pageOffset = pageNumber - 1;

        this._tableHasRows = this._pageCount > 0;

        this._allColumns = this._columns = this._rows = [];

        if (!this._tableHasRows) {
          this.loadingSpinnerService.stopLoading();
          return;
        }

        // hardcoded column headers for now
        this._allColumns = [new DataTableColumn({ name: "Target", prop: "Target", visible: true }),
        new DataTableColumn({ name: "Changed From", prop: "ChangedFrom", visible: true }),
        new DataTableColumn({ name: "Changed To", prop: "ChangedTo", visible: true }),
        new DataTableColumn({ name: "Modified Date", prop: "ModifiedDate", visible: true }),
        new DataTableColumn({ name: "Consent Type", prop: "ConsentType", visible: true }),
        new DataTableColumn({ name: "Action", prop: "Action", visible: true }),
        new DataTableColumn({ name: "Comment", prop: "Comment", visible: true })];
        this._columns = this._allColumns;
        this._rows = this.getRows(executionResultDto);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();
    });
  }

  // add ability to dynamically sort data retrieved from the database by providing the direction and column being sorted
  // we also need to ensure that the captured directions are formatted to work with the API and SQL script
  onSort($event) {
    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      direction = "Descending";
    }
    else {
      direction = "Ascending";
    }
    this.getConsentFlagHistories(undefined, direction, $event.sorts[0].prop);
  }

  getRows(executionResultDto: ExecutionResultDto, exporting?) {
    let rows: { [prop: string]: any }[] = [];
    rows = executionResultDto.data.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        } else {
          if (exporting) {
            row[key] = '';
          }
        }
      });
      return row;
    });
    return rows;
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  isChecked(col) {
    return (
      this._columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  isValid(controlName: string): boolean {
    return this._consentHistoryFormGroup.get(controlName) && !this._consentHistoryFormGroup.get(controlName).invalid &&
      (this._consentHistoryFormGroup.get(controlName).touched || this._consentHistoryFormGroup.get(controlName).dirty);
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this._columns = this._columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this._columns = [...this._columns, col];
    }
  }

  interactionsFilterSave(event) {
    if (!event[1]) {
      this._filterHistoryRules = '';
    } else {
      this._filterHistoryRules = event[1].sql;
    }
    this.getConsentFlagHistories();
    this.filterHistoryModal.hide();
  }

  initQueryBuilder() {
    this.queryBuilder.getListFields(this._selectedListId, 'consent-history');
  }

  // allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    this._pageLimit = parseInt(limit);
    this.getConsentFlagHistories();
  }
}
