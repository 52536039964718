<section class="view intro intro-2">
    <div class="full-bg-img rgba-stylish-strong mask flex-center">
        <div class="container animated fadeIn py-5">
            <!--Grid row-->
            <div class="row  pt-5 mt-3">
                <!--Grid column-->
                <div class="col-md-12">
                    <mdb-card bgColor="register-card">
                        <mdb-card-body>

                            <!--Header-->
                            <form [formGroup]="_codeCheckForm" (ngSubmit)="setupTotp(codeTextBox.value)">
                                <div class="form-header primary-color rounded-lg">
                                    <h3>
                                        <mdb-icon fas icon="lock" class="mt-2 mb-2"></mdb-icon> Set up Two-Factor
                                        Authentication
                                    </h3>
                                </div>

                                <!--Grid row-->
                                <div class="row mt-5 justify-content-around">
                                    <!--Grid column-->
                                    <div class="col-md-5 textLeft">
                                        <!--Body-->
                                        <div class="logo-wrapper waves-light logo-background">
                                            <a tabindex="-1" href="https://marketingdelivery.co.uk/">
                                                <img src="../../../assets/images/MDLogoTransparent.png"
                                                    class="img-fluid">
                                            </a>
                                        </div>
                                        <div class="md-form">
                                            <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                                            <input #codeTextBox formControlName="tfaCode" type="text" id="codeTextBox" class="form-control"
                                                mdbInput autocomplete="off" />
                                            <label for="codeTextBox">Verification Code</label>
                                        </div>
                                        <qrcode class="qrCode" [qrdata]="_qrLink" [width]="256" allowEmptyString="true"
                                            [errorCorrectionLevel]="'M'" id="qrCode"></qrcode>
                                        <label class="explanationLabel">
                                            Please scan this QR code using the authentication app of your choice (i.e
                                            Google
                                            Authenticator).
                                            Once you have added your account to the app, you will then need to type in
                                            the 6
                                            digit code
                                            displayed on the app in order to finish the setup.

                                            <br /> <br />

                                            If you cannot scan the QR code at this time, please use the below key for
                                            manual setup: <br /><br />
                                            <b>{{ _cognitoCode }}</b>
                                        </label>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <div class="col-md-12">
                                        <button *ngIf="!_submitting" mdbBtn
                                            class="waves-effect waves-light btn-primary rounded-lg submitBtn"
                                            mdbWavesEffect>Submit
                                        </button>
                                        <button *ngIf="_submitting" mdbBtn
                                            class="waves-effect waves-light btn-primary rounded-lg submitBtn"
                                            type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Submitting...
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mdb-card-body>
                    </mdb-card>
                </div>
            </div>
        </div>
    </div>
</section>