<form [formGroup]="this.timingFormGroup">

  <div class="md-form remove-top-margin">
    <div class="form-check">
      <input 
        type="radio" 
        class="form-check-input" 
        id="immediately-check" 
        [value]="'immediately'"
        formControlName="timingType">
      <label class="form-check-label" for="immediately-check">Immediately</label>
    </div>
  </div>

  <div class="md-form remove-top-margin">
    <div class="form-check">
      <input 
        type="radio" 
        class="form-check-input" 
        id="period-of-time-check" 
        [value]="'periodOfTime'"
        formControlName="timingType">
      <label class="form-check-label" for="period-of-time-check">A period of time after the Trigger occurs</label>
    </div>
  </div>

  <form [formGroup]="this.periodOfTimeFormGroup">

    <div class="offset">
      <div class="num-of-units-container" *ngIf="this._showPeriodOfTime" [@slide]>
        <div class="md-form remove-vertical-margin left-form-input">
          <input 
            mdbInput 
            type="number" 
            class="form-control"
            id="no-of"
            min="0"
            maxlength="500"
            formControlName="numberOf">
          <label class="remove-bottom-margin" for="no-of">No. Of</label>
        </div>
        <div class="md-form remove-vertical-margin right-form-input">
          <mdb-select
            [options]="_minsHoursDaysWeeks"
            [filterEnabled]="true"
            [visibleOptions]="10"
            label="Mins/Hours/Days/Weeks"
            formControlName="minsHoursDaysWeeks">
          </mdb-select>
        </div>
      </div>
      <div class="alert alert-warning remove-padding mt-3" 
        *ngIf="this.createEditTriggerComponent.showValidationError(['timingFormGroup', 'periodOfTimeFormGroup', 'numberOf']) || 
        this.createEditTriggerComponent.showValidationError(['timingFormGroup', 'periodOfTimeFormGroup', 'minsHoursDaysWeeks'])">
        These fields are required.
      </div>
      <div *ngIf="_showTiming" [@slide]>
        <p class="mt-2 text-dark font-weight-bold remove-vertical-margin">Later, at</p>
        <div class="md-form remove-vertical-margin">
          <input
            mdbInput 
            type="time" 
            class="form-control custom-date-time-height shorten-time"
            formControlName="time">
        </div>
        <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['timingFormGroup', 'periodOfTimeFormGroup', 'time'])">This field is required.</div>
      </div>    
    </div>

  </form>

 

  

</form>
