import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-find-and-replace-modal',
  templateUrl: './find-and-replace-modal.component.html',
  styleUrls: ['./find-and-replace-modal.component.scss']
})
export class FindAndReplaceModalComponent implements OnInit {
  title = 'Find and Replace in Message Names';
  findReplaceFormGroup: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<FindAndReplaceModalComponent>) { }

  ngOnInit(): void {
    this.findReplaceFormGroup = new UntypedFormGroup({
      find: new UntypedFormControl(''),
      replace: new UntypedFormControl('')
    });
  }

  findAndReplace(): void {
    const formData = {
      find: this.findReplaceFormGroup.get('find').value,
      replace: this.findReplaceFormGroup.get('replace').value,
    };

    this.dialogRef.close(formData);
  }
}
