import { AwsSecretTagDto } from "./aws-secrets-tag-dto";

export class AwsSecretSftpDto {
  
  public awsSecretName: string = '';
  public host: string = '';
  public username: string = '';   
  public password: string = '';
  public tag: AwsSecretTagDto 

  constructor(init: { awsSecretName: string, host: string, username: string, password: string, tag: AwsSecretTagDto }) {
    this.awsSecretName = init.awsSecretName;
    this.host = init.host;
    this.username = init.username;    
    this.password = init.password;
    this.tag = init.tag;
  }  
}