import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model';
import { ListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model.adapter';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ListFieldControlTypeModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-field-control-type-model';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { NotificationService } from 'src/app/_services/notification.service';
import { ListFieldsModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-fields-model';
import { ListFieldsModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-fields-model.adapter';
import { ListFieldTypeModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-field-type-model';
import { ListFieldOptionModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-field-option-model.adapter';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { DatahubFieldsRequestDto } from 'src/app/_models/admin/clients/datahub-fields-request-dto';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { ListTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/lists/list-type-enum';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { DatahubFieldDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/datahub-field-dto-adapter';
import { DatahubFieldDto  } from 'src/app/_models/messaging/lists-and-contacts/lists/datahub-field-dto';
import {
  ListFolderService
} from '../../../../../_services/messaging/lists-and-contacts/list-folders/list-folder.service';

@Component({
  selector: 'app-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['./edit-list.component.scss']
})
export class EditListComponent implements OnInit {

  @ViewChild('addEditFieldModal', { static: true }) _addEditFieldModal: ModalDirective;
  @ViewChild('addCsvListModal', { static: true }) _addCsvListModal: ModalDirective;
  @ViewChild('fieldTypesDropdown', {static: true}) _fieldTypesDropdownElement: any;

  _listID: number;
  _list: ListModel;
  _foldersDropdown: Array<any> = [];
  _allFieldTypes: Array<ListFieldTypeModel> = [];
  _fieldTypesDropdown: Array<any> = [];
  _allControlTypes: Array<ListFieldControlTypeModel> = [];
  _availableControlTypes: Array<ListFieldControlTypeModel> = [];
  _fieldControlTypeDropdown: Array<any> = [];
  _fieldOptions: Array<string> = [];
  _selectedFieldOptionIndex: number;
  _showFieldOptions: boolean = false;
  _editingIndex: number;
  _deletingIndex: number;
  _editing = false;
  _editingDefaultField = false;
  _saving = false;
  _allDefaultFieldsValue: Array<ListFieldsModel> = [];
  _allCreatedFieldsValue: Array<ListFieldsModel> = [];
   _datahubFields: Array<any> = [];
  _datahubFieldDtos: Array<DatahubFieldDto> = [];
  _resettingListFieldsData: boolean = false;
  _userPermissions: PermissionsModel;
  _fieldTypeIdForText = 4;
  _selectedUnmappedFieldId = 0;
  _showDatahubFields = false;
  _datahubFieldsPopulated = false;
  _showDatahubFieldsCheckBox = false;
  _allFieldNamesForAllTables: Array<string> = [];

  get _allDefaultFields() {
    return this._allDefaultFieldsValue;
  }
  set _allDefaultFields(fields: Array<ListFieldsModel>) {
      this._allDefaultFieldsValue = fields;
  }
  get _allCreatedFields() {
    return this._allCreatedFieldsValue;
  }
  set _allCreatedFields(fields: Array<ListFieldsModel>) {
    this._allCreatedFieldsValue = fields;
    if (!this._resettingListFieldsData) {
      this._list.ListFields = this._allDefaultFields.concat(this._allCreatedFieldsValue);
    }
  }

  // Edit List Form

  _editListForm = this.fb.group({
    listName: ['', Validators.required],
    listFolder: ['', Validators.required],
    listComment: [''],
  });

  // Add Field Form

  _addEditFieldForm = this.fb.group ({
    fieldName: ['', [Validators.required, Validators.maxLength(255)]],
    datahubFieldName: [''],
    isDatahubField: [false],
    fieldType: ['', Validators.required],
    fieldControlType: ['', Validators.required],
    fieldOptions: [''],
  });

  _addCsvListForm = this.fb.group ({
    csvList: ['', Validators.required]
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private listsService: ListsService,
    private listFolderService: ListFolderService,
    private listModelAdapter: ListModelAdapter,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private listFieldsModelAdapter: ListFieldsModelAdapter,
    private listFieldOptionModelAdapter: ListFieldOptionModelAdapter,
    private permissionsService: PermissionsService,
    private clientService: ClientManagementService,
    private localStorageService: LocalStorageService,
    private datahubFieldDtoAdapter: DatahubFieldDtoAdapter
    ) { }

  ngOnInit() {
    this.route.params.subscribe( params =>
      this._listID = params['listId']
    );

    this._userPermissions = this.permissionsService.getPermissionsModel();

    this.getListById();
    this.populateFolderDropdown();
    this.getFieldControlTypes();
    this.getFieldTypes();
    this.subscribeToDatahubFieldName();
    this.getAllFieldNamesForAllTables();
  }

  backClicked() {
    this.location.back();
  }

  getListById() {
      this.listsService.getListById(this._listID).subscribe(result => {
        this._list = this.listModelAdapter.adapt(result.data[0]);
        this.populateEditListForm();
      });
    }

  populateFolderDropdown() {
    this.listFolderService.getAll().subscribe((result: ExecutionResultDto) => {
      result.data.forEach((folder: ListFolderModel) => {
        this._foldersDropdown = [... this._foldersDropdown, {
          value: folder.FolderID,
          label: folder.FolderName,
        }];
      });
    });
  }

  populateEditListForm() {
    this._editListForm.get('listName').setValue(this._list.ListName);
    this._editListForm.get('listFolder').setValue(this._list.FolderID);
    this._editListForm.get('listComment').setValue(this._list.Comment);

    const listFields = this._list.ListFields;
    this.addListFieldsToForm(listFields);
  }

  getAllFieldNamesForAllTables() {
    this.listsService.getAllFieldNamesForAllTables().subscribe((result: ExecutionResultDto) => {
      this._allFieldNamesForAllTables = result.data;
    });
  };


  addListFieldsToForm(listFields: Array<ListFieldsModel>) {
    this._resettingListFieldsData = true;
    this._allDefaultFields = [];
    this._allCreatedFields = [];
    this._resettingListFieldsData = false;
    listFields.forEach((field: ListFieldsModel) => {
      if (field.IsDefault) {
        this._allDefaultFields.push(field);
      } else {
        this._allCreatedFields.push(field);
      }
    });
  }

  filterSqlType(type?: string) {
    if (type) return filter => filter.SqlType == type;
    else return filter => filter.FieldTypeName.length > 0;
  }

  populateFieldTypesDropdown(filter?: string) {
    this._fieldTypesDropdown = [];
    this._allFieldTypes.filter(this.filterSqlType(filter)).forEach(fieldType => {
      this._fieldTypesDropdown = [... this._fieldTypesDropdown, {
        value: fieldType.FieldTypeID,
        label: fieldType.FieldTypeName,
        type: fieldType.SqlType
      }];
    });
  }

  populateDataHubFieldType() {
    this._fieldTypesDropdown = [];

    const dataHubFieldID = this._addEditFieldForm.controls.datahubFieldName.value;
    const dataHubDataType = this._datahubFieldDtos.find(field => field.id === dataHubFieldID).dataType;

    if (dataHubDataType == 'datetime' || dataHubDataType == 'date') {
      this._addEditFieldForm.get('fieldType').enable();
      this.populateFieldTypesDropdown('datetime');
    }
    else {
      this._addEditFieldForm.get('fieldType').disable();
      this.populateFieldTypesDropdown();
    }
  }

  populateFieldControlTypesDropdown() {
    this._addEditFieldForm.get('fieldControlType').enable();
    const fieldType = this._addEditFieldForm.controls.fieldType.value;
    const controlTypes = this._allControlTypes.find(controlType => controlType.FieldTypeID === fieldType);
    this._fieldControlTypeDropdown = [];

    controlTypes.ControlType.forEach(controlType => {
     this._fieldControlTypeDropdown = [... this._fieldControlTypeDropdown, {
        value: controlType.ControlTypeID,
        label: controlType.ControlTypeName,
      }];
    });

    if (controlTypes.ControlType.length === 1) {
      this._addEditFieldForm.get('fieldControlType').setValue(controlTypes.ControlType[0].ControlTypeID);
      this._addEditFieldForm.get('fieldControlType').disable();
    }
    this.showFieldOptions();
  }

  getFieldTypes() {
    this.listsService.getAllFieldTypes().subscribe(result => {
     result.data.forEach((fieldType: ListFieldTypeModel) => {
       this._allFieldTypes.push(fieldType);
     });
     this.populateFieldTypesDropdown();
    });
  }

  getFieldControlTypes() {
    this.listsService.getAllFieldControlTypes().subscribe(result => {
      result.data.forEach((controlType: ListFieldControlTypeModel) => {
        this._allControlTypes.push(controlType);
      });
    }, error => {
      console.log(error);
    });
  }

  addFieldsInit() {
    this._editing = false;
    this._showDatahubFields = false;
    this._showDatahubFieldsCheckBox = true;
    this._addEditFieldForm.get('fieldName').enable();
    this._addEditFieldForm.get('fieldType').enable();
    this._addEditFieldForm.get('fieldControlType').enable();
    this._addEditFieldModal.show();
  }

  subscribeToDatahubFieldName(){
    this._addEditFieldForm.get('datahubFieldName').valueChanges.subscribe((fieldId: number) => {
      if (!fieldId) return;

      this._selectedUnmappedFieldId = fieldId;
      const datahubField = this._datahubFieldDtos.find(x => x.id === this._selectedUnmappedFieldId);
      this._addEditFieldForm.get('fieldName').setValue(datahubField.columnName);
      this.populateDataHubFieldType();
      this.setDatahubFieldDataType(datahubField.dataType);
    });
  }


  //get all datahub fields from separate Datahub database
  getDataHubFields() {

    const clientId = parseInt(this.localStorageService.getCurrentClientId);

    const unmappedOutputFieldsInputDto = new DatahubFieldsRequestDto(clientId, this._listID, ListTypeEnum[this._list.ListType]);

    this.clientService.getDatahubFields(unmappedOutputFieldsInputDto).subscribe(executionResultDto => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._datahubFieldDtos = executionResultDto.data.map((x, index) => (this.datahubFieldDtoAdapter.adapt(++index, x)));
        this._datahubFields = this._datahubFieldDtos
                              .filter(x => this._allCreatedFields
                              .filter(y => y.IsDatahub && y.FieldName !== x.columnName))
                              .map(x => ({ value: x.id, label: x.columnName }));
        this._datahubFieldsPopulated = true;
      }
    });
  }

  addCsvListInit() {
    this._addCsvListModal.show();
  }

  importCsvList() {
    //remove trailing comma, if any
    const csvList = this._addCsvListForm.get('csvList').value.replace(/,\s*$/, '').split(',');

    csvList.forEach(value => {
      const trimmedValue = value.trim();
      if (trimmedValue.length > 0) {
        this._fieldOptions.push(trimmedValue);
      }
    });

    this._addCsvListModal.hide();

  }

  addEditField() {
    var validFieldName = true;

    if (!this._editing) {
      if (this._allDefaultFields.some(x => x.DisplayName.toLowerCase() === (this._addEditFieldForm.controls.fieldName.value && this._addEditFieldForm.controls.fieldName.value.trim().toLowerCase())) ||
          this._allCreatedFields.some(x => x.DisplayName.toLowerCase() === (this._addEditFieldForm.controls.fieldName.value && this._addEditFieldForm.controls.fieldName.value.trim().toLowerCase()))) {
            this.notificationService.showError('This Field Name is already used in this List');
            validFieldName = false;
      }
    }

    if (this._allFieldNamesForAllTables.some(x => x.toLowerCase() === (this._addEditFieldForm.controls.fieldName.value && this._addEditFieldForm.controls.fieldName.value.trim().toLowerCase()))) {
      this.notificationService.showError('This Field Name is already used in the Database in a System Table. Please enter a different Field Name');
      validFieldName = false;
    }

    if (validFieldName) {

      const duplicates = this.getDuplicates(this._fieldOptions);

      if (duplicates.length > 0) {
        this.notificationService.showError(`Values in the Field Options list must be unique - duplicates found: ${duplicates.join(', ')}`);
        return;
      }

      if (this._editing) {
        var editedField = this._editingDefaultField ? this._allDefaultFields[this._editingIndex] : this._allCreatedFields[this._editingIndex];
        editedField.DisplayName = this._addEditFieldForm.controls.fieldName.value.trim();
        editedField.FieldTypeID = this._addEditFieldForm.controls.fieldType.value;
        editedField.ListFieldType = this._allFieldTypes.find(field => field.FieldTypeID === editedField.FieldTypeID);
        editedField.ControlTypeID = this._addEditFieldForm.controls.fieldControlType.value;
        this._allControlTypes.forEach(listControlType => {
          if (editedField.FieldTypeID === listControlType.FieldTypeID) {
            editedField.ControlType = listControlType.ControlType.find(
              controlType => controlType.ControlTypeID === editedField.ControlTypeID );
          }
        });

        editedField.ListFieldOptions = [];
        this._fieldOptions.forEach(fieldOption => {
          const listFieldOption = this.listFieldOptionModelAdapter.createEmpty();
          listFieldOption.OptionValue = fieldOption.trim();
          listFieldOption.FieldID = editedField.FieldID;
          editedField.ListFieldOptions.push(listFieldOption);
        });

        if (this._editingDefaultField) {
          this._allDefaultFields[this._editingIndex] = editedField;
          this._allDefaultFields = this._allDefaultFields;
        } else {
          this._allCreatedFields[this._editingIndex] = editedField;
        }

        this._editing = false;
        this._addEditFieldForm.get('fieldType').enable();
        this._addEditFieldForm.get('fieldControlType').enable();
      } else {
        var newField: ListFieldsModel = this.listFieldsModelAdapter.createEmpty();
        var newIndex = 0;
        newField.DisplayName = this._addEditFieldForm.controls.fieldName.value.trim();
        newField.ListID = this._list.ListID;
        newField.FieldTypeID = this._addEditFieldForm.controls.fieldType.value;

        if (this._showDatahubFields) {
          newField.FieldName = this._addEditFieldForm.get('fieldName').value;
          newField.IsSystem = newField.IsDatahub = true;
        }
        this._allDefaultFields.forEach(listField => {
          if (listField.ColumnIndex >= newIndex) {
            newIndex = listField.ColumnIndex + 1;
          }
        });
        this._allCreatedFields.forEach(listField => {
          if (listField.ColumnIndex >= newIndex) {
            newIndex = listField.ColumnIndex + 1;
          }
        });
        newField.ColumnIndex = newIndex;

        newField.ListFieldType = this._allFieldTypes.find(
          field => field.FieldTypeID === newField.FieldTypeID);

        newField.ControlTypeID = this._addEditFieldForm.controls.fieldControlType.value;
        this._allControlTypes.forEach(listControlType => {
          if (newField.FieldTypeID === listControlType.FieldTypeID) {
            newField.ControlType = listControlType.ControlType.find(
              controlType => controlType.ControlTypeID === newField.ControlTypeID );
          }
        });

        this._fieldOptions.forEach(fieldOption => {
          const listFieldOption = this.listFieldOptionModelAdapter.createEmpty();
          listFieldOption.OptionValue = fieldOption.trim();
          newField.ListFieldOptions.push(listFieldOption);
          });

        this._allCreatedFields.push(newField);
        this._allCreatedFields = this._allCreatedFields;
      }
      if (this._showFieldOptions === false) {
        if (editedField) {
          editedField.ListFieldOptions = [];
        } else {
          newField.ListFieldOptions = [];
        }
      }
      this._addEditFieldModal.hide();
    }
  }

  deleteRestoreField(index) {
    if (this._userPermissions.Messaging.ListsAndContactLists.Delete) {
      if (this._deletingIndex || this._deletingIndex === 0) {
        this._allCreatedFields.splice(this._deletingIndex, 1);
        this._deletingIndex = null;
        this._allCreatedFields = this._allCreatedFields;
      } else {
        this._allCreatedFields[index].IsDeleted = !this._allCreatedFields[index].IsDeleted;
        this._allCreatedFields = this._allCreatedFields;
      }
    } else {
      this.notificationService.showError('You do not have Delete Permissions on Lists.');
    }
  }

  editField(index: number, isDefaultField: boolean, isDatahubField: boolean) {

    this._editingDefaultField = isDefaultField;
    this._editing = true;
    this._editingIndex = index;
    const fieldToEdit = this._editingDefaultField ? this._allDefaultFields[index] : this._allCreatedFields[index];

    this._showDatahubFieldsCheckBox = false;

    this._addEditFieldForm.get('fieldName').setValue(fieldToEdit.DisplayName);
    this._addEditFieldForm.get('fieldType').setValue(fieldToEdit.FieldTypeID);
    this._addEditFieldForm.get('fieldControlType').setValue(fieldToEdit.ControlTypeID);

    fieldToEdit.ListFieldOptions.forEach(fieldOption => {
      this._fieldOptions.push(fieldOption.OptionValue);
    });
    this.populateFieldControlTypesDropdown();
    this._addEditFieldForm.get('fieldType').disable();

    if (this._editingDefaultField || isDatahubField) {
      this._addEditFieldForm.get('fieldName').disable();
      this._addEditFieldForm.get('fieldControlType').enable();
    } else {
      this._addEditFieldForm.get('fieldName').enable();
    }

    this._addEditFieldModal.show();
  }

  editFieldName(index: number, $event) {
    this._editingIndex = index;
    this._allCreatedFields[this._editingIndex].DisplayName = $event.target.value;
  }

  clearFieldOptions() {
    this._fieldOptions = [];
  }

  showFieldOptions() {
    const fieldTypeID = this._addEditFieldForm.get('fieldType').value;
    const controlTypeID = this._addEditFieldForm.get('fieldControlType').value;

    switch (fieldTypeID) {
      case 4:
        if (controlTypeID === 3 || controlTypeID === 4 || controlTypeID === 5) {
          this._showFieldOptions = true;
        } else {
          this._showFieldOptions = false;
        }
        break;
      case 5:
        if (controlTypeID === 3 || controlTypeID === 5) {
          this._showFieldOptions = true;
        } else {
          this._showFieldOptions = false;
        }
        break;
      case 6:
        if (controlTypeID === 3 || controlTypeID === 5) {
          this._showFieldOptions = true;
        } else {
          this._showFieldOptions = false;
        }
        break;
      case 7:
        if (controlTypeID === 3 || controlTypeID === 5) {
          this._showFieldOptions = true;
        } else {
          this._showFieldOptions = false;
        }
        break;
      default:
        this._showFieldOptions = false;
        break;
    }
  }

  addFieldOption($event) {
    const fieldOptionsValue = this._addEditFieldForm.get('fieldOptions').value;
    const fieldType = this._addEditFieldForm.get('fieldType').value;
    if ($event.type === 'click' || $event.key === 'Enter') {
      if (fieldOptionsValue === '' || fieldOptionsValue === null || fieldOptionsValue === undefined) {
        this.notificationService.showWarning('Cannot add an empty Field Option.');
      } else {
        if ((fieldType === 6 ||
             fieldType === 2 ||
             fieldType === 5 ||
             fieldType === 3) && !fieldOptionsValue.match(/^[-+]?[0-9,.<>:#£$%()]+$/)) {
              this.notificationService.showError('Field Option must be a number.');
            } else {
              const fieldOption = fieldOptionsValue;
              this._fieldOptions.push(fieldOption);
              this._addEditFieldForm.get('fieldOptions').reset();
            }
      }
    }
  }

  fieldOptionSelect(index) {
    if (this._selectedFieldOptionIndex === undefined || this._selectedFieldOptionIndex !== index) {
    this._selectedFieldOptionIndex = index;
    } else {
      this._selectedFieldOptionIndex = undefined;
    }
  }

  moveFieldOption(from, to) {
    // remove `from` item and store it
    var f = this._fieldOptions.splice(from, 1)[0];
    // insert stored item into position `to`
    this._fieldOptions.splice(to, 0, f);

    if (from > to) {
      this._selectedFieldOptionIndex --;
    } else {
      this._selectedFieldOptionIndex ++;
    }
  }

  removeFieldOption() {
    if (this._selectedFieldOptionIndex || this._selectedFieldOptionIndex === 0) {
      this._fieldOptions.splice(this._selectedFieldOptionIndex, 1);
    }
  }

  initAddingCustomFieldsMode(){
    this._addEditFieldForm.reset();
    this._addEditFieldForm.get('fieldType').enable();
    this._addEditFieldForm.get('fieldControlType').enable();
    this._addEditFieldForm.get('fieldName').setValidators([Validators.required, Validators.maxLength(255)]);
    this._addEditFieldForm.get('fieldName').updateValueAndValidity();
    this._addEditFieldForm.get('datahubFieldName').setValidators(null);
    this._addEditFieldForm.get('datahubFieldName').updateValueAndValidity();
    this.populateFieldTypesDropdown();
  }

  initAddingDatahubFieldsMode(){
    this._addEditFieldForm.get('fieldType').disable();
    this._addEditFieldForm.get('fieldName').setValidators(null);
    this._addEditFieldForm.get('datahubFieldName').setValidators([Validators.required]);
    this._addEditFieldForm.get('fieldName').updateValueAndValidity();
    this._addEditFieldForm.get('datahubFieldName').updateValueAndValidity();
    this._fieldOptions = [];
  }

  addEditFieldModalClosed() {
    this.initAddingCustomFieldsMode();

    this._fieldOptions = [];
    this._editing = false;
    this._showFieldOptions = false;
  }

  addCsvListModalClosed() {
    this._addCsvListForm.reset();
  }

  confirmDeleteModalClosed() {
    this._deletingIndex = null;
  }

  saveList() {
    this._saving = true;
    this._list.ListName = this._editListForm.get('listName').value;
    this._list.FolderID = this._editListForm.get('listFolder').value;
    this._list.Comment = this._editListForm.get('listComment').value;

    this.listsService.createEditList(this._list).subscribe(result => {
      this.notificationService.showSuccess(result.message);
      this._saving = false;
      this.getListById();
    }, error => {
      this._saving = false;
      console.log(error);
    });
  }

  getDuplicates(array: string[]) {
    const arrayWithoutDuplicates = [...new Set(array)];

    let duplicates = [...array];
    arrayWithoutDuplicates.forEach((item) => {
      const i = duplicates.indexOf(item);
      duplicates = duplicates.slice(0, i).concat(duplicates.slice(i + 1, duplicates.length));
    });

    return duplicates;
  }

  setDatahubFieldDataType(input: string) {

    var name = 'Text';

    switch(input){
      case 'bigint': name = 'BigNumber'; break;
      case 'bit': name = 'Boolean'; break;
      case 'date': name = 'Date'; break;
      case 'datetime': name = 'Date and Time'; break;
      case 'int': name = 'Number'; break;
      case 'time': name = 'Time'; break;
      case 'tinyint': name = 'Number'; break;
    }

    this._addEditFieldForm.get('fieldType').setValue(this._fieldTypesDropdown.find(x => x.label === name).value);

    this.populateFieldControlTypesDropdown();
    this.clearFieldOptions();
  }

  hideShowDatahubFields() {

    this._showDatahubFields = !!this._addEditFieldForm.get('isDatahubField').value;

    if (this._showDatahubFields && !this._datahubFieldsPopulated){
      this.getDataHubFields();
    }

    if (this._showDatahubFields) {
      this.initAddingDatahubFieldsMode();
    } else {
      this.initAddingCustomFieldsMode();
    }

  }
}
