export class MessageReportModel {
  public ReportID: number;
  public MessageID: number;
  public MessageName: string;
  public Subject: string;
  public HtmlContent: string;
  public ListID: number;
  public TotalRecipients: number;
  public LastSentDate: string;
  public OpenRate: number;
  public Opened: number;
  public BounceRate: number;
  public Bounced: number;
  public UnopenRate: number;
  public Unopened: number;
  public ComplainRate: number;
  public Complained: number;
  public DeliveryRate: number;
  public Delivered: number;
  public FailRate: number;
  public Failed: number;
  public TemporaryBounceRate: number;
  public TemporaryBounced: number;
  public ClickedRate: number;
  public Clicked: number;
  public ProfileUpdated: number;
  public ProfileUpdatedRate: number;
  public Unsubscribed: number;
  public UnsubscribedRate: number;

  constructor(init: any) {
    this.ReportID = init.ReportID;
    this.MessageID = init.MessageID;
    this.MessageName = init.MessageName;
    this.Subject = init.Subject;
    this.HtmlContent = init.HtmlContent;
    this.ListID = init.ListID;
    this.TotalRecipients = init. TotalRecipients;
    this.LastSentDate = init.LastSentDate;
    this.OpenRate = init.OpenRate;
    this.Opened = init.Opened;
    this.BounceRate = init.BounceRate;
    this.Bounced = init.Bounced;
    this.UnopenRate = init.UnopenRate;
    this.Unopened = init.Unopened;
    this.ComplainRate = init.ComplainRate;
    this.Complained = init.Complained;
    this.DeliveryRate = init.DeliveryRate;
    this.Delivered = init.Delivered;
    this.FailRate = init.FailRate;
    this.Failed = init.Failed;
    this.TemporaryBounceRate = init.TemporaryBounceRate;
    this.TemporaryBounced = init.TemporaryBounced;
    this.ClickedRate = init.ClickedRate;
    this.Clicked = init.Clicked;
    this.ProfileUpdated = init.ProfileUpdated;
    this.ProfileUpdatedRate = init.ProfileUpdatedRate;
    this.Unsubscribed = init.Unsubscribed;
    this.UnsubscribedRate = init.UnsubscribedRate;
  }
}
