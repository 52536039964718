import { Injectable } from '@angular/core';
import { PublicPreviewTokensDto } from './public-preview-tokens-dto';

@Injectable({
  providedIn: 'root'
})

export class PublicPreviewTokensAdapter {
  adapt(item: any): PublicPreviewTokensDto {
    return new PublicPreviewTokensDto(item);
  }
}
