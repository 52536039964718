import { Injectable } from '@angular/core';
import { EventInfoModel } from './event-info-model';

@Injectable({
  providedIn: 'root'
})

export class EventInfoModelAdapter {

  adapt(item: any): EventInfoModel {
    return new EventInfoModel(item);
  }

  adaptArray(init: Array<any>): EventInfoModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new EventInfoModel(table))
      : [new EventInfoModel(init[0])];
    } else {
      return [];
    }
  }
}
