import { Injectable } from '@angular/core';
import { DashboardStatsModel } from './dashboard-stats-model';

@Injectable({
  providedIn: 'root'
})

export class DashboardStatsModelAdapter {

  adapt(item: any): DashboardStatsModel {
    return new DashboardStatsModel(item);
  }
  
  createEmpty(){
    return new DashboardStatsModel({
      TotalContacts: 0,
      ContactsPercentageDifference: 0,
      TotalMessagesSent: 0,
      MessagesPercentageDifference: 0,
      TotalSMSMessagesSent: 0,    
      SmsPercentageDifference: 0
    });
  }
}
