<app-modal-container [title]="title"
                     actionButtonName="Clear"
                     (action)="clearList()">
  <div class="mb-3">
    <span>
      Clear List <span class="font-weight-bold">{{ name }}</span>?
    </span>
    <div>You will lose all Contact Data and related Statistical Information. You won't be able to restore them. Are you sure you want to clear this List?</div>
  </div>
  <div class="mt-3">Are you sure you want to proceed?</div>
</app-modal-container>
