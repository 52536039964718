import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss']
})
export class StatsCardComponent implements OnInit {
  @Input() faIconName: string;
  @Input() headlineText: string;
  @Input() headlineNumber: number;
  @Input() percentageDifference: number;  
  @Input() faIconNameForPercentage: string;
  @Input() colourForPercentage: string;
  
  constructor() { }

  ngOnInit() { } 

}


