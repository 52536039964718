<app-modal-container [title]="title"
                     [actionButtonName]="isLiveMessage ? 'Delete' : 'Restore'"
                     (action)="deleteRestore()">
  <div class="mb-3">
    The following {{ deleteType }} will be {{ isLiveMessage ? 'deleted' : 'restored' }}:
  </div>
  <div class="bold">{{ name }}</div>
  <div class="mt-3">Are you sure you want to proceed?</div>
  <div *ngIf="tableType.lists === tableType[tableKey]" class="bold mt-3">
    You will also lose all Contact Data and related Statistical Information
  </div>
</app-modal-container>




