import { Injectable } from '@angular/core';

export class UserRoleDto {

    public id: number
    public userRoleName: string
    public userRolePermissionSet: string

    constructor(init: any) {
      this.id = +init.Id
      this.userRoleName = init.UserRoleName
      this.userRolePermissionSet = init.UserRolePermissionSet
    }
}

@Injectable({
  providedIn: 'root'
})

export class UserRoleAdapter { 

  adapt(item: any): UserRoleDto {
    return new UserRoleDto(item)
  }
}

