import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

constructor() { }

  static convertUkDateTimeToANSI(date: string) {
    // example setup = "06/01/2020,18:31"
    var dateArray = date.split("/");
    var yearTimeArray = [];

    if (date.includes(',')) yearTimeArray = dateArray[2].split(',');
    else yearTimeArray = dateArray[2].split(' ');

    var retDate =  new Date(yearTimeArray[0] + "-" + dateArray[1] + "-" + dateArray[0] + "," + yearTimeArray[1]);
    return retDate; //converted
  }

   addDays(days: number)  {
    const date = new Date();
    return new Date(date.setDate(date.getDate() + days));
  }

  addMonths(months: number) {
    const date = new Date();
    return new Date(date.setMonth(date.getMonth() + months));
  }

  convertUkDateToANSI(date: string) {
    // example setup = "06/01/2020"    
    const dateArray = date.split('/');
    return new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
  }

  static convertUkDateToANSI(date: string) {
    // example setup = "06/01/2020"
    const dateArray = date.split('/');
    return new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);
  }

  today() {
    const today = new Date();
    return `${today.getDay()}/${today.getMonth() + 1}/${today.getFullYear()}`;
  }

  formatStringDate(stringDate: string) {
    var datePipe = new DatePipe('en-UK');
    var formatDate = datePipe.transform(stringDate, 'dd/MM/yyyy HH:mm a');
    
    return formatDate;
  } 

  getUtcTime(hours:number, mins: number) : Date {
    const now = new Date(Date.now());
    now.setUTCHours(hours, mins);
    return now;
  } 
  
}
