import { Component, OnInit, ViewChild, OnDestroy, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { interval, Subscription } from 'rxjs';
import { UnsubscribeService } from 'src/app/_services/system/unsubscribe.service';
import { ActivatedRoute } from '@angular/router';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { MessageFolderService } from 'src/app/_services/messaging/messages/message-folder.service';
import { ClientInboxAdapter, ClientInboxDto } from 'src/app/_models/admin/clients/client-inboxes-model';
import { SmsMessageService } from 'src/app/_services/messaging/messages/sms-messages/sms-message.service';
import { SmsMessageDtoAdapter } from 'src/app/_models/messaging/messages/sms-messages/sms-message-dto.adapter';
import { SmsMessageDto } from 'src/app/_models/messaging/messages/sms-messages/sms-message-dto';
import { ListFolderService } from 'src/app/_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { ListFolderDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/list-folders/list-folder-dto.adapter';
import { ListFolderDto } from 'src/app/_models/messaging/lists-and-contacts/list-folders/list-folder-dto';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model.adapter';
import { ListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model';
import { ListFieldsModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-fields-model.adapter';
import { ListFieldsModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-fields-model';
import { SmsMessageDtoRequest } from 'src/app/_models/messaging/messages/sms-messages/sms-message-dto-request';
import { Router } from '@angular/router';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { ComponentCanDeactivate } from '../../../../_guards/pending-changes-guard';
import { HostListener } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LastActiveService } from 'src/app/_services/system/last-activity-service';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { UnsavedChangesModalComponent } from 'src/app/views/shared/modals/unsaved-changes/unsaved-changes-modal.component';
import { InactivityWarningModalComponent } from 'src/app/views/shared/modals/unsaved-changes/inactivity-warning-modal.component';

@Component({
  selector: 'app-sms-message',
  templateUrl: './sms-message.component.html',
  styleUrls: ['./sms-message.component.scss'],
})

export class SmsMessageComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild('insertWildcardModal', { static: true }) insertWildcardModal: ModalDirective;

  _formGroupInsertWildcard: UntypedFormGroup;
  _formGroupSms: UntypedFormGroup;
  _clientInboxSelectListOptions: SelectListOption[] = [];
  _listModels: ListModel[] = [];
  _listFolderSelectListOptions: SelectListOption[] = [];
  _listsSelecListOptions: SelectListOption[] = [];
  _listFieldsSelectListOptions: SelectListOption[] = [];  
  _messageFolderSelectListOptions: SelectListOption[] = [];
  _selectedListId = 0;
  _selectedListFieldId = 0;
  _selectedListFolderId = 0;
  _selectedMessageFolderId = 0;
  _selectedMessageId = 0;
  _selectedSenderId = 0;
  _selectionStart = 0;
  _showSpinner = true;
  _smsLimits = [
    [160, '1 SMS'],
    [306, '2 SMS'],
    [459, '3 SMS'],
    [612, '4 SMS'],
    [765, '5 SMS']
  ];
  _subscriptionMessageFolders: Subscription;
  _subscriptionSenders: Subscription;
  _subscriptionLists: Subscription;
  _subscriptionListFields: Subscription;
  _subscriptionListFolders: Subscription;
  _title = 'Create New';
  _userPermissions: PermissionsModel;  
  lastActivityDate: Date;
  activityCheckInterval: any;
  matDialog: MatDialog;
  dialogRef: MatDialogRef<InactivityWarningModalComponent>;
  isSmsSaved: boolean = false;

  constructor(
    private clientInboxDtoAdapter: ClientInboxAdapter,    
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,    
    private listFolderDtoAdapter: ListFolderDtoAdapter,
    private listFolderService: ListFolderService,
    private listsService: ListsService,
    private listModelAdapter: ListModelAdapter,
    private listFieldsModelAdapter: ListFieldsModelAdapter,
    private messageFolderDtoAdapter: FolderDtoAdapter,
    private messageFolderService: MessageFolderService,
    private route: ActivatedRoute,
    private router: Router,    
    private smsMessageDtoAdapter: SmsMessageDtoAdapter,
    private smsMessageService: SmsMessageService,
    private lastActiveService: LastActiveService,
    injector: Injector) {
      this._selectedMessageId = parseInt(this.route.snapshot.paramMap.get('messageId') || "0");
      this.matDialog = injector.get(MatDialog);
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
      return !this._formGroupSms.dirty || this.isSmsSaved
    }

    ngOnInit() {

      this._userPermissions = this.permissionsService.getPermissionsModel();

      this._formGroupSms = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        messageFolders: new UntypedFormControl('', Validators.required),
        senders: new UntypedFormControl(0, Validators.required),
        message: new UntypedFormControl('', Validators.required),
        wordCounter: new UntypedFormControl(''),
      });
  
      this._formGroupInsertWildcard = new UntypedFormGroup({
        listFolders: new UntypedFormControl({value: 0, disabled: true}, Validators.required),
        lists: new UntypedFormControl({value: 0, disabled: true}, Validators.required),
        listFields: new UntypedFormControl({value: 0, disabled: true}, Validators.required)
      });

      if (this._selectedMessageId > 0) {
        this._title = 'Edit';
        this.getSmsMessage(this._selectedMessageId);
      }

      this.populateSendersDropdown();
  
      this.populateMessageFoldersDropdown();
  
      this.populateListFoldersDropdown();

      this.subscribeToFormControls();  
      
      this.lastActiveService.lastActive$.subscribe(lastActivityDate=>
      {
          this.lastActivityDate = lastActivityDate;
      });
      
      
      this.activityCheckInterval = setInterval(() => {
        var now = new Date();
        if(this.lastActivityDate < new Date(now.getTime() - (15 * 60 * 1000))){
          if(this.dialogRef == null || this.dialogRef.getState() !== MatDialogState.OPEN){
            this.dialogRef = this.matDialog.open(InactivityWarningModalComponent, { id:'inactivity-warning-modal' });
          }          
        }                 
      }, 60000);

    }

    ngOnDestroy() {
      UnsubscribeService.unsubscribe(this._subscriptionMessageFolders,
                                     this._subscriptionSenders,
                                     this._subscriptionLists,
                                     this._subscriptionListFields,
                                     this._subscriptionListFolders);
     clearInterval(this.activityCheckInterval);                         
    }

  buildCounterString(messageLength: number) {
    for (var i = 0; i < this._smsLimits.length; i++) {
      if (messageLength <= this._smsLimits[i][0]) {
        const countString = `Characters ${messageLength} / ${this._smsLimits[i][0]}. (${this._smsLimits[i][1]})`;
        this._formGroupSms.get('wordCounter').setValue(countString);
        break;
      }
    }
  }

  getMessageLength() {
    return this._formGroupSms.get('message').value.length;
  }

  getSmsMessage(messageId: number) {

    this.smsMessageService.get(messageId).subscribe((executionResultDto: ExecutionResultDto) => {
      this.showSpinner();
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const smsMessageDto: SmsMessageDto = this.smsMessageDtoAdapter.adapt(executionResultDto.data);

        this._formGroupSms.patchValue({
          name: smsMessageDto.messageName,
          message: smsMessageDto.smsContent,
          messageFolders: smsMessageDto.folderId,
          senders: smsMessageDto.clientInboxId
        });

        this.updateCharCounter(this.getMessageLength());

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
    }, error => this.hideSpinner());
  }

  hideSpinner() {
    this._showSpinner = false;
  }

  insertWildcardModalSubmit() {

    this.insertWildcardModal.hide();
    
    const text = this._formGroupSms.get('message').value;
    const textBefore = text.substring(0, this._selectionStart);
    const textAfter = text.substring(this._selectionStart, text.length);
    const field = this._listFieldsSelectListOptions.find(x => x.value === this._selectedListFieldId).simpleName;
    const textplusWildcard = `${textBefore}${field}${textAfter}`; 
    
    this._formGroupSms.get('message').setValue(textplusWildcard);
  }

  onClickEvent(event) {
    this.onEvent(event);
  }

  onModelChangeEvent(event) {
    this.onEvent(event);
  }

  onKeyUpEvent(event) {
   this.onEvent(event);
  }

  onEvent(event) {

    if (event && event.target) {
      this._selectionStart = event.target.selectionStart;
    }
    this.updateCharCounter(this.getMessageLength());
  }

  navigateToMessagesPage() {
    this.router.navigate(['messages/', this._selectedMessageFolderId]);
  }

  navigateToSendPage() {
    this.router.navigate(['messages/message-send', this._selectedMessageId, MessageTypeEnum.sms]);    
  }

  populateListsDropdown() {

    if (!this._selectedListFolderId || this._selectedListFolderId === 0) { return; }

    if (this._listModels.length > 0) { 
      this.setSelectedList();
      return;
    }; 

    this.listsService.getAllLists().subscribe((executionResultDto: ExecutionResultDto) => {
      
      this._formGroupInsertWildcard.get('lists').disable();      
      this.showSpinner();

      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._listModels = executionResultDto.data.map((listDto: ListModel) => this.listModelAdapter.adapt(listDto));
        this.setSelectedList();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this._formGroupInsertWildcard.get('lists').enable();
      this.hideSpinner();
    }, errow => this.hideSpinner());
  }

  populateListFieldsDropdown() {

    if (!this._selectedListId || this._selectedListId === 0) { return; }
    
    this.listsService.getWildcardListFields(this._selectedListId).subscribe((executionResultDto: ExecutionResultDto) => {
      
      this.showSpinner();      
      
      this._formGroupInsertWildcard.get('listFields').disable();

      if (executionResultDto.executionResult === ExecutionResult.success) {
        
        const listFieldsModels = executionResultDto.data.map((listFieldsModel: ListFieldsModel) => 
                                  this.listFieldsModelAdapter.adapt(listFieldsModel));

        this._listFieldsSelectListOptions = listFieldsModels.map(((listFieldsModel: ListFieldsModel) => {
            var fieldName = listFieldsModel.FieldName;

            if(!fieldName) {
              fieldName = listFieldsModel.DisplayName;
            }

            return new SelectListOption(listFieldsModel.FieldID, fieldName, listFieldsModel.Wildcard)
        }));

        this._selectedListFieldId = this._listFieldsSelectListOptions && this._listFieldsSelectListOptions.length === 1 ?
                                    this._listFieldsSelectListOptions[0].value :
                                    0;

        this._formGroupInsertWildcard.get('listFields').setValue(this._selectedListId);

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
      this._formGroupInsertWildcard.get('listFields').enable();
    }, error => this.hideSpinner());
  }

  populateListFoldersDropdown() {

    this.listFolderService.getAll().subscribe((executionResultDto: ExecutionResultDto) => {

      this.showSpinner();
      this._formGroupInsertWildcard.get('listFolders').disable();

      if (executionResultDto.executionResult === ExecutionResult.success) {
        const listFolderDtos = executionResultDto.data.map((listFolderDto: ListFolderDto) =>
                                this.listFolderDtoAdapter.adapt(listFolderDto));

        this._listFolderSelectListOptions = listFolderDtos.map((listFolderDto: ListFolderDto) =>
                                                new SelectListOption(listFolderDto.id, listFolderDto.name));
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
      this._formGroupInsertWildcard.get('listFolders').enable();

    }, error => this.hideSpinner());
  }

  populateMessageFoldersDropdown() {

    this.messageFolderService.getAll().subscribe((executionResultDto: ExecutionResultDto) => {

      this.showSpinner();
      this._formGroupSms.get('messageFolders').disable();

      if (executionResultDto.executionResult === ExecutionResult.success) {
        const messageFolderDtos = executionResultDto.data.map((messageFolderDto: FolderDto) =>
                                                          this.messageFolderDtoAdapter.adapt(messageFolderDto));

        this._messageFolderSelectListOptions = messageFolderDtos.map((messageFolderDto: FolderDto) =>
                                                                  new SelectListOption(messageFolderDto.id, messageFolderDto.name));
      } else {
        this.notificationService.showError(executionResultDto.message);
      }

      this._formGroupSms.get('messageFolders').enable();
      this.hideSpinner();
    }, error => this.hideSpinner());
  }

  populateSendersDropdown() {

    this.smsMessageService.getClientInboxes().subscribe((executionResultDto: ExecutionResultDto) => {

      this.showSpinner();
      this._formGroupSms.get('senders').disable();

      if (executionResultDto.executionResult === ExecutionResult.success) {
        const clientInboxDtos = executionResultDto.data
                               .map((clientInboxDto: ClientInboxDto) => this.clientInboxDtoAdapter.adapt(clientInboxDto));

        this._clientInboxSelectListOptions = clientInboxDtos.map((clientInboxDto: ClientInboxDto) =>
                                            new SelectListOption(clientInboxDto.id, `(${clientInboxDto.InboxNumber}) ${clientInboxDto.InboxName}`));
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this._formGroupSms.get('senders').enable();
      this.hideSpinner();
    }, error => this.hideSpinner());
  }

  save(navigateToSendPagePostSave: boolean) {    
    const smsMessageDtoRequest = new SmsMessageDtoRequest({ id: this._selectedMessageId,
                                                            name: this._formGroupSms.get('name').value,
                                                            folderId: this._selectedMessageFolderId,
                                                            message: this._formGroupSms.get('message').value,
                                                            clientInboxId: this._selectedSenderId });

    this.smsMessageService.save(smsMessageDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      this.showSpinner();
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const smsMessageDto = this.smsMessageDtoAdapter.adapt(executionResultDto.data);
        this._selectedMessageId = smsMessageDto.messageId;
        if (navigateToSendPagePostSave) {
          this.isSmsSaved = true;
          this.navigateToSendPage();
        } else {
          this.notificationService.showSuccess(executionResultDto.message);
          this.isSmsSaved = true;
          this.navigateToMessagesPage();
        }
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
    }, error => this.hideSpinner()
    );
  }

  savePreSend() {

    if (this._formGroupSms.dirty) {
      this.save(true);
    } else {
      this.navigateToSendPage();
    }
  }

  setSelectedList() {
    this._listsSelecListOptions = this._listModels.filter(x => x.FolderID === this._selectedListFolderId)
                                                    .map((listDto: ListModel) => new SelectListOption(listDto.ListID, listDto.ListName));
     
    this._selectedListId = this._listsSelecListOptions && this._listsSelecListOptions.length === 1 ?
                             this._listsSelecListOptions[0].value :
                             0;

    this._formGroupInsertWildcard.get('lists').setValue(this._selectedListId);
  }

  showSpinner() {
    this._showSpinner = true;
  }

  subscribeToFormControls() {
    
    this._subscriptionMessageFolders = this._formGroupSms.get('messageFolders').valueChanges.subscribe((folderId: number) => {
      this._selectedMessageFolderId = folderId;
    });

    this._subscriptionSenders = this._formGroupSms.get('senders').valueChanges.subscribe((senderId: number) => {
      this._selectedSenderId = senderId;
    });

    this._subscriptionListFolders = this._formGroupInsertWildcard.get('listFolders').valueChanges.subscribe((folderId: number) => {
      this._selectedListFolderId = folderId;
      this.populateListsDropdown();
    });

    this._subscriptionLists = this._formGroupInsertWildcard.get('lists').valueChanges.subscribe((listId: number) => {
      this._selectedListId = listId;
      this.populateListFieldsDropdown();
    });

    this._subscriptionListFields = this._formGroupInsertWildcard.get('listFields').valueChanges.subscribe((listFieldId: number) => {
      this._selectedListFieldId = listFieldId;
    });
  }

  updateCharCounter(messageLength: number) {
    this.buildCounterString(messageLength);
  }
}
