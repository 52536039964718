<app-modal-container [title]="title"
                     [actionButtonName]="'Confirm'"
                     (action)="copyMove()">
  <form [formGroup]="copyFormGroup">
    <app-md-select [label]="'Destination Folder'"
                   [options]="folderSelectOptions"
                   formControlName="folderId">
    </app-md-select>

    <div *ngIf="isListCopying">
      <mat-checkbox formControlName="copyContacts" class="cursor-default-style">
        Copy Contacts?
      </mat-checkbox>
    </div>

    <app-message-to-copy-move [copyMoveList]="copyMoveList"
                              [type]="type"
                              [isCopy]="isCopy"
                              (arrayChanged)="setArray($event)"
                              (copyMove)="copyMove()">
    </app-message-to-copy-move>
  </form>
</app-modal-container>
