import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { GetClientAuditTrailLogsRequestModel } from 'src/app/_models/audit-trail/get-audit-trail-logs-request-model';

@Injectable({
  providedIn: 'root'
})

export class AuditTrailService {
  private baseUrl = environment.apiURL;
  private controllerName = 'AuditTrail';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }


  getAuditTrailFields(auditTrailType: string) {
    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetAuditTrailFields?auditTrailType=${auditTrailType}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }  

  getClientAuditTrailLogs(getClientAuditTrailLogsRequestModel: GetClientAuditTrailLogsRequestModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/GetClientAuditTrailLogs`, getClientAuditTrailLogsRequestModel)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

