import { Injectable } from '@angular/core';
import { ContactFieldDto } from './contact-field-dto';

@Injectable({
  providedIn: 'root'
})

export class ContactFieldDtoAdapter {
  public adapt(item: any) {
    return new ContactFieldDto(item);
  }

  adaptArray(init: Array<any>): ContactFieldDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new ContactFieldDto(table))
      : [new ContactFieldDto(init[0])];
    } else {
      return [];
    }
  }
}
