import { FieldControlTypeDto } from './field-control-type-dto';
import { FieldControlTypeDtoAdapter } from './field-control-type-dto-adapter';

export class ListFieldControlTypeDto {
    fieldTypeId: number;
    fieldTypeName: string;
    controlType: FieldControlTypeDto[];

    constructor(init: any) {
        this.fieldTypeId = init.FieldTypeID,
        this.fieldTypeName = init.FieldTypeName;
        this.controlType = init.ControlType.map(ct => new FieldControlTypeDtoAdapter().adapt(ct));

     }
}
