
<div class="email-builder-container">  
  
  <div id="messageName" class="emailSubject">
    <span>{{ this._message.MessageName }}</span>
  </div> 
  
  <button type="button" (click)="populateMessageModal(); saveMessageModal.show()" class="btn-okay relative waves-light rounded" mdbWavesEffect>
    <span *ngIf="_saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save Message
  </button>

  <div>   
    <email-editor style="height:850px" 
    [projectId]="70630" 
    (ready)="editorReady(getUnlayerFuncs())" 
    (loaded)="editorLoaded()"
    [options]="getOptions()"      
    >
    </email-editor>    
  </div>
   

  <div mdbModal #saveMessageModal="mdbModal" class="modal fade left rounded" id="saveMessageModalTop" tabindex="-1" role="dialog"
      aria-labelledby="saveMessageModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Saving Message: {{this._saveMessageForm.get('messageName').value}}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="saveMessageModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mx-3">
          <form [formGroup]="_saveMessageForm">
            <div class="modal-body">
              <div class="md-form remove-top-margin">
                <mdb-icon fas icon="tag" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput 
                  [ngClass]="{'is-invalid': _saveMessageForm.get('messageName').errors && _saveMessageForm.get('messageName').touched}"
                  type="text" 
                  id="message-name" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="messageName">
                <label for="message-name">Message Name</label>
                <div class="invalid-feedback ml-5">Please enter a Message Name.</div>
              </div>
              <div class="md-form remove-top-margin">
                <mdb-icon fas icon="info-circle" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput 
                  [ngClass]="{'is-invalid': _saveMessageForm.get('emailSubject').errors && _saveMessageForm.get('emailSubject').touched}"
                  type="text" 
                  id="email-subject" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="emailSubject">
                <label for="email-subject">Email subject</label>
                <div class="invalid-feedback ml-5">Please enter an Email Subject.</div>
              </div>
              <div class="md-form remove-top-margin">
                <mdb-icon fas icon="folder" class="prefix icon-color" [ngClass]="{'active': _folderSelectOpen}"></mdb-icon>
                <mdb-select
                  class="short-select"
                  [ngClass]="{'is-invalid': _saveMessageForm.get('messageFolder').errors && _saveMessageForm.get('messageFolder').touched}"
                  [options]="_messageFoldersDropdown"
                  [filterEnabled]="true"
                  [visibleOptions]="10"
                  label="Select Folder"
                  (opened)="folderSelectOpened()"
                  (closed)="folderSelectClosed()"
                  formControlName="messageFolder">
                </mdb-select>
                <div *ngIf="_saveMessageForm.get('messageFolder').errors && _saveMessageForm.get('messageFolder').touched" class="red-text ml-5">Please select a Folder.</div>
              </div>
              <div class="md-form">
                <mdb-icon fas icon="user" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput
                  [ngClass]="{'is-invalid': _saveMessageForm.get('fromName').errors && _saveMessageForm.get('fromName').touched}" 
                  type="text" 
                  id="from-name" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="fromName">
                <label for="from-name">From Name</label>
                <div class="invalid-feedback ml-5">Please enter a From Name.</div>
              </div>
              <div class="md-form">
                <mdb-icon fas icon="address-card" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput 
                  [ngClass]="{'is-invalid': _saveMessageForm.get('fromAddress').errors && _saveMessageForm.get('fromAddress').touched}" 
                  type="text" 
                  id="from-address"
                  maxlength="225" 
                  class="form-control" 
                  formControlName="fromAddress">
                <label for="from-address">From Address</label>
                <div class="invalid-feedback ml-5">Please enter a From Address.</div>
              </div>
              <div class="md-form">
                <mdb-icon fas icon="reply" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput
                  [ngClass]="{'is-invalid': _saveMessageForm.get('replyToAddress').errors && _saveMessageForm.get('replyToAddress').touched}"
                  type="text" 
                  id="reply-to-address" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="replyToAddress">
                <label for="reply-to-address">Reply To Address</label>
                <div class="invalid-feedback ml-5">Please enter a Reply To Address.</div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="btnCloseModalUnlayer" type="button" class="btn-cancel waves-light rounded" aria-label="Close" (click)="saveMessageModal.hide()" mdbWavesEffect>Close</button>
              <button id="btnSaveMessageUnlayer" type="button" [disabled]="!_saveMessageForm.valid || _saving" (click)="saveMessageSubmit(true)" class="btn-okay relative waves-light rounded" mdbWavesEffect><span *ngIf="_saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save Message</button>
              <div *ngIf="!this._sendAfterSave">
                <button id="btnSaveAndExitBuilderUnlayer" type="button" [disabled]="!_saveMessageForm.valid || _saving" (click)="saveMessageSubmit(false)" class="btn-saveandexit btn-okay relative waves-light rounded" mdbWavesEffect><span *ngIf="_saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save and Exit Builder</button>
              </div>              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>  

  <div mdbModal id="queryBuilderModal" id="queryBuilderModal" class="modal fade modal-overflow" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideQueryBuilderModal()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">Display Condition</h4>
        </div>
        <div class="modal-body">
          <form [formGroup]="_configuratorFormGroup">
            <div class="row">
              <div class="col-6">
                <div class="md-form">
                  <label for="folders" class="mb-2">Folder</label>
                  <mdb-select formControlName="folders"
                                (closed)="onListFoldersClosed($event)" 
                                [options]="_listFolders" 
                                [filterEnabled]="true"
                                [visibleOptions]="10"
                                placeholder="&lt;Select Folder&gt;"
                                class="form-controlx custom-drop-downx select-folderx">
                  </mdb-select>
                </div>
              </div>
              <div class="col-6"> 
                <div class="md-form">
                  <label for="lists" class="mb-2">List</label>
                  <mdb-select formControlName="lists"
                              (closed)="onListsClosed($event)"
                              [options]="this._listsByFolderId" 
                              [filterEnabled]="true"
                              [visibleOptions]="10"
                              placeholder="&lt;Select List&gt;" 
                              class="form-controlx custom-drop-downx select-folderx">
                  </mdb-select>
                </div>
              </div> 
            </div>
          </form>                  
            <app-query-builder            
              #queryBuilder 
              [_parentListID]="_selectedListId" 
              [_parentQuery]="''"
              [_parentSource]="this._pageTitle"              
              [_parentPageTitle]="this._pageTitle"
              (queryBuilderSave)="onContactsFilterSave($event)">            
            </app-query-builder>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>   
  
  <div mdbModal #mediaServerModal="mdbModal" class="modal fade right modal-overflow" tabindex="-1" role="dialog" aria-labelledby="mediaServerModalLabel"
  aria-hidden="true">
 <div class="modal-dialog modal-lg modal-xl" role="document">
     <div class="modal-content">
         <div class="modal-header">
             <button type="button" class="close pull-right" aria-label="Close" (click)="closeMediaServer()">
                 <span aria-hidden="true">×</span>
             </button>
             <h4 class="modal-title w-100" id="myModalLabel">Select Files From Media Server</h4>
         </div>
         <div class="modal-body">
          <app-media-server *ngIf="_showMediaServer"></app-media-server>
         </div>
         <div class="modal-footer justify-content-center">
             <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="closeMediaServer()" mdbWavesEffect>Close</button>
             <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="setClientImageUrl()" mdbWavesEffect>OK!</button>
         </div>
     </div>
 </div >
</div >

<div mdbModal #uploadFileModal="mdbModal" class="modal fade top rounded" id="uploadFileModalTop" tabindex="-1" role="dialog"
      aria-labelledby="uploadFileModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Save New Media File</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="uploadFileModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mx-3">
          <div class="modal-body">
            <div class="md-form remove-top-margin">
              <mdb-icon fas icon="tag" class="prefix icon-color"></mdb-icon>
              <mdb-select
                [options]="_bucketsOptions"
                [visibleOptions]="10"
                [(ngModel)]="this._bucketName">
              </mdb-select>
            </div>

            <div class="md-form remove-top-margin">
              <mdb-icon fas icon="folder" class="prefix icon-color" [ngClass]="{'active': _folderSelectOpen}"></mdb-icon>
              <mdb-card cascade="true" narrower="true" >
                <mdb-card-header class="primary-color">
                  <mdb-card-title class="text-white">Folders</mdb-card-title>
                </mdb-card-header>
                <mdb-card-body class="scrollbar scrollbar-primary max-height-210">
                  <div [ngClass]="{'media-loading': _loading}" class="text-center">
                    <div [ngClass]="{'mt-5': _loading}">
                      <mdb-spinner *ngIf="_loading" spinnerColor="blue" spinnerType="big"></mdb-spinner>
                    </div>
                  </div>
                  <div class="row justify-content-center folder-row" *ngIf="this._files">
                    <div class="col-md-12 pl-2 pr-2 " *ngIf="showFolderBackButton()">
                      <a (click)="folderBackClicked()" >
                        <div class="row p-1 folder-row">
                          <div class="folder-text col-md-12"><img class="img-fluid folder-image" src="../../../assets/images/Icons/Folder-icon-blue.png" alt="Card image cap"> Back ...</div>
                        </div>
                      </a>
                    </div>          
                    <div class="col-md-12 pl-2 pr-2" *ngFor="let folder of this._files.CommonPrefixes">
                      <div class="row p-1 folder-row">
                        <a class="folder-text col-md-10" (click)="folderClicked(folder)"> <img class="img-fluid folder-image" src="../../../assets/images/Icons/Folder-icon-blue.png" alt="Card image cap"> {{getFolderName(folder)}} </a>                          
                      </div>
                    </div>            
                  </div>          
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
          <div class="modal-footer">
            <button id="btnCloseUploadModal" type="button" class="btn-cancel waves-light rounded" aria-label="Close" (click)="uploadFileModal.hide()" mdbWavesEffect>Close</button>
            <button id="btnUploadFile" type="button" [disabled]="!this._folder || _uploading" (click)="uploadNewImage()" class="btn-okay relative waves-light rounded" mdbWavesEffect><span *ngIf="_uploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Save</button>
          </div>
        </div>
        </div>
      </div>
  </div>


  <input id="selectedId" type="hidden" (change)="onSelectedIdChanged()">
  <input id="displayConditions" type="hidden">
  <input id="configValues" type="hidden">

</div>
