import { Injectable } from '@angular/core';
import { MessageReportLinkClickModel } from './message-report-link-click-model';

@Injectable({
  providedIn: 'root'
})

export class MessageReportLinkClickModelAdapter {
  adapt(item: any): MessageReportLinkClickModel {
    return new MessageReportLinkClickModel(item);
  }

  adaptArray(init: Array<any>): MessageReportLinkClickModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new MessageReportLinkClickModel(table))
      : [new MessageReportLinkClickModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new MessageReportLinkClickModel({
      LinkId: 0,
      ReportId: 0,
      URL: '',
      LinkTitle: '',
      NonUniqueClicks: 0,
      UniqueClicks: 0,
      TotalNonUniqueClicksOnAllLinks: 0,
      MaxNonUniqueClicks: 0,
    });
  }
}
