import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserRolePermissionDto,UserRolePermissionAdapter } from '../../../_models/admin/users/user-role-permission-model';
import { PermissionsAllAvailableDto, PermissionsAllAvailableAdapter } from '../../../_models/admin/users/permissions-all-available-model';
import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserRolePermissionService {
    _baseUrl = environment.apiURL;

    constructor(private http: HttpClient,
      private userPermissionAdapter: UserRolePermissionAdapter,
      private permissionsAllAvailableAdapter: PermissionsAllAvailableAdapter) {

     }

    getAllAvailable() : Observable<PermissionsAllAvailableDto> {
      return this.http
      .get(this._baseUrl + 'UserRolePermission')
      .pipe(map((data: any) => this.permissionsAllAvailableAdapter.adapt(JSON.parse(data))));
    }

    post(userPermissionDto: UserRolePermissionDto):any{
      return this.http.
      post(this._baseUrl + 'UserRolePermission', userPermissionDto);
    }

    delete(id: number) {
      return this.http.
      delete(this._baseUrl + `UserRolePermission/${id}`);
    }

    get(userRoleId: number) : Observable<UserRolePermissionDto[]> {
      return this.http
      .get(this._baseUrl + `UserRolePermission/${userRoleId}`)
      .pipe(map((data: any) => data.map((item: any) => this.userPermissionAdapter.adapt(item))));
    }

}
