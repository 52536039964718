import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UserAdapter, UserDto } from 'src/app/_models/admin/users/user-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  _baseUrl: string = environment.apiURL;

constructor(private http: HttpClient,
            private userAdapter: UserAdapter,
) { }

getUsers(): Observable<UserDto[]> {
  return this.http.get(this._baseUrl + 'User').pipe(map((data: any) =>
  data.map((item: any) => this.userAdapter.adapt(item))));
}

getInactiveUsers(): Observable<UserDto[]> {
  return this.http.get(this._baseUrl + 'User/GetInactiveUsers').pipe(map((data: any) =>
  data.map((item: any) => this.userAdapter.adapt(item))));
}

getUser(id: number) {
  return this.http.get(this._baseUrl + `User/${id}`);
}

}
