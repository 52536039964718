
<div class="row mt-1 mb-2 justify-content-center">
          <div class="col-md-auto text-center">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button 
                mdbBtn 
                type="button" 
                color="secondary" 
                rounded="true" 
                outline="true" 
                class="waves-light"
                mdbWavesEffect
                (click)="previousSlide()">
                <mdb-icon fas icon="angle-left"></mdb-icon>
              </button>
              <button
                *ngFor="let webFormPage of _webFormPages; let i = index;" 
                mdbBtn 
                type="button" 
                color="secondary" 
                rounded="true" 
                outline="true" 
                class="waves-light font-weight-bold"
                [ngClass]="{'active': i === this._activeSlideIndex}"
                mdbWavesEffect
                (click) = selectSlide(i)>
                {{webFormPage.WebFormPageName}}
                <mdb-icon 
                  *ngIf="this._userPermissions.Messaging.ListsAndContactListsForms.Edit"
                  fas icon="edit" class="button-icon" (click)="openFormEditor(webFormPage)">
                </mdb-icon>
              </button>
              <button 
                mdbBtn 
                type="button" 
                color="secondary" 
                rounded="true" 
                outline="true" 
                class="waves-light"
                mdbWavesEffect
                (click)="nextSlide()">
                <mdb-icon fas icon="angle-right"></mdb-icon>
              </button>
            </div>
          </div>
        </div>

        <mdb-carousel #formCarousel [isControls]="false" [animation]="'slide'" [interval]="'0'" (activeSlideChange)="onActiveSlideChange($event)">
          <mdb-carousel-item class="justify-content-center form-container" *ngFor="let webFormPage of _webFormPages">
            <iframe id="web-form-page-{{webFormPage.WebFormPageID}}" sandbox [srcdoc]="webFormPage.Html | sanitizeHtml" class="">
            </iframe>
          </mdb-carousel-item>
        </mdb-carousel>