import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NotificationService } from '../_services/notification.service';
import { PermissionsModel } from '../_models/system/permissions/permissons-model';
import { PermissionsService } from '../_services/system/Permissions/permissions.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class PermissionsGuard implements CanActivate {
  private userPermissions: PermissionsModel;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private permissionService: PermissionsService) {      
      this.userPermissions = this.permissionService.getPermissionsModel();      
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return new Observable<boolean>((observer) => {
      const sectionName = route.data.section as string;
      const pageName = route.data.page as string;

      if(this.userPermissions) {
        if (this.userPermissions[sectionName][pageName].View) {
          observer.next(true);
          observer.complete();
        } else {
          this.router.navigate(['/dashboard']);
          this.notificationService.showError("You do not have the permissions to view this page. If this is not expected, please contact a system admin!");
          observer.next(false);
          observer.complete();
        }
      } else {
        this.permissionService.getPermission().subscribe(() => {
          this.userPermissions = this.permissionService.getPermissionsModel();
          if (this.userPermissions[sectionName][pageName].View) {
            observer.next(true);
            observer.complete();
          } else {
            this.router.navigate(['/dashboard']);
            this.notificationService.showError("You do not have the permissions to view this page. If this is not expected, please contact a system admin!");
            observer.next(false);
            observer.complete();
          }
        })
      }
    });
  }
}
