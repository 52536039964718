import { OverviewMetricsReportDto } from "./overview-metrics-report-dto";

export class TwitterMetricsReportDto extends OverviewMetricsReportDto {

  public Tweets: Array<any>[];
  public TotalReTweets: number;
  public TotalReplies: number;
  public TotalFavourites: number;
  public TotalQuoteTweets: number;
  public TotalLinkClicks: number;
  public TotalProfileViews: number;

  constructor(init: {
    Tweets: Array<any>[],
    TotalReTweets: number,
    TotalReplies: number,
    TotalFavourites: number,
    TotalQuoteTweets: number,
    TotalLinkClicks: number,
    TotalProfileViews: number,
    
    TotalPosts: number;
    TotalFollowers: number;
    EngagementRate: number;
    TotalImpressions: number;
  }) {
    super(init);
    this.Tweets = init.Tweets;
    this.TotalReTweets = init.TotalReTweets;
    this.TotalReplies = init.TotalReplies;
    this.TotalFavourites = init.TotalFavourites;
    this.TotalQuoteTweets = init.TotalQuoteTweets;
    this.TotalLinkClicks = init.TotalLinkClicks;
    this.TotalProfileViews = init.TotalProfileViews;
  }
}  