import { Injectable } from '@angular/core';
import { LightweightMessageModel } from './lightweight-message-model';

@Injectable({
  providedIn: 'root'
})

export class LightweightMessageModelAdapter {

  adapt(item: any): LightweightMessageModel {
    return new LightweightMessageModel(item);
  }

  adaptArray(init: Array<any>): LightweightMessageModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new LightweightMessageModel(table))
      : [new LightweightMessageModel(init[0])];
    } else {
      return [];
    }
  }
}
