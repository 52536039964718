import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';

import { ContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-dto-request';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'Dashboard';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) { }  

  get(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }  
}

