import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { TableType } from '../../constants/table-constants';
import { DeleteType } from '../constants/modal-constants';
import { MessageService } from '../../../../_services/messaging/messages/message.service';
import { SmsMessageService } from '../../../../_services/messaging/messages/sms-messages/sms-message.service';
import { ContactPlanService } from '../../../../_services/messaging/automation/contact-plan.service';
import { MessageCodeService } from '../../../../_services/messaging/automation/message-codes/message-code.service';
import { NotificationService } from '../../../../_services/notification.service';
import { ListsService } from '../../../../_services/messaging/lists-and-contacts/lists/lists.service';
import { ContactService } from '../../../../_services/messaging/lists-and-contacts/contacts/contact.service';

@Component({
  selector: 'app-delete-restore-modal',
  templateUrl: './delete-restore-modal.component.html',
  styleUrls: ['./delete-restore-modal.component.scss']
})
export class DeleteRestoreModalComponent implements OnInit {
  id: number;
  tableType = TableType;
  tableKey: string;
  title: string;
  isLiveMessage = true;
  name: string;
  deleteType: string;

  constructor(
    private messageService: MessageService,
    private smsMessageService: SmsMessageService,
    private contactPlanService: ContactPlanService,
    private messageCodeService: MessageCodeService,
    private listsService: ListsService,
    private contactService: ContactService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<DeleteRestoreModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    const row = this.data.row;
    this.isLiveMessage = this.data.options.isLiveMessage;
    this.tableKey = this.data.options.tableKey;
    this.name = row.messageName || row.title || row.ListName || row.Title;

    if (TableType.contacts === TableType[this.tableKey]) {
      this.name = this.name + ` ${row.Firstname}` + ` ${row.Surname}` ;
    }

    this.id = row.messageId || row.messageCodeId || row.contactPlanId || row.ContactID || row.ListID;
    this.deleteType = DeleteType[this.tableKey];
    this.createTitle();
  }

  createTitle(): void {
    this.title = 'Confirm ' + (this.isLiveMessage ? 'Delete ' : 'Restore ') + this.deleteType;
  }

  defineService(): MessageService | MessageCodeService | ContactPlanService | SmsMessageService | ListsService | ContactService {
    switch (this.tableType[this.tableKey]) {
      case TableType.messages:
        return TableType[this.data.row.messageType.toLowerCase()] === TableType.email ? this.messageService : this.smsMessageService;
      case TableType.contactPlan:
        return this.contactPlanService;
      case TableType.contactCriteria:
        return this.messageCodeService;
      case TableType.lists:
        return this.listsService;
      case TableType.contacts:
        return this.contactService;
    }
  }

  deleteRestore(): void {
    let request: Observable<ExecutionResultDto>;
    const service = this.defineService();
    request = service[this.isLiveMessage ? 'delete' : 'restore'](this.id, this.data.options.listId);

    request.subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.dialogRef.close({ isNeedToUpdate: true });
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }
}
