
export class MessageSaveModel {
  public MessageName: string;
  public MessageID: number;
  public EmailSubject: string;
  public FolderID_input: string;
  public FolderID: number;
  public FromName: string;
  public FromAddress: string;
  public ReplyToAddress: string;
  public HtmlContent: string;
  public MetaContent: string;
  public EmailContent: string;
  public IsUnlayer: boolean;

  constructor(init: any) {
    this.MessageName = init.MessageName;
    this.MessageID = init.MessageID;
    this.EmailSubject = init.EmailSubject;
    this.FolderID_input = init.FolderID_input;
    this.FolderID = init.FolderID;
    this.FromName = init.FromName;
    this.FromAddress = init.FromAddress;
    this.ReplyToAddress = init.ReplyToAddress;
    this.HtmlContent = init.HtmlContent;
    this.MetaContent  = init.MetaContent;
    this.EmailContent = init.EmailContent;
    this.IsUnlayer = init.IsUnlayer;
  }
}
