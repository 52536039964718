import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../_services/admin/auth/auth.service';
import { NotificationService } from '../_services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private notificationService: NotificationService) { }

  canActivate(): boolean  {
      if (this.authService.loggedIn()) {
        return true;
      }

      this.notificationService.showWarning('You\'ve been timed out.');
      this.router.navigate(['/login']);
  }
}
