import { Injectable } from '@angular/core';
import { ListFieldOptionModel } from './list-field-option-model';

@Injectable({
  providedIn: 'root'
})

export class ListFieldOptionModelAdapter {
  adapt(item: any): ListFieldOptionModel {
    return new ListFieldOptionModel(item);
  }
  createEmpty() {
    return new ListFieldOptionModel({
      OptionID: 0,
      FieldID: 0,
      OptionValue: '',
      IsSelected: false,
    });
  }
}
