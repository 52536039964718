import { NetworkTypeEnum } from './network-type-enum';

export class PostUpdateStatusModel {
  PostId: number;
  NetworkType: NetworkTypeEnum;

  constructor(init: any) {
    this.PostId = init.PostId;
    this.NetworkType = init.NetworkType
  }
}
