import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  loadingSpinner$: Subject<boolean> = new Subject<boolean>();

  loader(): Observable<boolean> {
    return this.loadingSpinner$.asObservable();
  }

  loading(): void {
    this.loadingSpinner$.next();
  }

  stopLoading(): void {
    this.loadingSpinner$.next(true);
  }
}


