import { Injectable } from '@angular/core';

import { NetworkGroupDto } from './network-group-dto';

@Injectable({
  providedIn: 'root'
})

export class NetworkGroupAdapter {
  public adapt(item: any[]): NetworkGroupDto[]  {  
    if (item && item.length > 0) {
      return item.length > 1
      ? item.map(networkGroup => new NetworkGroupDto(networkGroup))
      : [new NetworkGroupDto(item[0])];
    } else {
      return [];
    }
  }
  public adaptSingle(item: any): NetworkGroupDto {
    return new NetworkGroupDto(item);
  }
}
