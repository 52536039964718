export class FolderDto {

  public id: number;
  public name: string;
  public emailList: [];
  public smsList: []
  public emailNotDeletedCount: number;
  public smsNotDeletedCount: number;

  constructor(init: any) {
    this.id = init.FolderID;
    this.name = init.FolderName;
    this.emailList = init.Messages;
    this.smsList = init.SMSMessages;
    this.emailNotDeletedCount = init.EmailCount;
    this.smsNotDeletedCount = init.SMSCount;
  }
}




