import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { MessageReportsService } from 'src/app/_services/messaging/messages/message-reports.service';
import { DetailedEmailReportRequest } from 'src/app/_models/messaging/messages/message-reports/detailed-reports/detailed-email-report-request'
import { DetailedEmailReportModel } from 'src/app/_models/messaging/messages/message-reports/detailed-reports/detailed-email-report-model';
import { DateService } from 'src/app/_services/system/date.service';
import { Location } from '@angular/common';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { ModalDirective } from 'ng-uikit-pro-standard';
import SpinnerModalDisplay from 'src/app/views/shared/constants/modal-constants';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { NotificationService } from 'src/app/_services/notification.service';
import * as moment from 'moment';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { LoadingSpinnerService } from '../../../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-message-report-details-filtered',
  templateUrl: './message-report-details-filtered.component.html',
  styleUrls: ['./message-report-details-filtered.component.scss']
})
export class MessageReportDetailsFilteredComponent implements OnInit, AfterViewInit {
  _allColumns: DataTableColumn[] = [];
  _columns: DataTableColumn[] = [];
  _columnMode = ColumnMode;
  _detailedReport: DetailedEmailReportModel[];
  _exporting = false;
  _exportRows: { [prop: string]: any }[] = [];
  _filter: string;
  _messageTypeEnum: MessageTypeEnum;
  _pageTitle = '';
  _reportID: number;
  _rows: { [prop: string]: any }[] = [];
  _tableHasRows = false;
  _currentSelectedColumns = [];

  //Default column sorting value variables
  _sortColumn: string = 'MessageID';
  _sortDirection: string = 'Descending';

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;

  // Timeout variable for spinner modal
  _timeout: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private messageReportsService: MessageReportsService,
    private dateService: DateService,
    private location: Location,
    private paginationDataService: PaginationDataService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) { }

  ngOnInit(): void {
    // Get pagination data stored in a session
    this.paginationDataService.GetChildPaginationDataFromLocalStorage();
    this.paginationDataService.GetFolderIdFromLocalStorage();
    this.paginationDataService.GetListIdFromLocalStorage();

    this.activatedRoute.params.subscribe(params => {
      this._reportID = params.reportID;
      this._filter = params.filter;

      this._messageTypeEnum = this._filter.toLowerCase().indexOf('email') > -1 ? MessageTypeEnum.email : MessageTypeEnum.sms;
    });

    // trim leading and trailing spaces and add a space before the uppercase letters to make the title look nicer
    this._pageTitle = 'Detailed Report - ' + this._filter.replace(/([A-Z]+)/g, ' $1').trim();

    if (this.paginationDataService.childPaginationData.lastPage === 'Message-Report-Details-Filtered-' + this._pageTitle && this.paginationDataService.childPaginationData.page) {
      this._currentPage = this.paginationDataService.childPaginationData.page;
      this._pageLimit = this.paginationDataService.childPaginationData.pageLimit;
      this._sortColumn = this.paginationDataService.childPaginationData.sortByColumn;
      this._sortDirection = this.paginationDataService.childPaginationData.sortByDirection;
    }

    if (this.paginationDataService.childPaginationData.lastPage != 'Message-Report-Details-Filtered-' + this._pageTitle) {
      this.paginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, 'Message-Report-Details-Filtered');
    }
  }

  ngAfterViewInit() {
    this.getFilteredReport();
  }

  isChecked(col) {
    return this._columns.find(c => c.prop === col.prop && c.visible == col.visible) !== undefined;
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this._columns = this._columns.filter(c => c.prop !== col.prop);
    } else {
      this._columns = [...this._columns, col];
    }
  }

  // give the user the ability to sort the table
  // when doing so, reset the page back to the first page
  onSort($event: any) {
    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      this._sortDirection = "Descending";
    }
    else {
      this._sortDirection = "Ascending";
    }
    this.getFilteredReport(1, direction, $event.sorts[0].prop);
  }

  // allow the user to change the max number of rows showed in the table
  // set the page back to 1
  onLimitChange(limit: any): void {
    this._pageLimit = parseInt(limit);
    this._currentPage = 1;
    this.getFilteredReport();
  }

  // get specific filtered report based on whether or not the filter contains Email or SMS
  getFilteredReport(pageInfo?: any, sortDirection?: string, sortColumn?: string) {
    this.loadingSpinnerService.loading();

    if (sortDirection !== undefined) this._sortDirection = sortDirection;
    if (sortColumn !== undefined) this._sortColumn = sortColumn;
    var pageNumber = pageInfo ? pageInfo : 1;

    if (pageInfo) {
      this.paginationDataService.childPaginationData.page = pageInfo;
    }
    else if (this.paginationDataService.childPaginationData.page) {
      pageNumber = this.paginationDataService.childPaginationData.page;
    }

    this._currentPage = pageNumber;

    //if (this._filter.indexOf('Email') !== -1) {
      const pagerDtoRequest = new PagerDtoRequest({
        page: this._currentPage,
        pageSize: this._pageLimit,
        sortColumn: this._sortColumn,
        sortDirection: this._sortDirection
      });

      const getDetailedEmailReportRequest = new DetailedEmailReportRequest({
        pagerDtoRequest: pagerDtoRequest,
        reportId: this._reportID,
        filter: this._filter
      });

      this.messageReportsService.getDetailedReportData(getDetailedEmailReportRequest, this._messageTypeEnum).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {

          //Save Selected Columns if any - fixes selected column issues when sorting and changing pages
          if (this._columns.length > 0) this._currentSelectedColumns = this._columns;

          this._pageCount = executionResultDto.data.Total;
          this._tableHasRows = this._pageCount > 0;
          this._allColumns = this._columns = this._rows = [];

          if (!this._tableHasRows) {
            this.loadingSpinnerService.stopLoading();
            return;
          }

          //Searches and adds in any columns that was previously selected (if any) - fixes selected column issues when sorting and changing pages
          if (this._currentSelectedColumns.length > 0) {
            const search = columnName => this._currentSelectedColumns.find(element => element.prop === columnName);

            this._allColumns = executionResultDto.data.Columns.map(column => {
              return new DataTableColumn({
                prop: column.Name,
                name: column.DisplayName.replace(/([a-z])([A-Z])/g, '$1 $2'),
                visible: search(column.Name) ? true : false
              });
            });
          }
          else {
            this._allColumns = executionResultDto.data.Columns.map(column => {
              return new DataTableColumn({
                prop: column.Name,
                name: column.DisplayName.replace(/([a-z])([A-Z])/g, '$1 $2'),
                visible: column.Visible
              });
            });
          }

          this._columns = this._allColumns.filter(column => column.visible);
          this._rows = this.getRows(executionResultDto.data.Records);

        } else {
          this.loadingSpinnerService.stopLoading();
          this.notificationService.showError(executionResultDto.message);
        }
        this.loadingSpinnerService.stopLoading();
      });
    //}
  }

  getRows(records: any) {
    let rows: { [prop: string]: any }[] = [];
    rows = records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            row[key] = this.dateService.formatStringDate(record[key]);
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        }
      });
      return row;
    });
    return rows;
  }

  //Get Rows specifically for Exporting.
  //Sets user friendly column names to each column row so that the export is readable
  getRowsForExport(records: any) {
    let rows: { [prop: string]: any }[] = [];
    rows = records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
				var friendlyName = key.replace(/([a-z])([A-Z])/g, '$1 $2');
        if (this._columns.some(col => col.name === friendlyName)) {
					if (friendlyName.indexOf(',') != -1) friendlyName = friendlyName.replace(/,/g, ''); //remove commas from column names if they are any
          if (record[key] !== null && record[key] !== undefined) {
							if (this.isDate(record[key])) {
								row[friendlyName] = this.dateService.formatStringDate(record[key]);
							} else if (record[key].toString().toLowerCase() === 'true') {
								row[friendlyName] = 'Yes';
							} else if (record[key].toString().toLowerCase() === 'false') {
								row[friendlyName] = 'No';
							} else {
								row[friendlyName] = record[key];
							}
					} else {
						row[friendlyName] = record[key];
					}
        }
      });
      return row;
    });
    return rows;
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  // export the data displayed on screen in csv format
  // ensure the report downloaded has a filename that makes sense
  export() {
    this._exporting = true;

    const pagerDtoRequest = new PagerDtoRequest({
      page: 1,
      pageSize: 1000000,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    const getDetailedEmailReportRequest = new DetailedEmailReportRequest({
      pagerDtoRequest: pagerDtoRequest,
      reportId: this._reportID,
      filter: this._filter
    });

    this.messageReportsService.getDetailedReportData(getDetailedEmailReportRequest, this._messageTypeEnum).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {

        const today = new Date();
        const fileName = `${this._pageTitle}_${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

				this._exportRows = this.getRowsForExport(executionResultDto.data.Records);

				this._exporting = true;

				this.helperService.exportToCSV(fileName, this._exportRows);

      } else {
        this.notificationService.showError(executionResultDto.message);
        return;
      }
      this._exporting = false;
    });
  }

  // return to the previous page
  goBack() {
    this.location.back();
  }
}
