import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateConverterService {
  moment = moment;

  noOffsetConvert(date): string {
    return moment.utc(new Date(date).toISOString()).format('YYYY-MM-DDTHH:mm:ss');
  }
}
