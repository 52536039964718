import { Injectable } from '@angular/core';
import { WebFormViewModel } from './web-form-view-model';

@Injectable({
  providedIn: 'root'
})

export class WebFormViewModelAdapter {
  adapt(item: any): WebFormViewModel {
    return new WebFormViewModel(item);
  }

  adaptArray(init: Array<any>): WebFormViewModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new WebFormViewModel(table))
      : [new WebFormViewModel(init[0])];
    } else {
      return [];
    }
  }
}
