import { Injectable } from '@angular/core';
import { SmsMessageDto } from './sms-message-dto';

@Injectable({
  providedIn: 'root'
})

export class SmsMessageDtoAdapter {

  adapt(item: any): SmsMessageDto {
    return new SmsMessageDto(item);
  }
}
