import { Injectable } from '@angular/core';
import { SendViaApiInfo } from './send-via-api-info';

@Injectable({
  providedIn: 'root'
})

export class SendViaApiInfoAdapter {
  adapt(item: any): SendViaApiInfo {
    return new SendViaApiInfo(item);
  }

  createEmpty() {
    return new SendViaApiInfo({});
  }
}