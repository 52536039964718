<app-table [data]="rows"
           [length]="length"
           [tableKey]="tableKey"
           [sortColumn]="'ContactId'"
           [displayedColumns]="columnsTrialRun"
           (tableData)="trialRun($event)">
</app-table>

<div class="pt-4 footer">
  <button *ngIf="!exporting" [disabled]="!rows?.length" mdbBtn color="secondary" outline="true" rounded="true"
          size="sm" type="button" class="px-2 header-button mr-0" mdbWavesEffect (click)="export()">
    <mdb-icon fas size="lg" class="" icon="file-export"></mdb-icon>
  </button>
  <button *ngIf="exporting" disabled mdbBtn color="secondary" outline="true" rounded="true" size="sm" type="button"
          class="px-2 header-button mr-0" mdbWavesEffect>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>
  <button type="button"
          class="ml-2 btn-cancel-modal"
          data-dismiss="modal"
          (click)="dialogRef.close()">
    Close
  </button>
</div>


