import { Component, OnInit, OnDestroy } from '@angular/core';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ContactFieldDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-field-dto-adapter';
import { ContactFieldDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-field-dto';
import { ListControlTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-control-type-enum';
import { ListFieldTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-field-type-enum';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-contacts',
  templateUrl: './update-contacts.component.html',
  styleUrls: ['./update-contacts.component.scss']
})
export class UpdateContactsComponent implements OnInit, OnDestroy {
  
  public multipleOptions: Array<object> = [];
  public _dynamicFieldForm = this.formBuilder.group({
    fields: new UntypedFormArray([])
  });

  get form() { return this._dynamicFieldForm.controls; }
  get fields() { return this.form.fields as UntypedFormArray; }

  emptyFormFields() {      

    while (this.fields.length !== 0) {      
      this.fields.removeAt(0);
    }
    this.multipleOptions = [];
  }

  contactFields: Array<ContactFieldDto>;
  _contactFieldsDropdown: Array<any> = [];
  _setToFieldsDropdown: Array<any> = [];
  _listControlTypeEnum = ListControlTypeEnum;
  _listFieldTypeEnum = ListFieldTypeEnum;
  fieldChanges: Array<any> = [];

  _showUpdateTypes: boolean = false;
  _showUpdateTypesWithDate: boolean = false;
  booleanOptions: { value: string, label: string }[] = [{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }];
  _updateTypes: Array<any> = [
    { label: 'Set To Field', value: 'FieldName' },
    { label: 'Set To', value: 'RawValue' },
    { label: 'Reset', value: 'Reset' },
  ];
  _updateTypesWithDate: Array<any> = [
    { label: 'Set To Field', value: 'FieldName' },
    { label: 'Set To', value: 'RawValue' },
    { label: 'Reset', value: 'Reset' },
    { label: 'Current Date & Time', value: 'CurrentDateTimeUtc' },
  ];

  _sameValueSelected: boolean = false;

  constructor(
    private contactService: ContactService,
    private contactFieldDtoAdapter: ContactFieldDtoAdapter,
    private formBuilder: UntypedFormBuilder,
    public triggersService: TriggersService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.onChanges();

    this.route.data.subscribe(data => {      
      if (data.trigger) {        
        if (data.trigger.contactDtoData) {
          this.contactFields = this.contactFieldDtoAdapter.adaptArray(data.trigger.contactDtoData.data);          
        }
      }
    });
  }

  onChanges() {
     this.fields.valueChanges.subscribe(val => {
      if (this.contactFields) {
        val.forEach((field, index) => {
          this.setValues(field, index);
        });
      }
    });
  }

  ngOnDestroy() {    
    this.emptyFormFields();
  }  

  setValues(field: any, index: number) {

    if (field.name) {
      const contactField = this.contactFields.find(c => c.fieldName === field.name);
      if (this.fields.controls[index].get('displayName').value !== contactField.displayName) {
        this.fields.controls[index].get('displayName').setValue(contactField.displayName);
      }
      if (this.fields.controls[index].get('controlType').value !== contactField.controlTypeId) {
        this.fields.controls[index].get('controlType').setValue(contactField.controlTypeId);
      }
      if (this.fields.controls[index].get('fieldId').value !== contactField.fieldId) {
        this.fields.controls[index].get('fieldId').setValue(contactField.fieldId);
      }
      if (contactField.controlTypeId === this._listControlTypeEnum.DatePicker ||
          contactField.controlTypeId === this._listControlTypeEnum.DateTimePicker) {
            if (this.fields.controls[index].get('dateTime').value !== true) {
              this.fields.controls[index].get('dateTime').setValue(true);
            }
          } else {
            if (this.fields.controls[index].get('dateTime').value !== false) {
              this.fields.controls[index].get('dateTime').setValue(false);
            }
          }
      if (field.updateType !== null) {
        const updateType = this.fields.controls[index].get('updateType').value;
        const oldUpdateType = this.fields.controls[index].get('oldUpdateType').value;
        if (updateType && updateType !== oldUpdateType) {
          this.fields.controls[index].get('oldUpdateType').setValue(updateType);
          this.getInputType(field, index);
          this.populateOptions(field.updateType, contactField.fieldId,
            contactField.controlTypeId, index, this.fields.controls[index] as UntypedFormGroup);
        }
      }
    }

  }

  getContactFields(listID: number) {
    if (listID) {
      this.contactService.getContactFields(listID).subscribe(result => {
        this.contactFields = this.contactFieldDtoAdapter.adaptArray(result.data);
        this.populateFieldDropdown(); 
        this.fields.value.forEach((field, index) => {          
          this.setValues(field, index);
        });
      });
    }
  }

  addField() {
    this.fields.push(this.formBuilder.group({
      name: [],
      displayName: [],
      fieldId: [],
      dateTime: [],
      controlType: [],
      inputType: [],
      oldInputType: [],
      updateType: [],
      oldUpdateType: [],
      argumentFromEdit: [],
    }));
    this.multipleOptions.push([]);
  }

  removeField(index: number) {
    this.fields.removeAt(index);

    if (index > -1) {
      this.multipleOptions.splice(index, 1);
    }
  }

  populateFieldDropdown() {
    this._contactFieldsDropdown = [];
    this.contactFields.forEach((field: ContactFieldDto) => {
      this._contactFieldsDropdown = [ ... this._contactFieldsDropdown, {
        value: field.fieldName,
        label: field.displayName,
      }];
      this._setToFieldsDropdown = [ ... this._setToFieldsDropdown, {
        value: field.fieldName,
        label: field.displayName,
      }];
    });
  }

  getInputType(field, index) {
    const contactFieldDto = this.contactFields.find(listField => field.name === listField.fieldName);
    let inputType = 'text';

    if (contactFieldDto.fieldTypeId === this._listFieldTypeEnum.Number ||
        contactFieldDto.fieldTypeId === this._listFieldTypeEnum.Mobile ||
        contactFieldDto.fieldTypeId === this._listFieldTypeEnum.Currency) {
      inputType = 'number';
    } else if (contactFieldDto.fieldTypeId === this._listFieldTypeEnum.Email ||
               contactFieldDto.fieldTypeId === this._listFieldTypeEnum.Date ||
               contactFieldDto.fieldTypeId === this._listFieldTypeEnum.Time) {
      inputType = contactFieldDto.fieldTypeName.toLowerCase();
    }
    this.fields.controls[index].get('inputType').setValue(inputType);
  }

  populateOptions(updateType: string, fieldId: number,
                  controlType: ListControlTypeEnum,
                  index: number,
                  formGroup: UntypedFormGroup) {
    var routeContactFields: Array<ContactFieldDto>;
    var editing = false;
    this.route.data.subscribe(data => {
      if (data.trigger) {
        if (data.trigger.contactDataDto) {
          routeContactFields = this.contactFieldDtoAdapter.adaptArray(data.trigger.contactDtoData.data);
          editing = true;
        }
      }
    });
    if (controlType === this._listControlTypeEnum.Checkbox &&
        formGroup.get('argument') !== null) {
          formGroup.removeControl('argument');
    }
    if (controlType === this._listControlTypeEnum.Checkbox &&
        updateType === 'RawValue') {
          formGroup.addControl('argument', new UntypedFormArray([]));
    } else {
      formGroup.addControl('argument', new UntypedFormControl()); 
      if (formGroup.get('argumentFromEdit') && formGroup.get('argumentFromEdit').value) {
        if (controlType === this._listControlTypeEnum.DateTimePicker) {
          formGroup.get('argument').setValue(formGroup.get('argumentFromEdit').value.replace(' ', 'T'));
        } else {
          formGroup.get('argument').setValue(formGroup.get('argumentFromEdit').value);
        }
      }
    }

    var optionToPush = [];
    var field: ContactFieldDto;
    if (this.contactFields) {
      field = this.contactFields.find(x => x.fieldId === fieldId);
    } else {
      field = routeContactFields.find(x => x.fieldId === fieldId);
    }
    if (controlType === this._listControlTypeEnum.Dropdown ||
        controlType === this._listControlTypeEnum.Checkbox ||
        controlType === this._listControlTypeEnum.RadioButton) {
          field.options.forEach(option => {
            switch (controlType) {
              case this._listControlTypeEnum.Dropdown:
                optionToPush = [ ... optionToPush, {
                  value: option.optionValue,
                  label: option.optionValue
                }];
                break;
              case this._listControlTypeEnum.Checkbox:
                if (formGroup.get('argumentFromEdit').value) {
                  const values = formGroup.get('argumentFromEdit').value;
                  var valueOptions = [];
                  valueOptions = values.split(',');
                  var checked = false;

                  if (valueOptions.find(o => {
                    return o === option.optionValue;
                  })) {
                    checked = true;
                  }

                  optionToPush = [ ... optionToPush, {
                    id: option.optionId,
                    value: option.optionValue,
                    label: option.optionValue,
                    checked: checked,
                  }];
                } else {
                  optionToPush = [ ... optionToPush, {
                    id: option.optionId,
                    value: option.optionValue,
                    label: option.optionValue,
                    checked: false,
                  }];
                }
                break;
              case this._listControlTypeEnum.RadioButton:
                optionToPush = [ ... optionToPush, {
                  value: [option.optionId, option.optionValue],
                  label: option.optionValue
                }];
                break;
            }
          });
    }
    if (controlType === this._listControlTypeEnum.Checkbox) {
      var checkboxOpts = this.formBuilder.group({args: new UntypedFormArray([])});
      optionToPush.forEach(() => this.getControlsFromFormGroups(checkboxOpts).args.push(new UntypedFormControl(false)));
    }
    if (field.fieldTypeName == "Boolean") {
      optionToPush = [...this.booleanOptions]
    }
    this.multipleOptions[index] = optionToPush;
    if (formGroup.get('argumentFromEdit').value) {
      if (controlType === this._listControlTypeEnum.Checkbox) {
        const checkboxValues = formGroup.get('argumentFromEdit').value.split(',');
        const formArray: UntypedFormArray = formGroup.get('argument') as UntypedFormArray;
        checkboxValues.forEach(value => {
          formArray.push(new UntypedFormControl(value));
        });
      } else if (controlType === this._listControlTypeEnum.Dropdown) {
        formGroup.get('argument').setValue(formGroup.get('argumentFromEdit').value);
      }
    }
  }

  onCheckChange(event, formGroup) {
    const formArray: UntypedFormArray = formGroup.get('argument') as UntypedFormArray;
    /* Selected */
    if (event.checked) {
      // Add a new control in the arrayForm
      formArray.push(new UntypedFormControl(event.element.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;
      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value === event.element.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }

  getControlsFromFormGroups(group) {
    return group.controls;
  }

  contactValueSelected(event, formGroup: UntypedFormGroup) {
    if (formGroup.get('argument') !== null) {
      formGroup.get('argument').setValue(null);
    }
    formGroup.get('updateType').setValue(null);
    const formArray = this.getControlsFromFormGroups(this.fields);
    const sameValues = formArray.filter(data => data.controls.name.value === event.value && event.value != null);

    if (sameValues.length > 1) {
      this._sameValueSelected = true;
    } else {
      this._sameValueSelected = false;
    }
  }
}
