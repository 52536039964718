export class AddProfileDtoRequest {

  public userId: string;
  public accessToken: string;

  constructor(userId: string, accessToken: string) {
    this.userId = userId;
    this.accessToken = accessToken;
  }
}
