import { MessageCopyMoveDetailsDto } from './message-copy-move-details-dto';
import { MessageCopyMoveFindReplaceDto } from './message-copy-move-find-replace-dto';

export class MessageCopyMoveDto {
  public IsCopy: boolean;
  public IsDifferentClient: boolean;
  public ClientId: number;
  public OldClientId: number;
  public FolderId: number;
  public Messages: MessageCopyMoveDetailsDto[];
  public FindReplace: MessageCopyMoveFindReplaceDto[];

  /**
   *
   */
  constructor(init: any) {
    this.IsCopy = init.IsCopy;
    this.IsDifferentClient = init.IsDifferentClient;
    this.ClientId = init.ClientId;
    this.OldClientId = init.OldClientId;
    this.FolderId = init.FolderId;
    this.Messages = init.Messages;
    this.FindReplace = init.FindReplace;

  }
}
