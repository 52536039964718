export class MessageCodeStepDto {
    description: string;
    listId: number;
    messageCodeId: number;
    messageCodeStepId: number;
    startDate: Date;
    sql: string;
    endDate: Date;
    criteriaCode: string;
    orderOfPrecedence: number;

    constructor(init: any) {

        this.description = init.Description;
        this.listId = init.ListID;
        this.messageCodeId = init.MessageCodeID;
        this.messageCodeStepId = init.MessageCodeStepID;
        this.startDate = init.StartDate;
        this.sql = init.Sql;
        this.endDate = init.EndDate;
        this.criteriaCode = init.CriteriaCode;
        this.orderOfPrecedence = init.OrderOfPrecedence;
    }
}