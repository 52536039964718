import { Injectable } from '@angular/core';

export class ClientsDto {
  public ClientID: number;
  public Name: string;
  public DatabaseName: string;
  public AccountManagerUserId: number;
  public KnownAs: string;
  public WebsiteUrl: string;
  public PrimaryContact: string;
  public ClientImageUrl: string;
  public Active: boolean;

  constructor(init: any) {
    this.ClientID = init.ClientID;
    this.Name = init.Name;
    this.DatabaseName = init.DatabaseName;
    this.AccountManagerUserId = init.AccountManagerUserId;
    this.KnownAs = init.KnownAs;
    this.WebsiteUrl = init.WebsiteUrl;
    this.PrimaryContact = init.PrimaryContact;
    this.ClientImageUrl = init.ClientImageUrl;
    this.Active = init.Active;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ClientsAdapter {
  adapt(item: any) {
    return new ClientsDto(item);
  }
}
