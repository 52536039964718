
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserPermissionService } from 'src/app/_services/admin/users/user-permission-service';
import { UserPermissionTypeService } from 'src/app/_services/admin/users/user-permission-type-service';
import { UserPermissionDto } from 'src/app/_models/admin/users/user-permission-model';
import { UserPermissionTypeDto } from 'src/app/_models/admin/users/user-permission-type-model';
import { PermissionsAllAvailableDto } from 'src/app/_models/admin/users/permissions-all-available-model';
import { ActivatedRoute } from '@angular/router';
import { UserManagementService } from 'src/app/_services/admin/users/user-management.service'
import { ExecutionResultDto } from 'src/app/_models/execution-result-model'

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css']  
})
export class UserPermissionComponent implements OnInit {

  _userPermissionTypeDtos: UserPermissionTypeDto[] = []
  _userPermissionDtos: UserPermissionDto[] = []
  _permissionsAllAvailableDto: PermissionsAllAvailableDto;
  _userFullName: string;
  _userId: number; 
  _permissionKeyForView: number = 1;  
  
  constructor(
    private userPermissionService: UserPermissionService, 
    private userPermissionTypeService: UserPermissionTypeService,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this._userId = parseInt(this.route.snapshot.paramMap.get("userId"));

    this.userManagementService.getUser(this._userId).subscribe((data : any) => {
      this._userFullName = `${data.FirstName} ${data.LastName}`;      
    });    

    this.userPermissionService.get(this._userId).subscribe(data => {
      this._userPermissionDtos = data;
    });

    this.userPermissionTypeService.get().subscribe(data => {
      this._userPermissionTypeDtos = data;
    });    

    this.userPermissionService.getAllAvailable().subscribe(data => {
      this._permissionsAllAvailableDto = data;
    });  
    
  }

  postUserPermission(userPermissionDto: UserPermissionDto)  {
    this.userPermissionService.post(userPermissionDto).subscribe((executionResultDto: ExecutionResultDto) => {       
       userPermissionDto.id = +executionResultDto.data;       
       this.addUserPermissionIfNotExists(userPermissionDto)
    });
  }  

  deleteUserPermission(id: number) {
    this.userPermissionService.delete(id).subscribe(() => {
      this.removeUserPermission(id)
    });
  }

  removeUserPermission(id: number) {
    if (this._userPermissionDtos.some(_ => _.id == id)) {
        this._userPermissionDtos = this._userPermissionDtos.filter(_ => _.id != id);        
    };   
  }

  addUserPermissionIfNotExists(userPermissionDto: UserPermissionDto) {          
    if (!this._userPermissionDtos.some(_ => _.id == userPermissionDto.id)) {
      this._userPermissionDtos.push(userPermissionDto);
    };    
  }

  toggleHideNonViewCheckboxes(hide: boolean, sectionId: number, pageId: number) { 
    
    const elements = document.querySelectorAll(`div[id^='div-${sectionId}-${pageId}-']`);

    for(let i=0; i < elements.length; i++) {        
       if (elements[i].getAttribute("title").toLowerCase() !== 'view') { 
        const style = hide ? "none" : "inline"
        elements[i].setAttribute("style", `display:${style} !important`);
      }
    }
  }
  
  handleCheckBoxChange(e) {  

    const isChecked = e.target.checked;

    const checkboxValueArray = e.target.value.split('-');     
    const pageSectionId: number = +checkboxValueArray[0];
    const pageId: number = +checkboxValueArray[1];
    const permissionKey: number = +checkboxValueArray[2];

    let userPermissionDto = this.findUserPermission(pageSectionId, pageId);

    if (permissionKey === this._permissionKeyForView) {  
      this.toggleHideNonViewCheckboxes(!isChecked, pageSectionId, pageId);
      if (!isChecked) {
          this.deleteUserPermission(userPermissionDto.id)
          return;
      }
    }       

    if (userPermissionDto) 
        !isChecked ? userPermissionDto.permissionKey -= permissionKey : userPermissionDto.permissionKey += permissionKey;
    else 
        userPermissionDto = new UserPermissionDto({ Id: 0, PageSectionId: pageSectionId, PageId: pageId, UserId: this._userId, PermissionKey: permissionKey})
              
    this.postUserPermission(userPermissionDto) 
    
  }

  permissionTypeIsVisible (permissionKey: number, permissionKeyForPage: number): boolean {
    return permissionKey <= permissionKeyForPage;
  }

  hasPermission (pageSectionId: number, pageId: number, permissionKey: number): boolean { 
    var userPermissionDto = this.findUserPermission(pageSectionId, pageId)
    return (userPermissionDto) ?  (userPermissionDto.permissionKey & permissionKey) > 0 : false;
  }  

  findUserPermission (pageSectionId: number, pageId: number) : UserPermissionDto {       
    return this._userPermissionDtos.find(u => u.userId == this._userId &&
                                         u.pageSectionId == pageSectionId && 
                                         u.pageId == pageId);
  } 
}
