import { S3ObjectsModel } from './S3Models/S3Objects.model';

export class MoveCopyFilesDto {
  Action: string;
  BucketName: string;
  SourceFolderKey: string;
  DestinationFolderKey: string;
  Files: Array<string>;

  /**
   *
   */
  constructor(init: any) {
    this.Action = init.Action,
    this.BucketName = init.BucketName,
    this.SourceFolderKey = init.SourceFolderKey,
    this.DestinationFolderKey = init.DestinationFolderKey,
    this.Files = init.Files

  }
}
