import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { AppComponent } from 'src/app/app.component';
import { NavigationService } from 'src/app/_services/system/Navigations/navigation.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { Subscription } from 'rxjs';
import { UnsubscribeService } from '../../_services/system/unsubscribe.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, AfterViewInit, OnDestroy {
  userPermissions: PermissionsModel;
  permissionSubscribe: Subscription;
  firstName = '';
  lastName = '';
  userIconUrl = '';
  hasClients = false;
  userClients: any;
  currentClient: number;

  @ViewChild('clientSelect') clientSelect;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private appComponent: AppComponent,
    private navigationService: NavigationService,
    private permissionService: PermissionsService,
    private authService: AuthService,
    private paginationService: PaginationDataService
  ) { }

  ngOnInit() {
    
    this.firstName = this.localStorageService.getFirstName;
    this.localStorageService.FirstName.subscribe((FirstName: string) => {
      this.firstName = FirstName;
    });

    this.lastName = this.localStorageService.getLastName;
    this.localStorageService.LastName.subscribe((LastName: string) => {
      this.lastName = LastName;
    });

    this.userIconUrl = this.localStorageService.getUserIconUrl;
    this.localStorageService.UserIconUrl.subscribe((UserIconUrl: string) => {
      this.userIconUrl = UserIconUrl;
    });

    this.currentClient = +this.localStorageService.getCurrentClientId;
    this.localStorageService.CurrentClientId.subscribe((CurrentClient: number) => {
      if (this.currentClient !== CurrentClient) {
        this.currentClient = +CurrentClient;
      }
    });

    this.navigationService.userClients.subscribe(userClient => {
      if (userClient.length > 0) {
        this.hasClients = true;
      }
      else {
        this.currentClient = 0;
      }

      this.userClients = userClient;
    });
  
    this.permissionService.permissionsModel.subscribe((permissions: PermissionsModel) => {
      this.userPermissions = permissions;
    })
    
    if (localStorage.getItem("Token") !== null) {
      this.navigationService.updateUserClients();
      if (!this.userPermissions) {
        this.userPermissions = this.permissionService.getPermissionsModel();
      }
    }    
  }

  ngAfterViewInit(): void {
    this.currentClient = +this.localStorageService.getCurrentClientId;
    this.localStorageService.CurrentClientId.subscribe((CurrentClient: number) => {
      if (this.currentClient !== CurrentClient) {
        this.currentClient = +CurrentClient;
        this.clientSelect.select(this.currentClient);
      }

    });
  }

  async logout() {
    await this.authService.awsLogOut();
    localStorage.clear();
    this.appComponent.stopIdle();
    this.permissionService.setPermissionsModel = null;
    this.userClients = null;
    this.router.navigate(['/login']);
  }

  changedCurrentClient(value: any) {
    if (this.paginationService.paginationData) { this.paginationService.ClearPaginationDataFromLocalStorage(); }
    if (this.paginationService.childPaginationData) { this.paginationService.ClearChildDataFromLocalStorage(); }
    this.localStorageService.setCurrentClientId = value;
    const win = window.location;
    window.location.href = `${win.protocol}//${win.host}`;
  }

  ngOnDestroy() {
    UnsubscribeService.unsubscribe(this.permissionSubscribe);
  }
}
