<div class="mb-3 mt-3">The following {{ type }} will be {{ isCopy ? 'copied' : 'moved' }}:</div>
<div *ngFor="let message of copyMoveList; let i = index" class="bold">
  <div class="row">
    <div *ngIf="tableTypes[tableKey] === tableTypes.messages || isCopy" class="col-md-1">
      <a (click)="showMessageNameInput(message, i)">
        <i class="far fa-edit"></i>
      </a>
    </div>
    <div *ngIf="!message.checked" class="col-md-11">
      {{ copyMoveFormArray?.controls[i].value }}
    </div>
    <div *ngIf="message.checked" class="col-md-11">
      <input [formControl]="$any(copyMoveFormArray?.controls[i])"
             (focusout)="focusoutInputName(i)"
             (keydown.enter)="enterSubmit(i)"
             type="text" id="input{{ i }}"
             class="form-control">
    </div>
  </div>
</div>
