export class LightListFieldDto {
  public FieldID: number;
  public DisplayName: string;
  public ColumnName: string;
  public SqlType: string;

  constructor(init: any) {
    this.FieldID = init.FieldID;
    this.DisplayName = init.DisplayName;
    this.ColumnName = init.ColumnName;
    this.SqlType = init.SqlType;
  }
}
