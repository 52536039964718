import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ListTypeModel } from '../../../../_models/messaging/lists-and-contacts/lists/list-type-model';
import { ListsService } from '../../../../_services/messaging/lists-and-contacts/lists/lists.service';
import { SelectListOption } from '../../../../_models/system/select-list-option';
import { ListModel } from '../../../../_models/messaging/lists-and-contacts/lists/list-model';
import { ListModelAdapter } from '../../../../_models/messaging/lists-and-contacts/lists/list-model.adapter';
import { NotificationService } from '../../../../_services/notification.service';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { MessagingHeaderService } from '../../../../_services/messaging/messaging-header/messaging-header.service';

@Component({
  selector: 'app-create-list-modal',
  templateUrl: './create-list-modal.component.html',
})
export class CreateListModalComponent implements OnInit {
  createListFormGroup: FormGroup;
  listTypeOptions: SelectListOption[] = [];
  listFolderOptions: SelectListOption[];
  title: string;

  constructor(
    private listsService: ListsService,
    private listModelAdapter: ListModelAdapter,
    private notificationService: NotificationService,
    private messagingHeaderService: MessagingHeaderService,
    public dialogRef: MatDialogRef<CreateListModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = 'Create ' + this.data.title.slice(0, -1);
  }

  ngOnInit(): void {
    this.listFolderOptions = this.data.folders;

    this.createListFormGroup = new UntypedFormGroup({
      folder: new UntypedFormControl(this.data.selectedFolder && this.data.selectedFolder !== 0 ? this.data.selectedFolder : '', Validators.required),
      type: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      comment: new UntypedFormControl(''),
    });

    this.listsService.getAllListTypes().subscribe((result: ExecutionResultDto) => {
      result.data.forEach((listType: ListTypeModel) => {
        this.listTypeOptions = [... this.listTypeOptions, {
          value: listType.Value,
          label: listType.Name,
        }];
      });
    });
  }

  createList(): void {
    const newList: ListModel = this.listModelAdapter.createEmpty();
    newList.FolderID = this.createListFormGroup.get('folder').value;
    newList.ListType = this.createListFormGroup.get('type').value;
    newList.ListName = this.createListFormGroup.get('name').value;
    newList.Comment = this.createListFormGroup.get('comment').value;

    if (this.data.data.some(x => x.ListName.toLowerCase() === newList.ListName.toLowerCase() && x.FolderID === newList.FolderID)) {
      this.notificationService
        .showWarning(`A ${newList.ListName} with this name already exists in the selected folder. ${newList.ListName} names must be unique per folder`);

      return;
    }

    this.listsService.createEditList(newList).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        const folderId = this.createListFormGroup.get('folder').value;
        this.createListFormGroup.reset();
        this.messagingHeaderService.setFolder(folderId);
        this.dialogRef.close(true);
      } else {
        this.notificationService.showError(executionResultDto.message);
        this.dialogRef.close();
      }
    });
  }
}
