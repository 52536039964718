import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { ActivatedRoute } from '@angular/router';
import { DataTableColumn } from '../../../../../_models/messaging/datatable-column';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { MessageCodeService } from 'src/app/_services/messaging/automation/message-codes/message-code.service';
import { MessageCodeFolderService } from 'src/app/_services/messaging/automation/message-codes/message-code-folder.service';
import { MessageCodeStepGridRecordDtoAdapter } from 'src/app/_models/messaging/automation/message-codes/message-code-step-grid-record-dto-adapter';
import { MessageCodeStepGridRecordDto } from 'src/app/_models/messaging/automation/message-codes/message-code-step-grid-record-dto';
import { MessageCodeDtoAdapter } from 'src/app/_models/messaging/automation/message-codes/message-code-dto.adapter';
import { MessageCodeDto } from 'src/app/_models/messaging/automation/message-codes/message-code-dto';
import { MessageCodeStepService } from 'src/app/_services/messaging/automation/message-codes/message-code-step.service';
import { MessageCodeRequestDto } from 'src/app/_models/messaging/automation/message-codes/message-code-request-dto';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { ListFolderModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model.adapter';
import { LightListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model.adapter';
import { CreateEditMessageCodeComponent } from './create-edit-message-code-step/create-edit-message-code.component';
import { CopyMessageCodeStepRequestDto } from 'src/app/_models/messaging/automation/message-codes/copy-message-code-step-request-dto';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { DatePipe } from '@angular/common';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { LoadingSpinnerService } from '../../../../../_services/loading-spinner/loading-spinner.service';
import {
  ListFolderService
} from '../../../../../_services/messaging/lists-and-contacts/list-folders/list-folder.service';


@Component({
  selector: 'app-edit-message-code',
  templateUrl: 'edit-message-code.component.html',
  styleUrls: ['edit-message-code.component.scss']
})

export class EditMessageCodeComponent implements OnInit {

  @ViewChild('copyStepModal', { static: true }) copyStepModal: ModalDirective;
  @ViewChild('deleteStepModal', { static: true }) deleteStepModal: ModalDirective;
  @ViewChild('selectListModal', { static: true }) selectListModal: ModalDirective;
  @ViewChild('createEditMessageCodeModal', { static: true }) createEditMessageCodeModal: ModalDirective;
  @ViewChild('createEditMessageCodeComponent', { static: true }) createEditMessageCodeComponent: CreateEditMessageCodeComponent;
  @ViewChild('trialRunModal', { static: true }) trialRunModal: ModalDirective;

  _columnMode = ColumnMode;
  _columns: DataTableColumn[] = [];
  _columnsTrialRun: DataTableColumn[] = [];
  _configuratorFormGroup: UntypedFormGroup;
  _copyStepFormGroup: UntypedFormGroup;
  _folderSelectListOptions: SelectListOption[] = [];
  _messageTypeEnumEmail = MessageTypeEnum.email;
  _messageTypeEnumSms = MessageTypeEnum.sms;
  _rows: MessageCodeStepGridRecordDto[] = [];
  _rowsTrialRun: { [prop: string]: any }[] = [];
  _selectedStep: MessageCodeStepGridRecordDto;
  _selectedMessageCode: MessageCodeDto;
  _selectedMessageCodeId = 0;
  _selectedStepIndex = 0;
  _stepsFormGroup: UntypedFormGroup;
  _subscriptionLists: Subscription;
  _subscriptionShowListMessages: Subscription;
  _tableHasRows = false;
  _userPermissions: PermissionsModel;
  listForStepFormGroup: UntypedFormGroup;
  _listFolderDropdown: Array<any>;
  _listDropdown: Array<any>;

  //Default column sorting value variables
  _sortColumn: string = 'ContactID';
  _sortDirection: string = 'Ascending';

  // Trial Run modal variables
  _pageCountTrialRun = 0;
  _pageLimitTrialRun = 10;
  _pageOffsetTrialRun = 0;
  _currentPageTrialRun = 1;
  _tableHasRowsTrialRun = false;
  _selectedRowIndex: number;

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions.ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;


  // Timeout variable for spinner modal
  _timeout: number;

  // variables for pagination data
  _pageTitle: 'Edit-Message-Code';

  constructor(
    private messageCodeFolderDtoAdapter: FolderDtoAdapter,
    private messageCodeFolderService: MessageCodeFolderService,
    private stepGridRecordDtoAdapter: MessageCodeStepGridRecordDtoAdapter,
    private messageCodeDtoAdapter: MessageCodeDtoAdapter,
    private messageCodeService: MessageCodeService,
    private messageCodeStepService: MessageCodeStepService,
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private listsService: ListsService,
    private listFolderService: ListFolderService,
    private listFolderModelAdapter: ListFolderModelAdapter,
    private lightListModelAdapter: LightListModelAdapter,
    private contactService: ContactService,
    private datePipe: DatePipe,
    private paginationDataService: PaginationDataService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {
    this._selectedMessageCodeId = parseInt(this.route.snapshot.paramMap.get('messageCodeId') || "0");
  }

  ngOnInit() {

    // Get pagination data stored in a session
    this.paginationDataService.GetChildPaginationDataFromLocalStorage();
    this.paginationDataService.GetChildFolderIdFromLocalStorage();
    this.paginationDataService.GetChildListIdFromLocalStorage();

    if (this.paginationDataService.childPaginationData.lastPage === this._pageTitle && this.paginationDataService.childPaginationData.page) {

      this._currentPage = this.paginationDataService.childPaginationData.page;
      this._pageLimit = this.paginationDataService.childPaginationData.pageLimit;
    }

    if (this.paginationDataService.childPaginationData.lastPage != this._pageTitle) {
      this.paginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, '', '', this._pageTitle);
    }

    this._userPermissions = this.permissionsService.getPermissionsModel();

    this.createEditMessageCodeModal.closed.subscribe(() => {
      this.createEditMessageCodeComponent.messageCodeForm.reset();
      this.createEditMessageCodeComponent.messageCodeRules = '';
      this.createEditMessageCodeComponent.setStandardFormValues();
    });

    // reset the trial run modal table back to the original values for when it is next opened
    this.trialRunModal.closed.subscribe(() => {
      this._pageCountTrialRun = 0;
      this._pageLimitTrialRun = 10;
      this._pageOffsetTrialRun = 0;
      this._currentPageTrialRun = 1;
      this._tableHasRowsTrialRun = false;
    });

    this.initForms();
  }

  ngAfterViewInit() {
    this.getMessageCode();

    this.getListFolders();
  }

  copyStepSubmit() {

    const copyMessageCodeStepRequestDto = new CopyMessageCodeStepRequestDto({
      messageCodeStepId: this._selectedStep.messageCodeStepId,
      criteriaCode: this._copyStepFormGroup.get('criteriaCode').value
    });

    this.messageCodeStepService.copy(copyMessageCodeStepRequestDto).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.getSteps();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });

    this.copyStepModal.hide();

  }

  confirmDeleteStep(id: number) {

    this._selectedStep = this.getStep(id);

    this.deleteStepModal.show();
  }

  confirmCopyStep(id: number) {

    this._selectedStep = this.getStep(id);

    this._copyStepFormGroup.get('criteriaCode').setValue(this._selectedStep.criteriaCode);

    this.copyStepModal.show();
  }

  configuratorFormGroupSubmit() {

    const messageCodeRequestDto = new MessageCodeRequestDto(this._configuratorFormGroup.value);

    this.messageCodeService.post(messageCodeRequestDto).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  deleteStep() {

    this.messageCodeStepService.delete(this._selectedStep.messageCodeStepId).subscribe((executionResultDto: ExecutionResultDto) => {

      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this._rows = this._rows.filter(x => x.messageCodeStepId !== this._selectedStep.messageCodeStepId);
        this._selectedStep = null;
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });

    this.deleteStepModal.hide();
  }

  folderSelected($event) {
    this._configuratorFormGroup.get('folderId').setValue($event.value);
    this.paginationDataService.SetChildFolderIdToLocalStorage($event.value);
  }

  getStep(id: number) {
    return this._rows.find(x => x.messageCodeStepId === id);
  }

  getStepIndex(id: number) {
    return this._rows.findIndex(x => x.messageCodeStepId === id);
  }

  getMessageCode() {
    this.messageCodeService.get(this._selectedMessageCodeId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const messageCodeDto = this.messageCodeDtoAdapter.adapt(executionResultDto.data);
        this._selectedMessageCode = messageCodeDto;
        this._configuratorFormGroup.setValue(messageCodeDto);
        this.populateFoldersDropdown();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getSteps(pageInfo?: any) {
    this.loadingSpinnerService.loading();

    var pageNumber = pageInfo ? pageInfo : 1;

    if (pageInfo) {
      this.paginationDataService.childPaginationData.page = pageInfo;
    }
    else if (this.paginationDataService.childPaginationData.page) {
      pageNumber = this.paginationDataService.childPaginationData.page;
    }

    this._currentPage = pageNumber;
    const pagerDtoRequest = new PagerDtoRequest({
      page: pageNumber,
      pageSize: this._pageLimit,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });


    this.paginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, '', '', this._pageTitle);


    this.messageCodeStepService.getAll(pagerDtoRequest, this._selectedMessageCodeId).subscribe(async (executionResultDto: ExecutionResultDto) => {

      if (executionResultDto.executionResult === ExecutionResult.success) {

        if (executionResultDto.data.Total / this._pageLimit < (pageNumber - 1)) {
          pageNumber = 1;
          pagerDtoRequest.page = 1;
          this._currentPage = 1;
          const execResultDto = await this.messageCodeStepService.getAll(pagerDtoRequest, this._selectedMessageCodeId).toPromise();
          executionResultDto = execResultDto;
        }

        this._pageCount = executionResultDto.data.Total;
        this._pageOffset = pageNumber - 1;

        this._tableHasRows = this._pageCount > 0;

        this._columns = [];
        this._rows = [];

        if (!this._tableHasRows) {
          this.loadingSpinnerService.stopLoading();
          return;
        }

        this._rows = executionResultDto.data.Steps.map(step => (this.stepGridRecordDtoAdapter.adapt(step)));

        this._columns = [new DataTableColumn({ prop: 'criteriaCode', name: 'Code' }),
        new DataTableColumn({ prop: 'startDate', name: 'Valid From Date' }),
        new DataTableColumn({ prop: 'endDate', name: 'Valid To Date' }),
        new DataTableColumn({ prop: 'listName', name: 'List Name' }),
        new DataTableColumn({ prop: 'description', name: 'Description' }),
        new DataTableColumn({ prop: 'sql', name: 'SQL' })];
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();

    }, error => {
      this.loadingSpinnerService.stopLoading();
    });
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  initForms() {
    this._configuratorFormGroup = new UntypedFormGroup({
      messageCodeId: new UntypedFormControl(0, Validators.required),
      title: new UntypedFormControl('', Validators.required),
      folderId: new UntypedFormControl(0, Validators.required)
    });

    this._copyStepFormGroup = new UntypedFormGroup({
      criteriaCode: new UntypedFormControl('', Validators.required),
    });

    this._stepsFormGroup = new UntypedFormGroup({});

    this.listForStepFormGroup = new UntypedFormGroup({
      folderId: new UntypedFormControl('', Validators.required),
      listId: new UntypedFormControl('', Validators.required),
    });
  }

  onSaved() {
    this.createEditMessageCodeModal.hide();
    this.createEditMessageCodeComponent.messageCodeForm.reset();
    this.createEditMessageCodeComponent.setStandardFormValues();
    this.getSteps();
  }

  populateFoldersDropdown() {
    this.messageCodeFolderService.getAll().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const folderDtos = executionResultDto.data.map((folderDto: FolderDto) => this.messageCodeFolderDtoAdapter.adapt(folderDto));

        this._folderSelectListOptions = [];

        this._folderSelectListOptions = this._folderSelectListOptions.concat(folderDtos.map((folderDto: FolderDto) =>
          new SelectListOption(folderDto.id, folderDto.name)));

        this._configuratorFormGroup.get('folderId').setValue(this._selectedMessageCode.folderId);

        this.getSteps();

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getListFolders() {
    this.listFolderService.getAll().subscribe(result => {
      const listFolders = this.listFolderModelAdapter.adaptArray(result.data);
      this.populateListFolderDropdown(listFolders);
    });
  }

  getLists(folderID: number) {
    this.listsService.getAllLightLists(folderID).subscribe(result => {
      const lists: Array<LightListModel> = this.lightListModelAdapter.adaptArray(result.data);
      this.populateListDropdown(lists);
    });
  }

  populateListFolderDropdown(listFolders: Array<ListFolderModel>) {
    this._listFolderDropdown = [];
    listFolders.forEach((folder: ListFolderModel) => {
      this._listFolderDropdown = [... this._listFolderDropdown, {
        value: folder.FolderID,
        label: folder.FolderName
      }];
    });
  }

  populateListDropdown(lists: Array<LightListModel>) {
    this._listDropdown = [];
    lists.forEach((list: LightListModel) => {
      this._listDropdown = [... this._listDropdown, {
        value: list.ListId,
        label: list.ListName
      }];
    });
  }

  openCreateStep() {
    if (this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Create) {
      this.selectListModal.show();
    }
  }

  confirmList() {
    this.selectListModal.hide();
    this.createEditMessageCodeComponent.messageCodeForm.controls.listId.setValue(this.listForStepFormGroup.controls.listId.value);
    this.createEditMessageCodeModal.show();
    this.listForStepFormGroup.reset();
  }

  editStep(messageCodeStepId) {
    this.createEditMessageCodeModal.show();
    this.createEditMessageCodeComponent.initForEdit(messageCodeStepId);
  }

  saveMessageCode() {
    this.createEditMessageCodeComponent.messageCodeFormSubmit();
  }

  // add ability to dynamically sort data retrieved from the database by providing the direction and column being sorted
  // we also need to ensure that the captured directions are formatted to work with the API and SQL script
  onSort($event) {
    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      direction = "Descending";
    }
    else {
      direction = "Ascending";
    }
    this.trialRun(this._selectedRowIndex, undefined, direction, $event.sorts[0].prop);
  }

  // function to generate a "trial run" that allows the user
  // to view the data returned by the contact plan they have defined
  trialRun(selectedRowIndex?: number, pageInfo?: any, sortDirection?: string, sortColumn?: string) {

    if (sortDirection !== undefined) this._sortDirection = sortDirection;
    if (sortColumn !== undefined) this._sortColumn = sortColumn;

    // when sorting, we need to use the stored row index from when the modal was first opened
    // to allow interaction with the API endpoint
    if (selectedRowIndex !== undefined) {
      this._selectedRowIndex = selectedRowIndex;
    }
    // get the row data from the row that has been selected
    var selectedRow = this._rows[this._selectedRowIndex];

    this._currentPageTrialRun = pageInfo ? pageInfo : 1;

    const pagerDtoRequest = new PagerDtoRequest({
      page: this._currentPageTrialRun,
      pageSize: this._pageLimitTrialRun,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: selectedRow["listId"],
      contactFilter: selectedRow["sql"]
    });

    // use the List ID and SQL in the row in order to retrieve the contacts that have been requested
    // with the filter being added from the contact critera plan

    this.contactService.getContacts(getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._pageCountTrialRun = executionResultDto.data.Total;

        this._tableHasRowsTrialRun = this._pageCountTrialRun > 0;

        this._columnsTrialRun = this._rowsTrialRun = [];

        if (!this._tableHasRowsTrialRun) {
          return;
        }

        this._columnsTrialRun = executionResultDto.data.Columns.map(column =>
          new DataTableColumn({
            prop: column.Name,
            name: column.DisplayName
          }));

        setTimeout(() => {
          this._rowsTrialRun = this.getRows(executionResultDto);
        }, 250);

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });

    this.trialRunModal.show();
  }

  getRows(executionResultDto: ExecutionResultDto, exporting?) {
    let rows: { [prop: string]: any }[] = [];
    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        } else {
          if (exporting) {
            row[key] = '';
          }
        }
      });
      return row;
    });
    return rows;
  }

  // allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    this._pageLimit = parseInt(limit);
    this.getSteps();
  }

  // allow the user to change the max number of rows showed in the table
  onLimitChangeTrialRun(limit: any): void {
    this._pageLimitTrialRun = parseInt(limit);
    this.trialRun();
  }
}
