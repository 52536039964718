import { Injectable } from '@angular/core';

export class LoginRequestDto {
  public Username: string;
  public Password: string;

  constructor(init: any) {
    this.Username = init.Username;
    this.Password = init.Password;
  }
}

@Injectable({
  providedIn: 'root'
})

export class LoginRequestAdapter {

  adapt(item: any): LoginRequestDto {
    return new LoginRequestDto(item)
  }
}
