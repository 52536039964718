import { Injectable } from '@angular/core';
import { ExecutionAction } from './execution-action-enum';
import { ExecutionResult } from './execution-result-enum';

export class ExecutionResultDto {
    public message: string;
    public executionResult: ExecutionResult;
    public executionAction: ExecutionAction;
    public data: any;

    constructor(init: any) {
      this.message = init.Message;
      this.executionResult = +init.ExecutionResult;
      this.executionAction = +init.ExecutionAction;
      this.data = init.Data;
    }    
}

@Injectable({
  providedIn: 'root'
})

export class ExecutionResultAdapter {
  adapt(item: any): ExecutionResultDto {
    return new ExecutionResultDto(item);
  }
}
