<app-page-container [title]="tableName">
  <app-messaging-header [tableKey]="tableKey"
                        [permission]="userPermissionsEmail"
                        [folderService]="messageFolderService"
                        [isFolderEmpty]="!data?.length"
                        (folderChanged)="folderSelected($event)"
                        (action)="selectEmailBuilder()">
  </app-messaging-header>

  <app-table [tableKey]="tableKey"
             [permission]="userPermissionsEmail"
             [displayedColumns]="displayedColumns"
             [data]="data"
             [length]="length"
             [selectedFolderId]="selectedFolderId"
             [isSwitchOn]="true"
             [isSearchOn]="true"
             [dataType]="dataType"
             [actionList]="actionList"             
             (tableData)="getMessages($event)"
             (actionEmit)="actionHandler($event)"
             [filterMessageRules]="filterMessagesRules">
  </app-table>
</app-page-container>

<div mdbModal #confirmExportAllMessages="mdb-modal" class="confirm-export-all-modal modal fade" id="confirmExportAllMessages" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Export Email Preview Links</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="confirmExportAllMessages.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3 modal-text">
          All email preview links in this folder will be exported, would you like to continue?
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn-cancel-modal" outline="true" data-dismiss="modal" (click)="confirmExportAllMessages.hide()">
          No
        </button>

        <button type="button" class="btn-save-modal" (click)="exportAllMessages()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

