
<div class="email-builder-container">

  <div id="messageName" class="emailSubject">
    <span>{{ this._message.MessageName }}</span>
  </div>

  <iframe id="email-builder-frame" class="email-builder" frameBorder="0" [src]="_emailBuilderURL | safe" title="MDEmailBuilder"></iframe>

  <div mdbModal #saveMessageModal="mdbModal" class="modal fade left rounded" id="saveMessageModalTop" tabindex="-1" role="dialog"
      aria-labelledby="saveMessageModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold">Saving Message: {{this._saveMessageForm.get('messageName').value}}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="saveMessageModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mx-3">
          <form [formGroup]="_saveMessageForm">
            <div class="modal-body">
              <div class="md-form remove-top-margin">
                <mdb-icon fas icon="tag" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput 
                  [ngClass]="{'is-invalid': _saveMessageForm.get('messageName').errors && _saveMessageForm.get('messageName').touched}"
                  type="text" 
                  id="message-name" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="messageName">
                <label for="message-name">Message Name</label>
                <div class="invalid-feedback ml-5">Please enter a Message Name.</div>
              </div>
              <div class="md-form remove-top-margin">
                <mdb-icon fas icon="info-circle" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput 
                  [ngClass]="{'is-invalid': _saveMessageForm.get('emailSubject').errors && _saveMessageForm.get('emailSubject').touched}"
                  type="text" 
                  id="email-subject" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="emailSubject">
                <label for="email-subject">Email subject</label>
                <div class="invalid-feedback ml-5">Please enter an Email Subject.</div>
              </div>
              <div class="md-form remove-top-margin">
                <mdb-icon fas icon="folder" class="prefix icon-color" [ngClass]="{'active': _folderSelectOpen}"></mdb-icon>
                <mdb-select
                  class="short-select"
                  [ngClass]="{'is-invalid': _saveMessageForm.get('messageFolder').errors && _saveMessageForm.get('messageFolder').touched}"
                  [options]="_messageFoldersDropdown"
                  [filterEnabled]="true"
                  [visibleOptions]="10"
                  label="Select Folder"
                  (opened)="folderSelectOpened()"
                  (closed)="folderSelectClosed()"
                  formControlName="messageFolder">
                </mdb-select>
                <div *ngIf="_saveMessageForm.get('messageFolder').errors && _saveMessageForm.get('messageFolder').touched" class="red-text ml-5">Please select a Folder.</div>
              </div>
              <div class="md-form">
                <mdb-icon fas icon="user" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput
                  [ngClass]="{'is-invalid': _saveMessageForm.get('fromName').errors && _saveMessageForm.get('fromName').touched}" 
                  type="text" 
                  id="from-name" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="fromName">
                <label for="from-name">From Name</label>
                <div class="invalid-feedback ml-5">Please enter a From Name.</div>
              </div>
              <div class="md-form">
                <mdb-icon fas icon="address-card" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput 
                  [ngClass]="{'is-invalid': _saveMessageForm.get('fromAddress').errors && _saveMessageForm.get('fromAddress').touched}" 
                  type="text" 
                  id="from-address"
                  maxlength="225" 
                  class="form-control" 
                  formControlName="fromAddress">
                <label for="from-address">From Address</label>
                <div class="invalid-feedback ml-5">Please enter a From Address.</div>
              </div>
              <div class="md-form">
                <mdb-icon fas icon="reply" class="prefix icon-color"></mdb-icon>
                <input 
                  mdbInput
                  [ngClass]="{'is-invalid': _saveMessageForm.get('replyToAddress').errors && _saveMessageForm.get('replyToAddress').touched}"
                  type="text" 
                  id="reply-to-address" 
                  maxlength="225" 
                  class="form-control" 
                  formControlName="replyToAddress">
                <label for="reply-to-address">Reply To Address</label>
                <div class="invalid-feedback ml-5">Please enter a Reply To Address.</div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="btnCloseModalMosaico" type="button" class="btn-cancel waves-light rounded" aria-label="Close" (click)="saveMessageModal.hide()" mdbWavesEffect>Close</button>
              <button id="btnSaveMessageMosaico" type="button" [disabled]="!_saveMessageForm.valid || _saving" (click)="saveMessageSubmit(true)" class="btn-okay relative waves-light rounded" mdbWavesEffect><span *ngIf="_saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save Message</button>
              <div *ngIf="!this._sendAfterSave">
                <button id="btnSaveAndExitBuilderMosaico" type="button" [disabled]="!_saveMessageForm.valid || _saving" (click)="saveMessageSubmit(false)" class="btn-saveandexit btn-okay relative waves-light rounded" mdbWavesEffect><span *ngIf="_saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save and Exit Builder</button>
              </div>              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
