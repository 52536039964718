export class LightListModel {
  public FolderId: number;
  public ListId: number;
  public ListName: string;

  constructor(init: any) {
    this.FolderId = init.FolderId;
    this.ListId = init.ListId;
    this.ListName = init.ListName;
  }
}
