import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';

export class GetFinalContactsDtoRequest {
    pager: PagerDtoRequest;
    listId: number;
    contactFilter: string;
    secondListId: number;
    secondListContactFilter: string;
    isEmail: boolean;

    constructor({ pagerDtoRequest, listId, contactFilter, secondListId, secondListContactFilter, isEmail }:
        { pagerDtoRequest?: PagerDtoRequest; listId?: number; contactFilter?: string; secondListId?: number; secondListContactFilter?: string; isEmail: boolean; }) {                    
        this.listId = listId;
        this.contactFilter = contactFilter;
        this.secondListId = secondListId;
        this.secondListContactFilter = secondListContactFilter;
        this.pager = pagerDtoRequest;
        this.isEmail = isEmail;
    }
}
