import { Injectable } from '@angular/core';
import { PermissionsPageTypesEnum } from './permissions-page-types.enum';

export class PermissionsPageTypes {
  public View: boolean;
  public Edit: boolean;
  public Create: boolean;
  public Delete: boolean;
  public Send: boolean;
  public Draft: boolean;
  public Pause: boolean;
  public Approve: boolean;
  public TopLevelAccountAccess: boolean;

  constructor(init: number) {
    this.View = !!(init & PermissionsPageTypesEnum.View);
    this.Edit = !!(init & PermissionsPageTypesEnum.Edit);
    this.Create = !!(init & PermissionsPageTypesEnum.Create);
    this.Delete = !!(init & PermissionsPageTypesEnum.Delete);
    this.Send = !!(init & PermissionsPageTypesEnum.Send);
    this.Draft = !!(init & PermissionsPageTypesEnum.Draft);
    this.Pause = !!(init & PermissionsPageTypesEnum.Pause);
    this.Approve = !!(init & PermissionsPageTypesEnum.Approve);
    this.TopLevelAccountAccess = !!(init & PermissionsPageTypesEnum.TopLevelAccountAccess);
  };
}


@Injectable({
  providedIn: 'root'
})

export class PermissionsPageTypesAdapter {
  adapt(item: number): PermissionsPageTypes {
    return new PermissionsPageTypes(item);
  }
}
