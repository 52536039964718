import { Injectable } from '@angular/core';
import { MessageSaveModel } from './message-save-model';

@Injectable({
  providedIn: 'root'
})

export class MessageSaveModelAdapter {
  adapt(item: any): MessageSaveModel {
    return new MessageSaveModel(item);
  }
  createEmpty() {
    return new MessageSaveModel({
      MessageName: '',
      MessageID: 0,
      EmailSubject: '',
      FolderID_input: '',
      FolderID: 0,
      FromName: '',
      FromAddress: '',
      ReplyToAddress: '',
      HtmlContent: '',
      MetaContent: '',
      EmailContent: '',
      IsUnlyer: false
    });
  }
}
