import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent {
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @Input() errorMessage = '';
}
