<button (click)="addField()" type="button" class="rounded" mdbBtn color="secondary" mdbWavesEffect>Add New Field</button>

<form [formGroup]="_secondListDynamicFieldsForm">
  <div class="card m-3">
      <div *ngFor="let secondListField of secondListFields.controls; let i = index" class="list-group list-group-flush">
        <div class="row remove-bottom-margin">
          <div class="col-md-11 second-list-left-col">
            <div class="list-group-item">
              <div [formGroup]="secondListField" class="form-row">
                <!-- First Column  -->
                <div class="col-md-4">
                  <div class="md-form remove-vertical-margin">
                    <mdb-select
                      [options]="_contactFieldsDropdown"
                      [filterEnabled]="true"
                      [visibleOptions]="10"
                      label="Contact Value"
                      formControlName="secondListName"
                      (selected)="contactValueSelected($event, secondListField)">
                    </mdb-select>
                  </div>
                </div>

                <!-- Second Column -->
                <div *ngIf="secondListField.get('secondListName').value" class="col-md-4">
                  <div *ngIf="!secondListField.get('dateTime').value" class="md-form remove-vertical-margin">

                    <mdb-select
                      [options]="_updateTypes"
                      [filterEnabled]="true"
                      [visibleOptions]="10"
                      label="Update Type"
                      formControlName="secondListUpdateType">
                    </mdb-select>
                  </div>
                  <div *ngIf="secondListField.get('dateTime').value" class="md-form remove-vertical-margin">
                    <mdb-select
                      [options]="_updateTypesWithDate"
                      [filterEnabled]="true"
                      [visibleOptions]="10"
                      label="Update Type"
                      formControlName="secondListUpdateType">
                    </mdb-select>
                  </div>
                </div>

                <!-- Third Column -->
                <div *ngIf="secondListField.get('secondListUpdateType').value" class="col-md-4">
                  <div *ngIf="secondListField.get('secondListUpdateType').value === 'RawValue'">
                    <div [ngSwitch]="secondListField.get('controlType').value">
                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.Input">
                          <input type="{{secondListField.get('inputType').value}}"
                              mdbInput
                              formControlName="secondListArgument"
                              class="form-control"
                              onwheel="this.blur()"
                          />
                      </div>
                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.Textarea">
                        <textarea
                            mdbInput
                            class="form-control md-textarea"
                            formControlName="secondListArgument"
                          >
                        </textarea>
                      </div>
                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.Dropdown">
                        <mdb-select
                          formControlName="secondListArgument"
                          [options]="multipleOptions[i]"
                          [visibleOptions]="10"
                        >
                        </mdb-select>
                      </div>
                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.Checkbox">
                        <mdb-checkbox *ngFor="let option of multipleOptions[i]; let i = index"
                          [inline]="true"
                          (change)="onCheckChange($event, secondListField)"
                          [value]="option.value"
                          [checked]="option.checked"
                          >
                          {{option.label}}
                        </mdb-checkbox>
                      </div>
                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.RadioButton">
                        <div class="form-check form-check-inline" *ngFor="let option of multipleOptions[i]; ">
                          <input
                            type="radio"
                            class="form-check-input"
                            id="second-list-radio-{{option.value[0]}}"
                            name="secondListArgument"
                            formControlName="secondListArgument"
                            [value]="option.value[1]">
                        <label class="form-check-label" for="second-list-radio-{{option.value[0]}}">{{option.label}}</label>
                        </div>
                      </div>
                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.DatePicker">
                        <input
                        mdbInput
                        type="date"
                        class="form-control"
                        formControlName="secondListArgument">
                      </div>
                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.TimePicker">
                        <input
                        mdbInput
                        type="time"
                        class="form-control"
                        formControlName="secondListArgument">
                      </div>

                      <div class="md-form remove-vertical-margin" *ngSwitchCase="_listControlTypeEnum.DateTimePicker">
                        <input
                        mdbInput
                        type="datetime-local"
                        class="form-control"
                        formControlName="secondListArgument">
                      </div>
                    </div>
                  </div>
                  <div *ngIf="secondListField.get('secondListUpdateType').value === 'FieldName'">
                    <div class="md-form remove-vertical-margin">
                      <mdb-select
                        [options]="_contactFieldsDropdown"
                        [filterEnabled]="true"
                        [visibleOptions]="10"
                        label="Contact Value"
                        formControlName="secondListArgument">
                      </mdb-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1 text-center text-dark second-list-right-col">
            <mdb-icon (click)="removeField(i)" fas icon="trash" class="second-list-delete-icon" size="lg"></mdb-icon>
          </div>
        </div>
      </div>
      <div *ngIf="this._sameValueSelected">
        <div class="alert alert-warning second-list-remove-padding text-center">Duplicate Fields Selected. This will break the trigger if saved. Please Change.</div>
      </div>
  </div>
</form>
