<app-modal-container [title]="title"
                     [actionButtonName]="'Confirm'"
                     [buttonValidation]="messageCopyMoveSubmitting"
                     (action)="copyMoveMessage()">
  <form [formGroup]="copyMoveFormGroup">
    <app-md-select *ngIf="isCopy"
                   [label]="'Client'"
                   [options]="clientSelectOptions"
                   formControlName="clientSelection">
    </app-md-select>

    <app-md-select [label]="'Destination Folder'"
                   [options]="folderSelectOptions"
                   formControlName="messageFolder">
    </app-md-select>

    <mdb-accordion [multiple]="false" class="mt-3 mb-3">
      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head>Message(s) to copy Names</mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <button type="button" class="btn-find-replace-modal" (click)="openFindReplaceModal()">
            Find and Replace in Message Name
          </button>

          <app-message-to-copy-move [copyMoveList]="copyMoveList"
                                    [isCopy]="isCopy"
                                    [tableKey]="tableKey"
                                    [type]="type"
                                    (copyMove)="copyMoveMessage()"
                                    (arrayChanged)="setArray($event)">
          </app-message-to-copy-move>
        </mdb-accordion-item-body>
      </mdb-accordion-item>

      <mdb-accordion-item>
        <mdb-accordion-item-head>Search and Replace in Messages</mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <app-search-replace-message (formArrayChanged)="setArrayValue($event)"></app-search-replace-message>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
    <div class="mt-2">Are you sure you want to proceed?</div>
  </form>
</app-modal-container>
