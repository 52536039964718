export class AvailableDMSDto {
  ID: number;
  DMS: string;
  Type: string;

  constructor(init: any) {
    this.ID = init.ID;
    this.DMS = init.DMS;
    this.Type = init.Type;
  }
}
