
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { ScreenColumnWidth } from 'src/app/_models/system/data-table-column-width-manager/screen-column-width';
import { ColumnWidth } from 'src/app/_models/system/data-table-column-width-manager/column-width';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class DataTableColumnWidthManager {

    set screenName(value: string) {
        this._screenName = value;
    }

    private _prop = '';
    private _screen: ScreenColumnWidth;
    private _screenName = '';
    private _screens: ScreenColumnWidth[] = [];
    private _width = 0;

    constructor(private localStorageService: LocalStorageService) {

        this._screens = [];

        if (this.localStorageService.getDataTableColumnWidths) {
            this._screens = (JSON.parse(this.localStorageService.getDataTableColumnWidths) as ScreenColumnWidth[]);
        }
    }

    getColumnWidth(prop: string): number {

        if (this.storageExists()) {
            this._screen = this._screens.find(x => x.screenName === this._screenName);
            if (this._screen) {
                const columnWidth = this._screen.columnWidths.find(x => x.prop === prop);
                return columnWidth ? columnWidth.width : null;
            }
        }

        return null;
    }

    setColumnWidth(prop: string, width: number): void {

        this._prop = prop;
        this._width = width;

        if (this.storageExists()) {
            this._screen = this._screens.find(x => x.screenName === this._screenName);

            if (this._screen) {
                if (this._screen.columnWidths.some(x => x.prop === this._prop)) {
                    this._screen.columnWidths.find(x => x.prop === this._prop).width = this._width;
                } else {
                    this._screen.columnWidths.push(new ColumnWidth(this._prop, this._width));
                }
            } else {
                this.createNewScreen();
            }
        } else {
            this.createNewScreen();
        }

        this.localStorageService.setColumnWidths = JSON.stringify(this._screens);
    }

    private createNewScreen() {

        if (this._screenName.length > 0) {
            this._screen = new ScreenColumnWidth(this._screenName, [new ColumnWidth(this._prop, this._width)]);
            this._screens.push(this._screen);
        }
    }

    private storageExists(): boolean {
        return this._screens && this._screens.length && this._screens.length > 0;
    }
}


