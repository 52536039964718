export class ListFieldOptionModel {
  public OptionID: number;
  public FieldID: number;
  public OptionValue: string;
  public IsSelected: boolean;

  constructor(init: any) {
    this.OptionID = init.OptionID;
    this.FieldID = init.FieldID;
    this.OptionValue = init.OptionValue;
    this.IsSelected = init.IsSelected;
  }
}
