<mdb-card cascade="true" narrower="true" class="mt-5">

  <!--Card Header-->
  <div class="view view-cascade gradient-card-header primary-color narrower
    py-2 mx-4 mb-3 d-flex justify-content-center">
    <h4 class="page-title">MOT Box</h4>
  </div>
  <!--/Card Header-->

  <!--Card Content-->
  <mdb-card-body cascade="true" class="text-center media-server-header-content">

    <div class="row">

    <!-- Selector to choose the bucket -->
    <div class="col-md-4">

    </div>


    <div class="col-md-4">
      <div class="btn-group" role="group">
        <button mdbBtn type="button" color="primary" class="wave-light" (click)="uploadModal.show()" mdbWavesEffect>Upload</button>
        <div *ngIf="this._vehicleResultData.length > 0"  class="dropdown" mdbDropdown>
          <button mdbDropdownToggle mdbBtn type="button" color="primary" class="dropdown-toggle wave-light" mdbWavesEffect>Download</button>
          <div class="dropdown-menu dropdown-primary">
            <div class="dropdown-item" (click)="this.downloadTableDataFile()">Download Table Data</div>
            <div class="dropdown-item" (click)="this.downloadFile()">Download Raw Data</div>
          </div>
        </div>

        <button mdbBtn *ngIf="this._vehicleResultData.length > 0" type="button" color="primary" class="wave-light" (click)="this.buildTableData(true)" mdbWavesEffect>Display Table</button>

      </div>
    </div>


    </div>


  </mdb-card-body>
</mdb-card>

<div class="row">
  <div *ngIf="this._showTable" class="col-md-12 pt-4">
    <!-- <div class="row"> </div> -->
    <ngx-datatable
    class="material"
    [rows]="this._tableData"
    columnMode="force"
    [headerHeight]="50"
    [footerHeight]="0"
    [rowHeight]="50"
    [scrollbarV]="true"
    [scrollbarH]="true"
  >
    <ngx-datatable-column name="registration" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="make"></ngx-datatable-column>
    <ngx-datatable-column name="model"></ngx-datatable-column>
    <ngx-datatable-column name="manufactureYear" ></ngx-datatable-column>
    <ngx-datatable-column name="fuelType" ></ngx-datatable-column>
    <ngx-datatable-column name="primaryColour" ></ngx-datatable-column>
    <ngx-datatable-column name="motTestExpiryDate" ng-bind="motTestExpiryDate | date:'MM/dd/yyyy'" ></ngx-datatable-column>
  </ngx-datatable>
  </div>

</div>


<div mdbModal #uploadModal="mdbModal" class="modal fade right" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModal"
    aria-hidden="true" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form>
            <div class="modal-header">
              <button type="button" class="close pull-right" aria-label="Close" (click)="uploadModal.hide()">
                  <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title w-100" id="myModalLabel">Upload new files</h4>
            </div>
            <div class="modal-body">
              <div class="alert alert-primary text-center" role="alert">
                CSV file format only.  Registration Number heading must be regNbr.
              </div>
              <div class="file-field md-form">
                <div mdbBtn color="primary" size="sm" class="waves-light" (click)="chooseFileClicked()" mdbWavesEffect>
                  <span>Choose file</span>
                  <input #uploadFile type="file" mdbFileSelect accept=".csv" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()" >
                </div>
              </div>
              <div *ngIf="this._uploadingFiles" class="vehicleValidator-progressbar">
                <mdb-progress value={{this.percentageCompleted}} min="0" max="100" type="success" aria-valuenow={{this.percentageCompleted}} aria-valuemin="0" aria-valuemax="100">{{this.percentageCompleted}}%</mdb-progress>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="closeUploadModal()" mdbWavesEffect>Close</button>
                <button mdbBtn color="primary" (click)="startUpload()" [disabled]="this._uploadingFiles"><span *ngIf="_uploadingFiles" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Start uploading</button>
            </div>
          </form>
        </div>
    </div >
</div >

