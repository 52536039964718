import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserRoleDto, UserRoleAdapter } from '../../../_models/admin/users/user-role-model';
import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  _baseUrl: string = environment.apiURL;
 
  public currentUserRole: UserRoleDto;

  constructor(private http: HttpClient,
    private userRoleAdapter: UserRoleAdapter) {
   }

  getAll() : Observable<UserRoleDto[]> {
    return this.http
    .get(this._baseUrl + 'UserRole')
    .pipe(map((data: any) => data.map((item: any) => this.userRoleAdapter.adapt(item))));
  }

  get(id: number) : Observable<UserRoleDto> {
    return this.http
    .get(this._baseUrl + `UserRole/${id}`)
    .pipe(map((data: any) => this.userRoleAdapter.adapt(data)));
  }

  post(userRoleDto: UserRoleDto):any{
    return this.http.
    post(this._baseUrl + 'UserRole', userRoleDto)
  }

  updateEditComponent(userRoleDto: UserRoleDto)  {    
    this.currentUserRole = userRoleDto;    
  }

}
