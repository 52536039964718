import { ListModel } from './list-model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ListModelAdapter {
  adapt(item: any): ListModel {
    return new ListModel(item);
  }

  adaptArray(init: Array<any>): ListModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new ListModel(table))
      : [new ListModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new ListModel({
      ListFields: [],
      ListID: 0,
      FolderID: 0,
      ListName: '',
      Comment: '',
      IsDeleted: false,
      ListType: 0,
    });
  }
}
