import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EditContactPlanRequestDto
} from '../../../../_models/messaging/automation/contact-plans/edit-contact-plan-request-dto';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import {
  ContactPlanGridRecordDto
} from '../../../../_models/messaging/automation/contact-plans/contact-plan-grid-record-dto';
import { ContactPlanService } from '../../../../_services/messaging/automation/contact-plan.service';
import { NotificationService } from '../../../../_services/notification.service';

@Component({
  selector: 'app-activate-contact-plan',
  templateUrl: './activate-contact-plan-modal.component.html',
  styleUrls: ['./activate-contact-plan-modal.component.scss']
})
export class ActivateContactPlanModalComponent implements OnInit {
  title = 'Confirm Activate Contact Plan';
  action: string;

  constructor(
    private notificationService: NotificationService,
    private contactPlanService: ContactPlanService,
    public dialogRef: MatDialogRef<ActivateContactPlanModalComponent>,
    @Inject(MAT_DIALOG_DATA) private selectedPlan: ContactPlanGridRecordDto
  ) { }

  ngOnInit(): void {
    this.action = this.selectedPlan.status ? 'activate' : 'deactivate';
  }

  confirmActivateContactPlan() {
    const editContactPlanDto = new EditContactPlanRequestDto({
      id: this.selectedPlan.contactPlanId,
      status: this.selectedPlan.status
    });

    this.contactPlanService.activatePlan(editContactPlanDto).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.dialogRef.close({ isActivated: true});
      } else {
        this.notificationService.showError(executionResultDto.message);
        this.dialogRef.close();
      }
    });
  }
}
