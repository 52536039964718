import { Injectable } from "@angular/core";
import { EventInfoModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/event-info-model';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';

@Injectable()

export class EventInfoResolver implements Resolve<EventInfoModel> {
  constructor(private triggersService: TriggersService,
              private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<EventInfoModel> {
    return this.triggersService.getTriggerEvents().pipe(
      catchError(() => {
        this.router.navigate(['/contact-plans']);
        return of(null);
      })
    );
  }
}
