import { NetworkDto } from "./network-dto";

export class AddPageDtoRequest {

  public networkId: number;
  public id: string;
  public permissionNames: string[];
  public subNetwork: NetworkDto;

  constructor(networkId: number, id: string, permissionNames: string[], subNetwork: NetworkDto) {
    this.networkId = networkId;
    this.id = id;
    this.permissionNames = permissionNames;
    this.subNetwork = subNetwork;
  }
}
