export class DashboardStatsModel {
 
  totalContacts: number;
  contactsPercentageDifference: number;
  totalMessagesSent: number;
  messagesPercentageDifference: number;
  totalSMSMessagesSent: number;
  smsPercentageDifference: number;   

  constructor(init: any) {
    this.totalContacts = init.TotalContacts;
    this.contactsPercentageDifference = init.ContactsPercentageDifference;
    this.totalMessagesSent = init.TotalMessagesSent;
    this.messagesPercentageDifference = init.MessagesPercentageDifference;
    this.totalSMSMessagesSent = init.TotalSMSMessagesSent;    
    this.smsPercentageDifference = init.SmsPercentageDifference;    
  }  
}