export class BulkMessageSendDtoRequest {
    messageIds: number[];
    listId: number;
    contactsFilter: string;

    constructor(init: { messageIds: number[], listId: number, contactsFilter: string }) {
        this.messageIds = init.messageIds;
        this.listId = init.listId;
        this.contactsFilter = init.contactsFilter;
    }
}
