import { Component, OnInit, ViewChild } from '@angular/core';
import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';
import { NetworkService } from 'src/app/_services/social-media/networks/network.service';
import { NetworkModelAdapter } from 'src/app/_models/social-media/networks/network-model-adapter';
import { NetworkAdapter } from 'src/app/_models/social-media/networks/network-adapter';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { NetworkModelDto } from 'src/app/_models/social-media/networks/network-model-dto';
import { FacebookPageService } from 'src/app/_services/social-media/networks/facebook-page.service';
import { AddPageDtoRequest } from 'src/app/_models/social-media/networks/add-page-dto-request';
import { AddProfileDtoRequest } from 'src/app/_models/social-media/networks/add-profile-dto-request';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { TwitterService } from 'src/app/_services/social-media/networks/twitter.service';
import { FacebookProfileService } from 'src/app/_services/social-media/networks/facebook-profile.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FacebookInitializationVariablesAdapter } from 'src/app/_models/social-media/networks/facebook-initialization-variables-adapter';
import { FacebookInitializationVariablesDto } from 'src/app/_models/social-media/networks/facebook-initialization-variables-dto';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { NetworkTypeEnum } from 'src/app/_models/social-media/posts/network-type-enum';
import { MatCheckbox } from '@angular/material/checkbox';
import { LinkedInService } from 'src/app/_services/social-media/networks/linkedIn.service';
import { InstagramService } from 'src/app/_services/social-media/networks/instagram.service';

@Component({
  selector: 'app-network-management',
  templateUrl: './network-management.component.html',
  styleUrls: ['./network-management.component.scss']
})
export class NetworkManagementComponent implements OnInit {

  _defaultFacebookPermissions: string[] = [];
  _facebookInitializationVariables: FacebookInitializationVariablesDto;
  _filteredNetworks: NetworkDto[] = [];
  _facebookPagesLoading = false;
  _facebookPagesNoResults = false;
  _instagramPagesLoading = false;
  _instagramPagesNoResult = false;
  _linkedInOrganisationsLoading = false;
  _linkedInOrganisationsNoResults = false;
  _networkModel: NetworkModelDto;
  _networks: NetworkDto[] = [];
  _pages: NetworkDto[] = [];
  _searchText = '';
  _userPermissions : PermissionsModel;
  _refreshingNetworks: boolean = false;
  _networkIdToDelete: number;
  _isDisabled: boolean;
  _modalTitle: string = '';
  _instagramTitle: string = 'Available Instagram Pages';
  _facebookPageTitle: string = 'Available Facebook Pages';
  _linkedInTitle: string = 'Available LinkedIn Organisations';

  _networkToDeleteName: string = '';

  _linkedInAuthRequest: string = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code';

  @ViewChild('addSubNetworkModal', { static: true }) addSubNetworkModal: ModalDirective;
  @ViewChild('deleteNetworkModal', { static: true }) deleteNetworkModal: ModalDirective;

  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
    this._filteredNetworks = this.filter(value);
  }

  constructor(private networkService: NetworkService,
              private linkedInService: LinkedInService,
              private facebookProfileService: FacebookProfileService,
              private facebookPageService: FacebookPageService,
              private instagramService: InstagramService,
              private twitterService: TwitterService,
              private notificationService: NotificationService,
              private permissionService: PermissionsService,
              private networkAdapter: NetworkAdapter,
              private networkModelAdapter: NetworkModelAdapter,
              private facebookInitializationVariablesAdapter: FacebookInitializationVariablesAdapter) { }

  ngOnInit() {
    this.getFacebookDefaultPermissions();
    this.getFacebookInitializationVariables();
    this.getPermissions();
    this.getNetworks();
  }

  addDeleteSubNetworkPage(checkbox: MatCheckbox, checked: boolean, subNetwork: NetworkDto) {
    const isChecked = checked;
    const checkBoxValues = checkbox.value.split('-');
    const parentNetworkId: number = +checkBoxValues[0];
    const pageId: string = checkBoxValues[1];

    this._isDisabled = true;

    if (isChecked) {
      const permissionNames = this.getSelectedPagePermissions(pageId);
      const addPageDtoRequest = new AddPageDtoRequest(parentNetworkId, pageId, permissionNames, subNetwork);
      
      console.log('subnetwork: ');
      console.log(subNetwork);

      if (subNetwork.type === NetworkTypeEnum['Facebook Page'])
      {
        this.facebookPageService.post(addPageDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            const page = this.networkAdapter.adapt(executionResultDto.data);
            page.selected = true;
            this._filteredNetworks.filter(network => {
              if (network.networkId === parentNetworkId) {
                network.subNetworks.push(page);
              }
            });
  
            this.notificationService.showSuccess(executionResultDto.message);
            this._isDisabled = false;
          }
         });
      }      
      else if (subNetwork.type === NetworkTypeEnum.Instagram)
      {
        this.instagramService.post(addPageDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            const page = this.networkAdapter.adapt(executionResultDto.data);
            page.selected = true;
            this._filteredNetworks.filter(network => {
              if (network.networkId === parentNetworkId) {
                network.subNetworks.push(page);
              }
            });
  
            this.notificationService.showSuccess(executionResultDto.message);
            this._isDisabled = false;
          }
        });
      }
      else if (subNetwork.type === NetworkTypeEnum.LinkedIn) {
        this.linkedInService.post(addPageDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            const page = this.networkAdapter.adapt(executionResultDto.data);
            page.selected = true;
            this._filteredNetworks.filter(network => {
              if (network.networkId === parentNetworkId) {
                network.subNetworks.push(page);
              }
            });
  
            this.notificationService.showSuccess(executionResultDto.message);
            this._isDisabled = false;
          }
        });
      }
    } else {
      if (subNetwork.type === NetworkTypeEnum['Facebook Page'])
      {
        this.facebookPageService.delete(pageId).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            this._filteredNetworks.filter(network => {
                if (network.networkId === parentNetworkId) {
                  network.subNetworks = network.subNetworks.filter(subNetwork => subNetwork.id !== pageId);
                }
              }
            );
            this._facebookPagesNoResults = false;
            this.notificationService.showSuccess(executionResultDto.message);
            this._isDisabled = false;
          }
        });
      }
      else if (subNetwork.type === NetworkTypeEnum.Instagram)
      {
        this.instagramService.delete(pageId).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            this._filteredNetworks.filter(network => {
                if (network.networkId === parentNetworkId) {
                  network.subNetworks = network.subNetworks.filter(subNetwork => subNetwork.id !== pageId);
                }
              }
            );
            this._facebookPagesNoResults = false;
            this.notificationService.showSuccess(executionResultDto.message);
            this._isDisabled = false;
          }
        });
      }
      else if (subNetwork.type === NetworkTypeEnum.LinkedIn) {
        this.linkedInService.delete(+pageId).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            this._filteredNetworks.filter(network => {
                if (network.networkId === parentNetworkId) {
                  network.subNetworks = network.subNetworks.filter(subNetwork => subNetwork.id !== pageId);
                }
              }
            );
            this._facebookPagesNoResults = false;
            this.notificationService.showSuccess(executionResultDto.message);
            this._isDisabled = false;
          }
        });
      }
    }
  }  

  addLinkedInAccount() {
    this.linkedInService.getLinkedInCallbackInfo().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const result = executionResultDto.data;        
        document.location.href = `${this._linkedInAuthRequest}&client_id=${result.ClientId}&redirect_uri=${result.RedirectUri}&state=${result.State}&scope=${result.Scope}`;
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  addFacebookProfile() {
    const options: fb.LoginOptions = {
      enable_profile_selector: true,
      auth_type: 'reauthenticate',
      scope: this._defaultFacebookPermissions.join()
    };

    FB.init({
      appId: this._facebookInitializationVariables.appId,
      xfbml: true,
      version: this._facebookInitializationVariables.version,
      cookie: true,
      status: true
    });

    FB.login(loginResponse => {
      if (loginResponse.authResponse) {
        const addProfileDtoRequest = new AddProfileDtoRequest(loginResponse.authResponse.userID,
                                                              loginResponse.authResponse.accessToken);
        this.facebookProfileService.post(addProfileDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            const netWorkDto = this.networkAdapter.adapt(executionResultDto.data);
            
            const existingMetaProfile = this._filteredNetworks.find((network) => {
                return network.id == netWorkDto.id 
                       && network.name == netWorkDto.name 
                       && network.networkDetailId == netWorkDto.networkDetailId 
                       && network.networkId == netWorkDto.networkId
                       && network.type == netWorkDto.type;
            });

            if (!existingMetaProfile) {
              this._filteredNetworks.push(netWorkDto);
            }            
            this.notificationService.showSuccess(executionResultDto.message);
          } else {
            this.notificationService.showError(executionResultDto.message);
          }
        });
      } else {
        this.notificationService.showError('User cancelled login or did not fully authorize.');
      }

    }, options);
  }

  addTwitterAccount() {
    this.twitterService.getToken().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const token = executionResultDto.data;
        document.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${token}`;
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  deletePage() {
    this._facebookPagesNoResults = false;
  }

  setDeleteNetwork(network: any) {
    console.log(network);
    this.deleteNetworkModal.show();
    this._networkToDeleteName = network.name;
    this._networkIdToDelete = network.networkId;
  }

  deleteNetwork(networkId: number) {
    console.log('delete network');
    console.log(networkId);
    networkId = this._networkIdToDelete;
    const network = this._filteredNetworks.find(x => x.networkId === networkId);
    
    if (!network) {
      return;
    }

    if (network.type === NetworkTypeEnum.Twitter) {
      this.twitterService.delete(networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._filteredNetworks = this._filteredNetworks.filter(x => x.networkId !== networkId);
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    } else if(network.type === NetworkTypeEnum.Facebook) {
      this.facebookProfileService.delete(networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._filteredNetworks = this._filteredNetworks.filter(x => x.networkId !== networkId);
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    } else if(network.type === NetworkTypeEnum.Instagram) {
      this.instagramService.delete(network.id).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._filteredNetworks = this._filteredNetworks.filter(x => x.networkId !== networkId);
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    } else if(network.type === NetworkTypeEnum.LinkedIn) {
      this.linkedInService.delete(networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._filteredNetworks = this._filteredNetworks.filter(x => x.networkId !== networkId);
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
    this.deleteNetworkModal.hide();
    this._networkIdToDelete = null;
  }

  filter(searchText: string) {
    return this._networks.filter(network => {
      return network.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });
  }

  getFacebookDefaultPermissions() {
    this.facebookProfileService.getDefaultPermissions().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._defaultFacebookPermissions = executionResultDto.data;
      }
    });
  }

  getFacebookInitializationVariables() {
    this.facebookProfileService.getInitializationVariables().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._facebookInitializationVariables = this.facebookInitializationVariablesAdapter.adapt(executionResultDto.data);
      }
    });
  }

  getFacebookPages() {
    this._facebookPagesLoading = true;
    this.facebookPageService.get().subscribe((executionResultDto: ExecutionResultDto) => {
      this._pages =  executionResultDto.data.map(network => this.networkAdapter.adapt(network));
      this._facebookPagesLoading = false;
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._facebookPagesNoResults = false;
        this._modalTitle = this._facebookPageTitle;
        this.addSubNetworkModal.show();
      } else {
        this._facebookPagesNoResults = true;
        this.notificationService.showInfo(executionResultDto.message);
      }
    });
  }

  getInstagramPages() {
    this._instagramPagesLoading = true;
    this.instagramService.get().subscribe((executionResultDto: ExecutionResultDto) => {
      this._pages =  executionResultDto.data.map(network => this.networkAdapter.adapt(network));
      this._instagramPagesLoading = false;
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._instagramPagesNoResult = false;
        this._modalTitle = this._instagramTitle;
        this.addSubNetworkModal.show();
        this._instagramPagesLoading = false;
      } else {
        this._instagramPagesNoResult = true;
        this.notificationService.showInfo(executionResultDto.message);
        this._instagramPagesLoading = false;
      }
    });
  }

  getLinkedInOrganisations() {

    this._linkedInOrganisationsLoading = true;
    this.linkedInService.getLinkedInOrganizationPages().subscribe((executionResultDto: ExecutionResultDto) => {
      this._pages = executionResultDto.data.map(network => this.networkAdapter.adapt(network));
      this._linkedInOrganisationsLoading = false;
      console.log(executionResultDto);
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._linkedInOrganisationsNoResults = false;
        this._modalTitle = this._linkedInTitle;
        this.addSubNetworkModal.show();
        this._linkedInOrganisationsLoading = false;
        console.log(executionResultDto);
      } else {
        this._linkedInOrganisationsNoResults = true;
        this.notificationService.showInfo(executionResultDto.message);
        this._linkedInOrganisationsLoading = false;
      }
    });
  }

  getPermissions() {
    this._userPermissions = this.permissionService.getPermissionsModel();
    this.permissionService.permissionsModel.subscribe((permissions: PermissionsModel) => {
      this._userPermissions = permissions;
    });
  }

  getNetworks() {
    this.networkService.get().subscribe((executionResultDto: ExecutionResultDto) => {
      this._networkModel = this.networkModelAdapter.adapt(executionResultDto.data);
      this._networks = this._networkModel.networks;
      this._filteredNetworks = this._networks;
      this._filteredNetworks = this.filter('');
    });
  }

  getSelectedPagePermissions(pageId: string): string[] {
    var result = []
    this._pages.forEach(page => {
      const selectedPage = page.subNetworks.find(sn => sn.id === pageId);
      if (selectedPage) {
        result = selectedPage.permissionNames;
      }
    });
    return result;
  }

  refreshNetwork(networkId: number) {
    const network = this._filteredNetworks.find(x => x.networkId === networkId);
    
    if (!network) {
      return;
    }

    if (network.type === NetworkTypeEnum.LinkedIn) {
      this.linkedInService.refresh(networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
    else if (network.type === NetworkTypeEnum.Facebook) {
      this.addFacebookProfile();      
    }
  }

  refreshNetworks() {
    this._refreshingNetworks = true;
    this.networkService.refresh().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this._refreshingNetworks = false;
      } else {
        this.notificationService.showError(executionResultDto.message);
        this._refreshingNetworks = false;
      }
    });
  }
}

