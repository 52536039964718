import { Component, Injector } from '@angular/core';
import { MessagingAbstract } from '../../../../_models/messaging/messaging-abstract';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { MessageReportsService } from '../../../../_services/messaging/messages/message-reports.service';
import {
  SMSMessageReportsRetrievingModel
} from '../../../../_models/messaging/messages/message-reports/sms-reports/sms-message-reports-retrieving-model';
import {
  SMSMessageRunReportModel
} from '../../../../_models/messaging/messages/message-reports/sms-reports/sms-message-run-report-model';
import { DateService } from '../../../../_services/system/date.service';
import {
  SMSMessageRunReportModelAdapter
} from '../../../../_models/messaging/messages/message-reports/sms-reports/sms-message-run-report-model.adapter';
import { MessageFolderService } from '../../../../_services/messaging/messages/message-folder.service';
import { TableActionType } from '../../../shared/constants/table-constants';
import { HelperService } from '../../../../_services/system/helpers/helper.service';
import { DataTypesEnum } from 'src/app/_models/selected-fields-filters/data-types-enum';

@Component({
  selector: 'app-sms-reports',
  templateUrl: './sms-reports.component.html',
})
export class SmsReportsComponent extends MessagingAbstract {
  displayedColumns = [
    { name: 'listId', displayName: 'List ID' },
    { name: 'messageName', displayName: 'Message Name' },
    { name: 'messageSourceName', displayName: 'Message Source Name' },
    { name: 'sentBy', displayName: 'Sent By' },
    { name: 'folderName', displayName: 'Folder Name' },
    { name: 'sendDate', displayName: 'Send Date' },
    { name: 'totalSent', displayName: 'Total Sent' },
    { name: 'totalDelivered', displayName: 'Total Delivered' },
    { name: 'deliveredRate', displayName: 'Delivered Rate (%)' },
    { name: 'totalBounces', displayName: 'Total Bounces' },
    { name: 'bounceRate', displayName: 'Bounce Rate (%)' },
    { name: 'actions', displayName: '' }
  ];

  smsReportModel: SMSMessageReportsRetrievingModel;
  dataType = DataTypesEnum.SmsReports;
  
  constructor(
    public messageReportsService: MessageReportsService,
    private dateService: DateService,
    private smsMessageRunReportModelAdapter: SMSMessageRunReportModelAdapter,
    public messageFolderService: MessageFolderService,
    private helperService: HelperService,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'smsReports';
    this.init();
  }

  getReports(data): void {
    this.loadingSpinnerService.loading();
    this.smsReportModel = {
      PagerModel: {
        Page: data.currentPage,
        PageSize: data.pageLimit,
        SortColumn: data.sortColumn,
        SortDirection: data.sortDirection
      },
      FolderID: this.selectedFolderId
    };

    this.messageReportsService.getSMSReports(this.smsReportModel).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.data = this.smsMessageRunReportModelAdapter.adaptArray(executionResultDto.data.Item1);
        this.length = executionResultDto.data.Item2;
        this.formatDate();
        this.roundRates();
        this.loadingSpinnerService.stopLoading();
      } else {
        this.notificationService.showError(executionResultDto.message);
        this.loadingSpinnerService.stopLoading();
      }
    });
  }

  formatDate() {
    this.data.forEach((report: SMSMessageRunReportModel) => {
      report.sendDate = this.dateService.formatStringDate(report.sendDate);
    });
  }

  roundRates() {
    this.data.forEach((report: SMSMessageRunReportModel) => {
      report.bounceRate = (+report.bounceRate * 100).toFixed(0);
      report.deliveredRate = (+report.deliveredRate * 100).toFixed(0);
    });
  }

  actionHandler({ selectedRows, action }): void {
    switch (TableActionType[action]) {
      case TableActionType.openDetails:
        this.router.navigate([ 'sms-reports/details/', selectedRows.reportId]);
        break;
      case TableActionType.export:
        this.messageReportsService.export(this.data, selectedRows.map(row => {
          return {
            displayName: row,
            name: this.displayedColumns.find(x => x.displayName === row)?.name
          }
        }), true);
        break;
    }
  }
}
