import { Injectable } from '@angular/core';
import { SMSMessageReportModel } from './sms-message-report-model';

@Injectable({
  providedIn: 'root'
})

export class SMSMessageReportModelAdapter {
  adapt(item: any): SMSMessageReportModel {
    return new SMSMessageReportModel(item);
  }

  adaptArray(init: Array<any>): SMSMessageReportModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new SMSMessageReportModel(table))
      : [new SMSMessageReportModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new SMSMessageReportModel({
      MessageName: '',
      MessageBody: '',
      ListName: '',
      TotalSent: 0,
      SendDate: '',
      ActivityPending: 0,
      ActivityDelivered: 0,
      ActivityBounced: 0,
      ActivityUndelivered: 0,
      ActivityTotalReplies: 0,
      ActivityTotalUnqiueReplies: 0,
      SMSReplies: null,
    });
  }
}
