import { Injectable } from '@angular/core';
import { ContactPlanGridRecordDto } from './contact-plan-grid-record-dto';

@Injectable({
  providedIn: 'root'
})

export class ContactPlanGridRecordDtoAdapter {

  adapt(item: any): ContactPlanGridRecordDto {
    return new ContactPlanGridRecordDto(item);
  }
}
