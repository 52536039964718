export class DatahubFieldsRequestDto {
  clientId: number;
  listId: number;
  type: string;

  constructor(clientId: number, listId: number, type: string) {
    this.clientId = clientId;
    this.listId = listId;
    this.type = type;
  }
}

