import { Injectable } from '@angular/core';
import { StepGridRecordDto } from './step-grid-record-dto';

@Injectable({
  providedIn: 'root'
})

export class StepGridRecordDtoAdapter {

  adapt(item: any): StepGridRecordDto {
    return new StepGridRecordDto(item);
  }
}
