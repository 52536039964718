import { NetworkTypeEnum } from '../posts/network-type-enum';
import { PagerModel } from '../../system/pager-model';

export class SocialReportDtoRequest {
  public PagerModel: PagerModel;
  public DateFrom: string;
  public DateTo: string;
  public NetworkType: NetworkTypeEnum;
  public PostId: number;
  public NetworkDetailId: number;

  constructor(
    DateFrom: string,
    DateTo: string,
    NetworkType: NetworkTypeEnum,
    PostId: number,
    NetworkDetailId: number
  ) {
    this.DateFrom = DateFrom;
    this.DateTo = DateTo;
    this.NetworkType = NetworkType;
    this.PostId = PostId;
    this.NetworkDetailId = NetworkDetailId;
  }
}
