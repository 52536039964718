export class SMSRepliesModel {
  public smsReplyId: number;
  public replyDate: string;
  public smsMessage: string;
  public number: number;

  constructor(init: any) {
    this.smsReplyId = init.SMSReplyID;
    this.replyDate = init.ReplyDate;
    this.smsMessage = init.SMSMessageBody;
    this.number = init.Number;
  }
}
