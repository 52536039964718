import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteType } from '../constants/modal-constants';
import { SelectListOption } from '../../../../_models/system/select-list-option';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delete-folder',
  templateUrl: './delete-folder-modal.component.html',
  styleUrls: ['./delete-folder-modal.component.scss']
})
export class DeleteFolderModalComponent implements OnInit {
  title = 'Confirm Delete Folder';
  deleteType = DeleteType;
  destinationFolders: SelectListOption[] = [];
  deleteFolderFormGroup: UntypedFormGroup;
  isFolderEmpty: boolean;
  selectedFolderName: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    this.deleteFolderFormGroup = new UntypedFormGroup({
      destinationFolders: new UntypedFormControl('', Validators.required)
    });
    this.isFolderEmpty = this.data.isFolderEmpty;
    this.destinationFolders = this.data.folders;
    this.deleteType = DeleteType[this.data.tableKey];
    this.selectedFolderName = this.data.selectedFolder;
  }

  deleteFolder(): void {
    this.dialogRef.close({
      destinationFolder: this.deleteFolderFormGroup.get('destinationFolders').value,
      needToDelete: true
    });
  }
}
