import { ListFieldDto } from './list-field-dto';

export class ListFieldFromFileDto extends ListFieldDto {

  originalName: string;
  isIncluded: boolean;
  addOptions: boolean;

  constructor(init: {originalName: string,
                    isIncluded: boolean,
                    addOptions: boolean} ) {

    super();
    this.originalName = init.originalName;
    this.isIncluded = init.isIncluded;
    this.addOptions = init.addOptions;
  }
}




