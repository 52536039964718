export class DatahubFieldDto {
  
  public id: number;
  public columnName: string;
  public dataType: string;

  constructor(id: number, init: any) {
    this.id = id;
    this.columnName = init.ColumnName;
    this.dataType = init.DataType;     
  }
}





