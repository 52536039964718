<app-modal-container [title]="title"
                     [actionButtonName]="data.name ? 'Edit ' : 'Create '"
                     [buttonValidation]="!folderForm.valid"
                     (action)="save()">
  <form [formGroup]="folderForm">
    <div class="pb-2">
      <label class="input-label">New Folder Name</label>
      <input type="text" maxlength="225" class="form-control input" formControlName="folderName">
    </div>
  </form>
</app-modal-container>
