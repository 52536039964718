import { Injectable } from "@angular/core";
import { NotifyContactsUploadActionInfo } from "./notify-contacts-upload-action-info";

@Injectable({
  providedIn: 'root'
})

export class NotifyContactsUploadActionInfoAdapter {
  adapt(item: any): NotifyContactsUploadActionInfo {
    return new NotifyContactsUploadActionInfo(item);
  }

  adaptArray(init: Array<any>): NotifyContactsUploadActionInfo[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new NotifyContactsUploadActionInfo(table))
      : [new NotifyContactsUploadActionInfo(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new NotifyContactsUploadActionInfo({
      ListId: 0,
      ContactIds: [],
    });
  }
}
