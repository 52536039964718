import { Injectable } from '@angular/core';
import { TriggerSchedulingModel } from './trigger-scheduling-model';

@Injectable({
  providedIn: 'root'
})

export class TriggerSchedulingModelAdapter {
  adapt(item: any): TriggerSchedulingModel {
    return new TriggerSchedulingModel(item);
  }

  adaptArray(init: Array<any>): TriggerSchedulingModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new TriggerSchedulingModel(table))
      : [new TriggerSchedulingModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new TriggerSchedulingModel({
      SchedulingType: null,
      DelayInSeconds: 0,
      DateTime: null,
      MonthDelay: 0,
      DayOfMonth: 0,
      Frequency: '',
      DaysOfWeek: []
    });
  }
}
