import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../system/localStorage/localStorage.service';
import { ExecutionResultAdapter } from 'src/app/_models/execution-result-model';
import { Auth } from 'aws-amplify';
import { CognitoUserAttributesDto } from 'src/app/_models/admin/users/cognito/cognito-user-attributes';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  _baseUrl: string = environment.apiURL;
  jwtHelper = new JwtHelperService();
  cognitoUserSession: any;
  setupTwoFactorCode: string;
  localUserModel: any = {};
  signedInUser: any;
  userAuthenticated: boolean;
  signedUpUser: any = {};
  cognitoSignUp: boolean;
  newUserLocal: any = {};
  awsRegisterResult: string;
  cognitoUserAttributes: CognitoUserAttributesDto;
  localUserSignUp: string;
  awsNewUser: any = {};

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService,
    private executionResultAdapter: ExecutionResultAdapter) { }

  login(model: any, saveUserToStorage: boolean) {
    return this.http.post(this._baseUrl + 'Account/Login', model)
      .pipe(
        map((response: any) => {
          const user = response;
          this.signedInUser = user;
          if (saveUserToStorage === true) {
            this.saveUserToLocalStorage(user);
          }
        })
      );
  }

  // send a login request using only the userId - this will be after they have redirected from Marketing.Delivery platform
  // the user doesn't need to login again as they will already have
  // an active session - we can use the token to pass over to Voicebox to validate the user
  async loginWithId(userId: string, userToken: string, saveUserToStorage: boolean) {

    var endpointUrl = this._baseUrl + 'Account/LoginWithUserId' + '?userToken=' + userToken + '&userId=' + userId;
    console.log(endpointUrl);
    var user = await this.http.get(endpointUrl).pipe().toPromise();

    if (saveUserToStorage === true) {
      this.saveUserToLocalStorage(user);
    }
  }

  saveUserToLocalStorage(user: any) {
    if (user) {
      this.localUserModel = user;
      this.localStorageService.setUserId = user.UserId;
      this.localStorageService.setFirstName = user.FirstName;
      this.localStorageService.setLastName = user.LastName;
      this.localStorageService.setEmail = user.Email;
      this.localStorageService.setUserRoles = user.UserRoles;
      this.localStorageService.setToken = user.Token;
      this.localStorageService.setExpires = user.Expires;
      this.localStorageService.setPermissions = user.Permissions;
      this.localStorageService.setUsername = user.UserName;
      this.localStorageService.setUserIconUrl = user.UserIconUrl;
      this.localStorageService.setDefaultClientId = user.DefaultClient;
      this.localStorageService.Title = user.Title;
      if (localStorage.getItem("CurrentClientId") == null) {
        this.localStorageService.setCurrentClientId = user.DefaultClient;
      }
    }
  }

  // refresh the current authenticated user and get their information
  async awsGetCurrentAuthenticatedUser(): Promise<any> {
    try {
      var user = await Auth.currentAuthenticatedUser();
      user.getCachedDeviceKeyAndPassword(); // without this line, the deviceKey is null
      return user;

    } catch (error) {
      return error;
    }
  }

  // this method allows us to refresh the user tokens so that they can stay logged in
  async awsGetCurrentSession(): Promise<any> {
    try {
      return await Auth.currentSession();
    } catch (error) {
      return error;
    }
  }

  // attempt to sign into Cognito with the credentials provided
  async awsSignIn(loginAttempt: any): Promise<string> {
    try {

      this.cognitoUserSession = await Auth.signIn(loginAttempt.username.toLowerCase(), loginAttempt.password);      

      if (this.cognitoUserAttributes === undefined) {
        this.cognitoUserAttributes = this.cognitoUserSession.attributes;
      }

      // the user needs to provide the 2FA code to log in
      if (this.cognitoUserSession.challengeName === 'SMS_MFA' ||
        this.cognitoUserSession.challengeName === 'SOFTWARE_TOKEN_MFA') {
        return 'Two Factor Authentication';
      }
      else if (this.cognitoUserSession.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // this is used when a user has been created in Cognito manually - they will need to have their password
        // changed by using a specific page in the app
        return 'New Password Required';
      } else if (this.cognitoUserSession.challengeName === 'MFA_SETUP') {
        // the user needs to set up 2FA for their login
        return '2FA Setup';
      } else {
        // the user has successfully signed in
        return 'true';
      }
    } catch (error) {
      // catch any unexpected errors
      console.log('Error signing in to AWS: ', error)
      return error.message;
    }
  }

  // attempt to verify a two factor authentication code against a specific user
  async awsTwoFactorAuthentication(user: any, userCode: string): Promise<string> {
    try {
      if (this.cognitoUserAttributes === undefined) {
        this.cognitoUserAttributes = this.cognitoUserSession.attributes;
      }
      this.cognitoUserSession = await Auth.confirmSignIn(user, userCode, 'SOFTWARE_TOKEN_MFA');
      return 'true';
    } catch (error) {
      return error.message;
    }
  }

  // attempt to sign up to Cognito with the information provided
  async awsSignUp(signedUpUser: any): Promise<string> {
    try {
      this.cognitoUserSession = await Auth.signUp({
        'username': signedUpUser.username,
        'password': signedUpUser.password,

        'attributes': {
          'email': signedUpUser.email,
          'name': signedUpUser.firstName + " " + signedUpUser.lastName,
          'custom:title': signedUpUser.title
        }
      })
      return 'true';
    } catch (error) {
      console.log('Error signing up: ', error);
      return error.message;
    }
  }

  // request a forgotten email password from Cognito
  async awsForgotPasswordRequest(username: string): Promise<string> {
    try {
      await Auth.forgotPassword(username);
      return 'true';
    } catch (error) {
      console.log('Error sending reset request: ', error);
      return error.message;
    }
  }

  // submit a new password to replace the forgotten one in Cognito
  async awsForgotPassword(username: string, code: string, newPassword: string): Promise<string> {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      return 'true';
    } catch (error) {
      console.log('Error resetting password: ', error);
      return error.message;
    }
  }

  // submit a new password to replace the old one in Cognito
  async awsNewPassword(newPassword: string): Promise<string> {
    try {
      await Auth.completeNewPassword(this.cognitoUserSession, newPassword, {
        'email': this.cognitoUserSession.email,
        'name': this.cognitoUserSession.firstName + " " + this.cognitoUserSession.lastName,
        'custom:title': this.cognitoUserSession.title
      });
      return 'true';
    } catch (error) {
      console.log('Error updating password: ', error);
      return error.message;
    }
  }

  // request for a TOTP QR setup code from Cognito for 2FA
  async awsGetTotpQrCode(user: any): Promise<string> {
    try {
      await Auth.setupTOTP(user).then((code) => { this.setupTwoFactorCode = code });
      return this.setupTwoFactorCode;
    } catch (error) {
      console.log('Error getting TOTP code: ', error);
      return error.message;
    }
  }

  // set a users login challenge to TOTP so that they must use 2FA to login
  async awsSetupTotpForUser(user: any, challengeAnswer: string) : Promise<string> {
    try {
      await Auth.verifyTotpToken(user, challengeAnswer).then(() => {
        Auth.setPreferredMFA(user, 'TOTP');
      });
      return 'true';
    } catch (error) {
      console.log('Error setting user to TOTP: ', error);
      return error.message;
    }
  }

  // complete the sign up process for a Cognito user by confirming the code that they have entered
  async awsCompleteSignUp(username: string, code: string): Promise<string> {
    try {
      await Auth.confirmSignUp(username, code);
      this.cognitoUserSession = Auth.currentAuthenticatedUser;
      return 'true';
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }

  // request a new confirmation code from Cognito
  async awsResendConfirmationCode(username: string): Promise<string> {
    try {
      await Auth.resendSignUp(username);
      return 'true';
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }

  // When creating a new user, sometimes it does not refresh when the sign up process is complete
  // when this happens, we need to sign the user out and in automatically to refresh the model we have locally
  // so that we can manage the login flow correctly
  async awsReSignInUser(): Promise<string> {
    try {
      await Auth.signOut();

      this.cognitoUserSession = await Auth.signIn(this.signedUpUser);

      return 'true';
    } catch (error) {

      console.log(error);
      return error.message;
    }
  }

  // sign the user out of Cognito - this can be either just for the current app, or globally
  async awsLogOut() {
    // add global : true to sign out of Cognito globally, instead of just in this one place
    //await Auth.signOut({global : true});
    this.cognitoUserSession = undefined;
    await Auth.signOut();
  }

  // if a user exists in Cognito but not the local DB, attempt to add them to local - we try and use the Cognito information here as this 
  // is an automatic attempt to sign a user up when they exist in Cognito, but not the local app
  async addUserToLocalDB(): Promise<string> {

    // grab the information from the signed in Cognito user
    try {
      const userLoginAttempt = await Auth.currentAuthenticatedUser();

      const splitNames = userLoginAttempt.attributes.name.split(" ");

      this.newUserLocal.Email = userLoginAttempt.attributes.email;
      this.newUserLocal.FirstName = splitNames[0];
      this.newUserLocal.LastName = splitNames[1];
      if (userLoginAttempt.attributes.title) {
        this.newUserLocal.Title = userLoginAttempt.attributes.title;
      }
      else {
        // assign a default title if one does not exist in the Cognito account as it is a required field
        this.newUserLocal.Title = 'Mr';
      }

      await this.register(this.newUserLocal).toPromise().then();

      this.localUserSignUp = 'Success';
    } catch (error) {
      console.log(error);
      this.localUserSignUp = error.message;
      return error.message;
    }
  }

  // if the user exists in the Local DB but not Cognito, we should attempt to add them to Cognito by using the 
  // local db information we have saved
  async addUserToCognito(awsNewUser: any): Promise<string> {
    try {
      var loginResult: any;
      this.login(this.localUserModel, false).toPromise().then(result => loginResult = result);

      awsNewUser.email = this.signedInUser.Email;
      awsNewUser.firstName = this.signedInUser.FirstName;
      awsNewUser.lastName = this.signedInUser.LastName;

      if (this.signedInUser.title) {
        awsNewUser.title = this.signedInUser.title;
      }
      else {
        // assign a default title if one does not exist in the Cognito account as it is a required field
        awsNewUser.title = 'Mr';
      }

      await this.awsSignUp(awsNewUser).then(result => this.awsRegisterResult = result);
      if (this.awsRegisterResult === 'true') {
        return 'Success';
      }
      else {
        return 'Failed';
      }
    } catch (error) {
      return error.message;
    }
  }

  // if the user exists in the Local DB but not Cognito, we should attempt to add them to Cognito by using the 
  // local db information we have saved - however, as we receive this information slightly differently
  // due to the user being inactive, we need to manipulate the objects differently
  async addInactiveUserToCognito(awsNewUser: any): Promise<string> {
    try {

      this.awsNewUser.email = awsNewUser.Email;
      this.awsNewUser.firstName = awsNewUser.FirstName;
      this.awsNewUser.lastName = awsNewUser.LastName;

      if (awsNewUser.Title) {
        this.awsNewUser.title = awsNewUser.Title;
      }
      else {
        // assign a default title if one does not exist in the Cognito account as it is a required field
        this.awsNewUser.title = 'Mr';
      }

      await this.awsSignUp(this.awsNewUser).then(result => this.awsRegisterResult = result);
      if (this.awsRegisterResult === 'true') {
        return 'Success';
      }
      else {
        return 'Failed';
      }
    } catch (error) {
      return error.message;
    }
  }

  loggedIn() {
    const token = this.localStorageService.getToken;
    return !this.jwtHelper.isTokenExpired(token);
  }

  register(model: any) {
    return this.http.post(this._baseUrl + 'Account/Register', model);
  }

  reGenToken() {
    return this.http
      .get(this._baseUrl + 'Account/UpdateUserSession')
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));;
  }

  getExtenedToken() {
    return this.http.get(this._baseUrl + 'Account/GetExtendedToken')
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  async passwordReset(model: any): Promise<any> {
    return this.http.post(this._baseUrl + 'Account/ResetPassword', model).toPromise();
  }
}
