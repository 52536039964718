import { Injectable } from '@angular/core';
import { ContactDto } from './contact-dto';


@Injectable({
  providedIn: 'root'
})

export class ContactDtoAdapter {
  public adapt(item: any) {
    return new ContactDto(item);
  }
}
