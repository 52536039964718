export class ContactPlanGridRecordDto {
    changedBy: string;
    changeDate: Date;
    contactPlanId: number;
    createdBy: string;
    createDate: Date;
    folderId: number;
    folderName: string;
    status: boolean;
    title: string;

    constructor(init: any) {

        this.changeDate = init.ChangeDate;
        this.changedBy = init.ChangedBy;
        this.contactPlanId = init.ContactPlanID;
        this.createdBy = init.CreatedBy;
        this.createDate = init.CreateDate;
        this.folderId = init.FolderID;
        this.folderName = init.FolderName;
        this.status = init.Status;
        this.title = init.Title;
    }
}

export type ContactPlanGridRecordType = {
  changedBy: string;
  changeDate: Date;
  contactPlanId: number;
  createdBy: string;
  createDate: Date;
  folderId: number;
  folderName: string;
  status: boolean;
  title: string;
};
