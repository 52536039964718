import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  getData$ = new Subject();

  getTableData(): Observable<any> {
    return this.getData$.asObservable();
  }

  requestTableData(data?): void {
    this.getData$.next(data);
  }
}
