import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model';
import { CopyListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/copy-list-model';
import { MoveListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/move-list-model';
import { PagerDtoRequest } from '../../../../_models/messaging/pager-dto-request';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class ListsService {
  private baseUrl = environment.apiURL;
  private controllerName = 'List';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter,
    private localStorageService: LocalStorageService
  ) {  }

  createEditList(list: ListModel) {
    let currentClientId = +this.localStorageService.getCurrentClientId;
    return this.http.post(this.baseUrl + 'List?clientId=' + currentClientId, list).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getAllListTypes(): Observable <ExecutionResultDto> {
    return this.http
    .get(this.baseUrl + 'List/GetListTypes')
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAllLists(): Observable <ExecutionResultDto> {
    return this.http
    .get(this.baseUrl + 'List')
    .pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getLists(pagerDtoRequest: PagerDtoRequest, folderId: number): Observable <ExecutionResultDto> {
    const query = `?Page=${pagerDtoRequest.page}` +
      `&PageSize=${pagerDtoRequest.pageSize}` +
      `&SortColumn=${pagerDtoRequest.sortColumn}` +
      `&SortDirection=${pagerDtoRequest.sortDirection}` +
      `&folderId=${folderId}`;

    return this.http
      .get(`${this.baseUrl}${this.controllerName}/GetLists${query}`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAllLightLists(folderID: number, clientId: number = 0): Observable <ExecutionResultDto> {
    var query = `?folderId=` + folderID + `&clientId=` + clientId;

    return this.http
    .get(this.baseUrl + 'List/GetLightFolderLists' + query)
    .pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getListById(listID: number): Observable <ExecutionResultDto> {
    return this.http.get(this.baseUrl + 'List/' + listID).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getByFolderId(folderID: number): Observable <ExecutionResultDto> {
    return this.http.get(this.baseUrl + 'List/GetByFolderID/' + folderID).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getWildcardListFields(listId: number): Observable <ExecutionResultDto> {
    return this.http.get(this.baseUrl + 'List/GetWildcardListFields/' + listId).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getAllListFields(listId: number): Observable <ExecutionResultDto> {
    return this.http.get(this.baseUrl + 'List/GetAllListFields/' + listId).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  delete(listID: number) {
    return this.http.delete(this.baseUrl + 'List/' +  listID).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  copy(copyList: CopyListModel) {
    return this.http.post(this.baseUrl + 'List/CopyList', copyList).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  move(moveList: MoveListModel) {
    return this.http.post(this.baseUrl + 'List/MoveLists', moveList).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  clearList(listID: number) {
    return this.http.post(this.baseUrl + 'List/ClearList', listID).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  updateDelete({ folderId, destinationFolderId }) {
    const sourceFolderParams = new HttpParams().set(
      'sourceFolderId', folderId.toString());
    const destinationFolderParams = new HttpParams().set(
      'destinationFolderId', destinationFolderId.toString());

    return this.http.delete(this.baseUrl + 'ListFolder/?' + sourceFolderParams + '&' + destinationFolderParams)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAllFieldTypes() {
    return this.http.get(this.baseUrl + 'List/GetFieldTypes').pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getAllFieldControlTypes() {
    return this.http.get(this.baseUrl + 'List/GetFieldControlTypes').pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getAllFieldNamesForAllTables() {
    return this.http.get(this.baseUrl + 'List/GetAllFieldNamesForAllTables').pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getAllFoldersAllListsAllFields() {
    return this.http.get(this.baseUrl + 'List/GetAllFoldersAllListsAllFields').pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }
}
