export class MessageCodeGridRecordDto {
    changedBy: string;
    changeDate: Date;
    messageCodeId: number;
    createdBy: string;
    createDate: Date;
    folderId: number;
    folderName: string;
    title: string;

    constructor(init: any) {

        this.changeDate = init.ChangeDate;
        this.changedBy = init.ChangedBy;
        this.messageCodeId = init.MessageCodeID;
        this.createdBy = init.CreatedBy;
        this.createDate = init.CreateDate;
        this.folderId = init.FolderID;
        this.folderName = init.FolderName;
        this.title = init.Title;
    }
}

export type MessageCodeGridRecordType = {
  changedBy: string;
  changeDate: Date;
  messageCodeId: number;
  createdBy: string;
  createDate: Date;
  folderId: number;
  folderName: string;
  title: string;
}
