<form [formGroup]="this.actionRelatesFormGroup">

  <div class="md-form remove-top-margin">
    <div class="form-check">
      <input 
        type="radio" 
        class="form-check-input" 
        id="list-trigger-check-1" 
        [value]="'list'"
        formControlName="triggerActionType" 
        name="triggerActionType"
        label="A trigger against the following list">
      <label class="form-check-label" for="list-trigger-check-1">A trigger against the following list</label>
    </div>
  </div>

  <div class="offset" *ngIf="_showListTypeTrigger" [@slide]>
    <div class="md-form remove-vertical-margin">
      <mdb-select
        [options]="_listFolderDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select Folder"
        formControlName="listFolder">
      </mdb-select>
    </div>

    <div class="md-form remove-bottom-margin">
      <mdb-select
        [options]="_lightListsDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select List"
        formControlName="list">
      </mdb-select>
      <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['actionRelatesFormGroup', 'list'])">This field is required.</div>
    </div>
  </div>

  <div class="md-form">
    <div class="form-check">
      <input
        type="radio" 
        class="form-check-input" 
        id="list-trigger-check-2" 
        [value]="'message'"
        formControlName="triggerActionType" 
        name="triggerActionType">
      <label class="form-check-label" for="list-trigger-check-2">The following message being sent</label>
    </div>
  </div>

  <div class="offset" *ngIf="!_showListTypeTrigger" [@slide]>
    <p class="text-dark">Select type of message</p>

    <div class="form-check form-check-inline">
      <input 
        type="radio" 
        class="form-check-input" 
        id="email-type" 
        name="messageType"
        formControlName="messageType"
        [value]="1">
      <label class="form-check-label" for="email-type">Email</label>
    </div>

    <div class="form-check form-check-inline">
      <input 
        type="radio" 
        class="form-check-input" 
        id="sms-type" 
        name="messageType"
        formControlName="messageType"
        [value]="2">
      <label class="form-check-label" for="sms-type">SMS</label>
    </div>

    <div class="md-form remove-bottom-margin">
      <mdb-select
        [options]="_messageFolderDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select Folder"
        formControlName="messageFolder"
        (selected)="MessageFolderSelected()"
        >
      </mdb-select>
    </div>
    <div class="md-form remove-bottom-margin">
      <mdb-select
        [options]="_lightMessageDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select Message"
        formControlName="message"
        (selected)="onMessageChanged()">
      </mdb-select>
      <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['actionRelatesFormGroup', 'message'])">This field is required.</div>
    </div>

    <p class="text-dark mt-2 font-weight-bold">... to the following list ...</p>
    <div class="md-form remove-vertical-margin">
      <mdb-select
        [options]="_listFolderDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select Folder"
        formControlName="listFolder">
      </mdb-select>
    </div>

    <div class="md-form remove-bottom-margin">
      <mdb-select
        [options]="_lightListsDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select List"
        formControlName="list">
      </mdb-select>
      <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['actionRelatesFormGroup', 'list'])">This field is required.</div>
    </div>
  </div>

</form>
