import { MessageTypeEnum } from '../../../messages/message-type-enum';

export class NotifyContactsActionInfo {
  public MessageId: number;
  public MessageType: MessageTypeEnum;
  public ListId: number;
  public ContactIds: Array<number>;

  constructor(init: any) {
    this.MessageId = init.MessageId;
    this.MessageType = init.MessageType;
    this.ListId = init.ListId;
    this.ContactIds = init.ContactIds;
  }
}
