import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteRestoreModalComponent } from '../delete-restore/delete-restore-modal.component';
import { TableName } from '../../constants/table-constants';

@Component({
  selector: 'app-create-edit-folder-modal',
  templateUrl: './create-edit-folder-modal.component.html',
  styleUrls: ['./create-edit-folder-modal.component.scss']
})
export class CreateEditFolderModalComponent implements OnInit {
  folderForm: UntypedFormGroup;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteRestoreModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.folderForm = new UntypedFormGroup({
      folderName: new UntypedFormControl(this.data.name, Validators.required)
    });

    this.setTitle();
  }

  setTitle(): void {
    const actionName = this.data.name ? 'Edit ' : 'Create ';
    this.title = actionName + TableName[this.data.tableKey] + ' Folder';
  }

  save(): void {
    this.dialogRef.close({ newName: this.folderForm.get('folderName').value });
  }
}
