import { Injectable } from '@angular/core';
import { TriggerModel } from './trigger-model';

@Injectable({
  providedIn: 'root'
})

export class TriggerModelAdapter {
  adapt(item: any): TriggerModel {
    return new TriggerModel(item);
  }

  adaptArray(init: Array<any>): TriggerModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new TriggerModel(table))
      : [new TriggerModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new TriggerModel({
      TriggerId: 0,
      Title: '',
      Description: '',
      ActionType: null,
      Parameters: null,
      WorkflowId: null,
    });
  }
}
