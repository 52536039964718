import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';
import { FacebookConfigurationModelDto } from 'src/app/_models/social-media/networks/facebook-configuration-model-dto';
import { FacebookConfigurationModelAdapter } from './facebook-configuration-model-adapter';
import { NetworkAdapter } from './network-adapter';

export class NetworkModelDto {
  configuration: FacebookConfigurationModelDto;
  networks: NetworkDto[];

  constructor(init: any) {
    this.configuration = new FacebookConfigurationModelAdapter().adapt(init.Configuration);
    this.networks = init.Networks.map(network => new NetworkAdapter().adapt(network));
  }
}
