import { Injectable } from '@angular/core';
import { LinkModel } from './link-model';
import { PostAttachmentModel } from './post-attachment-model';

export class PostModel {
  public PostID: number;
  public NetworkType: number;
  public PostDateTime: Date;
  public PostText: string;
  public CustomLink: LinkModel;
  public CheckRelatedPosts: boolean;
  public AlbumName: string;
  public Attachments: Array<PostAttachmentModel>;
  public PostAttachments: string;
  public Status: number;

  constructor(init: any) {
    this.PostID = init.PostID;
    this.NetworkType = init.NetworkType;
    this.PostDateTime = init.PostDateTime;
    this.PostText = init.PostText;
    this.CustomLink = init.CustomLink;
    this.CheckRelatedPosts = init.CheckRelatedPosts;
    this.AlbumName = init.AlbumName;
    this.Attachments = init.Attachments;
    this.PostAttachments = init.PostAttachments;
    this.Status = init.Status;
  }
}

@Injectable({
  providedIn: 'root'
})

export class PostModelAdapter {
  adapt(item: any): PostModel {
    return new PostModel(item);
  }
}
