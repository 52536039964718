import { MessageTypeEnum } from '../../messages/message-type-enum';

export class StepGridRecordDto { 
    id: number;
    changedBy: string;
    changeDate: Date;
    contactPlanId: number;
    createdBy: string;
    createDate: Date;
    description: string;
    folderId: number;
    reportId: number;    
    status: boolean;
    title: string;
    actionType: string;
    reportType: MessageTypeEnum;


    constructor(init: any) {
        this.changeDate = init.ChangeDate;
        this.changedBy = init.ChangedBy;
        this.contactPlanId = init.ContactPlanID;
        this.createdBy = init.CreatedBy;
        this.createDate = init.CreateDate;
        this.description = init.Description;
        this.id = init.StepDd;
        this.folderId = init.FolderId;
        this.status = init.Status;
        this.id = init.StepID;
        this.actionType = init.ActionType;
        
        if (init.ReportID > 0) {
            this.reportId = init.ReportID;
            this.reportType = MessageTypeEnum.email;
        } else if (init.SMSReportID > 0) {
            this.reportId = init.SMSReportID;
            this.reportType = MessageTypeEnum.sms;
        } else {
            this.reportId = 0;
            this.reportType = null;
        }

        this.title = init.Title;
    }
}
