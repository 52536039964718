<app-modal-container [title]="title"
                     [actionButtonName]="'Create'"
                     [buttonValidation]="!createListFormGroup.valid"
                     (action)="createList()">

  <form [formGroup]="createListFormGroup">
    <app-md-select label="Select Folder"
                   formControlName="folder"
                   [options]="listFolderOptions">
    </app-md-select>

    <app-md-select label="Select List Type"
                   formControlName="type"
                   [options]="listTypeOptions">
    </app-md-select>

    <app-md-input label="New List Name" formControlName="name"></app-md-input>

    <app-md-input label="Comment" formControlName="comment"></app-md-input>
  </form>
</app-modal-container>
