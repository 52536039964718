import { OverviewMetricsReportDto } from "./overview-metrics-report-dto";

export class LinkedInMetricsReportDto extends OverviewMetricsReportDto {

  public Posts: Array<any>[];
  public TotalReach: number;
  public TotalLikes: number;
  public TotalShares: number;
  public TotalComments: number;
  public TotalClicks: number;

  constructor(init: {
    Posts: Array<any>[],
    TotalReach: number,
    TotalLikes: number,
    TotalShares: number,
    TotalComments: number,
    TotalClicks: number,

    TotalPosts: number;
    TotalFollowers: number;
    EngagementRate: number;
    TotalImpressions: number;
  }) {
    super(init);
    this.Posts = init.Posts;
    this.TotalReach = init.TotalReach;
    this.TotalLikes = init.TotalLikes;
    this.TotalShares = init.TotalShares;
    this.TotalComments = init.TotalComments;
    this.TotalClicks = init.TotalClicks;
  }
}  