<section class="view intro intro-2">
  <div class="full-bg-img rgba-stylish-strong mask flex-center">
    <div class="container animated fadeIn">
      <div class="row">
        <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

          <!--Form with header-->
          <mdb-card bgColor="login-card">
            <mdb-card-body>

              <!--Header-->
              <div class="form-header primary-color rounded-lg">
                <h3>
                  <mdb-icon fas icon="sign-in-alt" class="mt-2 mb-2"></mdb-icon> New Password
                </h3>
              </div>

              <!--Body-->

              <div>
                <img src="../../../../assets/images/voicebox-logo-square.png" alt="" class="vb-logo-image">
              </div>

              <form [formGroup]="_newPasswordForm" (ngSubmit)="submitNewPassword(newPassword.value)">
                <div class="md-form">
                  <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                  <input type="password" [ngClass]="{'is-invalid': _newPasswordForm.get('password').errors
                              && _newPasswordForm.get('password').touched}" id="password" class="form-control"
                    formControlName="password" mdbInput #newPassword />
                  <label for="password">New password</label>
                  <div class="invalid-feedback ml-5" *ngIf="_newPasswordForm.get('password').hasError('required')
                        && _newPasswordForm.get('password').touched">Please enter your new Password.
                  </div>
                  <div class="invalid-feedback ml-5" *ngIf="_newPasswordForm.get('password').hasError('minlength')
                        && _newPasswordForm.get('password').touched">Password must be atleast 8 characters.
                  </div>
                  <div class="invalid-feedback ml-5" *ngIf="_newPasswordForm.get('password').hasError('weak')
                        && _newPasswordForm.get('password').touched">Passwords must contain one upper case letter,
                    <br />one lower case letter and one special character.
                    <br />Special characters include: ^ $ * . [ ] &#123; &#125; ( ) ? &quot; ! @ # % / , &lt; &gt; &apos; : ; | _ ~ &#96;
                  </div>
                </div>

                <div class="md-form">
                  <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                  <input type="password" [ngClass]="{'is-invalid': _newPasswordForm.get('confirmPassword').errors
                              && _newPasswordForm.get('confirmPassword').touched
                              || _newPasswordForm.get('confirmPassword').touched
                              && _newPasswordForm.hasError('mismatch')}" id="password-confirm" class="form-control"
                    formControlName="confirmPassword" mdbInput>
                  <label for="password-confirm">Confirm your password</label>
                  <div class="invalid-feedback ml-5" *ngIf="_newPasswordForm.get('confirmPassword').hasError('required')
                        && _newPasswordForm.get('confirmPassword').touched">Please enter your new Password.
                  </div>
                  <div class="invalid-feedback ml-5" *ngIf="_newPasswordForm.hasError('mismatch')
                        && _newPasswordForm.get('confirmPassword').touched">Passwords must match.
                  </div>
                </div>

                <div class="text-center">
                  <button *ngIf="!_submitting" mdbBtn size="lg"
                    class="waves-effect waves-light btn-primary rounded-lg login-btn"
                    [disabled]="!_newPasswordForm.valid" mdbWavesEffect>Submit</button>
                  <button *ngIf="_submitting" mdbBtn size="lg" class="waves-effect waves-light btn-primary rounded-lg"
                    type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Submitting...
                  </button> <br>
                </div>
              </form>

            </mdb-card-body>
          </mdb-card>
          <!--/Form with header-->

        </div>
      </div>
    </div>
  </div>
</section>