export class ClientETLStatusChangeDto {
  MDLClientDMSId: number;
  ClientId: number;
  ActiveFlag: boolean;
  UpdatedUserId: number;

  constructor(init: any) {
    this.MDLClientDMSId = init.MDLClientDMSId;
    this.ClientId = init.ClientId;
    this.ActiveFlag = init.ActiveFlag;
    this.UpdatedUserId = init.UpdatedUserId;
  }
}
