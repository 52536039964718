<app-modal-container [title]="title"
                     actionButtonName="Delete"
                     (action)="deleteFolder()">
  <form [formGroup]="deleteFolderFormGroup">
    <div class="mb-3">Are you sure you want to delete the folder
      <span class="bold">{{ selectedFolderName }}</span>?
    </div>
    <div *ngIf="!isFolderEmpty">
      <div class="mb-3">{{ deleteType }}s from this folder will be moved to:</div>
      <label class="input-label">Select Folder</label>
      <select class="form-control select-input select-folder" formControlName="destinationFolders">
        <option *ngFor="let folder of destinationFolders; let i = index" [ngValue]="folder.value">
          {{folder.label}}
        </option>
      </select>
    </div>
  </form>
</app-modal-container>
