export class MessageSendDtoRequest {
    messageId: number;
    listId: number;
    contactsFilter: string;

    constructor(init: { messageId: number, listId: number, contactsFilter: string }) {
        this.messageId = init.messageId;
        this.listId = init.listId;
        this.contactsFilter = init.contactsFilter;
    }
}
