export class PagerDtoRequest {
  page: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;

  constructor(param: {page?: number; pageSize?: number; sortColumn?: string; sortDirection?: string}) {
    this.page = param.page;
    this.pageSize = param.pageSize;
    this.sortColumn = param.sortColumn;
    this.sortDirection = param.sortDirection;
  }
}
