import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { NotificationService } from 'src/app/_services/notification.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  _model: any = {};
  _registerForm: UntypedFormGroup;
  _modalRef: MDBModalRef;
  _registering: boolean;
  _awsRegister: string;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createRegisterForm();
  }

  createRegisterForm() {
    this._registerForm = this.fb.group({
      recaptcha: ['', Validators.required],
      title: ['',
        [Validators.required, Validators.maxLength(9)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['',
        [Validators.required, Validators.minLength(8), this.passwordStrengthValidator]],
      confirmPassword: ['', Validators.required]
    }, { validators: [this.passwordMatchValidator] });
  }

  // ensure password and confirm password fields match
  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : { 'mismatch': true };
  }

  // ensure that the password is secure enough
  passwordStrengthValidator(g: UntypedFormGroup) {
    let hasNumber = /\d/.test(g.value);
    let hasUpper = /[A-Z]/.test(g.value);
    let hasLower = /[a-z]/.test(g.value);
    let hasSpecial = /[`!@#$%^&*()_\\[\]{};':"\\|,.<>\/?~]/.test(g.value);

    const valid = hasNumber && hasUpper && hasLower && hasSpecial;

    if (!valid) return { 'weak': true };
    return null;
  }

  // attempt to register with Cognito first and then locally
  // using the information provided on the form for both 
  async register() {
    if (this._registerForm.valid) {
      this._registering = true;
      this._model = Object.assign({}, this._registerForm.value);
      this.authService.newUserLocal.username = this._model.username;
      this.authService.newUserLocal.password = this._model.password;
      await this.authService.awsSignUp(this._model).then(result => this._awsRegister = result);
      // if we succeed registering on Cognito, then attempt to register locally before moving back to login
      if (this._awsRegister === 'true') {
        this.authService.register(this._model).subscribe(() => {
          this.notificationService.showSuccess("Registration complete!");
          this.router.navigate(['/login', { data: this._model.username }]);
        }, error => {
          this.notificationService.showError("Error signing up. " + error.message);
          this._registering = false;
        }, () => {
        });
      }
      else {
        this.notificationService.showError("Error signing up. " + this._awsRegister);
        this._registering = false;
      }
    }
  }
}
