<div class="container-fluid animated fadeIn">
  <div class="row m-4">
      <div class="col-lg-12">
        <!--Card-->
        <mdb-card cascade="true" narrower="true">
            <!--Card header-->
            <div class="view view-cascade card-header text-center primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center align-items-center">

              <h4 class="h4-responsive card-header-text">User Roles</h4>
          
            </div>
            <!--/Card header-->
          
            <div class="px-4">
          
              <div class="table-wrapper">
                <!--Table-->
                <table mdbTable #tableEl="mdbTable" mdbTableScroll scrollX="true" maxWidth="1494" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
                    <thead class="sticky-top">                    
                      <tr>
                        <th class="text-center" *ngFor="let head of this._headerElements" [mdbTableSort]="this._elements" [sortBy]="head.name"
                            scope="col">{{head.name | titlecase}} <mdb-icon *ngIf="head.sortable" fas icon="sort"></mdb-icon>
                        </th>
                      </tr>
                    </thead>
                    <tbody #row>
                    <tr mdbTableCol *ngFor="let el of this._elements; let i = index">
                      <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row">{{el.id}}</td>
                      <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.name}}</td>
                      <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                        <div class="btn-group mx-4" mdbDropdown>
                            <mdb-icon fas icon="user" classInside="dropdown-toggle" mdbDropdownToggle class="waves-effect icon-color"
                              mdbWavesEffect></mdb-icon>
                            <div class="dropdown-menu dropdown-primary">
                              <a class="dropdown-item" (click)="editUserRole(el.id)">Edit</a>                                
                            </div>
                          </div>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                    <tr>
                      <td colspan="8">
                        <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="this._elements"></mdb-table-pagination>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                <!--/Table-->
              </div>
            </div>
          </mdb-card>
        <!--/Card-->
      </div>
  </div>
</div>