import { Injectable } from '@angular/core';
import { NotifyContactsActionInfo } from './notify-contacts-action-info';

@Injectable({
  providedIn: 'root'
})

export class NotifyContactsActionInfoAdapter {
  adapt(item: any): NotifyContactsActionInfo {
    return new NotifyContactsActionInfo(item);
  }

  adaptArray(init: Array<any>): NotifyContactsActionInfo[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new NotifyContactsActionInfo(table))
      : [new NotifyContactsActionInfo(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new NotifyContactsActionInfo({
      MessageId: 0,
      MessageType: null,
      ListId: 0,
      ContactIds: [],
    });
  }
}
