import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { CreateEditUserRoleComponent } from './create-edit-user-role/create-edit-user-role.component';
import { UserRolesComponent } from './user-roles/user-roles.component';

@Component({
  selector: 'app-user-role-management',
  templateUrl: './user-role-management.component.html',
  styleUrls: ['./user-role-management.component.scss']
})
export class UserRoleManagementComponent implements OnInit {

  @ViewChild('createEditUserRole') child: CreateEditUserRoleComponent;
  @ViewChild('userRoles') userRolesChild: UserRolesComponent;

  constructor() { }

  _searchText: string;
  _component = 'userRoles';
  _createUserRole: boolean;

  // variable for search data
  _pageTitle = 'User-Role-Management';

  ngOnInit() {
  }

  setPage(changeComponent) {
    this._component = changeComponent;
    if (this._component === 'createEditUserRole') {
      this._createUserRole = true;
      if (this.child) {
        this.child.createUserRole = true;
        this.child.editUserRoleState();
      }
    }
  }

  receivePageChange($event) {
    this._component = $event;
  }
  receiveComingFrom($event) {
    this._createUserRole = $event;
  }

  @HostListener('input') oninput() {
    if (this.userRolesChild) {
      this.userRolesChild.searchText = this._searchText;
      this.userRolesChild.searchItems();
    }
  }

}
