<div class="network-group-card d-flex flex-column align-items-center">
  <div class="social-icons">
    <div *ngIf="hasFacebookNetwork(); then facebookLogo"></div>
    <div *ngIf="hasInstagramNetwork(); then instagramLogo"></div>
    <div *ngIf="hasTwitterNetwork(); then twitterLogo"></div>
    <div *ngIf="hasLinkedInNetwork(); then linkedinLogo"></div>
  </div>
  <div class="d-flex w-100 justify-content-between">
    <div class="float-left">
      <h4 class="m-0">{{networkGroup.name}}</h4>
    </div>

    <div *ngIf="userPermissions.Social.NetworkGroups.Delete || userPermissions.Social.NetworkGroups.Edit"
         class="dropdown float-left pointer" mdbDropdown>
      <img src="./assets/images/Icons/Dots.svg" mdbDropdownToggle alt="Alternate Text"/>
      <div class="dropdown-menu dropdown-primary move-dropdown">
        <span *ngIf="userPermissions.Social.NetworkGroups.Delete" class="dropdown-item"
              (click)="deleteNetworkGroup(networkGroup.id)">Delete</span>
        <span *ngIf="userPermissions.Social.NetworkGroups.Edit" class="dropdown-item"
              (click)="editNetworkGroup(networkGroup.id)">Edit</span>
      </div>
    </div>
  </div>

  <div *ngIf="networkGroup?.networks.length > 0" class="w-100">
    <div *ngIf="!networksIsCollapsed" class="networks-list">
      <div class="py-2 networks"
           *ngFor="let network of networkGroup.networks">
        <div *ngIf="(network.type == networkType['Facebook Page'] || network.type == networkType.Twitter ||
             network.type == networkType.LinkedIn || network.type == networkType.Instagram)  &&
             userPermissions.Social.NetworkGroups.View">
          <div class="row m-0">
            <div class="col-2 p-0">
              <div class="float-left">
                <img class="img" width="24" height="24" src="{{network.pictureUrl}}" alt="Network"/>
              </div>
            </div>
            <div class="col-10 p-0">
              <div>
                {{network.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-wrapper" (click)="this.toggleNetworksIsCollapsed()">
      <img *ngIf="networksIsCollapsed" src="./assets/images/Icons/ArrowBottom.svg" alt="Alternate Text"/>
      <div class="w-100 networks d-flex justify-content-center align-items-center" *ngIf="!networksIsCollapsed">
        <img src="./assets/images/Icons/ArrowTop.svg" alt="Alternate Text"/>
      </div>
    </div>
  </div>
</div>

<ng-template #twitterLogo><img class="icons" src="./assets/images/Icons/TwitterLogo.svg" alt="Twitter" /></ng-template>
<ng-template #facebookLogo><img src="./assets/images/Icons/Meta.png" alt="Meta" /></ng-template>
<ng-template #instagramLogo><img src="./assets/images/Icons/Instagram.png" alt="Instagram" /></ng-template>
<ng-template #linkedinLogo><img class="icons" src="./assets/images/Icons/LinkedIn.png" alt="LinkedIn" /></ng-template>
