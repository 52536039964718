export class DataTableColumn  {

   prop: string;
   name: string;
   visible: boolean;
   width?: number;

   constructor(init: { prop: string,
                       name: string,
                       visible?: boolean,
                       width?: number } ) {
     this.prop = init.prop;
     this.name = init.name;
     this.visible = init.visible;
     this.width = init.width;
  }
 }
