import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';
import { FacebookPageService } from 'src/app/_services/social-media/networks/facebook-page.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { NotificationService } from 'src/app/_services/notification.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { NetworkTypeEnum } from 'src/app/_models/social-media/posts/network-type-enum';
import { Masonry } from 'ng-masonry-grid';
import { InstagramService } from 'src/app/_services/social-media/networks/instagram.service';
import { TwitterService } from 'src/app/_services/social-media/networks/twitter.service';
import { LinkedInService } from 'src/app/_services/social-media/networks/linkedIn.service';

@Component({
  selector: 'app-network-card',
  templateUrl: './network-card.component.html',
  styleUrls: ['./network-card.component.scss']
})
export class NetworkCardComponent implements OnInit {

  _userPermissions: PermissionsModel;
  _networkTypeTwitter: NetworkTypeEnum = NetworkTypeEnum.Twitter;
  _facebookPage: NetworkTypeEnum = NetworkTypeEnum["Facebook Page"];
  _facebookProfile: NetworkTypeEnum = NetworkTypeEnum.Facebook;
  _instagramPage: NetworkTypeEnum = NetworkTypeEnum.Instagram;
  _linkedInProfile: NetworkTypeEnum = NetworkTypeEnum.LinkedIn;
  _masonry: Masonry;
  _subNetworksIsCollapsed: boolean = true;

  @Input() network: NetworkDto;
  @Output() deleteNetworkEvent = new EventEmitter<number>();
  @Output() refreshNetworkEvent = new EventEmitter<number>();
  @Output() deletePageEvent = new EventEmitter();

  constructor(private pageService: FacebookPageService,
              private instagramService: InstagramService,
              private notificationService: NotificationService,
              private twitterService: TwitterService,
              private linkedInService: LinkedInService,
              private permissionService: PermissionsService) { }

  ngOnInit() {
    this.getPermissions();
  }

  // Get ng masonry grid instance first
  onNgMasonryInit($event: Masonry) {
    this._masonry = $event;
  }

  deletePage(network: NetworkDto) {
    console.log(network);

    if(network.type === NetworkTypeEnum['Facebook Page'])
    {
      this.pageService.delete(network.id).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.network.subNetworks = this.network.subNetworks.filter(x => x.id !== network.id);
          this.notificationService.showSuccess(executionResultDto.message);
          this.deletePageEvent.emit();
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
    else if(network.type === NetworkTypeEnum.Instagram)
    {
      this.instagramService.delete(network.id).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.network.subNetworks = this.network.subNetworks.filter(x => x.id !== network.id);
          this.notificationService.showSuccess(executionResultDto.message);
          this.deletePageEvent.emit();
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
    else if(network.type === NetworkTypeEnum.Twitter)
    {
      this.twitterService.delete(network.networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.network.subNetworks = this.network.subNetworks.filter(x => x.id !== network.id);
          this.notificationService.showSuccess(executionResultDto.message);
          this.deletePageEvent.emit();
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
    else if(network.type === NetworkTypeEnum.LinkedIn)
    {
      this.linkedInService.delete(network.networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.network.subNetworks = this.network.subNetworks.filter(x => x.id !== network.id);
          this.notificationService.showSuccess(executionResultDto.message);
          this.deletePageEvent.emit();
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
  }

  deleteNetwork(networkId: number) {
    this.deleteNetworkEvent.emit(networkId);
  }

  getPermissions() {
    this._userPermissions = this.permissionService.getPermissionsModel();
    this.permissionService.permissionsModel.subscribe((permissions: PermissionsModel) => {
      this._userPermissions = permissions;
    });
  }

  refreshPage(network: NetworkDto) {
    if (network.type === NetworkTypeEnum.LinkedIn) {
      this.linkedInService.refresh(network.networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
    else {
      this.pageService.refresh(network.networkId).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
  }

  refreshNetwork(networkId: number) {
    this.refreshNetworkEvent.emit(networkId);
  }

  reorderItems() {
    if (this._masonry) {
        this._masonry.reOrderItems();
    }
  }

  toggleSubNetworksIsCollapsed() {
    if (this._subNetworksIsCollapsed) {
      this._subNetworksIsCollapsed = false;
    }
    else {
      this._subNetworksIsCollapsed = true;
    }
  }

}
