export class MessageCodeRequestDto
{
    folderId: number;
    messageCodeId: number;    
    title: string;

    constructor(init: {
        folderId?: number;
        messageCodeId: number;        
        title: string;
        }) {

        this.folderId = init.folderId;
        this.messageCodeId = init.messageCodeId;
        this.title = init.title;
    }
}