import { Injectable } from "@angular/core";
import { CopyContactPlanContactPlansDto } from "./copy-contact-plan-contact-plans-dto";

@Injectable({
  providedIn: 'root'
})

export class CopyContactPlanContactPlansDtoAdapter{
  adapt(item: any): CopyContactPlanContactPlansDto {
    return new CopyContactPlanContactPlansDto(item)
  }
}
