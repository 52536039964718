import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { ClientsAdapter,  ClientsDto } from 'src/app/_models/admin/clients/clients-model'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientDto , ClientAdapter} from 'src/app/_models/admin/clients/client-model';
import { ClientAmazonSetting } from 'src/app/_models/admin/clients/client-amazon-settings-model';
import { ClientDomain } from 'src/app/_models/admin/clients/client-domains.model';
import { ClientInboxDto, ClientInboxAdapter } from 'src/app/_models/admin/clients/client-inboxes-model';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { LocalStorageService } from '../../system/localStorage/localStorage.service';
import { CreateETLDto } from 'src/app/_models/admin/clients/create-etl-dto';
import { ClientETLStatusChangeDto } from 'src/app/_models/admin/clients/client-etl-status-change-dto';
import { DatahubFieldsRequestDto } from 'src/app/_models/admin/clients/datahub-fields-request-dto';
import { EnquiryMaxUpdateDealerInformationDto } from 'src/app/_models/admin/clients/enquiry-max-update-dealer-information-dto';
import { HasClientSettingsAndDomainsResponseAdapter } from 'src/app/_models/admin/clients/HasClientSettingsAndDomainsResponse';


@Injectable({
  providedIn: 'root'
})
export class ClientManagementService {
  _baseUrl = environment.apiURL;
  _currentClient: ClientDto;

  constructor(private http: HttpClient,
              private clientsAdapter: ClientsAdapter,
              private clientAdapter: ClientAdapter,
              private clientInboxAdapter: ClientInboxAdapter,
              private executionResultAdapter: ExecutionResultAdapter,
              private localStorageService: LocalStorageService,
              private hasClientSettingsAndDomainsResponseAdapter: HasClientSettingsAndDomainsResponseAdapter) {

  }

  getAllClient() : Observable<ClientsDto[]> {
    return this.http.get(this._baseUrl + 'Client')
                    .pipe(map((data: any) => data.map((item: any) => this.clientsAdapter.adapt(item))));

  }

  getUserClients() {
    return this.http.get(this._baseUrl + 'User/UserClients/' + this.localStorageService.getUserId)
                    .pipe(map((data: any) =>
                      this.executionResultAdapter.adapt(data)));
  }

  getClient(clientId: number) : Observable<ClientDto> {
    return this.http.get(this._baseUrl + 'Client/'+clientId)
      .pipe(map((data: any) => this.clientAdapter.adapt(data)));
  }

  setCurrentClient(currentClient) {
    this._currentClient = currentClient;
  }

  getCurrentClient() {
    const temp = this._currentClient;
    return temp;
  }

  updateClient(client: ClientDto) {
    return this.http.post(this._baseUrl + 'Client', client);
  }

  deactivateClient(clientId: number) {
    return this.http.delete(this._baseUrl+'Client/'+clientId);
  }

  createUpdateAmazonSettings(clientAmazonSetting: ClientAmazonSetting) {
    return this.http.post(this._baseUrl + 'Client/amazonSettings',
                          clientAmazonSetting);
  }

  deleteAmazonSettings(clientAmazonSetting: ClientAmazonSetting) {
    return this.http.post(this._baseUrl + 'Client/amazonSettings/delete',
                          clientAmazonSetting);
  }

  addDomain(domainToAdd: ClientDomain) {
    return this.http.post(this._baseUrl + 'Client/domains/' +
                          this._currentClient.ClientID, domainToAdd)
                    .pipe(map((data: any) =>
                          this.executionResultAdapter.adapt(data)));
  }

  deleteDomain(domainToDelete: ClientDomain) {
    return this.http.post(this._baseUrl + 'Client/domains/delete/' +
                          this._currentClient.ClientID, domainToDelete).pipe(map((data: any) =>
                          this.executionResultAdapter.adapt(data)));
  }

  getAvailableInboxes(): Observable<ClientInboxDto[]> {
    return this.http.get(this._baseUrl + 'Client/inboxes').pipe(map((data: any) =>
    data.map((item: any) => this.clientInboxAdapter.adapt(item))));
  }

  attachInbox(inboxToAttach: ClientInboxDto): Observable<ExecutionResultDto> {
    return this.http.post(this._baseUrl + 'Client/inboxes/add', inboxToAttach).pipe(map((data: any) =>
                          this.executionResultAdapter.adapt(data)));
  }

  getAttachedInboxes(): Observable<ClientInboxDto[]> {
    return this.http.get(this._baseUrl + 'Client/inboxes/' + this._currentClient.ClientID).pipe(map((data: any) =>
                          data.map((item: any) => this.clientInboxAdapter.adapt(item))));
  }

  getAllETLs(clientID: number): Observable<ExecutionResultDto> {
    return this.http.get(this._baseUrl + 'ClientETL/' + clientID).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getAvailableDMSs() {
    return this.http.get(this._baseUrl + 'ClientETL/getAvailableETLs').pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  getDatahubFields(datahubFieldsRequestDto: DatahubFieldsRequestDto) {
    return this.http.get(this._baseUrl + `Client/GetDatahubFields?clientId=${datahubFieldsRequestDto.clientId}&listId=${datahubFieldsRequestDto.listId}&type=${datahubFieldsRequestDto.type}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  createETL(newETL: CreateETLDto) {
    return this.http.post(this._baseUrl + 'ClientETL/createETL', newETL).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  changeETLStatus(changeDto: ClientETLStatusChangeDto) {
    return this.http.post(this._baseUrl + 'ClientETL/changeClientETLStatus', changeDto).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  enquiryMaxGetDealerInformation(clientId: number) {    
    return this.http.get(this._baseUrl + `ClientETL/EnquiryMaxGetDealerInformation/${clientId}`).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  enquiryMaxUpdateDealerInformation(enquiryMaxUpdateDealerInformationDto: EnquiryMaxUpdateDealerInformationDto) {
    return this.http.post(this._baseUrl + 'ClientETL/EnquiryMaxUpdateDealerInformation', enquiryMaxUpdateDealerInformationDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getClientAPIs(clientId: string): Observable<ExecutionResultDto> {    
    return this.http
    .get(this._baseUrl + `Client/GetClientApis/${clientId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  hasClientSettingsAndDomains() {
    return this.http.get(this._baseUrl + 'Client/HasClientSettingsAndDomains').pipe(map((data: any) =>
    this.hasClientSettingsAndDomainsResponseAdapter.adapt(data)));
  }
}

