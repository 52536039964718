import { Injectable } from '@angular/core';
import { MessageCopyMoveDto } from './message-copy-move-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageCopyMoveDtoAdapter {
  public adapt(item: any): MessageCopyMoveDto {
    return new MessageCopyMoveDto(item);
  }
}
