import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
} from '@angular/animations';

const optional = { optional: true };

var onPageSlideToTheRight = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: -24,
      right: 0,
      width: '100%',
      overflow: 'hidden'
    })
  ], optional),
  query(':enter', [
    style({ right: '-100%',  })
  ]),
  group([
    query(':leave', [
      animate('900ms ease', style({ right: '100%', overflow: 'hidden'  }))
    ], optional),
    query(':enter', [
      animate('900ms ease', style({ right: '0%', overflow: 'hidden' }))
    ])
  ]),
];
var onPageSlideToTheLeft = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: -24,
      left: 0,
      width: '100%',
      overflow: 'hidden'
    })
  ], optional),
  query(':enter', [
    style({ left: '-100%',  })
  ]),
  group([
    query(':leave', [
      animate('900ms ease', style({ left: '100%', overflow: 'hidden' }))
    ], optional),
    query(':enter', [
      animate('900ms ease', style({ left: '0%', overflow: 'hidden' }))
    ])
  ]),
]


var fullPageSlideToTheRight = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      right: 0,
      width: '100%',
      overflow: 'hidden'
    })
  ], optional),
  query(':enter', [
    style({ right: '-100%',  })
  ]),
  group([
    query(':leave', [
      animate('900ms ease', style({ right: '100%', overflow: 'hidden'  }))
    ], optional),
    query(':enter', [
      animate('900ms ease', style({ right: '0%', overflow: 'hidden' }))
    ])
  ]),
];
var fullPageSlideToTheLeft = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      left: 0,
      width: '100%',
      overflow: 'hidden'
    })
  ], optional),
  query(':enter', [
    style({ left: '-100%',  })
  ]),
  group([
    query(':leave', [
      animate('900ms ease', style({ left: '100%', overflow: 'hidden' }))
    ], optional),
    query(':enter', [
      animate('900ms ease', style({ left: '0%', overflow: 'hidden' }))
    ])
  ]),
]

export const onPageSlide =
trigger('onPageAnimations', [
  transition('isFarRight => *', onPageSlideToTheLeft),
  transition('isFarLeft => *', onPageSlideToTheRight),
  transition('isFarRight => isRight', onPageSlideToTheLeft),
  transition('isFarLeft => isLeft', onPageSlideToTheRight),
  transition('* => isLeft', onPageSlideToTheLeft),
  transition('* => isFarLeft', onPageSlideToTheLeft),
  transition('* => isRight', onPageSlideToTheRight),
  transition('* => isFarRight', onPageSlideToTheRight),
  
]);

export const fullPageSlide =
trigger('fullPageAnimations', [
  transition('* => isLeft', fullPageSlideToTheLeft ),
  transition('* => isRight', fullPageSlideToTheRight ),
  transition('isRight => *', fullPageSlideToTheLeft ),
  transition('isLeft => *', fullPageSlideToTheRight ),
]);
