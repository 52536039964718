
export class MessageLinkDto {
  
  public linkId: number;
  public linkTitle: string;
  public url: string;  

  constructor(init: any) {
    this.linkId = init.LinkID;
    this.linkTitle = init.LinkTitle;
    this.url = init.Url;
  }
}





    