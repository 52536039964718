export class SmsMessageDtoRequest {
    id: number;
    folderId: number;
    name: string;
    message: string;
    clientInboxId: number;

    constructor(init: { id: number, folderId: number, name: string, message: string, clientInboxId: number }) {
        this.id = init.id;
        this.folderId = init.folderId;
        this.name = init.name;
        this.message = init.message;
        this.clientInboxId = init.clientInboxId;
    }
}
