<div *ngFor="let post of metrics; index as i" class="row p-0 content">
  <div *ngIf="!isReport" class="col-md-2 col-lg-2 d-flex justify-content-start my-1 align-items-center">
    <i [ngClass]="'fab mr-2 fa-' + getIconName(post) + ' social-icon ' + getIconName(post)"></i>
    <div class="network-name">{{ post.username }}</div>
  </div>

  <div class="d-flex align-items-center my-1"
       [ngClass]="{
         'col-md-8 col-lg-8 placeholder justify-content-center': !isReport,
         'col-md-12 col-lg-12 p-0': isReport
       }"
  >
    <ng-container *ngIf="isReport">
      <div class="col-md-5ths">        
        <span class="performance-type">Impressions</span>
        <div>
          <span *ngIf="showImpressions" class="mr-2 performance-number">{{ post.firstColumn }}</span>
          <span *ngIf="!showImpressions" class="mr-2 performance-number">N/A</span>
        </div>
      </div>
      <div class="col-md-5ths">
        <span class="performance-type">
          {{ postNetwork.viewVideos || postNetwork.reach || postNetwork.clicks || postNetwork.linkclicks || postNetwork.twitterReactions}}
        </span>
        <div>
          <span class="mr-2 performance-number">{{ post.secondColumn }}</span>
        </div>
      </div>
      <div class="col-md-5ths">
        <span class="performance-type">{{ postNetwork.likes || postNetwork.clicks || postNetwork.twitterLikeCount || 'Reach' }}</span>
        <div>
          <span class="mr-2 performance-number">{{ post.thirdColumn }}</span>
        </div>
      </div>
      <div class="col-md-5ths">
        <span class="performance-type">{{ postNetwork.reactions || postNetwork.comments || postNetwork.twitterReplies || 'Likes' }}</span>
        <div>
          <span class="mr-2 performance-number">{{ post.fourthColumn }}</span>
        </div>
      </div>
      <div class="col-md-5ths">
        <span class="performance-type">{{  postNetwork.replies ||postNetwork.saved || postNetwork.twitterProfileViews ||  'Comments' }}</span>
        <div>
          <span class="mr-2 performance-number">{{ post.fifthColumn }}</span>
        </div>
      </div>
      <div class="col-md-5ths" 
      *ngIf="(selectedNetworkType == networkType.Instagram && post.sixColumn > 0)
        || (postNetwork.lastColumn && selectedNetworkType!=networkType.Facebook && selectedNetworkType != networkType.Instagram)">
        <span class="performance-type">{{ postNetwork.lastColumn }}</span>
        <div>
          <span class="mr-2 performance-number">{{ post.sixColumn }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!isReport && (metrics.length - 1 === i)"
       class="col-md-2 col-lg-2 pr-0 d-flex my-1 pr-3 align-items-center justify-content-end">
    <ng-container *ngIf="post.status !== postStatus?.Success">
      <div *ngIf="socialPostsPermission.Edit && (post.status !== postStatus?.Success)"
           class="p-0 icon-color">
        <a (click)="openEditPostModal(post)" routerLink="'single-network-report'">
          <mdb-icon fas icon="edit" class="options-icon"></mdb-icon>
        </a>
      </div>
      <div *ngIf="socialPostsPermission.Pause && (post.status !== postStatus.Paused)"
           class="ml-3 p-0 icon-color"
           [disabled]="postPausing">
        <a (click)="pausePost(post.postId, post.networkType)">
          <mdb-icon fas icon="pause" class="options-icon"></mdb-icon>
        </a>
      </div>
      <div *ngIf="socialPostsPermission.Delete && (post.status !== postStatus?.Success)"
           class="ml-3 p-0 icon-color"
           [disabled]="postDeleting">
        <a (click)="confirmDelete.show(); deletePostId = post.postId; deletePostNetworkType = post.networkType;">
          <mdb-icon fas icon="trash" class="options-icon"></mdb-icon>
        </a>
      </div>
      <div *ngIf="(socialPostsPermission.Approve && socialPostsPermission.Send) && (post.status === postStatus?.Draft)"
           class="ml-3 p-0 icon-color"
           [disabled]="postApproving">
        <a (click)="approvePost(post.postId, post.networkType)">
          <mdb-icon fas icon="check" class="options-icon"></mdb-icon>
        </a>
      </div>
      <div *ngIf="socialPostsPermission.Send && (post.status === postStatus?.Paused)"
           class="ml-3 p-0 icon-color"
           [disabled]="postPausing">
        <a (click)="resumePost(post.postId, post.networkType)">
          <mdb-icon fas icon="check" class="options-icon"></mdb-icon>
        </a>
      </div>
    </ng-container>
  </div>
</div>

<div mdbModal #confirmDelete="mdb-modal" class="modal fade delete-modal" id="confirmDelete" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog delete-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm Action</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="confirmDelete.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3 modal-text">
          Are you sure you want to delete this post?
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn-cancel-modal" outline="true" data-dismiss="modal" (click)="confirmDelete.hide()">
          Cancel
        </button>

        <button type="button" class="btn-save-modal" (click)="deletePost(confirmDelete)">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
