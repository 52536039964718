import { Component, Injector, OnInit } from '@angular/core';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { ContactPlanFolderService } from 'src/app/_services/messaging/automation/contact-plan-folder.service';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { ContactPlanService } from 'src/app/_services/messaging/automation/contact-plan.service';
import { ContactPlanGridRecordDto } from 'src/app/_models/messaging/automation/contact-plans/contact-plan-grid-record-dto';
import { ContactPlanGridRecordDtoAdapter } from 'src/app/_models/messaging/automation/contact-plans/contact-plan-grid-record-dto-adapter';
import { PermissionsPageTypes } from '../../../../_models/system/permissions/permissions-page-types-model';
import {
  CreateAutomationModalComponent
} from '../../../shared/modals/create-automation/create-automation-modal.component';
import {
  ActivateContactPlanModalComponent
} from '../../../shared/modals/activate-contact-plan/activate-contact-plan-modal.component';
import { TableActionType, TableName } from '../../../shared/constants/table-constants';
import { MessagingAbstract } from '../../../../_models/messaging/messaging-abstract';
import { CopyMoveModalComponent } from '../../../shared/modals/copy-move/copy-move-modal.component';
import {
  CopyContactPlanContactPlansDtoAdapter
} from '../../../../_models/messaging/automation/contact-plans/copy-contact-plan-contact-plans-dto.adapter';

@Component({
  selector: 'app-contact-plans',
  templateUrl: './contact-plans.component.html'
})

export class ContactPlansComponent extends MessagingAbstract implements OnInit {
  displayedColumns = [
    { name: 'checkBox', displayName: '' },
    { name: 'status', displayName: 'Status' },
    { name: 'title', displayName: 'Title' },
    { name: 'folderName', displayName: 'Folder' },
    { name: 'createDate', displayName: 'Creation Date' },
    { name: 'createdBy', displayName: 'Created By' },
    { name: 'changeDate', displayName: 'Last Modified Date' },
    { name: 'changedBy', displayName: 'Last Modified By' },
    { name: 'actions', displayName: '' }
  ];

  actionList = ['copy'];
  editPath = 'contact-plans/edit/';
  userPermissionsAutomationContactPlans: PermissionsPageTypes;
  folderSelectListOptions: SelectListOption[] = [];

  constructor(
    private contactPlanGridRecordDtoAdapter: ContactPlanGridRecordDtoAdapter,
    private contactPlanService: ContactPlanService,
    public contactPlanFolderService: ContactPlanFolderService,
    private copyContactPlanContactPlansDtoAdapter: CopyContactPlanContactPlansDtoAdapter,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'contactPlan';
    this.init();
  }

  ngOnInit() {
    this.userPermissionsAutomationContactPlans = this.permissions.AutomationContactPlans;
  }

  activateContactPlan(contactPlan: ContactPlanGridRecordDto): void {
    const dialog = this.matDialog.open(ActivateContactPlanModalComponent, { data: contactPlan });

    dialog.afterClosed().subscribe(({ isActivated }) => {
      contactPlan.status =  isActivated ? contactPlan.status : !contactPlan.status;
    });
  }

  getContactPlans(data): void {
    this.loadingSpinnerService.loading();
    const pagerDtoRequest = new PagerDtoRequest({
      page: data.currentPage,
      pageSize: data.pageLimit,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection
    });

    this.contactPlanService.getPlans(pagerDtoRequest, this.selectedFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.data = executionResultDto.data.ContactPlans.map((contactPlan) => this.contactPlanGridRecordDtoAdapter.adapt(contactPlan));
        this.length = executionResultDto.data.Total;
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();
    }, () => {
      this.loadingSpinnerService.stopLoading();
    });
  }

  showCreateContactPlanModal() {
    const folders = this.messagingHeaderService.folders.filter(x => x.value > 0);
    const dialog = this.matDialog.open(CreateAutomationModalComponent, {
      width: '500px',
      data: {
        data: this.data,
        title: TableName[this.tableKey],
        tableKey: this.tableKey,
        folders,
        selectedFolder: this.selectedFolderId
      }
    });

    dialog.afterClosed().subscribe(({ isNeedToUpdate, folderId }) => {
      if (isNeedToUpdate) {
        this.messagingHeaderService.setFolder(folderId);
        this.tableService.requestTableData();
      }
    });
  }

  actionHandler({ selectedRows, action, extraOptions }): void {
    switch (TableActionType[action]) {
      case TableActionType.copy:
        const data = {
          modal: CopyMoveModalComponent,
          isCopy: true,
          dataService: this.contactPlanService,
          tableKey: this.tableKey,
          selectedRows,
        };

        this.copyMove(data);
        break;
      case TableActionType.delete:
        this.deleteRestoreRow(selectedRows, extraOptions);
        break;
      case TableActionType.edit:
        this.editRow(selectedRows, this.editPath);
        break;
      case TableActionType.switchStatus:
        this.activateContactPlan(selectedRows);
        break;
    }
  }
}
