<div class="single-network-report">
  <form [formGroup]="singleNetworkReportForm" autocomplete="off">
    <div class="title-box ml-3">
      <span class="header-section">{{ pageTitle }}</span>
    </div>

    <div class="report-selection">
      <div class="container-fluid">
        <error-alert *ngIf="noDataAvailableError"
                     #errorAlert
                     [errorMessage]="errorMessages.ReportingErrorMessages.NoDataAvailableError">
        </error-alert>
        <div class="row">
          <div class="col-md-12">
            <span class="title-sub-section">Report Selection</span>
            <hr class="mt-1 mb-2">
          </div>
        </div>
        <div class="row mt-0">
          <div class="col-12 col-md-6 col-lg-2">
            <label class="input-label ml-0">Select Channel</label>
            <mdb-select [options]="channelDropdown" [filterEnabled]="true" [visibleOptions]="10"
              (selected)="channelSelected($event)" formControlName="selectedChannel"
              class="custom-drop-down form-control select-input select-folder" placeholder="&lt;Select Channel&gt;">
            </mdb-select>
          </div>
          <div class="col-12 col-md-6 col-lg-2">
            <label class="input-label ml-0">Select Network</label>
            <mdb-select [options]="networksFilteredDropdown" [filterEnabled]="true" [visibleOptions]="10"
              class="custom-drop-down form-control select-input select-folder" formControlName="selectedNetwork"
              (selected)="networkSelected($event)" placeholder="&lt;Select Network&gt;">
            </mdb-select>
          </div>
          <div class="col-12 col-md-6 col-lg-2 date">
            <label class="input-label ml-0">Date From</label>
            <input autocomplete="off" name="dual-date-time-from" id="dual-date-time-from-input"
              class="form-control align-bottom" type="datetime-local" formControlName="dateTimeFrom"
              [placeholder]="emptyState" (click)="this.pickerIsOpen(true)" (keydown)="this.pickerIsOpen(true)"
              (blur)="this.pickerIsOpen(false)"
              [ngClass]="{'is-invalid': singleNetworkReportForm.get('dateTimeFrom').touched
                  && (singleNetworkReportForm.get('dateTimeFrom').errors || singleNetworkReportForm.hasError('dateValidationError'))}" mdbInput>
          </div>
          <div class="col-12 col-md-6 col-lg-2 date">
            <label class="input-label ml-0">Date To</label>
            <input autocomplete="off" name="dual-date-time-to" id="dual-date-time-to-input"
              class="form-control align-bottom" type="datetime-local" formControlName="dateTimeTo"
              [placeholder]="emptyState" (click)="this.pickerIsOpen(true)" (keydown)="this.pickerIsOpen(true)"
              (blur)="this.pickerIsOpen(false)"
              [ngClass]="{'is-invalid': singleNetworkReportForm.get('dateTimeTo').touched
                  && (singleNetworkReportForm.get('dateTimeTo').errors || singleNetworkReportForm.hasError('dateValidationError'))}" mdbInput>
            <div class="invalid-feedback text-left" *ngIf="!singleNetworkReportForm.get('dateTimeTo').hasError('required')
                  && singleNetworkReportForm.hasError('dateValidationError')">
              To Date must be valid and greater than From Date!
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4 mt-4 d-flex justify-content-end align-items-end">
            <button type="button" class="btn-search"
                    [disabled]="!singleNetworkReportForm.valid || !isSearchEnabled"
                    (click)="searchInsights()">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="report-selection" *ngIf="singleNetworkReportSearched">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <span class="title-sub-section mr-3">Performance</span>
            <button class="nav-pill mr-3" (click)="currentTab = 'Overview'"
              [ngClass]="{'nav-pill-clicked': currentTab == 'Overview'}">Overview</button>
            <button class="nav-pill" (click)="currentTab = 'Posts'"
              [ngClass]="{'nav-pill-clicked': currentTab == 'Posts'}">Posts</button>
          </div>
          <div
            class="col-md-4 col-lg-6 text-right d-flex align-items-center justify-content-sm-start justify-content-md-end">
            <a placement="left" mdbPopover="These metrics are measured for the lifetime of the posts that were created between the two dates you supplied when creating the report." triggers="hover" container="body">
              <i class="fas fa-question-circle mr-1 align-middle"></i>
            </a> How is this calculated?
          </div>
        </div>

        <hr class="mt-2 mb-2">

        <div class="mt-2" *ngIf="currentTab == 'Overview'">
            <div class="row">
              <div class="col-sm-6 col-md-4">
                <span class="performance-type">Total Posts</span>
                <div>
                <span class="mr-2 performance-number">
                  {{ this.totalCount || '--' }}
                </span>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <span class="performance-type">Total Followers</span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalFollowers}}</span>
                </div>
              </div>          

              <div class="col-sm-6 col-md-4">
            <span class="performance-type">
              Eng. Rate
              <a placement="right"
                 mdbPopover="Engagement Rate for Meta is the total reactions plus the post clicks and comments divided by the number of people your page reached."
                 triggers="hover"
                 container="body">
                <mdb-icon fas icon="info-circle"></mdb-icon>
              </a>
            </span>
                <div>
              <span class="mr-2 performance-number">
                {{ metrics?.EngagementRate ? metrics?.EngagementRate + '%' : '--' }}
              </span>
                </div>
              </div>
            </div>
        </div>

        <div class="mt-2" *ngIf="currentTab == 'Posts'">
          <!-- Facebook Page Metrics -->
          <ng-container
            *ngIf="selectedNetworkType == networkType['Facebook Page']  && (metrics | keyvalue)?.length">
            <div class="row">
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">
                  Total Impressions
                  <a placement="right"
                  mdbPopover="The number of times your Page's posts entered a person's screen through unpaid distribution. (Total Count)"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalImpressions}}</span>
                </div>
              </div>
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">
                  Total Reach
                  <a placement="right"
                  mdbPopover="The number of people who had your Page's post enter their screen through unpaid distribution. (Unique Users)"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalReach}}</span>
                </div>
              </div>
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">
                  Total Post Clicks
                  <a placement="right"
                  mdbPopover="The number of clicks anywhere in your post on News Feed from the user that matched the audience targeting on it. (Total Count)"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalPostClicks}}</span>
                </div>
              </div>     
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">
                  Total Reactions
                  <a placement="right"
                  mdbPopover="Total post reactions by all types"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">
                    {{ metrics?.TotalReactions || metrics?.TotalReTweets || metrics.TotalLikes || metrics?.TotalReach }}
                  </span>
                </div>
              </div>                       
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">
                  Total Comments
                  <a placement="right"
                  mdbPopover="The number of comments on your post"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalComments}}</span>
                </div>
              </div>
            </div>           
          </ng-container>

          <!-- Twitter Metrics -->
          <ng-container
            *ngIf="selectedNetworkType == networkType.Twitter && (metrics | keyvalue)?.length">
            <div class="row">
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">Total Impressions</span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalImpressions}}</span>
                </div>
              </div>
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">Total Retweets</span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalReTweets}}</span>
                </div>
              </div>
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">Total Likes</span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalFavourites}}</span>
                </div>
              </div> 
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">Total Replies</span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalReplies}}</span>
                </div>
              </div>
              <div class="col-sm-5ths col-md-5ths col-lg-5ths">
                <span class="performance-type">Total Profile Views</span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalProfileViews}}</span>
                </div>
              </div>
            </div>
              
           
          </ng-container>

          <!-- Instagram Metrics -->
          <ng-container
            *ngIf="selectedNetworkType == networkType.Instagram && (metrics | keyvalue)?.length">
            <div class="row">
              <div [ngClass]="metrics.TotalVideoViews>0 ? 'col-sm-2 col-md-2 col-lg-2' : 'col-sm-5ths col-md-5ths col-lg-5ths'">
                <span class="performance-type">
                  Total Impressions
                  <a placement="right"
                  mdbPopover="Total number of times the media object has been seen"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics.TotalImpressions}}</span>
                </div>
              </div>
              <div [ngClass]="metrics.TotalVideoViews>0 ? 'col-sm-2 col-md-2 col-lg-2' : 'col-sm-5ths col-md-5ths col-lg-5ths'">
                <span class="performance-type">
                  Total Reach
                  <a placement="right"
                  mdbPopover="Total number of unique accounts that have seen the media object"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics.TotalReach}}</span>
                </div>
              </div>
              <div [ngClass]="metrics.TotalVideoViews>0 ? 'col-sm-2 col-md-2 col-lg-2' : 'col-sm-5ths col-md-5ths col-lg-5ths'">
                <span class="performance-type">
                  Total Likes
                  <a placement="right"
                  mdbPopover="The number of likes on your post"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics.TotalLikes}}</span>
                </div>
              </div>
              <div [ngClass]="metrics.TotalVideoViews>0 ? 'col-sm-2 col-md-2 col-lg-2' : 'col-sm-5ths col-md-5ths col-lg-5ths'">
                <span class="performance-type">
                  Total Comments
                  <a placement="right"
                  mdbPopover="The number of comments on your post"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics.TotalComments}}</span>
                </div>
              </div>
              <div [ngClass]="metrics.TotalVideoViews>0 ? 'col-sm-2 col-md-2 col-lg-2' : 'col-sm-5ths col-md-5ths col-lg-5ths'">
                <span class="performance-type">
                  Total Saved
                  <a placement="right"
                  mdbPopover="Total number of unique accounts that have saved the media object"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics.TotalSaved}}</span>
                </div>
              </div>
             <div [ngClass]="metrics.TotalVideoViews>0 ? 'col-sm-2 col-md-2 col-lg-2' : 'col-sm-5ths col-md-5ths col-lg-5ths'" *ngIf="metrics.TotalVideoViews>0">
                <span class="performance-type">
                  Total Video Views
                  <a placement="right"
                  mdbPopover="Total number of times the video has been seen"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics.TotalVideoViews}}</span>
                </div>
              </div>
            </div>           
          </ng-container>

          <!-- LinkedIn Metrics -->
          <ng-container
            *ngIf="selectedNetworkType == networkType.LinkedIn && (metrics | keyvalue)?.length">
            <div class="row">
              <div class="col-sm-2 col-md-2 col-lg-2">
                <span class="performance-type">
                  Total Impressions
                  <a placement="right"
                  mdbPopover="Total number of times people saw your posts"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalImpressions}}</span>
                </div>
              </div>
              <div class="col-sm-2 col-md-2 col-lg-2">
                <span class="performance-type">
                  Total Reach
                  <a placement="right"
                  mdbPopover="Total number of views received by your LinkedIn Page"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalReach}}</span>
                </div>
              </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                <span class="performance-type">
                  Total Clicks
                  <a placement="right"
                  mdbPopover="Total number of clicks your posts received. This includes the number of clicks on your content, company name, or logo by a (signed in) member. It doesn't include interactions (shares, reactions, and comments)"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalClicks}}</span>
                </div>
              </div>
              <div class="col-sm-2 col-md-2 col-lg-2">
                <span class="performance-type">
                  Total Likes
                  <a placement="right"
                  mdbPopover="Total number of likes your posts received. You could have a negative number of likes for a day if there are more people who removed their likes than people who liked your posts"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalLikes}}</span>
                </div>
              </div>
              <div class="col-sm-2 col-md-2 col-lg-2">
                <span class="performance-type">
                  Total Comments
                  <a placement="right"
                  mdbPopover="Total number of comments on your posts. You could have a negative number of comments for a day if there are more people who removed their comments than people who commented your posts"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalComments}}</span>
                </div>
              </div>
              <div class="col-sm-2 col-md-2 col-lg-2">
                <span class="performance-type">
                  Total Shares
                  <a placement="right"
                  mdbPopover="The number of times your posts were shared"
                  triggers="hover"
                  container="body">
                 <mdb-icon fas icon="info-circle"></mdb-icon>
                 </a>
                </span>
                <div>
                  <span class="mr-2 performance-number">{{metrics?.TotalShares}}</span>
                </div>
              </div>
            </div>            
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Overview -->
    <div class="report-selection" *ngIf="singleNetworkReportSearched && currentTab == 'Overview'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <span class="title-sub-section mr-3">Post Counts</span>
          </div>
          <div class="col-md-6 text-right">
            <button [disabled]="!postCountReportHasData" mdbBtn size="lg" type="button"
              class="header-button table-button no-box-shadow" (click)="exportPostCountTable()">
              <mdb-icon fas size="lg" class="blue-icon" icon="file-download"></mdb-icon>
            </button>
          </div>
        </div>
        <hr class="mt-1 mb-2">
        <div class="row">
          <!-- Table -->
          <div *ngIf="postCountReportHasData" class="mat-table-container col-md-12">
            <ngx-datatable [columns]="postCountReportColumns" [rows]="postCountReportRows" class="post-count-table"
              [headerHeight]="50" [footerHeight]="0" rowHeight="40" [columnMode]="'force'" [reorderable]="'true'">
              <ngx-datatable-column *ngFor="let col of postCountReportColumns" [prop]="col.prop" [name]="col.name">
              </ngx-datatable-column>
            </ngx-datatable>
            <mat-paginator [hidden]="!postCountReportHasData" [length]="postCountPageCount"
              [pageSize]="postCountPageLimit" [pageSizeOptions]="postCountPageSizeOptions"
              [pageIndex]="postCountPageOffSet" (page)="postCountPaginateTable($event)" class="mat-paginator">
            </mat-paginator>
          </div>
          <div *ngIf="!postCountReportHasData" class="col-md-12">
            <span class="label-no-data">No data to display.</span>
          </div>
        </div>
      </div>
    </div>

    <app-posts-view
      *ngIf="currentTab === 'Posts' && posts.length"
      [selectedNetworkType]="selectedNetworkType"
      [searched]="searched"
      [itemsCount]="totalCount"
      [posts]="posts"
      [selectedNetworkName]="selectedNetworkName"
      [headLabel]="headLabel"
      [isReport]="true">
    </app-posts-view>

    <div class="report-selection" *ngIf="currentTab === 'Posts' && !posts.length">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-4 col-md-3 col-lg-2">
            <span class="title-sub-section mr-3">Post Insights</span>
            <hr class="mt-1 mb-2">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-md-3 col-lg-2">
            <span>No Posts available for this network</span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
