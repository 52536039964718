export class CopyMessageCodeStepRequestDto {
    messageCodeStepId: number;
    criteriaCode: string;

    constructor(init: { messageCodeStepId: number, criteriaCode: string }) {

        this.messageCodeStepId = init.messageCodeStepId;
        this.criteriaCode = init.criteriaCode;

    }
}