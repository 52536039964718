import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';

@Injectable()

export class LightListModelResolver implements Resolve<LightListModel> {
  constructor(private listsService: ListsService,
              private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<LightListModel> {
    return this.listsService.getAllLightLists(0).pipe(
      catchError(() => {
        this.router.navigate(['/contact-plans']);
        return of(null);
      })
    );
  }
}
