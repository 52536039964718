import { Injectable } from '@angular/core';
import { NetworkTypeEnum } from './network-type-enum';

export class ShortLinkModel {
  public LongUrl: string;
  public NetworkTypes: Array<number>;
  public RemoveUTM: boolean;
  public CampaignName: string;
  public CampaignContent: string;

  constructor(init: any) {
    this.LongUrl = init.LongUrl;
    this.NetworkTypes = init.NetworkTypes;
    this.RemoveUTM = init.RemoveUTM;
    this.CampaignName = init.CampaignName;
    this.CampaignContent = init.CampaignContent;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ShortLinkModelAdapter {
  adapt(item: any): ShortLinkModel {
    return new ShortLinkModel(item);
  }
}
