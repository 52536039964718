import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientAmazonSettingAdapter, ClientAmazonSetting } from 'src/app/_models/admin/clients/client-amazon-settings-model';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { Router } from '@angular/router';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-amazon-settings',
  templateUrl: './amazon-settings.component.html',
  styleUrls: ['./amazon-settings.component.scss']
})
export class AmazonSettingsComponent implements OnInit {

  currentClient: ClientDto;
  _amazonSettings: ClientAmazonSetting;
  _amazonSettingsForm: UntypedFormGroup;
  _saving: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    public clientManagementService: ClientManagementService,
    private route: Router,
    private clientAmazonSettingAdapter: ClientAmazonSettingAdapter,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.currentClient = this.clientManagementService.getCurrentClient();

    if (this.currentClient === undefined) {
      this.route.navigateByUrl('/client-management');
    }

    this.createAmazonSettingsForm();

    if (this.currentClient.AmazonSettings != null) {
      this.populateAmazonSettings();
    }
  }

  createAmazonSettingsForm() {
    this._amazonSettingsForm = this.fb.group({
      accessKeyEmail: ['', Validators.required],
      secretKeyEmail: ['', Validators.required],
      regionEmail: ['', Validators.required],
      configSettings: ['', Validators.required],
      accessKeyQueue: ['', Validators.required],
      secretKeyQueue: ['', Validators.required],
      regionQueue: ['', Validators.required],
      queueName: ['', Validators.required]
    });
  }

  submitAmazonSettings() {
    this._saving = true;
    this._amazonSettings = this.clientAmazonSettingAdapter.adapt({
      ClientID: this.currentClient.ClientID,
      SesAccessKey: this._amazonSettingsForm.value.accessKeyEmail,
      SesSecretKey: this._amazonSettingsForm.value.secretKeyEmail,
      SesRegion:    this._amazonSettingsForm.value.regionEmail,
      SesConfigSet: this._amazonSettingsForm.value.configSettings,
      SqsAccessKey: this._amazonSettingsForm.value.accessKeyQueue,
      SqsSecretKey: this._amazonSettingsForm.value.secretKeyQueue,
      SqsRegion:    this._amazonSettingsForm.value.regionQueue,
      SqsQueueName: this._amazonSettingsForm.value.queueName,
    });
    this.currentClient.AmazonSettings = this._amazonSettings;
    this.clientManagementService.createUpdateAmazonSettings(this.currentClient.AmazonSettings).subscribe(data => {
      const response: any = data;
      this.notificationService.showSuccess(response.Message);
      this._saving = false;
    }, error => {
      this._saving = false;
    });
  }

  populateAmazonSettings() {
    this._amazonSettingsForm.get('accessKeyEmail').setValue(this.currentClient.AmazonSettings.SesAccessKey);
    this._amazonSettingsForm.get('secretKeyEmail').setValue(this.currentClient.AmazonSettings.SesSecretKey);
    this._amazonSettingsForm.get('regionEmail').setValue(this.currentClient.AmazonSettings.SesRegion);
    this._amazonSettingsForm.get('configSettings').setValue(this.currentClient.AmazonSettings.SesConfigSet);
    this._amazonSettingsForm.get('accessKeyQueue').setValue(this.currentClient.AmazonSettings.SqsAccessKey);
    this._amazonSettingsForm.get('secretKeyQueue').setValue(this.currentClient.AmazonSettings.SqsSecretKey);
    this._amazonSettingsForm.get('regionQueue').setValue(this.currentClient.AmazonSettings.SqsRegion);
    this._amazonSettingsForm.get('queueName').setValue(this.currentClient.AmazonSettings.SqsQueueName);
  }

  deleteAmazonSettings() {
    this.clientManagementService.deleteAmazonSettings(this.currentClient.AmazonSettings).subscribe(data => {
      const a: any = data;
      this._amazonSettingsForm.reset();
      this.notificationService.showSuccess(a.Message);
    });
  }
}
