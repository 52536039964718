import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
@Injectable()

export class TriggerResolver implements Resolve<any> {
  constructor(private triggersService: TriggersService,
              private contactService: ContactService, ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const triggerData = await
    this.triggersService.getTrigger(route.params.triggerID).toPromise();
    if (triggerData.data.Parameters.UpdateContact.Changes.length !== 0) {
      const contactDtoData = await this.contactService.getContactFields(triggerData.data.Parameters.TriggerTarget.ListId).toPromise();
      return {triggerData, contactDtoData};
    }    
    return triggerData;
  }
}
