<div class="message-page">
  <div class="title-box">
    <span class="header-section">Welcome to VoiceBox</span>
  </div>
  <br>
  <mdb-card>
    <mdb-card-body class="pt-3 pb-0">      
      <span class="title-sub-section">Usage Stats (Last 30 Days)</span>
      <hr class="mt-1 mb-0">
      <div class="row">
        <div class="col">
          <app-stats-card 
            [headlineText]="'Total Contacts'" 
            [headlineNumber]="_dashboardStatsModel.totalContacts" 
            [faIconName]="'users'" 
            [percentageDifference]="_dashboardStatsModel.contactsPercentageDifference" 
            [faIconNameForPercentage]="getFaIconName(_dashboardStatsModel.contactsPercentageDifference)"             
            [colourForPercentage]="getPercentageColour(_dashboardStatsModel.contactsPercentageDifference)">
          </app-stats-card>
        </div>
        <div class="col">
          <app-stats-card 
            [headlineText]="'Email Sends'" 
            [headlineNumber]="_dashboardStatsModel.totalMessagesSent" 
            [faIconName]="'envelope'" 
            [percentageDifference]="_dashboardStatsModel.messagesPercentageDifference" 
            [faIconNameForPercentage]="getFaIconName(_dashboardStatsModel.messagesPercentageDifference)" 
            [colourForPercentage]="getPercentageColour(_dashboardStatsModel.messagesPercentageDifference)"></app-stats-card>
        </div>
        <div class="col">
          <app-stats-card 
            [headlineText]="'SMS Sends'"  
            [headlineNumber]="_dashboardStatsModel.totalSMSMessagesSent" 
            [faIconName]="'mobile-alt'" 
            [percentageDifference]="_dashboardStatsModel.smsPercentageDifference" 
            [faIconNameForPercentage]="getFaIconName(_dashboardStatsModel.smsPercentageDifference)" 
            [colourForPercentage]="getPercentageColour(_dashboardStatsModel.smsPercentageDifference)">
          </app-stats-card>
        </div>
      </div>        
    </mdb-card-body>                
  </mdb-card>  
</div>