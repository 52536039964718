import { EditStepRequestDto } from '../steps/edit-step-request-dto';


export class EditContactPlanRequestDto {

    id: number;
    folderId: number;
    status: boolean;
    title: string;
    editStepDtos: EditStepRequestDto[] = [];

    constructor(init: any) {
        this.id = init.id;
        this.editStepDtos = init.EditStepDtos ? init.EditStepDtos : [];
        this.folderId = init.folderId;
        this.status = init.status;
        this.title = init.title;
    }
}
