import { ListFieldFromFileDto } from './list-field-from-file-dto';
import { ImportListSettingsDto } from './import-list-settings-dto';
import { BaseListDto } from './base-list-dto';
import { ListHistoryUploadDto } from './list-history-upload-dto';

export class ListFromFileDtoRequest extends BaseListDto {

  columns: ListFieldFromFileDto[] = [];
  file: ListHistoryUploadDto;
  settings: ImportListSettingsDto;
  isExistingList: boolean;

  constructor(init: { 
    columns: ListFieldFromFileDto[];
    file: ListHistoryUploadDto;
    settings: ImportListSettingsDto;
    isExistingList: boolean;
  }) {

    super();
    this.columns = init.columns,
    this.file = init.file,
    this.settings = init.settings,
    this.isExistingList = init.isExistingList;
  }
}




