<!--SMS Message-->
<div class="row ml-4 mr-4 mt-4 mb-1">
  <div class="col-md-12 sms-message-page">
    <div class="navigation">
      <img class="image-back" src="assets/images/back.png" (click)="navigateToMessagesPage()" alt="Back">
      <span class="label-back">Back to Messages</span>
    </div>
    <div class="title-box">
      <span class="title-section">{{_title}} SMS Message</span>
    </div>
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">

      <!--Card Content-->
      <mdb-card-body cascade="true">
        <section>
          <div class="container-fluid">
            <!-- First Row -->
            <form [formGroup]="_formGroupSms">
              <div class="row mt-2 justify-content-md-center">
                <div class="col-md-6">
                  <label for="name" class="input-label">Message Name</label>
                  <input mdbInput type="text" maxlength="225" id="name" class="form-control input-style"
                    placeholder="Enter Message Name" formControlName="name">
                </div>
              </div>
              <div class="row mt-2 justify-content-md-center">
                <div class="col-md-6">

                  <label for="name" class="input-label">Folder</label>
                  <select class="form-control select-input" name="messageFolders" id="messageFolders"
                    formControlName="messageFolders">
                    <option value="" hidden>Select Folder</option>
                    <option *ngFor="let folder of _messageFolderSelectListOptions; let i = index"
                      [ngValue]="folder.value">
                      {{folder.label}}
                    </option>
                  </select>

                </div>
              </div>
              <div class="row mt-2 justify-content-md-center">
                <div class="col-md-6">
                  <label for="senders" class="input-label">Sender</label>
                  <select class="form-control select-input" name="senders" id="senders" formControlName="senders">
                    <option value="0" hidden>Select Sender</option>
                    <option *ngFor="let sender of _clientInboxSelectListOptions; let i = index"
                      [ngValue]="sender.value">
                      {{sender.label}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2 justify-content-md-center">
                <div class="col-md-6">
                  <label for="message" class="input-label">Message</label>
                  <div class="input-group">
                    <textarea rows="4" maxlength="765" formControlName="message" type="text" id="message"
                      (keyup)="onKeyUpEvent($event)" (click)="onClickEvent($event)"
                      (ngModelChange)="onModelChangeEvent($event)" placeholder="Enter Message"
                      class="md-textarea textarea-style form-control" mdbInput></textarea>
                    <div class="input-group-append">
                      <button mdbTooltip="Insert Wildcard" placement="top" class="input-group-text insert-wilcard"
                        id="basic-addon2" (click)="_formGroupInsertWildcard.reset(); insertWildcardModal.show()">
                        <span class="insert-wilcard-span">W</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2 justify-content-md-center">
                <div class="col-md-6">
                  <input mdbInput type="text" id="wordCounter" class="form-control border-0 wordcount-input"
                    formControlName="wordCounter" readonly="readonly">
                </div>
              </div>
              <div class="row mt-2 mb-2 justify-content-md-center">
                <div class="col-md-3">
                  <button *ngIf="this._userPermissions.Messaging.MessagesSMS.Edit" [disabled]="!_formGroupSms.valid"
                    type="button" mdbBtn color="primary" class="btn btn-save" (click)="save(false)">
                    Save
                  </button>
                </div>
                <div class="col-md-3 text-right">
                  <button *ngIf="this._userPermissions.Messaging.MessagesSMS.Send" type="button" mdbBtn color="primary"
                    class="btn btn-send" [disabled]="!_formGroupSms.valid" (click)="savePreSend()">
                    Send SMS
                  </button>
                </div>
              </div>
            </form>
            <!-- /First Row -->
          </div>
        </section>
      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>

<!-- Insert Wildcard Modal -->
<div mdbModal #insertWildcardModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="insertWildcardModal" aria-hidden="true">
  <div class="modal-dialog insert-wilcard-modal" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="insertWildcardModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="createFolderModalLabel">Insert Wildcard</h4>
      </div>
      <form [formGroup]="_formGroupInsertWildcard" (ngSubmit)="insertWildcardModalSubmit()">
        <div class="modal-body">
          <div class="form-group pl-16 pr-16">
            <!-- Form -->
            <div class="form-row">
              <div class="col-md-12">
                <label for="listFolders" class="input-label">Folder</label>
                <select class="form-control select-input" name="listFolders" id="listFolders"
                  formControlName="listFolders" [visibleOptions]="10">
                  <option value=0 hidden>Select Folder</option>
                  <option *ngFor="let folder of _listFolderSelectListOptions; let i = index" [ngValue]="folder.value">
                    {{folder.label}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12">
                <label for="lists" class="input-label">List</label>
                <select class="form-control select-input" name="lists" id="lists" formControlName="lists"
                  [visibleOptions]="10">
                  <option value=0 hidden>Select List</option>
                  <option *ngFor="let list of _listsSelecListOptions; let i = index" [ngValue]="list.value">
                    {{list.label}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12">
                <label for="listFields" class="input-label">Field</label>
                <select class="form-control select-input" name="listFields" id="listFields" formControlName="listFields"
                  [visibleOptions]="10">
                  <option value=0 hidden>Select Field</option>
                  <option *ngFor="let option of _listFieldsSelectListOptions; let i = index" [ngValue]="option.value">
                    {{option.label}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- Form -->
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light btn-cancel-modal" aria-label="Close"
            (click)="insertWildcardModal.hide()" mdbWavesEffect>Close</button>
          <button type="submit" [disabled]="!_formGroupInsertWildcard.valid" mdbBtn color="primary"
            class="relative waves-light btn-insert-modal" mdbWavesEffect>Insert</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Insert Wildcard Modal -->

<!--/SMS Message-->

<p *ngIf="this._showSpinner">
  <app-loader></app-loader>
</p>