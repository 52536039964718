import { MessageCopyMoveDetailsDto } from "./message-copy-move-details-dto";
import { MessageCopyMoveFindReplaceDto } from "./message-copy-move-find-replace-dto";

export class MessageSearchReplaceDto {
  public Messages: MessageCopyMoveDetailsDto[];
  public FindReplace: MessageCopyMoveFindReplaceDto[];

  /**
   *
   */
  constructor(init: any) {

    this.Messages = init.Messages;
    this.FindReplace = init.FindReplace;

  }
}
