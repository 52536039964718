import { Injectable } from '@angular/core';
import { VideoThumbnailModel } from './video-thumbnail-model';

@Injectable({
  providedIn: 'root'
})

export class VideoThumbnailModelAdaptor {
  public adapt(init: any): VideoThumbnailModel {
    return new VideoThumbnailModel(init);
  }
}
