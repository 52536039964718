import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-user-authentication',
  templateUrl: './user-authentication.component.html',
  styleUrls: ['./user-authentication.component.scss']
})
export class UserAuthenticationComponent implements OnInit {
  _validateForm: UntypedFormGroup;
  _loggingIn: boolean;
  _signUpResult: string;
  _sendCodeResult: string;
  _username: string;

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    public route: ActivatedRoute) {
    route.params.subscribe(params => {
      this._username = params['data'];
    });
  }

  ngOnInit(): void {
    this.createvalidateForm();
    if (this._username === undefined) {
      this.notificationService.showError("Error occurred while signing in - please sign in again.");
      this.router.navigate(['/login']);
    }
  }

  createvalidateForm() {
    this._validateForm = this.fb.group({
      authenticationCode: ['', Validators.required]
    });
  }

  // attempt to submit the authentication code that the user has provided to Cognito
  // if successful, Cognito will mark the user as "confirmed" and they will be able to login properly in future
  async authenticateUser(code: string) {
    try {
      await this.authService.awsCompleteSignUp(this._username, code).then((result => this._signUpResult = result));
      if (this._signUpResult === 'true') {
        this.notificationService.showSuccess('Cognito sign up completed! Please set up Two Factor Authentication.');
        this.authService.cognitoSignUp = true;
        this.router.navigate(['/two-factor-setup']);
      }
      else {
        this.notificationService.showError('Error completing sign up: ' + this._signUpResult);
      }
    } catch (error) {
      console.log(error);
      this.notificationService.showError('Error completing sign up: ' + error.message);
    }
  }

  // request a new code from Cognito (useful if the user has lost their original email, for instance)
  async resendCode() {
    try {
      await this.authService.awsResendConfirmationCode(this._username).then((result => this._sendCodeResult = result));
      if (this._sendCodeResult === 'true') {
        this.notificationService.showSuccess('Code sent!');
      }
      else {
        this.notificationService.showError('Error sending code: ' + this._sendCodeResult);
      }
    } catch (error) {
      console.log(error);
      this.notificationService.showError('Error sending code: ' + error.message);
    }
  }

}
