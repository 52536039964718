 import { Injectable } from "@angular/core";
import { SelectedFieldsFiltersDto } from "./selected-fields-filters-dto";
 
 @Injectable({
     providedIn: 'root'
	   })
 
   export class SelectedFieldsFiltersDtoAdapter {
     
     public adapt(item: any) {
         return new SelectedFieldsFiltersDto(item);
     }
 
     adaptArray(init: Array<any>): SelectedFieldsFiltersDto[] {
         if (init && init.length > 0) {
           return init.length > 1
           ? init.map(field => new SelectedFieldsFiltersDto(field))
           : [new SelectedFieldsFiltersDto(init[0])];
         } else {
           return [];
         }
       }
   }