import { Injectable } from '@angular/core';
import { MessageCodeStepGridRecordDto } from './message-code-step-grid-record-dto';


@Injectable({
  providedIn: 'root'
})

export class MessageCodeStepGridRecordDtoAdapter {

  adapt(item: any): MessageCodeStepGridRecordDto {
    return new MessageCodeStepGridRecordDto(item);
  }
}
