<div class="row ml-4 mr-4 mt-4 mb-1">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 d-flex justify-content-center">
    <h4 class="page-title">Edit Forms - '{{this._listName}}'</h4>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="text-center remove-padding">

        <section>
          <div class="container-fluid">
            <!-- First Row -->

            <div class="row mt-2">
              <div class="col-md-3 text-left">
                <button
                  type="button"
                  class="btn btn-secondary rounded"
                  (click)="backClicked()">
                  <span class="text-truncate">Back</span>
                </button>
              </div>
              <div class="col-md-9 text-right">
                <button
                  type="button"
                  class="btn btn-secondary rounded"
                  (click)="openPreviewFormNewTab()">
                  <span class="text-truncate">Preview</span>
                </button>
                <div class="btn-group" mdbDropdown style="margin: 6px;">
                  <button
                    mdbDropdownToggle
                    type="button"
                    mdbBtn
                    color="info"
                    class="btn-secondary rounded dropdown-toggle waves-light"
                    mdbWavesEffect>
                    <span class="text-truncate">Actions</span>
                  </button>

                  <div class="dropdown-menu dropdown-primary">
                      <a class="dropdown-item" *ngIf="this._userPermissions.Messaging.ListsAndContactListsForms.Create"
                        (click)="addFormModal.show()">New Form</a>
                      <a class="dropdown-item" *ngIf="this._userPermissions.Messaging.ListsAndContactListsForms.Create"
                        (click)="copyFormModal.show()">Copy Form</a>
                      <a class="dropdown-item" *ngIf="this._userPermissions.Messaging.ListsAndContactListsForms.Edit"
                        (click)="editFormNameModal.show()">Edit Form Name</a>
                      <a class="dropdown-item" *ngIf="this._userPermissions.Messaging.ListsAndContactListsForms.Delete"
                        [ngClass]="{'disabled': this._cannotDelete}" (click)="deleteFormModal.show()">Delete Form</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- /First Row -->
          </div>
        </section>

      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>

<div class="row ml-4 mr-4 mt-4 mb-1">
  <div class="col-md-12">
    <mdb-tabset class="mt-2 form-tabset" #webFormTabs [buttonClass]="'md-tabs'" [contentClass]="'card'" (getActiveTab)="onGetActiveTab($event)">
      <mdb-tab class="tab-body animated fadeIn" *ngFor="let webForm of _allWebForms" heading="{{ webForm.WebFormName }}">

        <div style="text-align: center; position: relative;">
          <router-outlet #outlet="outlet" (activate)="onActivate($event, webForm)"></router-outlet>
        </div>

      </mdb-tab>
    </mdb-tabset>
  </div>
</div>

<div mdbModal #addFormModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addFormModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="addFormModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="addFormModalLabel">Add Form</h4>
      </div>
      <form [formGroup]="_addNewFormForm" (ngSubmit)="addForm()">
        <div class="modal-body">
          <div class="md-form remove-bottom-margin">
            <mdb-select
              class="short-select"
              [options]="_formTypeDropdown"
              [filterEnabled]="true"
              [visibleOptions]="10"
              label="Form Type"
              formControlName="newFormType"
              >
            </mdb-select>
          </div>
          <div class="md-form">
            <input mdbInput type="text" id="add-form-name" maxlength="225" class="form-control" formControlName="formName">
            <label for="add-form-name">New Form Name</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="addFormModal.hide()" mdbWavesEffect>Close</button>
          <button type="submit" [disabled]="!_addNewFormForm.valid" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div mdbModal #editFormNameModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editFormNameModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="editFormNameModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="editFormNameModalLabel">Edit Form Name</h4>
      </div>
      <form [formGroup]="_editFormNameForm" (ngSubmit)="editFormName()">
        <div class="modal-body">
          <div class="md-form remove-bottom-margin">
            <mdb-select
              class="short-select"
              [options]="_formNameDropdown"
              [filterEnabled]="true"
              [visibleOptions]="10"
              label="Form Group Name"
              formControlName="oldName"
              >
            </mdb-select>
          </div>
          <div class="md-form">
            <input mdbInput type="text" id="new-form-name" maxlength="225" class="form-control" formControlName="newName">
            <label for="new-form-name">New Form Name</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="editFormNameModal.hide()" mdbWavesEffect>Close</button>
          <button type="submit" [disabled]="!_editFormNameForm.valid" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div mdbModal #copyFormModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="copyFormModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="copyFormModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="copyFormModalLabel">Copy Form</h4>
      </div>
      <form [formGroup]="_copyFormForm" (ngSubmit)="copyForm()">
        <div class="modal-body">
          <div class="">
            <span>Form Type</span>
            <input
              mdbInput
              type="text"
              id="new-form-type"
              maxlength="225"
              class="form-control"
              formControlName="formType">
          </div>
          <div class="md-form">
            <input mdbInput type="text" id="copy-form-name" maxlength="225" class="form-control" formControlName="formName">
            <label for="copy-form-name">New Form Name</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="copyFormModal.hide()" mdbWavesEffect>Close</button>
          <button type="submit" [disabled]="!_copyFormForm.valid" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div mdbModal #deleteFormModal="mdb-modal" class="modal fade" id="deleteFormModal" tabindex="-1" role="dialog" [config]="{ignoreBackdropClick: true}"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Delete Form?</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="deleteFormModal.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="trash" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <div *ngIf="this._activeForm">
                    <span>Form</span>
                    <span class="font-weight-bold"> {{this._activeForm.WebFormName}}</span>
                    <span>will be deleted from VoiceBox.</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deleteForm();" mdbWavesEffect>Yes, I am sure
                </a>
                <a type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
                   (click)="deleteFormModal.hide()" mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>




