import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteRestoreModalComponent } from '../delete-restore/delete-restore-modal.component';
import { SearchDataService } from '../../../../_services/tables/search-data/search-data.service';
import { QueryBuilderComponent } from '../../query-builder/query-builder.component';
import { QueryBuilderFilterService } from '../../../../_services/query-builder-filters/query-builder-filter.service';
import { TableName } from '../../constants/table-constants';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {
  title: string;
  selectedId: number;
  filterMessageRules = '';
  tableName: string;
  selectedFilter = 0;
  tableKey: string;

  @ViewChild('queryBuilder', { static: true }) queryBuilder: QueryBuilderComponent;

  constructor(
    public queryBuilderFilterService: QueryBuilderFilterService,
    private searchDataService: SearchDataService,
    private dialogRef: MatDialogRef<DeleteRestoreModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.tableKey = this.data.tableKey;
    this.title = 'Filter ' + TableName[this.tableKey];
    this.selectedId = this.data.selectedId;
    this.tableName = TableName[this.tableKey];
  }

  messagesFilterSave(event): void {
    this.queryBuilderFilterService.appliedFilter = event[1]?.sql;

    if (!event[1]) {
      this.filterMessageRules = '';
    } else {
      this.filterMessageRules = event[1].sql;
    }

    this.searchDataService.SetSearchData(this.filterMessageRules, this.tableName);
    this.dialogRef.close({ isNeedToUpdate: true, rules: this.filterMessageRules, filter: this.selectedFilter });
  }

  setFilterValue(value: number): void {
    this.selectedFilter = value;
  }
}
