import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-replace-message',
  templateUrl: './search-replace-message.component.html',
  styleUrls: ['./search-replace-message.component.scss']
})
export class SearchReplaceMessageComponent implements OnInit {
  @Output() formArrayChanged: EventEmitter<UntypedFormArray> = new EventEmitter<UntypedFormArray>();

  searchReplaceFormArray: UntypedFormArray;

  ngOnInit(): void {
    this.searchReplaceFormArray = new UntypedFormArray([]);

    this.searchReplaceFormArray.valueChanges.subscribe(() => {
      this.formArrayChanged.emit(this.searchReplaceFormArray);
    });
  }

  addNewMessageFindReplace(): void {
    this.searchReplaceFormArray.push(new UntypedFormGroup({
      find: new UntypedFormControl('', Validators.required),
      replace: new UntypedFormControl('', Validators.required)
    }));
  }

  deleteMessageFindReplace(index: number): void {
    this.searchReplaceFormArray.removeAt(index);
  }
}
