import { ListFieldsModel } from './list-fields-model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ListFieldsModelAdapter {
  adapt(item: any): ListFieldsModel {
    return new ListFieldsModel(item);    
  }
  
  createEmpty() {
    return new ListFieldsModel({
      FieldID: 0,
      FieldName: '',
      ListID: 0,
      ColumnIndex: 0,
      IsDefault: false,
      IsDeleted: false,
      ColumnName: '',
      Wildcard: null,
      DisplayName: '',
      IsSystem: false,
      IsDatahub: false,
      IsNullable: false,
      MaxLength: null,
      IsIdentity: false,
      Readonly: false,
      Visible: false,
      IsWildcard: false,
      OrderIndex: 0,
      OrderIndexMinor: 0,
      FieldTypeID: 0,
      ListFieldType: [],
      ControlTypeID: 0,
      ControlType: [],
      ViewPath: '',
      IsNew: false,
      ListFieldOptions: [],
    });
  }
}
