import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor() { }

  static unsubscribe(... subscriptions: Subscription[]) {
    subscriptions.map((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }
}
