import { Injectable } from '@angular/core';
import { ListFieldOptionDto } from './list-field-option-dto';

@Injectable({
  providedIn: 'root'
})

export class ListFieldOptionDtoAdapter {
  public adapt(item: any) {
    return new ListFieldOptionDto(item);
  }
}
