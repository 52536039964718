import { Component, OnInit } from '@angular/core';
import {ClientManagementService} from 'src/app/_services/admin/clients/client-management.service';
import { ClientsDto } from 'src/app/_models/admin/clients/clients-model';
import { UserManagementService } from 'src/app/_services/admin/users/user-management.service';
import { SearchDataService } from 'src/app/_services/tables/search-data/search-data.service';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements OnInit {

  _clients: ClientsDto[] = [];
  _filteredClients: ClientsDto[] = [];
  _activeClients: boolean = true;
  private _searchText: string = '';

  _users: any;

  // variable for search data
  _pageTitle = 'Client-Management';

  get activeClients() : boolean {
    return this._activeClients
  }

  set activeClients(value: boolean) {
    this._activeClients = value;
    this._filteredClients = this.filterClients(this._searchText);
  }

  get searchText() : string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
    this._filteredClients = this.filterClients(value);
  }

  constructor(
    private clientManagementService: ClientManagementService,
    private userManagementService: UserManagementService,
    private searchDataService: SearchDataService) { }

  ngOnInit() {  
    this.userManagementService.getUsers().subscribe(data => {
      this._users = data;
      this.clientManagementService.getAllClient().subscribe(data => {
        this._clients = data;
        this._filteredClients = this._clients;        
        if(this.searchDataService.searchData.lastPage === this._pageTitle)
        {
          this._filteredClients = this.filterClients(this.searchDataService.searchData.searchTerm);
          this._searchText = this.searchDataService.searchData.searchTerm;
        }
        else
        {
          this._filteredClients = this.filterClients('');
        }
      });
    });

    if(this.searchDataService.searchData.lastPage != this._pageTitle)
    {
      this.searchDataService.SetSearchData('', this._pageTitle)
    }
  }

  findAccountManager = function(userId: number){
    var accountManager = this._users.find(user => {
      return user.userId == userId;
    });

    if(accountManager){
      return accountManager.firstName + ' ' + accountManager.lastName;
    } else {
      return 'Unknown';
    }
  }

  filterClients(searchText: string) {
    return this._clients.filter(client => {
      this.searchDataService.searchData.searchTerm = searchText;
      this.searchDataService.searchData.lastPage = this._pageTitle;
      return client.KnownAs.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 && client.Active == this._activeClients;}
      );

      
    }

}
