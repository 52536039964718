import { GetContactsDto } from './get-contacts-dto';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GetContactsDtoAdapter {

  createEmpty() {
    return new GetContactsDto({
      ListID: null,
      ContactIDs: null,
    });
  }
}
