<div class="container-fluid animated fadeIn">
  <div class="row m-4">
      <div class="col-lg-12">
        <!--Card-->
        <mdb-card cascade="true" narrower="true">
            <!--Card header-->
            <div class="view view-cascade card-header text-center primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center align-items-center">

              <h4 class="h4-responsive card-header-text">Users</h4>

              <div class="switch">
                <label class="toggleText">
                  Inactive Users
                  <input type="checkbox" checked="checked" (change)="toggleUsers($event)">
                  <span class="lever"></span> Active Users
                </label>
              </div>

            </div>
            <!--/Card header-->

            <div class="px-4">

              <div class="table-wrapper">
                <!--Table-->
                <table mdbTable #tableEl="mdbTable" mdbTableScroll scrollX="true" maxWidth="1494" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
                  <thead class="sticky-top">
                  <tr>
                    <th class="text-center" *ngFor="let head of headElements; let i = index" scope="col">
                      <a *ngIf="head !== 'edit user'" [mdbTableSort]="users" [sortBy]="headElements[i]">{{head | titlecase}} <mdb-icon fas icon="sort" class="ml-1"></mdb-icon></a>
                      <a style="cursor: default" *ngIf="head === 'edit user'">{{head | titlecase}}</a>

                    </th>
                  </tr>
                  </thead>
                  <tbody #row>
                  <tr mdbTableCol *ngFor="let user of users; let i = index">
                    <th class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row">{{user.userId}}</th>
                    <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{user.firstName}}</td>
                    <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{user.lastName}}</td>
                    <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{user.username}}</td>
                    <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{user.email}}</td>
                    <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{user.defaultClient}}</td>
                    <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                      <i *ngIf="user.active" class="status-icon fa fa-circle" style="color: green"></i>
                      <i *ngIf="!user.active" class="status-icon fa fa-circle" style="color: red"></i>
                    </td>
                    <td class="text-center" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                        <div class="btn-group mx-4" mdbDropdown>
                            <mdb-icon fas icon="user" classInside="dropdown-toggle" mdbDropdownToggle class="waves-effect icon-color"
                              mdbWavesEffect></mdb-icon>
                            <div class="dropdown-menu dropdown-primary">
                              <a class="dropdown-item" (click)="editUser(user.userId)">Edit User</a>
                              <a class="dropdown-item" (click)="changeUserStatus(user.userId)" *ngIf="!user.active">Activate</a>
                              <a class="dropdown-item" (click)="changeUserStatus(user.userId)" *ngIf="user.active">Deactivate</a>
                            </div>
                          </div>
                    </td>

                  </tr>
                  </tbody>
                  <tfoot class="grey lighten-5 w-100">
                  <tr>
                    <td colspan="8">
                      <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="users"></mdb-table-pagination>
                    </td>
                  </tr>
                  </tfoot>
                </table>
                <!--/Table-->
              </div>
            </div>
          </mdb-card>
        <!--/Card-->
      </div>
  </div>
</div>
