import { PermissionsPageTypes, PermissionsPageTypesAdapter } from './permissions-page-types-model';
import { Section, Page } from '../../admin/users/permissions-all-available-model';
import { Injectable } from '@angular/core';
import { PermissionsSocialPagesEnum } from './permissions-social-pages.enum';
import { PermissionsMessagingPagesEnum } from './permissions-messaging-pages.enum';

export class PermissionsMessagingPages {
  Messages : PermissionsPageTypes;
  MessagesEmail : PermissionsPageTypes;
  MessagesSMS : PermissionsPageTypes;
  MessagesReports : PermissionsPageTypes;
  MessagesReportsEmail : PermissionsPageTypes;
  MessagesReportsSMS : PermissionsPageTypes;
  ListsAndContact : PermissionsPageTypes;
  ListsAndContactLists : PermissionsPageTypes;
  ListsAndContactListsForms : PermissionsPageTypes;
  ListsAndContactContacts : PermissionsPageTypes;
  Automation : PermissionsPageTypes;
  AutomationContactPlans : PermissionsPageTypes;
  AutomationContactPlansContactSteps : PermissionsPageTypes;
  AutomationMessageCodes : PermissionsPageTypes;
  AutomationMessageCodesMessageCodeSteps : PermissionsPageTypes;
  AutomationExtracts : PermissionsPageTypes;
  AutomationExtractsSteps : PermissionsPageTypes;

  private _permissionsPageTypesAdapter : PermissionsPageTypesAdapter = new PermissionsPageTypesAdapter();


  constructor(section: Section) {
    section.pages.forEach((Page: Page) => {  
      switch (Page.id) {
        case PermissionsMessagingPagesEnum.Messages:
          this.Messages = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.MessagesEmail:
          this.MessagesEmail = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.MessagesSMS:
          this.MessagesSMS = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.MessagesReports:
          this.MessagesReports = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.MessagesReportsEmail:
          this.MessagesReportsEmail = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.MessagesReportsSMS:
          this.MessagesReportsSMS = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.ListsAndContact:
          this.ListsAndContact = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.ListsAndContactLists:
          this.ListsAndContactLists = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.ListsAndContactListsForms:
          this.ListsAndContactListsForms = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.ListsAndContactContacts:
          this.ListsAndContactContacts = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.Automation:
          this.Automation = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.AutomationContactPlans:
          this.AutomationContactPlans = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.AutomationContactPlansContactSteps:
          this.AutomationContactPlansContactSteps = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.AutomationMessageCodes:
          this.AutomationMessageCodes = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.AutomationMessageCodesMessageCodeSteps:
          this.AutomationMessageCodesMessageCodeSteps = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.AutomationExtracts:          
          this.AutomationExtracts = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsMessagingPagesEnum.AutomationExtractsSteps:        
          this.AutomationExtractsSteps = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        default:
          console.warn("Unknown permission with Page name: " + Page.name);
          break;
      }

    });
  }
}

@Injectable({
  providedIn: 'root'
})

export class PermissionsMessagingPagesAdapter {
  adapt(item: Section): PermissionsMessagingPages {
    return new PermissionsMessagingPages(item);
  }
}
