import { Injectable } from '@angular/core';
import { LightListModel } from './light-list-model';

@Injectable({
  providedIn: 'root'
})

export class LightListModelAdapter {
  adapt(item: any): LightListModel {
    return new LightListModel(item);
  }

  adaptArray(init: Array<any>): LightListModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new LightListModel(table))
      : [new LightListModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new LightListModel({
      FolderID: 0,
      FolderName: '',
      IsDelete: false,
    });
  }
}
