<div class="row m-4">
    <div class="col-md-12">
      <!--Card-->
      <mdb-card cascade="true" narrower="true" class="mt-5">

        <!--Card image-->
        <div class="view view-cascade gradient-card-header primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center">
            <h4 class="page-title">{{client?.KnownAs}} Settings</h4>
        </div>
        <!--/Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="text-center">

          <section>
            <div class="row m-2">
              <div class="col-md-1">
                  <button 
                  type="button" 
                  mdbBtn 
                  color="primary" 
                  class="rounded"
                  [routerLink]="['/client-management']"
                  mdbWavesEffect>Back
                </button>
              </div>
            </div>
            <div class="container">              
              <div class="row m-2">
                <div class="col-md-3">
                  <button 
                    type="button" 
                    mdbBtn 
                    color="secondary" 
                    class="rounded"
                    [routerLink]="['domains']" routerLinkActive="router-link-active" 
                    mdbWavesEffect>Domains
                  </button>
                </div>
                <div class="col-md-3">
                  <button 
                    type="button" 
                    mdbBtn 
                    color="secondary" 
                    class="rounded"
                    [routerLink]="['amazon-settings']" routerLinkActive="router-link-active" 
                    mdbWavesEffect>Amazon Settings
                  </button>
                </div>
                <div class="col-md-3">
                  <button 
                    type="button" 
                    mdbBtn 
                    color="secondary" 
                    class="rounded"
                    [routerLink]="['client-inboxes']" routerLinkActive="router-link-active" 
                    mdbWavesEffect>Client Inboxes
                  </button>
                </div>
                <div class="col-md-3">
                  <button 
                    type="button" 
                    mdbBtn 
                    color="secondary" 
                    class="rounded"
                    [routerLink]="['client-etls']" routerLinkActive="router-link-active" 
                    mdbWavesEffect>Client ETLs
                  </button>
                </div>
              </div>
            </div>

          </section>

        </mdb-card-body>
        <!--/.Card content-->
      </mdb-card>
      <!--/.Card-->
    </div>
  </div>

<div [@onPageAnimations]="prepareRoute(outlet)" class="" style="text-align: center; position: relative;">

  <router-outlet #outlet="outlet"></router-outlet>

</div>