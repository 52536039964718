export class FolderDtoRequest {

    id: number;
    name: string;

    constructor(init: { id?: number, name: string }) {
        this.id = init.id,
        this.name = init.name;
    }
}
