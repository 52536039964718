import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PostsModel } from 'src/app/_models/social-media/posts/posts-model';
import { ShortLinkModel } from 'src/app/_models/social-media/posts/short-link-model';
import { FilterPostsModel } from 'src/app/_models/social-media/posts/filter-posts-model';
import { PostViewModelAdapter, PostViewModel } from 'src/app/_models/social-media/posts/post-view.model';
import { Observable, Subject } from 'rxjs';
import { PostUpdateStatusModel } from 'src/app/_models/social-media/posts/post-update-status.model';
import { LinkModel } from 'src/app/_models/social-media/posts/link-model';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  resetPagination$: Subject<void> = new Subject<void>();
  public networkId: number | number[];
  public networkGroupId: number | number[];
  baseUrl = environment.apiURL;

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  resetPagination(): void {
    this.resetPagination$.next();
  }

  resetPaginator(): Observable<void> {
    return this.resetPagination$ as Observable<void>;
  }

  addPost(post: PostsModel) {
    return this.http.post(this.baseUrl + 'Post', post).pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  shrinkLink(linkDetails: ShortLinkModel) {
    return this.http.post(this.baseUrl + 'Post/shortlinks', linkDetails).pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  getAllPosts(filterPost: FilterPostsModel) {
    return this.http.post(this.baseUrl + 'Post/GetAllPosts', filterPost).pipe(map((data: any) =>
      data.Data
    ));
  }

  getPostsModel(): Observable<ExecutionResultDto> {
    return this.http.get(this.baseUrl + 'Post/GetPostModel').pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  pausePost(postUpdateStatusModel: PostUpdateStatusModel): Observable<ExecutionResultDto> {
    return this.http.post(this.baseUrl + 'Post/PausePost', postUpdateStatusModel).pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  resumePost(postUpdateStatusModel: PostUpdateStatusModel): Observable<ExecutionResultDto> {
    return this.http.post(this.baseUrl + 'Post/ResumePost', postUpdateStatusModel).pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  approvePost(postUpdateStatusModel: PostUpdateStatusModel): Observable<ExecutionResultDto> {
    return this.http.post(this.baseUrl + 'Post/ApprovePost', postUpdateStatusModel).pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  deletePost(postUpdateStatusModel: PostUpdateStatusModel): Observable<ExecutionResultDto> {
    return this.http.post(this.baseUrl + 'Post/Delete', postUpdateStatusModel)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  editPost(postViewModel): Observable<ExecutionResultDto> {
    return this.http.put(this.baseUrl + 'Post', postViewModel).pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  get(id: number): Observable<ExecutionResultDto> {
    return this.http.get(this.baseUrl + 'Post/' + id)
      .pipe(
        map((data: any) => this.executionResultAdapter.adapt(data))
    );
  }

  addCustomLink(linkModel: LinkModel): Observable<ExecutionResultDto> {
    return this.http.post(this.baseUrl + 'Post/AddCustomLink', linkModel).pipe(map((data: any) =>
      this.executionResultAdapter.adapt(data)));
  }

  getDate(obj): string {
    return obj.CreatedTime || obj.Timestamp || obj.createdDate || '';
  }
}
