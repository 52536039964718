import { Injectable } from '@angular/core';

export class PagerModel {
  Page: number;
  PageSize: number;
  SortColumn?: string;
  SortDirection?: string;

  constructor(init: any) {
    this.Page = +init.Page;
    this.PageSize = +init.PageSize;
    this.SortColumn = init.SortColumn;
    this.SortDirection = init.SortDirection;
  }
}

@Injectable({
  providedIn: 'root'
})

export class PagerModelAdapter {
  adapt(item: any): PagerModel {
    return new PagerModel(item);
  }

  createEmpty(): PagerModel {
    return new PagerModel({
      Page: 1,
      PageSize: 5,
      SortColumn: "id",
      SortDirection: "asc"
    });
  }

  setCurrentPagination(currentPage, pagesize):PagerModel {
    return new PagerModel({
      Page: currentPage + 1,
      PageSize: pagesize,
      SortColumn: "id",
      SortDirection: "asc"
    });
  }
}
