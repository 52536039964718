export enum ListControlTypeEnum {
    Input = 1,
    Textarea = 2,
    Dropdown = 3,
    Checkbox = 4,
    RadioButton = 5,
    DatePicker = 6,
    TimePicker = 7,
    DateTimePicker = 8
}
