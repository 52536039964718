import { ListFieldTypeModel } from './list-field-type-model';
import { FieldControlTypeModel } from './field-control-type-model';
import { ListFieldOptionModel } from './list-field-option-model';
import { ListFieldControlTypeModel } from './list-field-control-type-model';

export class ListFieldsModel {

  public FieldID: number;
  public FieldName: string;
  public ListID: number;
  public ColumnIndex: number;
  public IsDefault: boolean;
  public IsDeleted: boolean;
  public ColumnName: string;
  public Wildcard: string;
  public DisplayName: string;
  public IsSystem: boolean;
  public IsDatahub: boolean;
  public IsNullable: boolean;
  public MaxLength: string;
  public IsIdentity: boolean;
  public Readonly: boolean;
  public Visible: boolean;
  public IsWildcard: boolean;
  public OrderIndex: number;
  public OrderIndexMinor: number;
  public FieldTypeID: number;
  public ListFieldType: ListFieldTypeModel;
  public ControlTypeID: number;
  public ControlType: FieldControlTypeModel;
  public ViewPath: string;
  public IsNew: boolean;
  public ListFieldOptions: Array<ListFieldOptionModel>;

  constructor(init: any) {    

    this.FieldID = init.FieldID;
    this.FieldName = init.FieldName;
    this.ListID = init.ListID;
    this.ColumnIndex = init.ColumnIndex;
    this.IsDefault = init.IsDefault;
    this.IsDeleted = init.IsDeleted;
    this.ColumnName = init.ColumnName;
    this.Wildcard = init.Wildcard;
    this.DisplayName = init.DisplayName;
    this.IsSystem = init.IsSystem;
    this.IsDatahub = init.IsDatahub;
    this.IsNullable = init.IsNullable;
    this.MaxLength = init.MaxLength;
    this.IsIdentity = init.IsIdentity;
    this.Readonly = init.Readonly;
    this.Visible = init.Visible;
    this.IsWildcard = init.IsWildcard;
    this.OrderIndex = init.OrderIndex;
    this.OrderIndexMinor = init.OrderIndexMinor;
    this.FieldTypeID = init.FieldTypeID;
    this.ListFieldType = init.ListFieldType;
    this.ControlTypeID = init.ControlTypeID;
    this.ControlType = init.ControlType;
    this.ViewPath = init.ViewPath;
    this.IsNew = init.IsNew;
    this.ListFieldOptions = init.ListFieldOptions;
  }
}
