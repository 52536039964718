import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ListControlTypeEnum } from '../../../../_models/messaging/lists-and-contacts/contacts/list-control-type-enum';
import { ClockPickerComponent, IMyOptions, MDBDatePickerComponent, ModalDirective } from 'ng-uikit-pro-standard';
import { ListFieldTypeEnum } from '../../../../_models/messaging/lists-and-contacts/contacts/list-field-type-enum';
import { ContactService } from '../../../../_services/messaging/lists-and-contacts/contacts/contact.service';
import { ContactFieldDto } from '../../../../_models/messaging/lists-and-contacts/contacts/contact-field-dto';
import { NotificationService } from '../../../../_services/notification.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SelectListOption } from '../../../../_models/system/select-list-option';
import { ContactDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-dto-adapter';
import { ContactFieldDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-field-dto-adapter';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';

@Component({
  selector: 'app-create-edit-contact',
  templateUrl: './create-edit-contact-modal.component.html',
  styleUrls: ['./create-edit-contact-modal.component.scss']
})
export class CreateEditContactModalComponent implements OnInit {
  contactFormGroup: UntypedFormGroup;
  fields: ContactFieldDto[];
  selectedDatePicker: MDBDatePickerComponent;
  selectedTimePicker: ClockPickerComponent;
  listControlTypeEnum = ListControlTypeEnum;
  listFieldTypeEnum = ListFieldTypeEnum;
  folderOptions: SelectListOption[];
  listOptions: SelectListOption[];
  title: string;

  dropDownListOptions: { fieldId: number, options: { value: string, label: string }[] }[] = [];
  booleanOptions: { value: string, label: string }[] = [{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }];
  myDatePickerOptions: IMyOptions = { dateFormat: 'dd/mm/yyyy' };

  @ViewChildren(MDBDatePickerComponent) datepickers: QueryList<MDBDatePickerComponent>;
  @ViewChildren(ClockPickerComponent) timepickers: QueryList<ClockPickerComponent>;

  constructor(
    private contactService: ContactService,
    private datePipe: DatePipe,
    private contactFieldDtoAdapter: ContactFieldDtoAdapter,
     private notificationService: NotificationService,
    private dialogRef: MatDialogRef<CreateEditContactModalComponent>,
    private contactDtoAdapter: ContactDtoAdapter,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.title = this.data.row ? 'Edit' : 'Create' + ' Contact';
    this.folderOptions = this.data.folders;
    this.listOptions = this.data.lists;
    this.contactFormGroup = new UntypedFormGroup({
      folderId: new UntypedFormControl(this.data.selectedFolder),
      listId: new UntypedFormControl(this.data.selectedList)
    });

    if (this.data.row) {
      this.getContact();
    }
    else {
      this.fields = this.data.fields;
      this.populateFormFields();   
    }    
  }

  create(): void {
    this.dialogRef.close({
      needCreate: true,
      selectedContact: this.data.row?.ContactID || 0,
      fields: this.fields,
      contactFormGroup: this.contactFormGroup
    });
  }

  getContact() {
    this.contactService.get(this.data.selectedList, this.data.row?.ContactID).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const contactDto = this.contactDtoAdapter.adapt(executionResultDto.data);
        this.fields = contactDto.fields;
        this.populateFormFields();      
        this.fillForm();  
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getContactFields(): void {
    this.contactService.getContactFields(this.data.selectedList).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.fields = executionResultDto.data.map(data => this.contactFieldDtoAdapter.adapt(data));
        this.populateFormFields();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      });
  }

  populateFormFields(): void {
    this.dropDownListOptions = [];
    this.fields.map((contactFieldDto) => {
      let validator = null;
      let value = '';
      let hasOptions = false;

      if (contactFieldDto.controlTypeId === ListControlTypeEnum.Dropdown
        || contactFieldDto.controlTypeId === ListControlTypeEnum.RadioButton
        || contactFieldDto.controlTypeId === ListControlTypeEnum.Checkbox) {

        hasOptions = Object.keys(contactFieldDto.options).length > 0;
        if (contactFieldDto.fieldTypeId === ListFieldTypeEnum.Boolean) {
          value = this.booleanOptions.some(x => x.value && x.value.toString().toLowerCase() === contactFieldDto.value.toString().toLowerCase()) ?
            this.booleanOptions.find(x => x.value.toString().toLowerCase() === contactFieldDto.value.toString().toLowerCase()).value :
            null;
        } else {
          value = contactFieldDto.value;
        }

        if (contactFieldDto.controlTypeId === ListControlTypeEnum.Dropdown && hasOptions) {
          this.dropDownListOptions.push({
            fieldId: contactFieldDto.fieldId,
            options: contactFieldDto.options.map(o => ({ value: o.optionValue, label: o.optionValue }))
          });
        }

        if (contactFieldDto.controlTypeId === ListControlTypeEnum.Checkbox) {
          const values = value.indexOf(',') > -1 ? value.split(',') : [value];

          contactFieldDto.options.map(option => {
            let selectedValue = '';
            selectedValue = values.some(x => x === option.optionValue) ? option.optionValue : '';
            this.contactFormGroup.addControl(`${contactFieldDto.name}-${option.optionValue}`,
              new UntypedFormControl(selectedValue, validator));
          });
        } else {
          this.contactFormGroup.addControl(contactFieldDto.fieldName, new UntypedFormControl(value, validator));
        }

        validator = Validators.required;
      } else if (contactFieldDto.controlTypeId === ListControlTypeEnum.DatePicker ||
        contactFieldDto.controlTypeId === ListControlTypeEnum.DateTimePicker) {

        var dateTimeString = this.getFormattedDateString(contactFieldDto);

        this.contactFormGroup.addControl(contactFieldDto.fieldName, new UntypedFormControl(dateTimeString, validator));

        const date = this.isDate(contactFieldDto.value) ? new Date(contactFieldDto.value) : new Date();

        const selectedDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } };

        this.contactFormGroup.addControl('dp-' + contactFieldDto.fieldName, new UntypedFormControl(selectedDate, validator));

      } else if (contactFieldDto.fieldTypeName.toLowerCase() === 'email' || contactFieldDto.fieldName.toLowerCase() === 'email') {
        value = contactFieldDto.value;
        validator = Validators.email;
        this.contactFormGroup.addControl(contactFieldDto.fieldName, new UntypedFormControl(value, validator));
      } else if (contactFieldDto.fieldTypeName.toLowerCase() === 'mobile' || contactFieldDto.fieldName.toLowerCase() === 'mobile') {
        value = contactFieldDto.value;
        validator = Validators.pattern(/^(447)([0-9]{9})$/);
        this.contactFormGroup.addControl(contactFieldDto.fieldName, new UntypedFormControl(value, validator));
      } else {
        value = contactFieldDto.value;
        this.contactFormGroup.addControl(contactFieldDto.fieldName, new UntypedFormControl(value, validator));
      }
    });    

  }

  fillForm(): void {
    this.fields.forEach((field) => {
      let value = field.value; 
      if (field.controlTypeId === ListControlTypeEnum.Checkbox) {
        const values = value.indexOf(',') > -1 ? value.split(',') : [value];

        field.options.map(option => {
          let selectedValue = '';
          selectedValue = values.some(x => x === option.optionValue) ? option.optionValue : '';
          this.contactFormGroup.get(`${field.name}-${option.optionValue}`).setValue(selectedValue);
        });
      } else {
        if (value && field.fieldTypeId === ListFieldTypeEnum.Boolean) {
          value = this.booleanOptions.some(x => x.value && x.value.toString().toLowerCase() === field.value.toString().toLowerCase()) ?
          this.booleanOptions.find(x => x.value.toString().toLowerCase() === field.value.toString().toLowerCase()).value :
          null;
        } else if (field.controlTypeId === ListControlTypeEnum.DatePicker ||
          field.controlTypeId === ListControlTypeEnum.DateTimePicker) {
  
          var dateTimeString = this.getFormattedDateString(field);

          this.contactFormGroup.get(field.name).setValue(dateTimeString);
        }
        else {
          this.contactFormGroup.get(field.name).setValue(value);
        }       
      }
    });
  }

  getFormattedDateString(field: ContactFieldDto): string {
        var dateTimeString = field.value;

        if (field.controlTypeId === ListControlTypeEnum.DatePicker && field.value && field.value !== '') {
          dateTimeString = this.datePipe.transform(new Date(field.value), 'dd/MM/yyyy');
        }

        if (field.controlTypeId === ListControlTypeEnum.DateTimePicker && field.value && field.value !== '') {
          if (field.value.indexOf('Z') < 0) {
            const dateToConvert = new Date(field.value + 'Z');
            dateTimeString = this.datePipe.transform(dateToConvert, 'dd/MM/yyyy HH:mm:ss');
          }
          else {
            const dateToConvert = new Date(field.value);
            dateTimeString = this.datePipe.transform(dateToConvert, 'dd/MM/yyyy HH:mm:ss');
          }
        }

        return dateTimeString;
  }

  findDropDownListOptions(fieldId: number): { value: string, label: string }[] {
    return this.dropDownListOptions.find(x => x.fieldId === fieldId)?.options || [];
  }

  isDate(value): boolean {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  onInputClickShowDatePicker(controlName: string): void {
    this.selectedDatePicker = this.datepickers.find(x => x.id === `dp-${controlName}`);

    if (this.selectedDatePicker) {
      this.selectedDatePicker.openBtnClicked();
    }
  }

  onDateChange(controlName, event): void {
    if (event.jsdate !== null) {
      this.contactFormGroup.get(controlName).setValue(event.actualDateFormatted);
    } else {
      this.contactFormGroup.get(controlName).setValue(this.contactFormGroup.get(controlName).value.toString().substring(0, 10));
    }

    if (this.selectedDatePicker) {
      this.selectedDatePicker.closeBtnClicked();
    }

    const timepicker: ClockPickerComponent = this.timepickers.find(x => x.elem.nativeElement.id === `tp-${controlName}`);

    if (timepicker) {
      timepicker.openBtnClicked();
    }
  }

  onTimeChange(controlName, event): void {
    const control = this.contactFormGroup.get(controlName);
    this.datepickers.some(x => x.id === `dp-${controlName}`) ? control.setValue(`${control.value}, ${event}`) : control.setValue(event);
  }

  onInputClickShowTimePicker(controlName: string) {
    this.selectedTimePicker = this.timepickers.find(x => x.elem.nativeElement.id === `tp-${controlName}`);

    if (this.selectedTimePicker) {
      this.selectedTimePicker.openBtnClicked();
    }
  }
}
