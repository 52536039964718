import { ContactFieldDto } from './contact-field-dto';
import { ContactFieldDtoAdapter } from './contact-field-dto-adapter';

export class ContactDto {
  contactId: number;
  listId: number;
  listName: string;
  folderID: number;
  folderName: string;
  creationDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
  lastEmailOpenDate: Date;
  lastEmailSendDate: Date;
  lastEmailClickDate: Date;
  lastWebFormUsageDate: Date;
  bounced: boolean;
  fields: ContactFieldDto[];

  constructor(init: any) {
    this.contactId = +init.ContactID,
    this.listId = +init.ListID,
    this.listName = init.ListName,
    this.folderID = +init.FolderID;
    this.folderName = init.FolderName;
    this.creationDate = init.CreationDate;
    this.createdBy = init.CreatedBy;
    this.updatedDate = init.UpdatedDate;
    this.updatedBy = init.UpdatedBy;
    this.lastEmailOpenDate = init.LastEmailOpenDate;
    this.lastEmailSendDate = init.LastEmailSendDate;
    this.lastEmailClickDate = init.LastEmailClickDate;
    this.lastWebFormUsageDate = init.LastWebFormUsageDate;
    this.bounced = !!init.Bounced;
    this.fields = init.Fields.map(field => new ContactFieldDtoAdapter().adapt(field));
  }
}
