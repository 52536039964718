import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { WebFormPageViewModel } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-page-view-model';
import { WebFormViewModel } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-view-model';
import { CarouselComponent } from 'ng-uikit-pro-standard';
import { FormsService } from 'src/app/_services/messaging/lists-and-contacts/forms/forms.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { WebFormMessageModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-message-model.adapter';
import { WebFormMessageModel } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-message-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { ApplicationService } from 'src/app/_services/system/application.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';

@Component({
  selector: 'app-web-form-container',
  templateUrl: './web-form-container.component.html',
  styleUrls: ['./web-form-container.component.scss']
})
export class WebFormContainerComponent implements OnInit {

  @ViewChild('formCarousel', { static: true }) formCarousel: CarouselComponent;

  _userPermissions: PermissionsModel;
  _webFormID: number;
  _webFormPages: Array<WebFormPageViewModel>;
  _activeSlideIndex: number = 0;
  _editorWindow;
  _timer: any;

  constructor(
    private formsService: FormsService,
    private notificationService: NotificationService,
    private webFormMessageModelAdapter: WebFormMessageModelAdapter,
    private permissionsService: PermissionsService,
    private applicationService: ApplicationService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,

  ) { }

  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if(event.data === null) {
      this.applicationService.hideOverlay();
      return;
    }
    if (this._webFormID === event.data.WebFormID) {
      this.formsService.updateFormContent(event.data).subscribe(result => {
        this.updateFormOnPage(event.data);
        this._editorWindow.close();
        this.notificationService.showSuccess(result.data.Message);
        this.applicationService.hideOverlay();
      }, error => {
        this.notificationService.showError('Web Form Page has failed to save.');
      });
    }
  }

  ngOnInit() {
    this._userPermissions = this.permissionsService.getPermissionsModel();
  }

  displayForm(webForm: WebFormViewModel) {
    this._webFormID = webForm.WebFormID
    this._webFormPages = webForm.ListWebFormPages;
  }

  nextSlide() {
    this.formCarousel.nextSlide();
  }

  previousSlide() {
    this.formCarousel.previousSlide();
  }

  selectSlide(i) {
    this.formCarousel.selectSlide(i);
  }

  onActiveSlideChange($event) {
    this._activeSlideIndex = $event.relatedTarget;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  openFormEditor(webFormPage: WebFormPageViewModel) {
    this.applicationService.showOverlay();

    var webFormMessage: WebFormMessageModel = this.webFormMessageModelAdapter.adapt(webFormPage);

    webFormMessage.ClientID = parseInt(localStorage.getItem('CurrentClientId'), 10);
    webFormMessage.Username = this.localStorageService.getUsername;

    this.authService.getExtenedToken().subscribe((result: ExecutionResultDto) => {
      webFormMessage.Token = result.data;

      var windowHref = environment.formBuilderURL;

      this._editorWindow = window.open(windowHref, 'FormBuilder',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1200,height=800');

      setTimeout(() => {
        this._editorWindow.postMessage(JSON.stringify(webFormMessage, this.getCircularReplacer()), '*');
        this._editorWindow.focus();
      }, 3000);
    });
  }

  updateFormOnPage(data) {
    const formToUpdate = this._webFormPages.find(webform =>
      webform.WebFormPageID === data.WebFormPageID);
    const index = this._webFormPages.findIndex(webform =>
      webform.WebFormPageID === data.WebFormPageID);

    formToUpdate.Html = data.Html;
    formToUpdate.MetaDataJson = data.MetaDataJson;
    formToUpdate.ContentDataJson = data.ContentDataJson;

    this._webFormPages[index] = formToUpdate;
  }
}
