import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { CreateEditUserService } from 'src/app/_services/admin/users/create-edit-user.service';
import { UserRoleService } from 'src/app/_services/admin/users/user-role-service';
import { UserRoleMappingService } from 'src/app/_services/admin/users/user-role-mapping-service';
import { UserRoleMappingDto } from 'src/app/_models/admin/users/user-role-mapping-model';
import { UserRoleDto } from 'src/app/_models/admin/users/user-role-model';
import { ClientsDto } from 'src/app/_models/admin/clients/clients-model';
import { UserDto } from 'src/app/_models/admin/users/user-model';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { MediaServerService } from 'src/app/_services/media-server/media-server.service';
import { S3ObjectsModel } from 'src/app/_models/media-server/S3Models/S3Objects.model';

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.scss']
})
export class CreateEditUserComponent implements OnInit {

    @ViewChild('mediaServerModal', { static: true }) mediaServerModal: ModalDirective;
    @Input() createUser: boolean;
    @Output() userCreatedEvent: EventEmitter<string> = new EventEmitter();

    _model: any = {};
    createEditUserForm: UntypedFormGroup;
    _submitting: boolean;
    user: UserDto; 
    _userRoleDtos: UserRoleDto[] = [];
    _userRoleMappingDtos: UserRoleMappingDto[] = [];
    _clients: ClientsDto[] = [];
    _clientsSelect: Array<any> = [];
    _showMediaServer = false;

    
    
  constructor(
    private fb: UntypedFormBuilder,
    private createEditUserService: CreateEditUserService,
    private notificationService: NotificationService,
    private userRoleService: UserRoleService,
    private userRoleMappingService: UserRoleMappingService,
    private clientManagementService: ClientManagementService,
    public mediaServerService: MediaServerService
    ) { }

  ngOnInit() {
    this.createCreateEditForm();
    this.editUserState();
    this.getRoles();
    this.getClients();
    if (this.user && this.user.userId !== undefined) {
      this.getUserMappings(this.user.userId);
    }
  }

  getClients() {
    this.clientManagementService.getAllClient().subscribe(data => {
      this._clients = data;
      this._clientsSelect = [];
      this._clients.forEach((client: ClientsDto) => {
        if (client.Active) {
          this._clientsSelect = [...this._clientsSelect, {
            value: client.ClientID,
            label: client.KnownAs,
            icon: client.ClientImageUrl
         }];
        }
      });
    });
  }  

  createCreateEditForm() {
    this.createEditUserForm = this.fb.group({
      userId: [],
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address1: [],
      address2: [],
      postcode: [],
      userIconUrl: [],
      defaultClient: new UntypedFormControl('', Validators.required),
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]}, {validator: [this.passwordMatchValidator, this.defaultClientValidator]});
  }  

  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value
      ? null : {'mismatch': true};
  }

  defaultClientValidator(g: UntypedFormGroup) {
    return g.get('defaultClient').value ? null : {'noDefaultClient': true};
  }

  createEditUser() {
    if (this.createEditUserForm.valid) {
      if (this.createUser) {
      this._submitting = true;
      this._model = Object.assign({}, this.createEditUserForm.value);
      delete this._model.userId;
      this.createEditUserService.createUser(this._model).subscribe(() => {
        this.notificationService.showSuccess('User created.');        
        this.createEditUserService.currentUser = undefined; 
        this.userCreatedEvent.emit('users');        
      }, error => {
        this._submitting = false;
      }, () => {
        this._submitting = false;
      });
    } else if (!this.createUser) {
      this._submitting = true;
      this.user.title = this.createEditUserForm.value.title;
      this.user.firstName = this.createEditUserForm.value.firstName;
      this.user.lastName = this.createEditUserForm.value.lastName;
      this.user.username = this.createEditUserForm.value.userName;
      this.user.email = this.createEditUserForm.value.email;
      this.user.address1 = this.createEditUserForm.value.address1;
      this.user.address2 = this.createEditUserForm.value.address2;
      this.user.postCode = this.createEditUserForm.value.postcode;
      this.user.defaultClient = this.createEditUserForm.value.defaultClient;
      this.user.userIconUrl = this.createEditUserForm.value.userIconUrl;
      for (let [key, value] of Object.entries(this.user)) {
        if (!value) {
          this._model[key] = '';
        }
      }
      this.createEditUserService.updateUser(this.user).subscribe(result => {
        this.notificationService.showSuccess(result.message);
      }, result => {
        this._submitting = false;
        this.notificationService.showSuccess(result.message);
      }, () => {
        this._submitting = false;
      });
    }
    }
  }

  editUserState = function() {
      if (this.createEditUserService.currentUser !== undefined || this.createEditUserService.currentUser !== null) {
        const user = this.createEditUserService.currentUser;
        this.user = user;
        if (!this.createUser) {
        this.createEditUserForm.get('userId').setValue(this.user.userId);
        this.createEditUserForm.get('title').setValue(this.user.title);
        this.createEditUserForm.get('firstName').setValue(this.user.firstName);
        this.createEditUserForm.get('lastName').setValue(this.user.lastName);
        this.createEditUserForm.get('userName').setValue(this.user.username);
        this.createEditUserForm.get('email').setValue(this.user.email);
        this.createEditUserForm.get('userIconUrl').setValue(this.user.userIconUrl);
        if (this.user.address1 != null) {
        this.createEditUserForm.get('address1').setValue(this.user.address1);
        // tslint:disable-next-line: align
        } if (this.user.address2 != null) {
        this.createEditUserForm.get('address2').setValue(this.user.address2);
        // tslint:disable-next-line: align
        } if (this.user.postCode != null) {
        this.createEditUserForm.get('postcode').setValue(this.user.postCode);
        // tslint:disable-next-line: align
        } if (this.user.defaultClient != 0) {
        this.createEditUserForm.get('defaultClient').setValue(this.user.defaultClient);
        }
        this.createEditUserForm.get('password').disable();
        this.createEditUserForm.get('confirmPassword').disable();
        this._userIconUrl = this.user.userIconUrl;
        } else if (this.createUser) {
        this.createEditUserForm.reset();
        this.createEditUserForm.get('password').enable();
        this.createEditUserForm.get('confirmPassword').enable();
        }
    }
  };

  getRoles() {
    this.userRoleService.getAll().subscribe(data => {
      this._userRoleDtos = data;
    })
  }

  getUserMappings(userId: number) {
    this.userRoleMappingService.get(userId).subscribe(data => {
      this._userRoleMappingDtos = data;
    })
  }

  postUserRoleMapping(userRoleMappingDto: UserRoleMappingDto)  {
    this.userRoleMappingService.post(userRoleMappingDto).subscribe((id: number) => {
       userRoleMappingDto.id = id;
       this.addUserPermissionIfNotExists(userRoleMappingDto)
    });
  }

  deleteUserRoleMapping(id: number) {
    this.userRoleMappingService.delete(id).subscribe(() => {
      this.removeUserRoleMapping(id);
    });
  }

  userIsInRole(roleId: number) : boolean{
    return this._userRoleMappingDtos.some(_ => _.roleId == roleId);
  }

  getUserRoleMapping(roleId: number) : UserRoleMappingDto{
    return this._userRoleMappingDtos.find(_ => _.roleId == roleId);
  }

  removeUserRoleMapping(id: number) {
    if (this._userRoleMappingDtos.some(_ => _.id == id)) {
        this._userRoleMappingDtos = this._userRoleMappingDtos.filter(_ => _.id !== id);
    };
  }

  addUserPermissionIfNotExists(userRoleMappingDto: UserRoleMappingDto) {
    if (!this._userRoleMappingDtos.some(_ => _.id == userRoleMappingDto.id)) {
      this._userRoleMappingDtos.push(userRoleMappingDto);
    };
  }

  handleCheckBoxChange(e) {
    const isChecked: boolean = e.target.checked;
    const roleId: number = +e.target.value;

    let userRoleMappingDto: UserRoleMappingDto = this.getUserRoleMapping(e.target.value);

    if (!isChecked) {
      this.deleteUserRoleMapping(userRoleMappingDto.id);
      return;
    }

    if (!userRoleMappingDto) userRoleMappingDto = new UserRoleMappingDto({ Id:0, UserId: this.user.userId, RoleId: roleId });

    this.postUserRoleMapping(userRoleMappingDto);
  }

  closeMediaServer() {
    this._showMediaServer = false;
    this.mediaServerService.selectedFiles = [];
    this.mediaServerModal.hide();
  }

  setUserIcon() {

    var selectedFile: S3ObjectsModel;

    this.mediaServerService.selectedFiles.forEach((file: S3ObjectsModel) => { 
      if (!selectedFile) { 
        const extension = file.Key.substring(file.Key.lastIndexOf('.') + 1);
        switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            selectedFile = file;
            break;
          default:
              this.notificationService.showWarning('Not able to add file ' +
              file.Key.substring(file.Key.lastIndexOf('/') + 1, file.Key.length) +
              '. Images can only be of type png, jpg and jpeg');
              break;
        }
      }
    });

    if (selectedFile) {
      this.createEditUserForm.get('userIconUrl').setValue(this.mediaServerService.getFilePublicUrl(selectedFile));
    }

    this._showMediaServer = false;
    this.mediaServerModal.hide();
  }

  openMediaServerModal() {
    this._showMediaServer = true;
    this.mediaServerModal.show();
  }

  deleteUserIcon() {
    this.createEditUserForm.get('userIconUrl').setValue('');
  }
}
