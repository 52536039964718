export class ExtractModel {
  public FieldIds: number[] = [];
  public AwsSecretName: string = '';
   

  constructor(init: any) {
    this.FieldIds = init.FieldIds;
    this.AwsSecretName = init.AwsSecretName;    
  }  
}
