<app-modal-container [title]="title"
                     [actionButtonName]="'Create'"
                     [buttonValidation]="!createAutomationFormGroup.valid"
                     (action)="createAutomation()">
  <form [formGroup]="createAutomationFormGroup">
    <app-md-input [label]="inputName" formControlName="title"></app-md-input>
    <app-md-select label="Select Folder"
                   formControlName="folderId"
                   [options]="optionList">
    </app-md-select>
  </form>
</app-modal-container>


