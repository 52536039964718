import { Component, Input, OnInit } from '@angular/core';
import { PostsService } from '../../../../_services/social-media/posts/posts.service';
import { PostsStatuesEnum } from '../../../../_models/social-media/posts/posts-statues.enum';
import { ModalDirective } from 'ng-uikit-pro-standard/public_api';
import { PermissionsService } from '../../../../_services/system/Permissions/permissions.service';
import { NetworkTypeEnum, SocialWebsEnum } from '../../../../_models/social-media/posts/network-type-enum';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { PostUpdateStatusAdapter } from '../../../../_models/social-media/posts/post-update-status.adapter';
import { NotificationService } from '../../../../_services/notification.service';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { PostDetails } from '../../../../_models/social-media/posts/post-content.model';
import { postContentColumnsNames } from '../../constants/social-media-constants';
import { Router } from '@angular/router';
import { PermissionsPageTypes } from '../../../../_models/system/permissions/permissions-page-types-model';
import * as moment from 'moment';

@Component({
  selector: 'app-post-list-content',
  templateUrl: './post-list-content.component.html',
  styleUrls: ['./post-list-content.component.scss']
})
export class PostListContentComponent implements OnInit {
  @Input() metrics: PostDetails[];
  @Input() selectedNetworkType;
  @Input() isReport;
  @Input() imageUrl;
  networkType = NetworkTypeEnum;
  public socialPostsPermission: PermissionsPageTypes;
  public postStatus = PostsStatuesEnum;
  public moment = moment;
  public postPausing = false;
  public postUpdating = false;
  public postResuming = false;
  public postApproving = false;
  public postDeleting = false;
  public deletePostId = 0;
  public deletePostNetworkType = 0;
  public showImpressions = false;

  get postNetwork() {
      return postContentColumnsNames[SocialWebsEnum[this.selectedNetworkType]];
  }

  constructor(
    private postsService: PostsService,
    private permissionService: PermissionsService,
    private postUpdateStatusAdapter: PostUpdateStatusAdapter,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const userPermissions = this.permissionService.getPermissionsModel();
    this.socialPostsPermission = userPermissions.Social.Posts;
    this.showImpressions = !(this.selectedNetworkType == this.networkType.Instagram && this.imageUrl.includes(".mp4?"))
  }

  getNetworksId(): number[] {
    return this.metrics.map((detail) => detail.networkDetailId);
  }

  openEditPostModal(post: PostDetails): void {
    const passData = {
      networkType: post.networkType,
      networkId: this.getNetworksId(),
      postDate: post.postDate,
      postText: post.postText,
      postStatus: post.status,
      postId: post.postId,
      networkLink: post.networkLink,
      postLink: post.postLink,
      postLinkId: post.postLinkId,
      attachments: post.postAttachments
    };

    this.router.navigate(['create-post'], { state: passData });
  }

  pausePost(postId: number, networkType: NetworkTypeEnum): void {
    this.postPausing = true;
    const postModel = this.postUpdateStatusAdapter.adapt({
      PostId: postId,
      NetworkType: networkType
    });

    this.postsService.pausePost(postModel).subscribe((result: ExecutionResultDto) => {
      this.postUpdating = true;
      this.notificationService.showSuccess(result.message);
    }, () => {
      this.postResuming = false;
    });
  }

  approvePost(postId: number, networkType: NetworkTypeEnum): void {
    this.postApproving = true;
    const postModel = this.postUpdateStatusAdapter.adapt({
      PostId: postId,
      NetworkType: networkType
    });

    this.postsService.approvePost(postModel).subscribe((result: ExecutionResultDto) => {
      this.postUpdating = true;
      this.notificationService.showSuccess(result.message);
    }, () => {
      this.postResuming = false;
    });
  }

  resumePost(postId: number, networkType: NetworkTypeEnum): void {
    this.postResuming = true;
    const postModel = this.postUpdateStatusAdapter.adapt({
      PostId: postId,
      NetworkType: networkType
    });

    this.postsService.resumePost(postModel).subscribe((result: ExecutionResultDto) => {
      this.postUpdating = true;
      this.postResuming = false;
      this.notificationService.showSuccess(result.message);
    }, () => {
      this.postResuming = false;
    });
  }

  deletePost(confirm: ModalDirective): void {
    this.postDeleting = true;
    const postModel = this.postUpdateStatusAdapter.adapt({
      PostId: this.deletePostId,
      NetworkType: this.deletePostNetworkType
    });

    this.postsService.deletePost(postModel).subscribe((result: ExecutionResultDto) => {
      this.postUpdating = true;

      if (result.executionResult === ExecutionResult.error) {
        this.notificationService.showError(result.message);
      } else {
        this.notificationService.showSuccess(result.message);
      }

      this.postDeleting = false;
      confirm.hide();
    });
  }

  getIconName(post: PostDetails): string {
    return SocialWebsEnum[post.networkType];
  }
}
