import { WebFormPageViewModel } from './web-form-page-view-model';

export class WebFormViewModel {

  public WebFormID: number;
  public UniqueID: number;
  public WebFormTypeID: number;
  public WebFormTypeName: string;
  public ListID: number;
  public WebFormName: string;
  public IsDefault: boolean;
  public ListWebFormPages: Array<WebFormPageViewModel>;
  public URL: string;

  constructor(init: any) {
    this.WebFormID = init.WebFormID;
    this.UniqueID = init.UniqueID;
    this.WebFormTypeID = init.WebFormTypeID;
    this.WebFormTypeName = init.WebFormTypeName;
    this.ListID = init.ListID;
    this.WebFormName = init.WebFormName;
    this.IsDefault = init.IsDefault;
    this.ListWebFormPages = init.ListWebFormPages;
    this.URL = init.URL;
  }
}




