export class EditStepRequestDto {

    id: number;
    currentContactPlanId: number;
    contactPlanIds: number[];
    title: string;
    status: boolean;

  constructor(init: {
    id: number, currentContactPlanId?: number, status?: boolean, title?: string,
    contactPlanIds?: number []
  }) {
        this.id = init.id;
        this.currentContactPlanId = init.currentContactPlanId;
        this.contactPlanIds = init.contactPlanIds;
        this.status = init.status;
        this.title = init.title;
    }
}
