import { NetworkDto } from '../networks/network-dto';

export class NetworkGroupDto {

    public id: number;
    public name: string;
    private _networks: NetworkDto[] = [];

    public get networks(): NetworkDto[] {
      return this._networks;
    }

    public set networks(networkDtos: NetworkDto[]) {
      this._networks = networkDtos;
    }

    constructor(init: any) {
      this.id = init.NetworkGroupID;
      this.name = init.Name;
      this.setNetworks(init.Networks);
    }

      private setNetworks(init: any[]) {
        if (init) {
          init.map(network => this._networks.push(
            new NetworkDto (network)
          ));
        }
      }
    }
