<div class="consent-history-page">
  <div class="consent-history-table-section">
    <div class="d-flex align-items-center">
      <div class="mr-auto">
        <span class="title-table-section">Consent History</span>
      </div>
      <!-- Table Header -->
      <button *ngIf="this._filterHistoryRules.length === 0" mdbBtn
        size="lg" type="button" class="header-button table-button no-box-shadow minus-margin-2"
        (click)="filterHistoryModal.show()">
        <mdb-icon fas size="lg" class="blue-icon" icon="search"></mdb-icon>
      </button>
      <button *ngIf="this._filterHistoryRules.length > 0" mdbBtn
        size="lg" type="button" mdbTooltip="An active filter is set, click here to clear or edit it."
        placement="top" class="header-button table-button no-box-shadow"
        (click)="filterHistoryModal.show()">
      <mdb-icon fas size="lg" class="orange-icon" icon="search"></mdb-icon>
      </button>
      <button *ngIf="!_exporting" [disabled]="!_tableHasRows" mdbBtn
        size="lg" type="button" class="header-button table-button no-box-shadow" (click)="export()">
        <mdb-icon fas size="lg" class="blue-icon" icon="file-download"></mdb-icon>
      </button>
      <button *ngIf="_exporting" disabled mdbBtn
        class="header-button table-button no-box-shadow">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
  </div>
  <hr class="hr-reports">
  <!-- Table -->
  <div *ngIf="_tableHasRows" class="mat-table-container">
    <ngx-datatable class="consent-history-table"
                   [columnMode]="_columnMode.force"
                   [headerHeight]="40"
                   [footerHeight]="0"
                   [rowHeight]="50"
                   [scrollbarV]="false"
                   [scrollbarH]="true"
                   [rows]="_rows"
                   [count]="_pageCount"
                   [offset]="_pageOffset"
                   [limit]="_pageLimit"
                   (sort)="onSort($event)">
        <ngx-datatable-column *ngFor="let col of _columns" [width]="150" [headerClass]="'lists-table-headers'"
            [name]="col.name" [prop]="col.prop">
        </ngx-datatable-column>
    </ngx-datatable>
    <mat-paginator [hidden]="!_tableHasRows"
      [length]="_pageCount"
      [pageSize]="_pageLimit"
      [pageSizeOptions]="_pageSizeOptions"
      [pageIndex]="_pageOffset"
      (page)="paginateTable($event)"
      class="mat-paginator">
    </mat-paginator>
  </div>
  <div *ngIf="!_tableHasRows">
    <span class="label-no-data">No data to display.</span>
  </div>
</div>

<div mdbModal #filterHistoryModal class="modal fade modal-overflow" tabindex="-1" role="dialog"
  aria-labelledby="filterHistoryModal" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="filterHistoryModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="filterHistoryModal">Filter Consent History</h4>
      </div>

      <div class="modal-body">
        <app-query-builder #queryBuilder [(_parentListID)]="this._selectedListId"
          [(_parentQuery)]="this._filterHistoryRules" [_parentSource]="'consent-history'"
          (queryBuilderSave)="interactionsFilterSave($event)"
          [(_parentPageTitle)]="this._pageTitle">
        </app-query-builder>
      </div>
    </div>
  </div>
</div>
