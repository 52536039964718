export class WebFormMessageModel {
  public WebFormPageID: number;
  public WebFormTypeID: number;
  public WebFormPageTypeID: number;
  public WebFormID: number;
  public ListID: number;
  public WebFormPageName: string;
  public OrderIndex: number;
  public ContactID: number;
  public Html: string;
  public MetaDataJson: string;
  public ContentDataJson: string;
  public ClientID: number;
  public Username: string;
  public Token: string;

  constructor(init: any) {
    this.WebFormPageID = init.WebFormPageID;
    this.WebFormTypeID = init.WebFormTypeID;
    this.WebFormPageTypeID = init.WebFormPageTypeID;
    this.WebFormID = init.WebFormID;
    this.ListID = init.ListID;
    this.WebFormPageName = init.WebFormPageName;
    this.OrderIndex = init.OrderIndex;
    this.ContactID = init.ContactID;
    this.Html = init.Html;
    this.MetaDataJson = init.MetaDataJson;
    this.ContentDataJson = init.ContentDataJson;
    this.ClientID = init.ClientID;
    this.Username = init.Username;
    this.Token = init.Token
  }
}

