import { EventTypeEnum } from '../trigger-enums/event-type.enum';

export class EventInfoModel {
  public EventID: number;
  public Guid: number;
  public DisplayName: string;
  public SubjectType: EventTypeEnum;
  public SubjectEventType: number;

  constructor(init: any) {
    this.EventID = init.EventID;
    this.Guid = init.Guid;
    this.DisplayName = init.DisplayName;
    this.SubjectType = init.SubjectType;
    this.SubjectEventType = init.SubjectEventType;
  }
}
