import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageSaveModel } from 'src/app/_models/messaging/messages/email-messages/message-save-model';
import { ExecutionResultDto, ExecutionResultAdapter } from 'src/app/_models/execution-result-model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ContactsMessagePreviewModel } from 'src/app/_models/messaging/messages/email-messages/contacts-message-preview-model';
import { MessageSendDtoRequest } from 'src/app/_models/messaging/messages/message-send-dto-request';

@Injectable({
  providedIn: 'root'
})
export class UnlayerEmailMessageService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'UnlayerMessage';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
    ) { }
  
  getTemplate(templateId: number): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetTemplate/${templateId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }  
  
}
