import { Injectable } from '@angular/core';
import { MessageReportModel } from './message-report-model';

@Injectable({
  providedIn: 'root'
})

export class MessageReportModelAdapter {
  adapt(item: any): MessageReportModel {
    return new MessageReportModel(item);
  }

  adaptArray(init: Array<any>): MessageReportModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new MessageReportModel(table))
      : [new MessageReportModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new MessageReportModel({
      ReportID: 0,
      MessageID: 0,
      MessageName: '',
      Subject: '',
      HtmlContent: '',
      ListID: 0,
      TotalRecipients: 0,
      LastSentDate: '',
      OpenRate: 0,
      Opened: 0,
      BounceRate: 0,
      Bounced: 0,
      UnopenRate: 0,
      Unopened: 0,
      ComplainRate: 0,
      Complained: 0,
      DeliveryRate: 0,
      Delivered: 0,
      FailRate: 0,
      Failed: 0,
      TemporaryBounceRate: 0,
      TemporaryBounced: 0,
      ClickedRate: 0,
      Clicked: 0,
      ProfileUpdated: 0,
      ProfileUpdatedRate: 0,
      Unsubscribed: 0,
      UnsubscribedRate: 0,
    });
  }
}
