import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SocialWebsEnum } from '../../../../_models/social-media/posts/network-type-enum';
import { PostsService } from '../../../../_services/social-media/posts/posts.service';
import { PermissionsModel } from '../../../../_models/system/permissions/permissons-model';
import { PermissionsService } from '../../../../_services/system/Permissions/permissions.service';
import { PostsStatuesEnum } from '../../../../_models/social-media/posts/posts-statues.enum';
import { ItemListContent } from '../../../../_models/social-media/posts/post-content.model';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit {
  @Input() filteredPosts: ItemListContent[];
  @Input() pageLimit: number;
  @Input() selectedNetworkName: string;
  @Input() selectedNetworkType: number;
  @Input() isReport: boolean;

  private userPermissions: PermissionsModel;
  public postStatus = PostsStatuesEnum;
  public network: string;
  public moment = moment;

  constructor(private postsService: PostsService, private permissionService: PermissionsService) { }

  ngOnInit() {
    this.network = SocialWebsEnum[this.selectedNetworkType];
    this.userPermissions = this.permissionService.getPermissionsModel();
  }
}
