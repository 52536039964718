import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientETLsDto } from 'src/app/_models/admin/clients/client-etl-dto';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';

@Injectable()

export class ClientETLsResolver implements Resolve<ClientETLsDto> {
  constructor(private clientManagementService: ClientManagementService,
              private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ClientETLsDto> {
    if (this.clientManagementService._currentClient) {
      return this.clientManagementService.getAllETLs(this.clientManagementService._currentClient.ClientID).pipe(
        catchError(() => {
          this.router.navigate(['/client-management/edit/' + this.clientManagementService._currentClient.ClientID + '/client-settings']);
          return of(null);
        })
      );
    } else {
      this.router.navigate(['/client-management']);
    }
  }
}