import { CanDeactivate, ResolveEnd } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MatDialog } from '@angular/material/dialog';
import { UnsavedChangesModalComponent } from '../views/shared/modals/unsaved-changes/unsaved-changes-modal.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean> | Promise<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  matDialog: MatDialog;
  constructor(injector: Injector) {
    this.matDialog = injector.get(MatDialog);
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> | Promise<boolean> {
    if (component.canDeactivate() == true) {
      return true;
    }
    else if (component.canDeactivate() == false) {
      const dialog = this.matDialog.open(UnsavedChangesModalComponent);
      return new Observable<boolean>((observer) => {
        dialog.afterClosed().subscribe(res => {
          if (res == true) {
            observer.next(res);
            observer.complete();
          }
          else {
            observer.next(false);
            observer.complete();
          }
        });
      });
    }
    else {
      return new Observable<boolean>((observer) => {
        (<Observable<boolean>>component.canDeactivate()).subscribe((result) => {
          if (result) {
            observer.next(result);
            observer.complete();
          }
          else {
            const dialog = this.matDialog.open(UnsavedChangesModalComponent);
            dialog.afterClosed().subscribe(res => {
              if (res == true) {
                observer.next(res);
                observer.complete();
              }
              else {
                observer.next(false);
                observer.complete();
              }
            });
          }
        });
      });
    }
  }
}