<div class="post-page">
  <div class="title-box">
    <span class="header-section">Posts</span>
  </div>
  <div class="post-section">
    <error-alert *ngIf="noDataAvailableError"
                 #errorAlert
                 [errorMessage]="errorMessages.ReportingErrorMessages.NoDataAvailableError">
    </error-alert>
    <span class="title-sub-section">Posts Options</span>
    <hr class="mt-1 mb-0">
    <form [formGroup]="postsSearchForm" autocomplete="off">
      <div class="container-fluid">
        <div class="row mt-2">
          <div class="col-md-3 pl-0">
            <label for="selectedNetworkGroupIds" class="input-label">{{ selectTexts.selectGroup }}</label>
            <mdb-select id="selectedNetworkGroupIds"
                        [placeholder]="'<' + selectTexts.selectGroup + '>'"
                        [options]="networkGroupsDropdown"
                        [multiple]="true"
                        [filterEnabled]="true"
                        [highlightFirst]="false"
                        [visibleOptions]="10"
                        [ngClass]="{'is-invalid': postsSearchForm.get('networkGroups').touched
                                    && (postsSearchForm.get('networkGroups').errors
                                    || postsSearchForm.hasError('networkValidationError'))}"
                        formControlName="networkGroups">
            </mdb-select>
            <div class="invalid-feedback text-left" *ngIf="this.postsSearchForm.get('networkGroups').touched">
              To Date is required!
            </div>
            <div class="invalid-feedback text-left" *ngIf="postsSearchForm.hasError('dateValidationError')
                  && this.postsSearchForm.get('networkGroups').touched">
              To Date must be valid and greater then From Date!
            </div>
          </div>
          <div class="col-md-3">
            <label for="selectedNetworkIds" class="input-label">{{ selectTexts.selectNetworks }}</label>
            <mdb-select id="selectedNetworkIds"
                        [placeholder]="'<' + selectTexts.selectNetworks + '>'"
                        [options]="networksDropdown"
                        [multiple]="true"
                        [filterEnabled]="true"
                        [highlightFirst]="false"
                        [visibleOptions]="10"
                        [ngClass]="{'is-invalid': postsSearchForm.get('networks').touched && (postsSearchForm.get('networks').errors
                        || postsSearchForm.hasError('networkValidationError'))}"
                        formControlName="networks">
            </mdb-select>
            <div class="invalid-feedback text-left" *ngIf="this.postsSearchForm.get('networks').touched"> To Date is
              required!
            </div>
            <div class="invalid-feedback text-left" *ngIf="postsSearchForm.hasError('dateValidationError')
                  && this.postsSearchForm.get('networks').touched"> To Date must be valid and greater then From Date!
            </div>
          </div>
          <div class="col-md-3">
            <label class="input-label">{{ selectTexts.postStatus }}</label>
            <select class="form-control select-input select-folder"
                    id="status"
                    formControlName="status">
              <option value="" selected hidden>
                {{ '<' + selectTexts.selectPost + '>' }}
              </option>
              <option *ngFor="let option of optionsSelect; let i = index" [ngValue]="option.value">
                {{option.label}}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-3 pl-0">
            <div class="date-picker-label-full-width date">
              <label id="dateFromInputLabel" class="input-label" for="DateFromInput">{{ selectTexts.dateFrom }}</label>
              <input id="DateFromInput"
                     (click)="this.pickerIsOpen(true)"
                     (keydown)="this.pickerIsOpen(true)"
                     (blur)="this.pickerIsOpen(false)"
                     [ngClass]="{'is-invalid': postsSearchForm.get('dateFrom').touched
                     && (postsSearchForm.get('dateFrom').errors || postsSearchForm.hasError('dateValidationError'))}"
                     formControlName="dateFrom"
                     class="form-control date-picker"
                     type="datetime-local"
                     [placeholder]="selectTexts.emptyDate"
                     mdbInput>
              <div class="invalid-feedback text-left" *ngIf="postsSearchForm.get('dateFrom').hasError('required')">
                From Date is required!
              </div>
              <div class="invalid-feedback text-left" *ngIf="!postsSearchForm.get('dateFrom').hasError('required')
                  && postsSearchForm.hasError('dateValidationError')"> From Date must be valid and less than To Date!
              </div>
            </div>
          </div>

          <div class="col-md-3 date-picker-box date">
            <label id="dateToInputLabel" class="input-label" for="dateToInput">{{ selectTexts.dateTo }}</label>
            <input id="dateToInput"
                   (click)="this.pickerIsOpen(true)"
                   (blur)="this.pickerIsOpen(false)"
                   [ngClass]="{'is-invalid': postsSearchForm.get('dateTo').touched
                   && (postsSearchForm.get('dateTo').errors || postsSearchForm.hasError('dateValidationError'))}"
                   formControlName="dateTo"
                   class="form-control date-picker"
                   type="datetime-local"
                   [placeholder]="selectTexts.emptyDate"
                   mdbInput>
            <div class="invalid-feedback text-left" *ngIf="postsSearchForm.get('dateTo').hasError('required')">
              To Date is required!
            </div>
            <div class="invalid-feedback text-left" *ngIf="!postsSearchForm.get('dateTo').hasError('required')
                  && postsSearchForm.hasError('dateValidationError')">
              To Date must be valid and greater than From Date!
            </div>
          </div>

          <div class="col-md-6 mt-2 d-flex align-items-end pr-0">
            <div class="ml-auto">
              <button *ngIf="this.userPermissions.Social.Posts.Create"
                      class="btn-create"
                      [routerLink]="['create-post']"
                      type="button">
                Create Post
              </button>
              <button class="btn-create ml-3"
                      type="button" (click)="getPosts()"
                      [disabled]="!postsSearchForm.valid">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <app-posts-view
    *ngIf="posts?.length"
    [posts]="posts"
    [itemsCount]="totalCount"
    [headLabel]="headLabel"
    [isReport]="false"
    (getDataForTable)="getPosts($event)">
  </app-posts-view>
</div>
