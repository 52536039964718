import { PostUpdateStatusModel } from './post-update-status.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PostUpdateStatusAdapter {
  adapt(item: any): PostUpdateStatusModel {
    return new PostUpdateStatusModel(item);
  }
  createEmpty(): PostUpdateStatusModel {
    return new PostUpdateStatusModel({
      PostId: 0,
      NetworkType: 0
    })
  }
}
