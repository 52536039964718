import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VehicleValidationService {

  apiKey = environment.vehicleValidationAPIKey;
  VVUrl = environment.vehicleValidationAPIUrl;

constructor(private http: HttpClient) { }

callVehicleValidatorAPI(jsonReq: any): Observable<any>  {
  return this.http.post(this.VVUrl, jsonReq)
}

}
