import { Injectable } from '@angular/core';
import { MessageCodeDto } from './message-code-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageCodeDtoAdapter {

  adapt(item: any): MessageCodeDto {
    return new MessageCodeDto(item);
  }
}
