export class GetBucketObjectsModel {
  Delimiter: string;
  Prefix: string;
  MaxKeys: number;

  constructor(init: any) {
    this.Delimiter = init.Delimiter,
    this.Prefix = init.Prefix,
    this.MaxKeys = init.MaxKeys
  }
}
