import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CreateEditTriggerComponent } from '../../create-edit-trigger.component';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NotificationService } from 'src/app/_services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { LightListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model.adapter';
import { EmailContactModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/email-contact-model';
import { EmailContactModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/email-contact-model.adapter';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';

@Component({
  selector: 'app-select-contacts',
  templateUrl: './select-contacts.component.html',
  styleUrls: ['./select-contacts.component.scss']
})
export class SelectContactsComponent implements OnInit {
  @ViewChild('contactTable', { static: true }) contactTable: DatatableComponent;
  @Input() notifyContactMessage;
  @Input() selectedContacts;
  @Input() selectedContactIds: Array<any> = [];  

  _listFolderDropdown: Array<any> = [];
  _lightListsDropdown: Array<any> = [];
  _lightLists: Array<LightListModel> = [];

  _rows: { [prop: string]: any }[] = [];
  _allColumns: DataTableColumn[] = [];
  _columns: DataTableColumn[] = [];
  _visibleColumns = [];
  _columnMode = ColumnMode;
  _tableHasRows = false;
  _dateCulture = 'en-GB';

  _selectedListId: number;
  _selectedContactsEmail: Array<EmailContactModel> = [];
  SelectionType = SelectionType;

  // variable to check if a folder or a list dropdown is being changed
  _listSelected = false;

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;

  constructor(
    private createEditTriggerComponent: CreateEditTriggerComponent,
    private contactService: ContactService,
    private notificationService: NotificationService,
    private lightListModelAdapter: LightListModelAdapter,
    private route: ActivatedRoute,
    private emailContactModelAdapter: EmailContactModelAdapter,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this._lightLists = this.lightListModelAdapter.adaptArray(data.lightLists.data);      
      this.populateLightListsDropdown();
    });    

    this.onChanges(); 
  }

  // this._listsSelected check to ensure that when selecting a list
  // the folder it belongs too also selects correctly without wiping
  // the changes made when the folder change event fires

  onChanges() {
    this.notifyContactMessage.get('listFolder').valueChanges.subscribe(val => {
      if (this._listSelected === false) {
        this.populateLightListsDropdown();
        this.notifyContactMessage.get('list').setValue(null);
      }
    });

    this.notifyContactMessage.get('list').valueChanges.subscribe(val => {
      this._listSelected = true;
      this._selectedListId = val;
      if (val > 0) {
        var lightListSelected = this._lightLists.find(l => l.ListId === val);
        this.getContacts();        
        this.notifyContactMessage.get('listFolder').setValue(lightListSelected.FolderId);
      }
      this._listSelected = false;
    });

  }

  populateListFoldersDropdown() {
    this._listFolderDropdown = [];
    this.createEditTriggerComponent._listFolders.forEach((listFolder: ListFolderModel) => {
      this._listFolderDropdown = [... this._listFolderDropdown, {
        value: listFolder.FolderID,
        label: listFolder.FolderName
      }];
    });
  }

  populateLightListsDropdown() {
    this._lightListsDropdown = [];
    var folderID = this.notifyContactMessage.get('listFolder').value;
    if (folderID !== null) {
      this._lightLists.forEach((lightList: LightListModel) => {
        if (lightList.FolderId === folderID) {
          this._lightListsDropdown = [... this._lightListsDropdown, {
            value: lightList.ListId,
            label: lightList.ListName
          }];
        }
      });
    } else {
      this._lightLists.forEach((lightList: LightListModel) => {
        this._lightListsDropdown = [... this._lightListsDropdown, {
          value: lightList.ListId,
          label: lightList.ListName
        }];
      });
    }
  }

  getContacts(pageInfo?: any) {
    const pageNumber = pageInfo ? pageInfo : 1;

    const pagerDtoRequest = new PagerDtoRequest({
      page: pageNumber,
      pageSize: this._pageLimit,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this._selectedListId
    });

    this.contactService.getContacts(getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._pageCount = executionResultDto.data.Total;
        this._pageOffset = pageNumber - 1;

        this._tableHasRows = this._pageCount > 0;

        this._allColumns = this._columns = this._rows = [];

        if (!this._tableHasRows) {
          return;
        }

        this._allColumns = executionResultDto.data.Columns.map(column =>
          new DataTableColumn({
            prop: column.Name,
            name: column.DisplayName
          }));
        this._columns = this._allColumns;
        this._rows = this.getRows(executionResultDto);

        this.highlightSelectedContacts();

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getRows(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            const date = new Date(record[key]);
            row[key] = `${date.toLocaleDateString(this._dateCulture, { day: 'numeric', month: 'numeric', year: 'numeric' })} ${date.toLocaleTimeString()}`;
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        }
      });
      return row;
    });

    return rows;
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  getId(row) {
    return row.ContactID;
  }

  onSelect({ selected }) {
    var contactIds: Array<number> = [];
    this._selectedContactsEmail = [];

    this.selectedContacts.splice(0, this.selectedContacts.length);
    this.selectedContacts.push(...selected);

    this.selectedContacts.forEach(contact => {
      var newContact: EmailContactModel = this.emailContactModelAdapter.createEmpty();
      newContact.ContactID = contact.ContactID;
      newContact.Email = contact.Email;

      this._selectedContactsEmail.push(newContact);

      contactIds.push(contact.ContactID);
    });

    this.notifyContactMessage.get('contacts').setValue(contactIds);
  }

  deselectAll() {
    this.onSelect({ selected: [] });
    this.contactTable.selected = [];
    this.notifyContactMessage.get('contacts').setValue([]);
  }

  // allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    this._pageLimit = parseInt(limit);
    this.getContacts();
  } 

  highlightSelectedContacts() {
    var previousSelectedContacts = this._rows.filter((row) => this.selectedContactIds.some(contactId => row.ContactID === contactId));

    this.selectedContacts.push(...previousSelectedContacts);
  }  

}