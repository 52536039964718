import { Injectable } from '@angular/core';

export class ClientInboxDto {
  ClientId: number;
  TextLocalInboxId: number;
  InboxNumber: number;
  Active: boolean;
  Keyword: string;
  NumMessages: number;
  NumContacts: number;
  InboxName: string;
  NewMessages: number;
  LoadDate: Date;
  id: number;
  Description: string;

  constructor(init: any) {
    this.ClientId = init.ClientId;
    this.TextLocalInboxId = init.TextLocalInboxId;
    this.InboxNumber = init.InboxNumber;
    this.Active = init.Active;
    this.Keyword = init.Keyword;
    this.NumMessages = init.NumMessages;
    this.NumContacts = init.NumContacts;
    this.InboxName = init.InboxName;
    this.NewMessages = init.NewMessages;
    this.LoadDate = init.LoadDate;
    this.id  = init.id;
    this.Description = init.Description;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ClientInboxAdapter {
  adapt(item: any) {
    return new ClientInboxDto(item);
  }
}
