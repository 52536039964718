import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { GetBucketObjectsModel } from 'src/app/_models/media-server/get-bucket-objects-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaServerBucketModel } from 'src/app/_models/media-server/media-server-buckets-model';
import { MediaServerFolderModel } from 'src/app/_models/media-server/media-server-folder-model';
import { S3ObjectsModel } from 'src/app/_models/media-server/S3Models/S3Objects.model';
import { MoveCopyFilesDto } from 'src/app/_models/media-server/move-copy-files-dto';
import { MediaServerValidationRequest } from 'src/app/_models/media-server/media-server-validation-request';

@Injectable({
  providedIn: 'root'
})
export class MediaServerService {
  _baseUrl = environment.apiURL;
  private _selectedFilesValue: Array<S3ObjectsModel> = [];
  private _moveFileDestinationKeyValue: string = '';

  private _selectedFiles: Subject<Array<S3ObjectsModel>> = new Subject<Array<S3ObjectsModel>>();

  public selectedFiles$ = this._selectedFiles.asObservable();

  // Getters
  get selectedFiles() {
    return this._selectedFilesValue;
  }

  get moveFileDestinationKey() {
    return this._moveFileDestinationKeyValue;
  }

  // Setters
  set selectedFiles(files: Array<S3ObjectsModel>) {
    this._selectedFiles.next(files);
    this._selectedFilesValue = files;
  }

  set moveFileDestinationKey(destPath) {
    this._moveFileDestinationKeyValue = destPath;
  }



constructor(private http: HttpClient,
  private executionResultAdapter: ExecutionResultAdapter,
  ) { }

getBucketObjects(model: GetBucketObjectsModel, bucketName: string) : Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + 'MediaServer/GetBucketObjects'+ bucketName, model).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
}

getBuckets() : Observable<ExecutionResultDto> {
  return this.http.get(this._baseUrl + "MediaServer/GetBuckets").pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

createBucket(bucket: MediaServerBucketModel): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + 'MediaServer/CreateBucket', bucket).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

createFolder(folder: MediaServerFolderModel): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/CreateFolder", folder).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

uploadFile(file: FormData): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/UploadFile", file).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

deleteFile(form: FormData): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/Delete", form).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

deleteFolder(form: FormData): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/DeleteFolder", form).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

renameFile(form: FormData): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/Rename", form).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

getAllFolders(bucketName: string): Observable<ExecutionResultDto> {
  return this.http.get(this._baseUrl + "MediaServer/AllFolders/" + bucketName).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

moveCopyFiles(moveCopyDto: MoveCopyFilesDto): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/MoveCopy", moveCopyDto).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

// shared functions

getFileSizeFromURL(mediaServerFileHeader: MediaServerValidationRequest): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/GetFileSizeFromURL", mediaServerFileHeader).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

getVideoDuration(mediaServerFileHeader: MediaServerValidationRequest): Observable<ExecutionResultDto> {
  return this.http.post(this._baseUrl + "MediaServer/GetVideoDuration", mediaServerFileHeader).pipe(map((data: any) =>
  this.executionResultAdapter.adapt(data)));
}

getFileImageIcon(file): string {
  switch (this.getFileExtension(file.FileUrl)) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'heif':
      return file.FileUrl;
    case 'xlsx':
      return "../../../assets/images/Icons/XLSX.png";
    case 'csv':
      return "../../../assets/images/Icons/CSV.png";
    case 'mov':
      return "../../../assets/images/Icons/MOV.png";
    case 'mp4':
      return "../../../assets/images/Icons/MP4.png";
    default:
      return "../../../assets/images/Icons/BLANK.png";
  }
}

getFileName(file: string): string {
  return file.substring(file.lastIndexOf('/') + 1);
}

getFileExtension(file: string) {
  return file.substring(file.lastIndexOf('.') + 1);
}

getFilePublicUrl(file: S3ObjectsModel): string {
  switch (this.getFileExtension(file.Key)) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'heif':
    case 'mp4':
    case 'mkv':
      return "https://"+file.BucketName+".s3.eu-west-2.amazonaws.com/"+file.Key;
    default:
      return "N/A";
  }
}


}
