<div class="network-group-management-page">
  <div class="title-box">
    <span class="header-section">Networks</span>
  </div>
  <div class="network-groups-section">
    <span class="title-sub-section">Network Groups</span>
    <hr class="mt-1 mb-0">
    <form>
      <div class="container-fluid">
        <!-- Grid Row -->
        <div class="row mt-2">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-12 pl-0 mt-2">
                <div class="network-group">
                  <input type="text"
                         id="networkSearch"
                         class="form-control input-style"
                         [(ngModel)]="searchText"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Search..." />
                  <img src="./assets/images/Icons/Search.svg" alt="Searcht" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 d-lg-flex justify-content-end pr-0 mt-2">
            <button *ngIf="userPermissions.Social.NetworkGroups.Create"
                    type="button"
                    class="btn-create-group"
                    (click)="showModalForm()">
              Create Group
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="container-fluid network-list-wrapper m-0 p-0">
    <div class="row p-0">
      <div id="{{'masonry-item-' + networkGroup.id}}" class="network-card-wrapper col-12 col-md-4 col-lg-five col-6"
           *ngFor="let networkGroup of this.filteredNetworkGroups; let i = index">

        <app-network-group-card #appNetworkGroupCard
                                [userPermissions] = "userPermissions"
                                [networkGroup]= "networkGroup"
                                (editNetworkGroupEvent)="showModalForm($event)"
                                (deleteNetworkGroupEvent)="showConfirmDeleteNetworkGroupModal($event)">
        </app-network-group-card>
      </div>
    </div>
  </div>
</div>

<div mdbModal #networkGroupModal="mdb-modal" id="networkGroupModal" class="create-group-modal modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form [formGroup]="networkGroupsForm" (ngSubmit)="createEditNetworkGroup()">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="networkGroupModal.hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="modalLabel">{{modalTitleAction}} Network Group</h4>
        </div>
        <div class="modal-body">

          <input type="hidden"
                 id="networkGroupId"
                 class="form-control"
                 formControlName="networkGroupId"
                 mdbInput>
          <div class="form-group">
            <label class="input-label" for="name">Name</label>
            <input type="text"
                   id="name"
                   [ngClass]="{'is-invalid': networkGroupsForm.get('name').errors
                        && networkGroupsForm.get('name').touched}"
                   class="form-control input-style"
                   formControlName="name"
                   maxlength="50">
            <div class="invalid-feedback text-left">Name required.</div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="selectedNetworkDetailIds" class="input-label">Select Networks</label>
                <mdb-select id="selectedNetworkDetailIds"
                            class="form-control test"
                            formControlName="networks"
                            [options]="networksMultiSelectListItems"
                            [filterEnabled]="true"
                            [multiple]="true"
                            [highlightFirst]="false"
                            [visibleOptions]="10">
                </mdb-select>
                <div *ngIf="!selectedNetworkDetailIds.length && networkGroupsForm.get('networks').touched" class="error text-left">No network selected.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-cancel-modal" aria-label="Close" (click)="networkGroupModal.hide()">
            Cancel
          </button>

          <button type="submit"
                  class="btn-save-modal"
                  [disabled]="!networkGroupsForm.valid || this.submitting"
                  mdbWavesEffect>
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div mdbModal #confirmDeleteNetworkGroupModal="mdb-modal" class="network-group-modal modal fade" id="confirmDeleteNetworkGroupModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel2" aria-hidden="true">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm Action</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="confirmDeleteNetworkGroupModal.hide()">
            <span aria-hidden="true">×</span>
          </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <div class="mb-3 modal-text">
            Are you sure want to delete this group?
          </div>
        </div>
        </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn-cancel-modal" outline="true" data-dismiss="modal"
                (click)="confirmDeleteNetworkGroupModal.hide()">
          Cancel
        </button>
        <button type="button" (click)="deleteNetworkGroup()"
                class="btn-save-modal">
          Save
        </button>
      </div>
    </div>
  </div>
</div>


