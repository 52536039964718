import { Injectable } from '@angular/core';
import { ListFolderDto } from './list-folder-dto';


@Injectable({
  providedIn: 'root'
})

export class ListFolderDtoAdapter {

  adapt(item: any): ListFolderDto {
    return new ListFolderDto(item);
  }

  adaptArray(init: Array<any>): ListFolderDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new ListFolderDto(table))
      : [new ListFolderDto(init[0])];
    } else {
      return [];
    }
  }
  
}
