import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DeleteRestoreModalComponent } from '../../modals/delete-restore/delete-restore-modal.component';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent {
  @Input() isTableModal = false;
  @Input() title: string;
  @Input() buttonValidation: boolean;
  @Input() actionButtonName = 'Save';
  @Input() actionsOn = true;
  @Input() warning = false;

  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  constructor(public dialogRef: MatDialogRef<DeleteRestoreModalComponent>) { }

  actionClick(): void {
    this.action.emit();
  }
}
