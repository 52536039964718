<div class="row m-4">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">

      <!--Card image-->
      <div class="view view-cascade gradient-card-header primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center">
          <h4 class="page-title">Edit User Permissions for <i>{{this?._userFullName}}</i></h4>
      </div>
      <!--/Card image-->

      <section>
        <mdb-accordion [multiple]="true">
          <mdb-accordion-item [collapsed]="true" *ngFor="let section of this._permissionsAllAvailableDto?.sections">
            <mdb-accordion-item-head>{{section.name}}</mdb-accordion-item-head>             
            <mdb-accordion-item-body>              
                <div id="div-{{section.id}}-{{page.id}}" *ngFor="let page of section.pages">    
                    <h6>{{page.name}}</h6>
                    <div class="form-check form-check-inline" *ngFor="let userPermissionTypeDto of this._userPermissionTypeDtos">                        
                        <div [hidden]="userPermissionTypeDto.permissionKey !== this._permissionKeyForView && !this.hasPermission(section.id, page.id, this._permissionKeyForView)" 
                        title="{{userPermissionTypeDto.permissionName}}" id="div-{{section.id}}-{{page.id}}-{{userPermissionTypeDto.permissionKey}}" 
                        *ngIf="this.permissionTypeIsVisible(userPermissionTypeDto.permissionKey, page.permissionKey)">
                            <input type="checkbox" [checked]="this.hasPermission(section.id, page.id, userPermissionTypeDto.permissionKey)" 
                            class="form-check-input" id="checkbox-{{section.id}}-{{page.id}}-{{userPermissionTypeDto.permissionKey}}" 
                            (change)="this.handleCheckBoxChange($event)" value="{{section.id}}-{{page.id}}-{{userPermissionTypeDto.permissionKey}}">
                            <label class="form-check-label" for="checkbox-{{section.id}}-{{page.id}}-{{userPermissionTypeDto.permissionKey}}">{{userPermissionTypeDto.permissionName}}</label> 
                        </div>                                     
                    </div>                      
                </div>            
            </mdb-accordion-item-body>
          </mdb-accordion-item>          
        </mdb-accordion>
      </section>      

      <div class="text-center">
        <button
            type="button"
            mdbBtn
            color="secondary"
            ng-reflect-color="secondary"
            class="waves-light btn btn-secondary rounded"                       
            [routerLink]="['/user-management']"
            mdbWavesEffect>Back
        </button>
      </div>

    </mdb-card>
    <!--/.Card--> 

  </div>
</div>