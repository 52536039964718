<app-block-container [title]="'Uploaded File'">
  <form [formGroup]="uploadOptionsFormGroup" class="d-flex pt-3">
    <div class="text-left">
      <span *ngIf="file">
        Uploaded File: <b>{{ this.file?.name }}</b>
      </span>
      <i mdbTooltip="Select a different file"
         class="fas fa-file-upload pl-1"
         (click)="uploadFile()">
      </i>

      <div *ngIf="!isExistingList">New List Name: <b>{{ newName }}</b>
        <i mdbTooltip="Edit list name"
           class="fas fa-edit pl-1"
           (click)="editName(newName)">
        </i>
      </div>
    </div>

    <div class="pl-4">
      <mdb-checkbox [checked]="true" value="true" formControlName="includeHeaderRow">
        File Includes Header Row
      </mdb-checkbox>

      <mdb-checkbox [checked]="false" value="true" formControlName="compareForDuplicates" (change)="onCompareForDuplicatesChange()">
        Compare File Rows With Existing Contacts Based On Field
      </mdb-checkbox>
    </div>

    <div class="compare-select pl-4"
         [ngClass]="{ 'visible': compareForDuplicatesIsChecked, 'invisible': !compareForDuplicatesIsChecked }">
      <app-md-select formControlName="compareByFieldName"
                     [options]="fieldCompareSelectListOptions"
                     [placeholder]="'<Select>'"
                     [showPlaceholder]="true"
                     [visibleLabel]="false">
      </app-md-select>
    </div>

    <button class="option-btn" (click)="onSaveClick()" [disabled]="!dataTableFormGroupIsValid()">
      <span *ngIf="importingFile" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Save
    </button>
  </form>

  <form id="dataTableFormGroup" [formGroup]="_dataTableFormGroup" class="py-3">
    <div>
      <ngx-datatable
        class="material striped"
        [columnMode]="_columnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="50"
        [scrollbarV]="false"
        [scrollbarH]="true"
        [rows]="_rows"
        [limit]="15"
      >

        <ngx-datatable-column width="10">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <strong>{{ rowIndex === 0 && _includeHeaderRow ? 1 : rowIndex + 1 }}</strong>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="250" *ngFor="let col of _columns; let i = index" [draggable]="false" [headerClass]="'lists-table-headers'" [name]="col.name" [prop]="col.prop">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="mr-5">
                    <span *ngIf="isExistingList; else elseBlock">
                      <mdb-select [mdbTooltip]="this._toolTipText[i]" [visibleOptions]="10" placement="bottom" container="body" [appendToBody]="true" [allowClear]="isExistingList" (click)="onFieldSelectListClick(i)"
                                  (ngModelChange)="onNgModelChange($event, i)" [options]="fieldSelectListOptions" placeholder="Select"
                                  formControlName="fieldSelectList{{i}}" id="fieldSelectList{{i}}"></mdb-select>
                    </span>
              <ng-template #elseBlock>
                      <span>
                        <mdb-select [mdbTooltip]="this._toolTipText[i]" placement="bottom" [visibleOptions]="10" container="body" [appendToBody]="true" (click)="onFieldSelectListClick(i)"
                                    [options]="fieldSelectListOptions" formControlName="fieldSelectList{{i}}" id="fieldSelectList{{i}}"></mdb-select>
                      </span>
              </ng-template>



              <mdb-icon mdbTooltip="Add field" placement="bottom" container="body" [ngClass]="{'icon-visible': !col.editIconVisible,  'icon-invisible': col.editIconVisible }" class="mr-1"
                        fas icon="plus" (click)="onAddEditClick(true, i)"></mdb-icon>
              <mdb-icon mdbTooltip="Edit" placement="bottom" container="body" [ngClass]="{'icon-visible': col.editIconVisible, 'icon-invisible': !col.editIconVisible }" class="mr-1"
                        fas icon="edit" (click)="onAddEditClick(false, i)"></mdb-icon>
              <mdb-icon mdbTooltip="Included (click to exclude)" container="body" placement="bottom" [ngClass]="{'icon-visible': col.includeIconVisible, 'icon-invisible': !col.includeIconVisible }"
                        fas icon="check-circle" class="include mr-1" (click)="onIncludeExcludeClick(i, false)"></mdb-icon>
              <mdb-icon mdbTooltip="Excluded (click to include)" container="body" placement="bottom" [ngClass]="{'icon-visible': !col.includeIconVisible, 'icon-invisible': col.includeIconVisible }"
                        fas icon="times-circle" class="exclude mr-1" (click)="onIncludeExcludeClick(i, true)"></mdb-icon>
              <mdb-icon [mdbTooltip]="col.addOptions ? 'Options added (click to remove)' : 'Options removed (click to add)'" container="body" placement="bottom"
                        fas icon="list-alt" (click)="onAddRemoveOptionsClick(col.prop, i)"

                        [ngClass]="{'icon-visible include': col.addOptionsIconVisible && col.addOptions,
                                'icon-visible exclude': col.addOptionsIconVisible && !col.addOptions,
                                'icon-invisible': !col.addOptionsIconVisible && !col.addOptions }"


              ></mdb-icon>

            </div>
          </ng-template>

          <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>

        </ngx-datatable-column>

        <!-- Custom table footer -->
        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="_pageCount" let-pageSize="_pageLimit"
                       let-curPage="_currentPage" let-offset="_pageOffset">
            <div class="table-custom-footer">
              <div>
                Rows: {{ _pageCount }}
              </div>
              <select (change)="onLimitChange($event.target.value)" class="table-page-limit-select">
                <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                        [selected]="option.value == _pageLimit">
                  {{option.value}} per page
                </option>
              </select>
              <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                               [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                               [pagerNextIcon]="'datatable-icon-skip'"
                               [page]="_currentPage"
                               [size]="_pageLimit"
                               [count]="_pageCount"
                               [hidden]="!((_pageCount / _pageLimit) > 1)"
                               (change)="paginateTable(null, $event.page)">
              </datatable-pager>
            </div>
          </ng-template>
        </ngx-datatable-footer>

      </ngx-datatable>

    </div>




    <section>


      <div class="container-fluid">
        <div class="row">

        </div>
      </div>
    </section>
  </form>
</app-block-container>




  <div mdbModal #addContactFieldModal="mdbModal" class="modal fade right" id="addContactFieldModal" tabindex="-1" role="dialog" aria-labelledby="addContactFieldModal"
      aria-hidden="true" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form [formGroup]="_addContactFieldFormGroup" (ngSubmit)="onAddContactFieldFormSubmit()">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="addContactFieldModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="addContactFieldModalLabel">{{_editMode}} Contact Field</h4>
            </div>
            <div class="modal-body">
              <div class="form-row">
                <div class="col">
                  <div class="md-form">
                    <input type="text" id="newFieldName" class="form-control" formControlName="newFieldName" mdbInput>
                    <label for="newFieldName">Name</label>
                    <mdb-error *ngIf="_addContactFieldFormGroup.get('newFieldName').invalid
                    && (_addContactFieldFormGroup.get('newFieldName').dirty || _addContactFieldFormGroup.get('newFieldName').touched)">Enter field name</mdb-error>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="md-form remove-top-margin mb-4">
                    <mdb-select [options]="_fieldTypeSelectListOptions" id="fieldTypes" [visibleOptions]="10" formControlName="fieldTypes" placeholder="Select"></mdb-select>
                    <label for="fieldTypes">Field Type</label>
                    <mdb-error *ngIf="_addContactFieldFormGroup.get('fieldTypes').invalid
                    && (_addContactFieldFormGroup.get('fieldTypes').dirty || _addContactFieldFormGroup.get('fieldTypes').touched)">Select Field Type</mdb-error>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="md-form remove-top-margin mb-4">
                    <mdb-select [options]="_fieldControlTypeSelectListOptions" [visibleOptions]="10" id="fieldControlTypes" formControlName="fieldControlTypes" placeholder="Select"></mdb-select>
                    <label for="fieldControlTypes">Field Control Type</label>
                    <mdb-error *ngIf="_addContactFieldFormGroup.get('fieldControlTypes').invalid
                    && (_addContactFieldFormGroup.get('fieldControlTypes').dirty || _addContactFieldFormGroup.get('fieldControlTypes').touched)">Select Field Control Type</mdb-error>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="addContactFieldModal.hide()" mdbWavesEffect>Close</button>
              <button mdbBtn type="submit" color="primary" [disabled]="_addContactFieldFormGroup.invalid">
                Save
              </button>
            </div>
          </form>
        </div>
    </div>
  </div>

  <div mdbModal #confirmAdditionMandatoryFields="mdb-modal" class="modal fade" id="confirmDeactivation" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true">
 <div class="modal-dialog modal-notify modal-warning" role="document">
     <div class="modal-content">
         <div class="modal-header">
             <p class="heading lead">Missing Mandatory Fields</p>
             <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                     (click)="confirmAdditionMandatoryFields.hide()">
                 <span aria-hidden="true" class="white-text">×</span>
             </button>
         </div>
         <div class="modal-body">
              <div class="text-center">
                 <mdb-icon fas icon="question-circle" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
              <div>
              <div class="text-left">
                 <p>You have not included the field{{_missingOrExcludedMandatoryFields.length > 1 ? "s" : ""}}:</p>
                 <ul class="ml-3">
                   <li *ngFor="let field of _missingOrExcludedMandatoryFields">
                     <strong>{{field}}</strong>
                    </li>
                 </ul>
                 <p>{{_missingOrExcludedMandatoryFields.length > 1 ? "These" : "This"}} field{{_missingOrExcludedMandatoryFields.length > 1 ? "s" : ""}} will be added automatically.</p>
                 <p>Do you want to proceed?</p>
             </div>
         </div>
         <div class="modal-footer justify-content-center">
             <a type="button" mdbBtn color="primary" class="waves-light rounded text-white" (click)="onConfirmAdditionMandatoryFieldsClick(); confirmAdditionMandatoryFields.hide()" mdbWavesEffect>Yes, I am sure
             </a>
             <a type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
                (click)="confirmAdditionMandatoryFields.hide()" mdbWavesEffect>No, thanks</a>
         </div>
     </div>
 </div>
      </div>
 </div>
  </div>


