import { Injectable } from '@angular/core';
import { SMSMessageRunReportModel } from './sms-message-run-report-model';

@Injectable({
  providedIn: 'root'
})

export class SMSMessageRunReportModelAdapter {
  adapt(item: any): SMSMessageRunReportModel {
    return new SMSMessageRunReportModel(item);
  }

  adaptArray(init: Array<any>): SMSMessageRunReportModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new SMSMessageRunReportModel(table))
      : [new SMSMessageRunReportModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new SMSMessageRunReportModel({
      ReportID: 0,
      ListID: 0,
      TemplateID: 0,
      MessageName: '',
      MessageType: null,
      MessageSourceName: '',
      MessageSourceCssClass: '',
      SentBy: '',
      FolderName: '',
      FolderID: 0,
      TotalDelivered: 0,
      DeliveredRate: 0,
      TotalBounces: 0,
      BounceRate: 0,
      SendDate: null,
      TotalRecipients: 0
    });
  }
}
