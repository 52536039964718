import { Injectable } from '@angular/core';
import { AuthService } from '../_services/admin/auth/auth.service';
import { Router, CanActivate } from '@angular/router';
import { NotificationService } from '../_services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginActivate implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router,
              private notificationService: NotificationService) {}

  canActivate(): boolean {
    if (this.authService.loggedIn()) {
      this.router.navigate(['dashboard']);      
    }
    else {
      this.notificationService.showInfo('You are unable to access this page when logged in.');
    }
    return true;
  }
}
