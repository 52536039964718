import { PermissionsPageTypes, PermissionsPageTypesAdapter } from './permissions-page-types-model'
import { Section, Page } from '../../admin/users/permissions-all-available-model';
import { Injectable } from '@angular/core';
import { PermissionsToolsPagesEnum } from './permissions-tools-pages.enum';

export class PermissionsToolsPages {
  Tools : PermissionsPageTypes;
  VehicleValidation : PermissionsPageTypes;

  private _permissionsPageTypesAdapter : PermissionsPageTypesAdapter = new PermissionsPageTypesAdapter();


  constructor(section: Section) {
    section.pages.forEach((Page: Page) => {
      switch (Page.id) {
        case PermissionsToolsPagesEnum.Tools:
          this.Tools = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsToolsPagesEnum.VehicleValidation:
          this.VehicleValidation = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        default:
          console.warn("Unknown permission with Page name: " + Page.name);
          break;
      }
    });

  }
}


@Injectable({
  providedIn: 'root'
})

export class PermissionsToolsPagesAdapter {
  adapt(item: Section): PermissionsToolsPages {
    return new PermissionsToolsPages(item);
  }
}
