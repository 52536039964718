import { ApiIntegrationEndpointModel } from "./api-integration-endpoint-model";
import { ApiIntegrationParameterModel } from "./api-integration-parameter-model";

export class ApiIntegrationModel {

    id: number;
    name: string;
    displayName: string;
    description: string;
    queueName: string;
    endpointNames: ApiIntegrationEndpointModel[]
    clientParameters: ApiIntegrationParameterModel[]

    constructor(init: { id: number, name: string, displayName: string, description: string, queueName: string,
         endpointNames: ApiIntegrationEndpointModel[],  clientParameters: ApiIntegrationParameterModel[]}) {
        this.id = init.id;
        this.name = init.name;
        this.description = init.description;
        this.queueName = init.queueName;
        this.endpointNames = init.endpointNames;
        this.displayName = init.displayName;
        this.clientParameters = init.clientParameters;
    }
}