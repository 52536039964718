export class MediaServerFolderModel {
  FolderName: string;
  BucketName: string;

  constructor(init: any) {
    this.FolderName = init.FolderName;
    this.BucketName = init.BucketName;

  }
}
