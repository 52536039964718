import { Component, OnInit, HostListener, ViewChild, ElementRef, Injector } from '@angular/core';
import { MessageSaveModel } from 'src/app/_models/messaging/messages/email-messages/message-save-model';
import { MessageSaveModelAdapter } from 'src/app/_models/messaging/messages/email-messages/message-save-model.adapter';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageFolderService } from 'src/app/_services/messaging/messages/message-folder.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { EmailEditorComponent } from 'angular-email-editor';
import { UnlayerEmailMessageService } from 'src/app/_services/messaging/messages/email-messages/unlayer-email-message.service';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { AllFoldersAllListsAllFieldsModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/all-folders-all-lists-all-fields-model-adapter';
import { AllFoldersAllListsAllFieldsModel } from 'src/app/_models/messaging/lists-and-contacts/lists/all-folders-all-lists-all-fields-model';
import { QueryBuilderComponent } from 'src/app/views/shared/query-builder/query-builder.component';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { MediaServerBucketsModelAdapter } from 'src/app/_models/media-server/media-server-buckets-model.adapter';
import { MediaServerService } from 'src/app/_services/media-server/media-server.service'
import { S3ObjectsModel } from 'src/app/_models/media-server/S3Models/S3Objects.model';
import { MediaServerBucketModel } from 'src/app/_models/media-server/media-server-buckets-model';
import { GetBucketObjectsModel } from 'src/app/_models/media-server/get-bucket-objects-model';
import { GetBucketObjectsModelAdapter } from 'src/app/_models/media-server/get-bucket-objects-model.adapter';
import { ListObjectsV2ResponseModelAdapter } from 'src/app/_models/media-server/S3Models/ListObjectsV2Response.model.adapter';
import { ListObjectsV2ResponseModel } from 'src/app/_models/media-server/S3Models/ListObjectsV2Response.model';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/_guards/pending-changes-guard';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { LastActiveService } from 'src/app/_services/system/last-activity-service';
import { UnsavedChangesModalComponent } from 'src/app/views/shared/modals/unsaved-changes/unsaved-changes-modal.component';
import { InactivityWarningModalComponent } from 'src/app/views/shared/modals/unsaved-changes/inactivity-warning-modal.component';

@Component({
  selector: 'app-email-builder-unlayer',
  templateUrl: './email-builder-unlayer.component.html',
  styleUrls: ['./email-builder-unlayer.component.scss']
})
export class EmailBuilderUnlayerComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild('queryBuilder', { static: true }) queryBuilder: QueryBuilderComponent;
  @ViewChild('saveMessageModal', { static: true }) saveMessageModal: ModalDirective;
  @ViewChild('mediaServerModal', { static: false }) mediaServerModal: ModalDirective;
  @ViewChild('uploadFileModal', { static: true }) uploadFileModal: ModalDirective;
  @ViewChild(EmailEditorComponent) private unlayer: EmailEditorComponent;
    
  _allFoldersAllListsAllFields: AllFoldersAllListsAllFieldsModel[] = [];
  _showMediaServer: boolean = true;
  _unlayerImageSelectionDone: any;
  _bucketsOptions: Array<any> = [];
  _buckets: Array<MediaServerBucketModel> = [];
  _bucketNameValue: string = '';
  _folder: string = "";
  _getBucketOptionsModel: GetBucketObjectsModel;
  _files: ListObjectsV2ResponseModel;
  _loading: boolean = false;

  _currentImageToUpload: any;
  _unlayerFuncs: any;
  _unlayerUploadDone: any;

  _uploadingFileName: string = '';
  _configuratorFormGroup: UntypedFormGroup = new UntypedFormGroup({
    folders: new UntypedFormControl(''),
    lists: new UntypedFormControl('')    
  }); 
  
  _folderSelectOpen: boolean = false;
  _hasMessageSent = false;
 
  _listFolders: SelectListOption[] = [];
  _lists: AllFoldersAllListsAllFieldsModel[] = [];
  _listsByFolderId: SelectListOption[] = []; 

  // Model Variables.
  _message: MessageSaveModel;
  _messageFolders: Array<FolderDto> = [];

  // Boolean Variables for states of elements & to prevent actions from happening.
  _newMessageState = true;

  _pageTitle = 'unlayerEmailEditor';  

  // Simple Variables.
  _messageFoldersDropdown: Array<any> = [];
  _messageID: number;
  _mergeTags = {}; 
  
   // Save Form
   _saveMessageForm = new UntypedFormGroup({
    messageName: new UntypedFormControl('', Validators.required),
    emailSubject: new UntypedFormControl('', Validators.required),
    messageFolder: new UntypedFormControl('', Validators.required),
    fromName: new UntypedFormControl('', Validators.required),
    fromAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    replyToAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  _saving: boolean = false;
  _selectedListId = 0;
  _sendAfterSave: boolean;
  _submitButtonText: string; 
  _uploading: boolean = false;
  lastActivityDate: Date;
  activityCheckInterval: any;
  matDialog: MatDialog;
  dialogRef: MatDialogRef<InactivityWarningModalComponent>;
  _stayOnPage: boolean = true;
  _mergeTagsValues: any[] = [];


  constructor(
    private messageSaveModelAdapter: MessageSaveModelAdapter,
    private messageFolderService: MessageFolderService,
    private messageFolderDtoAdapter: FolderDtoAdapter,
    private emailMessagesService: EmailMessagesService,
    private unlayerEmailMessageService: UnlayerEmailMessageService,
    private notificationService: NotificationService,
    private listService: ListsService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private allFoldersAllListsAllFieldsModelAdapter: AllFoldersAllListsAllFieldsModelAdapter,
    private router: Router,
    private mediaServerService: MediaServerService,
    private mediaServiceBucketsAdapter: MediaServerBucketsModelAdapter,
    private getBucketObjectsModelAdapter: GetBucketObjectsModelAdapter,
    private mediaServerBucketsAdapter: MediaServerBucketsModelAdapter,
    private listObjectV2ResponseModelAdapter: ListObjectsV2ResponseModelAdapter,
    private lastActiveService: LastActiveService,
    injector: Injector    
  ) {
    this.matDialog = injector.get(MatDialog);
   }   
  
  @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean>{
      return new Observable<boolean>((observer) => {
        this.unlayer.editor.exportHtml((data) => {
          observer.next(this._message.HtmlContent == data.html
            && (!this._saveMessageForm.dirty || !this._stayOnPage));
          observer.complete();
        });
      });
    }

  
  @HostListener('window:showNotification', ['$event.detail']) showNotification(event) {     
    event.isError ? this.notificationService.showError(event.message): this.notificationService.showSuccess(event.message);    
  } 


  ngOnInit() {    
    this.getBuckets();
    this._message = this.messageSaveModelAdapter.createEmpty();   

    this.activatedRoute.params.subscribe( params => {        
        this._messageID = params['messageID'];            
      } 
    ); 
    if (this._messageID && this._messageID > 0) {
        this._newMessageState = false;
        this.emailMessagesService.getMessage(this._messageID).subscribe( async result => {
          this._message = this.messageSaveModelAdapter.adapt(result.data);
        }, error => {
          console.log(error);
        })
    }

    this.setConfigValues();
    this.getAllFoldersAllListsAllFields().then(() => this.populateMergeTags());
    this.getMessageFolders();

         
    this.lastActiveService.lastActive$.subscribe(lastActivityDate=>
    {
      this.lastActivityDate = lastActivityDate;
    });
      
      
    this.activityCheckInterval = setInterval(() => {
      var now = new Date();
      if(this.lastActivityDate < new Date(now.getTime() - (15 * 60 * 1000))){
        if(this.dialogRef == null || this.dialogRef.getState() !== MatDialogState.OPEN){
          this.dialogRef = this.matDialog.open(InactivityWarningModalComponent, { id:'inactivity-warning-modal' });
        } 
      }                 
    }, 60000);

    this.queryBuilder.populateQueryFilterList(this._pageTitle, "");
  }

  ngOnDestroy() {    
   clearInterval(this.activityCheckInterval);                         
  }

   editorLoaded() {   

    const serviceCall = this._newMessageState ? 
      this.unlayerEmailMessageService.getTemplate(149346) : 
      this.emailMessagesService.getMessage(this._messageID); 
    
      serviceCall.subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {       
            
        var data = this._newMessageState ? JSON.parse(executionResultDto.data).data.design : JSON.parse(executionResultDto.data.EmailContent);

        this.unlayer.loadDesign(data);
        setTimeout(()=>{
          this.exportDefaultHtml().subscribe(res=>{});
        }, 1000);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }  

  get _bucketName() {
    return this._bucketNameValue;
  }

  set _bucketName(value: string) {
    this._bucketNameValue = value;
    this._folder = '';
    this.getBucketObjects();
  } 

  getBucketObjects() {
    this._loading = true;
    this._getBucketOptionsModel = this.getBucketObjectsModelAdapter.adapt({
      Delimiter: '/',
      Prefix: this._folder,
      MaxKeys: 1000
    })

    this.mediaServerService.getBucketObjects(this._getBucketOptionsModel, '/' + this._bucketName).subscribe((result) => {

      this._files = this.listObjectV2ResponseModelAdapter.adapt(result.data.s3);
      this._files.S3Objects = this._files.S3Objects.filter((file: S3ObjectsModel) => file.Key !== this._folder);

      this._loading = false;
    });
  }

  getBuckets() {
    this.mediaServerService.getBuckets().subscribe((result: ExecutionResultDto) => {
      this._buckets = this.mediaServerBucketsAdapter.adaptArray(result.data);

      this._bucketsOptions = [];
      var selectSet: boolean = false;
      this._buckets.forEach((bucket: MediaServerBucketModel, index) => {
        if(this._buckets.length === 1){
          this._bucketsOptions = [... this._bucketsOptions, {
            value: bucket.BucketName,
            label: bucket.BucketFriendlyName,
            selected: true
          }]

          this._bucketName = bucket.BucketName;
        } else if(!bucket.ShowAllFolders && !selectSet) {
          this._bucketsOptions = [... this._bucketsOptions, {
            value: bucket.BucketName,
            label: bucket.BucketFriendlyName,
            selected: true
          }]
          this._bucketName = bucket.BucketName;
          selectSet = true;
        } else {
          this._bucketsOptions = [... this._bucketsOptions, {
            value: bucket.BucketName,
            label: bucket.BucketFriendlyName
          }]
        }


      });
    })
  }

  showFolderBackButton() {
    if((this._folder.match(/\//g) || []).length <= 0) {
      return false;
    }
    return true;
  }

  folderClicked(folder: string) {
    this._folder = folder;
    this.getBucketObjects();
  }

  folderBackClicked() {
    if((this._folder.match(/\//g) || []).length <= 1) {
      this._folder = '';
    }else {
      var split = this._folder.split('/');
      split.pop();
      split.pop();
      this._folder = split.join('/') + '/';
    }
    this.getBucketObjects();
  }

  getFolderName(folder: string) {
    if(folder.lastIndexOf('/') === -1) return folder;
    var secondLastIndex = folder.lastIndexOf('/', folder.lastIndexOf('/')-1)
    return folder.substring(secondLastIndex+1, folder.length-1)
  }

  uploadNewImage() {
    this._uploading = true;

    var configValues = this._unlayerFuncs.getConfigValues();     

      var bucketName = this._bucketName;  
      var apiUrl = configValues.apiUrl;
      var webMethod = 'MediaServer/UnlayerUploadFile';
      var url = apiUrl + webMethod;     

      this._uploadingFileName = this._currentImageToUpload.attachments[0].name;
      var data = new FormData();
      data.append('bucketName', bucketName);
      data.append('key', `${this._folder}${this._uploadingFileName}`);
      data.append('file', this._currentImageToUpload.attachments[0])

      var event = new CustomEvent('showNotification', {
        detail: {
            isError: false,
            message: ''                  
        }
      })
    
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Username': configValues.username,
          'ClientId': configValues.clientId,
          'Token': configValues.token

        },
        body: data
      }).then(response => {
        // Make sure the response was valid
        if (response.status >= 200 && response.status < 300) {
          return response
        } else {          
          response.json().then(json => {             
            event.detail.isError = true;
            event.detail.message = json.Message;
            window.dispatchEvent(event);
            return
          })          
        }
        this.closeUploadFileModal();
      }).then(data => {
        // Pass the URL back to Unlayer to mark this upload as completed
        if (!data) return;
        
        data.json().then(json => {
          this._unlayerUploadDone({ progress: 100, url: json.Data });
          event.detail.isError = false;
          event.detail.message = json.Message;
          window.dispatchEvent(event);
          this.closeUploadFileModal();
        }) 
      })
  }

  closeUploadFileModal() {
    this._uploading = false;
    this.uploadFileModal.hide();
  }
  openMediaServerModal() {
    this._showMediaServer = true;
    this.mediaServerModal.show();
  }

  closeMediaServer() {
    this._showMediaServer = false;
    this.mediaServerService.selectedFiles = [];
    this.mediaServerModal.hide();
  }

  setClientImageUrl() {

    var selectedFile: S3ObjectsModel;

    this.mediaServerService.selectedFiles.forEach((file: S3ObjectsModel) => { 
      if (!selectedFile) {
        const extension = file.Key.substring(file.Key.lastIndexOf('.') + 1);
        switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
            selectedFile = file;
            break;
          default:
              this.notificationService.showWarning('Not able to add file ' +
              file.Key.substring(file.Key.lastIndexOf('/') + 1, file.Key.length) +
              '. Images can only be of type png, jpg, jpeg and gif');
              break;
        }
      }
    });

    if (selectedFile) {
      var url = this.mediaServerService.getFilePublicUrl(selectedFile);
      this._unlayerImageSelectionDone({url: url});
    }   
    
    this.closeMediaServer();
  }

  editorReady(unlayerFuncs) {
    
    const self = this;

    this.unlayer.editor.setLinkTypes([
      {
        name: 'link_with_title',
        label: 'Link With Title',
        attrs: {
          href: '{{url}}?title={{title}}',
          target: '_blank'
        },
        fields: [
          {
            name: 'url',
            label: 'URL' 
          },
          {
            name: 'title',
            label: 'Title',
            defaultValue: ''
          }                 
        ]
      },
      {
        name: 'send_email',
        label: 'Send Email',
        attrs: {
          href: 'mailto:{{mailTo}}?subject={{subject}}&body={{body}}',
          target: '_blank'          
        },
        fields: [
          {
            name: 'mailTo',
            label: 'Mail To',
            defaultValue: '',
            inputType: 'text',
            placeholderText: null,
          },
          {
            name: 'subject',
            label: 'Subject'            
          },
          {
            name: 'body',
            label: 'Body',
            defaultValue: '',
            inputType: 'textarea',        
          }
        ]
      },
      {
        name: 'call_phone_number',
        label: 'Call Phone Number',
        attrs: {
          href: 'tel:{{phone}}',
          target: '_blank'
        },
        fields: [
          {
            name: 'phone',
            label: 'Phone',
            defaultValue: '',
            inputType: 'number',
            placeholderText: 'Action not tracked',
          }
        ]
      },
      {
        name: 'default',
        label: 'Default - No Action',
        fields: []
      },
      {
        name: 'phone',
        enabled: false
      },
      {
        name: 'email',
        enabled: false
      },      
      {
        name: 'web',
        enabled: false
      },
      {
        name: 'sms',
        enabled: false
      }
    ]);
 
     this.unlayer.editor.registerCallback('selectImage', function (data, done) {
       self.openMediaServerModal();
       self._unlayerImageSelectionDone = done;
     })

    this.unlayer.editor.registerCallback('image', function(file, done) { 
      self.uploadFileModal.show();    

      self._currentImageToUpload = file;
      self._unlayerFuncs = unlayerFuncs;
      self._unlayerUploadDone = done;  
    })

    this.unlayer.editor.registerCallback('displayCondition', function (data, done) {        

      function updateDisplayCondition() {

        var displayCondition = unlayerFuncs.getSelectedDisplayCondtion();                       
        
        done(displayCondition) 

        queryBuilderSaveBtn.removeEventListener('click', updateDisplayCondition);
      }
      
      if (!data.id) {
        data = unlayerFuncs.getEmptyDisplayCondition();
        data.id = 'xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx'.replace(/x/g, unlayerFuncs.randomDigit);        
      }

      unlayerFuncs.setSelectedId(data.id); 
      unlayerFuncs.setDisplayConditions(data);
      document.getElementById('selectedId').dispatchEvent(new Event('change'));        
      
      var queryBuilderSaveBtn = document.getElementById('queryBuilderSaveBtn');
      queryBuilderSaveBtn.addEventListener('click', updateDisplayCondition);      
      
      document.getElementById('queryBuilderModal').classList.add('show');
      document.getElementById('queryBuilderModal').style.display = 'block';      
      
    });    

    this.unlayer.editor.registerCallback('previewHtml', function (params, done) {

      var newHtml = params.html;
      var urls = newHtml.match(/(https?:\/\/[^\s]+)/g);     

      urls.forEach(function(url) {
        if (url.indexOf("?title=") !== -1) {
          var splittedUrl = url.split('?');
          if (splittedUrl.length > 2) {
            var newUrl = url.replace(/\?title/g, "&title");
            newHtml = newHtml.replace(url, newUrl);
          }
        }
      });

      done({
        html: newHtml
      });
    });

    this.unlayer.editor.setBodyValues({
      contentWidth: "600px"
    });
  }

  exportHtml() { 
    return new Promise<void>((resolve) => {
      this.unlayer.editor.exportHtml((data) => {        
        this._message.HtmlContent = data.html;                
        resolve();     
      });
    });          
  }

  folderSelectClosed() {
    this._folderSelectOpen = false;
  }

  folderSelectOpened() {
    this._folderSelectOpen = true;
  }  

  getAllFoldersAllListsAllFields() { 
    return new Promise<void>((resolve) => {
      this.listService.getAllFoldersAllListsAllFields().subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) { 
          this._allFoldersAllListsAllFields = executionResultDto.data.map(data => this.allFoldersAllListsAllFieldsModelAdapter.adapt(data));

          this._listFolders = this._allFoldersAllListsAllFields.filter((
            (folderListField, i, arr) => arr.findIndex(t => t.folderId === folderListField.folderId) === i)
          ).map(x => new SelectListOption(x.folderId, x.folderName));

          this._lists = this._allFoldersAllListsAllFields.filter(
            (thing, i, arr) => arr.findIndex(t => t.listId === thing.listId) === i
          );
                    
        } 
        resolve();               
      });       
    });    
  }   

  exportDefaultHtml(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.unlayer.editor.exportHtml((data) => {
        this._message.HtmlContent = data.html;
        observer.next(true);
        observer.complete();
      });
    });
  }

  getImageFolderName() {

    return new Promise<string>((resolve) => {
      this.mediaServerService.getBuckets().subscribe((result: ExecutionResultDto) => {
        const buckets = this.mediaServiceBucketsAdapter.adaptArray(result.data);
        const imageFolderName = buckets.some(x => x.BucketName !== "voiceboxglobaldebug") ? buckets.find(x => x.BucketName !== "voiceboxglobaldebug").BucketName : ''; 
        resolve(imageFolderName);     
      });
    }); 
  }

  getMergeTagsByFolderId(folderId: number){

    var mergeTags = {};

    this._allFoldersAllListsAllFields.filter(x => x.folderId === folderId).forEach(list => {  
      var folderTagName = list.listName.replace(/ /g, '') + list.listId
      mergeTags[folderTagName] = {
        name: list.listName,
        mergeTags: this.getMergeTagsByListId(list.listId)
      }
    });

    return mergeTags;    
  }

  getMergeTagsByListId(listId: number){

    var mergeTags = {};

    this._allFoldersAllListsAllFields.filter(x => x.listId === listId && x.isWildcard).forEach(wildcard => {
      const listTagName = wildcard.displayName.replace(/ /g, '');
      mergeTags[listTagName] = { 
          name: wildcard.displayName,
          value: wildcard.wildcard
        } 
    });

    return mergeTags;    
  }

  getMessageFolders() {
    this.messageFolderService.getAll().subscribe(result => {
      this._messageFolders = this.messageFolderDtoAdapter.adaptArray(result.data);
    });
  }

  getOptions() {
    return {      
     /* mergeTags: this._mergeTags,      */
      features: {
        stockImages: {
          enabled: false
        },
        userUploads: false,
        textEditor: {
          inlineFontControls: true
        }
      },
      fonts: {
        showDefaultFonts: true,
        customFonts: [
          {
            label: "Arial Narrow",
            value: "'Arial Narrow', Arial, sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Arial+Narrow:400,700"
          },
          {
            label: "Porsche",
            value: "'Arial Narrow', Arial, sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Arial+Narrow:400,700"
          }
        ]
      },
      tools: {
        video: {
          enabled: false
        }
      }
    };
  } 

  getUnlayerFuncs() {

    return { 

      getConfigValues() {
        return JSON.parse(document.getElementById('configValues').getAttribute('value'));
      },      
      
      getDisplayCondtions() {
        var value = document.getElementById('displayConditions').getAttribute('value');          
        return value ? JSON.parse(value) : [];                                  
      }, 

      getSelectedDisplayCondtion() { 
        return this.getDisplayCondtions().find(x => x.id === this.getSelectedId());                             
      }, 
      
      getSelectedId() {
        return document.getElementById('selectedId').getAttribute('value'); 
      },

      randomDigit() {
        var crypto = window.crypto;
        if (crypto && crypto.getRandomValues) {
            var rands = new Uint8Array(1);
            crypto.getRandomValues(rands);
            return (rands[0] % 16).toString(16);
        } else {
            return ((Math.random() * 16) | 0).toString(16);
        }
      },

      setSelectedId(id) {
        document.getElementById('selectedId').setAttribute('value', id);        
      }, 
      
      setDisplayConditions(data){
        var displayConditions = this.getDisplayCondtions();

        //get count of matching ids
        var countOfIds = displayConditions.filter(dc => dc.id === data.id).length;
        
        if (countOfIds > 1) {
          //if count > 1 then the array contains a duplicate id, which is caused by copying a display condition.
          //This is expected behaviour, however the copied display condition needs to have a unique id
          displayConditions.filter(dc => dc.id === data.id)[countOfIds - 1].id = this.randomDigit();
        }        

        var i = displayConditions.findIndex(dc => dc.id === data.id)
      
        if (i > -1) {
          displayConditions[i].description = data.description; 
          displayConditions[i].before = displayConditions[i].before.replace(/data-sql=\".*\"/, `data-sql=\"${data.description}\"`);                     
        } else {          
          displayConditions.push(data);         
        } 
        
        document.getElementById('displayConditions').setAttribute('value', JSON.stringify(displayConditions));
      },

      getEmptyDisplayCondition(){
        return {
          id: '',
          type: '',
          label: 'Display Condition',
          description: '',
          before: '<div class="unlayer-display-condition" data-sql="{sql}">',
          after: '</div>' 
        }
      } 
    } 
  }

  hideQueryBuilderModal(){
    document.getElementById('queryBuilderModal').classList.remove('show');
    document.getElementById('queryBuilderModal').style.display = 'none';
  }

  onContactsFilterSave(event) {        

    const sql = event[0] === null && event[1] === null ? '' : event[1].sql;

    if (sql === '') return;
    
    const displayCondition = this.getUnlayerFuncs().getSelectedDisplayCondtion();
    
    displayCondition.description = sql;

    this.getUnlayerFuncs().setDisplayConditions(displayCondition); 
    
    this.hideQueryBuilderModal();
  }

  onListsClosed($event) {
    this._selectedListId = $event.value || 0;    
    this.queryBuilder.refreshFields(this._selectedListId);
    this.queryBuilder.resetFilter();
  }

  onListFoldersClosed($event) {   
    
    this._listsByFolderId = this._lists
                            .filter(x => x.folderId == $event.value)
                            .map(x => new SelectListOption(x.listId, x.listName));                             
  }

  onSelectedIdChanged() {  
               
    this.queryBuilder._parentQuery = this.getUnlayerFuncs().getSelectedDisplayCondtion().description;
    if (this.queryBuilder._parentQuery !== '' && this._selectedListId > 0) {
      this.queryBuilder.refreshSql();
    }  
  } 
  

  messageToSave(messageData: MessageSaveModel) {
    this._message.HtmlContent = messageData.HtmlContent;
    this._message.MetaContent = messageData.MetaContent;
    this._message.EmailContent = messageData.EmailContent;

    this.populateMessageModal();
    this.saveMessageModal.show();
  }  

  populateMessageFolderDropdown() {
    this._messageFoldersDropdown = [];
    this._messageFolders.forEach((folder: FolderDto) => {
      this._messageFoldersDropdown = [... this._messageFoldersDropdown, {
        value: folder.id,
        label: folder.name
      }];
    });
  }

  populateMergeTags() {

    this._listFolders.forEach(x=> {           
      var folder = this._allFoldersAllListsAllFields.find(folder => folder.folderId === x.value);                   

      const folderTagName = folder.folderName.replace(/ /g, '') + x.value;

      this._mergeTagsValues.push({
        name: folder.folderName,
        mergeTags: this.getMergeTagsByFolderId(folder.folderId)
      }
      )
    }); 
   
    this.unlayer.editor.setMergeTags(
      this._mergeTagsValues
    );
   
  } 

  populateMessageModal() {    
    this.populateMessageFolderDropdown();
    this._saveMessageForm.patchValue({
      messageName: this._message.MessageName,
      emailSubject: this._message.EmailSubject,
      messageFolder: this._message.FolderID,
      fromName: this._message.FromName,
      fromAddress: this._message.FromAddress,
      replyToAddress: this._message.ReplyToAddress
    });
  }

  saveDesign() { 
    return new Promise<void>((resolve) => {      
      this.unlayer.editor.saveDesign((data) => {        
        var dataString = JSON.stringify(data);
        this._message.EmailContent = dataString.replace(/\\n/g, "\\n")
                                               .replace(/\\'/g, "\\'")
                                               .replace(/\\"/g, '\\"')
                                               .replace(/\\&/g, "\\&")
                                               .replace(/\\r/g, "\\r")
                                               .replace(/\\t/g, "\\t")
                                               .replace(/\\b/g, "\\b")
                                               .replace(/\\f/g, "\\f");        
        resolve();    
      });      
    });          
  } 

  // if the user clicks save and exit, return them to the message screen
  // otherwise, just close the modal
  async saveMessageSubmit(stayOnPage: boolean) {
    this._stayOnPage = stayOnPage;
    this._saving = true;
    this._message.MessageName = this._saveMessageForm.value.messageName;
    this._message.EmailSubject = this._saveMessageForm.value.emailSubject;
    this._message.FolderID = this._saveMessageForm.value.messageFolder;
    this._message.FromName = this._saveMessageForm.value.fromName;
    this._message.FromAddress = this._saveMessageForm.value.fromAddress;
    this._message.ReplyToAddress = this._saveMessageForm.value.replyToAddress;
    this._message.IsUnlayer = true;
    
    await this.exportHtml();
    await this.saveDesign();            
     
   
    this.emailMessagesService.saveMessage(this._message).subscribe(result => {
      this.notificationService.showSuccess(result.message);
      this.saveMessageModal.hide();
      this._saving = false;
      this._message.MessageID = result.data.MessageID;
      if (this._sendAfterSave) {
        this.router.navigate(['messages/message-send', this._message.MessageID, MessageTypeEnum.email]);
      }
      else {        
        if(!stayOnPage) this.router.navigate(['messages/', this._message.FolderID]);
      }

    }, error => {
      console.log(error);
      this._saving = false;
    });
  }

  setConfigValues() {

    this.getImageFolderName().then(imageFolderName => {
      const configValues = {
        clientId: this.localStorageService.getCurrentClientId,
        token: this.localStorageService.getToken,
        username: this.localStorageService.getUsername,
        apiUrl: environment.apiURL,
        imageFolderName: imageFolderName
      }
      document.getElementById('configValues').setAttribute('value', JSON.stringify(configValues));

    })    
  }
}
