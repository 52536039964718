import { Injectable } from '@angular/core';

export class PostAttachmentModel {
  public PostID: number;
  public PostAttachmentID: number;
  public OrderID: number;
  public ContentType: string;
  public FileName: string;
  public FileUrl: string;
  public VideoThumbnailFileUrl: string;

  constructor(init: any) {
    this.PostID = init.PostID;
    this.PostAttachmentID = init.PostAttachmentID;
    this.OrderID = init.OrderID;
    this.ContentType = init.ContentType;
    this.FileName = init.FileName;
    this.FileUrl = init.FileUrl
    this.VideoThumbnailFileUrl = init.VideoThumbnailFileUrl;
  }
}

@Injectable({
  providedIn: 'root'
})

export class PostAttachmentModelAdapter {
  adapt(item: any): PostAttachmentModel {
    return new PostAttachmentModel(item);
  }
}
