import { Injectable } from '@angular/core';
import { ListObjectsV2ResponseModel } from './ListObjectsV2Response.model';

@Injectable({
  providedIn: 'root'
})

export class ListObjectsV2ResponseModelAdapter {
  public adapt(init: any): ListObjectsV2ResponseModel {
    return new ListObjectsV2ResponseModel(init);
  }
}
