import { ListFieldOptionDto } from './list-field-option-dto';
import { ListFieldOptionDtoAdapter } from './list-field-option-dto-adapter';


export class ContactFieldDto {
    name: string;
    displayName: string;
    isDefault: boolean;
    value: any;
    readOnly: boolean;
    visible: boolean;
    fieldTypeId: number;
    fieldName: string;
    fieldTypeName: string;
    controlTypeName: string;
    fieldId: number;
    sqlType: string;
    wildcard: string;
    controlTypeId: number;
    options: ListFieldOptionDto[];

    constructor(init: any) {
      this.name = init.Name;
      this.displayName = init.DisplayName;
      this.isDefault =  !!init.IsDefault;
      this.value = init.Value ? init.Value : '';
      this.readOnly = !!init.ReadOnly;
      this.visible = init.Visible;
      this.fieldName = init.Name;
      this.fieldTypeName = init.FieldTypeName;
      this.controlTypeName = init.ControlTypeName;
      this.fieldId = +init.FieldID;
      this.fieldTypeId = +init.FieldTypeID;
      this.sqlType = init.SqlType;
      this.wildcard = init.Wildcard;
      this.controlTypeId = +init.ControlTypeID;
      this.options = init.Options && (init.Options as []).length > 0
                      ? init.Options.map(option => new ListFieldOptionDtoAdapter().adapt(option))
                      : [];
    }
}




