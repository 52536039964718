import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MessageGridRecordDto } from '../../../../_models/messaging/messages/message-grid-record-dto';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, switchMap } from 'rxjs/operators';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClientsDto } from '../../../../_models/admin/clients/clients-model';
import { ClientManagementService } from '../../../../_services/admin/clients/client-management.service';
import { SelectListOption } from '../../../../_models/system/select-list-option';
import { FolderDto } from '../../../../_models/messaging/messages/folder-dto';
import { MessageFolderService } from '../../../../_services/messaging/messages/message-folder.service';
import { FolderDtoAdapter } from '../../../../_models/messaging/messages/folder-dto.adapter';
import { NotificationService } from '../../../../_services/notification.service';
import { LocalStorageService } from '../../../../_services/system/localStorage/localStorage.service';
import { MessageCopyMoveDtoAdapter } from '../../../../_models/messaging/messages/message-copy-move-dto-adapter';
import {
  MessageCopyMoveDetailsDtoAdapter
} from '../../../../_models/messaging/messages/message-copy-move-details-dto-adapter';
import {
  MessageCopyMoveFindReplaceDtoAdapter
} from '../../../../_models/messaging/messages/message-copy-move-find-replace-dto-adapter';
import { MessageService } from '../../../../_services/messaging/messages/message.service';
import { FindAndReplaceModalComponent } from '../find-and-replace/find-and-replace-modal.component';

@Component({
  selector: 'app-copy-move-message',
  templateUrl: './copy-move-message-modal.component.html',
  styleUrls: ['./copy-move-message-modal.component.scss']
})
export class CopyMoveMessageModalComponent implements OnInit {
  copyMoveFormGroup: UntypedFormGroup;
  copyMoveFormArray: UntypedFormArray;
  title: string;
  type: string;
  tableKey: string;
  isCopy: boolean;
  currentClientId: number;
  messageCopyMoveSubmitting = false;
  copyMoveList = [];
  clientSelectOptions: SelectListOption[] = [];
  folderSelectOptions: SelectListOption[] = [];

  constructor(
    private messageFolderService: MessageFolderService,
    private clientManagementService: ClientManagementService,
    private messageFolderDtoAdapter: FolderDtoAdapter,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private messageCopyMoveDtoAdapter: MessageCopyMoveDtoAdapter,
    private messageCopyMoveDetailsDtoAdapter: MessageCopyMoveDetailsDtoAdapter,
    private messageCopyMoveFindReplaceDtoAdapter: MessageCopyMoveFindReplaceDtoAdapter,
    private elemRef: ElementRef,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CopyMoveMessageModalComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(): void {
    this.isCopy = this.data.isCopy;
    this.copyMoveList = this.data.copyMoveList;
    this.tableKey = this.data.tableKey;
    this.type = 'Message' + (this.copyMoveList.length > 1 ? 's' : '');
    this.title = (this.isCopy ? 'Copy ' : 'Move ') + this.type;
    this.currentClientId = +this.localStorageService.getCurrentClientId;
    this.getClients();

    this.copyMoveFormArray = new UntypedFormArray([]);
    this.fillCopyMoveFormArray();

    this.copyMoveFormGroup = new UntypedFormGroup({
      messageFolder: new UntypedFormControl(''),
      clientSelection: new UntypedFormControl(this.currentClientId),
      searchReplace: new UntypedFormArray([])
    });

    this.folderSelectOptions = this.data.folders;
    this.copyMoveFormGroup.get('messageFolder').setValue(this.folderSelectOptions[0].value);
    this.copyMoveFormGroup.get('clientSelection').valueChanges.subscribe((id) => {
      this.populateFoldersDropdown(id);
    });
  }

  fillCopyMoveFormArray(): void {
    this.copyMoveList.forEach((el) => {
      el.checked = false;
      this.copyMoveFormArray.push(new UntypedFormControl(el.messageName));
    });
  }

  folderSelectConcat(folderSelect: SelectListOption[], listFolderDtos): SelectListOption[] {
    return folderSelect.concat(listFolderDtos.map((messageFolderDto: FolderDto) =>
      new SelectListOption(messageFolderDto.id, messageFolderDto.name + ' - ' +
        (messageFolderDto.emailNotDeletedCount + messageFolderDto.smsNotDeletedCount))));
  }

  populateFoldersDropdown(clientId?: number): void {
    const folderServiceAction = clientId ? this.messageFolderService.getAllByClientId(clientId) : this.messageFolderService.getAll();
    folderServiceAction.subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const listFolderDtos = executionResultDto.data
          .map((messageFolderDto: FolderDto) => this.messageFolderDtoAdapter.adapt(messageFolderDto));
        this.folderSelectOptions = [];
        this.folderSelectOptions = this.folderSelectConcat(this.folderSelectOptions, listFolderDtos);
        this.copyMoveFormGroup.get('messageFolder').setValue(this.folderSelectOptions[0].value);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  setArray(formArray): void {
    this.copyMoveFormArray = formArray;
  }

  getClients(): void {
    this.clientManagementService.getUserClients().subscribe(result => {
      const clients = result.data;
      this.clientSelectOptions = [];
      clients.forEach((client: ClientsDto) => {
        if (client.Active) {
          this.clientSelectOptions = [...this.clientSelectOptions, {
            value: client.ClientID,
            label: client.KnownAs,
            icon: client.ClientImageUrl
          }];
        }
      });
    });
  }

  copyMoveMessage(): void {
    const messageDetails = [];
    this.copyMoveList.forEach((message: MessageGridRecordDto, i) => {
      messageDetails.push({
        MessageId: message.messageId,
        MessageName: this.copyMoveFormArray.controls[i].value,
        MessageType: message.messageType
      });
    });

    const messageFindReplace = [];
    (this.copyMoveFormGroup.get('searchReplace') as UntypedFormArray).controls.forEach((message: UntypedFormGroup) => {
      messageFindReplace.push({
        Find: message.get('find').value,
        Replace: message.get('replace').value
      });
    });

    const messagesToSend = this.messageCopyMoveDtoAdapter.adapt({
      IsCopy: this.isCopy,
      ClientId: this.copyMoveFormGroup.get('clientSelection').value,
      OldClientId: this.currentClientId,
      FolderId: this.copyMoveFormGroup.get('messageFolder').value,
      Messages: this.messageCopyMoveDetailsDtoAdapter.adaptArray(messageDetails),
      FindReplace: this.messageCopyMoveFindReplaceDtoAdapter.adaptArray(messageFindReplace)
    });

    this.messageCopyMoveSubmitting = true;
    this.messageService.checkMessageName(messagesToSend)
      .pipe(
        switchMap((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            return this.messageService.copyMoveMessage(messagesToSend);
          }
        }),
        catchError(() => {
          this.messageCopyMoveSubmitting = false;
          return of(null);
        })
      )
      .subscribe((result: ExecutionResultDto | null ) => {
        if (result) {
          this.messageCopyMoveSubmitting = false;
          this.notificationService.showSuccess(result.message);
          this.copyMoveFormGroup.reset();
          this.dialogRef.close({ isNeedToUpdate: true });
        }
      }, () => this.messageCopyMoveSubmitting = false);
  }

  openFindReplaceModal(): void {
    const dialog = this.matDialog.open(FindAndReplaceModalComponent);
    this.dialogRef.componentInstance.elemRef.nativeElement.hidden = true;

    dialog.afterClosed().subscribe((formData) => {
      this.dialogRef.componentInstance.elemRef.nativeElement.hidden = false;
      this.findAndReplaceMessageNames(formData);
    });
  }

  setArrayValue(data): void {
    (this.copyMoveFormGroup.get('searchReplace') as UntypedFormArray).controls = data.controls;
  }

  findAndReplaceMessageNames(formData: { find: string, replace: string }): void {
    const find = formData.find;
    const replace = formData.replace;

    this.copyMoveFormArray.controls.forEach((control) => {
      const regEx = new RegExp(find, 'gi');
      control.setValue(control.value.replace(regEx, replace));
    });
  }
}
