import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-upload-contacts',
  templateUrl: './upload-contacts-modal.component.html',
  styleUrls: ['./upload-contacts-modal.component.scss']
})
export class UploadContactsModalComponent implements OnInit {
  uploadFileFormGroup: UntypedFormGroup;
  title = 'Upload File';
  file: UploadFile;
  buttonName = 'Start uploading';

  fileUploaderOptions = {
    concurrency: 0,
    allowedContentTypes: ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  };

  uploadInput = new EventEmitter<UploadInput>();

  constructor(
    public dialogRef: MatDialogRef<UploadContactsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    const newList = this.data.toNewList;
    this.uploadFileFormGroup = new UntypedFormGroup({});
    if (typeof newList === 'string' || newList instanceof String) { this.data.toNewList = newList === 'true'; }

    if (this.data.newName) {
      this.title = 'Edit New List Name';
      this.buttonName = 'Edit';
    }

    if (this.data.toNewList || this.data.newName) {
      this.uploadFileFormGroup.addControl(
        'name',
        new UntypedFormControl(this.data.newName || '', Validators.required)
      );
    }
  }

  upload(): void {
    this.dialogRef.close({
      fileUploaded: true,
      file: this.file,
      isNewList: this.data.toNewList,
      newListName: this.uploadFileFormGroup.get('name')?.value
    });
  }

  isInvalidFileName(): boolean {
    if (this.file) {
      const fileNameWithoutExtension = this.file.name.substring(0, this.file.name.lastIndexOf('.'));
      const invalidCharsRegEx = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
      return invalidCharsRegEx.test(fileNameWithoutExtension);
    }

    return true;
  }

  onUploadOutput(output: UploadOutput | any): void {
    if (output.type === 'addedToQueue') {
      this.file = output.file;
    } else if (output.type === 'uploading') {
      this.file = output.file;
    } else if (output.type === 'removed') {
      this.file = null;
    }
  }

  editName(): void {
    this.dialogRef.close(this.uploadFileFormGroup.get('name').value);
  }

  validatioUpload(): boolean {
    if (this.data.toNewList || this.data.newName) {
      return this.isInvalidFileName() || this.uploadFileFormGroup.invalid;
    } else {
      return this.isInvalidFileName();
    }
  }
}
