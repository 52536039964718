import { Injectable } from '@angular/core';
import { MessagePreviewModel } from './message-preview-model';

@Injectable({
  providedIn: 'root'
})

export class MessagePreviewModelAdapter {
  adapt(item: any): MessagePreviewModel {
    return new MessagePreviewModel(item);
  }

  createEmpty() {
    return new MessagePreviewModel({
      MessageID: 0,
      MessageName: '',
      Subject: '',
      HtmlContent: '',
      FromName: '',
      FromAddress: '',
      ReplyToAddress: ''
    });
  }
}
