<div class="row m-4">
  <div class="col-md-12">

    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5 animated fadeIn">

        <!--Card image-->
        <div class="view view-cascade gradient-card-header primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center" >
            <h4 class="page-title">Client Management</h4>
        </div>
        <!--/Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="topCardPanel text-center">

          <section>

            <form class="form-inline md-form form-sm" autocomplete="off">

                <div class="container-fluid">
                    <!-- Grid Row -->
                    <div class="row">
                        <div class="col-md-5">
                          <div class="md-form text-left">
                            <mdb-icon fas icon="search" class="prefix icon-color" size="sm" aria-hidden="true"></mdb-icon>
                            <input type="text"
                              id="clientSearch"
                              class="form-control form-control-sm ml-3 w-75 searchIcon"
                              [(ngModel)]="searchText"
                              [ngModelOptions]="{standalone: true}"
                              mdbInput>
                            <label for="clientSearch">Search</label>
                          </div>
                        </div>


                        <div class="col-md-2">

                        </div>
                        <div class="col-md-3">
                            <a mdbBtn color="secondary" class="waves-light rounded" [routerLink]="['/client-management/create']" mdbWavesEffect>Create new client</a>
                        </div>

                        <div class="col-md-2">
                          <div class="switch" >
                            <label>
                              Inactive
                              <input type="checkbox" [(ngModel)]='activeClients' [ngModelOptions]="{standalone: true}">
                              <span class="lever" ></span> Active
                            </label>
                          </div>
                        </div>

                    </div>
                    <!--/.Grid Row-->
              </div>

            </form>

          </section>

        </mdb-card-body>
        <!--/.Card content-->
      </mdb-card>
      <!--/.Card-->
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="row pb-4">
          <div class="clientCard col-md-3 animated fadeIn" *ngFor="let client of _filteredClients">
            <app-client-card [client] = "client" [accountManager] = "this.findAccountManager(client.AccountManagerUserId)" ></app-client-card>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
