import { Injectable } from '@angular/core';

export class ClientDomain {
  DomainName: string;
  DomainID: number;

  constructor(init: any) {
    this.DomainName = init.DomainName;
    this.DomainID = init.DomainID;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ClientDomainAdapter {
  adapt(item: any) {
    return new ClientDomain(item);
  }
}
