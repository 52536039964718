import { Injectable } from '@angular/core';

export class ClientAmazonSetting {
  ClientID: number;
  SesAccessKey: string;
  SesSecretKey: string;
  SesRegion: string;
  SesConfigSet: string;
  SqsAccessKey: string;
  SqsSecretKey: string;
  SqsRegion: string;
  SqsQueueName: string;

  // tslint:disable-next-line: align
  constructor(init: any) {
    this.ClientID = init.ClientID;
    this.SesAccessKey = init.SesAccessKey;
    this.SesSecretKey = init.SesSecretKey;
    this.SesRegion = init.SesRegion;
    this.SesConfigSet = init.SesConfigSet;
    this.SqsAccessKey = init.SqsAccessKey;
    this.SqsSecretKey = init.SqsSecretKey;
    this.SqsRegion = init.SqsRegion;
    this.SqsQueueName = init.SqsQueueName;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ClientAmazonSettingAdapter {
  adapt(item: any) {
    return new ClientAmazonSetting(item);
  }
}

