import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PermissionsModel, PermissonsModelAdapter } from 'src/app/_models/system/permissions/permissons-model';
import { UserPermissionService } from '../../admin/users/user-permission-service';
import { UserPermissionDto } from 'src/app/_models/admin/users/user-permission-model';
import { PermissionsAllAvailableDto, Section, Page } from 'src/app/_models/admin/users/permissions-all-available-model';
import { LocalStorageService } from '../localStorage/localStorage.service';
import { switchMap, map } from 'rxjs/operators';
import { PermissionsPageTypes } from '../../../_models/system/permissions/permissions-page-types-model';
import { AuthService } from '../../admin/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private allAvailable: PermissionsAllAvailableDto;
  private permissionModel: PermissionsModel;
  public permissionsModel = new Subject<PermissionsModel>();

  constructor(
    private userPermissionsService: UserPermissionService,
    private permissionsModelAdapter: PermissonsModelAdapter,
    private localStorageService: LocalStorageService
  ) { }

  public getSmsPermission(): PermissionsPageTypes {
    return this.getPermissionsModel().Messaging.MessagesSMS;
  }

  public set setPermissionsModel(value: PermissionsModel) {
    this.permissionsModel.next(value);
    this.permissionModel = value;
  }

  public getPermissionsModel(): PermissionsModel {
    return this.permissionModel;
  }

  public getPermission(): Observable<void> {
    return this.userPermissionsService.getEmptyPermissionsModel()
      .pipe(
        switchMap((allAvailable: PermissionsAllAvailableDto) => {
          this.allAvailable = allAvailable;
          return this.userPermissionsService.get(+this.localStorageService.getUserId);
        }),
        map((userPermissions: UserPermissionDto[]) => {
          userPermissions.forEach(permissions => {
            const sectionIndex = this.allAvailable.sections.findIndex((section: Section) => {
              return section.id === permissions.pageSectionId;
            });
            const pageIndex = this.allAvailable.sections[sectionIndex].pages.findIndex((page: Page) => {
              return page.id === permissions.pageId;
            });
            this.allAvailable.sections[sectionIndex].pages[pageIndex].permissionKey = permissions.permissionKey;
          });

          this.setPermissionsModel = this.permissionsModelAdapter.adapt(this.allAvailable);
        })
      );
  }
}
