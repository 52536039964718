import { Injectable } from '@angular/core';

export class UserPermissionTypeDto {

    public id: number
    public permissionKey: number
    public permissionName: string

    constructor(init: any) {
      this.id = init.Id
      this.permissionKey = init.PermissionKey
      this.permissionName = init.PermissionName
    }
}

@Injectable({
  providedIn: 'root'
})

export class UserPermissionTypeAdapter { 

  adapt(item: any): UserPermissionTypeDto {
    return new UserPermissionTypeDto(item)
  }
}
