import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { SmsMessageDtoRequest } from 'src/app/_models/messaging/messages/sms-messages/sms-message-dto-request';
import { MessageSendDtoRequest } from 'src/app/_models/messaging/messages/message-send-dto-request';

@Injectable({
  providedIn: 'root'
})

export class SmsMessageService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'SmsMessage';
  private _options = { headers: new HttpHeaders() };

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) { }

  delete(id: number): Observable<ExecutionResultDto> {
    return this.http
    .patch(`${this._baseUrl}${this._controllerName}/Delete/${id}`, this._options)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  get(id: number): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/${id}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getClientInboxes(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetClientInboxes`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }  

  restore(id: number): Observable<ExecutionResultDto> {
    return this.http
    .patch(`${this._baseUrl}${this._controllerName}/Restore/${id}`, this._options)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  save(smsMessageDtoRequest: SmsMessageDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/Save`, smsMessageDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  send(messageSendDtoRequest: MessageSendDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/Send`, messageSendDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

