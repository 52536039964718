import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { EditContactPlanRequestDto } from 'src/app/_models/messaging/automation/contact-plans/edit-contact-plan-request-dto';
import { EditStepRequestDto } from 'src/app/_models/messaging/automation/steps/edit-step-request-dto';
import { CopyContactPlanContactPlansDto } from 'src/app/_models/messaging/automation/contact-plans/copy-contact-plan-contact-plans-dto';
import { CopyContactPlanDto } from 'src/app/_models/messaging/automation/contact-plans/copy-contact-plans-dto';

@Injectable({
  providedIn: 'root'
})

export class ContactPlanService {
  private baseUrl = environment.apiURL;
  private controllerName = 'ContactPlan';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  copy(copyContactPlanDtoRequest: CopyContactPlanDto): Observable<ExecutionResultDto> {
    return this.http
      .post(`${this.baseUrl}${this.controllerName}/CopyContactPlans `, copyContactPlanDtoRequest)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  activatePlan(editContactPlanDto: EditContactPlanRequestDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/ActivatePlan`, editContactPlanDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  activateStep(editStepRequestDto: EditStepRequestDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/ActivateStep`, editStepRequestDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  toggleAllSteps(contactPlanId: number, allStepsStatus: boolean): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/ToggleAllSteps?ContactPlanId=${contactPlanId}&status=${allStepsStatus}`, null)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  copyStep(editStepRequestDto: EditStepRequestDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/CopyStep`, editStepRequestDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  delete(id: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this.baseUrl}${this.controllerName}/DeletePlan/${id}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  deleteStep(id: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this.baseUrl}${this.controllerName}/DeleteStep/${id}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  get(id: number): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetPlan/${id}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getPlansByFolderId(folderId: number) {
    return this.http
      .get(`${this.baseUrl}${this.controllerName}/GetPlansByFolderId/${folderId}`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getPlans(pagerDtoRequest: PagerDtoRequest, folderId: number): Observable<ExecutionResultDto> {

    const query = `?Page=${pagerDtoRequest.page}` +
                  `&PageSize=${pagerDtoRequest.pageSize}` +
                  `&SortColumn=${pagerDtoRequest.sortColumn}` +
                  `&SortDirection=${pagerDtoRequest.sortDirection}` +
                  `&folderId=${folderId}`;

    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetPlans${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getSteps(pagerDtoRequest: PagerDtoRequest, contactPlanId: number): Observable<ExecutionResultDto> {

    const query = `?Page=${pagerDtoRequest.page}` +
                  `&PageSize=${pagerDtoRequest.pageSize}` +
                  `&SortColumn=${pagerDtoRequest.sortColumn}` +
                  `&SortDirection=${pagerDtoRequest.sortDirection}` +
                  `&contactPlanId=${contactPlanId}`;

    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetSteps${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(contactPlanDtoRequest: EditContactPlanRequestDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}`, contactPlanDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

