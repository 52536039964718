export const TableType = {
  messages: 1,
  sms: 2,
  email: 3,
  contactPlan: 4,
  contactCriteria: 5,
  trial: 6,
  lists: 7,
  contacts: 8,
  emailReports: 9,
  smsReports: 10
};

export const TableName = {
  messages: 'Messages',
  sms: 'SMS Replies',
  email: 'Link Clicks',
  contactPlan: 'Contact Plans',
  contactCriteria: 'Contact Criteria Groups',
  trial: 'Trial Run Results',
  lists: 'Lists',
  contacts: 'Contacts',
  smsReports: 'SMS Reports',
  emailReports: 'Email Reports'
};

export const TableActionType = {
  getData: 1,
  copy: 2,
  move: 3,
  delete: 4,
  searchReplace: 5,
  send: 6,
  preview: 7,
  clear: 8,
  uploadNewList: 9,
  uploadList: 10,
  uploadHistory: 12,
  edit: 13,
  trial: 14,
  switchStatus: 15,
  editForms: 16,
  listContacts: 17,
  openDetails: 18,
  export: 19,
  getMessageHistory: 20,
  previewLinks: 21,
  copyCriteriaGroup: 22
};

export const TableActionName = {
  copy: 'Copy',
  move: 'Move',
  searchReplace: 'S/R',
  clear: 'Clear',
  uploadNewList: 'Upload To New List',
  uploadList: 'Upload To List: ',
  uploadHistory: 'History of Uploads',
  send: 'Send',
  preview: 'Preview',
  previewLinks: 'Preview Links'
};
