import { Component, OnInit, ViewChild, Input, EventEmitter, ViewChildren, QueryList, Output } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective, UploadFile, UploadInput, UploadOutput, SelectComponent, IOption, MdbInput} from 'ng-uikit-pro-standard';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { ListControlTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-control-type-enum';
import { ListFieldTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-field-type-enum';
import * as moment from 'moment';
import { ContactUploadService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.upload.service';
import { PropertyDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/property-dto-adapter';
import { ListFromFileDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-from-file-dto-request';
import { ListFieldFromFileDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-field-from-file-dto';
import { ImportListSettingsDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/import-list-settings-dto';
import { ListHistoryUploadDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-history-upload-dto';
import { FieldControlTypeDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/field-control-type-dto';
import { ListFieldControlTypeDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-field-control-type-dto';
import { ListFieldControlTypeDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-field-control-type-dto-adapter';
import { ListTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/lists/list-type-enum';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { ContactsUploadDataTableColumn } from '../contacts-upload-datatable-column';
import { PropertyDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/property-dto';
import { DatePipe } from '@angular/common';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import {
  UploadContactsModalComponent
} from '../../../../shared/modals/upload-contacts/upload-contacts-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contacts-upload',
  templateUrl: './contacts-upload.component.html',
  styleUrls: ['./contacts-upload.component.scss'],
})
export class ContactsUploadComponent implements OnInit {
  @Input() isExistingList: boolean;
  @Input() selectedFolderId: number;
  @Input() selectedListId: number;
  @Input() file: UploadFile;
  @Input() newName: string;

  @Output() contactsSaved: EventEmitter<any> = new EventEmitter<any>();

  uploadOptionsFormGroup: UntypedFormGroup;
  _addContactFieldFormGroup: UntypedFormGroup;
  _addingNewField: boolean;
  compareForDuplicatesIsChecked = false;
  _columnMode = ColumnMode;
  _columns: ContactsUploadDataTableColumn[] = [];
  _datePipe: DatePipe = new DatePipe('en-GB');
  _dataTableFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  _editMode: string;
  _hasConfirmedAdditionMandatoryFields: boolean;
  fieldCompareSelectListOptions: IOption[] = [];
  _fieldControlTypeSelectListOptions: SelectListOption[] = [];
  fieldSelectListOptions: IOption[] = [];
  _fieldTypeSelectListOptions: SelectListOption[] = [];
  _headerRow: {[prop: string]: any};
  importingFile = false;
  _includeHeaderRow = true;
  _listFieldControlTypeDtos: ListFieldControlTypeDto[] = [];
  _mandatoryFields: string[] = ['Title', 'Firstname', 'Surname', 'Email', 'Mobile', 'Consent SMS', 'Consent Email'];
  _missingOrExcludedMandatoryFields: string[] = [];
  _models: PropertyDto[] = [];
  _newListNameIsVisible: boolean;
  _rows: {[prop: string]: any}[] = [];
  _selectedColumnIndex: number;
  _selectedFieldName: string;
  _toolTipText: string[] = [];
  _uploadFileFormGroup: UntypedFormGroup;
  uniqueUploadFilename: string;
  public pageLimitOptions = ConstTableRowLimitOptions;
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;

  @ViewChild('addContactFieldModal', { static: true }) addContactFieldModal: ModalDirective;
  @ViewChild('confirmAdditionMandatoryFields', { static: true }) confirmAdditionMandatoryFields: ModalDirective;
  @ViewChild('uploadModal', { static: true }) uploadModal: ModalDirective;
  @ViewChildren(SelectComponent) selectLists: QueryList<SelectComponent>;

  constructor(
    private listFieldControlTypeDtoAdapter: ListFieldControlTypeDtoAdapter,
    private notificationService: NotificationService,
    private propertyDtoAdapter: PropertyDtoAdapter,
    private contactUploadService: ContactUploadService,
    private permissionsService: PermissionsService,
    private helperService: HelperService,
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
    this._addContactFieldFormGroup = new UntypedFormGroup({
      newFieldName: new UntypedFormControl('', Validators.required),
      fieldTypes: new UntypedFormControl('', Validators.required),
      fieldControlTypes: new UntypedFormControl('', Validators.required)
    });

    this.uploadOptionsFormGroup = new UntypedFormGroup({
      includeHeaderRow: new UntypedFormControl(this._includeHeaderRow),
      compareForDuplicates: new UntypedFormControl(''),
      compareByFieldName: new UntypedFormControl(0)
    });

    this.getAllFieldControlTypes();
    this.subscribeToFieldTypes();
    this.subscribeToHeaderRowIsIncludedCheckbox();
    this.onUploadClick();
  }

  allFieldSelectListsAreValid(fieldSelectLists: string[]) {
    const atLeastOneFieldIncluded = this._models.some(x => x.isIncluded);

    if (this.isExistingList) {
      const fieldIsUnmappedAndIncluded: boolean[] = fieldSelectLists.map((x, i) => (x === undefined && this._columns[i].included));

      return fieldIsUnmappedAndIncluded.every(x => x === false) && atLeastOneFieldIncluded;
    }

    return atLeastOneFieldIncluded;
  }

  checkIfDuplicateExists(arr) {
    return new Set(arr).size !== arr.length && !arr.some(column => column === undefined);
  }

  controlTypeContainsOptions(controlTypeId: number) {
    return controlTypeId === ListControlTypeEnum.Dropdown ||
           controlTypeId === ListControlTypeEnum.RadioButton ||
           controlTypeId === ListControlTypeEnum.Checkbox;
  }

  dataTableFormGroupIsValid() {

    if (this._newListNameIsVisible && this._uploadFileFormGroup.get('newListName').value === '') { return false; }
    if (this.uploadOptionsFormGroup.get('compareForDuplicates').value) {
      return this.uploadOptionsFormGroup.get('compareByFieldName').value ? true : false;
    }
    return true;
  }

  isValidFileName() {

    if (this.file != undefined) {
      var fileNameWithoutExtension = this.file.name.substring(0, this.file.name.lastIndexOf('.'));
      var invalidCharsRegEx = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

      if (invalidCharsRegEx.test(fileNameWithoutExtension)) return false;
    }

    return true;
  }

  getAllFieldControlTypes() {
    this.contactUploadService.getAllFieldControlTypes().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._listFieldControlTypeDtos = executionResultDto.data.map(data => this.listFieldControlTypeDtoAdapter.adapt(data));
        this.populateFieldTypes();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getDateTimeForFileName() {
    const today = new Date();
    const date = today.getDate().toString() +
                 (today.getMonth() < 9 ? '0' +
                 (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString())
                 + today.getFullYear().toString();
    const time = today.getHours().toString()
                 + today.getMinutes().toString()
                 + today.getSeconds().toString()
                 + today.getMilliseconds().toString();
    return date.toString() + time.toString();
  }

  getFieldSelectListValues(): string[] {
    return this.selectLists.filter(x => x.el.nativeElement.id.indexOf('fieldSelectList') > -1).map(x => x.value);
  }

  getMissingOrExcludedMandatoryFields(fieldSelectListValues: string[]) {
    const selectedFields = fieldSelectListValues.map(sel => sel.replace('_', ' ').toLowerCase());

    return this._mandatoryFields.filter(mandatoryField => {
        if (!selectedFields.includes(mandatoryField.toLowerCase()) ||
            this._models.some(col => col.name.toLowerCase() === mandatoryField.toLowerCase() && !col.isIncluded) ) {
        return mandatoryField;
      }
    });
  }

  getRows(data) {
    let rows: {[prop: string]: any}[] = [];
    rows = data.map(row => {
      const column: {[prop: string]: any} = {};
      row.map(col => {
        const colName = col.Name.toString().toLowerCase();
        if (colName.toString().toLowerCase() === 'mobile')
        {
            var validatedPhoneNumber = this.helperService.validateMobilePhoneNumber(col.Value);
            column[colName] = validatedPhoneNumber;
        }
        else if (col.Value) {
          if (this.isDate(col.Value)) {
           const date = moment(col.Value, 'DD/MM/YYYY', true).toDate();
           column[colName] = this._datePipe.transform(date, 'dd/MM/yyyy');
          } else if (col.Value.toString().toLowerCase() === 'true') {
            column[colName] = 'Yes';
          } else if (col.Value.toString().toLowerCase() === 'false') {
            column[colName] = 'No';
          }
          else {
            column[colName.toString().toLowerCase()] = col.Value;
          }
        } else {
          column[colName] = '';
        }
      });
      return column;
    });
    return rows;
  }

  getTooltip(model: PropertyDto) {

    if (!model || model.name === '') {
      return 'Unmapped';
    }

    const fieldType = this._listFieldControlTypeDtos.find(x => x.fieldTypeId === model.fieldTypeId);
    const controlType = fieldType.controlType.find(x => x.controlTypeId === model.controlTypeId);

    return `${fieldType.fieldTypeName} (${controlType.controlTypeName})`;
  }

  getUniqueUploadFilename(filename: string) {
    const filenameArray = filename.split('.');
    return filenameArray[0] + '-' + this.getDateTimeForFileName() + '.' + filenameArray[1];
  }

  isConsentField(value: string) {
    return value && value.toLowerCase().indexOf('consent') > - 1
           && (value.toLowerCase().indexOf('sms') > - 1 || value.toLowerCase().indexOf('email') > - 1);
  }

  isDate(value) {

    if (!value) return false;

    const dateFormats = ['YYYY-MM-DD', 'DD/MM/YYYY', 'DD-MM-YYYY', 'DD/MM/YY', 'DD-MM-YY'];

    return moment(value.toString(), dateFormats, true).isValid();
  }

  onAddContactFieldFormSubmit() {

    const newFieldName = this._addContactFieldFormGroup.get('newFieldName').value;

    if (this._models.some(x => x.name.toLowerCase() === newFieldName.toLowerCase() && !x.isNew)) {
      this.notificationService.showWarning(`Field ${newFieldName} already exists. Field names must be unique.`);
      return;
    }

    this.addContactFieldModal.hide();

    const fieldTypeId: number = this._addContactFieldFormGroup.get('fieldTypes').value;
    const controlTypeId: number = this._addContactFieldFormGroup.get('fieldControlTypes').value;
    const addOptions = this.controlTypeContainsOptions(controlTypeId);

    if (!this._addingNewField) {
      this._models = this._models.filter(x => x.name !== this._selectedFieldName);
      this.fieldSelectListOptions = this.fieldSelectListOptions.filter(x => x.value !== this._selectedFieldName);
    }

    const contactsUploadModel = new PropertyDto ({
      Name: newFieldName,
      DisplayName: newFieldName,
      FieldID: 0,
      FieldTypeID: fieldTypeId,
      ControlTypeID: controlTypeId,
      IsIncluded: true,
      IsNew: true,
      AddOptions : addOptions});

    this._toolTipText[this._selectedColumnIndex] = this.getTooltip(contactsUploadModel);

    this._models = this._models.concat(contactsUploadModel);
    this.fieldSelectListOptions = this.fieldSelectListOptions.concat({ label: newFieldName, value: newFieldName, icon: '' });

    this._columns[this._selectedColumnIndex].editIconVisible =
    this._columns[this._selectedColumnIndex].includeIconVisible = true;

    this._columns[this._selectedColumnIndex].addOptionsIconVisible =
    this._columns[this._selectedColumnIndex].addOptions = addOptions;

    this._dataTableFormGroup.get(`fieldSelectList${this._selectedColumnIndex}`).setValue(newFieldName);

    this._selectedFieldName = newFieldName;
  }

  onAddEditClick(addingNewField: boolean, index: number) {

    this._addingNewField = addingNewField;

    this._editMode = this._addingNewField ? 'Add' : 'Edit';

    this._selectedColumnIndex = index;

    if (addingNewField) {
      this._addContactFieldFormGroup.get('newFieldName').setValue('');
      this._addContactFieldFormGroup.get('fieldTypes').setValue('');
      this._addContactFieldFormGroup.get('fieldControlTypes').setValue('');
    } else {
      this._selectedFieldName = this.getFieldSelectListValues()[index];
      this._addContactFieldFormGroup.get('newFieldName').setValue(this._selectedFieldName);
      const selectedColumn = this._models.find(x => x && x.name.toLowerCase() === this._selectedFieldName.toLowerCase());
      if(selectedColumn) {
        this._addContactFieldFormGroup.get('fieldTypes').setValue(selectedColumn.fieldTypeId);
        this._addContactFieldFormGroup.get('fieldControlTypes').setValue(selectedColumn.controlTypeId);
      }
    }

    this.addContactFieldModal.show();
  }

  onAddRemoveOptionsClick(name: string, index: number) {

    this._columns[index].addOptions = !this._columns[index].addOptions;

    const selectedValue = this.getFieldSelectListValues()[index];

    if (selectedValue) {
      const addOptions = this._models.some(x => x.name.toLowerCase() === selectedValue.toLowerCase()) ?
      this._models.find(x => x.name.toLowerCase() === selectedValue.toLowerCase()).addOptions :
      this._models[index].addOptions;

      this._models.some(x => x.name.toLowerCase() === selectedValue.toLowerCase()) ?
      this._models.find(x => x.name.toLowerCase() === selectedValue.toLowerCase()).addOptions = !addOptions :
      this._models[index].addOptions = !addOptions;
    }

    this._columns = this._columns.slice(0);
  }

  onCompareForDuplicatesChange() {
    this.compareForDuplicatesIsChecked = this.uploadOptionsFormGroup.get('compareForDuplicates').value;
  }

  onConfirmAdditionMandatoryFieldsClick() {
    this._hasConfirmedAdditionMandatoryFields = true;
    this.onSaveClick();
  }

  onFieldSelectListClick(index: number) {
    this._selectedFieldName = this._dataTableFormGroup.get(`fieldSelectList${index}`).value;
  }

  onIncludeExcludeClick(index: number, isIncluded: boolean) {

    this._columns[index].included = this._columns[index].includeIconVisible = isIncluded;

    const selectedValue = this.getFieldSelectListValues()[index];

    if (selectedValue) {
      this._models.some(x => x.name.toLowerCase() === selectedValue.toLowerCase()) ?
      this._models.find(x => x.name.toLowerCase() === selectedValue.toLowerCase()).isIncluded = isIncluded :
      this._models[index].isIncluded = isIncluded;
    }

    this._columns = this._columns.slice(0);
  }

  onNgModelChange(name: string, index: number) {

    this._columns[index].editIconVisible = this._models.some(x => name && x.name.toLowerCase() === name.toLowerCase() && x.isNew);

    const selectedColumn = this._models.find(x => name && x.name.toLowerCase() === name.toLowerCase());

    this._toolTipText[index] = this.getTooltip(selectedColumn);

    if (selectedColumn) {
      const addOptions = this.controlTypeContainsOptions(selectedColumn.controlTypeId);
      this._models.find(x => name && x.name.toLowerCase() === name.toLowerCase()).addOptions = addOptions;
      this._columns[index].addOptions = this._columns[index].addOptionsIconVisible = addOptions;
    } else {
      this._columns[index].addOptions = this._columns[index].addOptionsIconVisible = false;
    }

    if (this.getFieldSelectListValues().filter(x => name && x === name).length > 1) {

      const originalValue = this._selectedFieldName;

      this._dataTableFormGroup.get(`fieldSelectList${index}`).setValue(originalValue ? originalValue : null);

      const originalColumn = this._models.find(x => originalValue && x.name.toLowerCase() === originalValue.toLowerCase());

      this._models[index].addOptions = originalColumn ? originalColumn.addOptions : false;

      this.notificationService.showWarning(`${name} is already mapped. Please unmap it.`);

    } else {
      this._models[index].isIncluded = this._columns[index].includeIconVisible = true;
    }

    this._columns = this._columns.slice(0);
  }

  onSaveClick() {

    let fieldSelectListValues = this.getFieldSelectListValues();

    if (this.checkIfDuplicateExists(fieldSelectListValues)){
      this.notificationService.showWarning('Some fields have duplicate names. Field names must be unique.');
      return;
    }

    if (!this.allFieldSelectListsAreValid(fieldSelectListValues)) {
      this.notificationService.showWarning('Some fields are not mapped. Please map or exclude them.');
      return;
    }

    if (!this.isExistingList && !this._hasConfirmedAdditionMandatoryFields) {
      this._missingOrExcludedMandatoryFields = this.getMissingOrExcludedMandatoryFields(fieldSelectListValues);
      if (this._missingOrExcludedMandatoryFields.length > 0) {
        this.confirmAdditionMandatoryFields.show();
        return;
      }
    }

    //Filter and return columns
    fieldSelectListValues = fieldSelectListValues.filter(column => (column));

    const listFieldFromFileDtos: ListFieldFromFileDto[] = fieldSelectListValues.map((fieldSelectListValue, index) => {

      let model: PropertyDto = fieldSelectListValue ?
                                this._models.find(x => x.name && x.name.toLowerCase() === fieldSelectListValue.toString().toLowerCase()) :
                                null;

      if (!model) {
        this._models[index].name = this._models[index].displayName = fieldSelectListValue;
        model = this._models[index];
      }

      const listFieldFromFileDto = new ListFieldFromFileDto( {  originalName: model.name,
                                                                isIncluded: model.isIncluded,
                                                                addOptions: model.addOptions,
                                                              } );
      listFieldFromFileDto.columnIndex = index + 1;
      listFieldFromFileDto.columnName = model.name;
      listFieldFromFileDto.displayName = model.displayName;
      listFieldFromFileDto.fieldId = model.fieldId;
      listFieldFromFileDto.fieldTypeId = model.fieldTypeId;
      listFieldFromFileDto.controlTypeId = model.controlTypeId;
      listFieldFromFileDto.isNew = model.isNew;

      if (!this.isExistingList) {
        if (this.isConsentField(model.name)) {
          listFieldFromFileDto.fieldTypeId = ListFieldTypeEnum.Boolean;
          listFieldFromFileDto.isSystem = listFieldFromFileDto.isDefault = true;
        } else if (this.isDate(this._rows[0][model.name.toLowerCase()])) {
          listFieldFromFileDto.fieldTypeId = ListFieldTypeEnum.Date;
        } else {
          listFieldFromFileDto.fieldTypeId =
          this._listFieldControlTypeDtos.some(x => x.fieldTypeName.toLowerCase() === model.name.toLowerCase()) ?
          this._listFieldControlTypeDtos.find(x => x.fieldTypeName.toLowerCase() === model.name.toLowerCase()).fieldTypeId :
          ListFieldTypeEnum.Text;
        }
      }

      return listFieldFromFileDto;
    });

    const updateExisting = this.compareForDuplicatesIsChecked;

    const importListSettingsDto = new ImportListSettingsDto({
      headerRowIsIncluded: this._includeHeaderRow,
      updateExisting,
      compareByColumnIndex: updateExisting ?
                            this.fieldCompareSelectListOptions
                            .findIndex(x => x.value === this.uploadOptionsFormGroup.get('compareByFieldName').value) + 1 :
                            0,
      compareByColumnName: ''
    });

    const listHistoryUploadDto = new ListHistoryUploadDto();
    listHistoryUploadDto.fileName = this.uniqueUploadFilename;
    listHistoryUploadDto.listName = this.isExistingList ? '' : this.newName;

    const listFromFileDtoRequest = new ListFromFileDtoRequest({
      columns: listFieldFromFileDtos,
      file: listHistoryUploadDto,
      settings: importListSettingsDto,
      isExistingList: this.isExistingList});

    listFromFileDtoRequest.listName = listHistoryUploadDto.listName;
    listFromFileDtoRequest.folderId = this.selectedFolderId;

    if (!this.isExistingList) {
      listFromFileDtoRequest.listId = 0;
      listFromFileDtoRequest.listType =  ListTypeEnum.Custom;
    } else {
      listFromFileDtoRequest.listId = this.selectedListId;
    }

    try {
      this.importingFile = true;
      this.contactUploadService.import(listFromFileDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.contactsSaved.emit();
          this.notificationService.showSuccess(executionResultDto.message);
        } else if (executionResultDto.executionResult === ExecutionResult.warning) {
          this.contactsSaved.emit();
          this.notificationService.showWarning(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
        this.importingFile = false;
        this._hasConfirmedAdditionMandatoryFields = false;
        }
      );
    } catch (error) {
      this.notificationService.showError(error);
      this.importingFile = false;
      this._hasConfirmedAdditionMandatoryFields = false;
    }

  }

  onUploadClick(): void {
    const formData = new FormData();
    this.uniqueUploadFilename = this.getUniqueUploadFilename(this.file.name);
    formData.append('file', this.file.nativeFile);
    formData.append('filename', this.uniqueUploadFilename);

    const listId = this.isExistingList ? this.selectedListId : 0;

    this.contactUploadService.post(listId, formData).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._dataTableFormGroup = new UntypedFormGroup({});
        this._columns = [];
        this._rows = [];
        this.fieldCompareSelectListOptions = [];
        this.fieldSelectListOptions = [];
        this._includeHeaderRow = true;
        this.uploadOptionsFormGroup.get('includeHeaderRow').setValue(this._includeHeaderRow);

        if (this.isExistingList) {
          this.populateDatatableForExistingList(executionResultDto);
        } else {
          this.populateDatatableForNewList(executionResultDto);
        }

        const header = this._columns.map(x => ({ Name: x.prop, Value: x.name }));
        this._headerRow = this.getRows([header])[0];

        if (!this._includeHeaderRow) {
          this._rows = this._rows.concat(this._headerRow);
        }

        this._rows = this._rows.concat(this.getRows(executionResultDto.data.Rows));

        this._pageCount = this._rows.length;

        this.notificationService.showSuccess(executionResultDto.message);

        this.notificationService
          .showInfo('You are currently previewing 10 records, when clicking save the full data file will be processed');
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  populateDatatableForExistingList(executionResultDto: ExecutionResultDto) {

    const propertyDtos = executionResultDto.data.Model.map(x =>  this.propertyDtoAdapter.adapt(x));

    this._models = propertyDtos.map(x => {
            x.addOptions = this.controlTypeContainsOptions(x.controlTypeId),
            x.isIncluded = true,
            x.IsNew = false;
            return x;
          });

    this.fieldSelectListOptions = this._models.map(x => ({ label: x.displayName, value: x.name, icon: '' }));

    this._columns = executionResultDto.data.Columns.map((c, index) => {

      this.fieldCompareSelectListOptions = this.fieldCompareSelectListOptions.concat({ label: c.DisplayName, value: c.Name, icon: '' });

      const matchedPropertyDto =
      this._models.find(x => x.displayName.replace('_', ' ').toLowerCase() === c.DisplayName.replace('_', ' ').toLowerCase());

      this._dataTableFormGroup.addControl(`fieldSelectList${index}`,
                                        new UntypedFormControl({value: matchedPropertyDto ? matchedPropertyDto.name : '', disabled: false},
                                        Validators.required));

      const addOptions = matchedPropertyDto ? this.controlTypeContainsOptions(matchedPropertyDto.controlTypeId) : false;

      return new ContactsUploadDataTableColumn({
          prop: matchedPropertyDto ? matchedPropertyDto.name.toLowerCase() : c.Name.toLowerCase(),
          name: matchedPropertyDto ? matchedPropertyDto.displayName : c.DisplayName,
          included: true,
          includeIconVisible: true,
          editIconVisible: false,
          addOptions,
          addOptionsIconVisible: addOptions
        });

      });
  }

  populateDatatableForNewList(executionResultDto: ExecutionResultDto) {
    this._models = executionResultDto.data.Columns.map((col, index) => {

      const model = new PropertyDto({
        Name: col.Name,
        DisplayName: col.DisplayName,
        IsIncluded: true,
        FieldID: 0,
        FieldTypeID: ListFieldTypeEnum.Text,
        ControlTypeID: ListControlTypeEnum.Input,
        AddOptions: false,
        IsNew: true
      });

      this.fieldSelectListOptions = this.fieldSelectListOptions.concat({ label: model.displayName, value: model.name, icon: '' });

      window.setTimeout(this.removeSelectToggles, 250);

      this._dataTableFormGroup.addControl(`fieldSelectList${index}`, new UntypedFormControl({value: model.name, disabled: true},
        Validators.required));

      this._columns = this._columns.concat(new ContactsUploadDataTableColumn({
                                          prop: model.name.toString().toLowerCase(),
                                          name: model.name,
                                          included: true,
                                          includeIconVisible: true,
                                          editIconVisible: true,
                                          addOptions: false,
                                          addOptionsIconVisible: false }));

      return model;
    });
  }

  populateFieldControlTypes(fieldTypeId: number) {

    if (fieldTypeId) {
      const listFieldControlTypeDtos = this._listFieldControlTypeDtos.find(x => x.fieldTypeId === fieldTypeId);

      this._fieldControlTypeSelectListOptions = listFieldControlTypeDtos.controlType.map((fieldControlTypeDto: FieldControlTypeDto) =>
                                        new SelectListOption(fieldControlTypeDto.controlTypeId, fieldControlTypeDto.controlTypeName));

      if (this._fieldControlTypeSelectListOptions.length === 1) {
        this._addContactFieldFormGroup.get('fieldControlTypes').setValue(this._fieldControlTypeSelectListOptions[0].value);
      }
    }
  }

  populateFieldTypes() {
    this._fieldTypeSelectListOptions = this._listFieldControlTypeDtos
          .map((listFieldControlTypeDto: ListFieldControlTypeDto) =>
          new SelectListOption(listFieldControlTypeDto.fieldTypeId, listFieldControlTypeDto.fieldTypeName));
  }

  removeSelectToggles() {
    document.getElementById('dataTableFormGroup').querySelectorAll('.mdb-modal-select-toggle').forEach(x => x.remove());
  }

  subscribeToFieldTypes() {
    this._addContactFieldFormGroup.get('fieldTypes').valueChanges.subscribe((fieldTypeId: number) => {
      this.populateFieldControlTypes(fieldTypeId);
    });
  }

  subscribeToHeaderRowIsIncludedCheckbox() {
    this.uploadOptionsFormGroup.get('includeHeaderRow').valueChanges.subscribe((checked: boolean) => {

      this._includeHeaderRow = checked;

      if (!this._includeHeaderRow) {
        this._rows.unshift(this._headerRow);
        this._rows = this._rows.slice(0);
      } else {
        this._rows = this._rows.slice(1);
      }
    });
  }

  // give table pagination functionality
  // as the sql behind the data does not do this for us
  paginateTable(pageSize?: number, page?: number)
  {
    if(pageSize) this._pageLimit = pageSize;
    if(page) this._currentPage = page;
    this._pageOffset = this._currentPage - 1;
  }

  // allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    this.paginateTable(parseInt(limit), null);
  }

  editName(newName: string): void {
    const dialog = this.matDialog.open(UploadContactsModalComponent, { width: '500px', data: { newName }});

    dialog.afterClosed().subscribe((name) => {
      if (name) {
        this.newName = name;
      }
    });
  }

  uploadFile() {
    const dialog = this.matDialog.open(UploadContactsModalComponent, {
      width: '500px', data: {
        toNewList: false,
        newUpload: true,
        selectedList: this.selectedListId
      }
    });

    dialog.afterClosed().subscribe((data) => {
      this.file = data?.file;
    });
  }
}
