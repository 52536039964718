import { Injectable } from '@angular/core';
import { TriggerExecutionDataModel } from './trigger-execution-data-model';

@Injectable({
  providedIn: 'root'
})

export class TriggerExecutionDataModelAdapter {
  adapt(item: any): TriggerExecutionDataModel {
    return new TriggerExecutionDataModel(item);
  }

  adaptArray(init: Array<any>): TriggerExecutionDataModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new TriggerExecutionDataModel(table))
      : [new TriggerExecutionDataModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new TriggerExecutionDataModel({
      TriggerTarget: null,
      ContactsFilter: { MessageCodeStepId: 0, SqlFilter: ''},
      SendMessage: null,
      NotifyContacts: null,
      SendViaApi: null,
      UpdateContact: { Changes: [] },
      Scheduling: null,
      Extract: null,
      SecondListInfo: null
    });
  }

  createEmptyForTwoLists() {
    return new TriggerExecutionDataModel({
      TriggerTarget: null,
      ContactsFilter: { MessageCodeStepId: 0, SqlFilter: ''},
      SendMessage: null,
      NotifyContacts: null,
      SendViaApi: null,
      UpdateContact: { Changes: [] },
      Scheduling: null,
      Extract: null,
      SecondListInfo: { 
        UpdateContact: { Changes: [] },
        ContactsFilter: { MessageCodeStepId: 0, SqlFilter: ''},
        ListId: 0
      }
    });
  }
}
