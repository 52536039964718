<app-modal-container [title]="title"
                     [actionButtonName]="buttonName"
                     [warning]="true"
                     [buttonValidation]="validatioUpload()"
                     (action)="data.newName ? editName() : upload()">

  <form [formGroup]="uploadFileFormGroup">
    <app-md-input *ngIf="data.toNewList || data.newName" label="New List Name" formControlName="name"></app-md-input>

    <div *ngIf="data.newUpload" class="md-form file-field form-group pt-2">
      <div class="btn-choose-file">
        <span>Choose file</span>
        <input mdbInput mdbFileSelect
               type="file"
               [options]="fileUploaderOptions"
               [uploadInput]="uploadInput"
               (uploadOutput)="onUploadOutput($event)" >
      </div>

      <div class="file-path-wrapper">
        <input mdbInput
               class="file-path"
               type="text"
               placeholder="Upload your file"
               [value]="file?.name || ''"
               readonly>
        <mdb-error *ngIf="isInvalidFileName() && file" class="pt-1">
          File name should not contain special characters (+ .&apos; &quot; ! @ # $ % ^ &amp; * ( ) + / = : | , &lt; &gt; ? ~)
        </mdb-error>
      </div>
    </div>
  </form>
</app-modal-container>


