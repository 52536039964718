import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryBuilderHelper {
    constructor() { }

    //Method to remove all occurrences of ISNULL from string
    removeIsNullFromSQL(sql) {
        var modifiedSQLQuery = '';

        if (sql.includes('ISNULL')) modifiedSQLQuery = sql.replaceAll('ISNULL(','').replaceAll(', \'\')', '');
        else modifiedSQLQuery = sql;

        return modifiedSQLQuery;
    }
}