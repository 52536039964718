export class ClientETLsDto {
  ActiveFlag: boolean;
  ClientName: string;
  DatabaseName: string;
  DMS: string;
  MDLClientDMSId: number;
  ClientId: number;
  SourceDirectory: string;
  Type: string;
  CreatedById: number;
  CreatedDate: Date;
  UpdatedById: number;
  UpdatedDate: Date;
  DecommissionedById: number;
  DecommissionedDate: Date;

  constructor(init: any) {
    this.ActiveFlag = init.ActiveFlag;
    this.ClientName = init.ClientName;
    this.DatabaseName = init.DatabaseName;
    this.DMS = init.DMS;
    this.MDLClientDMSId = init.MDLClientDMSId;
    this.ClientId = init.ClientId;
    this.SourceDirectory = init.SourceDirectory;
    this.Type = init.Type;
    this.CreatedById = init.CreatedById;
    this.CreatedDate = init.CreatedDate;
    this.UpdatedById = init.UpdatedById;
    this.UpdatedDate = init.UpdatedDate;
    this.DecommissionedById = init.DecommissionedById;
    this.DecommissionedDate = init.DecommissionedDate;
  }
}
