import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/localStorage.service';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  baseUrl = environment.apiURL;
  userClients = new Subject<any>();

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  getUserClients(): Observable<ExecutionResultDto> {
    return this.http
      .get(this.baseUrl + 'User/UserClients/' + this.localStorageService.getUserId)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  updateUserClients(): void {
    let userClients = [];

    if (this.localStorageService.getUserId) {
      this.getUserClients().subscribe((clients: ExecutionResultDto) => {
        clients.data.forEach((client: ClientDto) => {
          userClients = [...userClients, {
            value: client.ClientID,
            label: client.KnownAs
          }];
        });

        this.userClients.next(userClients);
      });
    }
  }
}


