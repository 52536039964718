import { SMSRepliesModel } from './sms-replies-model';

export class SMSMessageReportModel {
  public MessageName: string;
  public MessageBody: string;
  public ListName: string;
  public TotalSent: number;
  public SendDate: string;
  public ActivityPending: number;
  public ActivityDelivered: number;
  public ActivityBounced: number;
  public ActivityUndelivered: number;
  public ActivityTotalReplies: number;
  public ActivityTotalUniqueReplies: number;
  public SMSReplies: SMSRepliesModel;

  constructor(init: any) {
    this.MessageName = init.MessageName;
    this.MessageBody = init.MessageBody;
    this.ListName = init.ListName;
    this.TotalSent = init.TotalSent;
    this.SendDate = init.SendDate;
    this.ActivityPending = init.ActivityPending;
    this.ActivityDelivered = init.ActivityDelivered;
    this.ActivityBounced = init.ActivityBounced;
    this.ActivityUndelivered = init.ActivityUndelivered;
    this.ActivityTotalReplies = init.ActivityTotalReplies;
    this.ActivityTotalUniqueReplies = init.ActivityTotalUniqueReplies;
    this.SMSReplies = init.SMSReplies;
  }
}
