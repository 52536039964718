import { Injectable } from '@angular/core';
import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';

@Injectable({
  providedIn: 'root'
})

export class NetworkAdapter {
  public adapt(item: any) {
    return new NetworkDto(item);
  }
}
