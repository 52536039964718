import { Injectable } from '@angular/core';

export class UserDto {

    public userId: number;
    public defaultClient: number;
    public active: boolean;
    public title: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public username: string;
    public address1: string;
    public address2: string;
    public postCode: string;
    public userIconUrl: string;

    constructor(init: any) {
      this.userId = init.UserId;
      this.defaultClient = init.DefaultClient;
      this.active = init.Active;
      this.title = init.Title;
      this.username = init.UserName;
      this.firstName = init.FirstName;
      this.lastName = init.LastName;
      this.email = init.Email;
      this.address1 = init.Address1;
      this.address2 = init.Address2;
      this.postCode = init.PostCode;
      this.userIconUrl = init.UserIconUrl;
    }
}

@Injectable({
  providedIn: 'root'
})

export class UserAdapter {
  adapt(item: any): UserDto {
    return new UserDto(item);
  }
}
