import { ChangeTypeEnum } from '../../trigger-enums/change-type.enum';

export class ChangeModel {
  public Name: string;
  public Argument: string;
  public UpdateType: ChangeTypeEnum;
  public UpdateTypeName: string;

  constructor(init: any) {
    this.Name = init.Name;
    this.Argument = init.Argument;
    this.UpdateType = init.UpdateType;
    this.UpdateTypeName = init.UpdateTypeName;
  }
}
