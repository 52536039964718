import { ListTypeEnum } from './list-type-enum';

export class CopyListModel {
  public CopyListName: string;
  public ImportContactsRequired: boolean;
  public DestinationFolderId: number;
  public SourceListId: number;
  public ListType: ListTypeEnum;

  constructor(init: any) {
    this.CopyListName = init.CopyListName;
    this.ImportContactsRequired = init.ImportContactsRequired;
    this.DestinationFolderId = init.DestinationFolderId;
    this.SourceListId = init.SourceListId;
    this.ListType = init.ListType;
  }
}
