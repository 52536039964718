import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { GetBucketObjectsModel } from 'src/app/_models/media-server/get-bucket-objects-model';
import { GetBucketObjectsModelAdapter } from 'src/app/_models/media-server/get-bucket-objects-model.adapter';
import { MediaServerService } from 'src/app/_services/media-server/media-server.service';
import { ListObjectsV2ResponseModelAdapter } from 'src/app/_models/media-server/S3Models/ListObjectsV2Response.model.adapter';
import { ListObjectsV2ResponseModel } from 'src/app/_models/media-server/S3Models/ListObjectsV2Response.model';
import { S3ObjectsModel } from 'src/app/_models/media-server/S3Models/S3Objects.model';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { MediaServerBucketsModelAdapter } from 'src/app/_models/media-server/media-server-buckets-model.adapter';
import { MediaServerBucketModel } from 'src/app/_models/media-server/media-server-buckets-model';
import { environment } from 'src/environments/environment';
import { ModalDirective, UploadInput, UploadOutput, UploadFile, humanizeBytes, MDBFileSelectDirective } from 'ng-uikit-pro-standard';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { MediaServerFolderModel } from 'src/app/_models/media-server/media-server-folder-model';
import { DatePipe } from '@angular/common';
import { MediaServerAllFoldersModel, MediaServerAllFoldersModelAdapter } from 'src/app/_models/media-server/media-server-all-folders-model';
import { MoveCopyFilesDto } from 'src/app/_models/media-server/move-copy-files-dto';
import { PagerService } from 'src/app/_services/system/pagination/pager.service';
import { VideoThumbnailModel } from 'src/app/_models/media-server/video-thumbnail-model';
import { VideoThumbnailModelAdaptor } from 'src/app/_models/media-server/video-thumbnail-model-adpator';

@Component({
  selector: 'app-media-server',
  templateUrl: './media-server.component.html',
  styleUrls: ['./media-server.component.scss']
})
export class MediaServerComponent implements OnInit {

  _maxNumberOfBuckets: number = environment.maxClientBuckets;
  _baseUrl = environment.apiURL;

  _folder: string = "";
  _getBucketOptionsModel: GetBucketObjectsModel;
  _files: ListObjectsV2ResponseModel;
  _loading: boolean = false;
  _bucketsOptions: Array<any> = [];
  _buckets: Array<MediaServerBucketModel> = [];
  _bucketNameValue: string = '';
  _createBucketLoading: boolean = false;
  _createFolderLoading: boolean = false;
  _copyMoveFileLoading: boolean = false;
  _uploadingFiles: boolean = false;
  _uploadFiles: Array<any> = [];

  // File uploader things
  humanizeBytes: Function;
  dragOver: boolean;
  uploadInput: EventEmitter<UploadInput>;
  formData: FormData;
  uploadForm: UntypedFormGroup;

  // File Details Modal
  _fileDetailsModalData: S3ObjectsModel;
  _fileDetailsModalImageNaturalWidth: number = 0;
  _fileDetailsModalImageNaturalHeight: number = 0;
  _fileDetailsModalImageNaturalSize: string = "0x0"
  _fileDetailsModalImage: HTMLImageElement = new Image();
  _savingRenameFile: boolean = false;

  // Edit Folder
  _deleteFolderName: string = '';
  _deletingFolder: boolean = false;

  // File Selection
  _selectedFiles: Array<S3ObjectsModel> = [];
  _loadingFolders: boolean = false;
  _allFolders: MediaServerAllFoldersModel;  

  // pagination
  _allFiles: S3ObjectsModel[] = [];
  _filteredFiles: S3ObjectsModel[] = [];
  _pagedFiles: S3ObjectsModel[] = [];
  _pager: any = {};

  _videoThumbnails: VideoThumbnailModel[] = [];

  @ViewChild('createMediaServerModal', { static: true }) createMediaServerModal: ModalDirective;
  @ViewChild('createFolderModal', { static: true }) createFolderModal: ModalDirective;
  @ViewChild('uploadModal', { static: true }) uploadModal: ModalDirective;
  @ViewChild('fileDetailsModal', { static: true }) fileDetailsModal: ModalDirective;
  @ViewChild('deleteFileModal', { static: true }) deleteFileModal: ModalDirective;
  @ViewChild('deleteFolderModal', { static: true }) deleteFolderModal: ModalDirective;
  @ViewChild('copyModal', { static: true }) copyModal: ModalDirective;
  @ViewChild('moveModal', { static: true }) moveModal: ModalDirective;
  @ViewChild('uploadFile', { static: true }) uploadFile: MDBFileSelectDirective;

  _createMediaServerForm = this.fb.group(
    {
      BucketFriendlyName: ['', Validators.required]
    },
    {
      validators: [this.checkMediaServerName]
    }
  )

  _editFileNameForm = this.fb.group({
    key: ['', Validators.required],
    newKey: ['', Validators.required]
  } )

  _createFolderForm = this.fb.group({
    FolderName: ['', Validators.required]
  }, {
    validators: [this.checkFolderNameValidator]
  })

  get _bucketName() {
    return this._bucketNameValue;
  }

  set _bucketName(value: string) {
    this._bucketNameValue = value;
    this._folder = '';
    this.getBucketObjects();    
  } 


  constructor(private getBucketObjectsModelAdapter: GetBucketObjectsModelAdapter,
    private mediaServerService: MediaServerService,
    private listObjectV2ResponseModelAdapter: ListObjectsV2ResponseModelAdapter,
    private mediaServerBucketsAdapter: MediaServerBucketsModelAdapter,
    private videoThumbnailModelAdaptor: VideoThumbnailModelAdaptor,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private mediaServerAllFoldersModelAdapter: MediaServerAllFoldersModelAdapter,
    private pagerService: PagerService) { }

  ngOnInit() {
    this.getBuckets();
    this.humanizeBytes = humanizeBytes;
    this.uploadInput = new EventEmitter<UploadInput>()
    this.uploadForm = this.fb.group({
      BucketName: [''],
      Key: [''],
      File: ['']
    });
  }

  checkFolderNameValidator(g: UntypedFormGroup) {
    if (!g.get("FolderName").value) {
      return null
    }
    else {
      var name: string = g.get("FolderName").value;

      if (name.match(/[^a-zA-Z0-9.\-_]/)) {
        return { "invalidChars": true };
      } else {
        return null;
      }
    }

  }

  checkMediaServerName(fg: UntypedFormGroup) {
    var bucketName = fg.get("BucketFriendlyName").value;

    if (!bucketName) {
      return null;
    } else {
      if (bucketName.includes('.')) return { "invalidChars": true }
      else return null;
    }
  }

  getBucketObjects() {
    this._loading = true;
    this._getBucketOptionsModel = this.getBucketObjectsModelAdapter.adapt({
      Delimiter: '/',
      Prefix: this._folder,
      MaxKeys: 1000
    })

    this.mediaServerService.getBucketObjects(this._getBucketOptionsModel, '/' + this._bucketName).subscribe((result) => {

      this._files = this.listObjectV2ResponseModelAdapter.adapt(result.data.s3);
      this._files.S3Objects = this._files.S3Objects.filter((file: S3ObjectsModel) => file.Key !== this._folder);

      this._allFiles = this._files.S3Objects;
      this._filteredFiles = this._allFiles;

      if (result.data.VideoThumbnails) {        

        this._videoThumbnails = result.data.VideoThumbnails.map((vt: VideoThumbnailModel) => this.videoThumbnailModelAdaptor.adapt(vt)) ;
        
        this._videoThumbnails.forEach(vt => {
          if (this._filteredFiles.some(ff => ff.Key === vt.videoFilename)) {
            this._filteredFiles.find(x => x.Key === vt.videoFilename).VideoThumbnailUrl = vt.videoThumbnailFilenameUrl;
          }
        });
      }      
      
      this.setPage(1);
      
      this._loading = false;
    });
  }  

  setPage(page: number) {
    this._pager = this.pagerService.getPager(this._filteredFiles.length, page);
    this._pagedFiles = this._filteredFiles.slice(this._pager.startIndex, this._pager.endIndex + 1);
  }

  folderClicked(folder: string) {
    this._folder = folder;
    this.mediaServerService.selectedFiles = [];
    this._selectedFiles = [];
    this.getBucketObjects();
  }

  deleteFolderClicked(folder: string) {
    this._deleteFolderName = folder;
    this.deleteFolderModal.show();
  }

  getFileExtension(file: string) {
    return file.substring(file.lastIndexOf('.') + 1);
  }

  isImageType(fileName: string) {
    switch (this.getFileExtension(fileName) ) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'heif':
        return true;
      default:
        return false
    }
  }

  getFileImageIcon(file: S3ObjectsModel): string {
    switch (this.getFileExtension(file.Key)) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'heif':
        return "https://"+this._bucketName+".s3.eu-west-2.amazonaws.com/"+file.Key;
      case 'xlsx':
        return "../../../assets/images/Icons/XLSX.png";
      case 'csv':
        return "../../../assets/images/Icons/CSV.png";
      case 'mov':
      case 'mp4':
        return this.getVideoThumbnailUrl(file.Key);
      default:
        return "../../../assets/images/Icons/BLANK.png";
    }
  }   

  getVideoThumbnailUrl(key: string) {       

    if (this._videoThumbnails.some(x => x.videoFilename === key)) {
      return this._videoThumbnails.find(x => x.videoFilename === key).videoThumbnailFilenameUrl;
    }

    return this.getFileExtension(key) === 'mp4' ? '../../../assets/images/Icons/MP4.png' :  '../../../assets/images/Icons/MOV.png';               
  }

  getFilePublicUrl(file: S3ObjectsModel): string {
    switch (this.getFileExtension(file.Key)) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'heif':
      case 'mp4':
      case 'mkv':
      case 'pdf':
        return "https://"+this._bucketName+".s3.eu-west-2.amazonaws.com/"+file.Key;
      default:
        return "N/A";
    }
  }

  getFileName(file: string): string {
    return file.substring(file.lastIndexOf('/') + 1);
  }

  folderBackClicked() {
    if((this._folder.match(/\//g) || []).length <= 1) {
      this._folder = '';
    }else {
      var split = this._folder.split('/');
      split.pop();
      split.pop();
      this._folder = split.join('/') + '/';
    }
    this.mediaServerService.selectedFiles = [];
    this._selectedFiles = [];
    this.getBucketObjects();
  }

  getFolderName(folder: string) {
    if(folder.lastIndexOf('/') === -1) return folder;
    var secondLastIndex = folder.lastIndexOf('/', folder.lastIndexOf('/')-1)
    return folder.substring(secondLastIndex+1, folder.length-1)
  }

  showFolderBackButton() {
    if((this._folder.match(/\//g) || []).length <= 0) {
      return false;
    }
    return true;
  }

  showCreateNewMediaServer() {
    return this._buckets.length < this._maxNumberOfBuckets ? true : false;
  }

  getBuckets() {
    this.mediaServerService.getBuckets().subscribe((result: ExecutionResultDto) => {
      this._buckets = this.mediaServerBucketsAdapter.adaptArray(result.data);

      this._bucketsOptions = [];
      var selectSet: boolean = false;
      this._buckets.forEach((bucket: MediaServerBucketModel, index) => {
        if(this._buckets.length === 1){
          this._bucketsOptions = [... this._bucketsOptions, {
            value: bucket.BucketName,
            label: bucket.BucketFriendlyName,
            selected: true
          }]

          this._bucketName = bucket.BucketName;
        } else if(!bucket.ShowAllFolders && !selectSet) {
          this._bucketsOptions = [... this._bucketsOptions, {
            value: bucket.BucketName,
            label: bucket.BucketFriendlyName,
            selected: true
          }]
          this._bucketName = bucket.BucketName;
          selectSet = true;
        } else {
          this._bucketsOptions = [... this._bucketsOptions, {
            value: bucket.BucketName,
            label: bucket.BucketFriendlyName
          }]
        }


      });
    })
  }


  createNewMediaServer() {
    this._createBucketLoading = true;

    var newBucketFriendlyName: string = this._createMediaServerForm.get("BucketFriendlyName").value;
    var newBucketName = newBucketFriendlyName.replace(/ /g, "").toLocaleLowerCase();

    var newMediaServer: MediaServerBucketModel = new MediaServerBucketModel({
      BucketName: newBucketName,
      BucketFriendlyName: newBucketFriendlyName,
      Id: 0
    })

    this.mediaServerService.createBucket(newMediaServer).subscribe((result: ExecutionResultDto) => {
      this.createMediaServerModal.hide();
      this._createBucketLoading = false;
      this.notificationService.showSuccess(result.message);
      this.getBuckets();
    }, () => this._createBucketLoading = false)

  }

  createNewFolder() {
    this._createFolderLoading = true;

    var newFolderName: string = this._createFolderForm.get("FolderName").value;
    newFolderName = this._folder + newFolderName;

    if(!newFolderName.endsWith('/')){
      newFolderName = newFolderName + '/'
    }

    var newFolderModel = new MediaServerFolderModel({
      FolderName: newFolderName,
      BucketName: this._bucketName
    })

    this.mediaServerService.createFolder(newFolderModel).subscribe((result: ExecutionResultDto) => {
      this._createFolderLoading = false;
      this.getBucketObjects();
      this.createFolderModal.hide();
      this._createFolderForm.reset();
      this.notificationService.showSuccess(result.message)
    }, () => this._createFolderLoading = false)

  }

  showFiles() {
    let files = '';
    for (let i = 0; i < this._uploadFiles.length; i ++) {
      files += this._uploadFiles[i].name;
       if (!(this._uploadFiles.length - 1 === i)) {
         files += ',';
      }
    }
    return files;
  }

  startUpload(): void {

      if (this._uploadFiles.length > 0) {
        this._uploadingFiles = true;
        var outerIndex = 0;
        var innerIndex = 0;
        this._uploadFiles.forEach((file: UploadFile, index: number) => {
          outerIndex = outerIndex + index;
        });

        this._uploadFiles.forEach((file: UploadFile, index: number) => {
          var formData = new FormData();
          formData.append('bucketName', this._bucketName);
          formData.append('key', this._folder + file.name);
          formData.append('file', file.nativeFile);

          this.mediaServerService.uploadFile(formData).subscribe((result: ExecutionResultDto) => {
            this.notificationService.showSuccess(result.message);
            this.getBucketObjects();
            this._uploadFiles = this._uploadFiles.filter((filterFile: UploadFile) => filterFile.name !== file.name)
            this.uploadModal.hide();
            this.showFiles();
            innerIndex = innerIndex + index;
            if (innerIndex === outerIndex) {
              this._uploadingFiles = false;
            }
          }, error => {
            innerIndex = innerIndex + index;
            if (innerIndex === outerIndex) {
              this._uploadingFiles = false;
            }
          });
        });
      }
  }

  onUploadOutput(output: UploadOutput | any): void {

      if (output.type === 'allAddedToQueue') {
      } else if (output.type === 'addedToQueue') {
        this._uploadFiles.push(output.file); // add file to array when added
      } else if (output.type === 'uploading') {
        // update current data in files array for uploading file
        const index = this._uploadFiles.findIndex(file => file.id === output.file.id);
        this._uploadFiles[index] = output.file;
      } else if (output.type === 'removed') {
        // remove file from array when removed
        this._uploadFiles = this._uploadFiles.filter((file: UploadFile) => file !== output.file);
      } else if (output.type === 'dragOver') {
        this.dragOver = true;
      } else if (output.type === 'dragOut') {
      } else if (output.type === 'drop') {
        this.dragOver = false;
      }
      this.showFiles();
  }

  closeUploadModal() {
    this.uploadModal.hide()
    this._uploadFiles = [];
    this.showFiles();
  }

  chooseFileClicked() {
    this._uploadFiles = [];
    this.showFiles();
  }

  updateFileDetailsImageSizeDetails(size: string) {
    this._fileDetailsModalImageNaturalSize = size
  }

  fileClicked(file: S3ObjectsModel) {
    this._fileDetailsModalData = file;
    this._editFileNameForm.get("key").setValue(this.getFileName(this._fileDetailsModalData.Key))
    this._editFileNameForm.get("newKey").setValue(this.getFileName(this._fileDetailsModalData.Key))
    this.fileDetailsModal.show();
    this.getMeta(this.getFilePublicUrl(this._fileDetailsModalData));
  }

  getMeta(url){
    if(url === "N/A") return url;
    var img = new Image();

    img.src = url;
    this._fileDetailsModalImage = img;
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'dd/MM/yyyy, h:mm a');
  }

  openDeleteFileModal() {
    this.fileDetailsModal.hide();
    this.deleteFileModal.show();
  }

  closeDeleteFileModal() {
    this.deleteFileModal.hide();
    this.fileDetailsModal.show();
  }

  deleteFile() {
    var formData = new FormData();
    formData.append('bucketName', this._bucketName)
    formData.append('key', this._fileDetailsModalData.Key)

    this.mediaServerService.deleteFile(formData).subscribe((result: ExecutionResultDto) => {
      this.notificationService.showSuccess(result.message);
      this.deleteFileModal.hide();
      this.getBucketObjects();
      this.mediaServerService.selectedFiles = [];
      this._selectedFiles = [];
    }, () => {

    })
  }

  deleteFolder() {
    var formData = new FormData();
    formData.append('bucketName', this._bucketName)
    formData.append('key', this._deleteFolderName.substring(0, this._deleteFolderName.length - 1))
    this._deletingFolder = true;

    this.mediaServerService.deleteFolder(formData).subscribe((result: ExecutionResultDto) => {
      this.notificationService.showSuccess(result.message);
      this.deleteFolderModal.hide();
      this.getBucketObjects();
      this._deleteFolderName = '';
      this._deletingFolder = false;

    }, () => {

    })
  }

  renameFile() {
    if (this._editFileNameForm.valid) {
      this._savingRenameFile = true;
      var formData = new FormData();
      formData.append('bucketName', this._bucketName);
      formData.append('key', this._folder + this._editFileNameForm.get("key").value);
      formData.append('newKey', this._folder + this._editFileNameForm.get("newKey").value);

      this.mediaServerService.renameFile(formData).subscribe((result) => {
        this.notificationService.showSuccess(result.message);
        this.getBucketObjects();
        this._editFileNameForm.reset();
        this.fileDetailsModal.hide();
        this._savingRenameFile = false;
      })
    }

  }

  // File Selections functions

  isFileSelected(file: S3ObjectsModel) {
    return this._selectedFiles.some((selectedFile: S3ObjectsModel) => {
      return selectedFile.Key === file.Key
    })
  }

  toggleFileSelect(file: S3ObjectsModel) {
    var selectedFile = this._selectedFiles.some((selectedFile: S3ObjectsModel) => {
      return selectedFile.Key === file.Key
    })

    if (selectedFile) {
      this._selectedFiles = this._selectedFiles.filter((selectedFile: S3ObjectsModel) => {
        return selectedFile.Key != file.Key
      })
    } else {
      this._selectedFiles.push(file);
    }

    this.mediaServerService.selectedFiles = this._selectedFiles;

  }

  // Copy and Move Functions

  getAllFolders() {
    this.mediaServerService.getAllFolders(this._bucketName).subscribe((result: ExecutionResultDto) => {
      this._allFolders = this.mediaServerAllFoldersModelAdapter.adapt(result.data);
      this._loadingFolders = false;
    })
  }

  openCopyModal() {
    this._loadingFolders = true;
    this.copyModal.show();
    this.getAllFolders();
  }

  openMoveModal() {
    this._loadingFolders = true;
    this.moveModal.show();
    this.getAllFolders();
  }

  copyFiles() {

    this._copyMoveFileLoading = true;
    const destinationFolderKey: string = this.mediaServerService.moveFileDestinationKey.replace(this._bucketName + ":\\", '');
    const moveCopyDto: MoveCopyFilesDto = new MoveCopyFilesDto({
      Action: 'copy',
      BucketName: this._bucketName,
      SourceFolderKey: this._folder,
      DestinationFolderKey: destinationFolderKey,
      Files: this.mediaServerService.selectedFiles.map((file)=> this.getFileName(file.Key))
    })

    this.mediaServerService.moveCopyFiles(moveCopyDto).subscribe((result) => {
      this.notificationService.showSuccess(result.message);
      this.getBucketObjects();
      this.copyModal.hide();
      this._copyMoveFileLoading = false;
    }, () => this._copyMoveFileLoading = false)
  }

  moveFiles() {

    this._copyMoveFileLoading = true;
    const destinationFolderKey: string = this.mediaServerService.moveFileDestinationKey.replace(this._bucketName + ":\\", '');
    const moveCopyDto: MoveCopyFilesDto = new MoveCopyFilesDto({
      Action: 'move',
      BucketName: this._bucketName,
      SourceFolderKey: this._folder,
      DestinationFolderKey: destinationFolderKey,
      Files: this.mediaServerService.selectedFiles.map((file)=> this.getFileName(file.Key))
    })

    this.mediaServerService.moveCopyFiles(moveCopyDto).subscribe((result) => {
      this.notificationService.showSuccess(result.message);
      this.getBucketObjects();
      this.moveModal.hide();
      this._copyMoveFileLoading = false;
      this.mediaServerService.selectedFiles = [];
      this._selectedFiles = [];
    }, () => this._copyMoveFileLoading = false)
  }

  clear() {
    this._selectedFiles = [];
    this.mediaServerService.selectedFiles = [];
  }


  filterItems(searchText: any) {

    this._filteredFiles = this._allFiles.filter(x => this.getFileName(x.Key.toLowerCase()).indexOf(searchText.toLowerCase()) > -1);

    this.setPage(1);
  }
}
