export enum PermissionsMessagingPagesEnum {
  Messages = 1,
  MessagesEmail = 2,
  MessagesSMS = 3,
  MessagesReports = 4,
  MessagesReportsEmail = 5,
  MessagesReportsSMS = 6,
  ListsAndContact = 7,
  ListsAndContactLists = 8,
  ListsAndContactListsForms = 9,
  ListsAndContactContacts = 10,
  Automation = 11,
  AutomationContactPlans = 12,
  AutomationContactPlansContactSteps = 13,
  AutomationMessageCodes = 14,
  AutomationMessageCodesMessageCodeSteps = 15,
  AutomationExtracts = 16,
  AutomationExtractsSteps = 17
}
