export class OverviewMetricsReportDto {
  public TotalPosts: number;
  public TotalFollowers: number;
  public NewFollowers: number;
  public EngagementRate: number;
  public TotalImpressions: number;

  constructor(init: {
    TotalPosts: number;
    TotalFollowers: number;
    EngagementRate: number;
    TotalImpressions: number;
  }) {
    this.TotalPosts = init.TotalPosts;
    this.TotalFollowers = init.TotalFollowers;
    this.EngagementRate = init.EngagementRate;
    this.TotalImpressions = init.TotalImpressions;
  }
}  