import { Injectable } from '@angular/core';
import { QueryBuilderFilterDto } from './query-builder-filter-dto';

@Injectable({
  providedIn: 'root'
})

export class QueryFilterDtoAdapter {

  adapt(item: any): QueryBuilderFilterDto {
    return new QueryBuilderFilterDto(item);
  }

  adaptArray(init: Array<any>): QueryBuilderFilterDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new QueryBuilderFilterDto(table))
      : [new QueryBuilderFilterDto(init[0])];
    } else {
      return [];
    }
  }
  
}
