import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent, ModalDirective } from 'ng-uikit-pro-standard';
import { ActivatedRoute, RouterOutlet, Router } from '@angular/router';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { FormsService } from 'src/app/_services/messaging/lists-and-contacts/forms/forms.service';
import { WebFormViewModel } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-view-model';
import { WebFormViewModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-view-model.adapter';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common';
import { WebFormTypeViewModel } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-type-view-model';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';


@Component({
  selector: 'app-edit-forms',
  templateUrl: './edit-forms.component.html',
  styleUrls: ['./edit-forms.component.scss']
})
export class EditFormsComponent implements OnInit {

  @ViewChild('webFormTabs', { static: true }) webFormTabs: TabsetComponent;
  @ViewChild('editFormNameModal', {static: true}) editFormNameModal: ModalDirective;
  @ViewChild('copyFormModal', {static: true}) copyFormModal: ModalDirective;
  @ViewChild('deleteFormModal', {static: true}) deleteFormModal: ModalDirective;
  @ViewChild('addFormModal', {static: true}) addFormModal: ModalDirective;

  _listID: number;
  _userPermissions: PermissionsModel;
  _allWebForms: Array<WebFormViewModel>;
  _list: ListModel;
  _listName: string = '';
  _formNameDropdown: Array<any> = [];
  _formTypeDropdown: Array<any> = [];
  _activeForm: WebFormViewModel;
  _firstLoad: boolean = true;
  _activeTabIndex: number = 1;
  _cannotDelete: boolean = true;
  message: string;

  _addNewFormForm = new UntypedFormGroup ({
    newFormType: new UntypedFormControl ('', Validators.required),
    formName: new UntypedFormControl ('', Validators.required),
  });

  _editFormNameForm = new UntypedFormGroup ({
    oldName: new UntypedFormControl('', Validators.required),
    newName: new UntypedFormControl('', Validators.required),
  });

  _copyFormForm = new UntypedFormGroup ({
    formType: new UntypedFormControl ({disabled: true}),
    formName: new UntypedFormControl ('', Validators.required),
  });

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private permissionsService: PermissionsService,
    private formsService: FormsService,
    private listsService: ListsService,
    private notificationService: NotificationService,
    private webFormViewModelAdapter: WebFormViewModelAdapter,
    private location: Location,
    private localStorageService: LocalStorageService,

  ) {
   }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params =>
      this._listID = params['listId']
    );

    this._userPermissions = this.permissionsService.getPermissionsModel();

    this.getListForms();
    this.getList();
  }

  backClicked() {
    this.location.back();
  }

  getListForms() {
    this._allWebForms = [];
    this.formsService.getListWebForms(this._listID).subscribe(result => {
      this.assignListID(result.data);
      this._allWebForms = this.webFormViewModelAdapter.adaptArray(result.data);
      this.populateFormNameDropdown();
      this.populateFormTypeDropdown();
      this.sortWebFormOrder();
      if (this._firstLoad) {
        setTimeout(() => {
          this.webFormTabs.setActiveTab(this._activeTabIndex);
        }, 1);
        this._firstLoad = false;
      } else {
        setTimeout(() => {
          this.webFormTabs.setActiveTab(this._activeTabIndex);
        }, 1);
      }
    });
  }

  populateFormNameDropdown() {
    this._formNameDropdown = [];
    this._allWebForms.forEach((form: WebFormViewModel) => {
      this._formNameDropdown = [... this._formNameDropdown, {
        value: form.WebFormID,
        label: form.WebFormName,
      }];
    });
  }

  populateFormTypeDropdown() {
    this._formTypeDropdown = [];
    this.formsService.getFormTypes().subscribe(result => {
      result.data.forEach((formType: WebFormTypeViewModel) => {
        this._formTypeDropdown = [... this._formTypeDropdown, {
          value: formType.WebFormTypeID,
          label: formType.WebFormTypeName,
        }];
      });
    });
  }

  sortWebFormOrder() {
    this._allWebForms.forEach(form => {
      form.ListWebFormPages.forEach(formPage => {
        if (formPage.OrderIndex === null) {
          formPage.OrderIndex = form.ListWebFormPages.length;
        }
      });
      form.ListWebFormPages.sort(this.compare);
    });
  }

  compare(a, b) {
    const orderIndexA = a.OrderIndex;
    const orderIndexB = b.OrderIndex;

    let comparison = 0;
    if (orderIndexA > orderIndexB) {
      comparison = 1;
    } else if (orderIndexA < orderIndexB) {
      comparison = -1;
    }
    return comparison;
  }

  getList() {
    this.listsService.getListById(this._listID).subscribe(result => {
      this._list = result.data;
      this._listName = result.data[0].ListName;
    });
  }

  onActivate(componentReference, webForm: WebFormViewModel) {
    componentReference.displayForm(webForm);
  }

  onGetActiveTab($event) {
    if (this._allWebForms && $event) {
      this._activeForm = this._allWebForms[$event.activeTabIndex];
      this._activeTabIndex = $event.activeTabIndex + 1;
      this._activeForm.IsDefault ?  this._cannotDelete = true : this._cannotDelete = false;
      this._editFormNameForm.get('oldName').setValue(this._activeForm.WebFormID);
      this._copyFormForm.get('formType').setValue(this._activeForm.WebFormTypeName);
      this._copyFormForm.get('formType').disable();
      this._copyFormForm.get('formName').setValue('Copy of ' + this._activeForm.WebFormName);
    }
  }
  addForm() {
    const webFormTypeID = this._addNewFormForm.controls.newFormType.value;
    const webFormName = this._addNewFormForm.controls.formName.value;

    this.formsService.newForm(this._listID, webFormTypeID, webFormName).subscribe(result => {
      this.notificationService.showSuccess(result.message);
      this.addFormModal.hide();
      this._addNewFormForm.get('formName').setValue('');
      this.getListForms();
    });
  }

  editFormName() {
    const webFormID = this._editFormNameForm.controls.oldName.value;
    const newName = this._editFormNameForm.controls.newName.value;
    this.formsService.editFormName(this._listID, webFormID, newName).subscribe(result => {
      this.notificationService.showSuccess(result.message);
      this.editFormNameModal.hide();
      this._editFormNameForm.get('newName').setValue('');
      this.getListForms();
    });
  }

  copyForm() {
    const copyFormName = this._copyFormForm.get('formName').value;
    this.formsService.copyForm(this._activeForm.WebFormID, copyFormName).subscribe(result => {
      this.copyFormModal.hide();
      this.notificationService.showSuccess(result.message);
      this.getListForms();
    });
  }

  deleteForm() {
    this.formsService.deleteForm(this._activeForm.WebFormID, this._activeForm.WebFormName).subscribe(result => {
      this.deleteFormModal.hide();
      this.notificationService.showSuccess(result.message);
      this.getListForms();
      this.webFormTabs.setActiveTab(1);
    });
  }

  assignListID(webForms: Array<WebFormViewModel>) {
    webForms.forEach(webForm => {
      const listID = webForm.ListID;
      webForm.ListWebFormPages.forEach(formPage => {
        formPage.ListID = listID;
      });
    });
  }

  openPreviewFormNewTab() {
    var webformId = this._activeForm.UniqueID;
    var url = environment.formPublishURL + "formId=" + webformId + "&msgId=&cId=" + this.localStorageService.getCurrentClientId;
    window.open(url, "_blank")
  }
}
