import { EventTypeEnum } from '../trigger-enums/event-type.enum';
import { MessageTypeEnum } from '../../../messages/message-type-enum';

export class TriggerTargetModel {
  public SubjectType: EventTypeEnum;
  // public SubjectTypeName: string;
  public SubjectEventType: number;
  public EventName: string;
  public ListId: number;
  public MessageId: number;
  public MessageType: MessageTypeEnum;
  public MessageLinkId: number;
  public FieldName: string;
  public WebFormId: number;
  public TargetUrl: string;

  constructor(init: any) {
    this.SubjectType = init.SubjectType;
    // this.SubjectTypeName = init.SubjectTypeName;
    this.SubjectEventType = init.SubjectEventType;
    this.EventName = init.EventName;
    this.ListId = init.ListId;
    this.MessageId = init.MessageId;
    this.MessageType = init.MessageType;
    this.MessageLinkId = init.MessageLinkId;
    this.FieldName = init.FieldName;
    this.WebFormId = init.WebFormId;
    this.TargetUrl = init.TargetUrl;
  }
}
