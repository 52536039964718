<!--SMS Message-->
<div class="row ml-4 mr-4 mt-4 mb-1">
  <div class="col-md-12 message-send-page">
    <div class="navigation">
      <img class="image-back" src="../../../../../assets/images/back.png" (click)="navigateToMessagesPage()" alt="Back">
      <span class="label-back">Back to Messages</span>
    </div>
    <div class="title-box">
      <span class="title-section">Send {{this._selectedMessageType === this._messageTypeEnumEmail ?  'Email' : 'SMS Message' }}</span>
    </div>

    <div class="message-send-section">
      <span class="title-sub-section">Send {{this._selectedMessageType === this._messageTypeEnumEmail ?  'Email' : 'SMS Message' }}: '{{this._messageName}}'</span>
      <hr class="mt-1 mb-0">
      <!-- First Row -->
      <!-- Form -->
      <form [formGroup]="_formGroupConfigurator" (ngSubmit)="onConfiguratorFormGroupSubmit()">
        <div class="form-body pt-2">
          <div class="form-row">
            <div class="col-md-2">
              <label for="listFolders" class="input-label">Folder</label>
              <select class="form-control select-input"
                      name="listFolders"
                      id="folders" formControlName="listFolders">
                <option value=0 hidden>Select Folder</option>
                <option *ngFor="let listFolder of _listFolderSelectListOptions; let i = index"
                        [value]="listFolder.value">
                  {{listFolder.label}}
                </option>
              </select>
            </div>
            <div class="col-md-2">
              <label for="list" class="input-label">List</label>
              <select class="form-control select-input"
                      name="list" formControlName="lists"
                      id="lists">
                <option value=0  hidden>Select List</option>
                <option *ngFor="let list of _listsSelectListOptions; let i = index"
                        [value]="list.value">
                  {{list.label}}
                </option>
              </select>
            </div>
            <div *ngIf="this._selectedMessageType === this._messageTypeEnumEmail; then emailBlock else smsBlock"></div>
            <ng-template #emailBlock>
              <div class="col-md-4">
                <label class="input-label" for="from">From</label>
                <input type="text" id="from" class="form-control input-style" formControlName="from" readonly />
              </div>
              <div class="col-md-2">
                <label class="input-label" for="subject">Subject</label>
                <input type="text" id="subject" class="form-control input-style" formControlName="subject" readonly>
              </div>
            </ng-template>
            <ng-template #smsBlock>
              <div class="col-md-6" *ngIf="this._selectedMessageType === this._messageTypeEnumSms">
                <label for="message" class="input-label">Message</label>
                <textarea mdbTooltip="Text is read only" formControlName="message" type="text" id="message"
                          class="form-control textarea-style" readonly></textarea>
              </div>
            </ng-template>
            <div class="col-md-2 button-box">
              <div class="d-flex justify-content-end">
                <button *ngIf="this._sending"
                        type="button"
                        class="btn-okay rounded mb-2"
                        disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Sending...
                </button>
                </div>
              <div class="d-flex justify-content-end">
                <button
                        *ngIf="(this._selectedMessageType === _messageTypeEnumEmail && this._userPermissions.Messaging.MessagesEmail.Send ||
                        this._selectedMessageType === _messageTypeEnumSms && this._userPermissions.Messaging.MessagesSMS.Send)
                        && !this._sending"
                        [disabled]="!this._tableHasRows || this._sendButtonDisabled"
                        type="submit"
                        class="btn-okay mb-2">
                  <span>
                    <mdb-icon fas icon="envelope" size="lg"></mdb-icon>
                    Send
                  </span>
                </button>
                </div>
              <div class="d-flex justify-content-end">
                <button type="button"
                        class="btn-cancel mb-2"
                        [routerLink]="['/messages']">
                  <span class="text-truncate">Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- Form -->
      <!-- /First Row -->
    </div>

    <div class="messages-table-section">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <span class="title-table-section">Contacts</span>
        </div>
        <div>
          <button *ngIf="this.filterContactRules.length === 0" [disabled]="_selectedListId === 0"
                  mdbBtn outline="true" rounded="true" size="sm" type="button"
                  class="table-button px-2 mr-2" mdbWavesEffect (click)="filterContactsModal.show()">
            <mdb-icon fas size="lg" class="table-icon" icon="search"></mdb-icon>
          </button>
        </div>
        <div>
          <button *ngIf="this.filterContactRules.length > 0" [disabled]="_selectedListId === 0"
                  mdbBtn outline="true" rounded="true" size="sm" type="button"
                  mdbTooltip="An active filter is set, click here to clear or edit it."
                  placement="top" class="table-button active px-2 mr-2" mdbWavesEffect
                  (click)="filterContactsModal.show()">
            <mdb-icon class="table-icon" fas size="lg" icon="search"></mdb-icon>
          </button>
        </div>
        <div>
          <button [disabled]="!_tableHasRows" mdbBtn outline="true" rounded="true" size="sm"
                  type="button" class="px-2 table-button mr-0" mdbWavesEffect (click)="columnOptions.toggle()">
            <mdb-icon fas size="lg" class="table-icon" icon="columns"></mdb-icon>
          </button>
        </div>
      </div>
      <hr class="hr-contact">

        <mdb-card-body cascade="true" class="text-center pr-0 pt-0 pl-0">
          <section>
            <div class="container-fluid pl-2 pr-2">
              <div class="row text-left" mdbCollapse #columnOptions="bs-collapse">
                <div class="col-3 font-size-12" *ngFor="let col of _allColumns; index as i">
                  <mat-checkbox id="checkbox-{{i}}"
                                [checked]="col.visible"
                                [value]="col.prop"
                                (change)="changeVisibleColumn($event, col)">
                    {{col.name}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </section>
        </mdb-card-body>

        <div *ngIf="_tableHasRows" class="mat-table-container">
          <table mat-table [dataSource]="_data" matSort (matSortChange)="onSortColumn()">
            <ng-container [matColumnDef]="column.Name" *ngFor="let column of _displayedColumns; let i = index;">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">{{column.DisplayName}}</th>
              <td mat-cell *matCellDef="let element">
                {{  element[column.Name] }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="_columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: _columnsToDisplay;"></tr>
          </table>
          <mat-paginator [hidden]="!_tableHasRows"
                         [length]="_pageCount"
                         [pageSize]="_pageLimit"
                         [pageSizeOptions]="_pageSizeOptions"
                         [pageIndex]="_pageIndex"
                         (page)="onChangePage($event)">
          </mat-paginator>
        </div>
        <div *ngIf="!_tableHasRows">
          <span class="label-no-data">No data to display.</span>
        </div>
      </div>
    </div>
</div>

    <!-- Confirm Send Modal -->
    <div mdbModal #confirmSendModal="mdb-modal" class="modal fade" id="confirmSendModal" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-notify modal-warning" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p class="heading lead">Confirm Send</p>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="confirmSendModal.hide()">
              <span aria-hidden="true" class="white-text">×</span>
            </button>
          </div> <!--modal-header-->
          <div class="modal-body">
            <div class="text-center mb-3">
              <mdb-icon fas icon="question-circle" size="4x" class="animated rotateIn"></mdb-icon>
            </div>  <!--text-center-->
            <form [formGroup]="_formGroupConfirmSend" (ngSubmit)="this.onConfirmSendFormGroupSubmit()">
              <div>
                <div *ngIf="_countUniqueContacts > 0 && filterContactRules === ''" class="mb-3">
                  {{_selectedMessageType === _messageTypeEnumEmail ? "Email" : "SMS"}} message will be sent to {{_countUniqueContacts}} contact{{ _countUniqueContacts > 1 ? 's' : ''  }}
                  excluding duplicates. <br><br> If you press OK, You won't be able to revoke this action.
                </div>
                <div *ngIf="_countUniqueContacts > 0 && filterContactRules.length > 0" class="mb-3">
                  {{_selectedMessageType === _messageTypeEnumEmail ? "Email" : "SMS"}} message will be sent to {{_countUniqueContacts}} contact{{ _countUniqueContacts > 1 ? 's' : ''  }}
                  following this critieria excluding duplicates: <br><br> {{filterContactRules}} <br><br> If you press OK, You won't be able to revoke this action.
                </div>
                <div *ngIf="_countUniqueContacts == 0" class="mb-3">
                  Cannot send any messages. All {{_selectedMessageType === _messageTypeEnumEmail ? "Email" : "SMS"}} consent fields are set to NO.
                </div>
                <div class="modal-footer justify-content-center">
                  <button *ngIf="_countUniqueContacts > 0" type="submit" [disabled]="!this._tableHasRows" mdbBtn color="primary" class="waves-light rounded text-white" mdbWavesEffect>OK</button>
                  <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal" (click)="confirmSendModal.hide()" mdbWavesEffect>Cancel</button>
                </div><!--modal-footer-->
              </div><!--md-form-->
            </form><!--form-->
          </div> <!--modal-body-->
        </div>  <!--modal-content-->
      </div> <!--modal-dialog-->
    </div> <!--mdbModal-->
    <!-- Confirm Send Modal -->


    <!-- Filter Contacts Modal  -->
    <div mdbModal #filterContactsModal class="modal fade modal-overflow" tabindex="-1" role="dialog" aria-labelledby="filterContactsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content rounded-lg">
          <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="filterContactsModal.hide()">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="filterContactstModalLabel">Filter Contacts</h4>
          </div>

          <div class="modal-body">
            <app-query-builder
                               #queryBuilder
                               [(_parentListID)]="this._selectedListId"
                               [(_parentQuery)]="this.filterContactRules"
                               [_parentSource]="'contacts'"
                               (queryBuilderSave)="contactsFilterSave($event)"
                               [(_parentPageTitle)]="this._pageTitle">
            </app-query-builder>
          </div>
        </div>
      </div>
    </div>
    <!--/Filter Contacts Modal-->

    <!--/SMS Message-->

<div class="d-flex justify-content-center spinner" *ngIf="this._showSpinner">
  <mdb-spinner spinnerType="big"></mdb-spinner>
</div>
