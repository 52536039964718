<div class="container-fluid">
  <div class="row m-4">

    <div class="col-md-10 offset-md-1 mb-4">
      <!--Card-->
      <mdb-card cascade="true" narrower="true">
        <!--Card header-->
        <div class="view view-cascade card-header text-center primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center align-items-center">

          <h4 class="h4-responsive card-header-text">Available Inboxes</h4>

        </div>
        <!--/Card header-->            
        <div class="px-4 mb-2">
            <table mdbTable mdbTableScroll scrollY="true" maxHeight="235" scrollX="true" maxWidth="100%">
                <thead>
                  <tr>
                    <th *ngFor="let headers of _availableInboxesHeaders" scope="col">{{headers}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol *ngFor="let inbox of _availableInboxes">                      
                    <td><mdb-icon fas icon="plus" size=2x (click)="attachInboxModal.show()" (click)="initInboxForm(inbox)"></mdb-icon></td>
                    <td>{{inbox.InboxName}}</td>
                    <td>{{inbox.InboxNumber}}</td>
                    <td>{{inbox.LoadDate | date : "dd MMMM y"}}</td>                      
                  </tr>
                </tbody>
              </table>
        </div>
      </mdb-card>
      <!--/Card-->
    </div>

    <div class="col-md-10 offset-md-1">
      <!--Card-->
      <mdb-card cascade="true" narrower="true">
        <!--Card header-->
        <div class="view view-cascade card-header text-center primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center align-items-center">

          <h4 class="h4-responsive card-header-text">Attached Inboxes</h4>

          
      
        </div>
        <!--/Card header-->            
        <div class="px-4 mb-2">
            <table mdbTable mdbTableScroll scrollY="true" maxHeight="235" scrollX="true" maxWidth="100%">
                <thead>
                  <tr>
                    <th *ngFor="let headers of _attachedInboxesHeaders" scope="col">{{headers}} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr mdbTableCol *ngFor="let inbox of _attachedInboxes">
                    <td>{{inbox.InboxName}}</td>
                    <td>{{inbox.InboxNumber}}</td>
                    <td>{{inbox.Description}}</td>
                    <td>{{inbox.LoadDate | date : "dd MMMM y"}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
      </mdb-card>
      <!--/Card-->
    </div>

  </div>
</div>

<div mdbModal #attachInboxModal="mdbModal" class="modal fade rounded" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" 
          class="close pull-right" 
          aria-label="Close" 
          (click)="attachInboxModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Attach Inbox</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="_inboxToAttachForm">
            <div class="md-form">
              <div>
                <input 
                  mdbInput 
                  type="text" 
                  id="inbox-number" 
                  class="form-control"
                  formControlName="InboxNumber">
                <label for="inbox-number">Inbox Number</label>
              </div>             
            </div>
          <div class="md-form">
            <div>
              <input 
                mdbInput 
                type="text" 
                id="description" 
                class="form-control"
                formControlName="Description" 
                [ngClass]="{'is-invalid': _inboxToAttachForm.get('Description').errors 
                      && _inboxToAttachForm.get('Description').touched}">
              <label for="description">Add Description</label>
              <div class="invalid-feedback text-left">Description required.</div>
            </div>             
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn 
          color="secondary" 
          class="waves-light rounded" 
          aria-label="Close" 
          (click)="attachInboxModal.hide()" 
          mdbWavesEffect>
          Close
        </button>
        <button type="button" mdbBtn 
          color="primary" 
          class="relative waves-light rounded"
          [disabled]="!_inboxToAttachForm.valid"
          (click)="attachInboxModal.hide()"
          (click)="attachInbox()"
          mdbWavesEffect>
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>