<!-- Double navigation-->
<header>

    <!-- SideNav slide-out button -->
    <a (click)="sidenav.toggle()" mdbBtn color="secondary" class="p-3 button-collapse sidenav-toggle rounded-lg animated bounceInLeft sidenav-button"><mdb-icon fas icon="bars"></mdb-icon></a>
    <!--/. SideNav slide-out button -->

    <!-- Sidebar navigation -->
    <mdb-sidenav #sidenav class="sn-bg-1 fixed sidenav" [fixed]="true">
      <div class="sidenav-bg mask-light"></div>
      <logo>
        <!-- Logo -->
        <li>
          <div class="flex-image-box waves-light zoom logo-background">
            <a href="#">
              <img src="https://marketingdeliverylive.s3.eu-west-2.amazonaws.com/MDVoiceBox_Logo.png" class="img-fluid flex-center">
            </a>
          </div>
        </li>
        <!--/. Logo -->
      </logo>

      <div class="user-details-container">
        <div class="user-details-text">
          <div class="logo-box">
            <span class="client-logged">You are logged in as</span>
          </div>
          <div>
            <span>{{ firstName }} {{ lastName }}</span>
          </div>
          </div>
        </div>

      <!-- <hr> -->



      <!--Client Dropdown-->
      <div class="client-dropdown" mdbDropdown>
        <select class="form-control select-input" name="client"
            [(ngModel)]="currentClient" #clientSelect (change)='changedCurrentClient(clientSelect.value)'
            id="client">
          <option *ngIf="!hasClients" class="dealer-select__item" hidden value="0">Please select a Client.</option>
          <option class="dropdown-item option-text" [class.hidden]="client.value == 0"
              *ngFor="let client of userClients"
              [value]="client.value">{{client.label}}</option>
        </select>
      </div>
      <!--/.Client Dropdown-->
      <hr>
      <!-- Side navigation links -->
      <div style="display:block">
        <links >
          <li>
            <ul class="collapsible collapsible-accordion custom-scrollbar">
              <mdb-squeezebox [multiple]="false" aria-multiselectable="false">
                <mdb-item class="no-collase">
                  <mdb-item-head mdbWavesEffect  style="display: inline-block">
                    <a [routerLink]="['/dashboard']" routerLinkActive="active"><mdb-icon fas icon="home" class="mr-1"></mdb-icon> Dashboard </a>
                  </mdb-item-head>
                </mdb-item>
                <div *ngIf="userPermissions">
                  <div *ngIf="userPermissions.Messaging.Messages.View || userPermissions.Messaging.ListsAndContact.View || userPermissions.Messaging.Automation.View">
                    <span class="nav-option-header">Messaging</span>
                    <mdb-item *ngIf="userPermissions.Messaging.Messages.View">
                      <mdb-item-head  mdbWavesEffect>
                        <mdb-icon fas icon="envelope" class="mr-1"></mdb-icon> Messages
                      </mdb-item-head>
                      <mdb-item-body>
                        <ul>
                          <li *ngIf="userPermissions.Messaging.MessagesEmail.View || userPermissions.Messaging.MessagesSMS.View">
                            <a routerLink="messages" routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>Messages</a>
                          </li>
                          <li *ngIf="userPermissions.Messaging.MessagesReportsEmail.View">
                            <a routerLink="email-reports" routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>Email Reports</a>
                          </li>
                          <li *ngIf="userPermissions.Messaging.MessagesReportsSMS.View">
                            <a routerLink="sms-reports" routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>SMS Reports</a>
                          </li>
                        </ul>
                      </mdb-item-body>
                    </mdb-item>

                    <mdb-item *ngIf="userPermissions.Messaging.ListsAndContact.View">
                      <mdb-item-head mdbWavesEffect>
                        <mdb-icon fas icon="address-book" class="mr-1"></mdb-icon> Lists & Contacts
                      </mdb-item-head>
                      <mdb-item-body>
                        <ul>
                          <li>
                            <a routerLink="lists" routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>Lists</a>
                          </li>
                          <li>
                            <a routerLink="contacts" routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>Contacts</a>
                          </li>
                          <li>
                            <a routerLink="contact-interactions"  routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>Contact Interactions</a>
                          </li>
                        </ul>
                      </mdb-item-body>
                    </mdb-item>
                    <mdb-item *ngIf="userPermissions.Messaging.Automation.View">
                      <mdb-item-head mdbWavesEffect>
                        <mdb-icon fas icon="cog" class="mr-1"></mdb-icon> Automation
                      </mdb-item-head>
                      <mdb-item-body>
                        <ul>
                          <li *ngIf="userPermissions.Messaging.AutomationContactPlans.View">
                            <a routerLink="contact-plans" routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>Contact Plans</a>
                          </li>
                          <li *ngIf="userPermissions.Messaging.AutomationMessageCodes.View">
                            <a routerLink="contact-criteria" routerLinkActive="active" class="waves-effect link-shadow" mdbWavesEffect>Contact Criteria</a>
                          </li>
                        </ul>
                      </mdb-item-body>
                    </mdb-item>
                  </div>
                  <div *ngIf="userPermissions.Social.Networks.View || userPermissions.Social.Posts.View || userPermissions.Social.NetworkGroups.View || userPermissions.Social.Reports.View">
                    <span class="nav-option-header">Social Media</span>
                    <mdb-item *ngIf="userPermissions.Social.Posts.View" class="no-collase">
                      <mdb-item-head mdbWavesEffect>
                        <a [routerLink]="['posts']" routerLinkActive="active"><mdb-icon fas icon="retweet" class="mr-1"></mdb-icon> Posts Overview</a>
                      </mdb-item-head>
                    </mdb-item>
                    <mdb-item *ngIf="userPermissions.Social.Networks.View || userPermissions.Social.NetworkGroups.View">
                      <mdb-item-head mdbWavesEffect>
                        <mdb-icon fas icon="network-wired" class="mr-1"></mdb-icon> Social Networks
                      </mdb-item-head>
                      <mdb-item-body>
                        <ul>
                          <li *ngIf="userPermissions.Social.Networks.View">
                            <a [routerLink]="['/network-management']" class="waves-effect link-shadow" mdbWavesEffect
                               routerLinkActive="active">
                              Networks</a>
                          </li>
                          <li *ngIf="userPermissions.Social.NetworkGroups.View">
                            <a [routerLink]="['/network-group-management']" class="waves-effect link-shadow" mdbWavesEffect
                               routerLinkActive="active">
                              Networks Groups</a>
                          </li>
                        </ul>
                      </mdb-item-body>
                    </mdb-item>
                    <mdb-item *ngIf="userPermissions.Social.Reports.View" class="no-collase">
                      <mdb-item-head mdbWavesEffect>
                        <a [routerLink]="['/single-network-report']" routerLinkActive="active"><mdb-icon fas icon="share-square" class="mr-1"></mdb-icon> Reports </a>
                      </mdb-item-head>
                    </mdb-item>
                  </div>
                  <div *ngIf="userPermissions.Media.MediaServer.View">
                    <span class="nav-option-header">Media</span>
                    <mdb-item class="no-collase">
                      <mdb-item-head mdbWavesEffect>
                        <a [routerLink]="['/media-server']" routerLinkActive="active"><mdb-icon fas icon="images" class="mr-1"></mdb-icon> Files & Images</a>
                      </mdb-item-head>
                    </mdb-item>
                  </div>

                  <div *ngIf="userPermissions.Tools.Tools.View">
                    <span class="nav-option-header">Tools</span>
                    <mdb-item class="no-collase" *ngIf="userPermissions.Tools.VehicleValidation.View">
                      <mdb-item-head mdbWavesEffect>
                        <a [routerLink]="['/tools/vehicle-validation']" routerLinkActive="active"><mdb-icon fas icon="car-alt" class="mr-1"></mdb-icon> MOT Box</a>
                      </mdb-item-head>
                    </mdb-item>
                    
                    <!--
                    <mdb-item class="no-collase" *ngIf="userPermissions.Admin.Admin.View">
                      <mdb-item-head mdbWavesEffect>
                        <a [routerLink]="['/audit-trail']" routerLinkActive="active"><mdb-icon fas icon="binoculars" class="white-text mr-1"></mdb-icon> Audit Trail</a>
                      </mdb-item-head>
                    </mdb-item>
                    -->
                  </div>
                </div>
              </mdb-squeezebox>
            </ul>
          </li>
        </links>
      </div>
      <hr>
      <!--/. Side navigation links -->
      <div *ngIf="userPermissions" style="display:block; ">
        <div class="fixed-bottom" style="width:100%; position: relative; bottom: 0; width: 100%; margin: 0; padding: 0;">
          <div class="left sidenav-footer-options">
            <!--<a class="icons-sm footer-link">
            <mdb-icon fas icon="user"></mdb-icon>
        </a>-->
          </div>
          <div class="btn-group middle sidenav-footer-options "  [dropup]="true" mdbDropdown>
            <!-- <div > -->
            <mdb-icon *ngIf="userPermissions.Admin.Admin.View" fas icon="toolbox" classInside="dropdown-toggle"  mdbDropdownToggle class="waves-effect md-sidenav-dropdown"
                      mdbWavesEffect></mdb-icon>
            <div class="dropdown-menu dropdown-primary admin-dropup" *ngIf="userPermissions.Admin.Admin.View">
              <a *ngIf="userPermissions.Admin.Users.View" style="color: white;" class="admin-links" [routerLink]="['/user-management']" routerLinkActive="active"><mdb-icon fas icon="users" class="mr-1"></mdb-icon>User Management</a>
              <a *ngIf="userPermissions.Admin.UserRoles.View" style="color: white;" class="admin-links" [routerLink]="['/user-role-management']" routerLinkActive="active"><mdb-icon fas icon="user-astronaut" class="mr-1"></mdb-icon>User Role Management</a>
              <a *ngIf="userPermissions.Admin.Clients.View" style="color: white;" class="admin-links" [routerLink]="['/client-management']" routerLinkActive="active"><mdb-icon fas icon="handshake" class="mr-1"></mdb-icon>Client Management</a>
            </div>
            <!-- </div> -->
          </div>
          <div class="right sidenav-footer-options">
            <a class="footer-link" (click)="logout()">
              <mdb-icon fas icon="power-off"></mdb-icon>
            </a>
          </div>
        </div>
      </div>







    </mdb-sidenav>
    <!--/. Sidebar navigation -->


</header>
<!--/.Double navigation -->

