import { Injectable } from '@angular/core';
import { FieldControlTypeDto } from './field-control-type-dto';

@Injectable({
  providedIn: 'root'
})

export class FieldControlTypeDtoAdapter {
  public adapt(item: any) {
    return new FieldControlTypeDto(item);
  }
}
