import { Injectable } from '@angular/core';
import { MessagePreviewDto } from './message-preview-dto';

@Injectable({
  providedIn: 'root'
})

export class MessagePreviewDtoAdapter {
  adapt(item: any): MessagePreviewDto {
    return new MessagePreviewDto(item);
  }

  adaptArray(init: Array<any>): MessagePreviewDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new MessagePreviewDto(table))
      : [new MessagePreviewDto(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new MessagePreviewDto({
      RowNum: 0,
      ContactID: 0,
      Firstname: '',
      Surname: '',
      Email: '',
      Subject: '',
      HtmlContent: '',
    });
  }
}
