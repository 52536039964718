export class MediaServerBucketModel {
  Id: number;
  BucketName: string;
  BucketFriendlyName: string;
  ShowAllFolders: boolean;

  constructor(init: any) {
    this.Id = init.Id;
    this.BucketName = init.BucketName;
    this.BucketFriendlyName = init.BucketFriendlyName;
    this.ShowAllFolders = init.ShowAllFolders;
  }
}
