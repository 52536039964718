import { Injectable } from '@angular/core';
import { TriggerTargetModel } from './trigger-target-model';

@Injectable({
  providedIn: 'root'
})

export class TriggerTargetModelAdapter {
  adapt(item: any): TriggerTargetModel {
    return new TriggerTargetModel(item);
  }

  adaptArray(init: Array<any>): TriggerTargetModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new TriggerTargetModel(table))
      : [new TriggerTargetModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new TriggerTargetModel({
      SubjectType: null,
      // SubjectTypeName: null,
      SubjectEventType: 0,
      EventName: '',
      ListId: 0,
      MessageId: null,
      MessageType: null,
      MessageLinkId: null,
      FieldName: null,
      WebFormId: null,
      TargetUrl: ''
    });
  }
}
