import { Injectable } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

constructor(private toastrService: ToastService) { }

showSuccess(message) {
  this.toastrService.success(message);
}

showError(message) {
  this.toastrService.error(message);
}

showInfo(message) {
  this.toastrService.info(message);
}

showWarning(message) {
  this.toastrService.warning(message);
}

}
