import { Injectable } from '@angular/core';
import { AvailableDMSDto } from './available-dms-dto';


@Injectable({
  providedIn: 'root'
})

export class AvailableDMSDtoAdapter {
  public adaptArray(init: Array<any>): AvailableDMSDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(bucket => new AvailableDMSDto(bucket))
      : [new AvailableDMSDto(init[0])];
    } else {
      return [];
    }
  }
}
