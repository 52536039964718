import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-md-select',
  templateUrl: './md-select.component.html',
  styleUrls: ['./md-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MdSelectComponent),
      multi: true
    }]
})
export class MdSelectComponent implements OnInit, ControlValueAccessor {
  @Input() options: any[];
  @Input() label: string;
  @Input() placeholder = '<Select Folder>';
  @Input() showPlaceholder: boolean;
  @Input() disabled: boolean;
  @Input() visibleLabel = true;

  selector: UntypedFormControl;

  @ViewChild('select', { static: true }) select: ElementRef;

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    this.selector = new UntypedFormControl('');
    this.select.nativeElement.disabled = this.disabled;

    this.selector.valueChanges.subscribe((value) => {
      this.onChange(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(id: number) {
    this.selector.setValue(id);
  }
}
