<form [formGroup]="messageCodeForm">
  <div class="row">
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-6">
          <div class="md-form">
            <input
              mdbInput
              type="number" 
              class="form-control" 
              id="listID-control"
              formControlName="listId">
            <label for="listID-control">List ID</label>
          </div>
    
          <div class="md-form">
            Start Date
            <input 
              mdbInput
              id="from-date"
              type="datetime-local" 
              class="form-control"
              formControlName="startDate"
              [ngClass]="{'is-invalid': messageCodeForm.get('startDate').errors && 
                                        messageCodeForm.get('startDate').touched}">
              <div class="invalid-feedback">Please enter a Start Date.</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="md-form">
            <input
              mdbInput
              type="text" 
              class="form-control" 
              id="code-control"
              formControlName="criteriaCode"
              [ngClass]="{'is-invalid': messageCodeForm.get('criteriaCode').errors && 
                                        messageCodeForm.get('criteriaCode').touched}">
            <label for="code-control">Message Criteria Code</label>
            <div class="invalid-feedback">Please enter a Criteria Code.</div>
          </div>
    
          <div class="md-form">
            End Date
            <input 
              mdbInput 
              id="end-date"
              type="datetime-local" 
              class="form-control"
              formControlName="endDate"
              [ngClass]="{'is-invalid': messageCodeForm.get('endDate').errors && 
                                        messageCodeForm.get('endDate').touched ||
                                        messageCodeForm.hasError('invalidDate') &&
                                        messageCodeForm.get('endDate').touched }">
            <div class="invalid-feedback"
              *ngIf="messageCodeForm.get('endDate').hasError('required')
              && messageCodeForm.get('endDate').touched">Please enter an End Date.
            </div>
            <div class="invalid-feedback"
              *ngIf="messageCodeForm.hasError('invalidDate')
              && messageCodeForm.get('endDate').touched">End Date is not greater than Start Date.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="md-form remove-top-margin">
            <input
              mdbInput
              type="text" 
              class="form-control" 
              id="rule-description-control"
              formControlName="description">
            <label for="rule-description-control">Rule Description</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">

      <div class="md-form">
        SQL
        <textarea
          mdbInput
          id="sql-control"
          rows="7"
          class="form-control mt-1"
          readonly>{{messageCodeForm.get('sql').value}}
        </textarea>
      </div>

    </div>
  </div>
</form>
<app-query-builder 
  #queryBuilder
  *ngIf="initQueryBuilder"
  [_parentListID]="this.messageCodeForm.controls.listId.value" 
  [_parentQuery]="this.messageCodeRules"
  [_parentSource]="'createEditMessageCode'"
  (queryBuilderSave)="messageCodeSQLSave($event)"
  [(_parentPageTitle)]="this._pageTitle">            
</app-query-builder>
