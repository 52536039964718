import { Injectable } from '@angular/core';
import { AllFoldersAllListsAllFieldsModel } from './all-folders-all-lists-all-fields-model';

@Injectable({
  providedIn: 'root'
})

export class AllFoldersAllListsAllFieldsModelAdapter {
  public adapt(item: any) {
    return new AllFoldersAllListsAllFieldsModel(item);
  }
}
