import { Component, OnInit } from '@angular/core';
import { ClientInboxDto, ClientInboxAdapter } from 'src/app/_models/admin/clients/client-inboxes-model';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-inboxes',
  templateUrl: './client-inboxes.component.html',
  styleUrls: ['./client-inboxes.component.scss']
})
export class ClientInboxesComponent implements OnInit {

  currentClient: ClientDto;
  _availableInboxes: ClientInboxDto[];
  _availableInboxesHeaders = ['Attach', 'Name', 'Number', 'LoadDate'];
  _inboxToAttach: ClientInboxDto;
  _inboxToAttachForm: UntypedFormGroup;
  _attachedInboxes: ClientInboxDto[] = [];
  _attachedInboxesHeaders = [ 'Name', 'Number', 'Description', 'LoadDate'];

  constructor( private clientManagementService: ClientManagementService,
               private route: Router,
               private clientInboxesAdapter: ClientInboxAdapter,
               private notificationService: NotificationService,
               private fb: UntypedFormBuilder
              ) { }

  ngOnInit() {

    this.currentClient = this.clientManagementService.getCurrentClient();

    if (this.currentClient === undefined) {
      this.route.navigateByUrl('/client-management');
    }

    this.clientManagementService.getAvailableInboxes().subscribe(data => {
      this._availableInboxes = data;
    });

    this.clientManagementService.getAttachedInboxes().subscribe(data => {
      this._attachedInboxes = data;
    });

    this.createInboxToAttachForm();
  }

  createInboxToAttachForm() {
    this._inboxToAttachForm = this.fb.group({
      InboxNumber: [{value: '', disabled: true}, Validators.required],
      Description: ['', Validators.required]
    });
  }

  initInboxForm(inbox: ClientInboxDto) {
    this._inboxToAttachForm.get('InboxNumber').setValue(inbox.InboxNumber);

    this._inboxToAttach = this.clientInboxesAdapter.adapt({
      ClientId: inbox.ClientId,
      TextLocalInboxId: inbox.TextLocalInboxId,
      InboxNumber: inbox.InboxNumber,
      Active: inbox.Active,
      Keyword: inbox.Keyword,
      NumMessages: inbox.NumMessages,
      NumContacts: inbox.NumContacts,
      InboxName: inbox.InboxName,
      NewMessages: inbox.NewMessages,
      LoadDate: inbox.LoadDate,
      id: inbox.id,
      Description: inbox.Description,
    });
  }

  attachInbox() {
    this._inboxToAttach.Description = this._inboxToAttachForm.value.Description;
    this._inboxToAttach.ClientId = this.currentClient.ClientID;
    this.clientManagementService.attachInbox(this._inboxToAttach).subscribe(result => {
      this._attachedInboxes = result.data;
      const inboxToSwap = this._availableInboxes.find(
        inbox => inbox.InboxNumber === this._inboxToAttach.InboxNumber
      );
      this._availableInboxes.splice(1, 1, inboxToSwap);
      this._inboxToAttachForm.reset();
      this.notificationService.showSuccess(result.message);
    }, result => {
      this.notificationService.showError(result.message);
    });
  }
}
