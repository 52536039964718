<div class="row" *ngFor="let control of searchReplaceFormArray.controls; let i = index">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-5">
        <label class="input-label">Search for: </label>
        <input type="text" class="form-control" [formControl]="$any(control).get('find')">
      </div>
      <div class="col-md-5">
        <label class="input-label">Replace with: </label>
        <input type="text" class="form-control" [formControl]="$any(control).get('replace')"/>
      </div>
      <div class="col-md-2">
        <a class="" (click)="deleteMessageFindReplace(i)">
          <mdb-icon class="mt-3 trash" fas icon="fas fa-trash" size="2x"></mdb-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-10">
  </div>
  <div class="col-md-2">
    <a class="" (click)="addNewMessageFindReplace()">
      <mdb-icon class="mt-1 plus" far icon="far fa-plus-square" size="2x"></mdb-icon>
    </a>
  </div>
</div>
