
import { UploadTypeEnum } from './upload-type-enum';


export class ListHistoryUploadDto {

  listId: number;
  listHistoryUploadId: number;
  uploadType: UploadTypeEnum;
  listName: string;
  uploadedDate: Date;
  uploadedById: number;
  fileName: string;
  contentType: string;
  fileId: string;
  fileContent: string;
  errorsFileName: string;
  errorsContentType: string;
  errorsFileId: string;
  errorsFileContent: Uint32Array;
  
}




