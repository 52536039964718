import { PermissionsPageTypes, PermissionsPageTypesAdapter } from './permissions-page-types-model'
import { Section, Page } from '../../admin/users/permissions-all-available-model';
import { Injectable } from '@angular/core';
import { PermissionsAdminPagesEnum } from './permissions-admin-pages.enum';

export class PermissionsAdminPages {
  Admin : PermissionsPageTypes;
  Clients : PermissionsPageTypes;
  Users : PermissionsPageTypes;
  LoginHistory : PermissionsPageTypes;
  UserPermissions : PermissionsPageTypes;
  UserRoles : PermissionsPageTypes;


  private _permissionsPageTypesAdapter : PermissionsPageTypesAdapter = new PermissionsPageTypesAdapter();


  constructor(section: Section) {
    section.pages.forEach((Page: Page) => {
      switch (Page.id) {
        case PermissionsAdminPagesEnum.Admin:
          this.Admin = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsAdminPagesEnum.Clients:
          this.Clients = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsAdminPagesEnum.Users:
          this.Users = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsAdminPagesEnum.LoginHistory:
          this.LoginHistory = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsAdminPagesEnum.UserPermissions:
          this.UserPermissions = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsAdminPagesEnum.UserRoles:
          this.UserRoles = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        default:
          console.warn("Unknown permission with Page name: " + Page.name);
          break;
      }
    });

  }
}


@Injectable({
  providedIn: 'root'
})

export class PermissionsAdminPagesAdapter {
  adapt(item: Section): PermissionsAdminPages {
    return new PermissionsAdminPages(item);
  }
}
