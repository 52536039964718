
export class EmailNotificationModel {

  public reportName: string;
  public firstName: string;
  public lastName: string;
  public clientId: number;
  public email: string;
  public date: Date;

  constructor(init: any) {
    this.reportName = init.reportName;
    this.firstName = init.firstName;
    this.lastName = init.lastName;
    this.clientId = init.clientId;
    this.email = init.email;
    this.date = init.date;
  }
}
