import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-two-factor-setup',
  templateUrl: './two-factor-setup.html',
  styleUrls: ['./two-factor-setup.scss']
})
export class TwoFactorSetupComponent implements OnInit {
  _codeCheckForm: UntypedFormGroup;
  _submitting: boolean;
  _user: any;
  _cognitoCode: string;
  _qrLink: string;
  _issuer: string = "MarketingDelivery";
  _signInUser: any = {};
  _setupResult: string;

  constructor(private fb: UntypedFormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private notificationService: NotificationService,
    private authService: AuthService) {
  }

  async ngOnInit(): Promise<void> {
    // if the Cognito user is empty, the QR code will not display
    if (!this.authService.cognitoUserSession) {
      this.notificationService.showError('Cognito session expired - please sign in again.');
      this.router.navigate(['/dashboard']);
    }   
    // ensure the user is signed in correctly before attempting to display a QR code 
    // (sometimes the user is not updated after finishing the authentication code entry, for example)
    await this.authService.awsReSignInUser();

    await this.authService.awsGetTotpQrCode(this.authService.cognitoUserSession).then((result => this._cognitoCode = result));
    this._qrLink = "otpauth://totp/AWSCognito:" + this.authService.cognitoUserSession.username + "?secret=" + this._cognitoCode + "&issuer=" + this._issuer;

    this.createCodeCheckForm();
  }

  createCodeCheckForm() {
    this._codeCheckForm = this.fb.group({
      tfaCode: ['', Validators.required]
    });
  }

  // check that the totp code the user entered from their authentication is correct
  // and return them to the login screen so they can sign in with their new security
  async setupTotp(code: string) {
    try {
      await this.authService.awsSetupTotpForUser(this.authService.cognitoUserSession, code).then(result => this._setupResult = result);  
      // if successful code entry, continue on
      if(this._setupResult === 'true')
      {
        this.notificationService.showSuccess('Authentication setup successful!');
        await this.authService.awsLogOut();
        this.router.navigate(['/login']);
      }
      // if the code entered is not long enough or incorrect, show a friendly error message to the user    
      else if(this._setupResult.indexOf("Code mismatch") !== -1 || this._setupResult.indexOf("greater than or equal to 6") !== -1)
      {
        this.notificationService.showError('Incorrect code entered - please try again!');
      }   
      // any other error, return it in full to the user so that it can then be reported
      else
      {
        this.notificationService.showError('Error setting up 2FA: ' + this._setupResult);
      }     
    } catch (error) {
      console.log('Error setting up authentication', error);
      this.notificationService.showError('Error setting up authentication: ' + error.message + ' - please contact an administrator.');
    }
  }
}
