import { DataTableColumn } from '../../../../_models/messaging/datatable-column';

export class ContactsUploadDataTableColumn extends DataTableColumn {

   included: boolean;
   includeIconVisible: boolean;
   editIconVisible: boolean;
   addOptions: boolean;
   addOptionsIconVisible: boolean;

   constructor(init: {  prop: string;
      name: string;
      included: boolean;
      includeIconVisible: boolean;
      editIconVisible: boolean;
      addOptions: boolean;
      addOptionsIconVisible: boolean } ) {

     super(init);

     this.included = init.included;
     this.editIconVisible = init.editIconVisible;
     this.includeIconVisible = init.includeIconVisible;
     this.addOptions = init.addOptions;
     this.addOptionsIconVisible = init.addOptionsIconVisible;
  }
 }
