<div class="row justify-content-center">
  <div class="col-md-8">
    <!--Card-->
    <mdb-card cascade="true" class="custom-container">

      <!--Card Content-->
      <mdb-card-body cascade="true" class="remove-padding">

        <section>
          <form [formGroup]="_folderListSelectForm">
            <div class="container-fluid">
              <div class="row mt-3 mb-3 justify-content-center">           
                <div class="col-md-4">
                  <div class="remove-top-margin justify-content-center">
                    <div>
                      <label for="folders" class="input-label">Folder</label>
                      <select class="form-control select-input"
                              name="folders" (change)="folderSelected($event)"
                              id="folders" formControlName="selectedFolder">
                        <option value="" hidden>Select Folder</option>
                        <option *ngFor="let listFolder of _listFolderDropdown; let i = index"
                                [value]="listFolder.value">
                          {{listFolder.label}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
  
                <div class="col-md-4">
                  <div class="remove-top-margin">
                    <label for="list" class="input-label">List</label>
                    <select class="form-control select-input"
                            name="list" (change)="listSelected($event)"
                            id="list" formControlName="selectedList">
                      <option value="" hidden>Select List</option>
                      <option *ngFor="let list of _listDropdown; let i = index"
                              [value]="list.value">
                        {{list.label}}
                      </option>
                    </select>
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="switch switch-box">
                    <label class="title-event">
                      <span class="switch-title">
                        <mdb-icon [ngStyle]="!_showMobileView ? {'color': '#1d1757'} : {'color': '#9c9c9c'}" fas icon="desktop"></mdb-icon>
                      </span>
                      <input type="checkbox"
                             (change)="switchView($event)">
                      <span class="lever"></span>
                      <span class="switch-title">
                        <mdb-icon fas icon="mobile-alt" [ngStyle]="_showMobileView ? {'color': '#1d1757'} : {'color': '#9c9c9c'}"></mdb-icon>
                      </span>
                    </label>
                  </div>
                </div>
  
                <div class="col-md-2">
                  <div class="search-box">
                    <button class="table-button btn-rounded btn-sm" [disabled]="this._selectedListID === 0"
                            outline="true" rounded="true" size="sm" type="button"
                            (click)="filterModal.show()">
                      <mdb-icon class="table-icon" fas size="lg" icon="search"></mdb-icon>
                    </button>
                  </div>
                </div>
              </div>
  
              <!-- /First Row -->
  
              <!-- Second Row -->
              <div class="row justify-content-center">
                <div class="col-md-10">
                  <mdb-card class="rounded" borderColor="border-dark">
                    <mdb-card-body class="text-left font-weight-bold mt-3">
                      <div class="msg-details-left">
                        <div>
                          <p>From:</p>
                        </div>
                        <div>
                          <p>Subject:</p>
                        </div>
                        <div *ngIf="this._contacts.length > 0">
                          <p>To:</p>
                        </div>
                      </div>
                      <div class="msg-details-right">
                        <div>
                          <p>{{_messageDetails.FromAddress}}</p>
                        </div>
                        <div>
                          <p>{{this._currentContact ? this._currentContact.Subject : ''}}</p>
                        </div>
                        <div class="contact-selection-container" *ngIf="this._contacts.length > 0 && this._currentContact">
                          <a>
                            <div class="contact-selection-arrow text-center icon-color" (click)="previousContactClick()">
                              <mdb-icon size="lg" fas icon="angle-left"></mdb-icon>
                            </div>
                          </a>
                          <div class="contact-info text-center" *ngIf="this._currentContact.ContactID != 0;">
                            {{this._currentContact.Firstname}}
                            {{this._currentContact.Surname}}
                            ({{this._currentContact.Email}})
                          </div>
                          <div class="font-italic contact-info text-center" *ngIf="this._currentContact.ContactID === 0;">
                            No Contact Selected
                          </div>
                          <a>
                            <div class="contact-selection-arrow text-center icon-color" (click)="nextContactClick()">
                              <mdb-icon size="lg" fas icon="angle-right"></mdb-icon>
                            </div>
                          </a>
                          <div *ngIf="this._totalContacts > 1" class="contact-counter text-right">
                            {{this._currentContact.RowNum}} / {{this._totalContacts}}
                          </div>
                        </div>
                      </div>
                      <mdb-progress-bar *ngIf="_loading" class="animated fadeIn progress primary-color-dark mb-1" mode="indeterminate"></mdb-progress-bar>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
  
              <!-- Third Row -->
              <div *ngIf="this._currentContact" class="row justify-content-center mt-2">
                <div class="col-md-12 pb-2">
                  <div class="animated fadeIn" [ngClass]="{'desktop-view': _showMobileView === false, 'mobile-view':_showMobileView === true }"
                       [innerHTML]="_currentContact.HtmlContent | sanitizeHtml">
                  </div>
                </div>
              </div>
              <!-- /Third Row -->
  
              <!-- Forth Row -->
              <div class="row justify-content-center mt-2 text-center">
                <div class="col-md-12 pb-2">
                  <a target="_blank" class="preview-style" href="{{this._publicPreviewUrl}}">Public Preview</a>
                  <a (click)="copyLink()" mdbTooltip="click to copy to clipboard" placement="top">
                    <mdb-icon far icon="clipboard" size="lg" class="ml-2"></mdb-icon>
                  </a>
                </div>
              </div>
              <!-- /Forth Row -->
            </div>               
          </form>
        </section>

      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>


<!-- Search Contacts Query Builder Modal -->
<div mdbModal #filterModal class="modal fade modal-overflow" tabindex="-1" role="dialog"
     aria-labelledby="filterModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="filterModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="filterModalLabel">Filter Contacts</h4>
      </div>

      <div class="modal-body">
        <app-query-builder #queryBuilder [(_parentListID)]="this._selectedListID"
                           [(_parentQuery)]="this._filterRules" [_parentSource]="'contactsPreview'"
                           (queryBuilderSave)="filterSave($event)"
                           [(_parentPageTitle)]="this._pageTitle">
        </app-query-builder>
      </div>
    </div>
  </div>
</div>
<!-- Search Contacts Query Builder Modal -->
