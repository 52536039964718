<div class="card mt-4" *ngFor="let post of filteredPosts | slice:0:pageLimit">
  <div class="row pb-3 pr-3 pl-3 mt-0">
    <div class="col-md-3 d-flex align-items-center justify-content-center">
      <img src="{{ post.imageUrl }}" class="img-thumbnail responsive-img" alt="Post Image">
    </div>
    <div class="col-md-9 d-flex flex-column">
      <div class="row">
        <div class="col-md-10 d-flex flex-row">
          <h2 class="title-sub-section font-weight-bold date mr-4">
            {{ moment(post.createdDate).format('LLL') }}
          </h2>
          <div *ngIf="!isReport">
            <div *ngIf="post.postStatus === postStatus.Scheduled" class="d-flex align-items-center scheduled">
              <i class="far fa-clock mr-1"></i>
              <div class="status-text">Scheduled</div>
            </div>

            <div *ngIf="post.postStatus === postStatus.Success" class="d-flex align-items-center success">
              <i class="fas fa-check-circle mr-1"></i>
              <div class="status-text">Successfully Posted</div>
            </div>

            <div *ngIf="post.postStatus === postStatus.Error" class="d-flex align-items-center failed">
              <i class="fas fa-exclamation-triangle mr-1"></i>
              <div class="status-text">Failed to post</div>
            </div>

            <div *ngIf="post.postStatus === postStatus.Draft" class="d-flex align-items-center draft">
              <i class="fas fa-pen-alt mr-1"></i>
              <div class="status-text">Draft</div>
            </div>
          </div>
        </div>
        <div class="col-md-2 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end">
          <i *ngIf="isReport" [ngClass]="'fab mr-1 fa-' + network + ' social-icon ' + network"></i>
          <span *ngIf="isReport" class="font-weight-bold">{{ selectedNetworkName }}</span>
          <div *ngIf="!isReport" class="chip name-chip">{{ post.username }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 post-text-wrap">
          <p>{{ post.postText }}</p>
        </div>
      </div>

      <app-post-list-content class="mt-auto"
                             [imageUrl]="post.imageUrl"
                             [metrics]="post.postDetails"
                             [selectedNetworkType]="selectedNetworkType"
                             [isReport]="isReport">
      </app-post-list-content>
    </div>
  </div>
</div>

