import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { MessageService } from 'src/app/_services/messaging/messages/message.service';
import { QueryBuilderFilterComponent } from 'src/app/views/shared/query-builder/query-builder-filter/query-builder-filter.component';
import { QueryFilterDtoAdapter } from 'src/app/_models/query-builder-filters/query-builder-filter-dto-adapter';
import { QueryBuilderFilterService } from 'src/app/_services/query-builder-filters/query-builder-filter.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { QueryBuilderFilterDto } from 'src/app/_models/query-builder-filters/query-builder-filter-dto';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QueryBuilderFilterDtoRequest } from 'src/app/_models/query-builder-filters/query-builder-filter-dto-request';
import { QueryBuilderHelper } from './query-builder-helper';
import { TableType } from '../constants/table-constants';
import { AuditTrailService } from 'src/app/_services/audit-trail/audit-trail.service';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent implements OnInit {
  @Input() _parentQueryRules: any = '';
  @Input() _parentQuery: string = '';
  @Input() _parentListID: number = 0;
  @Input() _parentSource: string = '';
  @Input() _parentPageTitle: string = '';
  @Input() selectedFilter = 0;
  @Input() appliedQueryFilter: string;

  @Output() queryBuilderSave = new EventEmitter<Array<string>>();
  @Output() selectValue = new EventEmitter<number>();

  _pageTitle: string = '';
  _listId: number;
  _queryRules: any = '';
  _query: string = '';
  _source: string = '';
  _isCct = false;
  _cctTrue = '';
  _cctFalse = '';
  _filters: any = [];
  _isSaveAsButton = false;
  _parentSelectedFilter = 0;

  _queryFilterDtos;
  _filterSelectForm: UntypedFormGroup;
  _cctForm: UntypedFormGroup;
  _queryFiltersList: SelectListOption[] = [];
  _showFilterModel: boolean = false;
  _updateFilterButton: boolean = false;
  _sqlQuery: string = '';

  @ViewChild('queryBuilderFilter', { static: true }) queryBuilder: QueryBuilderFilterComponent;

  constructor(private route: ActivatedRoute,
              private listService: ListsService,
              private contactService: ContactService,
              private messageService: MessageService,
              private queryBuilderFilterService: QueryBuilderFilterService,
              private queryFilterDtoAdapter: QueryFilterDtoAdapter,
              private notificationService: NotificationService,
              private queryBuilderHelper: QueryBuilderHelper,
              private auditTrailService: AuditTrailService) { }

  ngOnInit() {
    if (this._parentListID !== 0 || this._parentSource === 'interactions' || this._parentSource === 'consent-history' || this._parentPageTitle !== '') {
      this._listId = this._parentListID;
      this._query = this._parentQuery;
      this._source = this._parentSource;
      this._pageTitle = this._parentPageTitle;
    } else {
      this.route.queryParams.subscribe(params => {
        this._listId = params['listId'];
        this._queryRules = params['queryRules'];
        this._source = params['source'];
        this._isCct = params['isCct'] === 'true';
        this._parentQuery = params['parentQuery'];
        this._cctTrue = params['cctTrue'];
        this._cctFalse = params['cctFalse'];
      });
    }

    if (this._queryRules && !$.isEmptyObject(this._queryRules)) {
      this._queryRules = JSON.parse(this._queryRules);
    }

    this._cctForm = new UntypedFormGroup({
      cctTrue: new UntypedFormControl(this._cctTrue),
      cctFalse: new UntypedFormControl(this._cctFalse)
    });

    this.selectedFilter = this.queryBuilderFilterService.selectedFilter;

    this._filterSelectForm = new UntypedFormGroup({
      selectedFilter: new UntypedFormControl(this.selectedFilter)
    });

    this.initiateQueryBuilder();

    this.resetFilter();
  }

  resetFilter() {
    this._filterSelectForm.get('selectedFilter').setValue(0);
    if (this._filterSelectForm.get('listForm') != null) {
      this._filterSelectForm.get('listForm').get('contactFilterCriteria').setValue('');
    }
    this._filterSelectForm.get('selectedFilter').valueChanges.subscribe((value) => {
      this.queryBuilderFilterService.selectedFilter = value;
      this.filterSelected(value);
    });
  }

  refreshSql() {
    if (this._parentQuery) {
      fieldGenerator.importSQLData(this._parentQuery);
    }    
  }

  refreshFields(listId:number) {
    this._listId = listId;
    $('#builder').queryBuilder('destroy');
    if (this._listId && this._listId > 0) {
      this.listService.getAllListFields(this._listId).subscribe((result: ExecutionResultDto) => {
        this._filters = fieldGenerator.generateFields(result.data, this._source);

        this.getQueryBuilder();
        if (this._parentQuery !== '') {
          fieldGenerator.importSQLData(this._parentQuery);
        }
      });
    }
  }

  //Retrieve and populate the users saved filters by the page title
  populateQueryFilterList(pageTitle: string, sql: string) {
    this.queryBuilderFilterService.getFiltersByPageTitle(pageTitle).subscribe((executionResultDto: ExecutionResultDto) => {

      //If the execution is a success, save the retrieved object to the DTO and populate the front end list
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._queryFilterDtos = executionResultDto.data
          .map((queryBuilderFilterDto: QueryBuilderFilterDto) => this.queryFilterDtoAdapter.adapt(queryBuilderFilterDto));
        this._queryFiltersList = this._queryFilterDtos
          .map((queryBuilderFilterDto: QueryBuilderFilterDto) => new SelectListOption(queryBuilderFilterDto.filterId, queryBuilderFilterDto.filterName));

        this._queryFiltersList.unshift(new SelectListOption(0, '<Select Saved Filter>'));
        
        if (this.selectedFilter) {
          this.filterSelected(this.selectedFilter);
        }

        if (this.appliedQueryFilter) {
          if (sql != "") {
            fieldGenerator.importSQLData(this.queryBuilderHelper.removeIsNullFromSQL(sql));
          }
          else {
            fieldGenerator.importSQLData(this.queryBuilderHelper.removeIsNullFromSQL(this.appliedQueryFilter));
          }
        }
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  //Populate the query builder from the users selected query filter
  filterSelected($event) {
    if (this._queryFilterDtos) {
      const selectedQueryFilter = this._queryFilterDtos.find(filter => filter.filterId === $event);

      if (selectedQueryFilter) {
        this._updateFilterButton = true;
        fieldGenerator.importSQLData(selectedQueryFilter.sqlQuery);
      } else {
        this._updateFilterButton = false;
        $('#builder').queryBuilder('reset');
      }
    }    
  }

  //Validate Query builder data and display model
  displaySaveFilterModel(_isSaveAsButton: boolean) {
    var result = MDQueryBuilder.exportQueryData();
    this._isSaveAsButton = _isSaveAsButton;

    if (result && result.sql != null) {
      this._sqlQuery = result.sql;
    }

    if (_isSaveAsButton) {
      this._parentSelectedFilter = this._filterSelectForm.get('selectedFilter').value;
    }

    this._showFilterModel = true;

  }

  //Validate and update the users saved filter
  updateFilter() {
    const filterId = this._filterSelectForm.get('selectedFilter').value;
    const selectedQueryFilter = this._queryFilterDtos.find(filter => filter.filterId == filterId);

    var result = MDQueryBuilder.exportQueryData();

    if (selectedQueryFilter) {
      if (result && result.sql != null) {
        //Remove ISNULL - This is not required to be saved in db
        var modifiedSQLQuery = this.queryBuilderHelper.removeIsNullFromSQL(result.sql.sql);

        //Add Parameters to DTO Request
        const queryBuilderFilterDtoRequest = new QueryBuilderFilterDtoRequest({
          filterId: selectedQueryFilter.filterId,
          sqlQuery: modifiedSQLQuery
        });

        //Call the API to Update the SQL and Reset the Query Builder and Filter
        this.queryBuilderFilterService.updateFilter(queryBuilderFilterDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            this.selectedFilter = filterId;
            this.populateQueryFilterList(this._pageTitle, result.sql);
            this._updateFilterButton = false;
            this.notificationService.showSuccess(executionResultDto.message);
          } else {
            this.notificationService.showError(executionResultDto.message);
          }
        });
      }
    }
  }

  //Reset the query builder and refresh the saved list
  refreshQueryBuilderFilter($event?) {
    if ($event) {
      this._showFilterModel = false;
    }

    $('#builder').queryBuilder('reset');
    this._filterSelectForm.get('selectedFilter').setValue(0);
    this.populateQueryFilterList(this._pageTitle, "");
  }

  getListFields(listId: number, source: string, auditTrailType: string = "Sales", isReset?: boolean) {
    this._listId = listId;
    this._source = source;
    if (source === 'contacts') {
  
      if (isReset) {
        this._filterSelectForm.get('selectedFilter').setValue(0);
      }

      $('#builder').queryBuilder('destroy');
      this.listService.getAllListFields(listId).subscribe((result: ExecutionResultDto) => {
        this._filters = fieldGenerator.generateFields(result.data, this._source);
        this.getQueryBuilder();
        this.refreshSql();
      });
    } else if (source === 'interactions') {
      $('#builder').queryBuilder('destroy');
      this.contactService.getInteractionFields().subscribe((result: ExecutionResultDto) => {
        this._filters = fieldGenerator.generateFields(result.data, this._source);
        this.getQueryBuilder();
      });
    } else if (source === 'consent-history') {
      $('#builder').queryBuilder('destroy');
      this.contactService.getConsentHistoryFields().subscribe((result: ExecutionResultDto) => {
        this._filters = fieldGenerator.generateFields(result.data, this._source);
        this.getQueryBuilder();
      });
    } else if (source === 'audit-trail') {      
      $('#builder').queryBuilder('destroy');
      this.auditTrailService.getAuditTrailFields(auditTrailType).subscribe((result: ExecutionResultDto) => {
        this._filters = fieldGenerator.generateFields(result.data, this._source);
        this.getQueryBuilder();
      });
    }
  }

  initiateQueryBuilder() {
      var getFields: Observable<ExecutionResultDto>;

      if (TableType[this._parentSource] === TableType.messages) {
        getFields = this.messageService.getMessageFields();
      }
      else if (this._listId)
      {
        getFields = this.listService.getAllListFields(this._listId);
      }
      else
      {
        return;
      }

      getFields.subscribe((result: ExecutionResultDto) => {
        $('#builder').queryBuilder('destroy');
        this._filters = fieldGenerator.generateFields(result.data, this._source);
        this.getQueryBuilder();

        if (this._parentQuery !== '') {
          fieldGenerator.importSQLData(this._parentQuery);
        }

        this.populateQueryFilterList(this._pageTitle, "");
      });
  }

  savePressed() {

    var result = MDQueryBuilder.exportQueryData();

    if (result.sql == null && result.rules == null && result.fields.length == 0) {
      this.queryBuilderFilterService.selectedFilter = 0;
    }

    if (this._isCct) {
      result.cct = { 'cctTrue' : this._cctForm.get('cctTrue').value, 'cctFalse' :  this._cctForm.get('cctFalse').value };
    }

    if (this._source === "emaileditor") {
      if (!$.isEmptyObject(result.rules)) {
        window.parent.postMessage({
                'result': result
            },
            "*");
      }
    } else {
      this.queryBuilderSave.emit(
        [$('#builder').queryBuilder('getRules'),
        $('#builder').queryBuilder('getSQLMD')]
      );
    }
  }

  protected rules_basic = {
    condition: 'AND',
    rules: [{
        id: 'price',
        operator: 'less',
        value: 10.25
    }, {
        condition: 'OR',
        rules: [{
            id: 'category',
            operator: 'equal',
            value: 2
        }, {
            id: 'category',
            operator: 'equal',
            value: 1
        }]
    }]
  };

 

   private getQueryBuilder() {
    if(this._filters.length <= 0) {
      return;
     }

     $('#builder').queryBuilder({
       plugins: {
         sortable: { icon: "fas fa-arrows-alt" },
         // 'filter-description' : null,
         'unique-filter': null,
         // 'bt-tooltip-errors': null,
         // 'bt-selectpicker':null,
         // 'bt-checkbox': null,
         'invert': null,

         'not-group' : {icon_unchecked :"far fa-square", icon_checked: "far fa-check-square"},
         'MDToolsPlugin': null
       },
       icons: {
         add_group: 'fas fa-plus',
         add_rule: 'fas fa-plus',
         remove_group: 'fas fa-trash-alt',
         remove_rule: 'fas fa-trash-alt',
         error: 'fas fa-exclamation-triangle'
       },
       operators: $.fn.queryBuilder.constructor.DEFAULTS.operators.concat([
         { type: 'from_rundate',  nb_inputs: 2, multiple: true, apply_to: ['datetime', 'date'] },
         { type: 'greater_from_rundate',  nb_inputs: 2, multiple: true, apply_to: ['datetime', 'date'] },
         { type: 'until_rundate', nb_inputs: 2, multiple: true, apply_to: ['datetime', 'date'] },
         { type: 'less_until_rundate',  nb_inputs: 2, multiple: true, apply_to: ['datetime', 'date'] }
       ]),
       operatorOpposites: {
         'equal': 'not_equal',
         'not_equal': 'equal',
         'in': 'not_in',
         'not_in': 'in',
         'less': 'greater',
         'less_or_equal': 'greater_or_equal',
         'greater': 'less',
         'greater_or_equal': 'less_or_equal',
         'between': 'not_between',
         'not_between': 'between',
         'begins_with': 'not_begins_with',
         'not_begins_with': 'begins_with',
         'contains': 'not_contains',
         'not_contains': 'contains',
         'ends_with': 'not_ends_with',
         'not_ends_with': 'ends_with',
         'is_empty': 'is_not_empty',
         'is_not_empty': 'is_empty',
         'is_null': 'is_not_null',
         'is_not_null': 'is_null',
         'from_rundate': 'until_rundate',
         'greater_from_rundate': 'less_until_rundate',
         'until_rundate': 'from_rundate',
         'less_until_rundate': 'greater_from_rundate'
       },
       sqlOperators: {
         equal: { op: 'ISNULL({1}, \'\') = ?' },
         not_equal: { op: 'ISNULL({1}, \'\') != ?' },
         in: { op: 'ISNULL({1}, \'\') IN(?)', sep: ', ' },
         not_in: { op: 'ISNULL({1}, \'\') NOT IN(?)', sep: ', ' },
         less: { op: 'ISNULL({1}, \'\') < ?' },
         less_or_equal: { op: 'ISNULL({1}, \'\') <= ?' },
         greater: { op: 'ISNULL({1}, \'\') > ?' },
         greater_or_equal: { op: 'ISNULL({1}, \'\') >= ?' },
         between: { op: 'ISNULL({1}, \'\') BETWEEN ?', sep: ' AND ' },
         not_between: { op: 'ISNULL({1}, \'\') NOT BETWEEN ?', sep: ' AND ' },
         begins_with: { op: 'ISNULL({1}, \'\') LIKE(?)', mod: '{0}%' },
         not_begins_with: { op: 'ISNULL({1}, \'\') NOT LIKE(?)', mod: '{0}%' },
         contains: { op: 'ISNULL({1}, \'\') LIKE(?)', mod: '%{0}%' },
         not_contains: { op: 'ISNULL({1}, \'\') NOT LIKE(?)', mod: '%{0}%' },
         ends_with: { op: 'ISNULL({1}, \'\') LIKE(?)', mod: '%{0}' },
         not_ends_with: { op: 'ISNULL({1}, \'\') NOT LIKE(?)', mod: '%{0}' },
         is_empty: { op: 'ISNULL({0}, \'\') = \'\'' },
         is_not_empty: { op: 'ISNULL({0}, \'\') != \'\'' },
         is_null: { op: 'IS NULL' },
         is_not_null: { op: 'IS NOT NULL' },
         from_rundate: { op: 'ISNULL(CAST({2} AS date), \'\') = DATEADD({0}, {1}, CAST(SYSDATETIME() AS date))' },
         greater_from_rundate: { op: 'ISNULL(CAST({2} AS date), \'\') >= DATEADD({0}, {1}, CAST(SYSDATETIME() AS date))'}, // >=
         until_rundate: { op: 'ISNULL(CAST({2} AS date), \'\') = DATEADD({0}, -{1}, CAST(SYSDATETIME() AS date))' },
         less_until_rundate: { op: 'ISNULL(CAST({2} AS date), \'\') <= DATEADD({0}, -{1}, CAST(SYSDATETIME() AS date))' } // <=
       },

       filters: this._filters,
       rules: this._queryRules,
       lang: {
         operators: {
           from_rundate: 'after today',
           until_rundate: 'before today',
           greater_from_rundate: 'after today + ', // >=  // Post- Future
           less_until_rundate: 'before today + ' // <=       //
         }
       },
     });
  }
}

var MDQueryBuilder = {
  exportQueryData: function() {
      var result : any = new Object();
      var fieldString: any = new Array();

      result.rules = $('#builder').queryBuilder('getRules');
      result.sql = $('#builder').queryBuilder('getSQLMD');

      var fieldRules = $('#builder').queryBuilder('getModel').rules;

      $.each(fieldRules, function (i, val) {
          fieldString = fieldString.concat(MDQueryBuilder.processQueryBuilderRuleGroups(val));
      });

      result.fields = fieldString;


      return result;
  },
  importQueryData: function(queryData) {
    $('#builder').queryBuilder('setRules', queryData);
  },
  processQueryBuilderRuleGroups(group) {
      var fieldString = new Array();

      if ("filter" in group) {
          fieldString.push(group.filter.id);
      } else {

          $.each(group.rules,
              function(i, val) {
                  fieldString = fieldString.concat(MDQueryBuilder.processQueryBuilderRuleGroups(val));
              });
      }

      return fieldString;

  }
}

var fieldGenerator = {
  generateFields: function(fieldsJson, source) {
      var filterData = [];

      fieldsJson.forEach(element => {

          var data: any = {};
          var values: any = {};

          switch (element.ControlType.ControlTypeName) {
              case "Input":
                  if (element.ListFieldType.SqlType === "nvarchar") {
                      data.id = element.ColumnName;
                      data.name = "[" + element.DisplayName.replace(" ", "_") + "]";
                      data.label = element.DisplayName;
                      data.type = "string";
                      data.operators = [
                          'equal', 'not_equal', 'in', 'not_in', 'begins_with', 'not_begins_with', 'contains',
                          'not_contains', 'ends_with', 'not_ends_with', 'is_empty', 'is_not_empty', 'is_null',
                          'is_not_null'
                      ];
                      data.value_separator = "|";
                      data.description = function (rule) {
                          if (rule.operator && ['in', 'not_in'].indexOf(rule.operator.type) !== -1) {
                              return 'Use a pipe (|) to separate multiple values with "in" and "not in" operators';
                          }
                      };
                  } else if (element.ListFieldType.FieldTypeName === "Number" || element.ListFieldType.FieldTypeName === "BigNumber" || element.ListFieldType.FieldTypeName === "Currency") {
                      data.id = element.ColumnName;
                      data.name = "[" + element.DisplayName.replace(" ", "_") + "]";
                      data.label = element.DisplayName;
                      data.type = "double";
                      data.operators = [
                          'equal', 'not_equal', 'in', 'not_in', 'less', 'less_or_equal', 'greater', 'greater_or_equal',
                          'between', 'not_between', 'begins_with', 'not_begins_with', 'contains', 'not_contains',
                          'ends_with', 'not_ends_with', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'
                      ];
                      data.value_separator = "|";
                      data.description = function (rule) {
                          if (rule.operator && ['in', 'not_in'].indexOf(rule.operator.type) !== -1) {
                              return 'Use a pipe (|) to separate multiple values with "in" and "not in" operators';
                          }
                      };
                  }
                  break;
              case "Date time picker":
                  data.id = element.ColumnName;
                  data.label = element.DisplayName;
                  data.type = "datetime";
                  data.placeholder = "----/--/-- --:--";
                  data.operators = ["equal", "not_equal", "less", "less_or_equal", "greater", "greater_or_equal",
                  "between", "not_between", "is_empty", "is_not_empty", "is_null", "is_not_null",
                  'from_rundate', 'greater_from_rundate',
                  'until_rundate', 'less_until_rundate'];
                  data.input_event = "dp.change";
                  break;
              case "Date picker":
                  data.id = element.ColumnName;
                  data.label = element.DisplayName;
                  data.type = "date";
                  data.placeholder = "----/--/--";
                  data.operators = ["equal", "not_equal", "less", "less_or_equal", "greater", "greater_or_equal", "between",
                                    "not_between", "is_empty", "is_not_empty", "is_null", "is_not_null",
                                    'from_rundate', 'greater_from_rundate',
                                    'until_rundate', 'less_until_rundate'];
                  data.input_event = "dp.change";
                  break;
              case "Time picker":
                  data.id = element.ColumnName;
                  data.label = element.DisplayName;
                  data.type = "time";
                  data.placeholder = "--:--";
                  data.operators = ["equal", "not_equal", "less", "less_or_equal", "greater", "greater_or_equal", "between",
                                    "not_between", "is_empty", "is_not_empty", "is_null", "is_not_null"];
                  data.input_event = "dp.change";
                  break;
              case "Dropdown":
                  if (element.ListFieldType.FieldTypeName != "Boolean") {

                      data.id = element.ColumnName;
                      data.name = "[" + element.DisplayName.replace(" ", "_") + "]";
                      data.label = element.DisplayName;
                      data.type = "string";
                      data.multiple = true,
                      data.operators = ['in', 'not_in', 'equal', 'not_equal', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'];
                      data.input = 'select';
                      values = {};
                      element.ListFieldOptions.forEach(e => {
                        var inVal = {};
                        inVal[e.OptionValue] = e.OptionValue;
                        Object.assign(values, inVal);
                      });
                      data.values = values;
                  } else {
                      data.id = element.ColumnName;
                      data.name = "[" + element.DisplayName.replace(" ", "_") + "]";
                      data.label = element.DisplayName;
                      data.type = "string";
                      data.input = "radio";
                      data.operators = ['equal', 'not_equal', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'];
                      data.values = { 1: "True", 0: "False" };
                      data.colors = { 1: "success", 0: "danger" };
                  }
                  break;
              case "Checkbox":
                  data.id = element.ColumnName;
                  data.name = "[" + element.DisplayName.replace(" ", "_") + "]";
                  data.label = element.DisplayName;
                  data.type = "string";
                  data.input = "checkbox";
                  data.color = "primary";
                  data.operators = ['contains', 'not_contains', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'];

                  values = {};
                  element.ListFieldOptions.forEach(e => {
                      var inVal = {};
                      inVal[e.OptionValue] = e.OptionValue;
                      Object.assign(values, inVal);
                  });
                  data.values = values;

                  break;
              case "Radiobutton":
                data.id = element.ColumnName;
                data.name = "[" + element.DisplayName.replace(" ", "_") + "]";
                data.label = element.DisplayName;
                data.type = "string";
                data.multiple = true,
                data.operators = ['in', 'not_in', 'equal', 'not_equal', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'];
                data.input = 'select' //keep as select, even though control is a radio button - this so you can select multiple options when using IN operator.
                values = {};
                element.ListFieldOptions.forEach(e => {
                    var inVal = {};
                    inVal[e.OptionValue] = e.OptionValue;
                    Object.assign(values, inVal);
                });
                data.values = values;
                break;
              case "Text area":
                  data.id = element.ColumnName;
                  data.name = "[" + element.DisplayName.replace(" ", "_") + "]";
                  data.label = element.DisplayName;
                  data.type = "string";
                  data.input = "textarea";
                  data.operators = ['equal', 'not_equal', 'in', 'not_in', 'is_empty', 'is_not_empty', 'is_null', 'is_not_null'];
                  data.size = 30;
                  data.rows = 4;
                  data.value_separator = "|";
                  data.description = function (rule) {
                      if (rule.operator && ['in', 'not_in'].indexOf(rule.operator.type) !== -1) {
                          return 'Use a pipe (|) to separate multiple values with "in" and "not in" operators';
                      }
                  };
                  break;

              default:
          }
          if (data != {}) {
              filterData.push(data);
          }
      });
      return filterData;
  },

  exportSQLData: function() {
      return $('#builder').queryBuilder('getSQL', false, true);
  },

  importSQLData: function(data) {
    try {
      $('#builder').queryBuilder('setRulesFromSQLMD', data);
    }
    catch (e) {
      if (e.message.startsWith("Undefined filter"))
      {
        $('#builder').queryBuilder('reset');
      }
    }
  }
};
