import { ClientUser } from './client-users-model';
import { ClientSetting } from './client-settings-model';
import { ClientAmazonSetting } from './client-amazon-settings-model';
import { Injectable } from '@angular/core';


export class ClientDto {
  public Users: ClientUser[];
  public ClientSettings: ClientSetting;
  public AmazonSettings: ClientAmazonSetting;
  public ClientID: number;
  public Name: string;
  public DatabaseName: string;
  public AccountManagerUserId: number;
  public KnownAs: string;
  public WebsiteUrl: string;
  public PrimaryContactEmail: string;
  public ClientImageUrl: string;
  public Active: boolean;

  constructor(init: any) {
    this.Users = init.Users;
    this.ClientSettings = init.ClientSettings;
    this.AmazonSettings = init.AmazonSettings;
    this.ClientID = init.ClientID;
    this.Name = init.Name;
    this.DatabaseName = init.DatabaseName;
    this.AccountManagerUserId = init.AccountManagerUserId;
    this.KnownAs = init.KnownAs;
    this.WebsiteUrl = init.WebsiteUrl;
    this.PrimaryContactEmail = init.PrimaryContactEmail;
    this.ClientImageUrl = init.ClientImageUrl;
    this.Active = init.Active;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ClientAdapter {
  adapt(item: any) {
    return new ClientDto(item);
  }
}
