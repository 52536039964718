import { Injectable } from '@angular/core';
import { SearchData } from 'src/app/_models/tables/search-data/search-data';

@Injectable({
  providedIn: 'root'
})
export class SearchDataService {
  searchData = new SearchData();

// store the search data into a variable to be used when storage is required
  SetSearchData(searchTerm: string, lastPage: string) {
    this.searchData.searchTerm = searchTerm;
    this.searchData.lastPage = lastPage;
  }
}
