import { ContactFieldDto } from './contact-field-dto';

export class ContactDtoRequest {
    contactId: number;
    listId: number;
    subscriptionComment: string;
    contactFields: ContactFieldDto[];

    constructor({ contactId, listId, subscriptionComment, contactFields  }:
                { contactId?: number; listId?: number; subscriptionComment?: string; contactFields?: ContactFieldDto[] } = {}) {                    
        this.contactId = contactId;
        this.listId = listId;
        this.subscriptionComment = subscriptionComment;
        this.contactFields = contactFields;
    }
}
