import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TriggerModel } from 'src/app/_models/messaging/automation/triggers/trigger-model';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { GetContactsDto } from 'src/app/_models/messaging/automation/triggers/trigger-params/get-contacts-dto';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { AwsSecretSftpDto } from 'src/app/_models/messaging/automation/extracts/aws-secrets-sftp-dto';
import { AwsSecretTagDto } from 'src/app/_models/messaging/automation/extracts/aws-secrets-tag-dto';

@Injectable({
  providedIn: 'root'
})
export class TriggersService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'Triggers';

  public multipleOptions: Array<object> = [];
  public _dynamicFieldForm = this.formBuilder.group({
    fields: new UntypedFormArray([])
  });

  get form() { return this._dynamicFieldForm.controls; }
  get fields() { return this.form.fields as UntypedFormArray; }

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter,
              private formBuilder: UntypedFormBuilder,
              ) { }

  emptyFormFields() {      

    while (this.fields.length !== 0) {      
      this.fields.removeAt(0);
    }
    this.multipleOptions = [];
  }

  getTriggerEvents(): Observable<ExecutionResultDto> {

    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetTriggerEvents`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getWebFormsByTypeID(listID: number, formTypeID: number): Observable<ExecutionResultDto> {
    var query = `?listID=${listID}&formTypeID=${formTypeID}`;

    return this.http
    .get(`${this._baseUrl}WebForm/GetFormsTypeID` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getLightListFields(listID: number, includeReadOnlyFields: boolean = false): Observable<ExecutionResultDto> {
    var query = `?listID=${listID}&includeReadOnlyFields=${includeReadOnlyFields}`;

    return this.http
    .get(`${this._baseUrl}List/GetLightListFields` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getListFields(listID: number): Observable<ExecutionResultDto> {
    var query = `?listID=${listID}`;

    return this.http
    .get(`${this._baseUrl}List/GetListFields` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  createTrigger(trigger: TriggerModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/CreateTrigger`, trigger)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getTrigger(triggerID: number): Observable<ExecutionResultDto> {
    var query = `?triggerID=${triggerID}`;

    return this.http.get(`${this._baseUrl}${this._controllerName}/GetTrigger` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getLightMessage(messageID: number, messageType: MessageTypeEnum): Observable<ExecutionResultDto> {
    const query = `?messageID=${messageID}&messageType=${messageType}`;

    return this.http.get(`${this._baseUrl}Message/GetLightMessage` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  editTrigger(trigger: TriggerModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/EditTrigger`, trigger)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getContactsByID(contactDto: GetContactsDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}Contact/GetContactsByID`, contactDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAwsSecretNames(awsSecretTagDto: AwsSecretTagDto): Observable<ExecutionResultDto> {    
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetAwsSecretNames?tagKey=${awsSecretTagDto.key}&tagValue=${awsSecretTagDto.value}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  createUpdateAwsSecret(awsSecretSftpDto: AwsSecretSftpDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/CreateUpdateAwsSecret`, awsSecretSftpDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}
