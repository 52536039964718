import { Injectable } from '@angular/core';
import { MessageGridRecordDto } from './message-grid-record-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageGridRecordDtoAdapter {

  adapt(item: any): MessageGridRecordDto {
    return new MessageGridRecordDto(item);
  }  
}
