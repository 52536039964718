import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { LinkedInAccessCodeRequest } from 'src/app/_models/social-media/networks/LinkedIn/linked-in-access-code-request';
import { AddPageDtoRequest } from 'src/app/_models/social-media/networks/add-page-dto-request';

@Injectable({
  providedIn: 'root'
})
export class LinkedInService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'LinkedIn';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) {
  }

  getLinkedInCallbackInfo(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetLinkedInCallbackInfo`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  signInWithLinkedIn(LinkedInAccessCodeRequest: LinkedInAccessCodeRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/SignInWithLinkedIn`, LinkedInAccessCodeRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getLinkedInOrganizationPages(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetLinkedInOrganizationPages`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(addPageDtoRequest: AddPageDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}`, addPageDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
  
  delete(pageId: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/${pageId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  refresh(networkId: number): Observable<ExecutionResultDto> {
    return this.http
      .get(`${this._baseUrl}${this._controllerName}/Refresh/${networkId}`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}
