import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { onPageSlide } from '../../../animations/route-animations';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { NetworkService } from 'src/app/_services/social-media/networks/network.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';
import { NetworkAdapter } from 'src/app/_models/social-media/networks/network-adapter';
import { PostsStatuesEnum } from 'src/app/_models/social-media/posts/posts-statues.enum';
import { PagerModelAdapter } from 'src/app/_models/system/pager-model';
import { PostsService } from 'src/app/_services/social-media/posts/posts.service';
import { FilterPostsModelAdapter, FilterPostsModel } from 'src/app/_models/social-media/posts/filter-posts-model';
import { NetworkGroupService } from 'src/app/_services/social-media/network-groups/network-group.service';
import { NetworkGroupDto } from 'src/app/_models/social-media/network-groups/network-group-dto';
import { NetworkGroupAdapter } from 'src/app/_models/social-media/network-groups/network-group-adapter';
import ReportingErrorMessages, { Selects } from '../../shared/constants/app-constants';
import { PostViewModel } from '../../../_models/social-media/posts/post-view.model';
import { IconPath } from '../constants/social-media-constants';
import { LoadingSpinnerService } from '../../../_services/loading-spinner/loading-spinner.service';
import { DateConverterService } from '../../../_services/social-media/date-converter.service';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { browserRefresh } from 'src/app/app.component';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
  animations: [
    onPageSlide,
  ]
})
export class PostsComponent implements OnInit, OnDestroy {
  selectTexts = Selects;
  account: NetworkDto[] = [];
  networks: NetworkDto [] = [];
  networksDropdown: Array<any> = [];
  networkGroups: Array<NetworkGroupDto> = [];
  networkGroupsDropdown: Array<any> = [];
  optionsSelect: Array<any> = [];
  posts: PostViewModel[];
  totalCount: number;
  submittingSearch = false;
  isPickerOpen = false;
  dateTimeFormatString = 'yyyy-MM-ddTHH:mm';
  postsSearchForm: UntypedFormGroup;
  userPermissions: PermissionsModel;
  headLabel = 'All Posts';
  noDataAvailableError: boolean;
  errorMessages = ReportingErrorMessages;
  routeSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private permissionService: PermissionsService,
    private networkService: NetworkService,
    private pagerModelAdapter: PagerModelAdapter,
    private postsService: PostsService,
    private filterPostsModelAdapter: FilterPostsModelAdapter,
    private networkGroupsService: NetworkGroupService,
    private networkAdapter: NetworkAdapter,
    private networkGroupAdapter: NetworkGroupAdapter,
    private loadingSpinnerService: LoadingSpinnerService,
    private dateConverter: DateConverterService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userPermissions = this.permissionService.getPermissionsModel();
    this.permissionService.permissionsModel.subscribe((permissions: PermissionsModel) => {
      this.userPermissions = permissions;
    });

    if (browserRefresh) {
      this.localStorageService.setSocialSearchTerms = null;
    }

    this.buildStatuses();
    this.createPostsSearchForm();
    this.populateNetworkDropdown();
    this.populateNetworkGroupsDropdown();

    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!event.url.includes('/create-post') && !event.url.includes('/posts')) {
          this.localStorageService.setSocialSearchTerms = null;
        }
      }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }


  generateGetPostsModel(paginationData?): FilterPostsModel {
    const postFilter = this.postsSearchForm.value;
    const getPostsModel = this.filterPostsModelAdapter.adapt(postFilter, this.dateConverter);
    getPostsModel.PagerModel = paginationData ?
      this.pagerModelAdapter.setCurrentPagination(paginationData.pageIndex, paginationData.pageSize) :
      this.pagerModelAdapter.createEmpty();

    if (getPostsModel.DateFrom.toString() === '') {
      getPostsModel.DateFrom = new Date();
      getPostsModel.DateFrom.setDate(new Date().getDate() - 7);
      getPostsModel.DateFrom.setHours(0, 0, 0, 0);
    }

    if (getPostsModel.DateTo.toString() === '') {
      getPostsModel.DateTo = new Date();
      getPostsModel.DateTo.setDate(new Date().getDate());
      getPostsModel.DateTo.setHours(23, 59, 59, 0);
    }

    if (getPostsModel.Status === '') {
      getPostsModel.Status = 'All';
    }

    return getPostsModel;
  }

  setFormData() {
    this.postsService.networkGroupId = this.postsSearchForm.get('networkGroups').value;
    this.postsService.networkId = this.postsSearchForm.get('networks').value;
  }

  getPosts(paginationData?): void {
    if (!paginationData) {
      this.postsService.resetPagination();
    }

    this.setFormData();
    const getPostsModel: FilterPostsModel = this.generateGetPostsModel(paginationData);
    if (this.postsSearchForm.invalid) { return; }
    this.loadingSpinnerService.loading();
    this.submittingSearch = true;
    
    this.postsService.getAllPosts(getPostsModel).subscribe((data) => {
      this.totalCount = data.Total;
      this.posts = data.Posts;
      this.noDataAvailableError = !data.Posts.length;
      this.loadingSpinnerService.stopLoading(); 
      
      
      getPostsModel.DateFrom = this.postsSearchForm.value.dateFrom;
      getPostsModel.DateTo = this.postsSearchForm.value.dateTo;
      
      this.localStorageService.setSocialSearchTerms = JSON.stringify(getPostsModel);
    });
  }

  buildStatuses() {
    const statusOptions = PostsStatuesEnum.GetStatusLabelValues();
    statusOptions.forEach(option => {
      option.label = option.label.replace(/([A-Z])/g, ' $1').trim();
      this.optionsSelect.push(option);
    });
  }

  createPostsSearchForm() {
    this.postsSearchForm = this.fb.group({
      networkGroups: [''],
      networks: [''],
      status: [''],
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required],
      searchText: []
    }, {
      validators: [this.networkValidator, this.dateValidator]
    });
  }

  pickerIsOpen(isOpen: boolean){
    this.isPickerOpen = isOpen;
  }

  dateValidator(formGroup: UntypedFormGroup) {
    const fromDate: string = formGroup.get('dateFrom').value;
    const toDate: string = formGroup.get('dateTo').value;

    if (toDate && fromDate) {
      if ((toDate.includes(':') && toDate.includes('-')) && (fromDate.includes(':') && fromDate.includes('-'))) {
        const convertedFromDate: Date = new Date(fromDate);
        const convertedToDate: Date = new Date(toDate);
        if (convertedFromDate < convertedToDate) {
          return null;
        }
      }
    }

    return {dateValidationError: true};
  }

  networkValidator(formGroup: UntypedFormGroup) {
    const networkGroups = formGroup.get('networkGroups').value;
    const networks = formGroup.get('networks').value;
    if (networks !== null && networkGroups !== null)
    {
      if ((networks !== '' && networks.length !== 0) || (networkGroups !== '' && networkGroups.length !== 0)) {
        return null;
      }
    }
    return { networkValidationError: true };
  }

  populateNetworkDropdown() {
    this.networkService.get().subscribe((result: ExecutionResultDto) => {
      result.data.Networks.forEach((networks: NetworkDto) => {
        this.account = [... this.account, this.networkAdapter.adapt(networks)];
      });

      this.account.forEach((account: NetworkDto) => {
        this.networks = [... this.networks, account];
        account.subNetworks.forEach((network: NetworkDto) => {
            this.networks = [... this.networks, network];
        });
      });

      this.networks.forEach((network) => {
        if (this.userPermissions.Social.Posts.Create) {
          this.networksDropdown = [...this.networksDropdown, {
            value: network.networkDetailId,
            label: network.name,
            type: network.type,
            icon: IconPath[network.type],
            network,
          }];
        }
      });

      this.networksDropdown.sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }

        return 0;
      });
    });
  }

  populateNetworkGroupsDropdown() {
    this.networkGroupsService.get().subscribe((result: ExecutionResultDto) => {
      this.networkGroups = this.networkGroupAdapter.adapt(result.data);

      this.networkGroups.forEach((network: NetworkGroupDto) => {
        this.networkGroupsDropdown = [...this.networkGroupsDropdown, {
          value: network.id,
          label: network.name,
          icon: '../../../../../assets/images/list-flat.png',
          networks: network.networks,
          networkGroup: network,
        }];
      });

      this.loadSavedSearchTerms();
    });
  }

  loadSavedSearchTerms() {
    var savedSearchTerms = JSON.parse(this.localStorageService.getSocialSearchTerms);
    if (savedSearchTerms) {
      this.postsSearchForm.get('networkGroups').setValue(savedSearchTerms.NetworkGroups);
      this.postsSearchForm.get('networks').setValue(savedSearchTerms.Networks);    
      if (savedSearchTerms.Status && savedSearchTerms.Status !== 'All') {
        this.postsSearchForm.get('status').setValue(Number(savedSearchTerms.Status)); 
      }
      this.postsSearchForm.get('dateFrom').setValue(savedSearchTerms.DateFrom);   
      this.postsSearchForm.get('dateTo').setValue(savedSearchTerms.DateTo); 

      this.getPosts();
    }
  }
}
