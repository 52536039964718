import { ListTypeEnum } from '../lists/list-type-enum';

export class BaseListDto {

  listId: number;
  folderId: number;
  folderName: string;
  listName: string;
  comment: string;
  creationDate: Date;
  createdById: number;
  updatedDated: Date;
  updatedById: number;
  tableName: string;
  isDeleted: boolean;
  clientId: number;
  listType: ListTypeEnum;


  }




