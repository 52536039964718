import { Injectable } from '@angular/core';
import { ContactPlanDto } from './contact-plan-dto';

@Injectable({
  providedIn: 'root'
})

export class ContactPlanDtoAdapter {

  adapt(item: any): ContactPlanDto {
    return new ContactPlanDto(item);
  }
}
