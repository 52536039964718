<div class="container-fluid">
  <div class="row m-4">

    <div class="col-md-10 offset-md-1">
      <!--Card-->
      <mdb-card cascade="true" narrower="true">
        <!--Card header-->
        <div class="view view-cascade card-header text-center primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center align-items-center">

          <h4 class="h4-responsive card-header-text">Amazon Settings</h4>

        </div>
        <!--/Card header-->
        <div class="px-4 mb-2">
          <form [formGroup]="_amazonSettingsForm" (ngSubmit)="submitAmazonSettings()">
            <div class="row">
              <div class="col-md-6">
                <h5>Simple Email Service Settings</h5>
                <div class="md-form">
                  <input
                    mdbInput
                    type="text"
                    id="accessKeyEmail"
                    class="form-control"
                    formControlName="accessKeyEmail"
                    maxlength="256"
                    [ngClass]="{'is-invalid': _amazonSettingsForm.get('accessKeyEmail').errors
                          && _amazonSettingsForm.get('accessKeyEmail').touched}">
                  <label for="accessKeyEmail">Email Access Key</label>
                  <div class="invalid-feedback text-left">Email Access Key required.</div>
                </div>

                <div class="md-form">
                  <input
                    mdbInput
                    type="text"
                    id="secretKeyEmail"
                    class="form-control"
                    maxlength="256"
                    formControlName="secretKeyEmail"
                    [ngClass]="{'is-invalid': _amazonSettingsForm.get('secretKeyEmail').errors
                          && _amazonSettingsForm.get('secretKeyEmail').touched}">
                  <label for="secretKeyEmail">Email Secret Key</label>
                  <div class="invalid-feedback text-left">Email Secret Key required.</div>
                </div>

                <div class="md-form">
                  <input
                    mdbInput
                    type="text"
                    id="regionEmail"
                    class="form-control"
                    maxlength="256"
                    formControlName="regionEmail"
                    [ngClass]="{'is-invalid': _amazonSettingsForm.get('regionEmail').errors
                          && _amazonSettingsForm.get('regionEmail').touched}">
                  <label for="regionEmail">Email Region</label>
                  <div class="invalid-feedback text-left">Email Region required.</div>
                </div>

                <div class="md-form">
                  <input
                    mdbInput
                    type="text"
                    id="configSettings"
                    class="form-control"
                    maxlength="256"
                    formControlName="configSettings"
                    [ngClass]="{'is-invalid': _amazonSettingsForm.get('configSettings').errors
                          && _amazonSettingsForm.get('configSettings').touched}">
                  <label for="configSettings">Config Settings</label>
                  <div class="invalid-feedback text-left">Config Settings required.</div>
                </div>

                <div class="text-center">
                  <button mdbBtn size="lg" type="button"
                    *ngIf="currentClient.AmazonSettings !== null"
                    class="waves-effect waves-light btn-primary rounded-lg"
                    (click)="confirmDelete.show()"
                    tabindex="-1"
                    mdbWavesEffect>Delete</button>
                </div>
              </div>

            <div class="col-md-6">
              <h5>Simple Queue Service Settings</h5>

              <div class="md-form">
                <input
                  mdbInput
                  type="text"
                  id="accessKeyQueue"
                  class="form-control"
                    maxlength="256"
                    formControlName="accessKeyQueue"
                  [ngClass]="{'is-invalid': _amazonSettingsForm.get('accessKeyQueue').errors
                        && _amazonSettingsForm.get('accessKeyQueue').touched}">
                <label for="accessKeyQueue">Queue Access Key</label>
                <div class="invalid-feedback text-left">Queue Access Key required.</div>
              </div>

              <div class="md-form">
                <input
                  mdbInput
                  type="text"
                  id="secretKeyQueue"
                  class="form-control"
                    maxlength="256"
                    formControlName="secretKeyQueue"
                  [ngClass]="{'is-invalid': _amazonSettingsForm.get('secretKeyQueue').errors
                        && _amazonSettingsForm.get('secretKeyQueue').touched}">
                <label for="secretKeyQueue">Queue Secret Key</label>
                <div class="invalid-feedback text-left">Queue Secret Key required.</div>
              </div>

              <div class="md-form">
                <input
                  mdbInput
                  type="text"
                  id="regionQueue"
                  class="form-control"
                    maxlength="256"
                    formControlName="regionQueue"
                  [ngClass]="{'is-invalid': _amazonSettingsForm.get('regionQueue').errors
                        && _amazonSettingsForm.get('regionQueue').touched}">
                <label for="regionQueue">Queue Region</label>
                <div class="invalid-feedback text-left">Queue Region required.</div>
              </div>

              <div class="md-form">
                <input
                  mdbInput
                  type="text"
                  id="queueName"
                  class="form-control"
                    maxlength="256"
                    formControlName="queueName"
                  [ngClass]="{'is-invalid': _amazonSettingsForm.get('queueName').errors
                        && _amazonSettingsForm.get('queueName').touched}">
                <label for="queueName">Queue Name</label>
                <div class="invalid-feedback text-left">Queue Name required.</div>
              </div>

              <div class="text-center">
                <button *ngIf="!_saving" mdbBtn size="lg"
                  class="waves-effect waves-light btn-primary rounded-lg"
                  [disabled]="!_amazonSettingsForm.valid"
                  mdbWavesEffect>Save</button>
                  <button *ngIf="_saving" mdbBtn size="lg"
                  class="waves-effect waves-light btn-primary rounded-lg"
                  type="button"
                  disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Saving...
                  </button> <br>

              </div>

            </div>
          </div>
          </form>
        </div>
      </mdb-card>
      <!--/Card-->
    </div>
  </div>
</div>

<div mdbModal #confirmDelete="mdb-modal" class="modal fade" id="confirmDelete" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Remove Amazon Settings for "{{this.currentClient.KnownAs}}"</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="confirmDelete.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="trash" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Are you sure want to delete the Amazon Settings for "{{this.currentClient.KnownAs}}"?</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deleteAmazonSettings(); confirmDelete.hide()" mdbWavesEffect>Yes, I am sure
                </a>
                <a type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
                   (click)="confirmDelete.hide()" mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>
