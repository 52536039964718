import { Injectable } from '@angular/core';

export class UserRolePermissionDto {

    public id: number
    public userRoleId: number
    public pageSectionId: number
    public pageId: number
    public permissionKey: number    

    constructor(init: any) {
      this.id = +init.Id
      this.userRoleId = +init.UserRoleId
      this.pageSectionId = +init.PageSectionId
      this.pageId = +init.PageId
      this.permissionKey = +init.PermissionKey      
    }    
}

@Injectable({
  providedIn: 'root'
})

export class UserRolePermissionAdapter { 

  adapt(item: any): UserRolePermissionDto {
    return new UserRolePermissionDto(item);
  }
}
