import { PagerModel } from 'src/app/_models/system/pager-model';

export class ContactsMessagePreviewModel {
  public MessageID: number;
  public ListID: number;
  public PagerModel: PagerModel;
  public Filter: string;

  constructor(init: any) {
    this.MessageID = init.MessageID;
    this.ListID = init.ListID;
    this.PagerModel = init.PagerModel;
    this.Filter = init.Filter;
  }
}
