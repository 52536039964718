import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserRoleMappingDto, UserRoleMappingAdapter } from '../../../_models/admin/users/user-role-mapping-model';
import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})

export class UserRoleMappingService {
  _baseUrl: string = environment.apiURL;

  constructor(private http: HttpClient,
    private userRoleMappingAdapter: UserRoleMappingAdapter) {

    }

    get(userId: number) : Observable<UserRoleMappingDto[]> {
      return this.http
      .get(this._baseUrl + `UserRoleMapping/${userId}`)
      .pipe(map((data: any) => data.map((item: any) => this.userRoleMappingAdapter.adapt(item))));
    }

    post(userRoleMappingDto: UserRoleMappingDto): any{
      return this.http.
      post(this._baseUrl + 'UserRoleMapping', userRoleMappingDto);
    }

    delete(id: number) {
      return this.http.
      delete(this._baseUrl + `UserRoleMapping/${id}`);
    }


}
