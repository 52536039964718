export class ImportListSettingsDto {

   headerRowIsIncluded: boolean;
   updateExisting: boolean;
   compareByFieldName: string;
   compareByColumnIndex: number;
   compareByColumnName: string;

  constructor(init: { headerRowIsIncluded: boolean;
                      updateExisting: boolean;
                      compareByColumnIndex: number;
                      compareByColumnName: string }) {

    this.headerRowIsIncluded = init.headerRowIsIncluded;
    this.updateExisting = init.updateExisting;
    this.compareByColumnIndex = init.compareByColumnIndex;
    this.compareByColumnName = init.compareByColumnName;

  }
}




