import { Injectable } from '@angular/core';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';

@Injectable({
  providedIn: 'root'
})

export class HistoryViewColumnDtoAdapter {
  public adapt(init: any) {
    return init.Columns.map(column => new DataTableColumn({ prop: column.Name, name: column.DisplayName }));
  }
}
