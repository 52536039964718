<app-page-container [title]="tableName">
  <app-messaging-header [tableKey]="tableKey"
                        [permission]="automationMessageCodesPermissions"
                        [folderService]="messageCodeFolderService"
                        [isFolderEmpty]="!data?.length"
                        (folderChanged)="folderSelected($event)"
                        (action)="showCreateMessageCodeModal()">
  </app-messaging-header>

  <app-table [tableKey]="tableKey"
             [permission]="automationMessageCodesPermissions"
             [displayedColumns]="displayedColumns"
             [data]="data"
             [length]="length"
             (tableData)="getMessageCodes($event)"
             (actionEmit)="actionHandler($event)">
  </app-table>
</app-page-container>

<div mdbModal #contactCriteriaGroup="mdb-modal" class="contact-criteria-group-modal modal fade" id="contactCriteriaGroup" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Copy Contact Criteria Group</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="contactCriteriaGroup.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <form [formGroup]="copyContactCriteriaGroupFormGroup">
            <app-md-select
                   [label]="'Client'"
                   [options]="clientSelectOptions"
                   formControlName="client">
            </app-md-select>

            <app-md-select 
                  [label]="'Destination Folder'"
                  [options]="folderSelectOptions"
                  formControlName="messageFolder">
            </app-md-select>

            <app-md-select 
                  [label]="'Destination List Name'"
                  [options]="listSelectOptions"
                  formControlName="list">
            </app-md-select>
          </form>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn-close-contact-criteria-group" outline="true" data-dismiss="modal" (click)="contactCriteriaGroup.hide()">
          Close
        </button>

        <button type="button" class="btn-confirm-contact-criteria-group" (click)="copyContactCriteriaGroup()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
