import { Injectable } from '@angular/core';

export class MediaServerAllFoldersModel {
  FolderName: string;
  Path: string;
  Children: Array<MediaServerAllFoldersModel>;

  /**
   *
   */
  constructor(init: any) {
    this.FolderName = init.FolderName,
    this.Path = init.Path,
    this.Children = new MediaServerAllFoldersModelAdapter().adaptArray(init.Children);

  }
}


// Had to include the adapter in the modal file as caused a self referenceing loop when split out
@Injectable({
  providedIn: 'root'
})

export class MediaServerAllFoldersModelAdapter {
  public adapt(init:any) {
    return new MediaServerAllFoldersModel(init)
  }
  public adaptArray(init: Array<any>): MediaServerAllFoldersModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(folder => new MediaServerAllFoldersModel(folder))
      : [new MediaServerAllFoldersModel(init[0])];
    } else {
      return [];
    }
  }
}
