<!--Card-->    
<mdb-card cascade="true" narrower="true" class="mt-5">      
  <!--Card Header-->
  <div class="view view-cascade gradient-card-header primary-color narrower
  py-2 mx-4 mb-3 d-flex row justify-content-center list-table-header">
    <div class="text-center">
      <h4 class="page-title">Upload History</h4>    
    </div>    
  </div>
<!--/Card Header-->

<!--Card Content-->
<mdb-card-body cascade="true" class="text-center pb-4"> 
  <form>
  <section>
    <div class="container-fluid"> 
      <div class="row">
        <div class="col-md-12">                
            <ngx-datatable                                   
            class="material striped"
            [columnMode]="_columnMode.force"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="50"
            [scrollbarV]="false"
            [scrollbarH]="true"
            [rows]="_rows"
            [columns]="_columns"
            [externalPaging]="true"
            [count]="_pageCount"
            [offset]="_pageOffset"
            [limit]="_pageLimit"
            (page)="getHistory($event)"                                           
            > 

            <ngx-datatable-column [headerClass]="'lists-table-headers'" *ngFor="let col of _columns" [prop]="col.prop" [name]="col.name" [width]="col.prop === 'errorsFileName' ? 10 : 100">      
              <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                <div [ngSwitch]="col.prop">
                  <div *ngSwitchCase="'uploadedDate'">
                    {{ isDate(value) ? (value | date:'dd/MM/yyyy') : value }}
                  </div>   
                  <div *ngSwitchCase="'listName'">
                    <a class="link" [routerLink]="['/contacts/' + row.folderId + '/' + row.listId + '/false']">{{value}}</a>                    
                  </div>               
                  <div *ngSwitchCase="'fileName'">
                    <a mdbTooltip="Download Original File" placement="bottom" container="body" class="link" (click)="downloadFile(rowIndex)">{{ value }}</a>
                  </div>
                  <div *ngSwitchCase="'errorsFileName'">
                    <a *ngIf="value !== ''" (click)="downloadErrorsFile(rowIndex)">
                      <mdb-icon mdbTooltip="Download Error File" placement="bottom" container="body" fas icon="file-download"></mdb-icon>                    
                    </a>
                  </div>              
                  <div *ngSwitchDefault>
                    {{value}}
                  </div>
              </div>                
              </ng-template>
            </ngx-datatable-column>              
              <!-- Custom table footer -->
              <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="_pageCount" let-pageSize="_pageLimit"
                  let-curPage="_currentPage" let-offset="_pageOffset">
                  <div class="table-custom-footer">
                    <div>
                      Rows: {{ _pageCount }}
                    </div>
                    <select (change)="onLimitChange($event.target.value)" class="table-page-limit-select">
                      <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                        [selected]="option.value == _pageLimit">
                        {{option.value}} per page
                      </option>
                    </select>
                    <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                      [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                      [pagerNextIcon]="'datatable-icon-skip'" 
                      [page]="_currentPage" 
                      [size]="_pageLimit"
                      [count]="_pageCount" 
                      [hidden]="!((_pageCount / _pageLimit) > 1)"
                      (change)="getHistory($event.page)">
                    </datatable-pager>
                  </div>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable> 
          
        </div>
      </div> 
      <button type="button" mdbBtn color="secondary" class="waves-light mt-3" (click)="goBack()" mdbWavesEffect>Back</button>
    </div>
  </section>  
  </form>
</mdb-card-body>
<!--/Card Content-->
</mdb-card>
<!--/Card-->