import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ListFolderModel } from '../../../../_models/messaging/lists-and-contacts/lists/list-folder-model';


@Injectable({
  providedIn: 'root'
})
export class ListFolderService {
  private baseUrl = environment.apiURL;
  private controllerName = 'ListFolder';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) {  }

  getAll(): Observable <ExecutionResultDto> {
    return this.http
    .get(`${this.baseUrl}${this.controllerName}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  delete(folderID: number): Observable<ExecutionResultDto> {
    return this.http
      .delete(this.baseUrl + 'ListFolder/' + folderID)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  createUpdate(folder: ListFolderModel): Observable<ExecutionResultDto> {
    return this.http
      .post(this.baseUrl + 'Listfolder', folder)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  updateDelete({ sourceId, destinationId }): Observable<ExecutionResultDto> {
    const sourceFolderParams = new HttpParams().set(
      'sourceFolderId', sourceId.toString());
    const destinationFolderParams = new HttpParams().set(
      'destinationFolderId', destinationId.toString());

    return this.http.delete(this.baseUrl + 'ListFolder/?' + sourceFolderParams + '&' + destinationFolderParams)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}
