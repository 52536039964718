<section class="view intro intro-2">
	<div class="full-bg-img rgba-stylish-strong mask flex-center">
		<div class="container animated fadeIn">
			<div class="row">
				<div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

					<!--Form with header-->
					<mdb-card bgColor="login-card">
						<mdb-card-body>

							<!--Header-->
							<div class="form-header primary-color rounded-lg">
								<h3>
									<mdb-icon fas icon="sign-in-alt" class="mt-2 mb-2"></mdb-icon> Log in
								</h3>
							</div>

							<!--Body-->

							<div>
								<img src="../../../../assets/images/voicebox-logo-square.png" alt=""
									class="vb-logo-image">
							</div>

							<form [formGroup]="_loginForm" (ngSubmit)="login()">
								<div class="md-form text-left">
									<mdb-icon fas icon="user" class="prefix icon-color"></mdb-icon>
									<input type="text"
										[ngClass]="{'is-invalid': _loginForm.get('username').errors && _loginForm.get('username').touched}"
										id="orangeForm-username" class="form-control" formControlName="username"
										mdbInput>
									<label for="orangeForm-username" class="">Your username</label>
									<div class="invalid-feedback ml-5">Please enter your username.</div>
								</div>

								<div class="md-form text-left">
									<mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
									<input type="password"
										[ngClass]="{'is-invalid': _loginForm.get('password').errors && _loginForm.get('password').touched}"
										id="orangeForm-pass" class="form-control" formControlName="password" mdbInput>
									<label for="orangeForm-pass" class="primary-text">Your password</label>
									<div class="invalid-feedback ml-5">Please enter your password.</div>
								</div>

								<div class="text-center">
									<button *ngIf="!_loggingIn" mdbBtn size="lg"
										class="waves-effect waves-light btn-primary rounded-lg login-btn left-move"
										[disabled]="!_loginForm.valid" mdbWavesEffect>Login</button>
									<button *ngIf="_loggingIn" mdbBtn size="lg"
										class="waves-effect waves-light btn-primary rounded-lg" type="button" disabled>
										<span class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span>
										Logging In...
									</button> <br>
									<a *ngIf="!_loggingIn" class="register-text"
										[routerLink]="['/register']">Register</a><br/>
									<a *ngIf="!_loggingIn" class="forgot-password-text"
										[routerLink]="['/forgot-password']">Forgot Password</a>
								</div>
							</form>

						</mdb-card-body>
					</mdb-card>
					<!--/Form with header-->

				</div>
			</div>
		</div>
	</div>
</section>