import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { AddNetworkGroupDtoRequest } from 'src/app/_models/social-media/network-groups/add-network-group-dto-request';

@Injectable({
  providedIn: 'root'
})

export class NetworkGroupService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'NetworkGroup';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) { }

  delete(networkGroupId: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/${networkGroupId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  get(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getNetworks(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetNetworks`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(addNetworkGroupDtoRequest: AddNetworkGroupDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}`, addNetworkGroupDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

