import { CopyContactPlanContactPlansDto } from "./copy-contact-plan-contact-plans-dto";

export class CopyContactPlanDto{
    FolderId: number;
    ContactPlans: Array<CopyContactPlanContactPlansDto>

    /**
     *
     */
    constructor(init: any) {
      this.FolderId = init.FolderId,
      this.ContactPlans = init.ContactPlans

    }

}
