import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private baseUrl = environment.apiURL;
  private controllerName = 'Network';

  constructor(private http: HttpClient, private executionResultAdapter: ExecutionResultAdapter) { }

  get(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this.baseUrl}${this.controllerName}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  refresh(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this.baseUrl}${this.controllerName}/refresh`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

