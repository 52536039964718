import { Injectable } from '@angular/core';
import { GetBucketObjectsModel } from './get-bucket-objects-model';

@Injectable({
  providedIn: 'root'
})

export class GetBucketObjectsModelAdapter {
  public adapt(init: any): GetBucketObjectsModel {
    return new GetBucketObjectsModel(init);
  }
}
