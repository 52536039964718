import { Injectable } from "@angular/core";
import { ApiIntegrationModel } from "./api-integration-model";
   
@Injectable({
  providedIn: 'root'
})
export class ApiIntegrationModelAdapter {
    adaptArray(init: Array<any>): ApiIntegrationModel[] {
        if (init && init.length > 0) {
          return init.length > 1
          ? init.map(table => new ApiIntegrationModel(table))
          : [new ApiIntegrationModel(init[0])];
        } else {
          return [];
        }
      }
}