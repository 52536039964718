import { Injectable } from '@angular/core';
import { ContactsMessagePreviewModel } from './contacts-message-preview-model';

@Injectable({
  providedIn: 'root'
})

export class ContactsMessagePreviewModelAdapter {
  adapt(item: any): ContactsMessagePreviewModel {
    return new ContactsMessagePreviewModel(item);
  }

  createEmpty() {
    return new ContactsMessagePreviewModel({
      MessageID: 0,
      ListID: null,
      PagerModel: null,
      Filter: ''
    });
  }
}
