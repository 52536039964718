<div class="container-fluid">
  <div class="row m-4">

    <div class="col-md-4 offset-md-4">
      <!--Card-->
      <mdb-card cascade="true" narrower="true">
        <!--Card header-->
        <div class="view view-cascade card-header text-center primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center align-items-center">

          <h4 class="h4-responsive card-header-text">Domains</h4>
      
        </div>
        <!--/Card header-->            
        <div class="px-4 mb-2">
          <div>
            <div class="domain-container" *ngFor="let domain of _domains">        
              <div class="domain-badge-container align-middle">
                <mdb-badge class="domain-pill rounded">{{domain.DomainName}}</mdb-badge>
              </div>
              <div class="icon-container align-middle text-center">                  
                <mdb-icon *ngIf="!_removingDomain"
                  fas icon="times" 
                  class="icon align-middle ml-1" 
                  (click)="removeDomain(domain)">
                </mdb-icon>
                <mdb-icon *ngIf="_removingDomain" 
                  fas icon="times" 
                  class="icon align-middle ml-1">
                </mdb-icon>
              </div>              
            </div>
          </div>
          <hr>
          <form [formGroup]="_domainForm">
          <div class="md-form">
            <div class="domain-input">
              <input 
                mdbInput 
                type="text" 
                id="domain" 
                class="form-control"
                formControlName="DomainName" 
                [ngClass]="{'is-invalid': _domainForm.get('DomainName').errors 
                      && _domainForm.get('DomainName').touched}">
              <label for="domain">Add Domain</label>
              <div class="invalid-feedback text-left">Domain required.</div>
            </div>
            <div class="domain-add">
              <mdb-icon fas icon="arrow-up" class="prefix" (click)="addDomain()" *ngIf="_domainForm.valid && !_addingDomain"></mdb-icon>
              <mdb-spinner spinnerColor="blue" spinnerType="small" *ngIf="_addingDomain"></mdb-spinner>
            </div>              
          </div>
          </form>
        </div>
      </mdb-card>
      <!--/Card-->
    </div>
  </div>
</div>