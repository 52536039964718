<form [formGroup]="_editListForm">
<div class="row ml-4 mr-4 mt-4 mb-1">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 mb-3 d-flex justify-content-center">
        <h4 class="page-title">Edit List</h4>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="text-center">

        <section>
          <div class="container-fluid">
            <!-- First Row -->

            <div class="row justify-content-around">
              <div class="col-md-4">
                <div class="md-form remove-top-margin">
                  <input
                    mdbInput
                    type="text"
                    id="list-name"
                    class="form-control"
                    formControlName="listName">
                  <label for="form1" class="">List Name</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="md-form remove-top-margin">
                  <mdb-select
                    [options]="_foldersDropdown"
                    [visibleOptions]="10"
                    label="Choose your option"
                    formControlName="listFolder">
                  </mdb-select>
                </div>
              </div>
            </div>
            <!-- /First Row -->
            <!-- Second Row  -->
            <div class="row">
              <div class="col-md-3">
                <div class="md-form">
                  <button
                    type="button"
                    class="rounded"
                    mdbBtn
                    color="secondary"
                    mdbWavesEffect
                    (click)="backClicked()">
                    Go Back
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="md-form remove-vertical-margin">
                  <textarea
                    type="text"
                    id="optional-comment"
                    class="md-textarea form-control"
                    mdbInput
                    formControlName="listComment"></textarea>
                  <label for="optional-comment">Optional Comment</label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="md-form">
                  <button
                    type="button"
                    class="rounded"
                    mdbBtn
                    color="primary"
                    mdbWavesEffect
                    [disabled]="!_editListForm.valid || _saving"
                    (click)="saveList()">
                    <span *ngIf="!_saving">Save List</span>
                    <span *ngIf="_saving">Saving...<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
                  </button>
                </div>
              </div>
            </div>
            <!-- /Second Row -->
          </div>
        </section>

      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>
<!-- Third Row -->
<div class="row justify-content-center">
  <div class="col-md-8">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5 mb-5">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 mb-3 d-flex justify-content-center">
        <h4 class="page-title">Fields</h4>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="text-center pb-2">

        <section>
          <div class="container-fluid">
            <!-- First Row -->
            <div class="row">
              <div class="col-md-12 text-right">
                <button
                    type="button"
                    class="rounded"
                    mdbBtn
                    color="primary"
                    mdbWavesEffect
                    (click)="addFieldsInit()">
                    <span class="text-truncate">Add Fields</span>
                </button>                
              </div>
            </div>
            <!-- /First Row -->
            <!-- Second Row -->
            <div class="row">
              <mdb-accordion [multiple]="true">
                <mdb-accordion-item [collapsed]="true">
                  <mdb-accordion-item-head class="text-left">
                    <h6 class="font-weight-bold show-hide-text">
                      <mdb-icon class="mr-1" far icon="eye">
                      </mdb-icon><span>Show/Hide Standard Fields...</span>
                    </h6>
                  </mdb-accordion-item-head>
                  <mdb-accordion-item-body>
                    <div class="row">
                      <div class="col-md-6 mb-1" *ngFor="let listField of _allDefaultFields; let i = index">                        
                          <input
                          mdbInput
                          value="{{listField.DisplayName}}"
                          disabled
                          type="text"
                          style="width: 88%; float: left;"
                          id="list-field-{{i}}"
                          class="form-control"> 
                          <button *ngIf="listField.ListFieldType.FieldTypeID === _fieldTypeIdForText"
                            mdbBtn
                            type="button"
                            color="primary"
                            outline="true"
                            class="p-2 ml-1 mr-0 mt-0 mb-0 align-middle icon-button"
                            mdbWavesEffect
                            (click)="editField(i, true, listField.IsDatahub)">
                            <mdb-icon fas class="icon-color" icon="pencil-alt"></mdb-icon>
                          </button>                                               
                      </div>
                    </div>
                  </mdb-accordion-item-body>
                </mdb-accordion-item>

                <div class="row mt-2">
                  <div class="col-md-6 mb-1"
                  *ngFor="let listField of _allCreatedFields; let i = index" [hidden]="listField.IsDeleted">
                    <div *ngIf="!listField.IsDeleted">
                      <input
                      mdbInput
                      value="{{listField.DisplayName}}"
                      type="text"
                      id="list-field-{{i}}"
                      class="form-control created-fields"
                      (keyup)="editFieldName(i, $event)"                      
                      [disabled]="listField.IsDatahub"
                      maxlength="255">
                      <button
                        mdbBtn
                        type="button"
                        color="primary"
                        outline="true"
                        class="p-2 ml-1 mr-0 mt-0 mb-0 align-middle icon-button"
                        mdbWavesEffect                        
                        (click)="editField(i, false, listField.IsDatahub)">
                        <mdb-icon fas class="icon-color" icon="pencil-alt"></mdb-icon>
                      </button>
                      <button
                        *ngIf="listField.FieldID > 0"
                        mdbBtn
                        type="button"
                        color="primary"
                        outline="true"
                        class="p-2 ml-1 mr-0 mt-0 mb-0 align-middle icon-button"
                        mdbWavesEffect
                        (click)="deleteRestoreField(i)">
                        <mdb-icon fas class="icon-color" icon="trash"></mdb-icon>
                      </button>
                      <button
                        *ngIf="listField.FieldID === 0"
                        mdbBtn
                        type="button"
                        color="primary"
                        outline="true"
                        class="p-2 ml-1 mr-0 mt-0 mb-0 align-middle icon-button"
                        mdbWavesEffect
                        (click)="confirmDelete.show(); this._deletingIndex = i">
                        <mdb-icon fas class="icon-color" icon="trash"></mdb-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <mdb-accordion-item>
                  <mdb-accordion-item-head class="text-left">
                    <h6 class="font-weight-bold show-hide-text">
                      <mdb-icon class="mr-1" far icon="eye">
                      </mdb-icon><span>Show/Hide Deleted Fields...</span>
                    </h6>
                  </mdb-accordion-item-head>
                  <mdb-accordion-item-body>
                    <div class="row" mdbCollapse #deletedFields="bs-collapse" [isCollapsed]="false">
                      <div class="col-md-6 mb-1"
                      *ngFor="let listField of _allCreatedFields; let i = index" [hidden]="!listField.IsDeleted">
                        <div *ngIf="listField.IsDeleted">
                          <input
                          mdbInput
                          value="{{listField.DisplayName}}"
                          disabled
                          type="text"
                          id="deleted-list-field-{{i}}"
                          style="width: 88%; float: left;"
                          class="form-control">
                          <button
                            mdbBtn
                            type="button"
                            color="primary"
                            outline="true"
                            class="p-2 ml-1 mr-0 mt-0 mb-0 align-middle icon-button"
                            mdbWavesEffect
                            (click)="deleteRestoreField(i)">
                            <mdb-icon fas class="icon-color" icon="trash-restore"></mdb-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </mdb-accordion-item-body>
                </mdb-accordion-item>
              </mdb-accordion>
            </div>
            <!-- /Second Row -->
          </div>
        </section>

      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>
<!-- /Third Row -->
</form>

<!-- Add Fields Modal -->

<div [config]="{ignoreBackdropClick: true}" (closed)="addEditFieldModalClosed()" mdbModal #addEditFieldModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addEditFieldModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="addEditFieldModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="addEditFieldModalLabel">Add Field</h4>
      </div>
        <div class="modal-body">
          <form [formGroup]="_addEditFieldForm">
            <div class="md-form" *ngIf="this._showDatahubFieldsCheckBox">
              <input class="form-check-input" 
              mdb-checkbox 
              type="checkbox" 
              id="isDatahubField" 
              formControlName="isDatahubField"
              (change)="hideShowDatahubFields()"           
              >
              <label for="isDatahubField" class="">Show Datahub Fields</label>
            </div>
            <div class="md-form" *ngIf="this._showDatahubFields">
                <mdb-select                  
                [options]="_datahubFields"               
                [visibleOptions]="10"
                label="Select Datahub Field"
                id="datahub-field-name"
                formControlName="datahubFieldName"
                (selected)="populateDataHubFieldType()"
                [ngClass]="{'is-invalid': this._addEditFieldForm.get('datahubFieldName').errors && this._addEditFieldForm.get('datahubFieldName').touched}"
                >                
                </mdb-select>              
            </div>  
            <div class="md-form" *ngIf="!this._showDatahubFields">
              <input                
                mdbInput
                type="text"
                id="field-name"
                class="form-control"
                formControlName="fieldName"
                [ngClass]="{'is-invalid': this._addEditFieldForm.get('fieldName').errors && this._addEditFieldForm.get('fieldName').touched}">
              <label for="field-name" class="">Field Name</label>
              <div *ngIf="this._addEditFieldForm.get('fieldName').hasError('required')
              && this._addEditFieldForm.get('fieldName').touched" class="invalid-feedback text-left">
                A Field Name is required.
              </div>
              <div *ngIf="this._addEditFieldForm.get('fieldName').hasError('maxlength')
              && this._addEditFieldForm.get('fieldName').touched" class="invalid-feedback text-left">
                A Field Name can only contain up to 255 characters.
              </div>
            </div>
            <div class="md-form">
              <mdb-select
                #fieldTypesDropdown
                [options]="_fieldTypesDropdown"
                [visibleOptions]="10"
                [filterEnabled]="true"
                label="Field Type"
                class="form-control remove-form-control-border"
                formControlName="fieldType"
                (selected)="populateFieldControlTypesDropdown(); clearFieldOptions();"
                [ngClass]="{'is-invalid': this._addEditFieldForm.get('fieldType').errors && this._addEditFieldForm.get('fieldType').touched}">
              </mdb-select>
              <div class="invalid-feedback text-left">
                A Field Type is required.
              </div>
            </div>
            <div class="md-form">
              <mdb-select
                [options]="_fieldControlTypeDropdown"
                [visibleOptions]="10"
                label="Field Control Type"
                class="form-control remove-form-control-border"
                formControlName="fieldControlType"
                [ngClass]="{'is-invalid': this._addEditFieldForm.get('fieldControlType').errors && this._addEditFieldForm.get('fieldControlType').touched}"
                (selected)="showFieldOptions()">
              </mdb-select>
              <div class="invalid-feedback text-left">
                A Field Control Type is required.
              </div>
            </div>
          <div *ngIf="this._showFieldOptions" class="md-form field-options">
            <div class="field-options-input">
              <input
                mdbInput
                type="text"
                id="field-options"
                class="form-control"
                formControlName="fieldOptions"
                (keyup)="addFieldOption($event)">
              <label for="field-options">Field Options</label>
            </div>
            <div class="field-options-icon">
              <button
                mdbBtn
                color="primary"
                outline="true"
                rounded="true"
                size="sm"
                type="button"
                class="button-icon-padding"
                mdbWavesEffect
                (click)="addFieldOption($event)">
                <mdb-icon fas size="lg" class="" icon="plus"></mdb-icon>
              </button> 
            </div>

            <div class="field-options-icon">
              <button
                mdbBtn
                color="primary"
                outline="true"
                rounded="true"
                size="sm"
                type="button"
                class="button-icon-padding"
                mdbWavesEffect
                (click)="addCsvListInit()">
                <mdb-icon fas size="lg" class="" icon="paste"></mdb-icon>
              </button> 
            </div>

          </div>
        </form>
              <div *ngIf="this._showFieldOptions" class="field-options-list-container">
                <div class="scrollbar scrollbar-primary force-overflow field-options-list">
              <ul>
                <li
                  *ngFor="let option of _fieldOptions; let i = index"
                  class="field-option-list-item"
                  [ngClass]="{'field-option-list-item-selected': this._selectedFieldOptionIndex === i}"
                  (click)="fieldOptionSelect(i)">
                <mdb-badge
                  pill="true"
                  id="field-option-{{i}}"
                  class="field-option-pill">{{option}}</mdb-badge>
                </li>
              </ul>
            </div>
            <div class="field-options-list-buttons">
              <button
                mdbBtn
                color="primary"
                outline="true"
                rounded="true"
                size="sm"
                type="button"
                class="button-icon-padding"
                mdbWavesEffect
                [disabled]="this._selectedFieldOptionIndex === 0 || this._selectedFieldOptionIndex === undefined"
                (click)="moveFieldOption(this._selectedFieldOptionIndex, this._selectedFieldOptionIndex - 1)">
                <mdb-icon fas size="lg" class="" icon="angle-up"></mdb-icon>
              </button>
              <button
                mdbBtn
                color="primary"
                outline="true"
                rounded="true"
                size="sm"
                type="button"
                class="button-icon-padding"
                mdbWavesEffect
                [disabled]="this._selectedFieldOptionIndex === (this._fieldOptions.length - 1) || this._selectedFieldOptionIndex === undefined"
                (click)="moveFieldOption(this._selectedFieldOptionIndex, this._selectedFieldOptionIndex + 1)">
                <mdb-icon fas size="lg" class="" icon="angle-down"></mdb-icon>
              </button>
              <button
                mdbBtn
                color="primary"
                outline="true"
                rounded="true"
                size="sm"
                type="button"
                class="button-icon-padding"
                mdbWavesEffect
                [disabled]="this._selectedFieldOptionIndex === undefined"
                (click)="removeFieldOption()">
                <mdb-icon fas size="lg" class="" icon="times"></mdb-icon>
              </button>
            </div>
          </div>
          <div class="field-options-container">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="addEditFieldModal.hide()" mdbWavesEffect>Close</button>
          <button type="button" [disabled]="!_addEditFieldForm.valid" mdbBtn color="primary" class="relative waves-light" (click)="addEditField()" mdbWavesEffect>Save</button>
        </div>
    </div>
  </div>
</div>


<div mdbModal #confirmDelete="mdb-modal" class="modal fade" id="confirmDelete" tabindex="-1" role="dialog" (closed)="confirmDeleteModalClosed()"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Delete Field?</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="confirmDelete.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="trash" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Are you sure you want to delete this field?</p>
                    <p class="font-weight-bold">
                      This List has not been saved with this new field.
                      This new field will be deleted permanently, and you will
                      not be able to restore it.
                    </p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deleteRestoreField(null); confirmDelete.hide()" mdbWavesEffect>Yes, I am sure
                </a>
                <a type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
                   (click)="confirmDelete.hide()" mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>

<div [config]="{ignoreBackdropClick: true}" (closed)="addCsvListModalClosed()" mdbModal #addCsvListModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addEditFieldModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded-lg">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="addCsvListModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="addEditFieldModalLabel">Import Field Options List</h4>
      </div>
        <div class="modal-body">
          <p>
            Copy and paste, or enter a list of values separated by a comma. E.g.: ValueA, ValueB, ValueC
          </p>
          <form [formGroup]="_addCsvListForm">
            <div class="md-form">
              <textarea 
                type="text" 
                id="csvList" 
                class="md-textarea form-control" 
                formControlName="csvList"
                rows="5"
                [ngClass]="{'is-invalid': this._addCsvListForm.get('csvList').errors && this._addCsvListForm.get('csvList').touched}"              
                mdbInput>
              </textarea>
              <label for="csvList" class="">Comma Separated Values</label>
              <div *ngIf="this._addCsvListForm.get('csvList').hasError('required') && this._addCsvListForm.get('csvList').touched" class="invalid-feedback text-left">
                A value is required
              </div>        
            </div>          
          </form>            
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="addCsvListModal.hide()" mdbWavesEffect>Close</button>
          <button type="button" [disabled]="!_addCsvListForm.valid" mdbBtn color="primary" class="relative waves-light" (click)="importCsvList()" mdbWavesEffect>Save</button>
        </div>
    </div>
  </div>
</div>