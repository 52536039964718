import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { MessageCodeStepDto } from 'src/app/_models/messaging/automation/message-codes/message-code-step-dto';
import { MessageCodeStepService } from 'src/app/_services/messaging/automation/message-codes/message-code-step.service';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-create-edit-message-code',
  templateUrl: './create-edit-message-code.component.html',
  styleUrls: ['./create-edit-message-code.component.scss']
})
export class CreateEditMessageCodeComponent implements OnInit {

  @Output() saved = new EventEmitter();

  messageCodeForm: UntypedFormGroup = new UntypedFormGroup({
    messageCodeId: new UntypedFormControl(),
    messageCodeStepId: new UntypedFormControl(0),
    criteriaCode: new UntypedFormControl('', Validators.required),
    orderOfPrecedence: new UntypedFormControl(1, Validators.required),
    listId: new UntypedFormControl({value: '', disabled: true}, Validators.required),
    description: new UntypedFormControl(''),
    sql: new UntypedFormControl('', Validators.required),
    startDate: new UntypedFormControl('', Validators.required),
    endDate: new UntypedFormControl('', Validators.required),
  }, this.validateDate);

  messageCodeRules = '';
  initQueryBuilder: boolean = false;
  _pageTitle = 'Create Edit Message Codes';

  constructor(
    private messageCodeStepService: MessageCodeStepService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.onChanges();
    this.setStandardFormValues();
  }

  initForEdit(messageCodeStepId: number) {
    this.messageCodeStepService.get(messageCodeStepId).subscribe(result => {
      this.populateMessageCodeForm(result.data);
    });
  }

  onChanges() {
    this.messageCodeForm.get('listId').valueChanges.subscribe(val => {
      if (val) {
        this.initQueryBuilder = false;
        setTimeout(() => {
          this.initQueryBuilder = true;
        });
      } else {
        this.initQueryBuilder = false;
      }
    });
  }

  validateDate(group: UntypedFormGroup) {
    const invalid = group.get('startDate').value > group.get('endDate').value;

    return invalid ? { 'invalidDate': true } : null;
  }

  populateMessageCodeForm(data) {
    const messageCodeStepDto = new MessageCodeStepDto (data);
    this.messageCodeRules = messageCodeStepDto.sql;
    this.messageCodeForm.get('criteriaCode').setValue(messageCodeStepDto.criteriaCode);
    this.messageCodeForm.get('orderOfPrecedence').setValue(messageCodeStepDto.orderOfPrecedence);
    this.messageCodeForm.get('description').setValue(messageCodeStepDto.description);
    this.messageCodeForm.get('listId').setValue(messageCodeStepDto.listId);
    this.messageCodeForm.get('messageCodeId').setValue(messageCodeStepDto.messageCodeId);
    this.messageCodeForm.get('messageCodeStepId').setValue(messageCodeStepDto.messageCodeStepId);
    this.messageCodeForm.get('sql').setValue(messageCodeStepDto.sql);
    this.messageCodeForm.get('startDate').setValue(new Date(messageCodeStepDto.startDate).toISOString().slice(0, -1));
    this.messageCodeForm.get('endDate').setValue(new Date(messageCodeStepDto.endDate).toISOString().slice(0, -1));
  }

  setStandardFormValues() {
    this.route.params.subscribe(val => {
      this.messageCodeForm.get('messageCodeId').setValue(+val.messageCodeId);
    });
    this.messageCodeForm.get('orderOfPrecedence').setValue(1);
    this.messageCodeForm.get('messageCodeStepId').setValue(0);
    this.messageCodeForm.get('description').setValue('');
  }

  messageCodeSQLSave(event) {
    if (event[0] === null && event[1] === null) {
      this.messageCodeForm.controls.sql.setValue('');
    } else {
      this.messageCodeForm.controls.sql.setValue(event[1].sql);
    }
  }

  messageCodeFormSubmit() {
    const messageCodeStepDto = new MessageCodeStepDto ( {
      CriteriaCode: this.messageCodeForm.controls.criteriaCode.value,
      OrderOfPrecedence: this.messageCodeForm.controls.orderOfPrecedence.value,
      Description: this.messageCodeForm.controls.description.value,
      ListID: this.messageCodeForm.controls.listId.value,
      MessageCodeID: this.messageCodeForm.controls.messageCodeId.value,
      MessageCodeStepID: this.messageCodeForm.controls.messageCodeStepId.value,
      Sql: this.messageCodeForm.controls.sql.value,
      StartDate: this.messageCodeForm.controls.startDate.value,
      EndDate: this.messageCodeForm.controls.endDate.value
    });

    this.messageCodeStepService.post(messageCodeStepDto).subscribe(executionResultDto => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.saved.emit();
      } else {
        this.notificationService.showError(executionResultDto.message);        
      }
    });
  }
}
