export enum NetworkTypeEnum {
  Facebook = 1,
  Twitter = 2,
  'Facebook Page' = 3,
  Instagram = 4,
  LinkedIn = 5
}

export enum SocialWebsEnum {
  twitter = 2,
  facebook = 3,
  instagram = 4,
  linkedin = 5
}
