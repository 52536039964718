
<a (click)="toggleExpanded()" class="folders rounded p-2 m-2" [ngClass]="{'underlined': _expanded}"> <mdb-icon *ngIf="this.folders.Children.length" fas icon="caret-right"></mdb-icon> {{this.folders.FolderName}} </a>
<div *ngIf="this.folders.Children.length && _expanded" class="ml-5 pt-1" >
  <div *ngFor="let folder of this.folders.Children" class="pb-2 pt-2">
    <app-media-server-folder-tree
      [folders]="folder"
      (childDeselected)="deselectChild()"
      (childSelected)="selectChild()"
      [hasSiblingSelected]="_isSiblingSelected">
    </app-media-server-folder-tree>
  </div>
</div>
