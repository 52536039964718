<section class="view intro intro-2">
  <div *ngIf="!_requestSent" class="full-bg-img rgba-stylish-strong mask flex-center">
    <div class="container animated fadeIn">
      <div class="row">
        <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

          <!--Form with header-->
          <div class="face front tp-box_side tp-box_front rounded forgot-password-card">
            <mdb-card bgColor="register-card">
              <mdb-card-body>
                <!--Header-->
                <div class="form-header primary-color rounded-lg mb-3"
                  style="width: 90%; margin-left: auto; margin-right: auto">
                  <h3>
                    <mdb-icon fas icon="lock" class="mt-2 mb-2"></mdb-icon> Forgot Password
                  </h3>
                </div>

                <!--Body-->

                <div class="mt-4">

                  <form [formGroup]="_forgotPasswordRequestForm" (ngSubmit)="resetRequest(usernameTextBox.value)">
                    <div class="md-form text-left m-3">
                      <mdb-icon fas icon="user" class="prefix icon-color"></mdb-icon>
                      <input type="text"
                        [ngClass]="{'is-invalid': _forgotPasswordRequestForm.get('username').errors && _forgotPasswordRequestForm.get('username').touched}"
                        id="orangeForm-username" class="form-control" formControlName="username" #usernameTextBox
                        mdbInput>
                      <label for="orangeForm-username" class="">Your username</label>
                      <div class="invalid-feedback ml-5">Please enter your username.</div>
                    </div>

                    <div class="text-center">
                      <ngx-recaptcha2 #captchaElem [siteKey]="'6LeqCMAUAAAAACTk4sDYT-lbptHFg79nZL-g4MPp'"
                        [useGlobalDomain]="false" style="display: inline-block" formControlName="recaptcha">
                      </ngx-recaptcha2> <br>
                      <button *ngIf="!_verifying" mdbBtn size="lg"
                        class="waves-effect waves-light btn-primary rounded-lg login-btn"
                        [disabled]="!_forgotPasswordRequestForm.valid" mdbWavesEffect>Request
                        Reset</button>
                      <button *ngIf="_verifying" mdbBtn size="lg" class="waves-effect waves-light btn-primary rounded-lg"
                        type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Verifying...
                      </button> <br>
                      <a class="login-text" [routerLink]="['/login']">Login Here</a>
                    </div>
                  </form>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
        <!--/Form with header-->
      </div>
    </div>
  </div>

  <!--/Form with header-->

  <!-- CARD BELOW IF USER IS RESETTING AND NOT REQUESTING A RESET -->

  <!--Form with header-->
  <div *ngIf="_requestSent" class="full-bg-img rgba-stylish-strong mask flex-center">
    <div class="container animated fadeIn">
      <div class="row">
        <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

          <!--Form with header-->
          <div class="face front tp-box_side tp-box_front rounded forgot-password-card">
            <mdb-card bgColor="register-card">
              <mdb-card-body>
                <!--Header-->
                <div class="form-header primary-color rounded-lg mb-3"
                  style="width: 90%; margin-left: auto; margin-right: auto">
                  <h3>
                    <mdb-icon fas icon="lock" class="mt-2 mb-2"></mdb-icon> Forgot Password
                  </h3>
                </div>

                <!--Body-->

                <div class="mt-4">

                  <form [formGroup]="_resetPasswordForm"
                    (ngSubmit)="resetPassword(validateTextBox.value, newPasswordTextBox.value)">
                    <div class="md-form text-left m-3">
                      <mdb-icon fas icon="user" class="prefix icon-color"></mdb-icon>
                      <input type="text"
                        [ngClass]="{'is-invalid': _resetPasswordForm.get('validate').errors && _resetPasswordForm.get('validate').touched}"
                        id="orangeForm-validate" class="form-control" formControlName="validate" #validateTextBox
                        autocomplete="off" mdbInput>
                      <label for="orangeForm-validate" class="">Verification Code</label>
                      <div class="invalid-feedback ml-5"></div>
                    </div>

                    <div class="md-form">
                      <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                      <input type="password" [ngClass]="{'is-invalid': _resetPasswordForm.get('password').errors
                                && _resetPasswordForm.get('password').touched}" id="password" class="form-control"
                        formControlName="password" mdbInput #newPasswordTextBox />
                      <label for="password">Your password</label>
                      <div class="invalid-feedback ml-5" *ngIf="_resetPasswordForm.get('password').hasError('required')
                          && _resetPasswordForm.get('password').touched">Please enter your Password.
                      </div>
                      <div class="invalid-feedback ml-5" *ngIf="_resetPasswordForm.get('password').hasError('minlength')
                          && _resetPasswordForm.get('password').touched">Password must be atleast 8 characters.
                      </div>
                      <div class="invalid-feedback ml-5" *ngIf="_resetPasswordForm.get('password').hasError('weak')
                          && _resetPasswordForm.get('password').touched">Passwords must contain one upper case letter,
                        one lower case letter and one special character.
                        <br />Special characters include: ^ $ * . [ ] &#123; &#125; ( ) ? &quot; ! @ # % / , &lt; &gt; &apos; : ; | _ ~ &#96;
                      </div>
                    </div>

                    <div class="md-form">
                      <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                      <input type="password" [ngClass]="{'is-invalid': _resetPasswordForm.get('confirmPassword').errors
                                && _resetPasswordForm.get('confirmPassword').touched
                                || _resetPasswordForm.get('confirmPassword').touched
                                && _resetPasswordForm.hasError('mismatch')}" id="password-confirm" class="form-control"
                        formControlName="confirmPassword" mdbInput>
                      <label for="password-confirm">Confirm your password</label>
                      <div class="invalid-feedback ml-5" *ngIf="_resetPasswordForm.get('confirmPassword').hasError('required')
                          && _resetPasswordForm.get('confirmPassword').touched">Please enter your Password.
                      </div>
                      <div class="invalid-feedback ml-5" *ngIf="_resetPasswordForm.hasError('mismatch')
                          && _resetPasswordForm.get('confirmPassword').touched">Passwords must match.
                      </div>
                    </div>

                    <div class="text-center">
                      <button *ngIf="!_resetting" mdbBtn size="lg"
                        class="waves-effect waves-light btn-primary rounded-lg login-btn loginButton"
                        [disabled]="!_resetPasswordForm.valid" mdbWavesEffect>Submit</button>
                      <button *ngIf="_resetting" mdbBtn size="lg" class="waves-effect waves-light btn-primary rounded-lg"
                        type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Submitting...
                      </button> <br>
                      <a class="login-text" [routerLink]="['/login']">Login Here</a>
                    </div>
                  </form>
                </div>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
        <!--/Form with header-->
      </div>
    </div>
  </div>
</section>