
    <!--Card-->
    <mdb-card class="z-depth-1-half" cascade="true" narrower="true">
        <!--Card image-->
        <div class="view view-cascade overlay hm-white-slight waves-light rounded-circle md-image" [ngStyle]="{'background': 'url('+ client.ClientImageUrl + ') no-repeat center center'}" mdbWavesEffect>
            <a href="{{client.WebsiteUrl}}" target="_blank">
                <div class="mask"></div>
            </a>
        </div>
        <!--/.Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="text-center">
            <!--Title-->
            <h4 class="card-title mb-3">{{client.KnownAs}}</h4>
            <!--Website Url-->
            <h6 >Account Manager:</h6>
            <p class="card-text">{{accountManager}}</p>
                <a type="button" mdbBtn floating="true" size="sm" color="primary" class="waves-light" mdbWavesEffect
                  title="Edit Client"
                  [routerLink]="['/client-management/edit',client.ClientID]">
                  <mdb-icon fas icon="pencil-alt"></mdb-icon>
                </a>
                <a type="button" mdbBtn floating="true" size="sm" color="primary" class="waves-light" mdbWavesEffect
                  title="Edit Client Settings"
                  [routerLink]="['/client-management/edit',client.ClientID,'client-settings']">
                  <mdb-icon fas icon="cogs"></mdb-icon>
                </a>
        </mdb-card-body>
        <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->

