import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { FolderDtoRequest } from 'src/app/_models/messaging/messages/folder-dto-request';
import { DeleteFolderDtoRequest } from 'src/app/_models/messaging/messages/delete-folder-dto-request';
import { SelectListOption } from '../../../_models/system/select-list-option';

@Injectable({
  providedIn: 'root'
})

export class ContactPlanFolderService {
  private baseUrl = environment.apiURL;
  private controllerName = 'ContactPlanFolder';
  public contactPlanFoldersList: SelectListOption[];

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  delete(id: number): Observable<ExecutionResultDto> {
    return this.http
      .delete(`${ this.baseUrl }${ this.controllerName }/Delete/${ id }`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  createUpdate(folderDtoRequest: FolderDtoRequest): Observable<ExecutionResultDto> {
    return this.http
      .post(`${ this.baseUrl }${ this.controllerName }`, folderDtoRequest)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  updateDelete(deleteMessageFolderDtoRequest: DeleteFolderDtoRequest): Observable<ExecutionResultDto> {
    return this.http
      .post(`${ this.baseUrl }${ this.controllerName }/UpdateDelete`, deleteMessageFolderDtoRequest)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAll(): Observable<ExecutionResultDto> {
    return this.http
      .get(`${ this.baseUrl }${ this.controllerName }`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

