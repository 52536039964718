import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { MessageService } from 'src/app/_services/messaging/messages/message.service';
import { MessageFolderService } from 'src/app/_services/messaging/messages/message-folder.service';
import { MessageGridRecordDtoAdapter } from 'src/app/_models/messaging/messages/message-grid-record-dto.adapter';
import { MessageGridRecordDto } from 'src/app/_models/messaging/messages/message-grid-record-dto';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MailEdit } from '../../shared/constants/app-constants';
import { PermissionsPageTypes } from '../../../_models/system/permissions/permissions-page-types-model';
import {
  SelectEmailBuilderModalComponent
} from '../../shared/modals/select-email-builder/select-email-builder-modal.component';
import { SearchReplaceModalComponent } from '../../shared/modals/search-replace/search-replace-modal.component';
import { MessagingAbstract } from '../../../_models/messaging/messaging-abstract';
import { CopyMoveMessageModalComponent } from '../../shared/modals/copy-move-message/copy-move-message-modal.component';
import { TableActionType } from '../../shared/constants/table-constants';
import { DataTypesEnum } from 'src/app/_models/selected-fields-filters/data-types-enum';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { da } from 'date-fns/locale';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  providers: [
    { provide: 'screenName', useValue: 'Messages' }
  ]
})

export class MessagesComponent extends MessagingAbstract implements OnInit {
  displayedColumns = [
    { name: 'checkBox', displayName: '' },
    { name: 'messageId', displayName: 'MessageID' },
    { name: 'messageType', displayName: 'Type' },
    { name: 'messageName', displayName: 'Name' },
    { name: 'subject', displayName: 'Subject' },
    { name: 'folderName', displayName: 'Folder' },
    { name: 'createDate', displayName: 'Creation Date' },
    { name: 'createdBy', displayName: 'Created By' },
    { name: 'changeDate', displayName: 'Last Modified Date' },
    { name: 'changedBy', displayName: 'Last Modified By' },
    { name: 'actions', displayName: '' },
  ];

  displayedColumnsForExport = [
    { name: 'folderName', displayName: 'Folder' },
    { name: 'messageName', displayName: 'Message Name' },
    { name: 'subject', displayName: 'Subject' },    
    { name: 'previewUrl', displayName: 'Preview Url' }
  ];

  actionList = ['move', 'copy', 'searchReplace', 'preview', 'previewLinks', 'send'];
  userPermissionsEmail: PermissionsPageTypes;
  mosaico = MailEdit.mosaico;
  unlayer = MailEdit.unlayer;
  filterMessagesRules = '';
  dataType = DataTypesEnum.Messages;
  tableData: any;
  marketingDeliveryDomains = ['marketing.delivery', 'marketingdelivery.com'];

  @ViewChild('confirmExportAllMessages', { static: true }) confirmExportAllMessages: ModalDirective;

  constructor(
    public messageFolderService: MessageFolderService,
    private messageGridRecordDtoAdapter: MessageGridRecordDtoAdapter,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'messages';
    this.init();
  }

  ngOnInit(): void {
    this.userPermissionsEmail = this.permissions.MessagesEmail;
    if (this.paginationDataService.getFilterRules())
      this.filterMessagesRules = this.paginationDataService.getFilterRules();

    this.setActionList();
  }

  setActionList() {
    var domain = this.localStorageService.getEmail.split('@').pop();
    if (domain){
      if (!this.marketingDeliveryDomains.includes(domain)) {
        this.actionList = this.actionList.filter((item) => item !== 'previewLinks');
      }
    }
  }

  getMessages(data): void {
    this.tableData = data;
    this.loadingSpinnerService.loading();

    const pagerDtoRequest = new PagerDtoRequest({
      page: data.currentPage,
      pageSize: data.pageLimit,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection
    });

    this.filterMessagesRules = data.filters;
    this.paginationDataService.setFilterRules(this.filterMessagesRules);

    this.messageService.getMessages(pagerDtoRequest, this.selectedFolderId, data.isLive, data.filters)
      .subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.data = executionResultDto.data.Messages.map((message) => this.messageGridRecordDtoAdapter.adapt(message));
          this.length = executionResultDto.data.Total;
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
        this.loadingSpinnerService.stopLoading();
      }, () => {
        this.loadingSpinnerService.stopLoading();
      });
  }

  searchReplaceMessage(selectedMessages: MessageGridRecordDto[]): void {
    this.openModal(SearchReplaceModalComponent, { data: { selectedMessages } });
  }

  edit(row: MessageGridRecordDto): void {
    const path = row.messageTypeEnum === MessageTypeEnum.email
      ? `messages/email-builder-` + (row.isUnlayer ? MailEdit.unlayer : MailEdit.mosaico)
      : 'messages/sms-message';

    this.editRow(row, path);
  }

  previewEmail(row: MessageGridRecordDto): void {
    window.open(window.location.protocol + '//' + window.location.host + '/' + 'messages/email-preview/' + row.messageId, '_blank');
  }

  previewLinksExport(selectedMessages: MessageGridRecordDto[]) {
    if (selectedMessages.length) {
      selectedMessages = selectedMessages.filter((message) => message.messageTypeEnum == MessageTypeEnum.email);
      this.messageService.export(selectedMessages, this.displayedColumnsForExport);
    } else {
      this.confirmExportAllMessages.show();      
    } 
  }

  exportAllMessages() {
    this.confirmExportAllMessages.hide();
    this.loadingSpinnerService.loading();

    const pagerDtoRequest = new PagerDtoRequest({
      page: 1,
      pageSize: 10000000,
      sortColumn: this.tableData.sortColumn,
      sortDirection: this.tableData.sortDirection
    });

    this.messageService.getMessages(pagerDtoRequest, this.selectedFolderId, this.tableData.isLive, this.tableData.filters)
      .subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          var allMessages = executionResultDto.data.Messages.map((message) => this.messageGridRecordDtoAdapter.adapt(message)).filter((message) => message.messageTypeEnum == MessageTypeEnum.email);
          this.messageService.export(allMessages, this.displayedColumnsForExport);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
        this.loadingSpinnerService.stopLoading();
      }, () => {
        this.loadingSpinnerService.stopLoading();
      });
  }

  send(row: MessageGridRecordDto): void {
    this.router.navigate(
      ['messages/message-send/' + row.messageId + '/' + row.messageTypeEnum]
    );
  }

  sendBulk(rows: MessageGridRecordDto[]): void {
    this.router.navigate(
      ['messages/bulk-message-send'], { queryParams: { messageIds:  rows.map(r=> r.messageId)} }
    );
  }

  selectEmailBuilder(): void {
    this.router.navigate(
      ['/messages/email-builder-unlayer']
    );
  }

  actionHandler({ selectedRows, action, extraOptions }): void {
    switch (TableActionType[action]) {
      case TableActionType.copy:
      case TableActionType.move:
        this.copyMove({
          modal: CopyMoveMessageModalComponent,
          isCopy: TableActionType.move !== TableActionType[action],
          tableKey: this.tableKey,
          dataService: MessageService,
          selectedRows,
        });
        break;
      case TableActionType.delete:
        this.deleteRestoreRow(selectedRows, extraOptions);
        break;
      case TableActionType.send:{
        if(selectedRows.length == 1){
          this.send(selectedRows[0]);
        }
        else{
          this.sendBulk(selectedRows);
        }
        break;
      }        
      case TableActionType.preview:
        this.previewEmail(selectedRows[0]);
        break;
      case TableActionType.searchReplace:
        this.searchReplaceMessage(selectedRows);
        break;
      case TableActionType.edit:
        this.edit(selectedRows);
        break;
      case TableActionType.previewLinks:
        this.previewLinksExport(selectedRows);
        break;
    }
  }
}
