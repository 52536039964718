import { Injectable } from '@angular/core';
import { ExtractModel } from './extract-model';


@Injectable({
  providedIn: 'root'
})

export class ExtractModelAdapter {

  adapt(item: any): ExtractModel {
    return new ExtractModel(item);
  }

  createEmpty() {
    return new ExtractModel({
      FieldIds: [],
      AwsSecretName: ''
    });
  }
}
