import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MessageCodeFolderService } from 'src/app/_services/messaging/automation/message-codes/message-code-folder.service';
import { MessageCodeService } from 'src/app/_services/messaging/automation/message-codes/message-code.service';
import { MessageCodeGridRecordDtoAdapter } from 'src/app/_models/messaging/automation/message-codes/message-code-grid-record-dto-adapter';
import { PermissionsPageTypes } from '../../../../_models/system/permissions/permissions-page-types-model';
import {
  CreateAutomationModalComponent
} from '../../../shared/modals/create-automation/create-automation-modal.component';
import { TableActionType, TableName } from '../../../shared/constants/table-constants';
import { TrialRunModalComponent } from '../../../shared/modals/trial-run/trial-run-modal.component';
import { MessagingAbstract } from '../../../../_models/messaging/messaging-abstract';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { ClientsDto } from 'src/app/_models/admin/clients/clients-model';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { LightListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model.adapter';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { CopyContactCriteriaGroupDto } from 'src/app/_models/messaging/automation/message-codes/copy-contact-criteria-group-dto';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';

@Component({
  selector: 'app-message-codes',
  templateUrl: './message-codes.component.html',
  styleUrls: ['./message-codes.component.scss'],
})

export class MessageCodesComponent extends MessagingAbstract implements OnInit {
  displayedColumns = [
    { name: 'title', displayName: 'Title' },
    { name: 'folderName', displayName: 'Folder' },
    { name: 'createDate', displayName: 'Creation Date' },
    { name: 'createdBy', displayName: 'Created By' },
    { name: 'changeDate', displayName: 'Last Modified Date' },
    { name: 'changedBy', displayName: 'Last Modified By' },
    { name: 'actions', displayName: '' }
  ];

  editPath = 'contact-criteria/edit/';
  automationMessageCodesPermissions: PermissionsPageTypes;
  trialRunOpen = false;

  copyContactCriteriaGroupFormGroup: UntypedFormGroup;
  clientSelectOptions: SelectListOption[] = [];
  folderSelectOptions: SelectListOption[] = [];
  listSelectOptions: SelectListOption[] = [];

  selectedMessageCodeId: number = 0;
  selectedClientId: number = 0;
  selectedMessageCodeFolderId: number = 0;
  selectedListId: number = 0;

  @ViewChild('contactCriteriaGroup', { static: true }) contactCriteriaGroup: ModalDirective;

  constructor(
    public messageCodeFolderService: MessageCodeFolderService,
    private messageCodeGridRecordDtoAdapter: MessageCodeGridRecordDtoAdapter,
    private messageCodeService: MessageCodeService,
    private clientManagementService: ClientManagementService,
    private listsService: ListsService,
    private lightListModelAdapter: LightListModelAdapter,
    private localStorageService: LocalStorageService,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'contactCriteria';
    this.init();
  }

  ngOnInit() {
    this.automationMessageCodesPermissions = this.permissions.AutomationMessageCodes;

    this.getClients();

    this.copyContactCriteriaGroupFormGroup = new UntypedFormGroup({
      client: new UntypedFormControl('', Validators.required),
      messageFolder: new UntypedFormControl('', Validators.required),
      list: new UntypedFormControl('', Validators.required)
    });

    this.copyContactCriteriaGroupFormGroup.get('client').valueChanges.subscribe((clientId) => {
      if (clientId) {
        this.selectedClientId = clientId;
        this.populateFoldersDropdown(clientId);  
      }    
    });

    this.copyContactCriteriaGroupFormGroup.get('messageFolder').valueChanges.subscribe((messageFolderId) => {
      if (messageFolderId) {
        this.selectedMessageCodeFolderId = messageFolderId;
        this.populateListsDropdown(0, this.selectedClientId);
      }
    })

    this.copyContactCriteriaGroupFormGroup.get('list').valueChanges.subscribe((listId) => {
      if (listId) {
        this.selectedListId = listId;
      }
    })
  }

  populateFoldersDropdown(clientId?: number): void {
    this.messageCodeFolderService.getAllByClientId(clientId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const listFolderDtos = executionResultDto.data
          .map((messageFolderDto: FolderDto) => this.folderDtoAdapter.adapt(messageFolderDto));

        this.folderSelectOptions = [];
        this.folderSelectOptions = this.folderSelectOptions
          .concat(
            listFolderDtos
              .map((messageFolderDto: FolderDto) => new SelectListOption(messageFolderDto.id, messageFolderDto.name))
          );
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    })
  }

  populateListsDropdown(selectedListFolderId: number, selectedClientId: number) {
    this.listsService.getAllLightLists(selectedListFolderId, selectedClientId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const lightLists = this.lightListModelAdapter.adaptArray(executionResultDto.data);          
        
        this.listSelectOptions = [];
        this.listSelectOptions = this.listSelectOptions
          .concat(
            lightLists.map((lightListModel: LightListModel) => new SelectListOption(lightListModel.ListId, lightListModel.ListName))
          );
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });    
  }

  getClients(): void {
    this.clientManagementService.getUserClients().subscribe(result => {
      const clients = result.data;
      this.clientSelectOptions = [];
      clients.forEach((client: ClientsDto) => {
        if (client.Active) {
          this.clientSelectOptions = [...this.clientSelectOptions, {
            value: client.ClientID,
            label: client.KnownAs,
            icon: client.ClientImageUrl
          }];
        }
      });
    });
  }

  getMessageCodes(data: any): void {
    if (this.trialRunOpen) {
      return;
    }

    this.loadingSpinnerService.loading();
    const pagerDtoRequest = new PagerDtoRequest({
      page: data.currentPage,
      pageSize: data.pageLimit,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection
    });

    this.messageCodeService.getAll(pagerDtoRequest, this.selectedFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.data = executionResultDto.data.MessageCodes.map((messageCode) => this.messageCodeGridRecordDtoAdapter.adapt(messageCode));
        this.length = executionResultDto.data.Total;

        if (!this.length) {
          this.loadingSpinnerService.stopLoading();
          return;
        }
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();
    }, error => {
      this.loadingSpinnerService.stopLoading();
    });
  }

  showCreateMessageCodeModal() {
    const folders = this.messagingHeaderService.folders.filter(x => x.value > 0);
    const dialog = this.matDialog.open(CreateAutomationModalComponent, {
      width: '500px',
      data: {
        data: this.data,
        title: TableName[this.tableKey],
        tableKey: this.tableKey,
        folders,
        selectedFolder: this.selectedFolderId
      }
    });

    dialog.afterClosed().subscribe(({ isNeedToUpdate, folderId }) => {
      if (isNeedToUpdate) {
        this.messagingHeaderService.setFolder(folderId);
        this.tableService.requestTableData();
      }
    });
  }

  openTrialRunModal(row): void {
    this.trialRunOpen = true;
    const dialog = this.matDialog.open(TrialRunModalComponent, {
      maxWidth: '100vw',
      maxHeight: '45vw',
      width: '100%',
      data: { row }
    });

    dialog.afterClosed().subscribe(() => {
      this.trialRunOpen = false;
    });
  }

  actionHandler({ selectedRows, action, extraOptions }): void {
    switch (TableActionType[action]) {
      case TableActionType.trial:
        this.openTrialRunModal(selectedRows);
        break;
      case TableActionType.edit:
        this.editRow(selectedRows, this.editPath);
        break;
      case TableActionType.delete:
        this.deleteRestoreRow(selectedRows, extraOptions);
        break;
        case TableActionType.copyCriteriaGroup:
          this.selectedMessageCodeId = selectedRows.messageCodeId;         
          this.clearSelectedValues(); 
          this.contactCriteriaGroup.show();          
          break;
    }
  }

  clearSelectedValues() {
    this.folderSelectOptions = [];
    this.listSelectOptions = [];

    this.copyContactCriteriaGroupFormGroup.get('client').setValue('');
    this.copyContactCriteriaGroupFormGroup.get('messageFolder').setValue('');
    this.copyContactCriteriaGroupFormGroup.get('list').setValue('');
  }

  copyContactCriteriaGroup() {
    if (this.selectedClientId <= 0) {
      this.notificationService.showError('Please select client.');
      return;
    } else if (this.selectedMessageCodeFolderId <= 0) {
      this.notificationService.showError('Please select destination folder.');
      return;
    } else if (this.selectedListId <= 0) {
      this.notificationService.showError('Please select destination list.');
      return;
    }

    const copyMessageCodeStepRequestDto = new CopyContactCriteriaGroupDto({
      selectedMessageCodeId: this.selectedMessageCodeId,
      clientId: this.selectedClientId,
      messageCodeFolderId: this.selectedMessageCodeFolderId,
      listId: this.selectedListId,
      loggedInUserId: Number(this.localStorageService.getUserId)
    });

    this.contactCriteriaGroup.hide();
    this.loadingSpinnerService.loading();

    this.messageCodeService.copy(copyMessageCodeStepRequestDto).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      
      this.loadingSpinnerService.stopLoading();
    })
  }
}
