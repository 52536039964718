import { OverviewMetricsReportDto } from "./overview-metrics-report-dto";

export class FacebookMetricsReportDto extends OverviewMetricsReportDto {

  public Posts: Array<any>[];
  public TotalPostClicks: number;
  public TotalReactions: number;
  public TotalReach: number;
  public TotalComments: number;

  constructor(init: {
    Posts: Array<any>[],
    TotalPostClicks: number,
    TotalReactions: number,
    TotalReach: number,
    TotalComments: number,

    TotalPosts: number;
    TotalFollowers: number;
    EngagementRate: number;
    TotalImpressions: number;
  }) {
    super(init);
    this.Posts = init.Posts;
    this.TotalPostClicks = init.TotalPostClicks;
    this.TotalReactions = init.TotalReactions;
    this.TotalReach = init.TotalReach;
    this.TotalComments = init.TotalComments;
  }
}  
