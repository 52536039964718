import { ApiIntegrationEndpointFieldsMappingModel } from "../../api-integration/api-integration-endpoint-fields-mapping-model";
import { SendViaApiFieldMapping } from "./send-via-api-field-mapping";
import { SendViaApiParameter } from "./send-via-api-parameter";

export class SendViaApiInfo {
  public ApiName: string;
  public QueueName: string;
  public LocationName: string;
  public SchemaId: number;
  public EndpointName: string;
  public SendViaApiFieldMappings:  SendViaApiFieldMapping[];
  public SendViaApiParameters:  SendViaApiParameter[];

  constructor(init: any) {
    this.ApiName = init.ApiName;
    this.QueueName = init.QueueName;
    this.LocationName = init.LocationName;
    this.SchemaId = init.SchemaId;
    this.EndpointName = init.EndpointName;    
    this.SendViaApiParameters = init.SendViaApiParameters;
    this.SendViaApiFieldMappings = init.SendViaApiFieldMappings;
  }
}