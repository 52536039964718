import { MessageTypeEnum } from '../../../messages/message-type-enum';

export class SendMessageActionInfo {
  public MessageId: number;
  public MessageType: MessageTypeEnum;

  constructor(init: any) {
    this.MessageId = init.MessageId;
    this.MessageType = init.MessageType;
  }
}
