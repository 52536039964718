import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MessageGridRecordDto } from '../../../../_models/messaging/messages/message-grid-record-dto';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { NotificationService } from '../../../../_services/notification.service';
import {
  MessageCopyMoveDetailsDtoAdapter
} from '../../../../_models/messaging/messages/message-copy-move-details-dto-adapter';
import {
  MessageCopyMoveFindReplaceDtoAdapter
} from '../../../../_models/messaging/messages/message-copy-move-find-replace-dto-adapter';
import {
  MessageSearchReplaceDtoAdapter
} from '../../../../_models/messaging/messages/message-search-replace.adapter.dto';
import { MessageService } from '../../../../_services/messaging/messages/message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-search-replace',
  templateUrl: './search-replace-modal.component.html',
  styleUrls: ['./search-replace-modal.component.scss']
})
export class SearchReplaceModalComponent implements OnInit {
  title = 'Search and Replace in Message';
  findReplaceFormArray: UntypedFormArray;
  selectedMessages: MessageGridRecordDto[];
  searchReplaceInProgress: boolean;

  constructor(
    private notificationService: NotificationService,
    private messageCopyMoveDetailsDtoAdapter: MessageCopyMoveDetailsDtoAdapter,
    private messageCopyMoveFindReplaceDtoAdapter: MessageCopyMoveFindReplaceDtoAdapter,
    private messageSearchReplaceDtoAdapter: MessageSearchReplaceDtoAdapter,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<SearchReplaceModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(): void {
    this.selectedMessages = this.data.selectedMessages;
  }

  setArrayValue(data: UntypedFormArray): void {
    this.findReplaceFormArray = data;
  }

  searchReplace(): void  {
    const messageFindReplace = [];
    let validateLength = true;
    (this.findReplaceFormArray as UntypedFormArray).controls.forEach((message: UntypedFormGroup) => {
      if (message.get('find').value.length < 2) {
        this.notificationService.showError('Please enter more than one letter to search for.');
        validateLength = false;
      }

      messageFindReplace.push({
        Find: message.get('find').value,
        Replace: message.get('replace').value
      });
    });

    if (!validateLength) { return; }

    const messageDetails = [];
    this.selectedMessages.forEach((message: MessageGridRecordDto) => {
      messageDetails.push({
        MessageId: message.messageId,
        MessageName: message.messageName,
        MessageType: message.messageType
      });
    });

    const messagesToReplace = this.messageSearchReplaceDtoAdapter.adapt({
      Messages: this.messageCopyMoveDetailsDtoAdapter.adaptArray(messageDetails),
      FindReplace: this.messageCopyMoveFindReplaceDtoAdapter.adaptArray(messageFindReplace)
    });

    this.searchReplaceInProgress = true;
    this.messageService.searchReplaceMessage(messagesToReplace).subscribe((executionResultDto: ExecutionResultDto) => {
      this.notificationService.showSuccess(executionResultDto.message);
      this.searchReplaceInProgress = false;
      this.dialogRef.close(true);
    }, async error => {
      console.log(error);
      this.searchReplaceInProgress = false;
      this.dialogRef.close();
    });
  }
}
