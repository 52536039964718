import { PermissionsPageTypes, PermissionsPageTypesAdapter } from './permissions-page-types-model';
import { Section, Page } from '../../admin/users/permissions-all-available-model';
import { Injectable } from '@angular/core';
import { PermissionsMediaPagesEnum } from './permissions-media-pages.enum';

export class PermissionsMediaPages {
  MediaServer : PermissionsPageTypes;

  private _permissionsPageTypesAdapter : PermissionsPageTypesAdapter = new PermissionsPageTypesAdapter();

  constructor(section: Section) {
    section.pages.forEach((Page: Page) => {

      switch (Page.id) {
        case PermissionsMediaPagesEnum.MediaServer:
          this.MediaServer = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        default:
          console.warn("Unknown permission with Page name: " + Page.name);
          break;
      }
    });
  }
}

@Injectable({
  providedIn: 'root'
})

export class PermissionsMediaPagesAdapter {
  adapt(item: Section): PermissionsMediaPages {
    return new PermissionsMediaPages(item);
  }
}
