import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  overlayActive: boolean = false;

  constructor() { }

  showOverlay() {
    this.overlayActive = true;
  }

  hideOverlay() {
    this.overlayActive = false;
  }

}


