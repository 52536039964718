<div class="row m-2">
    <div class="col-md-12">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card Header-->
            <div class="view view-cascade gradient-card-header primary-color narrower
                py-2 mx-4 mb-3 d-flex row justify-content-end list-table-header">
                <div class="col-md-4 back-button">
                    <button
                        type="button" (click)="goBack()" class="btn btn-secondary rounded">
                        <span class="text-truncate">
                            <mdb-icon fas icon="arrow-left"></mdb-icon>
                            Back
                        </span>
                    </button>
                </div>
                <div class="col-md-4">
                    <h4 class="page-title">{{ _pageTitle }}</h4>
                </div>
                <div class="col-md-4 text-right">
                    <button [disabled]="!_tableHasRows" mdbBtn color="white" outline="true" rounded="true" size="sm"
                        type="button" class="px-2 header-button mr-2" mdbWavesEffect (click)="columnOptions.toggle()">
                        <mdb-icon fas size="lg" class="" icon="columns"></mdb-icon>
                    </button>
                    <button *ngIf="!_exporting" [disabled]="!_tableHasRows" mdbBtn color="white" outline="true"
                        rounded="true" size="sm" type="button" class="px-2 header-button mr-0" mdbWavesEffect
                        (click)="export()">
                        <mdb-icon fas size="lg" class="" icon="file-export"></mdb-icon>
                    </button>
                    <button *ngIf="_exporting" disabled mdbBtn color="white" outline="true" rounded="true" size="sm"
                        type="button" class="px-2 header-button mr-0" mdbWavesEffect>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <!--/Card Header-->

            <!--Card Content-->
            <mdb-card-body cascade="true" class="text-center pb-4">

                <section>
                    <div class="container-fluid">

                        <div class="row text-left" mdbCollapse #columnOptions="bs-collapse">
                            <div class="col-3 font-size-12" *ngFor="let col of _allColumns; index as i">
                                <input disabled class="form-check-input" mdb-checkbox type="checkbox"
                                    [checked]="isChecked(col)">
                                <input class="form-check-input" mdb-checkbox type="checkbox" id="checkbox-{{i}}"
                                    (change)="toggle(col)" [checked]="isChecked(col)" [value]="col.prop">
                                <label class="form-check-label" for="checkbox-{{i}}">{{col.name}}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <ngx-datatable class="material striped" [columns]="_allColumns"
                                    [columnMode]="_columnMode.force" [headerHeight]="50" [footerHeight]="50"
                                    [rowHeight]="100" [scrollbarV]="false" [scrollbarH]="true" [rows]="_rows"
                                    [externalPaging]="true" [count]="_pageCount" [offset]="_pageOffset" [limit]="_pageLimit"
                                    (page)="getFilteredReport($event)" (sort)="onSort($event)">

                                    <ngx-datatable-column *ngFor="let col of _columns" [width]="150"
                                        [headerClass]="'lists-table-headers'" [name]="col.name" [prop]="col.prop">
                                    </ngx-datatable-column>

                                    <!-- Custom table footer -->
                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="_pageCount"
                                            let-pageSize="_pageLimit" let-curPage="_currentPage"
                                            let-offset="_pageOffset">
                                            <div class="table-custom-footer">
                                                <div>
                                                    Rows: {{ _pageCount }}
                                                </div>
                                                <select (change)="onLimitChange($event.target.value)"
                                                    class="table-page-limit-select">
                                                    <option *ngFor="let option of pageLimitOptions"
                                                        [ngValue]="option.value"
                                                        [selected]="option.value == _pageLimit">
                                                        {{option.value}} per page
                                                    </option>
                                                </select>
                                                <datatable-pager class="table-custom-pager"
                                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                                    [pagerNextIcon]="'datatable-icon-skip'" [page]="_currentPage"
                                                    [size]="_pageLimit" [count]="_pageCount"
                                                    [hidden]="!((_pageCount / _pageLimit) > 1)"
                                                    (change)="getFilteredReport($event.page)">
                                                </datatable-pager>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-footer>
                                </ngx-datatable>

                            </div>
                        </div>

                    </div>
                </section>

            </mdb-card-body>
            <!--/Card Content-->
        </mdb-card>
        <!--/Card-->
    </div>
</div>
