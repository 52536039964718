export class QueryBuilderFilterDtoRequest {
    filterId: number;
    pageTitle: string;
    filterName: string;
    sqlQuery: string;
    isDeleted?: boolean;

    constructor({ filterId, pageTitle, filterName, sqlQuery, isDeleted }:
                { filterId?: number; pageTitle?: string; filterName?: string; sqlQuery?: string; isDeleted?: boolean; } = {}) {                    
        this.filterId = filterId;
        this.pageTitle = pageTitle;
        this.filterName = filterName;
        this.sqlQuery = sqlQuery;
        this.isDeleted = isDeleted;
    }
}
