<app-modal-container [title]="title"
                     [actionsOn]="false">
  <app-query-builder #queryBuilder
                     [selectedFilter]="selectedFilter"
                     [_parentListID]="selectedId"
                     [_parentQuery]="filterMessageRules"
                     [_parentSource]="tableKey"
                     [_parentPageTitle]="tableName"
                     [appliedQueryFilter]="queryBuilderFilterService.appliedFilter"
                     (queryBuilderSave)="messagesFilterSave($event)"
                     (selectValue)="setFilterValue($event)">
  </app-query-builder>
</app-modal-container>
