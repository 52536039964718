import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserPermissionTypeDto, UserPermissionTypeAdapter } from '../../../_models/admin/users/user-permission-type-model';
import { environment } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserPermissionTypeService {
    _baseUrl = environment.apiURL;

    constructor(private http: HttpClient,
      private userPermissionTypeAdapter: UserPermissionTypeAdapter) {
     }


    get() : Observable<UserPermissionTypeDto[]> {
      return this.http
      .get(this._baseUrl + 'UserPermissionType')
      .pipe(map((data: any) => data.map((item: any) => this.userPermissionTypeAdapter.adapt(item))));
    }



}
