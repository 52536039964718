import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';

export class DetailedEmailReportRequest {
    pager: PagerDtoRequest;
    reportId: number;
    filter: string;

    constructor({ pagerDtoRequest, reportId, filter }:
        { pagerDtoRequest: PagerDtoRequest; reportId: number; filter: string;}) {                    
        this.reportId = reportId;
        this.filter = filter;
        this.pager = pagerDtoRequest;
    }
}
