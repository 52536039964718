import { Injectable } from '@angular/core';

export class Page {
  id: number
  name: string
  permissionKey: number

  constructor(id: number, name: string, permissionKey: number) {
    this.id = id
    this.name = name
    this.permissionKey = permissionKey
  }
}

export class Section {
  id: number
  name: string
  pages: Page[] = []

  constructor(id: number, name: string) {
    this.id = id
    this.name = name
  }
}

export class PermissionsAllAvailableDto {

    public sections: Section[] = []

    constructor(sections: Section[]) {
      this.sections = sections
    }
}

@Injectable({
  providedIn: 'root'
})

export class PermissionsAllAvailableAdapter { 

  adapt(item: any): PermissionsAllAvailableDto {
        
    let sectionNames = Object.getOwnPropertyNames(item) as string[];     

    let sections: Section[] = [];

    for(let sectionName of sectionNames){
        let sectionId = item[sectionName][0];
        let pagesObj = item[sectionName][1];

        let section = new Section (sectionId, sectionName);

        let pageNames = Object.getOwnPropertyNames(pagesObj) as string[];

        for(let pageName of pageNames){
          let pageId = pagesObj[pageName][0];
          let permissionKey = pagesObj[pageName][1];
          let page = new Page(pageId, pageName, permissionKey)
          section.pages.push(page)
        }

      sections.push(section);

    };
    return new PermissionsAllAvailableDto(sections);
  }
}
