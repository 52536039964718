import { SchedulingTypeEnum } from '../trigger-enums/scheduling-type.enum';

export class TriggerSchedulingModel {
  public SchedulingType: SchedulingTypeEnum;
  public DelayInSeconds: number;
  public DateTime: Date;
  public MonthDelay: number;
  public DayOfMonth: number;
  public Frequency: string;
  public DaysOfWeek: number[] = []; 

  constructor(init: any) {
    this.SchedulingType = init.SchedulingType;
    this.DelayInSeconds = init.DelayInSeconds;
    this.DateTime = init.DateTime;
    this.MonthDelay = init.MonthDelay;
    this.DayOfMonth = init.DayOfMonth;
    this.Frequency = init.Frequency;
    this.DaysOfWeek = init.DaysOfWeek;
  }
}
