import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableColumn } from '../../../../../_models/messaging/datatable-column';
import { ContactPlanFolderService } from 'src/app/_services/messaging/automation/contact-plan-folder.service';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { ContactPlanService } from 'src/app/_services/messaging/automation/contact-plan.service';
import { ContactPlanDtoAdapter } from 'src/app/_models/messaging/automation/contact-plans/contact-plan-dto.adapter';
import { EditContactPlanRequestDto } from 'src/app/_models/messaging/automation/contact-plans/edit-contact-plan-request-dto';
import { ContactPlanDto } from 'src/app/_models/messaging/automation/contact-plans/contact-plan-dto';
import { StepGridRecordDtoAdapter } from 'src/app/_models/messaging/automation/steps/step-grid-record.dto-adapter.';
import { StepGridRecordDto } from 'src/app/_models/messaging/automation/steps/step-grid-record-dto';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { EditStepRequestDto } from 'src/app/_models/messaging/automation/steps/edit-step-request-dto';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { animate, style, transition, trigger } from '@angular/animations';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { DataTableColumnWidthManager } from 'src/app/_models/system/data-table-column-width-manager/data-table-column-width-manager';
import { LoadingSpinnerService } from '../../../../../_services/loading-spinner/loading-spinner.service';
import { QueryBuilderFilterService } from '../../../../../_services/query-builder-filters/query-builder-filter.service';

@Component({
  selector: 'app-edit-contact-plan',
  templateUrl: 'edit-contact-plan.component.html',
  styleUrls: ['edit-contact-plan.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
              style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class EditContactPlanComponent implements OnInit {

  @ViewChild('activateStepModal', { static: true }) activateStepModal: ModalDirective;
  @ViewChild('toggleAllStepsModal', { static: true }) toggleAllStepsModal: ModalDirective;
  @ViewChild('copyStepModal', { static: true }) copyStepModal: ModalDirective;
  @ViewChild('deleteStepModal', { static: true }) deleteStepModal: ModalDirective;

  _configuratorFormGroup: UntypedFormGroup;
  _copyStepFormGroup: UntypedFormGroup;
  _columnMode = ColumnMode;
  _columns: DataTableColumn[] = [];
  _descriptions: string[] = [];
  _folderSelectListOptions: SelectListOption[] = [];
  _messageTypeEnumEmail = MessageTypeEnum.email;
  _messageTypeEnumSms = MessageTypeEnum.sms;
  _rows: StepGridRecordDto[] = [];
  _selectedStep: StepGridRecordDto;
  _selectedContactPlan: ContactPlanDto;
  _selectedContactPlanId = 0;
  _selectedDestinationFolderId = 0;
  _selectedFolderId = 0;
  _selectedStepIndex = 0;
  _stepVerb = 'activate';
  _allStepsVerb = 'activate';
  _stepsFormGroup: UntypedFormGroup;
  _subscriptionLists: Subscription;
  _tableHasRows = false;
  _userPermissions: PermissionsModel;

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions.ConstTableRowLimitOptions;

  // custom footer variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;

  // Formgroup for global toggle for ease of reference.
  globalStatusToggleFormGroup = new UntypedFormGroup({
    statusToggle: new UntypedFormControl(false),
  });

  // Countdown before allowing to Toggle All Steps.
  _timeLeft: number = 3.00
  _timer = null;
  _interval;
  _allStepsConfirmButton = true;
  _selectedStatusToggleAllSteps: boolean;
  _contactPlansValues: Array<any> = [];
  _folderSelectOptions: SelectListOption[] = [];
  _isHiddenSelectFolderAndPlan: boolean = true;

  constructor(
    private contactPlanFolderDtoAdapter: FolderDtoAdapter,
    private contactPlanFolderService: ContactPlanFolderService,
    private stepGridRecordDtoAdapter: StepGridRecordDtoAdapter,
    private contactPlanDtoAdapter: ContactPlanDtoAdapter,
    private contactPlanService: ContactPlanService,
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private router: Router,
    private paginationDataService: PaginationDataService,
    private dataTableColumnWidthManager: DataTableColumnWidthManager,
    private loadingSpinnerService: LoadingSpinnerService,
    private queryBuilderFilterService: QueryBuilderFilterService,
  ) {
    this._selectedContactPlanId = parseInt(this.route.snapshot.paramMap.get('contactPlanId') || "0");
  }

  ngOnInit() {

    // Get pagination data stored in a session
    this.paginationDataService.GetChildPaginationDataFromLocalStorage();
    this.paginationDataService.GetChildFolderIdFromLocalStorage();
    this.paginationDataService.GetChildListIdFromLocalStorage();

    if (this.paginationDataService.childPaginationData.childPage === 'Edit-Contact-Plan' && this.paginationDataService.childPaginationData.page) {
      this._currentPage = this.paginationDataService.childPaginationData.page;
      this._pageLimit = this.paginationDataService.childPaginationData.pageLimit;
    }

    if (this.paginationDataService.childPaginationData.childPage === 'Edit-Contact-Plan' && this.paginationDataService.childPaginationData.folderId) {
      this._selectedFolderId = this.paginationDataService.childPaginationData.folderId;
    }

    if (this.paginationDataService.childPaginationData.childPage != 'Edit-Contact-Plan') {
      this.paginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, '', '', 'Edit-Contact-Plan');
    }

    this._userPermissions = this.permissionsService.getPermissionsModel();

    this.dataTableColumnWidthManager.screenName = this.router.url;

    this.initForms();

    this.queryBuilderFilterService.selectedFilter = 0;

    this.subscribeSwitchToCopyContactPlan();
  }

  ngAfterViewInit() {
    this.getContactPlan();
  }


  activateStep() {

    const stepDtoRequest = new EditStepRequestDto({
      id: this._selectedStep.id,
      status: this._selectedStep.status
    });

    this.contactPlanService.activateStep(stepDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {

      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });

    this.activateStepModal.hide();
  }

  confirmActivateStep(id: number) {

    this._selectedStep = this.getStep(id);
    this._selectedStepIndex = this.getStepIndex(id);

    this._stepVerb = this._selectedStep.status ? 'dectivate' : 'activate';

    this.toggleContactPlanStatus();

    this.activateStepModal.show();
  }

  onChangeCopyAnotherPlan() {
    var value = this._copyStepFormGroup.get('slideCopyToAnotherPlan').value

    if (value) {
      this._isHiddenSelectFolderAndPlan = false;
    }
    else {
      this._isHiddenSelectFolderAndPlan = true;
    }
  }

  subscribeSwitchToCopyContactPlan() {
    this._copyStepFormGroup.get('folder').valueChanges.subscribe((value: number) => {
    
      if (value != undefined) {
        this._copyStepFormGroup.get('contactPlan').setValue(0);

        this.contactPlanService.getPlansByFolderId(value).subscribe((executionResultDto: ExecutionResultDto) => {
          if (executionResultDto.executionResult === ExecutionResult.success) {
            let contactPlans = [];
            executionResultDto.data.ContactPlans.forEach((network) => {
              if (this._selectedContactPlanId != network.WorkflowId) {
                contactPlans.push({
                  value: network.WorkflowId,
                  label: network.Title
                });
              }
            })
            this._contactPlansValues = contactPlans;
          } else {
            this.notificationService.showError(executionResultDto.message);
          }
        });
      }
    });
  }

  copyStepSubmit() {

    const title = this._copyStepFormGroup.get('title').value;
    let copyToAnotherContactPlan = this._copyStepFormGroup.get('slideCopyToAnotherPlan').value;
    let contactPlans = new Array();

    if (copyToAnotherContactPlan) {
      let values = this._copyStepFormGroup.get('contactPlan').value;
      values.forEach((value: number) => {
        contactPlans.push(+value)
      });
    }
    else {
      contactPlans.push(this._selectedStep.contactPlanId)
    }

    const stepDtoRequest = new EditStepRequestDto({
      id: this._selectedStep.id,
      contactPlanIds: contactPlans,
      currentContactPlanId: this._selectedStep.contactPlanId,
      title,
      status: this._selectedStep.status
    });

    this.contactPlanService.copyStep(stepDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.getSteps();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });

    this._copyStepFormGroup.get('folder').setValue(0);
    this._copyStepFormGroup.get('contactPlan').setValue(0);
    this._copyStepFormGroup.get('slideCopyToAnotherPlan').setValue(false);
    this._isHiddenSelectFolderAndPlan = true;

    this.copyStepModal.hide();

  }

  hideCopyModel() {
    this._copyStepFormGroup.get('folder').setValue(0);
    this._copyStepFormGroup.get('contactPlan').setValue(0);
    this._copyStepFormGroup.get('slideCopyToAnotherPlan').setValue(false);
    this._isHiddenSelectFolderAndPlan = true;

    this.copyStepModal.hide();
  }

  confirmDeleteStep(id: number) {

    this._selectedStep = this.getStep(id);

    this.deleteStepModal.show();
  }

  confirmCopyStep(id: number) {

    this._selectedStep = this.getStep(id);

    this._copyStepFormGroup.get('title').setValue(this._selectedStep.title);

    this.copyStepModal.show();
  }



  configuratorFormGroupSubmit() {

    const editContactPlanDto = new EditContactPlanRequestDto(this._configuratorFormGroup.value);

    editContactPlanDto.editStepDtos = this._rows.map((row, index) => (
      new EditStepRequestDto({
        id: row.id,
        currentContactPlanId: this._selectedContactPlan.id,
        title: this._stepsFormGroup.get(`title-${index}`).value,
        status: row.status
      }))
    );

    this.contactPlanService.post(editContactPlanDto).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  deleteStep() {

    this.contactPlanService.deleteStep(this._selectedStep.id).subscribe((executionResultDto: ExecutionResultDto) => {

      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this._rows = this._rows.filter(x => x.id !== this._selectedStep.id);
        this._selectedStep = null;
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });

    this.deleteStepModal.hide();
  }

  folderSelected($event) {
    this._selectedFolderId = $event.value;

    this.paginationDataService.SetChildFolderIdToLocalStorage(this._selectedFolderId);
  }

  getStep(id: number) {
    return this._rows.find(x => x.id === id);
  }

  getStepIndex(id: number) {
    return this._rows.findIndex(x => x.id === id);
  }

  getContactPlan() {
    this.contactPlanService.get(this._selectedContactPlanId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const contactPlanDto = this.contactPlanDtoAdapter.adapt(executionResultDto.data);
        this._selectedContactPlan = contactPlanDto;
        this._configuratorFormGroup.setValue(contactPlanDto);
        this.populateFoldersDropdown();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  getReportType(reportId: number): MessageTypeEnum {
    return this._rows.find(x => x.reportId === reportId).reportType;
  }

  getSteps(pageInfo?: any) {
    this.loadingSpinnerService.loading();
    var pageNumber = pageInfo ? pageInfo : 1;
    if (pageInfo) {
      this.paginationDataService.paginationData.page = pageInfo;
    }
    else if (this.paginationDataService.childPaginationData.page) {
      pageNumber = this.paginationDataService.childPaginationData.page;
    }

    this._currentPage = pageNumber;

    const pagerDtoRequest = new PagerDtoRequest({
      page: pageNumber,
      pageSize: this._pageLimit,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });

    this.contactPlanService.getSteps(pagerDtoRequest, this._selectedContactPlanId).subscribe(async (executionResultDto: ExecutionResultDto) => {

      if (executionResultDto.executionResult === ExecutionResult.success) {
        if (executionResultDto.data.Total / this._pageLimit < (pageNumber - 1)) {
          pageNumber = 1;
          pagerDtoRequest.page = 1;
          this._currentPage = 1;
          const execResultDto = await this.contactPlanService.getSteps(pagerDtoRequest, this._selectedContactPlanId).toPromise();
          executionResultDto = execResultDto;
        }

        this.allStatusToggle(executionResultDto.data.TotalActive, executionResultDto.data.Total);
        this._pageCount = executionResultDto.data.Total;
        this._pageOffset = pageNumber - 1;

        this._tableHasRows = this._pageCount > 0;

        this._columns = [];
        this._rows = [];

        if (!this._tableHasRows) {
          this.loadingSpinnerService.stopLoading();
          return;
        }

        this._descriptions = [];

        this._rows = executionResultDto.data.Steps.map((step, index) => {

          const stepGridRecordDto = this.stepGridRecordDtoAdapter.adapt(step);

          this._stepsFormGroup.addControl(`id-${index}`, new UntypedFormControl(stepGridRecordDto.id, Validators.required));
          this._stepsFormGroup.addControl(`folderId-${index}`, new UntypedFormControl(this._selectedFolderId, Validators.required));
          this._stepsFormGroup.addControl(`title-${index}`, new UntypedFormControl(stepGridRecordDto.title, Validators.required));

          this._descriptions.push(stepGridRecordDto.description);

          return stepGridRecordDto;
        });

        this._columns = [
          new DataTableColumn({ prop: 'status', name: 'Status', width: this.dataTableColumnWidthManager.getColumnWidth('status') || 150 }),
          new DataTableColumn({ prop: 'title', name: 'Title', width: this.dataTableColumnWidthManager.getColumnWidth('title') || 500 }),
          new DataTableColumn({ prop: 'createDate', name: 'Creation Date', width: this.dataTableColumnWidthManager.getColumnWidth('createDate') || 150 }),
          new DataTableColumn({ prop: 'createdBy', name: 'Created By', width: this.dataTableColumnWidthManager.getColumnWidth('createdBy') || 150 }),
          new DataTableColumn({ prop: 'changeDate', name: 'Last Modified Date', width: this.dataTableColumnWidthManager.getColumnWidth('changeDate') || 150 }),
          new DataTableColumn({ prop: 'changedBy', name: 'Last Modified By', width: this.dataTableColumnWidthManager.getColumnWidth('changedBy') || 150 }),
          new DataTableColumn({ prop: 'reportId', name: 'Report', width: this.dataTableColumnWidthManager.getColumnWidth('reportId') || 150 })
        ];
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();
      this.paginationDataService.SetChildPaginationDataToLocalStorage(this._currentPage, this._pageLimit, '', '', 'Edit-Contact-Plan');
    }, error => {
      this.loadingSpinnerService.stopLoading();
    });
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  initForms() {
    this._configuratorFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(0, Validators.required),
      title: new UntypedFormControl('', Validators.required),
      folderId: new UntypedFormControl(0, Validators.required)
    });

    this._copyStepFormGroup = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      folder: new UntypedFormControl(''),
      contactPlan: new UntypedFormControl(''),
      slideCopyToAnotherPlan: new UntypedFormControl(''),
    });

    this._stepsFormGroup = new UntypedFormGroup({});
  }

  populateFoldersDropdown() {
    this.contactPlanFolderService.getAll().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const folderDtos = executionResultDto.data.map((folderDto: FolderDto) => this.contactPlanFolderDtoAdapter.adapt(folderDto));

        this.populateFoldersCopyModalDropdown(folderDtos);
        this._folderSelectListOptions = [];

        this._folderSelectListOptions.push(new SelectListOption(0, '<All>'));

        this._folderSelectListOptions = this._folderSelectListOptions.concat(folderDtos.map((folderDto: FolderDto) =>
          new SelectListOption(folderDto.id, folderDto.name)));

        this._configuratorFormGroup.get('folderId').setValue(this._selectedContactPlan.folderId);

        this._selectedFolderId = this._selectedContactPlan.folderId;

        this.getSteps();

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  populateFoldersCopyModalDropdown(folderDtos)
  {
    this._folderSelectOptions = folderDtos.map(x => ({ label: x.name, value: x.id, icon: '' }));;
  }


  toggleContactPlanStatus() {
    this._rows[this._selectedStepIndex].status = this._selectedStep.status = !this._selectedStep.status;
    this._rows = this._rows.slice(0);
  }

  updateTitle(event, rowIndex) {
    this._rows[rowIndex].title = event.target.value;
    this._rows = [...this._rows];
  }

  undoActivateStep() {
    this.toggleContactPlanStatus();
    this.activateStepModal.hide();
  }

  navigateCreateTriggerStep() {
    if (this._userPermissions.Messaging.AutomationContactPlansContactSteps.Create) {
      this.router.navigate(['contact-plans/edit', this._selectedContactPlanId, 'create-step']);
    }
  }

  navigateCreateExtractStep() {
    if (this._userPermissions.Messaging.AutomationContactPlansContactSteps.Create) {
      this.router.navigate(['extracts/edit', this._selectedContactPlanId, 'create-step']);
    }
  }

  navigateCreateMultiListStep() {
    if (this._userPermissions.Messaging.AutomationContactPlansContactSteps.Create) {
      this.router.navigate(['multi/edit', this._selectedContactPlanId, 'create-step']);
    }
  }

  confirmEditStep(value) {
    if (this._userPermissions.Messaging.AutomationContactPlansContactSteps.Edit) {

      this._selectedStep = this.getStep(value);

      if (this._selectedStep.actionType && this._selectedStep.actionType.toLowerCase() === 'extract') {
        this.router.navigate(['extracts/edit', this._selectedContactPlanId, 'edit-step', this._selectedStep.id]);
      } else if (this._selectedStep.actionType && this._selectedStep.actionType.toLowerCase() === 'multilist') {
        this.router.navigate(['multi/edit', this._selectedContactPlanId, 'edit-step', this._selectedStep.id]);
      } else {
        this.router.navigate(['contact-plans/edit', this._selectedContactPlanId, 'edit-step', this._selectedStep.id]);
      }


    }
  }

  // Allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    this._pageLimit = parseInt(limit);
    this.getSteps();
  }

  // This checks the requested status, and shows the confirmation modal.
  confirmToggleAllSteps(event) {
     this._allStepsVerb = this._selectedStatusToggleAllSteps ? 'activate' : 'dectivate';

    this.toggleAllStepsModal.show();
    this.startTimer();

  }

  setToggleAllSelectedValue(status: boolean){
    this._selectedStatusToggleAllSteps = status;

  }

  // This is function that calls the API once we have confirmation from the user.
  toggleAllSteps() {
    this.contactPlanService.toggleAllSteps(this._selectedContactPlanId, this._selectedStatusToggleAllSteps).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.toggleAllStepsModal.hide();
        this.getSteps();
        this.resetTimings();
      }
    });
  }

  // Reverse the switch click, reset timer timings, hide the modal, etc...
  undoToggleAllSteps() {
    this.globalStatusToggleFormGroup.get('statusToggle').setValue(
      !this.globalStatusToggleFormGroup.get('statusToggle').value
    )

    this.toggleAllStepsModal.hide();
    this.resetTimings();
  }

  // Set the 'All Off / All On' status depending on how many steps have a status of 'On'
  allStatusToggle(totalActive: Number, total: Number) {
    if (totalActive == 0) { this.globalStatusToggleFormGroup.get('statusToggle').setValue(false); };
    if (totalActive > 0) { this.globalStatusToggleFormGroup.get('statusToggle').setValue(false); };
    if (totalActive === total) { this.globalStatusToggleFormGroup.get('statusToggle').setValue(true); };
  }

  // Reset variables which deal with timings.
  resetTimings() {
    this._timeLeft = 3;
    this._allStepsConfirmButton = true;
    this.pauseTimer();
  }

  // Starts the timer, a 3 second countdown before the 'Confirm' button can be clicked.
  startTimer() {
    this._interval = setInterval(() => {
      if (this._timeLeft > 0) {
        this._timeLeft = this._timeLeft - 0.1;
      } else {
        this._allStepsConfirmButton = false;
        this.pauseTimer();
      }
    }, 100)
  }

  // Stops the Interval.
  pauseTimer() {
    clearInterval(this._interval);
  }

  // allow the user to change the width of the column in the table
  onResize(event) {
    this.dataTableColumnWidthManager.setColumnWidth(event.column.prop, event.newValue);
  }
}
