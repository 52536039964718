import { Injectable } from '@angular/core';
import { LinkImageModel, LinkImageModelAdapter } from './link-image-model';

export class LinkModel {
  public LinkID: number;
  public LinkUniqueID: string;
  public URL: string;
  public FacebookURL: string;
  public Title: string;
  public Description: string;
  public RemoveUTM: boolean;
  public UtmContent: string;
  public UtmCampaign: string;
  public Published: boolean;
  public UploadedDate: Date;
  public UploadedByID: number;
  public AppID: string;
  public LinkImage: LinkImageModel;
  public LinkSaved: boolean;

  private linkImageModelAdapter: LinkImageModelAdapter = new LinkImageModelAdapter();

  constructor(init: any) {
    this.LinkID = init.LinkID;
    this.LinkUniqueID = init.LinkUniqueID;
    this.URL = init.URL;
    this.FacebookURL = init.FacebookURL;
    this.Title = init.Title;
    this.Description = init.Description;
    this.RemoveUTM = init.RemoveUTM;
    this.UtmContent = init.UtmContent;
    this.UtmCampaign = init.UtmCampaign;
    this.Published = init.Published;
    this.UploadedDate = init.UploadedDate;
    this.UploadedByID = init. UploadedByID;
    this.AppID = init.AppID;
    this.LinkImage = init.LinkImage != null ? this.linkImageModelAdapter.adapt(init.LinkImage) : null;
    this.LinkSaved = init.LinkSaved;
  }
}

@Injectable({
  providedIn: 'root'
})

export class LinkModelAdapter {
  adapt(item: any): LinkModel {
    return new LinkModel(item);
  }
  createEmpty() {
    return new LinkModel({
      LinkID : 0,
      LinkUniqueID : '00000000-0000-0000-0000-000000000000',
      URL : null,
      FacebookURL : null,
      Title : null,
      Description: null,
      RemoveUTM : false,
      UtmContent : null,
      UtmCampaign : null,
      Published : false,
      UploadedDate : new Date(),
      UploadedByID :  0,
      AppID : null,
      LinkImage : null,
      LinkSaved: false,
    });
  }
}
