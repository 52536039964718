import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { ModalDirective} from 'ng-uikit-pro-standard';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { Subscription } from 'rxjs';
import { UnsubscribeService } from 'src/app/_services/system/unsubscribe.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { SmsMessageService } from 'src/app/_services/messaging/messages/sms-messages/sms-message.service';
import { SmsMessageDtoAdapter } from 'src/app/_models/messaging/messages/sms-messages/sms-message-dto.adapter';
import { SmsMessageDto } from 'src/app/_models/messaging/messages/sms-messages/sms-message-dto';
import { ListFolderService } from 'src/app/_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { ListFolderDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/list-folders/list-folder-dto.adapter';
import { ListFolderDto } from 'src/app/_models/messaging/lists-and-contacts/list-folders/list-folder-dto';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { MessageSendDtoRequest } from 'src/app/_models/messaging/messages/message-send-dto-request';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import * as moment from 'moment';
import { ListFieldOptionDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-field-option-dto';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { MessageSaveModelAdapter } from 'src/app/_models/messaging/messages/email-messages/message-save-model.adapter';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';
import { QueryBuilderComponent } from '../../../shared/query-builder/query-builder.component';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { LightListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model.adapter';
import ConstTableRowLimitOptions from '../../../shared/constants/app-constants';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HasClientSettingsAndDomainsResponse } from 'src/app/_models/admin/clients/HasClientSettingsAndDomainsResponse';

@Component({
  selector: 'app-message-send',
  templateUrl: './message-send.component.html',
  styleUrls: ['./message-send.component.scss'],
})

export class MessageSendComponent implements OnInit {

  @ViewChild('confirmSendModal', { static: true }) confirmSendModal: ModalDirective;
  @ViewChild('filterContactsModal', { static: true }) filterContactsModal: ModalDirective;
  @ViewChild('queryBuilder', { static: true}) queryBuilder: QueryBuilderComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  _allColumns: DataTableColumn[] = [];
  _confirmSendFormGroup: UntypedFormGroup;
  _countUniqueContacts = 0;
  _dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
  _dateCulture = 'en-GB';
  _defaultOptions: ListFieldOptionDto[] = [];
  _formGroupConfigurator: UntypedFormGroup;
  _formGroupConfirmSend: UntypedFormGroup;
  _listFolderSelectListOptions: SelectListOption[] = [];
  _lightListModels: LightListModel[] = [];
  _listsSelectListOptions: SelectListOption[] = [];
  _messageName = '';
  _messageTypeEnumEmail = MessageTypeEnum.email;
  _messageTypeEnumSms = MessageTypeEnum.sms;
  _selectedListId = 0;
  _selectedListFolderId = 0;
  _selectedMessageId = 0;
  _selectedMessageType: MessageTypeEnum;
  _sendButtonDisabled = false;
  _sending = false;
  _showSpinner = true;
  _tableHasRows = false;
  _subscriptionListFolders: Subscription;
  _subscriptionLists: Subscription;
  _userPermissions: PermissionsModel;
  _uniqueContacts: { listId: number, countUniqueContacts: number } [] = [];
  messageHtml: any;
  initQueryBuilder = false;
  filterContactRules = '';
  _pageTitle = 'Message Send';

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _columnsToDisplay: string[];
  _data: any;
  _displayedColumns: any;
  _pageSizeOptions: number[] = [10, 25, 50, 100];
  _sortColumn = 'UpdatedDate';
  _sortDirection: string = 'DESC';
  _pageIndex: number = 0;

  constructor(
    private contactService: ContactService,
    private emailMessagesService: EmailMessagesService,
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,
    private listFolderDtoAdapter: ListFolderDtoAdapter,
    private listFolderService: ListFolderService,
    private lightListModelAdapter: LightListModelAdapter,
    private listsService: ListsService,
    private smsMessageService: SmsMessageService,
    private smsMessageDtoAdapter: SmsMessageDtoAdapter,
    private messageSaveModelAdapter: MessageSaveModelAdapter,
    private clientManagementService: ClientManagementService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute) {
      this._selectedMessageId = parseInt(this.route.snapshot.paramMap.get('messageId') || "0");
      this._selectedMessageType = parseInt(this.route.snapshot.paramMap.get('messageType') || "0");
    }

  ngOnInit() {
    this._userPermissions = this.permissionsService.getPermissionsModel();

    this._formGroupConfigurator = new UntypedFormGroup({
      message: new UntypedFormControl(''),
      listFolders: new UntypedFormControl(0, Validators.required),
      lists: new UntypedFormControl(0, Validators.required),
      from: new UntypedFormControl({value: '', disabled: true}),
      subject: new UntypedFormControl({value: '', disabled: true}),
    });

    this._formGroupConfirmSend = new UntypedFormGroup({});

    this.getMessage();

    this.populateListFoldersDropdown();

    this.subscribeToFormControls();

    this.checkSettingsAndDomainsExist();
  }

  ngOnDestroy() {
    UnsubscribeService.unsubscribe(this._subscriptionLists,
                                   this._subscriptionListFolders);
  }

  checkSettingsAndDomainsExist() {
    this.clientManagementService.hasClientSettingsAndDomains().subscribe((respone: HasClientSettingsAndDomainsResponse) => {
      if (!respone.HasSettingsAndDomains) {
        this.notificationService.showWarning(respone.Message);
        this._sendButtonDisabled = true;
      }
    });
  }

  isDate(value){
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  hideSpinner() {
    this._showSpinner = false;
  }

  onConfirmSendFormGroupSubmit() {

    this.confirmSendModal.hide();
    this._sending = true;

    const messageSendDtoRequest = new MessageSendDtoRequest( { messageId: this._selectedMessageId,
                                                               listId: this._selectedListId,
                                                               contactsFilter: this.filterContactRules });

    const request = this._selectedMessageType === MessageTypeEnum.email ?
                    this.emailMessagesService.send(messageSendDtoRequest) :
                    this.smsMessageService.send(messageSendDtoRequest);

    request.subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
      this.notificationService.showError(executionResultDto.message);
      }
      this._sending = false;
    }, error =>  { this._sending = false; } );

  }

  onConfiguratorFormGroupSubmit() {
    this._uniqueContacts = [];
    this.contactService.getCountUniqueContacts(this._selectedListId, this._selectedMessageType, this.filterContactRules)
                       .subscribe((executionResultDto: ExecutionResultDto) => {
      this.showSpinner();
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._uniqueContacts.push({ listId: this._selectedListId, countUniqueContacts: executionResultDto.data });
        this.showConfirmSendModal();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
    }, error => this.hideSpinner());
  }

  onSortColumn() {
    if (this.sort.direction == "") {
      this._sortColumn = 'UpdatedDate';
      this._sortDirection = "DESC";
    }
    else if (this.sort.direction === "desc") {
      this._sortDirection = "Descending";
      this._sortColumn = this.sort.active;
    }
    else {
      this._sortDirection = "Ascending";
      this._sortColumn = this.sort.active;
    }

    this.getContacts(this._sortDirection, this._sortColumn);
  }


  onChangePage(event: PageEvent) {
    this._pageCount = event.length;
    this._pageLimit = event.pageSize;
    this._currentPage = +event.pageIndex + 1;
    this._pageIndex = +event.pageIndex;
    this.getContacts();
  }


  getContacts(sortDirection?: string, sortColumn?: string) {

    if (sortDirection !== undefined) this._sortDirection = sortDirection;
    if (sortColumn !== undefined) this._sortColumn = sortColumn;

    const pagerDtoRequest = new PagerDtoRequest({
      page: this._currentPage,
      pageSize: this._pageLimit,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this._selectedListId,
      contactFilter: this.filterContactRules
    });

    this.contactService.getContacts(getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      this.showSpinner();
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._pageCount = executionResultDto.data.Total;
        this._tableHasRows = this._pageCount > 0;
        this._allColumns = [];

        if (!this._tableHasRows) {
          this.hideSpinner();
          return;
        }

        this._allColumns = executionResultDto.data.Columns.map(column =>
          new DataTableColumn({
            prop: column.Name,
            name: column.DisplayName,
            visible: true
          }));

        this._columnsToDisplay = executionResultDto.data.Columns.map(a => a.Name).slice();
        this._data = this.getRows(executionResultDto);
        this._displayedColumns = executionResultDto.data.Columns;

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
    }, error => this.hideSpinner());
  }

  getMessage() {

    const request = this._selectedMessageType === MessageTypeEnum.email ?
                                                this.emailMessagesService.getMessage(this._selectedMessageId) :
                                                this.smsMessageService.get(this._selectedMessageId);

    request.subscribe((executionResultDto: ExecutionResultDto) => {
      this.showSpinner();
      if (executionResultDto.executionResult === ExecutionResult.success) {
        if (this._selectedMessageType === MessageTypeEnum.email) {
          const messageSaveModel = this.messageSaveModelAdapter.adapt(executionResultDto.data);
          this._formGroupConfigurator.get('from').setValue(`${messageSaveModel.FromName} (${messageSaveModel.FromAddress})`);
          this._formGroupConfigurator.get('subject').setValue(messageSaveModel.EmailSubject);
          this._messageName = messageSaveModel.MessageName;
        } else {
          const smsMessageDto: SmsMessageDto = this.smsMessageDtoAdapter.adapt(executionResultDto.data);
          this._formGroupConfigurator.get('message').setValue(smsMessageDto.smsContent);
          this._messageName = smsMessageDto.messageName;
        }
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
    }, error => this.hideSpinner());
  }

  getRows(executionResultDto: ExecutionResultDto) {
    let rows: {[prop: string]: any}[] = [];
    rows = executionResultDto.data.Records.map(record => {
      const row: {[prop: string]: any} = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
           const date = new Date(record[key]);
           row[key] = `${date.toLocaleDateString(this._dateCulture, this._dateOptions)} ${date.toLocaleTimeString()}`;
         } else if (record[key].toString().toLowerCase() === 'true') {
           row[key] = 'Yes';
         } else if (record[key].toString().toLowerCase() === 'false') {
           row[key] = 'No';
         } else {
          row[key] = record[key];
         }
        }
      });
      return row;
    });
    return rows;
  }

  populateListsDropdown() {

    if (!this._selectedListFolderId || this._selectedListFolderId === 0) { return; }

    this._listsSelectListOptions = [];

    this.listsService.getAllLightLists(this._selectedListFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      this.showSpinner();
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._lightListModels = executionResultDto.data.map((lightListModel: LightListModel) => this.lightListModelAdapter.adapt(lightListModel));
        this.setSelectedList();
        this.hideSpinner();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    }, error => this.hideSpinner());
    }

  populateListFoldersDropdown() {

    this.listFolderService.getAll().subscribe((executionResultDto: ExecutionResultDto) => {

      this.showSpinner();

      if (executionResultDto.executionResult === ExecutionResult.success) {
        const listFolderDtos = executionResultDto.data.map((listFolderDto: ListFolderDto) =>
                                this.listFolderDtoAdapter.adapt(listFolderDto));

        this._listFolderSelectListOptions = listFolderDtos.map((listFolderDto: ListFolderDto) =>
                                                new SelectListOption(listFolderDto.id, listFolderDto.name));


      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.hideSpinner();
    }, error => this.hideSpinner());
  }

  setSelectedList() {

    this._listsSelectListOptions = this._lightListModels.filter(lightListModel => lightListModel.FolderId === this._selectedListFolderId)
                                                   .map(opt => new SelectListOption(opt.ListId, opt.ListName));

    if (this._listsSelectListOptions && this._listsSelectListOptions.length === 1) {
      this._selectedListId = this._listsSelectListOptions[0].value;
    } else {
      this._selectedListId = 0;
    }
    this._formGroupConfigurator.get('lists').setValue(this._selectedListId);
  }

  showConfirmSendModal(){
    this._countUniqueContacts = this._uniqueContacts.find(x => x.listId === this._selectedListId).countUniqueContacts;
    this.confirmSendModal.show();
  }

  subscribeToFormControls() {
    this._subscriptionListFolders =  this._formGroupConfigurator.get('listFolders').valueChanges.subscribe((folderId: number) => {
      this._selectedListFolderId = +folderId;
      this.populateListsDropdown();
    });

    this._subscriptionLists = this._formGroupConfigurator.get('lists').valueChanges.subscribe((listId: number) => {
      this._selectedListId = listId;
      this.filterContactRules = '';
      if (this._selectedListId > 0) {
        this.initQueryBuilder = true;
        this.queryBuilder.getListFields(listId, 'contacts');
        this._currentPage = 1;
        this._pageIndex = 0;
        this.getContacts();
        this.checkSettingsAndDomainsExist();
      }
    });
  }

  showSpinner() {
    this._showSpinner = true;
  }

  changeVisibleColumn(event: MatCheckboxChange, col): void {
    let column = this._allColumns.find(e => e.prop == col.prop);
    column.visible = event.checked;

    let visibleColumns = this._allColumns.filter(e => e.visible === true);
    this._columnsToDisplay = this._displayedColumns.filter(column =>
      visibleColumns.find(check => check.prop == column.Name)).map(a => a.Name).slice();;

  }

  contactsFilterSave(event) {
    if (!event[1]) {
      this.filterContactRules = '';
    } else {
      this.filterContactRules = event[1].sql;
    }
    this.getContacts();
    this.filterContactsModal.hide();
  }

  // allow the user to change the max number of rows showed in the table
  onLimitChange(limit: any): void {
    this._pageLimit = parseInt(limit);
    this.getContacts();
  }

  navigateToMessagesPage() {
    this.router.navigate(['messages/', this._selectedMessageId]);
  }


}
