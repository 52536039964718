export class EnquiryMaxDealerInformationDto {
  id: number;
  dealerId: string;
  dealerDescription: string;  
  locationName: string;   

  constructor(init: any) {
    this.id = init.Id;
    this.dealerId = init.DealerId;
    this.dealerDescription = init.DealerDescription;
    this.locationName = init.LocationName;
  }
}


