<app-block-container [title]="optionsName">
  <div class="d-flex flex-row pt-2">
    <form [formGroup]="configuratorFormGroup" class="d-flex">
      <app-md-select label="Select Folder"
                     formControlName="folder"
                     [options]="folderOptions"
                     [showPlaceholder]="showPlaceholder">
      </app-md-select>

      <app-md-select *ngIf="configuratorFormGroup.get('list')"
                     class="pl-2"
                     label="Select List"
                     formControlName="list"
                     [options]="listOptions"
                     [showPlaceholder]="showPlaceholder"
                     [disabled]="disableSelect"
                     [placeholder]="listPlaceholder">
      </app-md-select>
    </form>

    <div *ngIf="selectedFolderId !== 0 && !configuratorFormGroup.get('list')" class="d-flex align-items-end mb-2 px-4">
      <button *ngIf="tableType[this.tableKey] === tableType.messages ? permission?.Create || smsPermission?.Create : permission?.Create"
              class="option-btn edit-folder mr-4"
              (click)="showCreateFolderModal(false)">
        Edit Folder
      </button>

      <button *ngIf="tableType[this.tableKey] === tableType.messages ? permission?.Delete || smsPermission?.Delete : permission?.Delete"
              class="option-btn delete-folder"
              (click)="showDeleteFolderModal()">
        Delete Folder
      </button>
    </div>

    <div class="d-flex align-items-end ml-auto mb-2">
      <button *ngIf="permission?.Create && !configuratorFormGroup.get('list')"
              class="option-btn btn-action {{ buttonStyle }} mr-4"
              (click)="showCreateFolderModal()">
        Create Folder
      </button>

      <button *ngIf="(tableType.messages === tableType[tableKey]) && smsPermission?.Create"
              class="option-btn btn-action {{ buttonStyle }} mr-4"
              [routerLink]="['/messages/sms-message']">
        Create SMS
      </button>

      <button *ngIf="permission?.Create"
              [disabled]="disableActionButton"
              class="option-btn btn-action {{ buttonStyle }}"
              (click)="action.emit()">
        {{ 'Create ' + (tableType[this.tableKey] === tableType.messages ? 'Email': actionName) }}
      </button>
    </div>
  </div>
</app-block-container>
