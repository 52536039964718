<div class="row">
  <div [formGroup]="notifyContactMessage" class="dropdown-container">
    <div class="md-form remove-vertical-margin">
      <mdb-select
        [options]="_listFolderDropdown"
        [visibleOptions]="10"
        class="left-dropdown"
        [filterEnabled]="true"
        label="Select Folder"
        formControlName="listFolder">
      </mdb-select>
    </div>
  
    <div class="md-form remove-vertical-margin">
      <mdb-select
        [options]="_lightListsDropdown"
        [visibleOptions]="10"
        class="right-dropdown"
        [filterEnabled]="true"
        label="Select List"
        formControlName="list">
      </mdb-select>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-md-12">
    <ngx-datatable  
    #contactTable                
    class="material striped"
    [columnMode]="_columnMode.force"
    [rowIdentity]="getId"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [scrollbarV]="false"
    [scrollbarH]="true"
    [rows]="_rows"
    [externalPaging]="true"
    [count]="_pageCount"
    [offset]="_pageOffset"
    [limit]="_pageLimit"
    [selected]="selectedContacts"
    [selectionType]="SelectionType.multiClick"
    (select)="onSelect($event)"
    (page)="getContacts($event)"                
    > 
       
      <ngx-datatable-column *ngFor="let col of _columns" [width]="150" [headerClass]="'lists-table-headers'" [name]="col.name" [prop]="col.prop">                                        
      </ngx-datatable-column>    
      
      <!-- Custom table footer -->
      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="_pageCount" let-pageSize="_pageLimit"
          let-curPage="_currentPage" let-offset="_pageOffset">
          <div class="table-custom-footer">
            <div>
               Rows: {{ _pageCount }}
            </div>                        
            <select (change)="onLimitChange($event.target.value)" class="table-page-limit-select">
              <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                [selected]="option.value == _pageLimit">
                {{option.value}} per page
            </option>
            </select>
            <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'" [page]="_currentPage" [size]="_pageLimit" [count]="_pageCount"
              [hidden]="!((_pageCount / _pageLimit) > 1)" (change)="getContacts($event.page)">
            </datatable-pager>
          </div>
        </ng-template>
      </ngx-datatable-footer>
      
    </ngx-datatable>
    
  </div>
</div>
