import { S3ObjectsModel } from './S3Objects.model'

export class ListObjectsV2ResponseModel {
  IsTruncated: boolean;
  S3Objects: Array<S3ObjectsModel>;
  Name: string;
  Prefix: string;
  MaxKeys: number;
  CommonPrefixes: Array<string>;
  Delimiter: string;
  Encoding: string;
  KeyCount: number;
  ContinuationToken: string;
  NextContinuationToken: string;
  StartAfter: string;
  ResponseMetadata: string;
  ContentLength: number;
  HttpStatusCode: number;

  constructor(init: any) {
    this.IsTruncated = init.IsTruncated
    this.S3Objects = init.S3Objects
    this.Name = init.Name
    this.Prefix = init.Prefix
    this.MaxKeys = init.MaxKeys
    this.CommonPrefixes = init.CommonPrefixes
    this.Delimiter = init.Delimiter
    this.Encoding = init.Encoding
    this.KeyCount = init.KeyCount
    this.ContinuationToken = init.ContinuationToken
    this.NextContinuationToken = init.NextContinuationToken
    this.StartAfter = init.StartAfter
    this.ResponseMetadata = init.ResponseMetadata
    this.ContentLength = init.ContentLength
    this.HttpStatusCode = init.HttpStatusCode
  }
}
