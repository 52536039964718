<app-page-container [title]="tableName">
  <app-messaging-header [tableKey]="tableKey"
                        [permission]="userPermissionsAutomationContactPlans"
                        [folderService]="contactPlanFolderService"
                        [isFolderEmpty]="!data?.length"
                        (folderChanged)="folderSelected($event)"
                        (action)="showCreateContactPlanModal()">
  </app-messaging-header>

  <app-table [tableKey]="tableKey"
             [permission]="userPermissionsAutomationContactPlans"
             [displayedColumns]="displayedColumns"
             [data]="data"
             [length]="length"
             [actionList]="actionList"
             (tableData)="getContactPlans($event)"
             (actionEmit)="actionHandler($event)">
  </app-table>
</app-page-container>
