import { PermissionsAdminPages, PermissionsAdminPagesAdapter } from './permissions-admin-pages.model';
import { PermissionsSocialPages, PermissionsSocialPagesAdapter } from './permissions-social-pages.model';
import { PermissionsMessagingPages, PermissionsMessagingPagesAdapter } from './permissions-messaging-pages.model';
import { PermissionsMediaPages, PermissionsMediaPagesAdapter } from './permissions-media-pages.model';
import { PermissionsAllAvailableDto } from '../../admin/users/permissions-all-available-model';
import { Injectable } from '@angular/core';
import { PermissionsToolsPages, PermissionsToolsPagesAdapter } from './permissions-tools-pages.model';

export class PermissionsModel {
  Admin: PermissionsAdminPages;
  Social: PermissionsSocialPages;
  Messaging: PermissionsMessagingPages;
  Media: PermissionsMediaPages;
  Tools: PermissionsToolsPages;
  private permissionsAdminPagesAdapter: PermissionsAdminPagesAdapter = new PermissionsAdminPagesAdapter();
  private permissionsSocialPagesAdapter: PermissionsSocialPagesAdapter = new PermissionsSocialPagesAdapter();
  private permissionsMessagingPagesAdapter: PermissionsMessagingPagesAdapter = new PermissionsMessagingPagesAdapter();
  private permissionsMediaPagesAdapter: PermissionsMediaPagesAdapter = new PermissionsMediaPagesAdapter();
  private permissionsToolsPagesAdapter: PermissionsToolsPagesAdapter = new PermissionsToolsPagesAdapter();


  constructor(init: PermissionsAllAvailableDto) {
    this.Admin = this.permissionsAdminPagesAdapter.adapt(init.sections.find(section => section.name == 'Admin'));
    this.Social = this.permissionsSocialPagesAdapter.adapt(init.sections.find(section => section.name == 'Social'));
    this.Messaging = this.permissionsMessagingPagesAdapter.adapt(init.sections.find(section => section.name == 'Messaging'));
    this.Media = this.permissionsMediaPagesAdapter.adapt(init.sections.find(section => section.name == 'Media'));
    this.Tools = this.permissionsToolsPagesAdapter.adapt(init.sections.find(section => section.name == 'Tools'));    
  }
}

@Injectable({
  providedIn: 'root'
})

export class PermissonsModelAdapter {
  adapt(item: PermissionsAllAvailableDto): PermissionsModel {
    return new PermissionsModel(item);
  }
}
