import { Injectable } from '@angular/core';
import { PropertyDto } from './property-dto';

@Injectable({
  providedIn: 'root'
})

export class PropertyDtoAdapter {
  public adapt(item: any) {
    return new PropertyDto(item);
  }
}
