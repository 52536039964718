export class MessageHistoryDtoRequest {

  public email: string;
  public mobile: string;    
  public sortColumn: string;
  public sortDirection: string;
  
  constructor(init: { email: string,
                      mobile: string,
                      sortColumn: string,
                      sortDirection: string }) {

    this.email = init.email;
    this.mobile = init.mobile;
    this.sortColumn = init.sortColumn;
    this.sortDirection = init.sortDirection;

  }
}
