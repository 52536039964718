import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { AddProfileDtoRequest } from 'src/app/_models/social-media/networks/add-profile-dto-request';

@Injectable({
  providedIn: 'root'
})
export class FacebookProfileService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'FacebookProfile';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) {

  }

  delete(networkId: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/${networkId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getDefaultPermissions(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetDefaultPermissions`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getInitializationVariables(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetInitializationVariables`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(addPageDtoRequest: AddProfileDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}`, addPageDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  refresh(networkId: number): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/Refresh/${networkId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

