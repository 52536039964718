import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import {
  ListFromFileDtoRequest
} from 'src/app/_models/messaging/lists-and-contacts/contacts/list-from-file-dto-request';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';


@Injectable({
  providedIn: 'root'
})

export class ContactUploadService {
  private baseUrl = environment.apiURL;
  private controllerName = 'ContactUpload';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  downloadErrorsFile(id: string): Observable<ExecutionResultDto> {
    return this.http
      .get(`${ this.baseUrl }${ this.controllerName }/DownloadErrorsFile?id=${ id }`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  downloadFile(id: string): Observable<ExecutionResultDto> {
    return this.http
      .get(`${ this.baseUrl }${ this.controllerName }/DownloadFile?id=${ id }`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAllFieldControlTypes(): Observable<ExecutionResultDto> {
    return this.http
      .get(`${ this.baseUrl }${ this.controllerName }/GetAllFieldControlTypes`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getFieldTypes(): Observable<ExecutionResultDto> {
    return this.http
      .get(`${ this.baseUrl }${ this.controllerName }/GetFieldTypes`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getHistory(contactsPagerDtoRequest: PagerDtoRequest): Observable<ExecutionResultDto> {
    const query = `?Page=${ contactsPagerDtoRequest.page }` +
      `&PageSize=${ contactsPagerDtoRequest.pageSize }` +
      `&SortColumn=${ contactsPagerDtoRequest.sortColumn }` +
      `&SortDirection=${ contactsPagerDtoRequest.sortDirection }`;

    return this.http
      .get(`${ this.baseUrl }${ this.controllerName }/GetHistory${ query }`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  import(listFromFileDtoRequest: ListFromFileDtoRequest): Observable<ExecutionResultDto> {
    return this.http
      .post(`${ this.baseUrl }${ this.controllerName }/Import`, listFromFileDtoRequest)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(listId: number, file: FormData): Observable<ExecutionResultDto> {
    return this.http
      .post(`${ this.baseUrl }${ this.controllerName }/${ listId }`, file)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

