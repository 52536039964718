import { Injectable } from '@angular/core';
import { MessageReportGridViewModel } from './message-report-grid-view-model';

@Injectable({
  providedIn: 'root'
})

export class MessageReportGridViewModelAdapter {
  adapt(item: any): MessageReportGridViewModel {
    return new MessageReportGridViewModel(item);
  }

  adaptArray(init: Array<any>): MessageReportGridViewModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new MessageReportGridViewModel(table))
      : [new MessageReportGridViewModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new MessageReportGridViewModel({
      ReportID: 0,
      ListID: 0,
      TemplateID: 0,
      MessageName: '',
      MessageType: null,
      MessageSourceName: '',
      MessageSourceCssClass: '',
      SentBy: '',
      FolderName: '',
      FolderID: 0,
      TotalOpens: 0,
      OpenRate: 0,
      TotalBounces: 0,
      BounceRate: 0,
      ClickThroughRate: 0,
      TotalClicks: 0,
      LastSentDate: null,
      TotalRecipients: 0
    });
  }
}
