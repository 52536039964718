import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ExecutionResultAdapter, ExecutionResultDto } from "src/app/_models/execution-result-model";
import { SelectedFieldsFiltersDto } from "src/app/_models/selected-fields-filters/selected-fields-filters-dto";
import { environment } from "src/environments/environment";
    
@Injectable({
    providedIn: 'root'
})

export class SelectedFieldsFiltersService {
    baseUrl = environment.apiURL;

    constructor(
        private http: HttpClient,
        private executionResultAdapter: ExecutionResultAdapter
    ) {}

    getAllFiltersByDataTypeAsync(dataType: number) : Observable<ExecutionResultDto> {
        return this.http.get(this.baseUrl + 'SelectedFieldsFilters/GetAllFiltersByDataType?dataType=' + dataType).pipe(map((data: any) =>
               this.executionResultAdapter.adapt(data)));
    }

    addNewFilter(newFilterDto: SelectedFieldsFiltersDto) : Observable<ExecutionResultDto> {
        return this.http.post(this.baseUrl + 'SelectedFieldsFilters/AddFilter', newFilterDto).pipe(map((data: any) =>
        this.executionResultAdapter.adapt(data)));
    }

    deleteFilter(filterToDelete: SelectedFieldsFiltersDto) : Observable<ExecutionResultDto> {
        return this.http.post(this.baseUrl + 'SelectedFieldsFilters/DeleteFilter', filterToDelete).pipe(map((data: any) =>
        this.executionResultAdapter.adapt(data)));
    }
}