import { Component, OnInit, Input } from '@angular/core';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';
import { ClientsDto } from 'src/app/_models/admin/clients/clients-model';

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent implements OnInit {

  @Input() client: ClientsDto;
  @Input() accountManager: string;


  constructor() { }

  ngOnInit() {
  }

}
