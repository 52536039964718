import { Injectable } from '@angular/core';
import { NetworkModelDto } from 'src/app/_models/social-media/networks/network-model-dto';

@Injectable({
  providedIn: 'root'
})

export class NetworkModelAdapter {
  public adapt(item: any) {
    return new NetworkModelDto(item);
  }
}
