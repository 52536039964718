<section class="view intro intro-2">
    <div class="full-bg-img rgba-stylish-strong mask flex-center">
        <div class="container animated fadeIn">
            <div class="row">
                <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

                    <!--Form with header-->
                    <mdb-card bgColor="login-card">
                        <mdb-card-body>

                            <!--Header-->
                            <div class="form-header primary-color rounded-lg">
                                <h3>
                                    <mdb-icon fas icon="lock" class="mt-2 mb-2"></mdb-icon> Cognito Account Validation
                                </h3>
                            </div>

                            <!--Body-->

                            <div>
                                <img src="../../../../assets/images/voicebox-logo-square.png" alt=""
                                    class="vb-logo-image">
                            </div>

                            <form [formGroup]="_validateForm" (ngSubmit)="authenticateUser(validationCodeTextBox.value)">
                                <div class="md-form text-left">
                                    <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                                    <input type="text" autocomplete="off"
                                        [ngClass]="{'is-invalid': _validateForm.get('authenticationCode').errors && _validateForm.get('authenticationCode').touched}"
                                        id="orangeForm-authenticationCode" class="form-control"
                                        formControlName="authenticationCode" mdbInput #validationCodeTextBox>
                                    <label for="orangeForm-authenticationCode" class="" >Validation Code</label>
                                    <div class="invalid-feedback ml-5">Please enter the code that has been sent to your
                                        email address</div>
                                </div>

                                <div class="text-center">
                                    <button *ngIf="!_loggingIn" mdbBtn size="lg"
                                        class="waves-effect waves-light btn-primary rounded-lg login-btn submitButton"
                                        [disabled]="!_validateForm.valid" mdbWavesEffect>Submit</button>
                                    <button *ngIf="_loggingIn" mdbBtn size="lg"
                                        class="waves-effect waves-light btn-primary rounded-lg" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Submitting
                                    </button> <br>
                                    <a class="forgot-password-text resendCodeText" (click)="resendCode()">Resend code</a>
                                </div>
                            </form>

                        </mdb-card-body>
                    </mdb-card>
                    <!--/Form with header-->

                </div>
            </div>
        </div>
    </div>
</section>