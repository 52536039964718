import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'Twitter';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) {
  }

  delete(networkId: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/${networkId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getToken(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/getToken`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

