import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MessageCodeDto } from 'src/app/_models/messaging/automation/message-codes/message-code-dto';
import { MessageCodeRequestDto } from 'src/app/_models/messaging/automation/message-codes/message-code-request-dto';
import { CopyContactCriteriaGroupDto } from 'src/app/_models/messaging/automation/message-codes/copy-contact-criteria-group-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageCodeService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'MessageCode';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) { }


  delete(messageCodeId: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/${messageCodeId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  get(messageCodeId: number): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/${messageCodeId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAll(pagerDtoRequest: PagerDtoRequest, folderId: number): Observable<ExecutionResultDto> {

    const query = `?Page=${pagerDtoRequest.page}` +
                  `&PageSize=${pagerDtoRequest.pageSize}` +
                  `&SortColumn=${pagerDtoRequest.sortColumn}` +
                  `&SortDirection=${pagerDtoRequest.sortDirection}` +
                  `&folderId=${folderId}`;

    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetAll${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(messageCodeDto: MessageCodeRequestDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}`, messageCodeDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  copy(copyContactCriteriaGroupDto: CopyContactCriteriaGroupDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/Copy`, copyContactCriteriaGroupDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

