export class QueryBuilderFilterDto {

  public filterId: number;
  public pageTitle: string;
  public filterName: string;
  public sqlQuery: string;
  public isDeleted?: boolean;

  constructor(init: any) {
    this.filterId = init.FilterID;
    this.pageTitle = init.PageTitle;
    this.filterName = init.FilterName;
    this.sqlQuery = init.SQLQuery;
    this.isDeleted = init.IsDeleted;
  }
}