<form [formGroup]="triggerFormGroup">

  <div class="shorten">
    <div class="md-form remove-bottom-margin">
      <mdb-select
        [options]="_triggerEventsDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select A Trigger"
        formControlName="trigger">
      </mdb-select>
      <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'trigger'])">This field is required.</div>
    </div>

    <div *ngIf="_triggerIsLink" class="md-form remove-vertical-margin">
      <mdb-select
        [options]="_messageLinksDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select A Link"
        formControlName="messageLinkId">
      </mdb-select>
      <div class="alert alert-warning remove-padding" *ngIf="this._messageLinksDropdown.length === 0">The selected message has no links.</div>
      <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'messageLinkId'])">This field is required.</div>
    </div>

    <div *ngIf="_triggerIsTargetLink" class="md-form remove-vertical-margin">
      <input 
          mdbInput 
          type="url" 
          class="form-control"
          id="targetUrl"  
          pattern="https://.*"
          required
          formControlName="targetUrl">
      <label for="targetUrl">Target URL</label>
      <div class="alert alert-warning remove-padding" *ngIf="this.triggerFormGroup.get('targetUrl').invalid && this.triggerFormGroup.get('targetUrl').touched">
        <div class="alert alert-warning remove-padding" *ngIf="this.triggerFormGroup.get('targetUrl').errors.required">Target URL is required.</div>
        <div class="alert alert-warning remove-padding" *ngIf="this.triggerFormGroup.get('targetUrl').errors.pattern">Invalid Target URL.</div>
      </div>
    </div>

    <div *ngIf="this._webFormDropdown.length !== 0" class="md-form remove-bottom-margin" [@slide]>
      <mdb-select
        [options]="_webFormDropdown"
        [filterEnabled]="true"
        [visibleOptions]="10"
        label="Select A Form"
        formControlName="form">
      </mdb-select>
      <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'form'])">This field is required.</div>
    </div>

    <div *ngIf="this._showTimeBasedCCFields" [@slide]>
      <form [formGroup]="timeBasedWCCFormGroup">
        <div class="cc-option-container">
          <div *ngIf="this._timeBasedWWCDropdown.length !== 0" class="md-form remove-vertical-margin cc-left-option">
            <mdb-select
              [options]="_timeBasedWWCDropdown"
              [filterEnabled]="true"
              [visibleOptions]="10"
              label="Contact Criteria"
              formControlName="contactCriteria">
            </mdb-select>
          </div>
          <div class="cc-middle-text">
            <p class="text-dark text-center align-middle remove-bottom-margin mt-3 font-weight-bold">everyday at</p>
          </div>
          <div class="md-form remove-vertical-margin cc-right-option">
            <input 
              mdbInput 
              type="time" 
              class="form-control custom-date-time-height"
              formControlName="time">
          </div>
        </div>
        <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedWCCFormGroup', 'contactCriteria']) ||
                                                               this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedWCCFormGroup', 'time'])">
          Please check the above fields are correct.
        </div>
      </form>
    </div>

    <div *ngIf="this._showTimeBasedFields" [@slide]>
      <form [formGroup]="timeBasedFormGroup">
        <div class="md-form remove-vertical-margin">
          <mdb-select
                      [options]="_listFieldsDropdown"
                      [filterEnabled]="true"
                      [visibleOptions]="10"
                      label="Field"
                      formControlName="field">
          </mdb-select>
          <div class="alert alert-warning remove-padding" *ngIf="this._listFieldsDropdown.length === 0">The selected List has no datetime fields.</div>
        </div>
        <div class="md-form remove-vertical-margin">
          <mdb-select
                      [options]="_frequencyDropdown"
                      [filterEnabled]="true"
                      [visibleOptions]="10"
                      label="Frequency"
                      formControlName="frequency">
          </mdb-select>
        </div>
        <p class="mt-2 text-dark font-weight-bold" *ngIf="!this._isTimeBasedValueSelected">Delivery Time</p>
        <p class="mt-2 text-dark font-weight-bold" *ngIf="this._isTimeBasedValueSelected">Date Is</p>
        <div class="side-by-side-container">
          <div class="md-form remove-vertical-margin left-form-input">
            <input
                   mdbInput
                   type="number"
                   class="form-control"
                   id="no-of"
                   min="0"
                   formControlName="numberOf">
            <label class="remove-bottom-margin" for="no-of">No. Of</label>
          </div>
          <div class="md-form remove-vertical-margin right-form-input">
            <mdb-select
                        [options]="_daysWeeksMonths"
                        [filterEnabled]="true"
                        [visibleOptions]="10"
                        label="Days/Weeks/Months"
                        formControlName="daysWeeksMonths">
            </mdb-select>
          </div>

          <div class="md-form remove-vertical-margin left-form-input-2">
            <mdb-select
                        [options]="_beforeAfter"
                        [filterEnabled]="true"
                        [visibleOptions]="10"
                        label="Before/After"
                        formControlName="beforeAfter">
            </mdb-select>
          </div>
          <div class="middle">
            <p class="text-dark text-center align-middle remove-bottom-margin mt-3 font-weight-bold">at</p>
          </div>
          <div class="md-form remove-vertical-margin right-form-input-2">
            <input
                   mdbInput
                   type="time"
                   class="form-control custom-date-time-height"
                   formControlName="time">
          </div>
        </div>
        <div class="alert alert-warning remove-padding"
             *ngIf="this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedFormGroup', 'field']) ||
                      this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedFormGroup', 'numberOf']) ||
                      this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedFormGroup', 'daysWeeksMonths']) ||
                      this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedFormGroup', 'beforeAfter']) ||
                      this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedFormGroup', 'time'])"
             >Missing one or more Time Based fields.</div>
      </form>  
    </div>   

    <div *ngIf="this._showTimeBasedOneOffSendFields" [@slide]>
      <form [formGroup]="timeBasedOneOffSendFormGroup">
        <div class="cc-option-container">          
          <div class="cc-middle-text">
            <p class="text-dark text-center align-middle remove-bottom-margin mt-3 font-weight-bold">at</p>
          </div>
          <div class="md-form remove-vertical-margin oos-dateTime-right-option">
            <input 
              mdbInput 
              type="datetime-local" 
              class="form-control custom-date-time-height"
              formControlName="oneOffSendDateTime"/>
          </div>
        </div>
        <div class="alert alert-warning remove-padding" *ngIf="this.createEditTriggerComponent.showValidationError(['triggerFormGroup', 'timeBasedOneOffSendFormGroup', 'oneOffSendDateTime'])">
          Please check the above fields are correct.
        </div>
      </form>
    </div>
    <div>
      <p class="mt-2 text-dark font-weight-bold">And will only occur for contacts with the following criteria...</p>
    </div>
    <div class="scrollbar scrollbar-primary force-overflow contact-filter-list" (click)="_queryBuilderModal.show()"> 
      <input type="hidden" #contactFilterCriteria formControlName="contactFilterCriteria" id="contactFilterCriteria">
      <p *ngIf="contactFilterCriteria.value === ''" class="contact-filter-placeholder remove-bottom-margin">Set Contact Criteria...</p>
      <p *ngIf="contactFilterCriteria.value !== ''" class="contact-filter-string">{{viewContactsFilterSaved()}}</p> 
    </div>
  </div>  
</form>

<div mdbModal #queryBuilderModal="mdbModal" class="modal fade modal-overflow" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="queryBuilderModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Filter Contacts</h4>
      </div>
      <div class="modal-body">                
        <app-query-builder 
          #queryBuilder
          *ngIf="this._listID !== undefined && _initQueryBuilder" 
          [_parentListID]="this._listID" 
          [_parentQuery]="contactFilterCriteria.value"
          [_parentSource]="'createEditTrigger'"
          (queryBuilderSave)="contactsFilterSave($event)"
          [(_parentPageTitle)]="this._pageTitle">            
        </app-query-builder>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
