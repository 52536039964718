
import { Component, OnInit } from '@angular/core';
import { UserRoleService } from 'src/app/_services/admin/users/user-role-service';
import { UserRolePermissionService } from 'src/app/_services/admin/users/user-role-permission-service';
import { UserPermissionTypeService } from 'src/app/_services/admin/users/user-permission-type-service';
import { UserRolePermissionDto } from 'src/app/_models/admin/users/user-role-permission-model';
import { UserPermissionTypeDto } from 'src/app/_models/admin/users/user-permission-type-model';
import { PermissionsAllAvailableDto } from 'src/app/_models/admin/users/permissions-all-available-model';
import { ActivatedRoute } from '@angular/router';
import { UserRoleDto } from 'src/app/_models/admin/users/user-role-model';

@Component({
  selector: 'app-user-role-permissions',
  templateUrl: './user-role-permission.component.html',
  styleUrls: ['./user-role-permission.component.css']  
})
export class UserRolePermissionComponent implements OnInit {

  _userPermissionTypeDtos: UserPermissionTypeDto[] = []
  _userRolePermissionDtos: UserRolePermissionDto[] = []
  _permissionsAllAvailableDto: PermissionsAllAvailableDto;
  _userRoleId: number; 
  _permissionKeyForView: number = 1; 
  _userRoleDto: UserRoleDto;
  
  constructor(
    private userRoleService: UserRoleService,
    private userRolePermissionService: UserRolePermissionService, 
    private userPermissionTypeService: UserPermissionTypeService,    
    private route: ActivatedRoute) { }

  ngOnInit() {

    this._userRoleId = parseInt(this.route.snapshot.paramMap.get("userRoleId"));

    this.userRoleService.get(this._userRoleId).subscribe(data => {
      this._userRoleDto = data;
    });
    this.userRolePermissionService.get(this._userRoleId).subscribe(data => {
      this._userRolePermissionDtos = data;
    });

    this.userPermissionTypeService.get().subscribe(data => {
      this._userPermissionTypeDtos = data;
    });    

    this.userRolePermissionService.getAllAvailable().subscribe(data => {
      this._permissionsAllAvailableDto = data;
    });  
    
  }

  postUserRolePermission(userPermissionDto: UserRolePermissionDto)  {
    this.userRolePermissionService.post(userPermissionDto).subscribe((id: number) => {
       userPermissionDto.id = id;
       this.addUserRolePermissionIfNotExists(userPermissionDto)
    });
  }  

  deleteUserRolePermission(id: number) {
    this.userRolePermissionService.delete(id).subscribe(() => {
      this.removeUserRolePermission(id)
    });
  }

  removeUserRolePermission(id: number) {
    if (this._userRolePermissionDtos.some(_ => _.id == id)) {
        this._userRolePermissionDtos = this._userRolePermissionDtos.filter(_ => _.id !== id);        
    };   
  }

  addUserRolePermissionIfNotExists(userPermissionDto: UserRolePermissionDto) {          
    if (!this._userRolePermissionDtos.some(_ => _.id == userPermissionDto.id)) {
      this._userRolePermissionDtos.push(userPermissionDto);
    };    
  }

  toggleHideNonViewCheckboxes(hide: boolean, sectionId: number, pageId: number) { 
    
    const elements = document.querySelectorAll(`div[id^='div-${sectionId}-${pageId}-']`);

    for(let i=0; i < elements.length; i++) {        
       if (elements[i].getAttribute("title").toLowerCase() !== 'view') { 
        const style = hide ? "none" : "inline"
        elements[i].setAttribute("style", `display:${style} !important`);
      }
    }
  }
  
  handleCheckBoxChange(e) {  

    const isChecked = e.target.checked;

    const checkboxValueArray = e.target.value.split('-');     
    const pageSectionId: number = +checkboxValueArray[0];
    const pageId: number = +checkboxValueArray[1];
    const permissionKey: number = +checkboxValueArray[2];

    let userRolePermissionDto = this.findUserPermission(pageSectionId, pageId);    

    if (permissionKey === this._permissionKeyForView) {  
      this.toggleHideNonViewCheckboxes(!isChecked, pageSectionId, pageId);
      if (!isChecked) {
        this.deleteUserRolePermission(userRolePermissionDto.id);
        return;
      }
    }   

    if (userRolePermissionDto) 
      !isChecked ? userRolePermissionDto.permissionKey -= permissionKey : userRolePermissionDto.permissionKey += permissionKey;
    else 
      userRolePermissionDto = new UserRolePermissionDto({ Id: 0, PageSectionId: pageSectionId, PageId: pageId, UserRoleId: this._userRoleId, PermissionKey: permissionKey})
              
    this.postUserRolePermission(userRolePermissionDto) 
  }

  permissionTypeIsVisible (permissionKey: number, permissionKeyForPage: number): boolean {
    return permissionKey <= permissionKeyForPage;
  }
  
  hasPermission (pageSectionId: number, pageId: number, permissionKey: number): boolean { 
    const userPermissionDto = this.findUserPermission(pageSectionId, pageId)
    return (userPermissionDto) ?  (userPermissionDto.permissionKey & permissionKey) > 0 : false;
  }  

  findUserPermission (pageSectionId: number, pageId: number) : UserRolePermissionDto {       
    return this._userRolePermissionDtos.find(u => u.userRoleId == this._userRoleId &&
                                             u.pageSectionId == pageSectionId && 
                                             u.pageId == pageId);
  } 
}
