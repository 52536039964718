import { Injectable } from "@angular/core";
import { CopyContactPlanDto } from "./copy-contact-plans-dto";

@Injectable({
  providedIn: 'root'
})

export class CopyContactPlanDtoAdapter {
  adapt(item: any): CopyContactPlanDto {
    return new CopyContactPlanDto(item);
  }
}
