import { Injectable } from '@angular/core';
import { PagerModel } from '../../system/pager-model';
import { DateConverterService } from '../../../_services/social-media/date-converter.service';

export class FilterPostsModel {
  PagerModel: PagerModel;
  DateFrom: string | Date;
  DateTo: string | Date;
  Networks: Array<string> = [];
  NetworkGroups: Array<string> = [];
  SearchText: string;
  Status: string;

  constructor(init: any, private dateConverter: DateConverterService) {
    this.DateFrom = this.dateConverter.noOffsetConvert(init.dateFrom);
    this.DateTo = this.dateConverter.noOffsetConvert(init.dateTo);
    this.Networks = init.networks;
    this.NetworkGroups = init.networkGroups;
    this.SearchText = init.searchText;
    this.Status = String(init.status);
  }
}

@Injectable({
  providedIn: 'root'
})

export class FilterPostsModelAdapter {
  adapt(item: any, dateConverter): FilterPostsModel {
    return new FilterPostsModel(item, dateConverter);
  }

}
