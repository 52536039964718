<div class="create-edit-post-page">
  <form [formGroup]="postsForm" autocomplete="off">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="navigation">
            <img class="image-back" src="../../../../../assets/images/back.png" alt="Back Button" (click)="backToPosts()">
            <span class="label-back">Back to Posts</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="title-box">
            <span class="header-section">{{ isEditMode ? 'Edit Post' : 'Create Post' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="post-section">
      <div class="container-fluid">
        <div class="row justify-content-start">
          <div class="col-12 col-md-4 col-lg-3">
            <label for="name" class="input-label ml-0">Select Network Groups</label>
            <mdb-select [options]="_networkGroupsDropdown" [visibleOptions]="10" [multiple]="true"
                        [filterEnabled]="true"
                        [highlightFirst]="false" class="custom-drop-down form-control select-input select-folder"
                        formControlName="networkGroups" (selected)="networkGroupSelect($event)"
                        (deselected)="networkGroupDeselect($event)" placeholder="&lt;Select Network Groups&gt;">
            </mdb-select>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <label for="name" class="input-label ml-0">Select Networks</label>
            <mdb-select [options]="_networksDropdown" [visibleOptions]="10" [multiple]="true" [filterEnabled]="true"
                        [highlightFirst]="false" class="custom-drop-down form-control select-input select-folder"
                        formControlName="networks" (selected)="networkSelect($event)"
                        (deselected)="networkDeselect($event)"
                        placeholder="&lt;Select Networks&gt;">
            </mdb-select>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <label for="name" class="input-label ml-0">Select Date & Time</label>
            <input [readonly]="_scheduleSeparately" min="{{_minDate}}" autocomplete="off" name="dual-date-time"
              id="dual-date-time-input" class="form-control align-bottom" type="datetime-local"
              formControlName="multiDateTime" placeholder="----/--/-- --:--" (click)="this.pickerIsOpen(true)"
              (keydown)="this.pickerIsOpen(true)" (blur)="this.pickerIsOpen(false)" mdbInput>
          </div>
          <div class="col-12 col-lg-3 d-flex align-items-end justify-content-start">
            <mat-checkbox [checked]="_scheduleSeparately" formControlName="globalSchedule"
              (change)="this._scheduleSeparately = !this._scheduleSeparately;"
              (change)="this.postsForm.get('multiDateTime').reset();">
              Schedule Separately?
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="name" class="d-block input-label ml-0">Selected Social Media Platforms</label>
            <div class="social-media-chip mr-3">
              <i class="fab fa-facebook mr-3 facebook-icon"></i>
              <span class="pr-2 font-weight-bold">Facebook</span>
              <span class="social-count">
                <span *ngIf="this._selectedFbPages.length > 0">{{countNetworks(networkTypeEnum()['Facebook Page'])}}</span>
                <span *ngIf="this._selectedFbPages.length == 0">0</span>
              </span>
            </div>
            <div class="social-media-chip mr-3">
              <mdb-icon fab icon="twitter" class="mr-2 twitter-icon"></mdb-icon>
              <span class="pr-2 font-weight-bold">Twitter</span>
              <span class="social-count">
                <span *ngIf="this._selectedTwPages.length > 0">{{countNetworks(networkTypeEnum().Twitter)}}</span>
                <span *ngIf="this._selectedTwPages.length == 0">0</span>
              </span>
            </div>
            <div class="social-media-chip mr-3">
              <mdb-icon fab icon="instagram" class="mr-2 instagram-icon"></mdb-icon>
              <span class="pr-2 font-weight-bold">Instagram</span>
              <span class="social-count">
                <span *ngIf="this._selectedIgPages.length > 0">{{countNetworks(networkTypeEnum().Instagram)}}</span>
                <span *ngIf="this._selectedIgPages.length == 0">0</span>
              </span>
            </div>
            <div class="social-media-chip">
              <mdb-icon fab icon="linkedin" class="mr-2 linkedin-icon"></mdb-icon>
              <span class="pr-2 font-weight-bold">LinkedIn</span>
              <span class="social-count">
                <span *ngIf="this._selectedLiPages.length > 0">{{countNetworks(networkTypeEnum().LinkedIn)}}</span>
                <span *ngIf="this._selectedLiPages.length == 0">0</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- General Post -->
    <div class="post-section" [hidden]="_isCustomiseEachNetworkSelected">
      <!-- Validation Messages -->
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyVideoFilesError}}"
        *ngIf="postsForm.hasError('TooManyVideoFilesError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyGifsError}}"
        *ngIf="postsForm.hasError('TooManyGifsError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.RequiresPhotosVideosForInstagramError}}"
        *ngIf="postsForm.hasError('RequiresPhotosVideosForInstagramError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyPhotosVideosForTwitterError}}"
        *ngIf="postsForm.hasError('TooManyPhotosVideosForTwitterError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyPhotosVideosForInstagramError}}"
        *ngIf="postsForm.hasError('TooManyPhotosVideosForInstagramError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyPhotosVideosForFacebookError}}"
        *ngIf="postsForm.hasError('TooManyPhotosVideosForFacebookError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyPhotosVideosForLinkedInError}}"
        *ngIf="postsForm.hasError('TooManyPhotosVideosForLinkedInError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FileTypeError}}"
        *ngIf="postsForm.hasError('FileTypeError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyFileFormatsError}}"
        *ngIf="postsForm.hasError('TooManyFileFormatsError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramCanOnlyAcceptTenSelectedMediaWhenPostingToCarouselsError}}"
        *ngIf="postsForm.hasError('InstagramCanOnlyAcceptTenSelectedMediaWhenPostingToCarouselsError')"></error-alert>
      <!-- File Size Validation Error -->
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookImageFileSizeError}}"
        *ngIf="this._selectedFbPages.length > 0 && facebookImageFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookVideoFileSizeError}}"
        *ngIf="_selectedFbPages.length > 0 && facebookVideoFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterImageFileSizeError}}"
        *ngIf="_selectedTwPages.length > 0 && twitterImageFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterVideoFileSizeError}}"
        *ngIf="_selectedTwPages.length > 0 && twitterVideoFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramImageFileSizeError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramImageFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramVideoFileSizeError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramVideoFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInImageFileSizeError}}"
        *ngIf="_selectedLiPages.length > 0 && linkedInImageFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInVideoFileSizeError}}"
        *ngIf="_selectedLiPages.length > 0 && linkedInVideoFileSizeError"></error-alert>
      <!-- Video Length Error -->
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookVideoLengthError}}"
        *ngIf="this._selectedFbPages.length > 0 && facebookVideoLengthError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterVideoLengthError}}"
        *ngIf="_selectedTwPages.length > 0 && twitterVideoLengthError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramVideoLengthError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramVideoLengthError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInVideoLengthError}}"
        *ngIf="_selectedLiPages.length > 0 && linkedInVideoLengthError"></error-alert>
      <!-- Aspect Ratio Error -->
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramMediaPortraitAspectRatioError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramMediaPortraitAspectRatioError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramMediaLandScapeAspectRatioError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramMediaLandScapeAspectRatioError"></error-alert>
        <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramMediaSquareAspectRatioError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramMediaSquareAspectRatioError"></error-alert>
      <!-- Main Content -->
      <span class="title-sub-section">Create Message</span>
      <hr class="mt-1 mb-0">
      <div class="row mt-3 ml-0">
        <div class="col-md-6">
          <label for="name" class="d-block input-label ml-0">Message</label>
          <div class="emoji-text-container">
            <textarea type="text" class="text-area form-control" formControlName="generalMessage">
            </textarea>          
            <div class="sidebar mt-2 mb-2 mr-2">
              <button #toggleEmoji class="emoji-button mt-2 mr-2" type="button" (click)="_isEmojiPickerVisible = !_isEmojiPickerVisible">
              <mdb-icon class="emoji-icon" far icon="smile"></mdb-icon>
            </button>
               <span *ngIf="this._selectedTwPages.length > 0"  class="text-count" [hidden]="_isEmojiPickerVisible">
                <span>{{_twitterCharLimit - this.postsForm.get('generalMessage')?.value?.length}}</span>
              </span>
            </div>
          </div>
          <emoji-mart (emojiClick)="addEmojiToMessage($event, 0)" [hidden]="!_isEmojiPickerVisible"
            class="emoji-mart-display"></emoji-mart>
        </div>
        <div class="col-md-6">
          <div class="row" style="margin-right: -7% !important;">
            <div class="col-md-11 mt-3">
              <div class="file-field md-form remove-vertical-margin justify-content-start">
                <a (click)="openMediaServerModal(0)" class="color-light-blue mb-3">+
                  Add Photo or Video from Media Server</a>
                <a placement="left" mdbPopover="Only one video, animated gif or collection of photos
                can be attached to this post. Allowed files types are .jpg,
                .jpeg, .png, .gif, .mp4." triggers="hover" container="body">
                  <i class="fas fa-info-circle ml-2"></i>
                </a>
              </div>
              <div class="file-image-placeholders" *ngIf="generalFiles">
                <div class="row">
                  <div class="col-md-4 mb-4" *ngFor="let file of generalFiles">
                    <mdb-card
                      [ngStyle]="{'background-image': 'url('+(file.VideoThumbnailFileUrl ? file.VideoThumbnailFileUrl : this.mediaServerService.getFileImageIcon(file))+')' }"
                      class="card-image">
                      <a class="file-details-container">
                        <div class="file-details justify-content-center text-white text-center">
                          {{this.mediaServerService.getFileName(file.FileName)}}
                          <div class="row">
                            <button mdbBtn type="button" color="primary" class="waves-light file-details-button"
                              (click)="deleteFile(file, 0)" mdbWavesEffect>
                              <mdb-icon fas icon="times"></mdb-icon>
                            </button>
                          </div>
                        </div>
                      </a>
                    </mdb-card>
                  </div>
                </div>
              </div>
              <div *ngIf="generalFiles.length > 1 && _selectedFbPages.length" class="remove-bottom-margin">
                <label for="name" class="text-left">Album Name</label>
                <input type="text" id="album-name" formControlName="albumName" class="form-control">
                <div class="error mt-2 mb-3" *ngIf="postsForm.hasError('albumNameError')">Album Name
                  Required
                </div>
                <div class="info mt-4">Because you're uploading more than one image, you need to create a
                  Facebook Album, which needs a name.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row justify-content-end text-right">
          <div class="col-md-12 col-lg-7">
            <button type="button" (click)="clearPosts(0)" class="btn-clear">Clear</button>
            <button type="button" class="btn-custom-network"
                    [disabled]="_selectedNetworks.size <= 1 || isEditMode"
                    (click)="customiseForEachNetwork()">
              Customise for each platform
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Facebook Post -->
    <div class="post-section" [hidden]="!displayNetworkSection(networkTypeEnum()['Facebook Page'])">
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyPhotosVideosForFacebookError}}"
        *ngIf="postsForm.hasError('facebookTooManyPhotosVideosError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyVideoFilesError}}"
        *ngIf="postsForm.hasError('facebookTooManyMP4sError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyGifsError}}"
        *ngIf="postsForm.hasError('facebookTooManyGifsError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FileTypeError}}"
        *ngIf="postsForm.hasError('facebookFileTypeError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookTooManyFileFormatsError}}"
        *ngIf="postsForm.hasError('facebookTooManyFileFormatsError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookImageFileSizeError}}"
        *ngIf="facebookImageFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookVideoFileSizeError}}"
        *ngIf="facebookVideoFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookVideoLengthError}}"
        *ngIf="facebookVideoLengthError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FacebookTooManyChars}}"
        *ngIf="postsForm.hasError('facebookTooManyChars')"></error-alert>
      <span class="title-sub-section"><i class="fab fa-facebook mr-3 facebook-icon"
          style="font-size: 30px;"></i>Facebook</span>
      <hr class="mt-1 mb-0">
      <div class="row mt-3 ml-0">
        <div class="col-md-6">
          <label for="name" class="d-block input-label ml-0">Message</label>
          <div class="emoji-text-container">
            <textarea type="text" class="text-area form-control" maxlength="63206" formControlName="facebookText">
            </textarea>
            <button #toggleEmojiFacebook class="emoji-button mt-2 mr-2" type="button" (click)="_isFacebookEmojiPickerVisible = !_isFacebookEmojiPickerVisible"><mdb-icon class="emoji-icon" far icon="smile"></mdb-icon></button>
          </div>
          <emoji-mart (emojiClick)="addEmojiToMessage($event, networkTypeEnum()['Facebook Page'])"
            [hidden]="!_isFacebookEmojiPickerVisible" class="emoji-mart-display"></emoji-mart>
        </div>
        <div class="col-md-6">
          <div class="row" style="margin-right: -7% !important;">
            <div class="col-md-11 mt-3">
              <div class="file-field md-form remove-vertical-margin justify-content-start">
                <a (click)="openMediaServerModal(networkTypeEnum()['Facebook Page'])" class="color-light-blue mb-3">+
                  Add Photo or Video from Media Server</a>
                <a placement="left" mdbPopover="Only one video, animated gif or collection of photos
                can be attached to Facebook posts. Allowed files types are .jpg,
                .jpeg, .png, .gif, .mov, .mp4." triggers="hover" container="body">
                  <i class="fas fa-info-circle ml-2"></i>
                </a>
              </div>
              <div class="file-image-placeholders" *ngIf="this._facebookFiles">
                <div class="row">
                  <div class="col-md-4 mb-4" *ngFor="let file of this._facebookFiles">
                    <mdb-card
                      [ngStyle]="{'background-image': 'url('+(file.VideoThumbnailFileUrl ? file.VideoThumbnailFileUrl : this.mediaServerService.getFileImageIcon(file))+')' }"
                      class="card-image">
                      <a class="file-details-container">
                        <div class="file-details justify-content-center text-white text-center">
                          {{this.mediaServerService.getFileName(file.FileName)}}
                          <div class="row">
                            <button mdbBtn type="button" color="primary" class="waves-light file-details-button"
                              (click)="deleteFile(file, networkTypeEnum()['Facebook Page'])" mdbWavesEffect>
                              <mdb-icon fas icon="times"></mdb-icon>
                            </button>
                          </div>
                        </div>
                      </a>
                    </mdb-card>
                  </div>
                </div>
              </div>
              <div *ngIf="_facebookFiles.length > 1" class="remove-bottom-margin">
                <label for="name" class="text-left">Album Name</label>
                <input type="text" id="album-name" formControlName="albumName" class="form-control">
                <div class="error mt-2 mb-3" *ngIf="postsForm.hasError('albumNameError')">Album Name
                  Required
                </div>
                <div class="info mt-4">Because you're uploading more than one image, you need to create a
                  Facebook
                  Album, which needs a name.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div [ngStyle]="{'visibility': !this._scheduleSeparately ? 'hidden': 'visible'}" class="col-md-3 col-lg-2">
            <label for="name" class="input-label ml-0">Select Date & Time</label>
            <input autocomplete="off" min="{{returnMinimumDate()}}" name="facebook-date-time"
              id="facebook-date-time-input" class="form-control align-bottom" type="datetime-local"
              formControlName="facebookDateTime" placeholder="----/--/-- --:--" (click)="this.pickerIsOpen(true)"
              (keydown)="this.pickerIsOpen(true)" (blur)="this.pickerIsOpen(false)" mdbInput>
          </div>
          <div class="col-md-9 col-lg-10 d-flex align-items-end justify-content-end">
            <button type="button" (click)="clearPosts(networkTypeEnum()['Facebook Page'])"
              class="btn-clear">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Twitter Post -->
    <div class="post-section" [hidden]="!displayNetworkSection(networkTypeEnum().Twitter)">
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyVideoFilesError}}"
        *ngIf="postsForm.hasError('twitterTooManyMP4sError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyGifsError}}"
        *ngIf="postsForm.hasError('twitterTooManyGifsError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyPhotosVideosForTwitterError}}"
        *ngIf="postsForm.hasError('twitterTooManyPhotosError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterFileTypeError}}"
        *ngIf="postsForm.hasError('twitterFileTypeError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterTooManyFileFormatsError}}"
        *ngIf="postsForm.hasError('twitterTooManyFileFormatsError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterImageFileSizeError}}"
        *ngIf="twitterImageFileSizeError"> </error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterVideoFileSizeError}}"
        *ngIf="twitterVideoFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterVideoLengthError}}"
        *ngIf="twitterVideoLengthError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TwitterTooManyChars}}"
        *ngIf="postsForm.hasError('twitterTooManyChars')"></error-alert>
      <span class="title-sub-section"><i class="fab fa-twitter mr-3 twitter-icon"
          style="font-size: 30px;"></i>Twitter</span>
      <hr class="mt-1 mb-0">
      <div class="row mt-3 ml-0">
        <div class="col-md-6">
          <label for="name" class="d-block input-label ml-0">Message</label>
          <div class="emoji-text-container">
            <textarea type="text" class="text-area form-control" maxlength="279" formControlName="twitterText">
            </textarea>
            <div class="sidebar mt-2 mb-2 mr-2">
              <button #toggleEmojiTwitter class="emoji-button" type="button" (click)="_isTwitterEmojiPickerVisible = !_isTwitterEmojiPickerVisible"><mdb-icon class="emoji-icon" far icon="smile"></mdb-icon></button>
              <span class="text-count" [hidden]="_isTwitterEmojiPickerVisible">
                <span>{{_twitterCharLimit - this.postsForm.get('twitterText').value.length}}</span>
              </span>
            </div>
          </div>
          <emoji-mart (emojiClick)="addEmojiToMessage($event, networkTypeEnum().Twitter)"
            [hidden]="!_isTwitterEmojiPickerVisible" class="emoji-mart-display"></emoji-mart>
        </div>
        <div class="col-md-6">
          <div class="row" style="margin-right: -7% !important;">
            <div class="col-md-11 mt-3">
              <div class="file-field md-form remove-vertical-margin justify-content-start">
                <a (click)="openMediaServerModal(networkTypeEnum().Twitter)" class="color-light-blue mb-3">+
                  Add Photo or Video from Media Server</a>
                <a placement="left" mdbPopover="Only one video, animated gif or four
                photos can be attached to Twitter posts. Accepted
                file types are .jpg, .jpeg, .png, .gif, .mp4." triggers="hover" container="body">
                  <i class="fas fa-info-circle ml-2"></i>
                </a>
              </div>
              <div class="file-image-placeholders" *ngIf="this._twitterFiles">
                <div class="row">
                  <div class="col-md-4 mb-4" *ngFor="let file of this._twitterFiles">
                    <mdb-card
                      [ngStyle]="{'background-image': 'url('+(file.VideoThumbnailFileUrl ? file.VideoThumbnailFileUrl : this.mediaServerService.getFileImageIcon(file))+')' }"
                      class="card-image">
                      <a class="file-details-container">
                        <div class="file-details justify-content-center text-white text-center">
                          {{ this.mediaServerService.getFileName(file.FileName) }}
                          <div class="row">
                            <button mdbBtn type="button" color="primary" class="waves-light file-details-button"
                              (click)="deleteFile(file, networkTypeEnum().Twitter)" mdbWavesEffect>
                              <mdb-icon fas icon="times">
                              </mdb-icon>
                            </button>
                          </div>
                        </div>
                      </a>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row mt-4">
          <div [ngStyle]="{'visibility': !this._scheduleSeparately ? 'hidden': 'visible'}" class="col-md-3 col-lg-2">
            <label for="name" class="input-label ml-0">Select Date & Time</label>
            <input autocomplete="off" min="{{returnMinimumDate()}}" name="twitter-date-time"
              id="twitter-date-time-input" class="form-control align-bottom" type="datetime-local"
              formControlName="twitterDateTime" placeholder="----/--/-- --:--" (click)="this.pickerIsOpen(true)"
              (keydown)="this.pickerIsOpen(true)" (blur)="this.pickerIsOpen(false)" mdbInput>
          </div>
          <div class="col-md-9 col-lg-10 d-flex align-items-end justify-content-end">
            <button type="button" (click)="clearPosts(networkTypeEnum().Twitter)" class="btn-clear">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Instagram Post -->
    
    <div class="post-section" [hidden]="!displayNetworkSection(networkTypeEnum().Instagram)">
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyVideoFilesError}}"
      *ngIf="postsForm.hasError('instagramTooManyMP4sError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyFileFormatsError}}"
        *ngIf="postsForm.hasError('instagramTooManyFileFormatsError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.FileTypeError}}"
        *ngIf="postsForm.hasError('instagramFileTypeError')"> </error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.RequiresPhotosVideosForInstagramError}}"
        *ngIf="postsForm.hasError('instagramRequiresPhotosVideosError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramImageFileSizeError}}"
        *ngIf="instagramImageFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramVideoFileSizeError}}"
        *ngIf="instagramVideoFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramVideoLengthError}}"
        *ngIf="instagramVideoLengthError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramTooManyChars}}"
        *ngIf="postsForm.hasError('instagramTooManyChars')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramCanOnlyAcceptTenSelectedMediaWhenPostingToCarouselsError}}"
        *ngIf="postsForm.hasError('InstagramCanOnlyAcceptTenSelectedMediaWhenPostingToCarouselsError')"></error-alert>
      <!-- Aspect Ratio Error -->
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramMediaPortraitAspectRatioError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramMediaPortraitAspectRatioError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramMediaLandScapeAspectRatioError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramMediaLandScapeAspectRatioError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.InstagramMediaSquareAspectRatioError}}"
        *ngIf="_selectedIgPages.length > 0 && instagramMediaSquareAspectRatioError"></error-alert>
      <!-- Main Content -->
      <span class="title-sub-section"><i class="fab fa-instagram mr-3 instagram-icon"
          style="font-size: 30px;"></i>Instagram</span>
      <hr class="mt-1 mb-0">
      <div class="row mt-3 ml-0">
        <div class="col-md-6">
          <label for="name" class="d-block input-label ml-0">Message</label>
          <div class="emoji-text-container">
            <textarea type="text" class="text-area form-control" maxlength="2199" formControlName="instagramText">
            </textarea>
            <button #toggleEmojiInstagram class="emoji-button mt-2 mr-2" type="button" (click)="_isInstagramEmojiPickerVisible = !_isInstagramEmojiPickerVisible"><mdb-icon class="emoji-icon" far icon="smile"></mdb-icon></button>
          </div>
          <emoji-mart (emojiClick)="addEmojiToMessage($event, networkTypeEnum().Instagram)"
            [hidden]="!_isInstagramEmojiPickerVisible" class="emoji-mart-display"></emoji-mart>
        </div>
        <div class="col-md-6">
          <div class="row" style="margin-right: -7% !important;">
            <div class="col-md-11 mt-3">
              <div class="file-field md-form remove-vertical-margin justify-content-start">
                <a (click)="openMediaServerModal(networkTypeEnum().Instagram)" class="color-light-blue mb-3">+
                  Add Photo or Video from Media Server</a>
                <a placement="left" mdbPopover="Up to 9 media attachments (photo or video) can be attached to Instagram. Accepted
                file types are .jpg, .jpeg, .png, .mov and .mp4." triggers="hover" container="body">
                  <i class="fas fa-info-circle ml-2"></i>
                </a>
              </div>
              <div class="file-image-placeholders" *ngIf="this._instagramFiles">
                <div class="row">
                  <div class="col-md-4 mb-4" *ngFor="let file of this._instagramFiles">
                    <mdb-card
                      [ngStyle]="{'background-image': 'url('+(file.VideoThumbnailFileUrl ? file.VideoThumbnailFileUrl : this.mediaServerService.getFileImageIcon(file))+')' }"
                      class="card-image">
                      <a class="file-details-container">
                        <div class="file-details justify-content-center text-white text-center">
                          {{this.mediaServerService.getFileName(file.FileName)}}
                          <div class="row">
                            <button mdbBtn type="button" color="primary" class="waves-light file-details-button"
                              (click)="deleteFile(file, networkTypeEnum().Instagram)" mdbWavesEffect>
                              <mdb-icon fas icon="times">
                              </mdb-icon>
                            </button>
                          </div>
                        </div>
                      </a>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <!-- Add this row back again once we have first comment implemented -->
        <!-- <div class="row">
          <div class="col-md-6 mt-4" style="flex-basis: 49%;">
            <label class="input-label ml-0 custom-label" for="name">First Comment</label>
            <input type="text" maxlength="225" class="form-control" formControlName="instagramFirstComment" placeholder="Add your first comment">
          </div>
        </div> -->
        <!-- Implement this on phase 2 -->
        <!-- <div class="row">
          <div class="col-md-6 mt-4" style="flex-basis: 49%;">
            <label class="input-label ml-0 custom-label" for="name">Location</label>
            <input type="text" maxlength="225" class="form-control" formControlName="instagramLocation" placeholder="Add Location">
          </div>
        </div> -->
        <div class="row mt-4">
          <div [ngStyle]="{'visibility': !this._scheduleSeparately ? 'hidden': 'visible'}" class="col-md-3 col-lg-2">
            <label for="name" class="input-label ml-0">Select Date & Time</label>
            <input autocomplete="off" min="{{returnMinimumDate()}}" name="instagram-date-time"
              id="instagram-date-time-input" class="form-control align-bottom" type="datetime-local"
              formControlName="instagramDateTime" placeholder="----/--/-- --:--" (click)="this.pickerIsOpen(true)"
              (keydown)="this.pickerIsOpen(true)" (blur)="this.pickerIsOpen(false)" mdbInput>
          </div>
          <div class="col-md-9 col-lg-10 d-flex align-items-end justify-content-end">
            <button type="button" (click)="clearPosts(networkTypeEnum().Instagram)" class="btn-clear">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <!-- LinkedIn Post -->
    <div class="post-section" [hidden]="!displayNetworkSection(networkTypeEnum().LinkedIn)">
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyVideoFilesError}}"
        *ngIf="postsForm.hasError('linkedInTooManyVideosError')"></error-alert>
      <error-alert #errorAlert
        errorMessage="{{errorMessages.SocialMediaErrorMessages.TooManyPhotosVideosForLinkedInError}}"
        *ngIf="postsForm.hasError('linkedInTooManyPhotoVideosError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInFileTypeError}}"
        *ngIf="postsForm.hasError('linkedInFileTypeError')"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInImageFileSizeError}}"
        *ngIf="linkedInImageFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInVideoFileSizeError}}"
        *ngIf="linkedInVideoFileSizeError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInVideoLengthError}}"
        *ngIf="linkedInVideoLengthError"></error-alert>
      <error-alert #errorAlert errorMessage="{{errorMessages.SocialMediaErrorMessages.LinkedInTooManyChars}}"
        *ngIf="postsForm.hasError('linkedInTooManyChars')"></error-alert>
      <span class="title-sub-section"><i class="fab fa-linkedin mr-3 linkedin-icon"
          style="font-size: 30px;"></i>LinkedIn</span>
      <hr class="mt-1 mb-0">
      <div class="row mt-3 ml-0">
        <div class="col-md-6">
          <label for="name" class="d-block input-label ml-0">Message</label>
          <div class="emoji-text-container">
            <textarea type="text" class="text-area form-control" maxlength="2999" formControlName="linkedInText">
            </textarea>
            <div class="block">
              <button #toggleEmojiLinkedIn class="emoji-button mt-2 mr-2" type="button" (click)="_isLinkedinEmojiPickerVisible = !_isLinkedinEmojiPickerVisible"><mdb-icon class="emoji-icon" far icon="smile"></mdb-icon></button>
            </div>
          </div>
          <emoji-mart (emojiClick)="addEmojiToMessage($event, networkTypeEnum().LinkedIn)"
            [hidden]="!_isLinkedinEmojiPickerVisible" class="emoji-mart-display"></emoji-mart>
        </div>
        <div class="col-md-6">
          <div class="row" style="margin-right: -7% !important;">
            <div class="col-md-11 mt-3">
              <div class="file-field md-form remove-vertical-margin justify-content-start">
                <a (click)="openMediaServerModal(networkTypeEnum().LinkedIn)" class="color-light-blue mb-3">+
                  Add Photo or Video from Media Server</a>
                <a placement="left" mdbPopover="Up to 9 media attachments (photo or video) can be attached to LinkedIn. Accepted
                file types are .jpg, .jpeg, .png, .mov and .mp4." triggers="hover" container="body">
                  <i class="fas fa-info-circle ml-2"></i>
                </a>
              </div>
              <div class="file-image-placeholders" *ngIf="this._linkedInFiles">
                <div class="row">
                  <div class="col-md-4 mb-4" *ngFor="let file of this._linkedInFiles">
                    <mdb-card
                      [ngStyle]="{'background-image': 'url('+(file.VideoThumbnailFileUrl ? file.VideoThumbnailFileUrl : this.mediaServerService.getFileImageIcon(file))+')' }"
                      class="card-image">
                      <a class="file-details-container">
                        <div class="file-details justify-content-center text-white text-center">
                          {{this.mediaServerService.getFileName(file.FileName)}}
                          <div class="row">
                            <button mdbBtn type="button" color="primary" class="waves-light file-details-button"
                              (click)="deleteFile(file, networkTypeEnum().LinkedIn)" mdbWavesEffect>
                              <mdb-icon fas icon="times">
                              </mdb-icon>
                            </button>
                          </div>
                        </div>
                      </a>
                    </mdb-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row mt-4">
          <div [ngStyle]="{'visibility': !this._scheduleSeparately ? 'hidden': 'visible'}" class="col-md-3 col-lg-2">
            <label for="name" class="input-label ml-0">Select Date & Time</label>
            <input autocomplete="off" min="{{returnMinimumDate()}}" name="linkedIn-date-time"
              id="linkedIn-date-time-input" class="form-control align-bottom" type="datetime-local"
              formControlName="linkedInDateTime" placeholder="----/--/-- --:--" (click)="this.pickerIsOpen(true)"
              (keydown)="this.pickerIsOpen(true)" (blur)="this.pickerIsOpen(false)" mdbInput>
          </div>
          <div class="col-md-9 col-lg-10 d-flex align-items-end justify-content-end">
            <button type="button" (click)="clearPosts(networkTypeEnum().LinkedIn)" class="btn-clear">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Create Post -->
    <div class="post-section">
      <div class="container-fluid">
        <!-- Disabled Row (Please customise for each network) -->
        <div class="row"
          *ngIf="this._selectedNetworks.size > 1 && !this.postsForm.get('customiseForEachNetworkSelected').value">
          <div class="col-md-12 col-lg-9">
            <label class="input-label ml-0 custom-label" for="name">Link</label>
            <input type="text" maxlength="225" class="form-control" value="Please customise for each platform" disabled>
          </div>
          <div class="col-md-12 col-lg-3 d-flex align-items-end justify-content-end">
            <button type="button" class="btn-shrink" disabled>Shrink</button>
            <button type="button" class="btn-ga" (click)="this._gaSettings = !this._gaSettings">GA &#8595;</button>
          </div>
        </div>
        <!-- enabled row until condition is met -->
        <div class="row"
          *ngIf="(this._selectedNetworks.size == 1 && !this.postsForm.get('customiseForEachNetworkSelected').value) ||
          (this._selectedNetworks.size > 0 && this.postsForm.get('customiseForEachNetworkSelected').value) || this._selectedNetworks.size == 0">
          <div class="col-md-12 col-lg-9">
            <label class="input-label ml-0 custom-label" for="name">Link</label>
            <input type="text" maxlength="225" class="form-control" formControlName="link" placeholder="Link">
          </div>
          <div class="col-md-12 col-lg-3 d-flex align-items-end justify-content-end">
            <button type="button" class="btn-shrink"
              [disabled]="!this.postsForm.get('link').value || this._selectedNetworks.size == 0"
              (click)="shrinkLink()">Shrink</button>
            <button type="button" class="btn-ga" (click)="this._gaSettings = !this._gaSettings">GA &#8595;</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mt-2 mb-2">
            <div *ngIf="this._gaSettings" class="row mb-2 mt-2">
              <div class="col-md-12">
                <mdb-card borderColor="border-light">
                  <mdb-card-header class="bg-transparent border-light">
                    <mdb-card-title class="text-light">
                      <h5 class="ga-card-title"><strong>GA Settings</strong></h5>
                    </mdb-card-title>
                  </mdb-card-header>
                  <mdb-card-body>
                    <div class="row">
                      <div class="col-md-12 mb-2 mt-2">
                        <div class="text-left remove-top-margin">
                          <mat-checkbox [checked]="false" formControlName="removeUtm">Remove UTM</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="remove-vertical-margin">
                          <label for="name">Campaign Name</label>
                          <input type="text" id="campaign-name" class="form-control" formControlName="campaignName">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-3">
                        <div class="remove-bottom-margin">
                          <label for="name">Campaign Content</label>
                          <input type="text" id="campaign-content" class="form-control"
                            formControlName="campaignContent">
                        </div>
                      </div>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-3">
            <label for="name" class="input-label ml-0 custom-label">Status</label>
            <mdb-select formControlName="dualPostStatus" [options]="_statusOptions" [filterEnabled]="true"
              [visibleOptions]="10" placeholder="&lt;Select Status&gt;"
              class="custom-drop-down form-control select-input select-folder post-status">
            </mdb-select>
          </div>
          <div class="col-md-12 col-lg-9 d-flex align-items-end justify-content-end">
            <button *ngIf="!creatingEditingPost"
                    [disabled]="!postsForm.valid || fileValidatorErrors()"
                    (click)="createEditPost()"
                    class="btn-save">
              {{ isEditMode ? 'Save Post' : 'Schedule Posts' }}
            </button>
            <button *ngIf="creatingEditingPost" class="btn-save" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ isEditMode ? 'Saving' : 'Scheduling' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div mdbModal #mediaServerModal="mdbModal" class="modal fade right modal-overflow" tabindex="-1" role="dialog"
  aria-labelledby="mediaServerModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeMediaServer()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Select Files From Media Server</h4>
      </div>
      <div class="modal-body">
        <app-media-server *ngIf="_showMediaServer"></app-media-server>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="closeMediaServer()" mdbWavesEffect>Close</button>
        <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="applyImagesToPost()"
          mdbWavesEffect>OK!</button>
      </div>
    </div>
  </div>
</div>
