import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { AvailableDMSDto } from 'src/app/_models/admin/clients/available-dms-dto';
import { AvailableDMSDtoAdapter } from 'src/app/_models/admin/clients/available-dms-dto.adapter';
import { ClientETLsDto } from 'src/app/_models/admin/clients/client-etl-dto';
import { ClientETLsDtoAdapter } from 'src/app/_models/admin/clients/client-etl-dto.adapter';
import { ClientETLStatusChangeDto } from 'src/app/_models/admin/clients/client-etl-status-change-dto';
import { CreateETLDto } from 'src/app/_models/admin/clients/create-etl-dto';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { trigger, transition, style, animate } from '@angular/animations';
import ConstTableRowLimitOptions from 'src/app/views/shared/constants/app-constants';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { EnquiryMaxDealerInformationDtoAdapter } from 'src/app/_models/admin/clients/enquiry-max-dealer-information-dto-adapter';
import { EnquiryMaxUpdateDealerInformationDto } from 'src/app/_models/admin/clients/enquiry-max-update-dealer-information-dto';
import { EnquiryMaxDealerInformationDto } from 'src/app/_models/admin/clients/enquiry-max-dealer-information-dto';

@Component({
  selector: 'app-client-etls',
  templateUrl: './client-etls.component.html',
  styleUrls: ['./client-etls.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
            style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
            style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ClientEtlsComponent implements OnInit, AfterViewInit {
  @ViewChild('newETLModal') newETLModal: ModalDirective;
  @ViewChild('dealerLocationsModal') dealerLocationsModal: ModalDirective;

  _newETLForm: UntypedFormGroup;
  _dealerLocationsForm: UntypedFormGroup;

  _dealerWebSalesOptionSelected = false;
  _userPermissions: PermissionsModel;
  _availableETLsDropdown: Array<any>;
  _availableETLs: Array<AvailableDMSDto>;
  _columnMode = ColumnMode;
  _columns: DataTableColumn[] = [];
  _rows: Array<ClientETLsDto> = [];
  _loading = false;
  _migrationSelected = false;
  _dealerLocations: UntypedFormArray = new UntypedFormArray([], [Validators.required]);
  _selectedClientId = 0;

  // options for table row limit
  public pageLimitOptions = ConstTableRowLimitOptions;

  // variables for the table
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;

  get dealerLocations(): UntypedFormArray {
    return this._dealerLocationsForm.get('dealerLocations') as UntypedFormArray;
  }

  constructor(
    public clientManagementService: ClientManagementService,
    private enquiryMaxDealerInformationDtoAdapter: EnquiryMaxDealerInformationDtoAdapter,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private permissionsService: PermissionsService,
    private availableDMSDtoAdapter: AvailableDMSDtoAdapter,
    private clientETLsDtoAdapter: ClientETLsDtoAdapter,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this._userPermissions = this.permissionsService.getPermissionsModel();
    if (!this._userPermissions.Admin.Clients.View) {
      this.router.navigate(['dashboard']);
    }

    this._newETLForm = this.formBuilder.group ({
      DMS: new UntypedFormControl('', Validators.required),
      migration: new UntypedFormControl(false)
    });

    this._dealerLocationsForm = this.formBuilder.group ({
      dealerLocations: this.formBuilder.array(
        [],
        [Validators.required])
    });

    this.getAvailableDMSs();
    this.getRouteData();
  }

  ngAfterViewInit() {
    this.newETLModal.onHide.subscribe(() => {
      this._newETLForm.reset();
      this._newETLForm.controls.migration.setValue(false);
    });
  }

  //Create the data in the form for editing dealer locations of an EnquiryMax Sales type ETL only
  createDealerInfo(id: string, dealerId: string, description: string, location: string): UntypedFormGroup {
    return this.formBuilder.group({
      id,
      dealerId,
      description,
      location: new UntypedFormControl(location, [Validators.required])
    });
  }

  //Get the dealer locations of an EnquiryMax Sales type ETL only
  enquiryMaxGetDealerInformation(clientId: number) {

    this._selectedClientId = clientId;

    this.dealerLocations.clear();

    this.clientManagementService.enquiryMaxGetDealerInformation(clientId).subscribe((executionResultDto: ExecutionResultDto)  => {
      if (executionResultDto.executionResult === ExecutionResult.success) {

        const enquiryMaxDealerInformationDto = executionResultDto.data.map((x: EnquiryMaxDealerInformationDto ) =>
                                               this.enquiryMaxDealerInformationDtoAdapter.adapt(x));

        enquiryMaxDealerInformationDto.forEach(x => {
          this.dealerLocations.push(this.createDealerInfo(x.id, x.dealerId, x.dealerDescription, x.locationName));
        });

        this.dealerLocationsModal.show();

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  //Update the dealer locations of an EnquiryMax Sales type ETL only
  enquiryMaxUpdateDealerInformation() {

    const enquiryMaxUpdateDealerInformationDto = new EnquiryMaxUpdateDealerInformationDto({
        clientId: this._selectedClientId,
        ids: this._dealerLocationsForm.value.dealerLocations.map(x => x.id),
        dealerIds: this._dealerLocationsForm.value.dealerLocations.map(x => x.dealerId),
        locationNames: this._dealerLocationsForm.value.dealerLocations.map(x => x.location)
    });

    this.clientManagementService.enquiryMaxUpdateDealerInformation(enquiryMaxUpdateDealerInformationDto)
                                .subscribe((executionResultDto: ExecutionResultDto)  => {

      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });

    this.dealerLocationsModal.hide();
  }

  getAvailableDMSs() {
    this.clientManagementService.getAvailableDMSs().subscribe(result => {
      this._availableETLs = this.availableDMSDtoAdapter.adaptArray(result.data);
      this.populateDMSDropdown();
    });
  }

  // Do this after we have created / edited, we originally load this data in through a resolver.
  getClientETLs() {
    this.clientManagementService.getAllETLs(this.clientManagementService._currentClient.ClientID).subscribe(result => {
      const clientETLs: Array<ClientETLsDto> = this.clientETLsDtoAdapter.adaptArray(result.data);
      this.populateETLsTable(clientETLs);
    });
  }

  getDMSName(dmsID: number): string {
    const ETL: AvailableDMSDto = this._availableETLs.find(
      etl => etl.ID === dmsID);
    return ETL.DMS;
  }

  getDMSType(dmsID: number): string {
    const ETL: AvailableDMSDto = this._availableETLs.find(
      etl => etl.ID === dmsID);
    return ETL.Type;
  }

  getRouteData() {
    this.route.data.subscribe(data => {
      const clientETLs: Array<ClientETLsDto> = this.clientETLsDtoAdapter.adaptArray(data.clientETLs.data);
      if (clientETLs.length > 0) {
        this.populateETLsTable(clientETLs);
      }
    });
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  //currently, an ETL is only editable if it's an EnquiryMax Sales type
  isDealerWebSalesEtl(rowIndex: number) {
    const row = this._rows[rowIndex];
    return row.DMS.toLowerCase() === 'enquirymax' && row.Type.toLowerCase() === 'sales';
  }

  // give table pagination functionality
  // as the sql behind the data does not do this for us
  paginateTable(pageSize?: number, page?: number) {
    if(pageSize) this._pageLimit = pageSize;
    if(page) this._currentPage = page;
    this._pageOffset = this._currentPage - 1;
  }

  populateDMSDropdown() {
    this._availableETLsDropdown = [];
    this._availableETLs.forEach((ETL: AvailableDMSDto) => {
      this._availableETLsDropdown = [... this._availableETLsDropdown, {
        value: ETL.ID,
        label: ETL.DMS + ' (' + ETL.Type + ')',
      }];
    });
  }

  populateETLsTable(clientETLs: Array<ClientETLsDto>) {
    this._columns = [];
    this._rows = [];

    this._pageCount = clientETLs.length;
    this._rows =  clientETLs;
    const headers = Object.keys(this._rows[0]);
    headers.forEach(header => {
      const spacedHeader = header.replace(/([a-z])([A-Z])/g, '$1 $2');
      const headerObject = { name: spacedHeader, prop: header, visible: true };
      this._columns.push(headerObject);
    });
  }

  toggleETL(ETL: ClientETLsDto) {
    const changeDto = new ClientETLStatusChangeDto ({
      MDLClientDMSId: ETL.MDLClientDMSId,
      ClientId: ETL.ClientId,
      ActiveFlag: !ETL.ActiveFlag,
      UpdatedUserId: +this.localStorageService.getUserId,
    });
    this.clientManagementService.changeETLStatus(changeDto).subscribe(result => {
      this.getClientETLs();
      this.notificationService.showSuccess(result.message);
    });
  }

  submitNewETL() {
    this._loading = true;
    const newETL = new CreateETLDto ({
      ClientId: this.clientManagementService._currentClient.ClientID,
      DMS: this.getDMSName(this._newETLForm.controls.DMS.value),
      Type: this.getDMSType(this._newETLForm.controls.DMS.value),
      IsMigration: this._newETLForm.controls.migration.value,
    });
    this.clientManagementService.createETL(newETL).subscribe(result => {
      this._loading = false;
      this.getClientETLs();
      this.newETLModal.hide();
      this.notificationService.showSuccess(result.message);
    }, error => {
      console.log(error);
      this._loading = false;
      this.getClientETLs();
      this.newETLModal.hide();
    });
  }
}