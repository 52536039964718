export enum ListFieldTypeEnum {
    Email = 1,
    Mobile = 2,
    Phone = 3,
    Text = 4,
    Number = 5,
    Currency = 6,
    BigNumber = 7,
    Date = 8,
    Time = 9,
    'Date and Time' = 10,
    Boolean = 11
}
