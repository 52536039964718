export class DeleteFolderDtoRequest {

    sourceId: number;
    destinationId: number;

    constructor(init: { sourceId: number, destinationId: number }) {
        this.sourceId = init.sourceId,
        this.destinationId = init.destinationId;
    }
}
