import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EditContactPlanRequestDto
} from '../../../../_models/messaging/automation/contact-plans/edit-contact-plan-request-dto';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import {
  ContactPlanGridRecordDto
} from '../../../../_models/messaging/automation/contact-plans/contact-plan-grid-record-dto';
import { ContactPlanService } from '../../../../_services/messaging/automation/contact-plan.service';
import { NotificationService } from '../../../../_services/notification.service';

@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss']
})
export class UnsavedChangesModalComponent implements OnInit {
  title = 'Unsaved changes';
  action: string;

  constructor(    
    public dialogRef: MatDialogRef<UnsavedChangesModalComponent>   
  ) { }

  ngOnInit(): void {  
  }

  confirmLeavePage() {
    this.dialogRef.close(true);
  }
}
