import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateEditUserComponent } from './create-edit-user/create-edit-user.component';
import { UsersComponent } from './users/users.component';
import { UserDto } from 'src/app/_models/admin/users/user-model';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  @ViewChild('createEditUser') child: CreateEditUserComponent;
  @ViewChild('users') usersChild: UsersComponent;

  constructor() { }

  searchText: string;
  results: Observable<string[]>;
  component = 'users';
  user: UserDto;
  createUser: boolean;

  // variable for search data
  _pageTitle = 'User-Management';


  ngOnInit() {
  }

  setPage(changeComponent) {
    this.component = changeComponent;
    if (this.component === 'createEditUser') {
      this.createUser = true;
      if (this.child) {
        this.child.createUser = true;
        this.child.editUserState();
      }
    }
  }

  receivePageChange($event: string) {
    this.component = $event;
  }
  receiveComingFrom($event: boolean) {
    this.createUser = $event;
  }

  @HostListener('input') oninput() {
    if (this.usersChild) {
      this.usersChild.searchText = this.searchText;
      this.usersChild.searchItems();
    }
  }

}
