import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { ListFolderService } from 'src/app/_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { NotificationService } from 'src/app/_services/notification.service';
import { ListFolderModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model.adapter';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { LightListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model.adapter';
import { MessageFolderService } from 'src/app/_services/messaging/messages/message-folder.service';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { MessageService } from 'src/app/_services/messaging/messages/message.service';
import { LightweightMessageModel } from 'src/app/_models/messaging/messages/lightweight-message-model';
import { LightweightMessageModelAdapter } from 'src/app/_models/messaging/messages/lightweight-message-model.adapter';
import { CreateEditTriggerComponent } from '../create-edit-trigger.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-action-relates',
  templateUrl: './action-relates.component.html',
  styleUrls: ['./action-relates.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
            style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
            style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class ActionRelatesComponent implements OnInit {
  @Input() actionRelatesFormGroup: UntypedFormGroup;
  @Output() validationCheck = new EventEmitter();
  @Output() listChanged = new EventEmitter();
  @Output() messageChanged = new EventEmitter();

  _showListTypeTrigger: boolean = true;
  _listFolders: Array<ListFolderModel>;
  _listFolderDropdown: Array<any> = [];
  _lightLists: Array<LightListModel>;
  _lightListsDropdown: Array<any> = [];
  _messageFolderDropdown: Array<any> = [];
  _currentMessageType: MessageTypeEnum = MessageTypeEnum.email;
  _lightMessages: Array<LightweightMessageModel>;
  _lightMessageDropdown: Array<any>;

  constructor(
    public listFolderService: ListFolderService,
    public listsService: ListsService,
    public messageFolderService: MessageFolderService,
    public messageService: MessageService,
    public notificationService: NotificationService,
    public listFolderModelAdapter: ListFolderModelAdapter,
    public lightListModelAdapter: LightListModelAdapter,
    public messageFolderDtoAdapter: FolderDtoAdapter,
    public lightweightMessageModelAdapter: LightweightMessageModelAdapter,
    public createEditTriggerComponent: CreateEditTriggerComponent,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.onChanges();

    this.route.data.subscribe(data => {
      this._lightLists = this.lightListModelAdapter.adaptArray(data.lightLists.data);
      this.populateLightListsDropdown();
    });
  }

  populateMessageFolderDropdown(messageFolders: Array<FolderDto>) {
    this._messageFolderDropdown = [];
    messageFolders.forEach((messageFolder: FolderDto) => {
      this._messageFolderDropdown = [... this._messageFolderDropdown, {
        value: messageFolder.id,
        label: messageFolder.name
      }];
    });
  }

  MessageFolderSelected() {
    this.actionRelatesFormGroup.get('message').setValue(null);
  }

  GetLightMessages(folderID: number) {
    this.messageService.getLightMessages(folderID, this._currentMessageType).subscribe(result => {
      this._lightMessages = this.lightweightMessageModelAdapter.adaptArray(result.data);
      this.populateLightMessagesDropdown();
    }, error => {
      console.log(error);
    });
  }

  populateLightMessagesDropdown() {
    this._lightMessageDropdown = [];
    this._lightMessages.forEach((message: LightweightMessageModel) => {
      this._lightMessageDropdown = [ ... this._lightMessageDropdown, {
        value: message.MessageID,
        label: message.MessageName
      }];
    });
    if (this._lightMessageDropdown.length === 0) {
      this.onMessageChanged();
    }
  }

  populateListFoldersDropdown() {
    this._listFolderDropdown = [];
    this.createEditTriggerComponent._listFolders.forEach((listFolder: ListFolderModel) => {
      this._listFolderDropdown = [... this._listFolderDropdown, {
        value: listFolder.FolderID,
        label: listFolder.FolderName
      }];
    });
  }

  populateLightListsDropdown() {
    this._lightListsDropdown = [];
    var folderID = this.actionRelatesFormGroup.get('listFolder').value;
    if (folderID !== null) {
      this._lightLists.forEach((lightList: LightListModel) => {
        if (lightList.FolderId === folderID) {
          this._lightListsDropdown = [... this._lightListsDropdown, {
          value: lightList.ListId,
          label: lightList.ListName
        }];
        }
      });
    } else {
      this._lightLists.forEach((lightList: LightListModel) => {
        this._lightListsDropdown = [... this._lightListsDropdown, {
          value: lightList.ListId,
          label: lightList.ListName
        }];
      });
    }
  }

  onChanges() {
    this.actionRelatesFormGroup.get('triggerActionType').valueChanges.subscribe(val => {
      if (val === 'list') {
        this._showListTypeTrigger = true;
      } else {
        this._showListTypeTrigger = false;
      }
      this.setMessageValidation(val);
    });

    this.actionRelatesFormGroup.get('listFolder').valueChanges.subscribe(() => {
      this.actionRelatesFormGroup.get('list').setValue(null);
      this.populateLightListsDropdown();
    });

    this.actionRelatesFormGroup.get('list').valueChanges.subscribe(val => {
      this.listChanged.emit(val);
    });

    this.actionRelatesFormGroup.get('messageFolder').valueChanges.subscribe(val => {
      this.GetLightMessages(val);
    });

    this.actionRelatesFormGroup.get('messageType').valueChanges.subscribe(val => {
      this._currentMessageType = val;
      if (this.actionRelatesFormGroup.get('messageFolder').value != null) {
        const messageFolderID = this.actionRelatesFormGroup.get('messageFolder').value;
        this.GetLightMessages(messageFolderID);
      }
      this.actionRelatesFormGroup.get('message').setValue(null);
    });
  }

  setListFolder(listId: number) {
    const folderId = this._lightLists.find(x => x.ListId === listId).FolderId;
    this.actionRelatesFormGroup.get('listFolder').setValue(folderId);
  }

  setMessageValidation(val: string) {
    if (val === 'list') {
      this.actionRelatesFormGroup.get('message').clearValidators();
      this.actionRelatesFormGroup.get('message').updateValueAndValidity();
    } else {
      this.actionRelatesFormGroup.get('message').setValidators([Validators.required]);
      this.actionRelatesFormGroup.get('message').updateValueAndValidity();
    }
  }

  showValidationError(formControl: string) {
    if (this.actionRelatesFormGroup.get(formControl).invalid &&
        this.actionRelatesFormGroup.get(formControl).touched) {
          return true;
    } else {
      return false;
    }
  }

  onMessageChanged() {
    this.messageChanged.emit();
  }
}
