import { PermissionsPageTypes, PermissionsPageTypesAdapter } from './permissions-page-types-model';
import { Injectable } from '@angular/core';
import { Section, Page } from '../../admin/users/permissions-all-available-model';
import { PermissionsSocialPagesEnum } from './permissions-social-pages.enum';

export class PermissionsSocialPages {
  Networks : PermissionsPageTypes;
  NetworkGroups : PermissionsPageTypes;
  Posts : PermissionsPageTypes;
  Reports : PermissionsPageTypes;

  private _permissionsPageTypesAdapter : PermissionsPageTypesAdapter = new PermissionsPageTypesAdapter();

  constructor(section: Section) {
    section.pages.forEach((Page: Page) => {
      
      switch (Page.id) {
        case PermissionsSocialPagesEnum.Networks:
          this.Networks = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
          break;
        case PermissionsSocialPagesEnum.NetworkGroups:
            this.NetworkGroups = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
            break;
        case PermissionsSocialPagesEnum.Posts:
            this.Posts = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
            break;
        case PermissionsSocialPagesEnum.Reports:
            this.Reports = this._permissionsPageTypesAdapter.adapt(Page.permissionKey);
            break;
        default:
          console.warn("Unknown permission with Page name: " + Page.name);
          break;
      }
   });
  }
}

@Injectable({
  providedIn: 'root'
})

export class PermissionsSocialPagesAdapter {
  adapt(item: Section): PermissionsSocialPages {
    return new PermissionsSocialPages(item);
  }
}
