import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { UserManagementService } from 'src/app/_services/admin/users/user-management.service';
import { ClientAdapter } from 'src/app/_models/admin/clients/client-model';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { moveItemInList, moveItemToNewList } from 'mdb-sortable';
import { NotificationService } from 'src/app/_services/notification.service';
import { UserDto } from 'src/app/_models/admin/users/user-model';
import { NavigationService } from 'src/app/_services/system/Navigations/navigation.service';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { MediaServerService } from 'src/app/_services/media-server/media-server.service';
import { S3ObjectsModel } from 'src/app/_models/media-server/S3Models/S3Objects.model';


@Component({
  selector: 'app-client-create-edit',
  templateUrl: './client-create-edit.component.html',
  styleUrls: ['./client-create-edit.component.scss']
})
export class ClientCreateEditComponent implements OnInit, OnDestroy {
  _clientId: number;
  _sub: any;
  _client: any;
  _createEditClientForm: UntypedFormGroup;  
  _knownAs: string = '';  
  _users: UserDto[] = [];
  _accountManagersSelect: Array<any> = [];
  _model: any;
  _manageUsersAvailable: Array<any> = [];
  _manageUsersAssigned: Array<any> = [];
  _manageUsersAvailableUnfiltered: Array<any> = [];
  _manageUsersAssignedUnfiltered: Array<any> = [];
  _clientActive: boolean;
  _showMediaServer: boolean = false;
  _submitting: boolean = false;
  _changingActiveStatus: boolean = false;
  private _searchAvailableUsersText: string = '';
  private _searchAssignedUsersText: string = '';

  @ViewChild('mediaServerModal', { static: true }) mediaServerModal: ModalDirective;

  get searchAvailableUsersText() : string {
    return this._searchAvailableUsersText;
  }

  set searchAvailableUsersText(value: string) {
    this._searchAvailableUsersText = value;
    this._manageUsersAvailable = this.filterAvailableUsers(value);
  }

  get searchAssignedUsersText() : string {
    return this._searchAssignedUsersText;
  }

  set searchAssignedUsersText(value: string) {
    this._searchAssignedUsersText = value;
    this._manageUsersAssigned = this.filterAssignedUsers(value);
  }

  constructor(
    private route: ActivatedRoute,
    private clientManagementService: ClientManagementService,
    private userManagementService: UserManagementService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private clientAdapter: ClientAdapter,
    private router: Router,
    private navService: NavigationService,
    private localStorageService: LocalStorageService,
    public mediaServerService: MediaServerService
    ) {

    }

  ngOnInit() {
    this._sub = this.route.params.subscribe(params => {
      this._clientId = +params['clientId']; // FFR (+) converts string to a number
    });

    this.generateForm();

    if(this._clientId) {
      this.generateEditForm();
    } else {
      this.generateCreateForm();
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  generateEditForm() {

    this.clientManagementService.getClient(this._clientId).subscribe(data => {
      this._client = data;
      this._knownAs = this._client.KnownAs;      
      this._createEditClientForm.get('clientId').setValue(this._client.ClientID);
      this._createEditClientForm.get('name').setValue(this._client.Name);
      this._createEditClientForm.get('knownAs').setValue(this._client.KnownAs);
      this._createEditClientForm.get('websiteUrl').setValue(this._client.WebsiteUrl);
      this._createEditClientForm.get('accountManagerUserId').setValue(this._client.AccountManagerUserId);
      this._createEditClientForm.get('primaryContactEmail').setValue(this._client.PrimaryContactEmail);
      this._createEditClientForm.get('clientImageUrl').setValue(this._client.ClientImageUrl);

      this._clientActive = this._client.Active;

      //now generate user related functionality
      this.getClientUsers();
    });
  }

  generateCreateForm() {
    this.getClientUsers();
  }

  createEditClient() {
    if(this._createEditClientForm.valid) {
      this._model = Object.assign({}, this._createEditClientForm.value);
      if(this._model.clientId) {

        this._client.Name = this._model.name;
        this._client.KnownAs = this._model.knownAs;
        this._client.WebsiteUrl = this._model.websiteUrl;
        this._client.AccountManagerUserId = this._model.accountManagerUserId;
        this._client.PrimaryContactEmail = this._model.primaryContactEmail;
        this._client.ClientImageUrl = this._model.clientImageUrl;
        if(!this._changingActiveStatus) {
           this._submitting = true;
        }
        this.clientManagementService.updateClient(this._client).subscribe(data => {
          let a: any = data;
          this.notificationService.showSuccess(a.Message);
          this.navService.updateUserClients();
          this.router.navigate(['/client-management']);
        }, error => {
          this._submitting = false;
          this._changingActiveStatus = false;

        })

      } else {

        this._client = this.clientAdapter.adapt({
          Name: this._model.name,
          KnownAs: this._model.knownAs,
          WebsiteUrl: this._model.websiteUrl,
          AccountManagerUserId: this._model.accountManagerUserId,
          PrimaryContactEmail: this._model.primaryContactEmail,
          ClientImageUrl: this._model.clientImageUrl,
          DatabaseName: null,
          ClientID: 0,
          AmazonSettings: null,
          ClientSettings: null
        })


        this.clientManagementService.updateClient(this._client).subscribe(data => {
          let response: any = data;
          this.notificationService.showSuccess(response.Message);
          this.navService.updateUserClients();
          this.router.navigate(['/client-management']);


        });
      }
    }


  }

  generateForm() {
    this._createEditClientForm = this.formBuilder.group({
      clientId: [],
      name: ['', Validators.required],
      knownAs: ['', Validators.required],
      websiteUrl: ['',],
      accountManagerUserId: ['', Validators.required],
      primaryContactEmail: ['', [Validators.email, Validators.required]],
      clientImageUrl: ['']
    });
  }

  getClientUsers() {
    this._accountManagersSelect = [];
    this._manageUsersAvailable = [];
    this._manageUsersAssigned = [];
    let deleteIndexCount = 0;

    this.userManagementService.getUsers().subscribe(data => {
      this._users = data;

      this._users.forEach((user, index) => {
        this._accountManagersSelect = [...this._accountManagersSelect, {
          value: user.userId,
          label: user.firstName + ' ' + user.lastName,
          icon: user.userIconUrl
        }];

        this._manageUsersAvailable = [...this._manageUsersAvailable, {
          Username: user.username,
          Id: user.userId,
          Email: user.email,
          icon: user.userIconUrl,
          FullName: user.firstName + ' ' + user.lastName
        }];

        this._manageUsersAvailableUnfiltered = this._manageUsersAvailable;

        if(this._client)
        {
          this._client.Users.forEach((clientUser) => {
            if(user.userId == clientUser.Id)
            {
              this._manageUsersAssigned = [...this._manageUsersAssigned, {
                Username: user.username,
                Id: user.userId,
                Email: user.email,
                icon: user.userIconUrl,
                FullName: user.firstName + ' ' + user.lastName
              }];

              this._manageUsersAvailable.splice(index - deleteIndexCount, 1);
              deleteIndexCount++;
            }
          })
          this._manageUsersAssignedUnfiltered = this._manageUsersAssigned;
        }

      });
    });

  }

  saveManagedUsers() {
    this._client.Users = [];

    this._manageUsersAssigned.forEach((user) => {
      this._client.Users = [...this._client.Users, {
        Email: user.Email,
        Id: user.Id,
        Username: user.Username
      }]

    })
  }

  deactivateClient() {
    this._changingActiveStatus = true;
    this.clientManagementService.deactivateClient(this._clientId).subscribe(data => {
      let response: any = data;
      this.notificationService.showSuccess(response.Message);
      this.navService.updateUserClients();
      if(+this.localStorageService.getCurrentClientId == this._clientId){
        this.localStorageService.setCurrentClientId = this.localStorageService.getDefaultClientId
      }
      this.router.navigate(['/client-management']);
    }, error => {
      this._changingActiveStatus = false;
    })
  }

  activateClient() {
    this._client.Active = true;
    this._changingActiveStatus = true;
    this.createEditClient()

  }

  onDrop(event: any) {
    if (event.previousList === event.currentList) {
      moveItemInList(event.previousList.data, event.oldIndex, event.newIndex);
    } else {
      if (event.currentList.data == this._manageUsersAvailable){
        var movedUser = event.previousList.data[event.oldIndex];
        this._manageUsersAvailableUnfiltered.push(movedUser);
        var indexToDelete = this._manageUsersAssignedUnfiltered.indexOf(movedUser);
        if (indexToDelete !== -1){
          this._manageUsersAssignedUnfiltered.splice(indexToDelete, 1);
        } else {
          console.error("Not able to find user at assigned unfiltered index");
        }

      } else {
        var movedUser = event.previousList.data[event.oldIndex];
        this._manageUsersAssignedUnfiltered.push(movedUser);
        var indexToDelete = this._manageUsersAvailableUnfiltered.indexOf(movedUser);
        if (indexToDelete !== -1){
          this._manageUsersAvailableUnfiltered.splice(indexToDelete, 1);
        } else {
          console.error("Not able to find user at available unfiltered index");
        }

      }
      this._manageUsersAvailable = this.filterAvailableUsers(this.searchAvailableUsersText);
      this._manageUsersAssigned = this.filterAssignedUsers(this.searchAssignedUsersText);
    }

  }

  filterAvailableUsers(searchText: string) {
    this._manageUsersAvailable = this._manageUsersAvailableUnfiltered;
    return this._manageUsersAvailable.filter(user => {
      return user.FullName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });
  }

  filterAssignedUsers(searchText: string) {
    this._manageUsersAssigned = this._manageUsersAssignedUnfiltered;
    return this._manageUsersAssigned.filter(user => {
      return user.FullName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
    });
  }

  closeMediaServer() {
    this._showMediaServer = false;
    this.mediaServerService.selectedFiles = [];
    this.mediaServerModal.hide();
  }

  openMediaServerModal() {
    this._showMediaServer = true;
    this.mediaServerModal.show();
  }

  deleteClientLogo() {
    this._createEditClientForm.get('clientImageUrl').setValue('');
  }

  setClientImageUrl() {

    var selectedFile: S3ObjectsModel;

    this.mediaServerService.selectedFiles.forEach((file: S3ObjectsModel) => { 
      if (!selectedFile) {
        const extension = file.Key.substring(file.Key.lastIndexOf('.') + 1);
        switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            selectedFile = file;
            break;
          default:
              this.notificationService.showWarning('Not able to add file ' +
              file.Key.substring(file.Key.lastIndexOf('/') + 1, file.Key.length) +
              '. Images can only be of type png, jpg and jpeg');
              break;
        }
      }
    });

    if (selectedFile) {
      this._createEditClientForm.get('clientImageUrl').setValue(this.mediaServerService.getFilePublicUrl(selectedFile));
    }

    this._showMediaServer = false;
    this.mediaServerModal.hide();
  }
}
