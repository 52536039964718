import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { ClientDomain, ClientDomainAdapter } from 'src/app/_models/admin/clients/client-domains.model';
import { Router } from '@angular/router';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit {

  currentClient: ClientDto;
  _domains: ClientDomain [] = [];
  _domainForm: UntypedFormGroup;
  _domainToAdd: ClientDomain;
  _addingDomain = false;
  _removingDomain = false;

  constructor(
    private fb: UntypedFormBuilder,
    private clientManagementService: ClientManagementService,
    private route: Router,
    private clientDomainAdapter: ClientDomainAdapter,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.currentClient = this.clientManagementService.getCurrentClient();

    if (this.currentClient === undefined) {
      this.route.navigateByUrl('/client-management');
    }

    this.createDomainForm();

    if (this.currentClient.ClientSettings != null) {
      if (this.currentClient.ClientSettings.ClientDomains != null) {
        this.getDomains(this.currentClient);
      }
    }
  }

  createDomainForm() {
    var regEx = '[A-z].*\\.{1}[A-z][A-z]*'
    this._domainForm = this.fb.group({
      DomainName: ['', [Validators.required, Validators.pattern(regEx)]]
    });
  }

  addDomain() {
    this._addingDomain = true;
    this._domainToAdd = this.clientDomainAdapter.adapt({
      DomainName: this._domainForm.value.DomainName,
    });
    this.clientManagementService.addDomain(this._domainToAdd).subscribe(result => {
      const newDomainInfo: any = result;
      this._domainToAdd.DomainID = newDomainInfo.data;
      this._domains.push(this._domainToAdd);
      this.notificationService.showSuccess(newDomainInfo.message);
      this._addingDomain = false;
    }, result => {
      this._addingDomain = false;
      this.notificationService.showError(result.error.Result.Message);
    });
    this._domainForm.reset();
  }

  getDomains(currentClient) {
    this._domains = currentClient.ClientSettings.ClientDomains;
  }

  removeDomain(domainToDelete: ClientDomain) {
    this._removingDomain = true;
    this.clientManagementService.deleteDomain(domainToDelete).subscribe(result => {
      this._domains = this._domains.filter(domain => domain.DomainID !== domainToDelete.DomainID);
      this.notificationService.showSuccess(result.message);
      this._removingDomain = false;
    }, result => {
      this.notificationService.showError(result.message);
      this._removingDomain = false;
    });
  }
}
