import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { TableType } from '../constants/table-constants';

@Component({
  selector: 'app-message-to-copy-move',
  templateUrl: './message-to-copy-move.component.html',
  styleUrls: ['./message-to-copy-move.component.scss']
})
export class MessageToCopyMoveComponent implements OnInit {
  @Input() copyMoveList = [];
  @Input() isCopy = true;
  @Input() type: string;
  @Input() tableKey: string;

  @Output() copyMove: EventEmitter<void> = new EventEmitter<void>();
  @Output() arrayChanged: EventEmitter<UntypedFormArray> = new EventEmitter<UntypedFormArray>();

  copyMoveFormArray: UntypedFormArray;
  tableTypes = TableType;

  constructor(private elemRef: ElementRef) { }

  ngOnInit(): void {
    this.copyMoveFormArray = new UntypedFormArray([]);
    this.fillCopyMoveFormArray();
    this.copyMoveFormArray.valueChanges.subscribe(() => this.arrayChanged.emit(this.copyMoveFormArray));
  }

  fillCopyMoveFormArray(): void {
    this.copyMoveList.forEach((el) => {
      el.checked = false;
      this.copyMoveFormArray.push(new UntypedFormControl(el.messageName || el.title || el.ListName));
    });
  }

  showMessageNameInput(nameRow, i: number): void {
    nameRow.checked = !nameRow.checked;
    if (nameRow.checked) {
      const focusInput = () => this.elemRef.nativeElement.querySelector('#input' + i).focus();
      setTimeout(focusInput, 100);
    }
  }

  focusoutInputName(index: number): void {
    this.copyMoveList[index].checked = false;
  }

  enterSubmit(i): void {
    this.focusoutInputName(i);
    this.copyMove.emit();
  }
}
