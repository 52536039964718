export class FacebookInitializationVariablesDto {

  public appId: string;
  public version: string;

  constructor(init: any) {
    this.appId = init.AppId;
    this.version = init.Version;
  }
}
