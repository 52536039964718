import { Injectable } from '@angular/core';
import { HistoryViewDto } from './history-view-dto';

@Injectable({
  providedIn: 'root'
})

export class HistoryViewDtoAdapter {
  public adapt(init: any) {
    return init.Rows.map(row => new HistoryViewDto(row));
  }
}
