import { Injectable } from "@angular/core";
import { MessageSearchReplaceDto } from "./message-search-replace.dto";

@Injectable({
  providedIn: 'root'
})

export class MessageSearchReplaceDtoAdapter {
  public adapt(item: any): MessageSearchReplaceDto {
    return new MessageSearchReplaceDto(item);
  }
}
