
export class ListDtoModel {
  public ListId: number;
  public ListName: string;
  public FolderName: string;

  constructor(init: any) {
    this.ListId = init.ListId;
    this.ListName = init.ListName;
    this.FolderName = init.FolderName;
  }
}
