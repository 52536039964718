import { Injectable } from '@angular/core';
import { WebFormMessageModel } from './web-form-message-model';

@Injectable({
  providedIn: 'root'
})

export class WebFormMessageModelAdapter {
  adapt(item: any): WebFormMessageModel {
    return new WebFormMessageModel(item);
  }
}
