import { Injectable } from '@angular/core';
import { SMSRepliesModel } from './sms-replies-model';

@Injectable({
  providedIn: 'root'
})

export class SMSRepliesModelAdapter {
  adapt(item: any): SMSRepliesModel {
    return new SMSRepliesModel(item);
  }

  adaptArray(init: Array<any>): SMSRepliesModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new SMSRepliesModel(table))
      : [new SMSRepliesModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new SMSRepliesModel({
      SMSReplyID: 0,
      ReplyDate: '',
      SMSMessageBody: '',
      Number: 0,
    });
  }
}
