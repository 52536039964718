import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { PostsService } from '../../../../_services/social-media/posts/posts.service';
import { ItemListContent } from '../../../../_models/social-media/posts/post-content.model';
import { PostViewModel } from '../../../../_models/social-media/posts/post-view.model';

@Component({
  selector: 'app-posts-view',
  templateUrl: './posts-view.component.html',
  styleUrls: ['./posts-view.component.scss']
})
export class PostsViewComponent implements OnInit, OnChanges {
  @Input() headLabel: string;
  @Input() sizeOptionsFiltered: number[] = [];
  @Input() pageOffSet = 0;
  @Input() selectedNetworkType: number;
  @Input() selectedNetworkName: string;
  @Input() searched: boolean;
  @Input() posts: PostViewModel[];
  @Input() isReport: boolean;
  @Input() itemsCount = 0;
  @Output() getDataForTable: EventEmitter<boolean> = new EventEmitter<boolean>();

  private searchResults: ItemListContent[];
  private currentPage = 1;
  private adaptList: ItemListContent[] = [];
  private postInsightSizeOptions: number[] = [5, 10, 25, 50, 100];
  public filteredPosts: ItemListContent[];
  public isAscending = false;
  public pageLimit = 5;
  public searchForm = new UntypedFormGroup({
    textFilter: new UntypedFormControl('')
  });
  moment: any = moment;

  constructor(private postService: PostsService) { }

  ngOnInit() {
    this.sizeOptionsFiltered = this.filterPostInsightPageSize(this.itemsCount);
    this.orderPostsByDate(false);

    this.postService.resetPaginator().subscribe(() => {
      this.currentPage = 1;
      this.pageOffSet = 0;
    });

    this.searchForm.get('textFilter').valueChanges.subscribe(val => {
      if (val !== '') {
        this.pageOffSet = 0;
        this.filteredPosts = this.adaptList.filter((data) => {
          if (this.getText(data)) {
            return this.getText(data).toLowerCase().includes(val.toLowerCase());
          }
        });

        this.postInsightSizeOptions = this.filterPostInsightPageSize(this.itemsCount);
        this.searchResults = this.filteredPosts;
        this.searched = true;
      } else {
        this.searchResults = [];
        this.filteredPosts = this.adaptList;
        this.sizeOptionsFiltered = this.filterPostInsightPageSize(this.itemsCount);
        this.searched = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.listAdaption(changes.posts.currentValue, this.isReport);
    this.filteredPosts = this.adaptList;
  }

  getText(data): string {
    return data.Caption || data.Message || data.postText;
  }

  pageInsightPaginateTable(event?: any): void {
    this.itemsCount = event.length;
    this.pageLimit = event.pageSize;
    this.pageOffSet = +event.pageIndex;
    this.currentPage = +event.pageIndex + 1;
    this.getDataForTable.emit(event);

    if (event) {
      const currentPosts = this.searched ? this.searchResults : this.adaptList;
      this.filteredPosts =
        currentPosts.slice(event.pageIndex * event.pageSize, event.pageIndex * event.pageSize + event.pageSize);
    }
  }

  filterPostInsightPageSize(postSize: number): number[] {
    return this.postInsightSizeOptions.filter((v, i, a) => v <= postSize || a[i - 1] < postSize);
  }

  orderPostsByDate(isAscending: boolean): void {
    if (isAscending) {
      this.filteredPosts = this.adaptList.sort(
        (objA, objB) => new Date(this.postService.getDate(objA)).getTime() - new Date(this.postService.getDate(objB)).getTime()
      );
      this.isAscending = true;
    } else {
      this.filteredPosts = this.adaptList.sort(
        (objA, objB) => new Date(this.postService.getDate(objB)).getTime() - new Date(this.postService.getDate(objA)).getTime()
      );
      this.isAscending = false;
    }
  }

  listAdaption(data, isReport): void {
    this.adaptList = [];
    data.forEach((listItem) => {
      if (isReport) {
        this.adaptList.push(new ItemListContent(listItem, true));
      } else {
        this.adaptList.push(new ItemListContent([listItem]));
      }
    });
  }
}
