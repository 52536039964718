import { Component, Injector, OnInit } from '@angular/core';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ListFolderModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model.adapter';
import { ListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model.adapter';
import { ListTableModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-table-model.adapter';
import { CopyListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/copy-list-model.adapter';
import { MoveListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/move-list-model.adapter';
import { TableActionType, TableName } from '../../../shared/constants/table-constants';
import { MessagingAbstract } from '../../../../_models/messaging/messaging-abstract';
import { PermissionsPageTypes } from '../../../../_models/system/permissions/permissions-page-types-model';
import { CreateListModalComponent } from '../../../shared/modals/create-list/create-list-modal.component';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { ClearListModalComponent } from '../../../shared/modals/clear-list/clear-list-modal.component';
import { ListFolderService } from '../../../../_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { CopyMoveModalComponent } from '../../../shared/modals/copy-move/copy-move-modal.component';
import { PagerDtoRequest } from '../../../../_models/messaging/pager-dto-request';
import { DataTypesEnum } from 'src/app/_models/selected-fields-filters/data-types-enum';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
})
export class ListsComponent extends MessagingAbstract implements OnInit {
  displayedColumns = [
    { name: 'checkBox', displayName: '' },
    { name: 'ListID', displayName: 'List ID' },
    { name: 'ListName', displayName: 'List Name' },
    { name: 'ListType', displayName: 'List Type' },
    { name: 'FolderName', displayName: 'Folder Name' },
    { name: 'Comment', displayName: 'Comment' },
    { name: 'CreatedByID', displayName: 'Created By ID' },
    { name: 'UpdatedByID', displayName: 'Updated By ID' },
    { name: 'actions', displayName: '' }
  ];

  actionList = ['move', 'copy', 'clear', 'uploadNewList', 'uploadList', 'uploadHistory'];
  userPermissionsLists: PermissionsPageTypes;
  selectedFolderId = 0;
  dataType = DataTypesEnum.Lists;

  constructor(
    private listFolderModelAdapter: ListFolderModelAdapter,
    private listModelAdapter: ListModelAdapter,
    private listTableModelAdapter: ListTableModelAdapter,
    private copyListModelAdapter: CopyListModelAdapter,
    private moveListModelAdapter: MoveListModelAdapter,
    public listsService: ListsService,
    public listFolderService: ListFolderService,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'lists';
    this.init();
  }

  ngOnInit() {
    this.userPermissionsLists = this.permissions.ListsAndContactLists;
  }

  getLists(data): void {
    this.loadingSpinnerService.loading();
    const pagerDtoRequest = new PagerDtoRequest({
      page: data.currentPage,
      pageSize: data.pageLimit,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection
    });

    this.listsService.getLists(pagerDtoRequest, this.selectedFolderId).subscribe((executionResultDto: ExecutionResultDto)  => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.data = executionResultDto.data.Lists.map((list) => this.listModelAdapter.adapt(list));
        this.length = executionResultDto.data.Total;
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();
    }, () => {
      this.loadingSpinnerService.stopLoading();
    });
  }

  showCreateListModal(): void {
    const folders = this.messagingHeaderService.folders.filter(x => x.value > 0);
    const dialog = this.matDialog.open(CreateListModalComponent, {
      width: '500px',
      data: {
        selectedFolder: this.selectedFolderId,
        data: this.data,
        title: TableName[this.tableKey],
        tableKey: this.tableKey,
        folders
      }
    });

    dialog.afterClosed().subscribe(({ isNeedToUpdate, folderId }) => {
      if (isNeedToUpdate) {
        this.messagingHeaderService.setFolder(folderId);
        this.tableService.requestTableData();
      }
    });
  }

  actionHandler({ selectedRows, action, extraOptions }): void {
    switch (TableActionType[action]) {
      case TableActionType.copy:
      case TableActionType.move:
        const data = {
          modal: CopyMoveModalComponent,
          dataService: this.listsService,
          isCopy: TableActionType[action] === TableActionType.copy,
          tableKey: this.tableKey,
          selectedRows
        };

        this.copyMove(data);
        break;
      case TableActionType.clear:
        this.openModal(ClearListModalComponent, {
          width: '500px',
          data: selectedRows[0]
        });
        break;
      case TableActionType.delete:
        this.deleteRestoreRow(selectedRows, extraOptions);
        break;
      case TableActionType.uploadNewList:
        this.router.navigate([
          'contacts/',
          { folder: this.selectedFolderId, newList: true }
        ]);
        break;
      case TableActionType.uploadList:
        this.router.navigate([
          'contacts/',
          { folder: selectedRows[0].FolderID, list: selectedRows[0].ListID, newList: false }
        ]);
        break;
      case TableActionType.uploadHistory:
        this.router.navigate(['/contacts-upload-history']);
        break;
      case TableActionType.editForms:
        this.router.navigate(['lists/edit-forms', selectedRows.ListID]);
        break;
      case TableActionType.edit:
        this.router.navigate(['lists/edit-list', selectedRows.ListID]);
        break;
      case TableActionType.listContacts:
        this.router.navigate(['contacts/', { folder: selectedRows.FolderID, list: selectedRows.ListID }]);
        break;
    }
  }
}
