export class ItemListContent {
  noImage = '../../../../../assets/images/noImageFound.png';
  username: string;
  createdDate: string;
  postText: string;
  imageUrl: string;  
  userId: number;
  postStatus: number;
  postDetails = [];

  constructor(data, isReport?) {
    const itemData = isReport ? data : data[0];
    this.username = this.getUserName(itemData);
    this.createdDate = this.getDate(itemData);
    this.postText = this.getText(itemData);
    this.imageUrl = this.getImgUrl(itemData);    
    this.userId = itemData.CreatedByID || 0;
    this.postStatus = itemData.Status;    

    if (isReport) {
      this.postDetails = [new PostDetails(data)];
    } else {
      data.forEach((post) => {
        post.NetworksCombined.forEach((network) => {
          this.postDetails = [...this.postDetails, new PostDetails(network, post)];
        });
      });
    }
  }

  getUserName(post): string {
    return post.Author || post.Username || '';
  }

  getImgUrl(post): string {
    return post.FullPicture || post.MediaUrl || post.ImageUrl || 
           post.PostAttachments?.[0]?.VideoThumbnailFileUrl || post.PostAttachments?.[0]?.FileUrl || this.noImage;
  }

  getText(post): string {
    return post.Message || post.Caption || post.PostText;
  }

  getDate(post): string {
    return post.CreatedTime || post.Timestamp || post.PostDate || post.CreatedDate || '';
  }
}

export class PostDetails {
  noImage = '../../../../../assets/images/noImageFound.png';
  postText: string;
  date: string;
  postAttachments: [];
  postId: number;
  username: string;
  networkType: number;
  status: number;
  networkLink: string;
  albumName: string;
  networkDetailId: number;
  postDate: string;
  postLink: string;
  postLinkId: number;
  imageUrl: string;

  firstColumn: number;
  secondColumn: number;
  thirdColumn: number;
  fourthColumn: number;
  fifthColumn: number;
  sixColumn: number;

  constructor(network, post?) {
    if (post) {
      this.postId = post.PostID || 0;
      this.networkType = network.Type;
      this.username = network.Username || network.Name || '';
      this.networkDetailId = network.NetworkDetailID;
      this.date = post.CreateDate;
      this.postText = post.PostText;
      this.status = post.Status;
      this.networkLink = network.Link;
      this.postLink = post.Link;
      this.postLinkId = post.LinkID;
      this.albumName = post.AlbumName;
      this.postAttachments = post.PostAttachments;
      this.imageUrl = this.getImgUrl(post);
      this.postDate = post.PostDate || '';
    }

    this.firstColumn = network.NumberOfImpressions || network.Impressions || network.TWNumberOfImpressions || 0;
    this.secondColumn = network.Reach || network.NumberOfLinkClicks ||  network.TWNumberOfReTweets || 0;
    this.thirdColumn = network.NumberOfLikes || network.NumberOfFavourites || network.NumberOfClicks || network.TWNumberOfFavourites || 0;
    this.fourthColumn = network.NumberOfReactions || network.LNNumberOfLikes || network.IgNumberOfComments || network.TWNumberOfReplies ||  0;
    this.fifthColumn = network.NumberOfReplies || network.Saved || network.LNNumberOfComments || network.NumberOfComments || network.TWNumberOfProfileViews || 0;
    this.sixColumn = network.NumberOfShares || network.NumberOfProfileViews || network.VideoViews || 0;
  }

  getImgUrl(post): string {
    return post.FullPicture || post.MediaUrl || post.ImageUrl || 
           post.PostAttachments?.[0]?.VideoThumbnailFileUrl || post.PostAttachments?.[0]?.FileUrl || this.noImage;
  }
}
