import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PermissionsPageTypes } from '../../../_models/system/permissions/permissions-page-types-model';
import { Observable, Subscription } from 'rxjs';
import { UnsubscribeService } from '../../../_services/system/unsubscribe.service';
import { PaginationDataService } from '../../../_services/tables/pagination-data/pagination-data.service';
import { TableName, TableType } from '../constants/table-constants';
import { PermissionsService } from '../../../_services/system/Permissions/permissions.service';
import { MessagingHeaderService } from '../../../_services/messaging/messaging-header/messaging-header.service';
import { SelectListOption } from '../../../_models/system/select-list-option';
import { ExecutionResultDto } from '../../../_models/execution-result-model';
import { ExecutionResult } from '../../../_models/execution-result-enum';
import { FolderDto } from '../../../_models/messaging/messages/folder-dto';
import { FolderDtoAdapter } from '../../../_models/messaging/messages/folder-dto.adapter';
import { NotificationService } from '../../../_services/notification.service';
import { LoadingSpinnerService } from '../../../_services/loading-spinner/loading-spinner.service';
import { CreateEditFolderModalComponent } from '../modals/create-edit-folder/create-edit-folder-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FolderDtoRequest } from '../../../_models/messaging/messages/folder-dto-request';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { DeleteFolderModalComponent } from '../modals/delete-folder/delete-folder-modal.component';
import { DeleteFolderDtoRequest } from '../../../_models/messaging/messages/delete-folder-dto-request';
import { ListModel } from '../../../_models/messaging/lists-and-contacts/lists/list-model';
import { ListsService } from '../../../_services/messaging/lists-and-contacts/lists/lists.service';
import { ListModelAdapter } from '../../../_models/messaging/lists-and-contacts/lists/list-model.adapter';

@Component({
  selector: 'app-messaging-header',
  templateUrl: './messaging-header.component.html',
  styleUrls: ['./messaging-header.component.scss']
})
export class MessagingHeaderComponent implements OnInit, OnDestroy {
  @Input() permission: PermissionsPageTypes;
  @Input() listsService: ListsService;
  @Input() tableKey: string;
  @Input() folderService;
  @Input() isFolderEmpty: boolean;
  @Input() showPlaceholder: boolean;
  @Input() disableActionButton: boolean;
  @Input() disableSelect: boolean;
  @Input() snapshot;

  @Output() folderChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() listChanged: EventEmitter<{ listId: number, lists: SelectListOption[] }> = new EventEmitter<{ listId: number, lists: SelectListOption[] }>();
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  configuratorFormGroup: FormGroup;
  selectsSubscription: Subscription;
  changeFolderSubscription: Subscription;
  changeListSubscription: Subscription;
  setFolderSubscription: Subscription;
  setListSubscription: Subscription;
  getFolderSubscription: Subscription;
  getListsSubscription: Subscription;
  smsPermission: PermissionsPageTypes;
  folderOptions: SelectListOption[];
  listOptions: SelectListOption[];
  lists: ListModel[] = [];
  tableType = TableType;
  listPlaceholder = '<Select List>';
  selectedFolderId: number;
  selectedListId: number | string;
  optionsName: string;
  actionName: string;
  buttonStyle: string;

  constructor(
    private paginationDataService: PaginationDataService,
    private permissionsService: PermissionsService,
    private folderDtoAdapter: FolderDtoAdapter,
    private messagingHeaderService: MessagingHeaderService,
    private notificationService: NotificationService,
    private loadingSpinnerService: LoadingSpinnerService,
    private listModelAdapter: ListModelAdapter,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.optionsName = TableName[this.tableKey] + ' Options';
    this.actionName = TableName[this.tableKey].slice(0, -1);
    this.buttonStyle = this.tableKey.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().replace(' ', '-');

    if (TableType[this.tableKey] === TableType.messages) {
      this.smsPermission = this.permissionsService.getSmsPermission();
    }

    this.initForms();
    this.populateFoldersDropdown();
  }

  initForms(): void {
    this.configuratorFormGroup = new UntypedFormGroup({
      folder: new UntypedFormControl(
        ''
      ),
    });

    this.changeFolderSubscription = this.configuratorFormGroup.get('folder').valueChanges
      .pipe(
        throttleTime(500)
      ).subscribe((id: number) => {
        this.loadingSpinnerService.loading();
        this.selectedFolderId = id;
        this.listOptions = [];
        if (this.configuratorFormGroup.get('list')) { this.populateListsDropdown(); }
        this.folderChanged.emit(id);
      });

    this.setFolderSubscription = this.messagingHeaderService.selectingFolder().subscribe((id) => {
      this.configuratorFormGroup.get('folder').setValue(id);
    });

    if (TableType.contacts === TableType[this.tableKey]) {
      this.configuratorFormGroup.addControl('list', new UntypedFormControl(''));

      this.changeListSubscription = this.configuratorFormGroup.get('list').valueChanges
        .pipe(
          distinctUntilChanged(),
          throttleTime(500)
        ).subscribe((id: number) => {
          this.selectedListId = id;
          this.listChanged.emit({ listId: id, lists: this.listOptions });
        });
    }
  }

  populateListsDropdown(): void {
    this.getListsSubscription = this.listsService.getByFolderId(this.selectedFolderId)
      .subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          const listModels = executionResultDto.data.map((listModel: ListModel) => this.listModelAdapter.adapt(listModel));
          this.listOptions = listModels.map((listModel: ListModel) => new SelectListOption(listModel.ListID, listModel.ListName));
          this.messagingHeaderService.lists = [...this.listOptions];
          const initList = +this.snapshot?.list || this.paginationDataService.getListId();
          this.selectedListId = this.listOptions.some((option) => option.value === initList) ? initList : 0;
          this.configuratorFormGroup.get('list').setValue(this.selectedListId || 0);
          this.disableSelect = !this.listOptions.length;
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
  }

  populateFoldersDropdown(id?: number): void {
    this.getFolderSubscription = this.folderService.getAll().subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const listFolderDtos = executionResultDto.data
          .map((messageFolderDto: FolderDto) => this.folderDtoAdapter.adapt(messageFolderDto));

        this.folderOptions = [];

        if (TableType.contacts !== TableType[this.tableKey]) {
          this.folderOptions.push(new SelectListOption(0, '<All>'));
        }

        this.folderOptions = this.folderOptions
          .concat(
            listFolderDtos
              .map((messageFolderDto: FolderDto) => new SelectListOption(messageFolderDto.id, messageFolderDto.name))
          );

        this.messagingHeaderService.folders = [...this.folderOptions];
        const initFolder = id || +this.snapshot?.folder || this.paginationDataService.getFolderId();
        this.selectedFolderId = initFolder || 0;
        this.configuratorFormGroup.get('folder').setValue(this.selectedFolderId);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();
    });
  }

  showCreateFolderModal(isCreate = true): void {
    const name = this.folderOptions.find(x => x.value === this.selectedFolderId).label;
    const dialog = this.matDialog.open(CreateEditFolderModalComponent, {
      data: {
        name: isCreate ? '' : name,
        tableKey: this.tableKey
      }
    });

    dialog.afterClosed().subscribe(({ newName }) => {
      if (newName) {
        this.createUpdateFolderSubmit(newName, isCreate);
      }
    });
  }

  createUpdateFolderSubmit(newName: string, isCreate: boolean): void {
    this.loadingSpinnerService.loading();
    const folderDto = new FolderDtoRequest({
      id: isCreate ? 0 : this.selectedFolderId,
      name: newName
    });

    if (this.folderOptions.some(x => x.label.toLowerCase() === newName.toLowerCase())) {
      this.notificationService.showWarning(`Folder ${newName} already exists. Folder names must be unique`);
      this.loadingSpinnerService.stopLoading();
      return;
    }

    this.folderService.createUpdate(folderDto).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.selectedFolderId = executionResultDto.data as number;
        this.populateFoldersDropdown(this.selectedFolderId);
        this.loadingSpinnerService.stopLoading();
      } else {
        this.loadingSpinnerService.stopLoading();
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  showDeleteFolderModal(): void {
    const name = this.folderOptions.find(x => x.value === this.selectedFolderId).label;
    const destinationFolders = this.folderOptions.filter(x => x.value > 0 && x.value !== this.selectedFolderId);
    const dialog = this.matDialog.open(DeleteFolderModalComponent, {
      width: '500px',
      data: {
        isFolderEmpty: this.isFolderEmpty,
        tableKey: this.tableKey,
        folders: destinationFolders,
        selectedFolder: name,
      }
    });

    dialog.afterClosed().subscribe((data) => {
      if (data?.needToDelete) {
        this.deleteFolderSubmit(data.destinationFolder);
      }
    });
  }

  deleteFolderSubmit(destinationFolderId: number): void {
    this.loadingSpinnerService.loading();
    let request: Observable<ExecutionResultDto>;
    if (destinationFolderId) {
      const deleteFolderDtoRequest = new DeleteFolderDtoRequest({
        sourceId: this.selectedFolderId,
        destinationId: destinationFolderId
      });

      request = this.folderService.updateDelete(deleteFolderDtoRequest);
    } else {
      request = this.folderService.delete(this.selectedFolderId);
    }

    request.subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.folderOptions = this.folderOptions.filter(x => x.value !== this.selectedFolderId);
        this.selectedFolderId = !this.isFolderEmpty ? destinationFolderId : 0;
        this.populateFoldersDropdown();
        this.messagingHeaderService.setFolder(this.selectedFolderId);
        this.loadingSpinnerService.stopLoading();
      } else {
        this.loadingSpinnerService.stopLoading();
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  ngOnDestroy(): void {
    UnsubscribeService.unsubscribe(
      this.selectsSubscription,
      this.changeFolderSubscription,
      this.setFolderSubscription,
      this.getListsSubscription,
      this.setFolderSubscription,
      this.changeListSubscription,
      this.setListSubscription
    );
  }
}
