import { Injectable } from '@angular/core';
import { MoveListModel } from './move-list-model';

@Injectable({
  providedIn: 'root'
})

export class MoveListModelAdapter {
  adapt(item: any): MoveListModel {
    return new MoveListModel(item);
  }
  createEmpty() {
    return new MoveListModel({
      DestinationFolderId: 0,
      ListIds: [],
    });
  }
}
