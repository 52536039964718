import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { Router } from '@angular/router';
import { ResetPasswordDto } from 'src/app/_models/admin/reset-password/reset-password-model';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  _forgotPasswordRequestForm: UntypedFormGroup;
  _resetPasswordForm: UntypedFormGroup;
  _verifying: boolean = false;
  _resetting: boolean = false;
  _requestSent = false;
  _username: string;
  _resetCodeRequestResult: string;
  _resetRequestResult: string;
  _model: any = {};
  _resetPasswordModel: ResetPasswordDto = new ResetPasswordDto;
  _apiResult: any;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  // Still need to add in functionality where a token can be decoded
  // and set to being valid so the page changes to resetting a password.
  // Check the routes file as routing is used to pass the Token.

  ngOnInit() {
    this.createForgotPasswordRequestForm();
    this.createResetPasswordForm();
  }

  createForgotPasswordRequestForm() {
    this._forgotPasswordRequestForm = this.fb.group({
      username: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  createResetPasswordForm() {
    this._resetPasswordForm = this.fb.group({
      validate: ['', Validators.required],
      password: ['',
        [Validators.required, Validators.minLength(8), this.passwordStrengthValidator]],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }

  // request a reset password code from Cognito
  // as Cognito requires a code in order to allow this to happen
  async resetRequest(username: string) {
    try {
      this._verifying = true;
      await this.authService.awsForgotPasswordRequest(username).then(result => this._resetCodeRequestResult = result);
      if (this._resetCodeRequestResult === 'true') {
        // set variable so that we can use the username to finish resetting the password 
        this._username = username;
        this._requestSent = true;
        this.notificationService.showSuccess("Reset verification code has been sent to your email address");
      }
      else {
        this.notificationService.showError('Error sending reset request: ' + this._resetCodeRequestResult + '.  Please contact an administrator.');
        this._requestSent = false;
        this._verifying = false;
      }
    } catch (error) {
      console.log('Error sending reset request', error);
      this.notificationService.showError('Error sending reset request - please contact an administrator.');
      this._requestSent = false;
      this._verifying = false;
    }
  }

  // Attempt to reset the password both in Cognito and Voicebox
  async resetPassword(code: string, newPassword: string) {
    try {
      this._resetPasswordModel.newPassword = newPassword;
      this._resetPasswordModel.userName = this._username;
      this._resetting = true;
      await this.authService.awsForgotPassword(this._username, code, newPassword).then(result => this._resetRequestResult = result)
      await this.authService.passwordReset(this._resetPasswordModel).then(r => this._apiResult = r);
      // if both passwords have been reset successfully, inform the user and go back to login
      if (this._resetRequestResult === 'true' && this._apiResult.Message === 'Password changed successfully.') {
        this.notificationService.showSuccess('Password reset successfully!');
        this.router.navigate(['/login']);
      }
      // if not, inform the user
      else {
        this.notificationService.showError('Error sending reset request: ' + this._resetRequestResult + '.  Please contact an administrator.');
        this._requestSent = false;
        this._resetting = false;
      }
    } catch (error) {
      // catch any errors and inform the user
      console.log('Error sending reset request', error);
      this.notificationService.showError('Error resetting password - please contact an administrator.');
      this._requestSent = false;
      this._resetting = false;
    }
  }

  // ensure that the new password and confirm password fields both match
  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value
      ? null : { 'mismatch': true };
  }

  // ensure the password entered is strong enough
  passwordStrengthValidator(g: UntypedFormGroup) {
    let hasNumber = /\d/.test(g.value);
    let hasUpper = /[A-Z]/.test(g.value);
    let hasLower = /[a-z]/.test(g.value);
    let hasSpecial = /[`!@#$%^&*()_\\[\]{};':"\\|,.<>\/?~]/.test(g.value);

    const valid = hasNumber && hasUpper && hasLower && hasSpecial;

    if (!valid) return { 'weak': true };
    return null;
  }
}
