<app-page-container [title]="title">
  <app-block-container [title]="optionsName">
    <div class="d-flex flex-row pt-2">
      <form [formGroup]="schemaTablesFormGroup">
        <div class="row">
          <div class="col dms-dropdown-width-margin">            
            <mdb-select
                [options]="listOfDMSs"
                [filterEnabled]="true"
                [visibleOptions]="10"
                label="Select DMS"
                formControlName="schemas"
                (selected)="onDmsSelected($event)">
            </mdb-select>
          </div>
          <div class="col types-dropdown-width-margin">
            <mdb-select
                [options]="listOfTypes"
                [filterEnabled]="true"
                [visibleOptions]="10"
                label="Select Type"
                formControlName="types"
                (selected)="onTypeSelected($event)">
            </mdb-select>
          </div>
        </div>   
      </form>
    </div>    
  </app-block-container>

  <div class="audit-trail-table-section">
    <div class="d-flex align-items-center">
      <div class="mr-auto">
        <span class="title-table-section"></span>
      </div>
      <!-- Table Header -->
      <button *ngIf="this.filterInteractionRules.length === 0" mdbBtn
        size="lg" type="button" class="header-button table-button no-box-shadow mr-4"
        (click)="filterInteractionsModal.show()">
        <mdb-icon fas size="lg" class="blue-icon" icon="search"></mdb-icon>
      </button>
      <button *ngIf="this.filterInteractionRules.length > 0" mdbBtn
        size="lg" type="button" mdbTooltip="An active filter is set, click here to clear or edit it."
        placement="top" class="header-button table-button no-box-shadow mr-4"
        (click)="filterInteractionsModal.show()">
        <mdb-icon fas size="lg" class="orange-icon" icon="search"></mdb-icon>
      </button>
      <button *ngIf="!exporting" [disabled]="!tableHasRows" mdbBtn
        size="lg" type="button" class="header-button table-button no-box-shadow mr-2" (click)="export()">
        <mdb-icon fas size="lg" class="blue-icon" icon="file-download"></mdb-icon>
      </button>
      <button *ngIf="exporting" disabled mdbBtn
        class="header-button table-button no-box-shadow mr-2">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
    <hr class="hr-reports">
    <!-- Table -->
    <div *ngIf="tableHasRows" class="mat-table-container">
      <ngx-datatable
              class="audit-trail-table"
              [columnMode]="columnMode.force"
              [headerHeight]="40"
              [footerHeight]="0"
              [rowHeight]="50"
              [scrollbarV]="false"
              [scrollbarH]="true"
              [rows]="rows"
              [count]="pageCount"
              [offset]="pageOffset"
              [limit]="pageLimit">

              <ngx-datatable-column
              *ngFor="let col of columns"
              [width]="150"
              [headerClass]="'lists-table-headers'"
              [name]="col.name"
              [prop]="col.prop">
              </ngx-datatable-column>
      </ngx-datatable>
                <mat-paginator [hidden]="!tableHasRows"
                               [length]="pageCount"
                               [pageSize]="pageLimit"
                               [pageSizeOptions]="pageSizeOptions"
                               [pageIndex]="pageOffset"
                               (page)="paginateTable($event)"
                               class="mat-paginator">
                </mat-paginator>
          </div>
          <div *ngIf="!tableHasRows">
            <span class="label-no-data">No data to display.</span>
          </div>
      </div>
  
  <div mdbModal #filterInteractionsModal class="modal fade modal-overflow" tabindex="-1" role="dialog"
    aria-labelledby="filterInteractionsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content rounded-lg">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="filterInteractionsModal.hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="filterInteractionsModalLabel">Filter Interactions</h4>
        </div>
  
        <div class="modal-body">
          <app-query-builder #queryBuilder [(_parentQuery)]="this.filterInteractionRules"
            [_parentSource]="'interactions'" [(_parentListID)]="this.selectedListId"
            (queryBuilderSave)="interactionsFilterSave($event)"
            [(_parentPageTitle)]="this.pageTitle">
          </app-query-builder>
        </div>
      </div>
    </div>
  </div>

</app-page-container>