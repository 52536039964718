<div class="row m-4">
    <div class="col-md-12">
      <!--Card-->
      <mdb-card cascade="true" narrower="true" class="mt-5">

        <!--Card image-->
        <div class="view view-cascade gradient-card-header primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center">
            <h4 class="page-title">User Management</h4>
        </div>
        <!--/Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="text-center">

          <section>

            <form class="form-inline md-form form-sm" autocomplete="off">

                <div class="container-fluid">
                    <!-- Grid Row -->
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-4">
                            <div class="md-form text-left" *ngIf="this.component === 'users'">
                                <mdb-icon fas icon="search" class="prefix icon-color" size="sm" aria-hidden="true"></mdb-icon>
                                <input type="text"
                                [(ngModel)]="this.searchText"
                                [ngModelOptions]="{standalone: true}"
                                id="userManagement-search"
                                class="form-control"
                                mdbInput>
                                <label for="userManagement-search" class="">Search</label>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>

                        <div class="col-md-6">
                            <div class="md-form">
                                <div class="btn-group btn-group-sm rounded" role="group">
                                    <button mdbBtn type="button" color="secondary" class="waves-light" (click) = "setPage('createEditUser')" mdbWavesEffect>Create User</button>
                                    <button mdbBtn type="button" color="secondary" class="waves-light" (click) = "setPage('users')" mdbWavesEffect>Users</button>
                                    <button mdbBtn type="button" color="secondary" class="waves-light" mdbWavesEffect>Login History</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/.Grid Row-->
              </div>

            </form>

          </section>

        </mdb-card-body>
        <!--/.Card content-->
      </mdb-card>
      <!--/.Card-->
    </div>
  </div>

  <app-create-edit-user
    *ngIf="component === 'createEditUser'"
    [createUser]="createUser"
    (userCreatedEvent)="receivePageChange($event)"
    #createEditUser>
  </app-create-edit-user>
  <app-users
    *ngIf="component === 'users'"
    (toEditUserEvent)="receivePageChange($event)"
    (comingFromEvent)="receiveComingFrom($event)"
    #users>
  </app-users>
