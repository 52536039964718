import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NetworkGroupDto } from 'src/app/_models/social-media/network-groups/network-group-dto';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { NetworkTypeEnum } from 'src/app/_models/social-media/posts/network-type-enum';

@Component({
  selector: 'app-network-group-card',
  templateUrl: './network-group-card.component.html',
  styleUrls: ['./network-group-card.component.scss']
})
export class NetworkGroupCardComponent {
  @Input() networkGroup: NetworkGroupDto;
  @Input() userPermissions: PermissionsModel;
  @Output() deleteNetworkGroupEvent = new EventEmitter<number>();
  @Output() editNetworkGroupEvent = new EventEmitter<number>();

  networkType = NetworkTypeEnum;
  networksIsCollapsed = true;

  deleteNetworkGroup(networkGroupId: number) {
    this.deleteNetworkGroupEvent.emit(networkGroupId);
  }

  editNetworkGroup(networkGroupId: number) {
    this.editNetworkGroupEvent.emit(networkGroupId);
  }

  hasTwitterNetwork() {
    return this.networkGroup.networks.some((network) => network.type === this.networkType.Twitter);
  }

  hasFacebookNetwork() {
    return this.networkGroup.networks.some((network) => network.type === this.networkType['Facebook Page']);
  }

  hasInstagramNetwork() {
    return this.networkGroup.networks.some((network) => network.type === this.networkType.Instagram);
  }

  hasLinkedInNetwork() {
    return this.networkGroup.networks.some((network) => network.type === this.networkType.LinkedIn);
  }

  toggleNetworksIsCollapsed() {
    this.networksIsCollapsed = !this.networksIsCollapsed;
  }
}
