import { Injectable } from '@angular/core';
import { ListDtoModel } from './list-dto-model';

@Injectable({
  providedIn: 'root'
})

export class ListDtoModelAdapter {
  adapt(item: any): ListDtoModel {
    return new ListDtoModel(item);
  }

  adaptArray(init: Array<any>): ListDtoModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new ListDtoModel(table))
      : [new ListDtoModel(init[0])];
    } else {
      return [];
    }
  }

  createEmpty() {
    return new ListDtoModel({
      ListId: 0,
      ListName: '',
      FolderName: '',
    });
  }
}
