import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  AfterViewInit
} from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { CreateEditUserService } from 'src/app/_services/admin/users/create-edit-user.service';
import { UserManagementService } from 'src/app/_services/admin/users/user-management.service';
import { UserDto } from 'src/app/_models/admin/users/user-model';
import { NotificationService } from 'src/app/_services/notification.service';
import { SearchDataService } from 'src/app/_services/tables/search-data/search-data.service';
import { LoadingSpinnerService } from '../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements AfterViewInit {
  @Input() searchText = '';
  @Output() toEditUserEvent = new EventEmitter<string>();
  @Output() comingFromEvent = new EventEmitter<boolean>();

  // Variables for Table
  users: UserDto[] = [];
  headElements = ['user Id', 'first Name', 'last Name', 'username', 'email', 'default Client', 'active', 'edit user'];
  previous: string;
  maxVisibleItems = 8;

  // Variable for sending userId to sibling component.
  userId: number;
  createUser: boolean;

  // Variable for checking if toggle is set to true or false for active users
  isActiveSelected = true;

  // variable for search data
  pageTitle = 'Users';

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private createEditUserService: CreateEditUserService,
    private userManagementService: UserManagementService,
    private notificationService: NotificationService,
    private searchDataService: SearchDataService,
    private loadingSpinnerService: LoadingSpinnerService
  ) { }

  ngAfterViewInit() {
    this.loadingSpinnerService.loading();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.getUsers();
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();

    if (this.searchDataService.searchData.lastPage !== this.pageTitle) {
      this.searchDataService.SetSearchData('', this.pageTitle);
    }
  }

  getUsers() {
    this.userManagementService.getUsers().subscribe(data => {
      this.users = data;
      this.mdbTable.setDataSource(this.users);
      this.previous = this.mdbTable.getDataSource();
      this.loadingSpinnerService.stopLoading();

      if (this.searchDataService.searchData.lastPage === this.pageTitle) {
        this.searchText = this.searchDataService.searchData.searchTerm;
        this.searchItems();
      }
    });
  }

  getInactiveUsers() {
    this.userManagementService.getInactiveUsers().subscribe(data => {
      this.users = data;
      this.mdbTable.setDataSource(this.users);
      this.previous = this.mdbTable.getDataSource();
      this.loadingSpinnerService.stopLoading();
    });
  }

  searchItems = () => {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.users = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.users = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  };

  editUser(id: number) {
    this.swapToEditComponent('createEditUser');
    this.comingFrom(false);
    const user: UserDto = this.users.find(user => user.userId === id);
    if (user) { this.updateEditComponentId(user); }
  }

  swapToEditComponent(page: string) {
    this.toEditUserEvent.emit(page);
  }

  comingFrom(comingFrom: boolean) {
    this.comingFromEvent.emit(comingFrom);
  }

  updateEditComponentId(user: UserDto) {
    this.createEditUserService.updateEditComponentId(user);
    this.createEditUserService.currentUser = user;
  }

  toggleUsers(toggle: any) {
    this.isActiveSelected = toggle.target.checked;
    if (toggle.target.checked === true) {
      this.getUsers();
    }
    else if (toggle.target.checked === false) {
      this.getInactiveUsers();
    }
  }

  changeUserStatus(userId: number) {
    this.loadingSpinnerService.loading();
    const userStatusChanging: UserDto = this.users.find(user => user.userId === userId);
    if (userStatusChanging.defaultClient !== 0 || userStatusChanging.active) {
      userStatusChanging.active = !userStatusChanging.active;
      this.createEditUserService.updateUser(userStatusChanging).subscribe(result => {
        this.loadingSpinnerService.stopLoading();
        this.notificationService.showSuccess(result.message);
        if (this.isActiveSelected) {
          this.getUsers();
        }
        else {
          this.getInactiveUsers();
        }

      }, error => {
        console.log(error);
        this.loadingSpinnerService.stopLoading();
      });
    } else {
      this.loadingSpinnerService.stopLoading();
      this.notificationService.showWarning('Please set a Default Client for this user before Activating.')
    }
  }
}
