import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/_services/admin/auth/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { AppComponent } from 'src/app/app.component';
import { NavigationService } from 'src/app/_services/system/Navigations/navigation.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  _model: any = {};
  _loginForm: UntypedFormGroup;
  _loggingIn: boolean = false;
  _awsLoginResult: string;
  _awsRegisterResult: boolean = false;
  _awsSignUpResult: string;
  _loginModel: any = {};
  _inactiveUser: any = {};

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private appComponent: AppComponent,
    private navService: NavigationService,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit() {
    this.createLoginForm();
    this.authService.cognitoUserSession = undefined;
  }

  createLoginForm() {
    this._loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async login() {
    if (this._loginForm.valid) {
      try {
        this._loggingIn = true;
        this.authService.localUserModel = Object.assign({}, this._loginForm.value);
        this._loginModel = this.authService.localUserModel;
        // Store these credentials locally in case we need them later (when we can't access them due to object changes)
        this.storeCredentials();
        await this.authService.awsSignIn(this.authService.localUserModel).then(result => this._awsLoginResult = result);
        await this.authService.awsGetCurrentAuthenticatedUser();
        // if the user logs in to Cognito correctly, we must handle a variety of potential messages back from Cognito
        switch (this._awsLoginResult) {
          // this result is if the user does not need to enter a 2FA (this could be because they'd recently do so, for example)
          case "true": {
            // if the user has not got 2fa set, then we take them to the screen so that they can set it up
            if (this.authService.cognitoUserSession.preferredMFA === "NOMFA") {
              this.router.navigate(['/two-factor-setup']);
            }
            else {
              // attempt to log them in locally. If this is successful, they can move to the dashboard
              this.authService.login(this.authService.localUserModel, true).subscribe(response => {
                this.appComponent.reset();
                this.navService.updateUserClients();
              }, async error => {
                console.log(error);
                // If the user is not registered locally, set them up using info from their Cognito user
                if (error.error.Message === "Invalid username or password") {
                  {
                    await this.authService.addUserToLocalDB();
                    if (this.authService.localUserSignUp === 'Success') {
                      // once added to the db, they can't login as their user needs to be made active by an admin
                      // so let them know this information and return them to the login screen
                      // and log out of both Cognito and Voicebox
                      this.notificationService.showSuccess('User signed up successfully!');
                      this.notificationService.showInfo('User must be activated by an administrator before being able to login.');
                      this.logoutLocal();
                      this.authService.awsLogOut();
                      this.router.navigate(['/login']);
                    }
                    // catch and display any error to the user
                    // and log out of both Cognito and Voicebox
                    else {
                      this.notificationService.showError('Error signing up to local DB: ' + this.authService.localUserSignUp);
                      this._loggingIn = false;
                      this.logoutLocal();
                      this.authService.awsLogOut();
                      this.router.navigate(['/login']);
                    }
                  }
                }
                this._loggingIn = false;
                this.logoutLocal();
                this.authService.awsLogOut();
                this.router.navigate(['/login']);
                return;
              }, () => {
                this._loggingIn = false;
                ;
              });
            }
            break;
          }
          // If Cognito tells us the user has not finishing setting up their 2FA, take them to the correct screen to do so
          case "2FA Setup": {
            this.notificationService.showError('Two-factor authentication needs to be set up for this user');
            this.router.navigate(['/two-factor-setup']);
            break;
          }
          // If Cognito tells us the user has not authenticating their account, take them to the correct screen to do so
          case "User is not confirmed.": {
            this.notificationService.showError('Cognito authentication failed - please enter the authentication code sent to your email or click the link to generate a new code.');
            this.router.navigate(['/user-authentication', { data: this.authService.localUserModel.username }]);
            break;
          }
          // If the user has been created manually in Cognito or we force a password change on their account in Cognito,
          // take them to the correct screen to do this
          case "New Password Required": {
            this.notificationService.showError('New password required!');
            this.router.navigate(['/new-password', { data: this.authService.localUserModel.username }]);
            break;
          }
          // If Cognito requires them to enter their 2FA code, take them to the screen to so
          case "Two Factor Authentication": {
            this.router.navigate(['/two-factor-input']);
            break;
          }
          // If the user does not exist in Cognito, we check if they exist in the local DB
          case "Incorrect username or password.": {
            // check if exist in m.d
            // if successful, try to sign up to cognito, otherwise, do not
            this.authService.login(this._loginModel, true).subscribe(async response => {
              await this.authService.addUserToCognito(this.authService.awsNewUser).then(result => this._awsSignUpResult = result);
              // based on the outcome of the Cognito sign up process, react accordingly
              // if it is successful, then we take the user to the Cognito account authentication page
              switch (this._awsSignUpResult) {
                case 'Success': {
                  this.notificationService.showInfo("User does not exist in Cognito - registering user now...");
                  this.notificationService.showSuccess("User added to Cognito! Please finish the Cognito authentication process.");
                  this.logoutLocal();
                  this.router.navigate(['/user-authentication', { data: this._loginModel.username }]);
                  break;
                }
                // if we get any other result, we should display this to the user
                // and log out of both Cognito and Voicebox
                default: {
                  this._loggingIn = false;
                  this.notificationService.showError("Error signing up to Cognito: " + this._awsSignUpResult);
                  this.logoutLocal();
                  this.authService.awsLogOut();
                  this.router.navigate(['/login']);
                  break;
                }
              }
            }, async error => {
              // if user is in the database, but not set to active, still create them in Cognito
              if (error.error.Message.indexOf("is not active") !== -1) {
                // grab the required information from the data in the error
                this._inactiveUser = error.error.Data;
                await this.authService.addInactiveUserToCognito(this._inactiveUser).then(result => this._awsSignUpResult = result);
                this.notificationService.showInfo("User does not exist in Cognito - registering user now...");
                this.notificationService.showError("User must be activated by an admin before continuing.");
                this.notificationService.showSuccess("User added to Cognito! Please finish the Cognito authentication process.");
                this.router.navigate(['/user-authentication', { data: this._loginModel.username }]);
                return;
              }
              // log out of both Cognito and Voicebox
              this._loggingIn = false;
              console.log(error);
              this.logoutLocal();
              this.authService.awsLogOut();
              this.router.navigate(['/login']);
            });
            break;
          }
          // catch any error, let the user know and then log out of both Cognito and Voicebox
          // However, if the error is "incorrect username or password", we don't need to show the error again as we have already automatically displayed it from
          // the login attempt above
          default: {
            this._loggingIn = false;
            if (this._awsLoginResult !== "Incorrect username or password.") this.notificationService.showError('Cognito login attempt failed: ' + this._awsLoginResult);
            this.logoutLocal();
            this.authService.awsLogOut();
            this.router.navigate(['/login']);
            break;
          }
        }
        // catch any error, let the user know and then log out of both Cognito and Voicebox
      } catch (error) {
        this._loggingIn = false;
        this.notificationService.showError('Cognito login attempt failed: ' + error.message);
        this.logoutLocal();
        this.authService.awsLogOut();
        this.router.navigate(['/login']);
      }
    }
  }

  // as we may need this information for other things (and the local user model gets manipulated)
  // we save these credentials to the models required for later use
  storeCredentials() {
    this.authService.awsNewUser.username = this.authService.localUserModel.username;
    this.authService.awsNewUser.password = this.authService.localUserModel.password;
    this.authService.newUserLocal.username = this.authService.localUserModel.username;
    this.authService.newUserLocal.password = this.authService.localUserModel.password;
    this.authService.newUserLocal.ConfirmPassword = this.authService.localUserModel.password;
    this.authService.signedUpUser.username = this.authService.localUserModel.username;
    this.authService.signedUpUser.password = this.authService.localUserModel.password;
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  // log out of the local DB
  logoutLocal() {
    localStorage.clear();
    this.appComponent.stopIdle();
    this.permissionsService.setPermissionsModel = null;
  }
}
