import { ListFieldOptionModel } from '../lists/list-field-option-model';


export class ListFieldDto {

  public fieldId: number;
  public fieldName: string;
  public listId: number;
  public columnIndex: number;
  public isDefault: boolean;
  public isDeleted: boolean;
  public columnName: string;
  public wildcard: string;
  public displayName: string;
  public isSystem: boolean;
  public isNullable: boolean;
  public maxLength: string;
  public isIdentity: boolean;
  public readonly: boolean;
  public visible: boolean;
  public isWildcard: boolean;
  public orderIndex: number;
  public orderIndexMinor: number;
  public fieldTypeId: number;
  public controlTypeId: number;
  public viewPath: string;
  public isNew: boolean;
  public listFieldOptions: Array<ListFieldOptionModel>;

}
