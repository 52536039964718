import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { SocialReportDtoRequest } from 'src/app/_models/social-media/reports/social-report-dto-request';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public reset: Subject<void> = new Subject<void>();
  private baseUrl = environment.apiURL;
  private controllerName = 'Report';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) {}

  getOverviewMetrics(socialReportDtoRequest: SocialReportDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/GetOverviewMetrics`, socialReportDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  resetTextFilter(): void {
    this.reset.next();
  }

  text(): Observable<any> {
    return this.reset as Observable<any>;
  }
}


