<div class="network-management-page">
  <div class="title-box">
    <span class="header-section">Networks</span>
  </div>
  <div class="network-management-section">
    <span class="title-sub-section">Network Management</span>
    <hr class="mt-1 mb-0">
    <form>
      <div class="container-fluid">
        <!-- Grid Row -->
        <div class="row mt-2">
          <div class="col-md-2">
            <div class="row">
              <div class="col-md-12 pl-0 mt-2">
                <div class="network-group">
                  <input type="text"
                         id="networkSearch"
                         class="form-control input-style"
                         [(ngModel)]="searchText"
                         [ngModelOptions]="{standalone: true}"
                         placeholder="Search..." />
                  <img src="./assets/images/Icons/Search.svg" alt="Alternate Text" />
                </div>
              </div>
            </div>
          </div>
          <div class="row offset-md-2 text-right ml-md-auto">

            <div class="col-md mt-2">
              <div *ngIf="_userPermissions.Social.Networks.Create">
                <button
                        type="button"
                        class="facebook-btn"
                        (click)="addFacebookProfile()">
                  <i class="fas fa-infinity"></i>
                  Add Profile
                </button>
              </div>
            </div>
            <div class="col-md mt-2">
              <button *ngIf="_facebookPagesLoading"
                      type="button"
                      color="primary"
                      class="facebook-btn"
                      disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>

              <div *ngIf="_userPermissions.Social.Networks.Create">
                <button *ngIf="!_facebookPagesLoading"
                        type="button"
                        color="primary"
                        class="facebook-btn"
                        (click)="this.getFacebookPages()"
                        [disabled]="_facebookPagesNoResults">
                  <mdb-icon fab icon="facebook-f"></mdb-icon>
                  Add Page
                </button>
              </div>
            </div>
            <div class="col-md mt-2">
              <button *ngIf="_instagramPagesLoading"
                      type="button"
                      color="primary"
                      class="facebook-btn"
                      disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>

              <div *ngIf="_userPermissions.Social.Networks.Create">
                <button *ngIf="!_instagramPagesLoading"
                        type="button"
                        color="primary"
                        class="facebook-btn"
                        (click)="this.getInstagramPages()"
                        [disabled]="_instagramPagesNoResult">
                  <mdb-icon fab icon="instagram"></mdb-icon>
                  Add Page
                </button>
              </div>
            </div>      
            <div class="col-md mt-2">
              <div *ngIf="_userPermissions.Social.Networks.Create">
                <button
                        type="button"
                        class="linkedin-btn"
                        (click)="addLinkedInAccount()">
                  <mdb-icon fab icon="linkedin"></mdb-icon>
                  Add Profile
                </button>
              </div>
            </div>
            <div class="col-md mt-2">
              <button *ngIf="_linkedInOrganisationsLoading"
                      type="button"
                      color="primary"
                      class="linkedin-btn linkedinOrganisation-btn"
                      disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>

              <div *ngIf="_userPermissions.Social.Networks.Create" class="link-box">
                <button *ngIf="!_linkedInOrganisationsLoading"
                        type="button"
                        color="primary"
                        class="linkedin-btn linkedinOrganisation-btn"
                        (click)="this.getLinkedInOrganisations()"
                        [disabled]="_instagramPagesNoResult">
                  <mdb-icon fab icon="linkedin"></mdb-icon>
                 Add Organisation
                </button>
              </div>
            </div>            
                  
            <div class="col-md mt-2 twitt-box">
              <div *ngIf="_userPermissions?.Social.Networks.Create">
                <button
                        type="button"
                        color="primary"
                        class="twitter-btn"
                        (click)="addTwitterAccount()">
                  <mdb-icon fab icon="twitter"></mdb-icon>
                  Add Account
                </button>
              </div>
            </div>

            <div class="col-md mt-2">
              <div *ngIf="_userPermissions.Social.Networks.Edit && _userPermissions.Social.Networks.Edit">
                <button *ngIf="!_refreshingNetworks"
                        type="button"
                        class="refresh-btn"
                        (click)="refreshNetworks()">
                  Refresh Networks
                </button>
                <button
                        *ngIf="_refreshingNetworks"
                        type="button"
                        class="refresh-network-btn"
                        disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Refreshing Networks...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>


  <div class="container-fluid network-list-wrapper m-0 p-0">
    <div class="row p-0">
      <div *ngFor="let network of this._filteredNetworks; let i = index" class="network-card-wrapper col-12 col-md-4 col-lg-five col-6">
        <app-network-card
                          #appNetworkCard
                          [network]="network"
                          (refreshNetworkEvent)="refreshNetwork($event)"
                          (deleteNetworkEvent)="setDeleteNetwork(network)"
                          (deletePageEvent)="deletePage()">
        </app-network-card>
      </div>
    </div>
  </div>
</div>

    <div mdbModal #addSubNetworkModal="mdbModal" id="addSubNetworkModal" class="add-subnetwork modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="addSubNetworkModal.hide()">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title w-100" id="modalLabel">{{ _modalTitle }}</h4>
          </div>
          <div class="modal-body">

            <div class="container">

              <div class="scrollbar">
                <div class="force-overflow">
                  <div *ngFor="let profile of this._pages">

                    <div class="row mt-3">
                      <div class="col-2">
                        <div class="float-left">
                          <img class="networkImage" src="{{profile.pictureUrl}}" />
                        </div>
                      </div>
                      <div class="col-10">
                        <div class="float-left">
                          <label class="title-profile-name">{{profile.name}}</label>
                        </div>
                      </div>
                    </div>

                    <div *ngFor="let subNetwork of profile.subNetworks">

                      <div class="row mt-3">
                        <div class="col-2">
                          <div class="float-left">
                            <img class="subNetworkImage" src="{{subNetwork.pictureUrl}}" />
                          </div>
                        </div>
                        <div class="col-10 subNetwork-box">
                          <div class="form-check form-check-inline float-left">
                            <mat-checkbox id="checkbox-{{profile.networkId}}-{{subNetwork.id}}"
                                          (change)="this.addDeleteSubNetworkPage($event.source, $event.checked, subNetwork)" value="{{profile.networkId}}-{{subNetwork.id}}"
                                          [disabled]="_isDisabled" [checked]="subNetwork.selected">
                             <span class="checkbox-label"> {{subNetwork.name}}</span>
                            </mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn-save-modal" aria-label="Close" (click)="addSubNetworkModal.hide()">Done</button>
          </div>
        </div>
      </div>
    </div>

    <div mdbModal #deleteNetworkModal="mdb-modal" class="network-modal modal fade" id="deleteNetworkModal" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Confirm Action</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="deleteNetworkModal.hide()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="text-center">
              <div class="mb-3 modal-text">
                Are you sure you want to delete {{ _networkToDeleteName }}?
              </div>
              <p class="modal-sub-text">
                All posts for this network will fail to post,
                and you will see errors or warnings on the Post Timeline.
              </p>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn-cancel-modal" outline="true" data-dismiss="modal"
                    (click)="deleteNetworkModal.hide()">
              Cancel
            </button>
            <button type="button" (click)="deleteNetwork(0);" 
                    class="btn-save-modal">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
