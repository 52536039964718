<div class="row m-4">
    <div class="col-md-12">
      <!--Card-->
      <mdb-card cascade="true" narrower="true" class="mt-5">

        <!--Card image-->
        <div class="view view-cascade gradient-card-header primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center">
            <h4 class="page-title">User Role Management</h4>
        </div>
        <!--/Card image-->

        <!--Card content-->
        <mdb-card-body cascade="true" class="text-center">

          <section>

            <form class="form-inline md-form form-sm" autocomplete="off">

                <div class="container-fluid">
                    <!-- Grid Row -->
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-4">
                            <div class="md-form text-left" *ngIf="this._component === 'userRoles'">
                                <mdb-icon fas icon="search" class="prefix icon-color" size="sm" aria-hidden="true"></mdb-icon>
                                <input type="text"
                                [(ngModel)]="this._searchText"
                                [ngModelOptions]="{standalone: true}"
                                id="userRoleManagement-search"
                                class="form-control"
                                mdbInput>
                                <label for="userRoleManagement-search" class="">Search</label>
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-6">
                            <div class="md-form">
                                <div class="btn-group btn-group-sm rounded" role="group">
                                    <button mdbBtn type="button" color="secondary" class="waves-light" (click) = "setPage('createEditUserRole')" mdbWavesEffect>Create User Role</button>
                                    <button mdbBtn type="button" color="secondary" class="waves-light" (click) = "setPage('userRoles')" mdbWavesEffect>User Roles</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/.Grid Row-->
              </div>

            </form>

          </section>

        </mdb-card-body>
        <!--/.Card content-->
      </mdb-card>
      <!--/.Card-->
    </div>
  </div>

  <app-create-edit-user-role
    *ngIf="this._component === 'createEditUserRole'"
    [createUserRole]="this._createUserRole"
    #createEditUserRole>
  </app-create-edit-user-role>  
  <app-user-roles
    *ngIf="this._component === 'userRoles'"
    (toEditUserRoleEvent)="receivePageChange($event)"
    (comingFromEvent)="receiveComingFrom($event)"
    #userRoles>
  </app-user-roles>