import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { CreateEditTriggerComponent } from '../create-edit-trigger.component';

@Component({
  selector: 'app-timing',
  templateUrl: './timing.component.html',
  styleUrls: ['./timing.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0, }),
            animate('.5s ease-out',
            style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
            style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class TimingComponent implements OnInit {
  @Input() timingFormGroup: UntypedFormGroup;

  periodOfTimeFormGroup: any;

  _showPeriodOfTime: Boolean = false;
  _showAtDateTime: Boolean = false;

  _showTiming: Boolean = false;

  _minsHoursDaysWeeks: Array<any> = [
    { label: 'Min(s)', value: 1, },
    { label: 'Hour(s)', value: 2, },
    { label: 'Day(s)', value: 3, },
    { label: 'Week(s)', value: 4, },
  ];

  constructor(
    public createEditTriggerComponent: CreateEditTriggerComponent,
  ) { }

  ngOnInit() {
    this.periodOfTimeFormGroup = this.timingFormGroup.controls.periodOfTimeFormGroup;



    this.onChanges();
  }

  onChanges() {
    this.timingFormGroup.get('timingType').valueChanges.subscribe(option => {
      this.showTimeOptions(option);
    });

    this.periodOfTimeFormGroup.get('numberOf').valueChanges.subscribe(val => {
      if (val > 500) {
        this.periodOfTimeFormGroup.get('numberOf').setValue(500);
      };
    });

    this.periodOfTimeFormGroup.get('minsHoursDaysWeeks').valueChanges.subscribe(val => {
      this.showTiming(val);
    });
  }

  showTimeOptions(option) {
    this.removeAllValidation();
    switch (option) {
      case 'immediately':
        this._showPeriodOfTime = false;
        this._showAtDateTime = false;
        this._showTiming = false;
        break;
      case 'periodOfTime':
        this._showPeriodOfTime = true;
        this._showAtDateTime = false;
        this.periodOfTimeFormGroup.get('numberOf').setValidators([Validators.required]);
        this.periodOfTimeFormGroup.get('minsHoursDaysWeeks').setValidators([Validators.required]);
        this.periodOfTimeFormGroup.updateValueAndValidity();
        break;
      default:
        break;
    }
  }

  showTiming(val) {
    if (val > 2) {
      this._showTiming = true;
      this.periodOfTimeFormGroup.get('time').setValidators([Validators.required]);
      this.periodOfTimeFormGroup.updateValueAndValidity();
    } else {
      this._showTiming = false;
      this.periodOfTimeFormGroup.get('time').clearValidators();
      this.periodOfTimeFormGroup.updateValueAndValidity();
    }
  }

  removeAllValidation() {
    // tslint:disable-next-line: forin
    for (const key in this.periodOfTimeFormGroup.controls) {
      this.periodOfTimeFormGroup.get(key).clearValidators();
      this.periodOfTimeFormGroup.get(key).updateValueAndValidity();
    }
  }
}
