import { Injectable } from '@angular/core';
import { MessageHistoryDto } from './message-history-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageHistoryDtoAdapter {
  public adapt(item: any): MessageHistoryDto {
    return new MessageHistoryDto(item);
  }
}
