export class MessageCodeStepGridRecordDto {

    changedBy: string;
    changeDate: Date;
    createdBy: string;
    createDate: Date;
    description: string;
    endDate: Date;
    listId: number;
    listName: string;
    messageCodeId: number;
    messageCodeStepId: number;
    orderOfPrecedence: number;
    sql: string;
    startDate: Date;
    criteriaCode: string;
    type: string;

    constructor(init: any) {

        this.changeDate = init.ChangeDate;
        this.changedBy = init.ChangedBy;
        this.createdBy = init.CreatedBy;
        this.createDate = init.CreateDate;
        this.description = init.Description;
        this.endDate = init.EndDate;
        this.listId = init.ListID;
        this.listName = init.ListName;
        this.messageCodeId = init.MessageCodeID;
        this.messageCodeStepId = init.MessageCodeStepID;
        this.orderOfPrecedence = init.OrderOfPrecedence;
        this.sql = init.Sql;
        this.startDate = init.StartDate;
        this.type = init.Type;
        this.criteriaCode = init.CriteriaCode;
    }
}
