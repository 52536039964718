//model to capture all the data for pagination so that it can be stored in the session

export class PaginationData {
    public page: number;
    public childPage: string;
    public pageLimit: number;
    public sortByColumn: string;
    public sortByDirection: string;    
    public lastPage: string;
    public folderId: number;
    public listId: number;
}
