import { Injectable } from '@angular/core';
import { MessageCopyMoveDetailsDto } from './message-copy-move-details-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageCopyMoveDetailsDtoAdapter {
  public adapt(item: any): MessageCopyMoveDetailsDto {
    return new MessageCopyMoveDetailsDto(item);
  }

  public adaptArray(init: Array<any>): MessageCopyMoveDetailsDto[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(m => new MessageCopyMoveDetailsDto(m))
      : [new MessageCopyMoveDetailsDto(init[0])];
    } else {
      return [];
    }
  }
}
