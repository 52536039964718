import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MessageCodeStepDto } from 'src/app/_models/messaging/automation/message-codes/message-code-step-dto';
import { CopyMessageCodeStepRequestDto } from 'src/app/_models/messaging/automation/message-codes/copy-message-code-step-request-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageCodeStepService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'MessageCodeStep';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) { }

  copy(copyMessageCodeStepRequestDto: CopyMessageCodeStepRequestDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/Copy`, copyMessageCodeStepRequestDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  delete(messageCodeId: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/${messageCodeId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAll(pagerDtoRequest: PagerDtoRequest, messageCodeId: number): Observable<ExecutionResultDto> {

    const query = `?Page=${pagerDtoRequest.page}` +
                  `&PageSize=${pagerDtoRequest.pageSize}` +
                  `&SortColumn=${pagerDtoRequest.sortColumn}` +
                  `&SortDirection=${pagerDtoRequest.sortDirection}` +
                  `&messageCodeId=${messageCodeId}`;

    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetAll${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }  

  get(messageCodeStepId: number): Observable<ExecutionResultDto> {
    const query = `?messageCodeStepId=${messageCodeStepId}`;

    return this.http
    .get(`${this._baseUrl}${this._controllerName}/Get${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getByAllListId(listId: number): Observable<ExecutionResultDto> {    
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetAllByListId/${listId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(messageCodeStepRequestDto: MessageCodeStepDto): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}`, messageCodeStepRequestDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

