import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailMessageReportsRetrievingModel } from 'src/app/_models/messaging/messages/message-reports/email-reports/email-message-reports-retrieving-model';
import { DetailedEmailReportRequest } from 'src/app/_models/messaging/messages/message-reports/detailed-reports/detailed-email-report-request';
import { SMSMessageReportsRetrievingModel } from 'src/app/_models/messaging/messages/message-reports/sms-reports/sms-message-reports-retrieving-model';
import { SMSRepliesRetrievingModel } from 'src/app/_models/messaging/messages/message-reports/sms-reports/sms-replies-retrieving-model';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { HelperService } from '../../system/helpers/helper.service';
import {
  MessageReportGridViewModel
} from '../../../_models/messaging/messages/message-reports/email-reports/message-report-grid-view-model';

@Injectable({
  providedIn: 'root'
})
export class MessageReportsService {
  private baseUrl = environment.apiURL;
  private emailControllerName = 'EmailMessageReports';
  private smsControllerName = 'SMSMessageReports';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter,
    private helperService: HelperService
  ) { }

  getFolders(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this.baseUrl}${this.emailControllerName}/GetFolders`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getEmailReports(reportModel: EmailMessageReportsRetrievingModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.emailControllerName}/GetReports`, reportModel)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getEmailReportDetails(reportID: number): Observable<ExecutionResultDto> {
    const query = `reportID=${reportID}`;
    return this.http
    .get(`${this.baseUrl}${this.emailControllerName}/GetReportDetails?` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getDetailedReportData(getDetailedReportRequest: DetailedEmailReportRequest, messageTypeEnum: MessageTypeEnum): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${MessageTypeEnum[messageTypeEnum]}MessageReports/GetDetailedReportData`, getDetailedReportRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getClickedLinks(reportID: number): Observable<ExecutionResultDto> {
    const query = `reportID=${reportID}`;
    return this.http
    .get(`${this.baseUrl}${this.emailControllerName}/GetClickedLinks?` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getSMSReports(reportModel: SMSMessageReportsRetrievingModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.smsControllerName}/GetReports`, reportModel)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getSMSReportDetails(reportID: number): Observable<ExecutionResultDto> {
    const query = `reportID=${reportID}`;
    return this.http
    .get(`${this.baseUrl}${this.smsControllerName}/GetReportDetails?` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getSMSReplies(repliesModel: SMSRepliesRetrievingModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.smsControllerName}/GetSMSReplies`, repliesModel)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getSMSDetailedReportData(filter: string, reportID: number): Observable<ExecutionResultDto> {
    const query = `filter=${filter}&reportID=${reportID}`;
    return this.http
    .get(`${this.baseUrl}${this.smsControllerName}/GetDetailedReportData?` + query)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  export(data: MessageReportGridViewModel[], columnsForExport: any[], isSms = false): void {
    columnsForExport.pop();
    const rows = data.map((row) => {
      const exportRows = {};
      columnsForExport.forEach((col) => {
        exportRows[col.displayName] = row[col.name];
      });

      return exportRows;
    });

    const reportName = isSms ? 'SMSReport' : 'EmailReport';
    const reportFullName = isSms ? 'SMS Report' : 'Email Report';
    const today = new Date();
    const fileName = `${reportName}_${ today.getDate() }${ today.getMonth() + 1 }${ today.getFullYear() }`;
    this.helperService.exportToCSV(fileName, rows);
    this.helperService.sentEmailWarningNotificationOfFileDownload(reportFullName, today);
  }
}
