import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification.service';
import { UserRoleService } from 'src/app/_services/admin/users/user-role-service';
import { UserRoleDto } from 'src/app/_models/admin/users/user-role-model';

@Component({
  selector: 'app-create-edit-user-role',
  templateUrl: './create-edit-user-role.component.html',
  styleUrls: ['./create-edit-user-role.component.scss']
})
export class CreateEditUserRoleComponent implements OnInit {
 
  _createEditUserRoleForm: UntypedFormGroup;
  _submitting: boolean;
  _userRole: UserRoleDto;

  @Input() createUserRole: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private userRoleService: UserRoleService,
    private notificationService: NotificationService
    ) { }

  ngOnInit() {
    this.createCreateEditForm();
    this.editUserRoleState();
  }

  createCreateEditForm() {
    this._createEditUserRoleForm = this.fb.group({
      id: [],
      userRoleName: ['', Validators.required]
    });
  }

  createEditUserRole() {

    if (this._createEditUserRoleForm.valid) {
      if (!this._userRole) {        
        this._submitting = true;
        this._userRole = Object.assign({}, this._createEditUserRoleForm.value);
        this._userRole.id = 0;
        this.userRoleService.post(this._userRole).subscribe(id => {
        this._userRole.id = id;   
        this._createEditUserRoleForm.get('id').setValue(this._userRole.id);     
        this.notificationService.showSuccess('User Role created.');
        this.createUserRole = false;
      }, error => {        
        this._userRole = null;        
        this._submitting = false;
      }, () => {
        this._submitting = false;
      });
    } else if (this._userRole && this._userRole.id !== undefined) {      
        this._submitting = true;        
        this._userRole = Object.assign({}, this._createEditUserRoleForm.value);
        this.userRoleService.post(this._userRole).subscribe(() => {
        this.notificationService.showSuccess('User Role updated.');
      }, error => {              
        this._submitting = false;
      }, () => {
        this._submitting = false;
      });
    }
    }
  }

  editUserRoleState() {
    
      if (this.createUserRole === false) {
        this._userRole = this.userRoleService.currentUserRole;
        this._createEditUserRoleForm.get('id').setValue(this._userRole.id);
        this._createEditUserRoleForm.get('userRoleName').setValue(this._userRole.userRoleName);
      } else if (this.createUserRole === true) {
        this._createEditUserRoleForm.reset();
        this._userRole = null;
      }
    
  };
}
