import { Component, Inject, OnInit } from '@angular/core';
import {
  CopyContactPlanContactPlansDto
} from '../../../../_models/messaging/automation/contact-plans/copy-contact-plan-contact-plans-dto';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectListOption } from '../../../../_models/system/select-list-option';
import { ContactPlanFolderService } from '../../../../_services/messaging/automation/contact-plan-folder.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CopyContactPlanContactPlansDtoAdapter
} from '../../../../_models/messaging/automation/contact-plans/copy-contact-plan-contact-plans-dto.adapter';
import {
  CopyContactPlanDtoAdapter
} from '../../../../_models/messaging/automation/contact-plans/copy-contact-plans-dto.adapter';
import { ContactPlanService } from '../../../../_services/messaging/automation/contact-plan.service';
import { NotificationService } from '../../../../_services/notification.service';
import { TableName, TableType } from '../../constants/table-constants';
import { CopyListModel } from '../../../../_models/messaging/lists-and-contacts/lists/copy-list-model';
import { CopyListModelAdapter } from '../../../../_models/messaging/lists-and-contacts/lists/copy-list-model.adapter';
import { MoveListModelAdapter } from '../../../../_models/messaging/lists-and-contacts/lists/move-list-model.adapter';
import { MoveListModel } from '../../../../_models/messaging/lists-and-contacts/lists/move-list-model';
import { ListsService } from '../../../../_services/messaging/lists-and-contacts/lists/lists.service';
import { LoadingSpinnerService } from '../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-copy-move-contact-plan',
  templateUrl: './copy-move-modal.component.html',
})
export class CopyMoveModalComponent implements OnInit {
  title: string;
  type: string;
  copyMoveList = [];
  copyFormGroup: UntypedFormGroup;
  copyFormArray: UntypedFormArray;
  folderSelectOptions: SelectListOption[] = [];
  tableKey: string;
  isCopy: boolean;
  isListCopying: boolean;

  constructor(
    private contactPlanFolderService: ContactPlanFolderService,
    private copyContactPlanContactPlansDtoAdapter: CopyContactPlanContactPlansDtoAdapter,
    private copyContactPlanDtoAdapter: CopyContactPlanDtoAdapter,
    private contactPlanService: ContactPlanService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<CopyMoveModalComponent>,
    private copyListModelAdapter: CopyListModelAdapter,
    private moveListModelAdapter: MoveListModelAdapter,
    private listsService: ListsService,
    private loadingSpinnerService: LoadingSpinnerService,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(): void {
    this.tableKey = this.data.tableKey;
    this.isListCopying = TableType.lists === TableType[this.tableKey];
    this.copyMoveList = this.data.copyMoveList;
    this.isCopy = this.data.isCopy;
    this.type = this.copyMoveList.length > 1 ? TableName[this.tableKey] : TableName[this.tableKey].slice(0, -1);
    this.title = (this.isCopy ? 'Copy ' : 'Move ') + this.type;
    this.folderSelectOptions = this.data.folders;
    this.copyFormArray = new UntypedFormArray([]);
    this.fillCopyFormArray();

    this.copyFormGroup = new UntypedFormGroup({
      folderId: new UntypedFormControl(this.folderSelectOptions[0].value, Validators.required)
    });

    if (this.isListCopying) {
      this.copyFormGroup.addControl('copyContacts', new UntypedFormControl(false));
    }
  }

  setArray(formArray): void {
    this.copyFormArray = formArray;
  }

  fillCopyFormArray(): void {
    this.copyMoveList.forEach((el) => {
      el.checked = false;
      this.copyFormArray.push(new UntypedFormControl(el.title || el.ListName));
    });
  }

  moveList(): MoveListModel {
    const moveList = this.moveListModelAdapter.createEmpty();
    moveList.DestinationFolderId = this.copyFormGroup.get('folderId').value;
    this.data.copyMoveList.forEach(list => {
      moveList.ListIds.push(list.ListID);
    });

    return moveList;
  }

  copyList(): CopyListModel {
    const data = this.copyListModelAdapter.createEmpty();
    const fullSelectedList = this.data.copyMoveList[0];
    data.CopyListName = this.copyFormArray.controls[0].value;
    data.DestinationFolderId = this.copyFormGroup.get('folderId').value;
    data.ImportContactsRequired = this.copyFormGroup.get('copyContacts').value;
    data.ListType = fullSelectedList.ListType;
    data.SourceListId = fullSelectedList.ListID;

    return data;
  }

  copyMove(): void {
    this.loadingSpinnerService.loading();
    let requestData;
    if (TableType.contactPlan === TableType[this.tableKey]) {
      const plans: Array<CopyContactPlanContactPlansDto> = [];
      this.copyMoveList.forEach((contactPlan, i) => {
        const dto = this.copyContactPlanContactPlansDtoAdapter.adapt({
          ContactPlanId: contactPlan.contactPlanId,
          PlanName: this.copyFormArray.controls[i].value
        });

        plans.push(dto);
      });

      requestData = this.copyContactPlanDtoAdapter.adapt({
        FolderId: this.copyFormGroup.get('folderId').value,
        ContactPlans: plans
      });
    }

    if (TableType.lists === TableType[this.tableKey]) {
      requestData = this.isCopy ? this.copyList() : this.moveList();
    }

    const service = this.isCopy ? this.data.dataService.copy(requestData) : this.data.dataService.move(requestData);
    service.subscribe((result: ExecutionResultDto) => {
      if (result.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(result.message);
        this.dialogRef.close({ isNeedToUpdate: true });
        this.loadingSpinnerService.stopLoading();
      } else {
        this.notificationService.showError(result.message);
        this.loadingSpinnerService.stopLoading();
      }
    }, (error: any) => {
      this.loadingSpinnerService.stopLoading();
      console.error(error);
    });
  }
}
