<mdb-card cascade="true" narrower="true" class="mt-5">

  <!--Card Header-->
  <div class="view view-cascade gradient-card-header primary-color narrower
    py-2 mx-4 mb-3 d-flex justify-content-center">
    <h4 class="page-title">Media Server</h4>
  </div>
  <!--/Card Header-->

  <!--Card Content-->
  <mdb-card-body cascade="true" class="text-center media-server-header-content">

    <div class="row">

    <!-- Selector to choose the bucket -->
    <div class="col-md-1">

    </div>
    <div class="col-md-3">
      <mdb-select
        [options]="_bucketsOptions"
        [visibleOptions]="10"
        [(ngModel)]="this._bucketName">
      </mdb-select>
    </div>
    <div class="col-md-4">
      <button mdbBtn *ngIf="showCreateNewMediaServer()" (click)="createMediaServerModal.show()" type="button" color="primary" class="wave-light create-media-server-button" mdbWavesEffect>Create new Client Media Server</button>
        
      <div class="md-form text-left mt-1">
        <mdb-icon fas icon="search" class="prefix icon-color" size="sm" aria-hidden="true"></mdb-icon>
        <input type="text"
         id="fileSearch"
         class="form-control form-control-sm ml-5 w-75 searchIcon"
         (keyup)="filterItems($event.target.value)"                        
         mdbInput>
        <label for="fileSearch" class="">Search files</label>
      </div>

    </div>

    <div class="col-md-4">
      <div class="btn-group" role="group">
        <button mdbBtn type="button" color="primary" class="wave-light" (click)="uploadModal.show()" mdbWavesEffect>Upload</button>
        <button mdbBtn type="button" color="primary" class="wave-light" (click)="createFolderModal.show()" mdbWavesEffect>Create Folder</button>

        <div class="dropdown" mdbDropdown>


          <button mdbBtn mdbDropdownToggle type="button" color="primary" class="dropdown-toggle wave-light" *ngIf="this._selectedFiles.length > 0" mdbWavesEffect>
            <mdb-icon fas icon="cog"></mdb-icon>
          </button>

          <div class="dropdown-menu dropdown-primary">
            <a class="dropdown-item" (click)="openCopyModal()">Copy</a>
            <a class="dropdown-item" (click)="openMoveModal()">Move</a>
          </div>
        </div>
      </div>
      <!-- Upload new Files -->

    <!-- Create Folder -->
    </div>


    </div>


  </mdb-card-body>
</mdb-card>

<div class="row">
  <div class="col-md-3">
    <mdb-card cascade="true" narrower="true" >
      <mdb-card-header class="primary-color">
        <mdb-card-title class="text-white">Folders</mdb-card-title>
      </mdb-card-header>
      <mdb-card-body class="scrollbar scrollbar-primary max-height-600">
        <div [ngClass]="{'media-loading': _loading}" class="text-center">
          <div [ngClass]="{'mt-5': _loading}">
            <mdb-spinner *ngIf="_loading" spinnerColor="blue" spinnerType="big"></mdb-spinner>
          </div>
        </div>
        <div class="row justify-content-center folder-row" *ngIf="this._files">
          <div class="col-md-12 pl-2 pr-2 " *ngIf="showFolderBackButton()">
            <a (click)="folderBackClicked()" >
              <div class="row p-1 folder-row">
                <div class="folder-text col-md-12"><img class="img-fluid folder-image" src="../../../assets/images/Icons/Folder-icon-blue.png" alt="Card image cap"> Back ...</div>
              </div>
            </a>
          </div>          
          <div class="col-md-12 pl-2 pr-2" *ngFor="let folder of this._files.CommonPrefixes">
            <div class="row p-1 folder-row">
              <a class="folder-text col-md-10" (click)="folderClicked(folder)"> <img class="img-fluid folder-image" src="../../../assets/images/Icons/Folder-icon-blue.png" alt="Card image cap"> {{getFolderName(folder)}} </a>
              <a class="edit-folder-icon col-md-2" (click)="deleteFolderClicked(folder)"> <mdb-icon  fas icon="trash-alt" class="edit-folder-icon"></mdb-icon> </a>
            </div>
          </div>            
        </div>          
      </mdb-card-body>
    </mdb-card>
  </div>

  <div class="col-md-9">
    <mdb-card cascade="true" narrower="true" >
      <mdb-card-body class="media-server-files-content">
        <div [ngClass]="{'media-loading': _loading}" class="text-center">
          <div [ngClass]="{'mt-5': _loading}">
            <mdb-spinner *ngIf="_loading" spinnerColor="blue" spinnerType="big"></mdb-spinner>
          </div>
        </div>
        <div *ngIf="this._files">
          <div *ngIf="this._files.S3Objects.length === 0 || _pagedFiles.length === 0">No files found</div>
          <div class="row media-server-files">            
              <div class="col-md-3 mb-3" *ngFor="let file of _pagedFiles">
                <mdb-card [ngStyle]="{'background-image': 'url('+this.getFileImageIcon(file)+')' }" class="card-image" (click)="toggleFileSelect(file)">
                  <a class="file-details-container" [ngClass]="{'file-selected': isFileSelected(file)}">
                    <div class="file-details justify-content-center text-white text-center" >
                      {{this.getFileName(file.Key)}}
                      <div class="row">
                        <button mdbBtn type="button" color="primary" class="waves-light file-details-button" (click)="fileClicked(file); toggleFileSelect(file)" mdbWavesEffect><mdb-icon fas icon="info"></mdb-icon></button>
                      </div>
                    </div>
                  </a>
                </mdb-card>
              </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>        
    <nav class="nav justify-content-center mt-3">        
      <ul *ngIf="_pager.pages && _pager.pages.length" class="pagination pg-blue">
          <li class="page-item" [ngClass]="{disabled:_pager.currentPage === 1}">
              <a class="page-link" (click)="setPage(1)" mdbWavesEffect>First</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:_pager.currentPage === 1}">
              <a class="page-link" (click)="setPage(_pager.currentPage - 1)" mdbWavesEffect>Previous</a>
          </li>
          <li class="page-item" *ngFor="let page of _pager.pages" [ngClass]="{active:_pager.currentPage === page}">
              <a class="page-link" (click)="setPage(page)" mdbWavesEffect>{{page}}</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:_pager.currentPage === _pager.totalPages}">
              <a class="page-link" (click)="setPage(_pager.currentPage + 1)" mdbWavesEffect>Next</a>
          </li>
          <li class="page-item" [ngClass]="{disabled:_pager.currentPage === _pager.totalPages}">
              <a class="page-link" (click)="setPage(_pager.totalPages)" mdbWavesEffect>Last</a>
          </li>
      </ul>        
    </nav>   
  </div>  
</div>

<div mdbModal #createMediaServerModal="mdbModal" class="modal fade right" id="createMediaServerModal" tabindex="-1" role="dialog" aria-labelledby="createMediaServerModal"
    aria-hidden="true" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form [formGroup]="_createMediaServerForm" (ngSubmit)="createNewMediaServer()">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="createMediaServerModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Create new Media Server</h4>
            </div>
            <div class="modal-body">
              <div class="row justify-content-md-center">
                <div class="col-md-12 md-form">
                  <input mdbInput placeholder="Media Server Name" 
                  formControlName="BucketFriendlyName" type="text" class="form-control remove-form-control-border">
                  <mdb-error class="pl-3" *ngIf="_createMediaServerForm.hasError('invalidChars') && !_createMediaServerForm.valid">Please ensure your media server name does not include any full stops</mdb-error>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="createMediaServerModal.hide()" mdbWavesEffect>Close</button>
                <button type="submit" mdbBtn color="primary" class="relative waves-light" [disabled]="!_createMediaServerForm.valid" mdbWavesEffect>Create</button>
            </div>
          </form>
        </div>
    </div>
</div>

<div mdbModal #createFolderModal="mdbModal" class="modal fade right" id="createFolderModal" tabindex="-1" role="dialog" aria-labelledby="createFolderModal"
    aria-hidden="true" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form [formGroup]="_createFolderForm" (ngSubmit)="createNewFolder()">
            <div class="modal-header">
              <button type="button" class="close pull-right" aria-label="Close" (click)="createFolderModal.hide()">
                  <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title w-100" id="myModalLabel">Create new Folder</h4>
            </div>
            <div class="modal-body">
              <div class="row justify-content-md-center">
                <div class="col-md-12 md-form">
                  <input mdbInput placeholder="New Folder Name" formControlName="FolderName" type="text" class="form-control remove-form-control-border">
                  <mdb-error class="pl-3" *ngIf="_createFolderForm.hasError('invalidChars') && !_createFolderForm.valid">Folder names can only included letters, numbers, underscores, hyphens and periods</mdb-error>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="createFolderModal.hide()" mdbWavesEffect>Close</button>
                <button type="submit" mdbBtn color="primary" class="relative waves-light" [disabled]="!_createFolderForm.valid" mdbWavesEffect>Create</button>
            </div>
          </form>
        </div>
    </div >
</div >

<div mdbModal #uploadModal="mdbModal" class="modal fade right" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModal"
    aria-hidden="true" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form>
            <div class="modal-header">
              <button type="button" class="close pull-right" aria-label="Close" (click)="uploadModal.hide()">
                  <span aria-hidden="true">×</span>
              </button>
              <h4 class="modal-title w-100" id="myModalLabel">Upload new files</h4>
            </div>
            <div class="modal-body">
                <div class="file-field md-form">
                  <div mdbBtn color="primary" size="sm" class="waves-light" (click)="chooseFileClicked()" mdbWavesEffect>
                    <span>Choose file</span>
                    <input #uploadFile type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                  </div>
                  <div class="file-path-wrapper">
                    <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()" >
                  </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="closeUploadModal()" mdbWavesEffect>Close</button>
                <button mdbBtn color="primary" (click)="startUpload()" [disabled]="this._uploadingFiles"><span *ngIf="_uploadingFiles" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Start uploading</button>
            </div>
          </form>
        </div>
    </div >
</div >

<div mdbModal #fileDetailsModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="fileDetailsModal"
   aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <form [formGroup]="_editFileNameForm" (ngSubmit)="renameFile()">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="fileDetailsModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">File Details</h4>
            </div>
            <div class="modal-body">
              <div class="row" *ngIf="this._fileDetailsModalData">
                <div class="col-md-6 file-details-image">
                  <mdb-card [ngStyle]="{'background-image': 'url('+this.getFileImageIcon(this._fileDetailsModalData)+')' }" class="card-image"></mdb-card>
                </div>
                <div class="col-md-6 justify-content-center">
                  <div class="md-form">
                    <input mdbInput formControlName="newKey" id="newKey" type="text" class="form-control">
                    <label for="newKey">File Name</label>
                  </div>
                  <div class="text-break">
                    <p>Folder: {{this._folder}}</p>
                    <p>File Type: {{this.getFileExtension(_fileDetailsModalData.Key)}}</p>
                    <p>File Dimensions: {{_fileDetailsModalImage.naturalWidth + ' x ' + _fileDetailsModalImage.naturalHeight}}</p>
                    <p>File Size: {{_fileDetailsModalData.Size}} Bytes</p>
                    <p>File URL: {{getFilePublicUrl(_fileDetailsModalData)}}</p>
                    <p>Last Modified: {{formatDate(_fileDetailsModalData.LastModified)}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <div class="col-md-2">
                <button type="button" mdbBtn color="primary" class="relative waves-light float-left" (click)="openDeleteFileModal()" mdbWavesEffect>Delete</button>
              </div>
              <div class="col-md-5"></div>
              <div class="col-md-5">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="fileDetailsModal.hide()" mdbWavesEffect>Close</button>
                <button type="submit" mdbBtn color="primary" class="relative waves-light" [disabled]="!_editFileNameForm.valid || !_editFileNameForm.touched || _savingRenameFile" mdbWavesEffect><span *ngIf="_savingRenameFile" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Save</button>
              </div>
            </div>
          </form>
        </div>
    </div >
</div >

<div mdbModal #deleteFileModal="mdb-modal" class="modal fade" id="deleteFileModal" tabindex="-1" role="dialog"
     aria-labelledby="deleteFileModal"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <div class="modal-content" *ngIf="this._fileDetailsModalData">
            <div class="modal-header">
            <p class="heading lead">Delete File - {{getFileName(this._fileDetailsModalData.Key)}}</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="closeDeleteFileModal()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="trash" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Are you sure want to delete the file "{{getFileName(this._fileDetailsModalData.Key)}}"? This will be permantantly deleted and if this file is used in any email, the link to this file will no longer work!</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deleteFile()" mdbWavesEffect>Yes, I am sure
                </a>
                <a type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
                   (click)="closeDeleteFileModal()" mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>

<div mdbModal #deleteFolderModal="mdb-modal" class="modal fade" id="deleteFolderModal" tabindex="-1" role="dialog"
     aria-labelledby="deleteFolderModal"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-warning" role="document">
        <div class="modal-content">
            <div class="modal-header">
            <p class="heading lead">Delete Folder - {{this._deleteFolderName.substring(0, this._deleteFolderName.length -1)}}</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="deleteFolderModal.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="trash" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Are you sure want to delete the folder "{{this._deleteFolderName.substring(0, this._deleteFolderName.length -1)}}"? This will be permantantly deleted and if this folder contains files used in any emails, the links and images to this file will no longer work!</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deleteFolder()" [disabled]="_deletingFolder" mdbWavesEffect><span *ngIf="_deletingFolder" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Yes, I am sure
                </a>
                <a type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal" (click)="deleteFolderModal.hide()"
                    mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>

<div mdbModal #copyModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="copyModalLabel"
   aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="copyModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Copy File(s) - Select destination folder</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="_loadingFolders" class="row">
                  <div class="col-auto">
                    <mdb-spinner spinnerColor="blue" spinnerType="big"></mdb-spinner>
                  </div>
                </div>
                <div *ngIf="!_loadingFolders" class="row">
                  <div *ngIf="_allFolders">
                    <app-media-server-folder-tree [folders]="_allFolders"></app-media-server-folder-tree>
                  </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="copyModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="copyFiles()" [disabled]="this._copyMoveFileLoading" mdbWavesEffect><span *ngIf="_copyMoveFileLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Copy</button>
            </div>
        </div>
    </div >
</div >

<div mdbModal #moveModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="moveModal"
   aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="moveModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Move File(s) - Select destination folder</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="_loadingFolders" class="row">
                  <div class="col-auto">
                    <mdb-spinner spinnerColor="blue" spinnerType="big"></mdb-spinner>
                  </div>
                </div>
                <div *ngIf="!_loadingFolders" class="row">
                  <div *ngIf="_allFolders">
                    <app-media-server-folder-tree [folders]="_allFolders"></app-media-server-folder-tree>
                  </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="moveModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="moveFiles()" [disabled]="this._copyMoveFileLoading" mdbWavesEffect><span *ngIf="_copyMoveFileLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Move</button>
            </div>
        </div>
    </div >
</div >
