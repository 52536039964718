<section class="view intro intro-2">
    <div class="full-bg-img rgba-stylish-strong mask flex-center">
        <div class="container animated fadeIn py-5">
            <!--Grid row-->
            <div class="row  pt-5 mt-3">
                <!--Grid column-->
                <div class="col-md-12">
                    <mdb-card bgColor="register-card">
                        <mdb-card-body>

                            <!--Header-->
                            <form [formGroup]="_codeCheckForm" (ngSubmit)="check2FACode(codeTextBox.value)">
                                <div class="form-header primary-color rounded-lg">
                                    <h3>
                                        <mdb-icon fas icon="lock" class="mt-2 mb-2"></mdb-icon> Two Factor
                                        Authentication
                                    </h3>
                                </div>

                                <!--Grid row-->
                                <div class="row mt-5 justify-content-around">
                                    <!--Grid column-->
                                    <div class="col-md-5">
                                        <!--Body-->
                                        <div class="logo-wrapper waves-light logo-background">
                                            <a tabindex="-1" href="https://marketingdelivery.co.uk/">
                                                <img src="../../../assets/images/MDLogoTransparent.png"
                                                    class="img-fluid">
                                            </a>
                                        </div>
                                        <div class="md-form">
                                            <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                                            <input formControlName="tfaCode" autocomplete="off" #codeTextBox type="text"
                                                id="codeTextBox" class="form-control" mdbInput autofocus/>
                                            <label for="codeTextBox">Two Factor Code</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <div class="col-md-12">
                                        <button *ngIf="!_loggingIn" mdbBtn
                                            class="waves-effect waves-light btn-primary rounded-lg submitBtn left-move"
                                            mdbWavesEffect>Submit
                                        </button>
                                        <button *ngIf="_loggingIn" mdbBtn
                                            class="waves-effect waves-light btn-primary rounded-lg submitBtn"
                                            type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Submitting...
                                        </button>
                                    </div>
                                    <a *ngIf="!_loggingIn" class="forgot-password-text removeAuthenticator">Remove authenticator?</a>
                                </div>
                            </form>
                        </mdb-card-body>
                    </mdb-card>
                </div>
            </div>
        </div>
    </div>
</section>
