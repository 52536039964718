import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { MessageFolderService } from 'src/app/_services/messaging/messages/message-folder.service';

@Injectable()

export class MessageFolderResolver implements Resolve<FolderDto> {
  constructor(private messageFolderService: MessageFolderService,
              private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<FolderDto> {
    return this.messageFolderService.getAll().pipe(
      catchError(() => {
        this.router.navigate(['/contact-plans']);
        return of(null);
      })
    );
  }
}
