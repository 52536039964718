import { Injectable } from '@angular/core';

import { ListFieldControlTypeDto } from './list-field-control-type-dto';

@Injectable({
  providedIn: 'root'
})

export class ListFieldControlTypeDtoAdapter {
  public adapt(item: any) {
    return new ListFieldControlTypeDto(item);
  }
}
