import { Injectable } from '@angular/core';

export class UserRoleMappingDto {

    public id: number
    public userId: number
    public roleId: number

    constructor(init: any) {
      this.id = +init.Id
      this.userId = init.UserId
      this.roleId = init.RoleId
    }
}

@Injectable({
  providedIn: 'root'
})

export class UserRoleMappingAdapter { 

  adapt(item: any): UserRoleMappingDto {
    return new UserRoleMappingDto(item)
  }
}

