<section class="view intro intro-2">
  <div class="full-bg-img rgba-stylish-strong mask flex-center">
    <div class="container animated fadeIn py-5">
      <!--Grid row-->
      <div class="row  pt-5 mt-3">
        <!--Grid column-->
        <div class="col-md-12">
          <mdb-card bgColor="register-card">
            <mdb-card-body>

              <!--Header-->
              <div class="form-header primary-color rounded-lg">
                <h3>
                  <mdb-icon fas icon="user-plus" class="mt-2 mb-2"></mdb-icon> Register
                </h3>
              </div>

              <!--Grid row-->
              <form [formGroup]="_registerForm" (ngSubmit)="register()">
                <div class="row mt-5 justify-content-around">
                  <!--Grid column-->
                  <div class="col-md-5">
                    <!--Body-->
                    <div class="logo-wrapper waves-light logo-background">
                      <a tabindex="-1" href="https://marketingdelivery.co.uk/">
                        <img src="../../../assets/images/MDLogoTransparent.png" class="img-fluid">
                      </a>
                    </div>
                    <div class="md-form">
                      <mdb-icon fas icon="address-card" class="prefix icon-color"></mdb-icon>
                      <input type="text" [ngClass]="{'is-invalid': _registerForm.get('title').errors
                                && _registerForm.get('title').touched}" id="orangeForm-title" class="form-control"
                        formControlName="title" mdbInput />
                      <label for="orangeForm-title">Your title</label>
                      <div class="invalid-feedback ml-5">Please enter your title - it must be no more than 9 characters.</div>
                    </div>
                    <div class="md-form">
                      <mdb-icon fas icon="address-card" class="prefix icon-color"></mdb-icon>
                      <input type="text" [ngClass]="{'is-invalid': _registerForm.get('firstName').errors
                                && _registerForm.get('firstName').touched}" id="orangeForm-first-name"
                        class="form-control" formControlName="firstName" mdbInput />
                      <label for="orangeForm-first-name">Your first name</label>
                      <div class="invalid-feedback ml-5">Please enter your First Name.</div>
                    </div>

                    <div class="md-form">
                      <mdb-icon fas icon="address-card" class="prefix icon-color"></mdb-icon>
                      <input type="text" [ngClass]="{'is-invalid': _registerForm.get('lastName').errors
                                && _registerForm.get('lastName').touched}" id="orangeForm-last-name"
                        class="form-control" formControlName="lastName" mdbInput />
                      <label for="orangeForm-last-name">Your last name</label>
                      <div class="invalid-feedback ml-5">Please enter your Last Name.</div>
                    </div>
                  </div>
                  <!--Grid column-->

                  <!--Grid column-->
                  <div class="col-md-5">
                    <!--Body-->
                    <div class="md-form">
                      <mdb-icon fas icon="user" class="prefix icon-color"></mdb-icon>
                      <input type="text" [ngClass]="{'is-invalid': _registerForm.get('username').errors
                                && _registerForm.get('username').touched}" id="orangeForm-username" class="form-control"
                        formControlName="username" mdbInput />
                      <label for="orangeForm-username">Your username</label>
                      <div class="invalid-feedback ml-5">Please enter your Username.</div>
                    </div>
                    <div class="md-form">
                      <mdb-icon far icon="envelope" class="prefix icon-color"></mdb-icon>
                      <input type="text" [ngClass]="{'is-invalid': _registerForm.get('email').errors
                                && _registerForm.get('email').touched}" id="orangeForm-email" class="form-control"
                        formControlName="email" mdbInput />
                      <label for="orangeForm-email">Your email</label>
                      <div class="invalid-feedback ml-5" *ngIf="_registerForm.get('email').hasError('required')
                          && _registerForm.get('email').touched">Please enter your Email.
                      </div>
                      <div class="invalid-feedback ml-5" *ngIf="_registerForm.get('email').hasError('email')
                          && _registerForm.get('email').touched">Email must be a valid Email Address.
                      </div>
                    </div>

                    <div class="md-form">
                      <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                      <input type="password" [ngClass]="{'is-invalid': _registerForm.get('password').errors
                                && _registerForm.get('password').touched}" id="password" class="form-control"
                        formControlName="password" mdbInput />
                      <label for="password">Your password</label>
                      <div class="invalid-feedback ml-5" *ngIf="_registerForm.get('password').hasError('required')
                          && _registerForm.get('password').touched">Please enter your Password.
                      </div>
                      <div class="invalid-feedback ml-5" *ngIf="_registerForm.get('password').hasError('minlength')
                          && _registerForm.get('password').touched">Password must be atleast 8 characters.
                      </div>
                      <div class="invalid-feedback ml-5" *ngIf="_registerForm.get('password').hasError('weak')
                          && _registerForm.get('password').touched">Passwords must contain one upper case letter, one
                        lower case letter and one special character.
                        <br />Special characters include: ^ $ * . [ ] &#123; &#125; ( ) ? &quot; ! @ # % / , &lt; &gt; &apos; : ; | _ ~ &#96;
                      </div>
                    </div>

                    <div class="md-form">
                      <mdb-icon fas icon="lock" class="prefix icon-color"></mdb-icon>
                      <input type="password" [ngClass]="{'is-invalid': _registerForm.get('confirmPassword').errors
                                && _registerForm.get('confirmPassword').touched
                                || _registerForm.get('confirmPassword').touched
                                && _registerForm.hasError('mismatch')}" id="password-confirm" class="form-control"
                        formControlName="confirmPassword" mdbInput>
                      <label for="password-confirm">Confirm your password</label>
                      <div class="invalid-feedback ml-5" *ngIf="_registerForm.get('confirmPassword').hasError('required')
                          && _registerForm.get('confirmPassword').touched">Please enter your Password.
                      </div>
                      <div class="invalid-feedback ml-5" *ngIf="_registerForm.hasError('mismatch')
                          && _registerForm.get('confirmPassword').touched">Passwords must match.
                      </div>
                    </div>


                  </div>
                  <!--!Grid column-->

                  <!--Grid column-->
                  <div class="col-md-10">
                    <div class="text-center">
                      <ngx-recaptcha2 #captchaElem [siteKey]="'6LeqCMAUAAAAACTk4sDYT-lbptHFg79nZL-g4MPp'"
                        [useGlobalDomain]="false" style="display: inline-block" formControlName="recaptcha">
                      </ngx-recaptcha2> <br>
                      <button *ngIf="!_registering" mdbBtn class="waves-effect waves-light btn-primary rounded-lg"
                        [disabled]="!_registerForm.valid" mdbWavesEffect>Register
                      </button>
                      <button *ngIf="_registering" mdbBtn class="waves-effect waves-light btn-primary rounded-lg"
                        type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Registering...
                      </button> <br>
                      <a class="login-text" [routerLink]="['/login']">Login Here</a>
                    </div>
                  </div>
                  <!--!Grid column-->
                </div>
              </form>
              <!--Grid row-->
            </mdb-card-body>
          </mdb-card>
        </div>
        <!--!Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
</section>