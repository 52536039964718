import { Routes } from '@angular/router';

import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/public/login/login.component';
import { RegisterComponent } from './views/public/register/register.component';
import { UserManagementComponent } from './views/admin/user-management/user-management.component';
import { UserRoleManagementComponent } from './views/admin/user-role-managment/user-role-management.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginActivate } from './_guards/auth.login-activate';
import { UserPermissionComponent } from './views/admin/user-permission/user-permission.component';
import { UserRolePermissionComponent } from './views/admin/user-role-permission/user-role-permission.component';
import { ClientManagementComponent } from './views/admin/clients/client-management/client-management.component';
import { ForgotPasswordComponent } from './views/public/forgot-password/forgot-password.component';
import { ClientCreateEditComponent } from './views/admin/clients/client-create-edit/client-create-edit.component';
import { ClientSettingsComponent } from './views/admin/clients/client-settings/client-settings.component';
import { DomainsComponent } from './views/admin/clients/client-settings/domains/domains.component';
import { AmazonSettingsComponent } from './views/admin/clients/client-settings/amazon-settings/amazon-settings.component';
import { ClientInboxesComponent } from './views/admin/clients/client-settings/client-inboxes/client-inboxes.component';
import { PostsComponent } from './views/social-media/posts/posts.component';
import { CreateEditPostComponent } from './views/social-media/posts/create-edit-post/create-edit-post.component';
import { NetworkManagementComponent } from './views/social-media/networks/network-management/network-management.component';
import { NetworkGroupManagementComponent } from './views/social-media/network-groups/network-group-management/network-group-management.component';
import { PermissionsGuard } from './_guards/permissions.guard';
import { MediaServerComponent } from './views/media-server/media-server.component';
import { ListsComponent } from './views/messaging/lists-and-contacts/lists/lists.component';
import { ContactsComponent } from './views/messaging/lists-and-contacts/contacts/contacts.component';
import { EditListComponent } from './views/messaging/lists-and-contacts/lists/edit-list/edit-list.component';
import { EditFormsComponent } from './views/messaging/lists-and-contacts/lists/web-forms/edit-forms/edit-forms.component';
import { WebFormContainerComponent } from './views/messaging/lists-and-contacts/lists/web-forms/edit-forms/web-form-container/web-form-container.component';
import { VehicleValidationComponent } from './views/tools/vehicle-validation/vehicle-validation/vehicle-validation.component';
import { EmailBuilderUnlayerComponent } from './views/messaging/messages/email-messaging/email-builder/unlayer/email-builder-unlayer.component';
import { MessagesComponent } from './views/messaging/messages/messages.component';
import { EmailPreviewComponent } from './views/messaging/messages/email-messaging/email-preview/email-preview.component';
import { SmsMessageComponent } from './views/messaging/messages/sms-messaging/sms-message.component';
import { MessageReportsComponent } from './views/messaging/messages/message-reports/message-reports.component';
import { MessageReportDetailsComponent } from './views/messaging/messages/message-reports/message-report-details/message-report-details.component';
import { QueryBuilderComponent } from './views/shared/query-builder/query-builder.component';
import { ContactsUploadHistoryComponent } from './views/messaging/lists-and-contacts/contacts/upload-history/contacts-upload-history.component';
import { MessageSendComponent } from './views/messaging/messages/messages-send/message-send.component';
import { CreateEditTriggerComponent } from './views/messaging/automation/create-edit-trigger/create-edit-trigger.component';
import { CreateEditMessageCodeComponent } from './views/messaging/automation/message-codes/edit-message-code/create-edit-message-code-step/create-edit-message-code.component';
import { ContactPlansComponent } from './views/messaging/automation/contact-plans/contact-plans.component';
import { EditContactPlanComponent } from './views/messaging/automation/contact-plans/edit-contact-plan/edit-contact-plan.component';
import { EventInfoResolver } from './_resolvers/messaging/automation/event-info-resolver';
import { LightListModelResolver } from './_resolvers/messaging/lists-and-contacts/light-lists-resolver';
import { MessageFolderResolver } from './_resolvers/messaging/messages/message-folder-resolver';
import { MessageCodesComponent } from './views/messaging/automation/message-codes/message-codes.component';
import { EditMessageCodeComponent } from './views/messaging/automation/message-codes/edit-message-code/edit-message-code.component';
import { TriggerResolver } from './_resolvers/messaging/automation/trigger-resolver';
import { ClientEtlsComponent } from './views/admin/clients/client-settings/client-etls/client-etls.component';
import { ClientETLsResolver } from './_resolvers/admin/clients/client-etls-resolver';
import { TwoFactorSetupComponent} from './views/public/cognito/two-factor-setup/two-factor-setup';
import { UserAuthenticationComponent } from './views/public/cognito/user-authentication/user-authentication.component';
import { NewPasswordComponent } from './views/public/cognito/new-password/new-password.component';
import { TwoFactorInputComponent } from './views/public/cognito/two-factor-input/two-factor-input.component';
import { ContactInteractionsComponent } from './views/messaging/lists-and-contacts/contact-interactions/contact-interactions.component';
import { ConsentHistoryComponent } from './views/messaging/lists-and-contacts/consent-history/consent-history.component';
import { MessageReportDetailsFilteredComponent } from './views/messaging/messages/message-reports/message-report-details/message-report-details-filtered/message-report-details-filtered.component';
import { MessageHistoryComponent } from './views/messaging/messages/message-history/message-history.component';
import { CreateEditExtractComponent } from './views/messaging/automation/extracts/create-edit-extract.component';
import { SingleNetworkReportComponent } from './views/social-media/reports/single-network-report/single-network-report.component';
import { EmailBuilderMosaicoComponent } from './views/messaging/messages/email-messaging/email-builder/mosaico/email-builder-mosaico.component';
import { SmsReportsComponent } from './views/messaging/messages/sms-reports/sms-reports.component';
import { AuditTrailComponent } from './views/audit-trail/audit-trail.component';
import { PendingChangesGuard } from './_guards/pending-changes-guard';
import { CreateEditMultiListTriggerComponent } from './views/messaging/automation/create-edit-multi-list-trigger/create-edit-multi-list-trigger.component';
import { BulkMessageSendComponent } from './views/messaging/messages/bulk-messages-send/bulk-message-send.component';

const _messageRouteChildren = [
  { path: '', component: MessagesComponent,
    canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'Messages'}},
  { path: 'email-builder-mosaico/:messageID', component: EmailBuilderMosaicoComponent,
    canActivate: [PermissionsGuard], canDeactivate: [PendingChangesGuard], data: {section: 'Messaging', page: 'MessagesEmail'} },
  { path: 'email-builder-unlayer', component: EmailBuilderUnlayerComponent,
    canActivate: [PermissionsGuard], canDeactivate: [PendingChangesGuard], data: {section: 'Messaging', page: 'MessagesEmail'} },
  { path: 'email-builder-unlayer/:messageID', component: EmailBuilderUnlayerComponent,
    canActivate: [PermissionsGuard], canDeactivate: [PendingChangesGuard], data: {section: 'Messaging', page: 'MessagesEmail'} },
  { path: 'email-preview/:messageID', component: EmailPreviewComponent,
    canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesEmail'} },
  { path: 'email-preview/:messageID/:listFolder/:listID', component: EmailPreviewComponent,
  canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesEmail'} },
  { path: 'sms-message', component: SmsMessageComponent,
  canActivate: [PermissionsGuard], canDeactivate: [PendingChangesGuard], data: {section: 'Messaging', page: 'MessagesSMS'} },
  { path: 'sms-message/:messageId', component: SmsMessageComponent,
  canActivate: [PermissionsGuard], canDeactivate: [PendingChangesGuard], data: {section: 'Messaging', page: 'MessagesSMS'} },
  { path: 'message-send/:messageId/:messageType', component: MessageSendComponent,
  canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'Messages'} },
  { path: 'bulk-message-send', component: BulkMessageSendComponent,
  canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'Messages'} },
  { path: 'message-history', component: MessageHistoryComponent,
  canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'Messages'} },
  { path: 'message-history/:folderId/:listId/:email/:mobile', component: MessageHistoryComponent,
  canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'Messages'} }

];

export const appRoutes: Routes = [
    { path: '', component: LoginComponent, canActivate: [LoginActivate] },
    {
      path: '',
      runGuardsAndResolvers: 'always',
      canActivate: [LoginActivate],
      children: [
        { path: 'login', component: LoginComponent, },
        { path: 'register', component: RegisterComponent, },
        { path: 'two-factor-setup', component: TwoFactorSetupComponent },
        { path: 'forgot-password', children: [
          { path: '', component: ForgotPasswordComponent },
          { path: ':forgotPasswordToken', component: ForgotPasswordComponent }
        ]},
        { path: 'user-authentication', component: UserAuthenticationComponent},
        { path: 'new-password', component: NewPasswordComponent },
        { path: 'two-factor-input', component: TwoFactorInputComponent}
      ],
    },
    // { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent },
    {
      path: '',
      runGuardsAndResolvers: 'always',
      canActivate: [AuthGuard],
      children: [
        { path: 'create-post', component: CreateEditPostComponent },
        { path: 'network-management', component: NetworkManagementComponent, canActivate: [PermissionsGuard],data: {section : 'Social', page: 'Networks'}},
        { path: 'network-group-management', component: NetworkGroupManagementComponent, canActivate: [PermissionsGuard],data: {section : 'Social', page: 'NetworkGroups'}},
        { path: 'single-network-report', component: SingleNetworkReportComponent, canActivate: [PermissionsGuard],data: {section : 'Social', page: 'Reports'}},
        { path: 'user-management', component: UserManagementComponent, canActivate: [PermissionsGuard],data: {section : 'Admin', page: 'Users'} },
        { path: 'user-role-management', component: UserRoleManagementComponent, canActivate: [PermissionsGuard],data: {section : 'Admin', page: 'UserRoles'} },
        { path: 'user-permission/:userId', component: UserPermissionComponent, canActivate: [PermissionsGuard],data: {section : 'Admin', page: 'UserPermissions'} },
        { path: 'user-role-permission/:userRoleId', component: UserRolePermissionComponent, canActivate: [PermissionsGuard],data: {section : 'Admin', page: 'UserPermissions'} },
        { path: 'client-management', children: [
          { path: '', component: ClientManagementComponent },
          { path: 'create', component: ClientCreateEditComponent },
          { path: 'edit/:clientId', component: ClientCreateEditComponent},
          { path: 'edit/:clientId/client-settings', component: ClientSettingsComponent, children: [
            { path: 'domains', component: DomainsComponent, data: { animation: 'isFarLeft' } },
            { path: 'amazon-settings', component: AmazonSettingsComponent, data: { animation: 'isLeft' } },
            { path: 'client-inboxes', component: ClientInboxesComponent, data: { animation: 'isRight' } },
            { path: 'client-etls', component: ClientEtlsComponent, data: { animation: 'isFarRight' }, resolve: {clientETLs: ClientETLsResolver }, }
          ], },
        ], canActivate: [PermissionsGuard], data: {section: 'Admin', page: 'Clients'}},

        {
          path: 'posts', children: [
            {path: '', component: PostsComponent},
            {path: 'create-post', component: CreateEditPostComponent}
          ], canActivate: [PermissionsGuard], data: {section: 'Social', page: 'Posts'}
        },

        {path: 'media-server', component: MediaServerComponent, canActivate: [PermissionsGuard],data: {section : 'Media', page: 'MediaServer'}},

        {path: 'audit-trail', component: AuditTrailComponent, data: {section : 'Tools', page: 'AuditTrail'}},

        { path: 'lists', children: [
          { path: '', component: ListsComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactLists'} },
          { path: 'edit-list/:listId', component: EditListComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactLists'} },
          { path: 'edit-forms/:listId', component: EditFormsComponent, children: [
            { path: '', component: WebFormContainerComponent },
          ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactListsForms'} },
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContact'}},

        { path: 'contact-interactions', children: [
          { path: '', component: ContactInteractionsComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactContacts'} }
        ]},
        { path: 'consent-history', children: [
          { path: '', component: ConsentHistoryComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactContacts'} }
        ]},
        { path: 'contacts', children: [
          { path: '', component: ContactsComponent, canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactContacts'}}
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContact'}},
        { path: 'contacts/:folderId/:listId/:showUploadModal', children: [
          { path: '', component: ContactsComponent, canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactContacts'}}
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContact'}},
        { path: 'contacts-upload-history', children: [
          { path: '', component: ContactsUploadHistoryComponent, canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContactContacts'}}
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'ListsAndContact'}},

        { path: 'messages', children: _messageRouteChildren, canActivate: [PermissionsGuard],
            data: {section: 'Messaging', page: 'Messages'}},
        { path: 'messages/:folderId', children: _messageRouteChildren, canActivate: [PermissionsGuard],
            data: {section: 'Messaging', page: 'Messages'}},

        { path: 'email-reports', children: [
          { path: '', component: MessageReportsComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsEmail'} },
          { path: 'details/:reportID', component: MessageReportDetailsComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsEmail'} },
            { path: 'details/:reportID/:filter', component: MessageReportDetailsFilteredComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsEmail'} },
        ],
        canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsEmail'}},

        { path: 'sms-reports', children: [
          { path: '', component: SmsReportsComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsSMS'} },
          { path: 'details/:reportID', component: MessageReportDetailsComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsSMS'} },
            { path: 'details/:reportID', component: MessageReportDetailsComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsEmail'} },
            { path: 'details/:reportID/:filter', component: MessageReportDetailsFilteredComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsEmail'} },
        ],
        canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'MessagesReportsSMS'}},


        { path: 'contact-plans', children: [
          { path: '', component: ContactPlansComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlans'}},
          { path: 'edit/:contactPlanId', component: EditContactPlanComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'} },
          { path: 'edit/:contactPlanId/create-step', component: CreateEditTriggerComponent,
          canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'},
          resolve: {triggerEvents: EventInfoResolver, lightLists: LightListModelResolver, messageFolders: MessageFolderResolver, } },
          { path: 'edit/:contactPlanId/edit-step/:triggerID', component: CreateEditTriggerComponent,
          canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'},
          resolve: {triggerEvents: EventInfoResolver, lightLists: LightListModelResolver, messageFolders: MessageFolderResolver, trigger: TriggerResolver } },
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlans'}},

        { path: 'contact-criteria', children: [
          { path: '', component: MessageCodesComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationMessageCodes'}},
          { path: 'edit/:messageCodeId', component: EditMessageCodeComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationMessageCodesMessageCodeSteps'} },
          { path: 'edit/:messageCodeId/create-message-code', component: CreateEditMessageCodeComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationMessageCodesMessageCodeSteps'} },
          { path: 'edit/:messageCodeId/edit-message-code', component: CreateEditMessageCodeComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationMessageCodesMessageCodeSteps'} },
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationMessageCodes'}},

        { path: 'extracts', children: [
          { path: '', component: ContactPlansComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlans'}},
          { path: 'edit/:contactPlanId', component: EditContactPlanComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'} },
          { path: 'edit/:contactPlanId/create-step', component: CreateEditExtractComponent,
          canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'},
          resolve: {triggerEvents: EventInfoResolver, lightLists: LightListModelResolver, messageFolders: MessageFolderResolver, } },
          { path: 'edit/:contactPlanId/edit-step/:triggerID', component: CreateEditExtractComponent,
          canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'},
          resolve: {triggerEvents: EventInfoResolver, lightLists: LightListModelResolver, messageFolders: MessageFolderResolver, trigger: TriggerResolver } },
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlans'}},

        { path: 'multi', children: [
          { path: '', component: ContactPlansComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlans'}},
          { path: 'edit/:contactPlanId', component: EditContactPlanComponent,
            canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'} },
          { path: 'edit/:contactPlanId/create-step', component: CreateEditMultiListTriggerComponent,
          canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'},
          resolve: {triggerEvents: EventInfoResolver, lightLists: LightListModelResolver, messageFolders: MessageFolderResolver, } },
          { path: 'edit/:contactPlanId/edit-step/:triggerID', component: CreateEditMultiListTriggerComponent,
          canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlansContactSteps'},
          resolve: {triggerEvents: EventInfoResolver, lightLists: LightListModelResolver, messageFolders: MessageFolderResolver, trigger: TriggerResolver } },
        ], canActivate: [PermissionsGuard], data: {section: 'Messaging', page: 'AutomationContactPlans'}},

        { path: 'tools', children: [
          { path: 'vehicle-validation', component: VehicleValidationComponent,
            canActivate: [PermissionsGuard], data: {section: 'Tools', page: 'VehicleValidation'} },
          { path: 'query-builder', component: QueryBuilderComponent,
            canActivate: [PermissionsGuard], data: {section: 'Tools', page: 'VehicleValidation'} }
        ], canActivate: [PermissionsGuard], data: {section: 'Tools', page: 'Tools'}},

      ]
    },

    { path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
  ];
