import { ListFieldOptionDto } from './list-field-option-dto';
import { ListFieldOptionDtoAdapter } from './list-field-option-dto-adapter';

export class PropertyDto {

  rowIndex: number;
  fileColumnIndex: number;
  fieldId: number;
  isIdentity: boolean;
  name: string;
  displayName: string;
  wildcard: string;
  type: number;
  fieldTypeId: number;
  controlTypeId: number;
  controlTypeName: string;
  fieldTypeName: string;
  sqlType: string;
  value: string;
  objectValue: object;
  visible: boolean;
  readonly: boolean;
  isDefault: boolean;
  isSystem: boolean;
  isWildcard: boolean;
  isDeleted: boolean;
  isUserDefined: boolean;
  isIncluded: boolean;
  orderIndex: number;
  orderIndexMinor: number;
  viewPath: string;
  addOptions: boolean;
  isNew: boolean;
  options: ListFieldOptionDto[];

  constructor(init: any) {
    this.rowIndex = init.RowIndex;
    this.fileColumnIndex = init.FileColumnIndex;
    this.fieldId = init.FieldID;
    this.isIdentity = init.IsIdentity;
    this.name = '' + init.Name;    
    this.displayName = init.DisplayName;
    this.wildcard = init.WildCard;
    this.type = init.Type;
    this.fieldTypeId = init.FieldTypeID;
    this.controlTypeId = init.ControlTypeID;
    this.controlTypeName = init.ControlTypeName;
    this.fieldTypeName = init.fieldTypeName;
    this.sqlType = init.SqlType;
    this.value = init.Value;
    this.objectValue = init.ObjectValue;
    this.visible = init.Visible;
    this.readonly = init.Readonly;
    this.isDefault = init.IsDefault;
    this.isSystem = init.IsSystem;
    this.isWildcard = init.IsWildcard;
    this.isDeleted = init.IsDeleted;
    this.isUserDefined = init.IsUserDefined;
    this.isIncluded = init.IsIncluded;
    this.orderIndex = init.OrderIndex;
    this.orderIndexMinor = init.OrderIndexMinor;
    this.viewPath = init.ViewPath;
    this.addOptions = init.AddOptions;
    this.isNew = init.IsNew || false;
    this.options = init.Options && (init.Options as []).length > 0
    ? init.Options.map(option => new ListFieldOptionDtoAdapter().adapt(option))
    : [];
  }
}




