import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';

export class SearchContactDtoRequest {
    pager: PagerDtoRequest;
    listId: number;
    contactFilter: string;

    constructor({ pagerDtoRequest, listId, contactFilter }:
        { pagerDtoRequest?: PagerDtoRequest; listId?: number; contactFilter?: string;}) {                    
        this.listId = listId;
        this.contactFilter = contactFilter;
        this.pager = pagerDtoRequest;
    }
}
