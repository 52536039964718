import { Injectable } from '@angular/core';
import { ChangeModel } from './change-model';

@Injectable({
  providedIn: 'root'
})

export class ChangeModelAdapter {
  createEmpty() {
    return new ChangeModel({
      Name: '',
      Argument: '',
      UpdateType: null,
      UpdateTypeName: '',
    });
  }
}
