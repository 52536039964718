import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-dto-request';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { GetFinalContactsDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/get-final-contacts-dto-request';

@Injectable({
  providedIn: 'root'
})

export class ContactService {
  private baseUrl = environment.apiURL;
  private controllerName = 'Contact';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  delete(contactId: number, listId: number): Observable<ExecutionResultDto> {
    return this.http
      .delete(`${ this.baseUrl }${ this.controllerName }?listId=${ listId }&contactId=${ contactId }`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  get(listId: number, contactId: number): Observable<ExecutionResultDto> {
    return this.http
      .get(`${ this.baseUrl }${ this.controllerName }?listId=${ listId }&contactId=${ contactId }`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getCountUniqueContacts(listId: number, messageType: MessageTypeEnum, contactsFilter: string) {
    var query: string;
    if (contactsFilter) {
      query = `?listId=${listId}` +
              `&messageType=${messageType}` +
              `&contactsFilter=${encodeURIComponent(contactsFilter)}`;
    } else {
      query = `?listId=${listId}` +
              `&messageType=${messageType}`;
    }

    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetCountUniqueContacts${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getContacts(getContactsSearchDtoRequest: SearchContactDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/GetContacts`, getContactsSearchDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getFinalContactsFromTwoLists(getFinalContactsDtoRequest: GetFinalContactsDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}/GetFinalContactsFromTwoListsAsync`, getFinalContactsDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getMessageValidationReport(getContactsPagerDtoRequest: PagerDtoRequest, messageCodeId: number): Observable<ExecutionResultDto> {
    var query: string = `?Page=${getContactsPagerDtoRequest.page}` +
              `&PageSize=${getContactsPagerDtoRequest.pageSize}` +
              `&SortColumn=${getContactsPagerDtoRequest.sortColumn}` +
              `&SortDirection=${getContactsPagerDtoRequest.sortDirection}` +
              `&MessageCodeId=${messageCodeId}`;


    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetMessageValidationReport${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getContactFields(listId: number): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetContactFields?listId=${listId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(addContactDtoRequest: ContactDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this.baseUrl}${this.controllerName}`, addContactDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  // retrieve contact interactions, applying filters that have been decided by the user in the front end
  // if no filter has been added, don't add one to the request
  getContactInteractions(getContactsPagerDtoRequest: PagerDtoRequest, contactInteractionsFilter?: string): Observable<ExecutionResultDto> {
    var query: string;
    if (contactInteractionsFilter) {
      query = `?Page=${getContactsPagerDtoRequest.page}` +
              `&PageSize=${getContactsPagerDtoRequest.pageSize}` +
              `&SortColumn=${getContactsPagerDtoRequest.sortColumn}` +
              `&SortDirection=${getContactsPagerDtoRequest.sortDirection}` +
              `&contactInteractionsFilter=${encodeURIComponent(contactInteractionsFilter)}`;
    }
    else{
      query = `?Page=${getContactsPagerDtoRequest.page}` +
      `&PageSize=${getContactsPagerDtoRequest.pageSize}` +
      `&SortColumn=${getContactsPagerDtoRequest.sortColumn}` +
      `&SortDirection=${getContactsPagerDtoRequest.sortDirection}`;
    }

    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetContactInteractions${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  // retrieve consent flag histories, applying filters that have been decided by the user in the front end
  // if no filter has been added, don't add one to the request
  getConsentFlagHistories(getContactsPagerDtoRequest: PagerDtoRequest, selectedTarget: string, consentFilter?: string): Observable<ExecutionResultDto> {
    var query: string;
    if (consentFilter) {
      query = `?SelectedTarget=${selectedTarget}` +
              `&Page=${getContactsPagerDtoRequest.page}` +
              `&PageSize=${getContactsPagerDtoRequest.pageSize}` +
              `&SortColumn=${getContactsPagerDtoRequest.sortColumn}` +
              `&SortDirection=${getContactsPagerDtoRequest.sortDirection}` +
              `&consentFilter=${encodeURIComponent(consentFilter)}` ;
    }
    else{
      query = `?SelectedTarget=${selectedTarget}` +
      `&Page=${getContactsPagerDtoRequest.page}` +
      `&PageSize=${getContactsPagerDtoRequest.pageSize}` +
      `&SortColumn=${getContactsPagerDtoRequest.sortColumn}` +
      `&SortDirection=${getContactsPagerDtoRequest.sortDirection}`;
    }
    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetConsentFlagHistories${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  // get the fields that will be used in the filter popup
  getInteractionFields(){
    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetContactInteractionFields`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  // get the fields that will be used in the filter popup
  getConsentHistoryFields(){
    return this.http
    .get(`${this.baseUrl}${this.controllerName}/GetConsentHistoryFields`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}

