import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserDto } from 'src/app/_models/admin/users/user-model';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateEditUserService {

  _baseUrl: string = environment.apiURL;

  currentUser: UserDto;

constructor(private http: HttpClient,
            private executionResultAdapter: ExecutionResultAdapter,
  ) { }

createUser(model: any) {
  return this.http.post(this._baseUrl + 'User', model);
}

updateUser(user: UserDto): Observable<ExecutionResultDto> {
  return this.http.put(this._baseUrl + 'User', user).pipe(map((data: any) =>
                       this.executionResultAdapter.adapt(data)));
}

updateEditComponentId(user: UserDto) {
  this.currentUser = user;
}

}
