<div class="container-fluid">
  <div class="row m-4">

    <div class="col-md-10 offset-md-1 mb-4">
      <!--Card-->
      <mdb-card cascade="true" narrower="true">
        <!--Card header-->
        <div class="view view-cascade card-header text-center primary-color narrower py-2 mx-4 mb-3 d-flex justify-content-center align-items-center">

          <h4 class="h4-responsive card-header-text">Client ETLs</h4>

        </div>
        <!--/Card header-->            
        <div class="px-4 mb-2">
          <div class="row">
            <div class="col-md-3 offset-md-9">
              <button
              *ngIf="this._userPermissions.Admin.Clients.Create" 
                type="button" 
                mdbBtn 
                color="secondary" 
                class="rounded" 
                mdbWavesEffect
                (click)="newETLModal.show()">Create New ETL
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">

              <ngx-datatable                  
                  class="material striped"
                  [columnMode]="_columnMode.force"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [rowHeight]="50"
                  [scrollbarV]="false"
                  [scrollbarH]="true"
                  [rows]="_rows"               
                  >                   
                     
                    <ngx-datatable-column *ngFor="let col of _columns" [headerClass]="'lists-table-headers'" [name]="col.name" [prop]="col.prop">
                        
                        <ng-template let-row="row" let-value="value" let-index="index" ngx-datatable-cell-template>
                          <div [ngSwitch]="col.prop">
                            <div *ngSwitchCase="'CreatedDate'">
                              {{value && isDate(value) ? (value | date:'dd/MM/yyyy HH:mm:ss') : value}}
                            </div>
                            <div *ngSwitchCase="'UpdatedDate'">
                              {{value && isDate(value) ? (value | date:'dd/MM/yyyy HH:mm:ss') : value}}
                            </div>
                            <div *ngSwitchCase="'DecommissionedDate'">
                              {{value && isDate(value) ? (value | date:'dd/MM/yyyy HH:mm:ss') : value}}
                            </div>
                            <div *ngSwitchCase="'SourceDirectory'" class="file-url">
                              {{value}}
                            </div>
                            <div *ngSwitchCase="'ActiveFlag'">                              
                              <div class="switch">
                                <label>
                                  Off
                                  <input type="checkbox" [value]="value" [checked]="value" (click)="toggleETL(row)">
                                  <span class="lever"></span> On
                                </label>
                              </div>
                            </div>                             
                            <div *ngSwitchDefault>
                              {{value}}
                            </div>
                        </div>
                        
                        </ng-template>                      
                      
                    </ngx-datatable-column>                    

                  <ngx-datatable-column
                      name="Actions" [width]="100" [maxWidth]="100" [cellClass]="'lists-table-right-cells'" [headerClass]="'lists-table-headers'" [frozenRight]="'true'" prop="ClientId">
                      <ng-template let-row="data" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <div class="row button-section rounded mt-1 mr-1 ml-2 text-right">                          
                          <div class="col-md-6 icon-padding p-0 mt-2 mb-1">               
                            <a *ngIf="this._userPermissions.Admin.Clients.Edit && isDealerWebSalesEtl(rowIndex)"
                            (click)="enquiryMaxGetDealerInformation(value)">
                            <mdb-icon fas icon="edit" size="lg" class="options-icon mb-1 mt-1"></mdb-icon></a>
                          </div>
                        </div>
                      </ng-template>
                    </ngx-datatable-column> 

                    <!-- Custom table footer -->
                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="_pageCount" let-pageSize="_pageLimit"
                      let-curPage="_currentPage" let-offset="_pageOffset">
                      <div class="table-custom-footer">
                        <div>
                          Rows: {{ _pageCount }}
                        </div>
                        <select (change)="paginateTable($event.target.value)" class="table-page-limit-select">
                          <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                            [selected]="option.value == _pageLimit">
                            {{option.value}} per page
                          </option>
                        </select>
                        <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                          [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                          [pagerNextIcon]="'datatable-icon-skip'" 
                          [page]="_currentPage" 
                          [size]="_pageLimit"
                          [count]="_pageCount" 
                          [hidden]="!((_pageCount / _pageLimit) > 1)"
                          (change)="paginateTable(null, $event.page)">
                        </datatable-pager>
                      </div>
                    </ng-template>
                  </ngx-datatable-footer>
            
                   
                    
                  </ngx-datatable>

            </div>
          </div>
        </div>
      </mdb-card>
      <!--/Card-->
    </div>
  </div>
</div>

<div mdbModal #newETLModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="newETLModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="newETLModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="newETLModalLabel">Create New ETL</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="_newETLForm">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="md-form remove-top-margin">
                <mdb-select
                  [options]="_availableETLsDropdown"
                  [visibleOptions]="10"
                  [filterEnabled]="true"
                  formControlName="DMS"
                  label="Select DMS">
                </mdb-select>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="switch">
                <p>Migrating ETL</p>
                <label>
                  <input type="checkbox" formControlName="migration">
                  <span class="lever"></span>
                </label>
              </div>
            </div>
          </div>          
          <div *ngIf="_loading" class="row justify-content-center" [@slide]>
            <p>Please wait while the ETL for {{this.clientManagementService._currentClient.KnownAs}} is setup. This can take a couple of minutes.</p>
            <div class="loading-bar-container">
              <mdb-progress-bar class="progress primary-color-dark mb-1" mode="indeterminate"></mdb-progress-bar>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-center">
        <button [disabled]="_newETLForm.invalid || _loading" (click)="submitNewETL()" type="button" mdbBtn color="primary" class="waves-light rounded" aria-label="Create" mdbWavesEffect>Create</button>
        <button type="button" mdbBtn color="secondary" class="waves-light rounded" aria-label="Close" (click)="newETLModal.hide()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #dealerLocationsModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dealerLocationsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content rounded">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="dealerLocationsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="dealerLocationsModalLabel">Dealer Locations</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="_dealerLocationsForm"> 
          <div formArrayName="dealerLocations">
              <div class="row text-left mb-2">
                <div class="col-4 font-weight-bold">
                  Dealer Description
                </div>
                <div class="col-8 font-weight-bold">
                  Dealer Location                
                </div>              
              </div>
              <div class="scrollbar scrollbar-primary max-height-400">
                <div class="row text-left mb-1" [formGroupName]="i" *ngFor="let location of dealerLocations.controls; let i = index;">
                  <div class="col-4">
                    {{location.controls.description.value}}
                  </div>
                  <div class="col-8">
                    <div><input formControlName="location" mdbInput type="text" class="form-control" [value]="location.controls.location.value"></div>
                    <div class="text-danger" [hidden]="!(dealerLocations.controls[i].get('location').errors?.required && 
                    dealerLocations.controls[i].get('location').touched)">This field is required</div>
                  </div> 
                </div>
              </div>             
          </div>     
        </form>
      </div>
      <div class="modal-footer justify-content-center">
        <button [disabled]="_dealerLocationsForm.invalid" type="button" (click)="enquiryMaxUpdateDealerInformation()" mdbBtn color="primary" class="waves-light rounded" aria-label="Update" mdbWavesEffect>Update</button>        
        <button type="button" mdbBtn color="secondary" class="waves-light rounded" aria-label="Close" (click)="dealerLocationsModal.hide()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>