export const social = {
  insights: 'Individual Post Insights',
  totalPosts: 'Total Posts',
  totalFans: 'Total Fans',
  newFans: 'New Fans',
  rate: 'Eng. Rate',
  restrictions: 'Due to restrictions from the Facebook API, we currently don`t have access to this information.',
  engagement: 'Engagement Rate for Meta is the total reactions plus the post clicks and comments divided by the number of people your page reached.'
};

export const postContentColumnsNames = {
  facebook: {
    clicks: 'Clicks',
    reach: 'Reach',
    reactions: 'Reactions',
   /* lastColumn: 'Eng. Reach'*/
  },
  linkedin: {
    reach: 'Reach',
    clicks: 'Clicks',
    lastColumn: 'Shares'
  },
  twitter: {
    twitterLikeCount: 'Likes',
    twitterImpressionCount: 'Impression',
    twitterReactions: 'Retweets',
    twitterReplies: 'Replies',
    twitterProfileViews: 'Profile Views'
  },
  instagram: {
    saved: 'Saved',
    reach: 'Reach',
    likes: 'Likes',
    comments: 'Comments',
    lastColumn: 'Video Views'
  }
};

export const IconPath = {
  1: './assets/images/Facebook.png',
  2: './assets/images/Twitter.png',
  3: './assets/images/Facebook.png',
  4: './assets/images/Instagram.png',
  5: './assets/images/LinkedIn.png'
};

