import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormGroup, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AuditTrailService } from "src/app/_services/audit-trail/audit-trail.service";
import { QueryBuilderComponent } from "../shared/query-builder/query-builder.component";
import { ClockPickerComponent, MDBDatePickerComponent, ModalDirective } from "ng-uikit-pro-standard";
import { SelectListOption } from "src/app/_models/system/select-list-option";
import { DataTableColumn } from "src/app/_models/messaging/datatable-column";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { NotificationService } from "src/app/_services/notification.service";
import { DatePipe } from "@angular/common";
import { PaginationDataService } from "src/app/_services/tables/pagination-data/pagination-data.service";
import { SearchDataService } from "src/app/_services/tables/search-data/search-data.service";
import { HelperService } from "src/app/_services/system/helpers/helper.service";
import { LoadingSpinnerService } from "src/app/_services/loading-spinner/loading-spinner.service";
import { ClientManagementService } from "src/app/_services/admin/clients/client-management.service";
import { ClientETLsDto } from "src/app/_models/admin/clients/client-etl-dto";
import { ClientETLsDtoAdapter } from "src/app/_models/admin/clients/client-etl-dto.adapter";
import { throttleTime } from "rxjs/operators";
import { ExecutionResultDto } from "src/app/_models/execution-result-model";
import * as moment from "moment";
import { GetClientAuditTrailLogsRequestModel } from "src/app/_models/audit-trail/get-audit-trail-logs-request-model";
import { ExecutionResult } from "src/app/_models/execution-result-enum";
import { LocalStorageService } from "src/app/_services/system/localStorage/localStorage.service";

@Component({
    selector: 'app-audit-trail',
    templateUrl: './audit-trail.component.html',
    styleUrls: ['./audit-trail.component.scss']
  })
  export class AuditTrailComponent implements OnInit {

    title: string = 'Marketing Delivery Audit Trail'
    optionsName: string = 'Audit Trail Options'
    schemaTablesFormGroup: FormGroup;

  allColumns: DataTableColumn[] = [];
  columns: DataTableColumn[] = [];
  columnMode = ColumnMode;
  exportRows: { [prop: string]: any }[] = [];
  exporting = false;
  rows: { [prop: string]: any }[] = [];
  selectedListId: number = 0;
  tableHasRows = false;
  filterInteractionRules = '';
  pageSizeOptions: number[] = [10, 25, 50, 100];
  clientETLs: Array<ClientETLsDto>;
  listOfDMSs: SelectListOption[];
  listOfTypes: SelectListOption[];
  selectedDMSName: string;
  selectedType: string;
  clientDatabaseName: string;
  maxReturnRecords: number = 1000;

  // variables for the table
  currentPage = 1;
  pageCount = 0;
  pageLimit = 10;
  pageOffset = 0;

  // variables for pagination Data
  pageTitle = 'Audit-Trail';

  isFilterDialogOpenningFirstTime: boolean = true;

  @ViewChildren(MDBDatePickerComponent) datepickers: QueryList<MDBDatePickerComponent>;
  @ViewChildren(ClockPickerComponent) timepickers: QueryList<ClockPickerComponent>;
  @ViewChild('filterInteractionsModal', { static: true }) filterInteractionsModal: ModalDirective;    
  @ViewChild('queryBuilder', { static: true }) queryBuilder: QueryBuilderComponent;
    
  constructor (
      private auditTrailService: AuditTrailService,
      private notificationService: NotificationService,
      private datePipe: DatePipe,
      private paginationDataService: PaginationDataService,
      private searchDataService: SearchDataService,
      private helperService: HelperService,
      private loadingSpinnerService: LoadingSpinnerService,
      private clientManagementService: ClientManagementService,
      private clientETLsDtoAdapter: ClientETLsDtoAdapter,
      private localStorageService: LocalStorageService
    ) {}

    ngOnInit() {
      this.schemaTablesFormGroup = new UntypedFormGroup({
        schemas: new UntypedFormControl(
          ''
        ),
        types: new UntypedFormControl(
          ''
        ),
      });

      this.filterInteractionsModal.onShown.asObservable().subscribe(() => {
        if (this.isFilterDialogOpenningFirstTime) {
          this.initQueryBuilder(this.selectedType);
          this.isFilterDialogOpenningFirstTime = false;
        } else {        
          this.filterInteractionRules = '';
        }
      })

      this.initDMSsAndTypes();
    } 

    initDMSsAndTypes() {
      this.clientManagementService.getAllETLs(Number(this.localStorageService.getCurrentClientId)).subscribe(result => {
        this.clientETLs = this.clientETLsDtoAdapter.adaptArray(result.data); 
        var dmsId = 1;
        
        this.clientDatabaseName = this.clientETLs[0].DatabaseName;

        var grouppedDMSs = [...new Set(this.clientETLs.filter(item => item.Type !== 'Consent').map(item => item.DMS))];
        this.listOfDMSs = grouppedDMSs.map((dms: string) => new SelectListOption(dmsId++, dms));
      });
    }

    getAuditTrailData(pageInfo?: any) {
      this.loadingSpinnerService.loading();
      var pageNumber = pageInfo ? pageInfo : 1;

      if (pageInfo) {
        this.paginationDataService.paginationData.page = pageInfo;
      }
      else if (this.paginationDataService.paginationData.page) {
        pageNumber = this.paginationDataService.paginationData.page;
      }

      this.paginationDataService.SetPaginationDataToLocalStorage(pageNumber, this.pageLimit, '', '', this.pageTitle);

      const getClientAuditTrailLogsRequestModel = new GetClientAuditTrailLogsRequestModel({
        DatabaseName: this.clientDatabaseName,
        DMS: this.getDms(),
        Type: this.selectedType,
        Page: pageNumber,
        PageSize: this.maxReturnRecords,
        Filter: this.filterInteractionRules
      });

      this.auditTrailService.getClientAuditTrailLogs(getClientAuditTrailLogsRequestModel).subscribe(executionResultDto => {
        
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.pageCount = executionResultDto.data.length;
          this.pageOffset = pageNumber - 1;
  
          this.tableHasRows = this.pageCount > 0;
  
          this.allColumns = this.columns = this.rows = [];
  
          if (!this.tableHasRows) {
            this.loadingSpinnerService.stopLoading();
            return;
          }

          var propertyNames = Object.keys(executionResultDto.data[0]);

          propertyNames.forEach(property => {
            var dataColumn = new DataTableColumn({ name: property, prop: property, visible: true });
            this.allColumns.push(dataColumn);
          });
          this.columns = this.allColumns;
          this.rows = this.getRows(executionResultDto);
  
        } else {          
          this.loadingSpinnerService.stopLoading();
          this.notificationService.showError(executionResultDto.message);          
        }

        this.loadingSpinnerService.stopLoading();
      });      
    }

    paginateTable(event?: any) {
      if (event !== undefined) {
        this.pageLimit = event.pageSize;
        this.currentPage = + event.pageIndex + 1;
        this.pageOffset = + event.pageIndex;
      }
  
      this.paginationDataService.SetPaginationDataToLocalStorage(this.currentPage, this.pageLimit, '', '', this.pageTitle);
    }

    interactionsFilterSave(event) {
      if (!event[1]) {
        this.filterInteractionRules = '';
      } else {
        this.filterInteractionRules = event[1].sql;
      }
      this.searchDataService.SetSearchData(this.filterInteractionRules, this.pageTitle);
      this.getAuditTrailData();
      this.filterInteractionsModal.hide();
    }

    initQueryBuilder(auditTrailType: string) {
      this.queryBuilder.getListFields(this.selectedListId, 'audit-trail', auditTrailType);
    }

    getRows(executionResultDto: ExecutionResultDto, exporting?) {
      let rows: { [prop: string]: any }[] = [];
      rows = executionResultDto.data.map(record => {
        const row: { [prop: string]: any } = {};
        Object.keys(record).map(key => {
          if (record[key] !== null && record[key] !== undefined) {
            if (this.isDate(record[key])) {
              const date = new Date(record[key]);
              row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
            } else if (record[key].toString().toLowerCase() === 'true') {
              row[key] = 'Yes';
            } else if (record[key].toString().toLowerCase() === 'false') {
              row[key] = 'No';
            } else {
              row[key] = record[key];
            }
          } else {
            if (exporting) {
              row[key] = '';
            }
          }
        });
        return row;
      });
      return rows;
    }

    isDate(value) {
      return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
    }

    export(pageInfo?: any) {
      try {
      this.exporting = true;

      const pageNumber = pageInfo ? pageInfo : 1;
      
      const getClientAuditTrailLogsRequestModel = new GetClientAuditTrailLogsRequestModel({
        DatabaseName: this.clientDatabaseName,
        DMS: this.getDms(),
        Type: this.selectedType,
        Page: pageNumber,
        PageSize: this.maxReturnRecords,
        Filter: this.filterInteractionRules
      });

      this.auditTrailService.getClientAuditTrailLogs(getClientAuditTrailLogsRequestModel).subscribe(executionResultDto => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.exportRows = this.getRows(executionResultDto, true);
          const today = new Date();
          const fileName = `${this.pageTitle}_${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

          this.helperService.exportToCSV(fileName, this.exportRows);

        } else {
          this.notificationService.showError(executionResultDto.message);
          return;
        }
        this.exporting = false;
      });
    } catch (error) {
      this.notificationService.showError(error.message);
      this.exporting = false;
    }
    }

    onDmsSelected(event) {
        this.selectedDMSName = event.label;
        
        var typeId = 1;
        var dmsTypes = this.clientETLs.filter((clientETL) => {
          return clientETL.DMS === this.selectedDMSName;
        });

        this.listOfTypes = [];
        this.listOfTypes = dmsTypes.map((clientETL: ClientETLsDto) => new SelectListOption(typeId, clientETL.Type));  
        this.schemaTablesFormGroup.get("types").setValue(null);
    }

    onTypeSelected(event) {
      this.selectedType = event.label;
      this.initQueryBuilder(this.selectedType);

      this.getAuditTrailData();
    }

    getDms() {
      if (this.listOfTypes.length > 1 && this.listOfTypes.every(elem => ["Sales", "Service"].indexOf(elem.label) > -1)) {
        if (this.selectedType === 'Sales') {
          return this.selectedDMSName + this.selectedType;
        } else {
          return this.selectedDMSName;
        }
      } else {
        return this.selectedDMSName;
      }
    }
  }