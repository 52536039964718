import { Injectable } from '@angular/core';
import { ListTableModel } from './list-table-model';

@Injectable({
  providedIn: 'root'
})

export class ListTableModelAdapter {
  adapt(item: any): ListTableModel {
    return new ListTableModel(item);
  }

  adaptArray(init: Array<any>): ListTableModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new ListTableModel(table))
      : [new ListTableModel(init[0])];
    } else {
      return [];
    }
  }
}


