import { Injectable } from "@angular/core";

export class HasClientSettingsAndDomainsResponse {
    public HasSettingsAndDomains: boolean;
    public Message: string;

    constructor (init: any) {
        this.HasSettingsAndDomains = init.HasSettingsAndDomains;
        this.Message = init.Message;
    }
}

@Injectable({
    providedIn: 'root'
  })
  
  export class HasClientSettingsAndDomainsResponseAdapter {
    adapt(item: any) {
      return new HasClientSettingsAndDomainsResponse(item);
    }
  }