import { Injectable } from '@angular/core';
import { MessageLinkDto } from './mesage-link-dto';

@Injectable({
  providedIn: 'root'
})

export class MessageLinkDtoAdapter {
  adapt(item: any): MessageLinkDto {
    return new MessageLinkDto(item);
  } 
}
