import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { UserRoleService } from 'src/app/_services/admin/users/user-role-service';
import { UserRoleDto } from 'src/app/_models/admin/users/user-role-model';
import { SearchDataService } from 'src/app/_services/tables/search-data/search-data.service';


@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;

  // Variables for Table
  _userRoles: UserRoleDto[] = [];
  _elements: any = [];

  _headerElements: any[] = [{ name: 'id', sortable: true },
  { name: 'name', sortable: true },
  { name: 'edit', sortable: false }];

  _previous: any;
  _maxVisibleItems = 8;

  // Variable for sending id to sibling component.
  id: number;
  toEditUserRole: string;
  createUserRole: boolean;

  // variable for search data
  _pageTitle = 'User-Role-Management';

  @Input() searchText: string = '';
  @Output() toEditUserRoleEvent = new EventEmitter<string>();
  @Output() comingFromEvent = new EventEmitter<string>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private userRoleService: UserRoleService,
    private searchDataService: SearchDataService
  ) { }

  ngOnInit() {

    if (this.searchDataService.searchData.lastPage != this._pageTitle) {
      this.searchDataService.SetSearchData('', this._pageTitle)
    }
    this.getUserRoles();

    this.mdbTable.setDataSource(this._elements);
    this._elements = this.mdbTable.getDataSource();
    this._previous = this.mdbTable.getDataSource();
  }

  getUserRoles() {
    this.userRoleService.getAll().subscribe((userRoles: []) => {
      this._userRoles = userRoles;
    }, error => {
    }, () => {
      this._userRoles.forEach(userRole => {
        this._elements.push({
          id: userRole.id,
          name: userRole.userRoleName
        });

      });
      this.ngAfterViewInit();
      this.mdbTable.setDataSource(this.mdbTable.getDataSource());
      if (this.searchDataService.searchData.lastPage === this._pageTitle) {
        this.searchText = this.searchDataService.searchData.searchTerm;
        this.searchItems();
      }
    });
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this._maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  emitDataSourceChange() {
    this.mdbTable.dataSourceChange().subscribe();
  }

  searchItems() {
    const dataSource = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this._previous);
      this._elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this._elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(dataSource);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  };

  editUserRole(id) {
    this.swapToEditComponent('createEditUserRole');
    this.comingFromFunc(false);
    const userRole = this._userRoles.find(_ => _.id == id);
    if (userRole) this.updateEditComponentId(userRole);
  }

  swapToEditComponent(page) {
    this.toEditUserRoleEvent.emit(page);
  }

  comingFromFunc(comingFrom) {
    this.comingFromEvent.emit(comingFrom);
  }

  updateEditComponentId(userRole) {
    this.userRoleService.updateEditComponent(userRole);
  }
}
