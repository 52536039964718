export class SmsMessageDto {
    messageId: number;
    folderId: number;
    isDeleted: boolean;
    messageName: string;
    smsContent: string;
    clientInboxId: number;

    constructor(init: any) {
        this.messageId = init.MessageID;
        this.folderId = init.FolderID;
        this.isDeleted = init.IsDeleted;
        this.messageName = init.MessageName;
        this.smsContent = init.SMSContent;
        this.clientInboxId = init.ClientInboxID;
    }
}
