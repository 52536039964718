import { ListTypeEnum } from './list-type-enum';

export class ListTableModel {

  public ListID: number;
  public ListName: string;
  public ListType: ListTypeEnum;
  public FolderID: number;
  public FolderName: string;
  public Comment: string;
  public CreatedByID: number;
  public UpdatedByID: number;

  constructor(init: any) {
    this.ListID = init.ListID;
    this.ListName = init.ListName;
    this.ListType = init.ListType;
    this.FolderID = init.FolderID;
    this.FolderName = init.FolderName;
    this.Comment = init.Comment;
    this.CreatedByID = init.CreatedByID;
    this.UpdatedByID = init.UpdatedByID;
  }
}
