import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-comment-modal',
  templateUrl: './subscription-comment-modal.component.html',
  styleUrls: ['./subscription-comment-modal.component.scss']
})
export class SubscriptionCommentModalComponent implements OnInit {
  subscriptionCommentFormGroup: UntypedFormGroup;
  title = 'Comment';

  constructor(
    private dialogRef: MatDialogRef<SubscriptionCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(): void {
    this.subscriptionCommentFormGroup = new UntypedFormGroup({
        comment: new UntypedFormControl('', Validators.required)
      }
    );
  }

  save(): void {
    this.dialogRef.close({ isNeedToUpdate: true, comment: this.subscriptionCommentFormGroup.get('comment').value });
  }
}
