import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { AddPageDtoRequest } from 'src/app/_models/social-media/networks/add-page-dto-request';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'Instagram';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) {
  }

  get(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  post(addPageDtoRequest: AddPageDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}`, addPageDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  delete(pageId: string): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/${pageId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
}