
export class MessagePreviewDto {
  public RowNum: number;
  public ContactID: number;
  public Firstname: string;
  public Surname: string;
  public Email: string;
  public Subject: string;
  public HtmlContent: string;

  constructor(init: any) {
    this.RowNum = init.RowNum;
    this.ContactID = init.ContactID;
    this.Firstname = init.Firstname;
    this.Surname = init.Surname;
    this.Email = init.Email;
    this.Subject = init.Subject;
    this.HtmlContent = init.HtmlContent;
  }
}
