import { Injectable } from '@angular/core';
import { DatahubFieldDto as DatahubFieldDto } from './datahub-field-dto';


@Injectable({
  providedIn: 'root'
})

export class DatahubFieldDtoAdapter {
  public adapt(id: number, init: any) {
    return new DatahubFieldDto(id, init);    
  }
}
