
<div class="row p-4">            
    <div class="col-3 rounded justify-content-center d-flex align-items-center p-3 square secondary-color">                 
        <mdb-icon style="color: white;" fas icon="{{faIconName}}" size="3x"></mdb-icon>
    </div>  
    <div class="col">
        <h5>{{headlineText}}</h5>
        <span class="mr-3 headline-number">{{headlineNumber | number}}</span>                            
        <mdb-icon class="mr-1 {{colourForPercentage}}-text" fas icon="{{faIconNameForPercentage}}"></mdb-icon>
        <span class="{{colourForPercentage}}-text">{{percentageDifference | number : '1.2-2'}}%</span> 
    </div>
</div>
