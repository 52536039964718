import { Injectable } from '@angular/core';

export class LinkImageModel {
  public LinkImageID: number;
  public FileUrl: string;
  public Guid: number;
  public FileName: string;
  public MediaServer: boolean;

  constructor(init: any) {
    this.LinkImageID = init.LinkImageID;
    this.FileUrl = init.FileUrl;
    this.Guid = init.Guid;
    this.FileName = init.FileName;
    this.MediaServer = init.MediaServer === undefined ? true : false;
  }
}

@Injectable({
  providedIn: 'root'
})

export class LinkImageModelAdapter {
  adapt(item: any): LinkImageModel {
    return new LinkImageModel(item);
  }
  createEmpty() {
    return new LinkImageModel({
      LinkImageID : 0,
      URL : null,
      Guid : null,
      FileName : null,
      MediaServer: false
    });
  }
}
