<div class="row ml-4 mr-4 mt-4 mb-1">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
            py-2 mx-4 mb-3 d-flex justify-content-center">
        <h4 class="page-title">Edit Contact Criteria</h4>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="text-center">
        <section>
          <form [formGroup]="_configuratorFormGroup" (ngSubmit)="this.configuratorFormGroupSubmit()">
            <div class="container-fluid">
              <input type="hidden" formControlName="messageCodeId" />
              <div class="row mt-3">
                <div class="col-md-3">
                  <div class="md-form">
                    <input mdbInput type="text" maxlength="225" id="title" class="form-control"
                      formControlName="title" />
                    <label for="title">Contact Criteria Group Name</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="md-form">
                    <div class="md-form remove-top-margin folder-select">
                      <mdb-select [options]="_folderSelectListOptions" [filterEnabled]="true" [visibleOptions]="10"
                        label="Contact Criteria Group Folder" formControlName="folderId"
                        (selected)="folderSelected($event)">
                      </mdb-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mt-3">
                    <div class="col-md-3">
                      <button *ngIf="this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.View"
                        type="button" [routerLink]="['/contact-criteria/']" class="btn btn-secondary rounded">
                        <span class="text-truncate">
                          <mdb-icon fas icon="arrow-left"></mdb-icon>
                          Back
                        </span>
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button *ngIf="this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Create"
                        type="button" class="btn btn-secondary rounded" (click)="openCreateStep()">
                        <span class="text-truncate">
                          <mdb-icon fas icon="plus" size="lg"></mdb-icon>
                          Create New Contact Criteria
                        </span>
                      </button>
                    </div>
                    <div class="col-md-3">
                      <button *ngIf="this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Edit"
                        type="submit" [disabled]="!_configuratorFormGroup.valid" class="btn btn-secondary rounded">
                        <span class="text-truncate">
                          <mdb-icon fas icon="save" size="lg"></mdb-icon>
                          Save
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>

<div class="row ml-2 mr-2 mb-2">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 mb-3 d-flex justify-content-center">
        <div class="col-md-12">
          <h4 class="page-title">Contact Criteria</h4>
        </div>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="text-center pb-4">
        <section>
          <form [formGroup]="_stepsFormGroup">
            <div class="container-fluid">
              <!-- First Row -->
              <div class="row">
                <div class="col-md-12">
                  <ngx-datatable class="material striped" [columnMode]="_columnMode.force" [headerHeight]="50"
                    [footerHeight]="50" [rowHeight]="50" [scrollbarV]="false" [scrollbarH]="true" [rows]="_rows"
                    [externalPaging]="true" [count]="_pageCount" [offset]="_pageOffset" [limit]="_pageLimit"
                    (page)="getSteps($event)">

                    <ngx-datatable-column *ngFor="let col of _columns" [headerClass]="'lists-table-headers'"
                      [name]="col.name" [prop]="col.prop">
                      <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <div [ngSwitch]="col.prop">
                          <div *ngSwitchCase="'startDate'">
                            {{value && isDate(value) ? (value | date:'dd/MM/yyyy HH:mm:ss') : value}}
                          </div>
                          <div *ngSwitchCase="'endDate'">
                            {{value && isDate(value) ? (value | date:'dd/MM/yyyy HH:mm:ss') : value}}
                          </div>
                          <div *ngSwitchDefault>
                            {{value}}
                          </div>
                        </div>

                      </ng-template>

                    </ngx-datatable-column>

                    <ngx-datatable-column
                      *ngIf="this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Edit || this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Delete"
                      name="Actions" [width]="105" [maxWidth]="105" [cellClass]="'lists-table-right-cells'"
                      [headerClass]="'lists-table-headers'" [frozenRight]="'true'" prop="messageCodeStepId">
                      <ng-template let-row="data" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        <div class="row button-section rounded mt-1 mr-1 ml-1 justify-content-center text-center">
                          <div class="col-md-3 icon-padding p-0 mt-2 mb-1 ">
                            <a (click)="trialRun(rowIndex)">
                              <mdb-icon fas icon="search" size="lg" class="options-icon mb-1 mt-1"></mdb-icon>
                            </a>
                          </div>
                          <div class="col-md-3 icon-padding p-0 mt-2 mb-1 ">
                            <a *ngIf="this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Edit">
                              <mdb-icon fas icon="edit" size="lg" class="options-icon mb-1 mt-1"
                                (click)="editStep(value)"></mdb-icon>
                            </a>
                          </div>
                          <div class="col-md-3 icon-padding p-0 mt-2 mb-1 ">
                            <a *ngIf="this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Delete">
                              <mdb-icon fas icon="trash" size="lg" class="options-icon mb-1 mt-1"
                                (click)="confirmDeleteStep(value)"></mdb-icon>
                            </a>
                          </div>
                          <div class="col-md-3 icon-padding p-0 mt-2 mb-1 ">
                            <a *ngIf="this._userPermissions.Messaging.AutomationMessageCodesMessageCodeSteps.Edit">
                              <mdb-icon fas icon="copy" size="lg" class="options-icon mb-1 mt-1"
                                (click)="confirmCopyStep(value)"></mdb-icon>
                            </a>
                          </div>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <!-- Custom table footer -->
                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="_pageCount" let-pageSize="_pageLimit"
                      let-curPage="_currentPage" let-offset="_pageOffset">
                      <div class="table-custom-footer">
                        <div>
                           Rows: {{ _pageCount }}
                        </div>
                        <select (change)="onLimitChange($event.target.value)" class="table-page-limit-select">
                          <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                            [selected]="option.value == _pageLimit">
                            {{option.value}} per page
                        </option>
                        </select>
                        <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                          [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                          [pagerNextIcon]="'datatable-icon-skip'" [page]="_currentPage" [size]="_pageLimit" [count]="_pageCount"
                          [hidden]="!((_pageCount / _pageLimit) > 1)" (change)="getSteps($event.page)">
                        </datatable-pager>
                      </div>
                    </ng-template>
                  </ngx-datatable-footer>
                  </ngx-datatable>
                </div>
              </div>
              <!-- /First Row -->
            </div>
          </form>
        </section>
      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>

<!-- Show Trial Run Results Modal -->
<div mdbModal [config]="{backdrop: 'static'}" #trialRunModal="mdbModal" class="modal fade modal-overflow"
tabindex="-1" role="dialog" aria-labelledby="trialRunModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fluid" role="document">
    <div class="modal-content rounded">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="trialRunModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="trialRunModalLabel">Trial Run Results</h4>
      </div>
      <div class="modal-body">
        <ngx-datatable
                  class="material striped"
                  [columnMode]="_columnMode.force"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  [rowHeight]="50"
                  [scrollbarV]="false"
                  [scrollbarH]="true"
                  [rows]="_rowsTrialRun"
                  [externalPaging]="true"
                  [count]="_pageCountTrialRun"
                  [offset]="_pageOffsetTrialRun"
                  [limit]="_pageLimitTrialRun"
                  (page)="trialRun(null, $event)"
                  (sort)="onSort($event)"
                  >

          <ngx-datatable-column *ngFor="let col of _columnsTrialRun" [width]="150" [headerClass]="'lists-table-headers'" [name]="col.name" [prop]="col.prop">
          </ngx-datatable-column>
          <!-- Custom table footer -->
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="_pageCountTrialRun" let-pageSize="_pageLimitTrialRun"
              let-curPage="_currentPageTrialRun" let-offset="_pageOffsetTrialRun">
              <div class="table-custom-footer">
                <div>
                   Rows: {{ _pageCountTrialRun }}
                </div>
                <select (change)="onLimitChangeTrialRun($event.target.value)" class="table-page-limit-select">
                  <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                    [selected]="option.value == _pageLimitTrialRun">
                    {{option.value}} per page
                </option>
                </select>
                <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'" [page]="_currentPageTrialRun" [size]="_pageLimitTrialRun" [count]="_pageCountTrialRun"
                  [hidden]="!((_pageCountTrialRun / _pageLimitTrialRun) > 1)" (change)="trialRun(undefined, $event.page)">
                </datatable-pager>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="trialRunModal.hide()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>
<!-- Show Trial Run Results Modal -->

<!-- Confirm Delete Contact Criteria Modal -->
<div mdbModal #deleteStepModal="mdb-modal" class="modal fade" id="deleteStepModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Confirm Delete Contact Criteria</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteStepModal.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <!--modal-header-->
      <div class="modal-body">
        <div class="text-center mb-3">
          <mdb-icon fas icon="question-circle" size="4x" class="animated rotateIn"></mdb-icon>
        </div>
        <!--text-center-->
        <div class="mb-3">Are you sure you want to delete this Contact Criteria?</div>
        <div class="mb-3">You will not be able to revoke this action.</div>
        <div class="modal-footer justify-content-center">
          <button type="submit" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deleteStep()"
            mdbWavesEffect>Yes, I am sure</button>
          <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
            (click)="deleteStepModal.hide()" mdbWavesEffect>No, thanks</button>
        </div>
        <!--modal-footer-->

      </div>
      <!--modal-body-->
    </div>
    <!--modal-content-->
  </div>
  <!--modal-dialog-->
</div>
<!--mdbModal-->
<!-- Confirm Delete Contact Criteria Modal -->


<!-- Copy Contact Criteria Modal -->
<div mdbModal #copyStepModal="mdb-modal" class="modal fade" id="copyStepModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Confirm Copy Contact Criteria</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="copyStepModal.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <!--modal-header-->
      <div class="modal-body">
        <div class="text-center mb-3">
          <mdb-icon fas icon="question-circle" size="4x" class="animated rotateIn"></mdb-icon>
        </div>
        <!--text-center-->
        <form [formGroup]="_copyStepFormGroup" (ngSubmit)="this.copyStepSubmit()">
          <div class="row">
            <div class="col">Would you like to make a copy this Contact Criteria?</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="md-form">
                <input mdbInput type="text" maxlength="225" id="criteriaCode" class="form-control"
                  formControlName="criteriaCode">
                <label for="criteriaCode">Contact Criteria Code</label>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button [disabled]="!_copyStepFormGroup.valid" type="submit" mdbBtn color="primary"
              class="waves-light rounded text-white" mdbWavesEffect>Yes</button>
            <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
              (click)="copyStepModal.hide()" mdbWavesEffect>No</button>
          </div>
          <!--modal-footer-->
        </form>
      </div>
      <!--modal-body-->
    </div>
    <!--modal-content-->
  </div>
  <!--modal-dialog-->
</div>
<!--mdbModal-->
<!-- Copy Contact Criteria Modal -->

<!-- Select List Modal For Create/Edit Contact Code Step Modal -->
<div mdbModal #selectListModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="mySelectListModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="selectListModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="selectListModalLabel">Criteria Code List</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="listForStepFormGroup">
          <div class="row">
            <div class="col">Select a List which the Criteria Code will be applied to:</div>
          </div>
          <div class="md-form">
            <mdb-select [options]="_listFolderDropdown" [filterEnabled]="true" [visibleOptions]="10"
              label="Select List Folder" formControlName="folderId" (selected)="getLists($event.value)">
            </mdb-select>
          </div>
          <div class="md-form">
            <mdb-select [options]="_listDropdown" [filterEnabled]="true" [visibleOptions]="10" label="Select List"
              formControlName="listId">
            </mdb-select>
          </div>
          <div class="modal-footer justify-content-center">
            <button [disabled]="!listForStepFormGroup.valid" type="submit" mdbBtn color="primary"
              class="waves-light rounded text-white" (click)="confirmList()" mdbWavesEffect>Confirm</button>
            <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal"
              (click)="selectListModal.hide()" mdbWavesEffect>Cancel</button>
          </div>
          <!--modal-footer-->
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Select List Modal For Create/Edit Contact Code Step Modal -->

<!-- Create/Edit Contact Code Step Modal -->
<div mdbModal [config]="{backdrop: 'static'}" #createEditMessageCodeModal="mdbModal" class="modal fade modal-overflow"
  tabindex="-1" role="dialog" aria-labelledby="createEditMessageCodeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content rounded">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="createEditMessageCodeModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="createEditMessageCodeModalLabel">Create Criteria Code</h4>
      </div>
      <div class="modal-body">
        <app-create-edit-message-code #createEditMessageCodeComponent (saved)="onSaved()">

        </app-create-edit-message-code>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="createEditMessageCodeModal.hide()" mdbWavesEffect>Close</button>
        <button type="button" mdbBtn color="primary" class="relative waves-light"
          [disabled]="createEditMessageCodeComponent.messageCodeForm.invalid" (click)="saveMessageCode()"
          mdbWavesEffect>Save</button>
      </div>
    </div>
  </div>
  <!-- Create/Edit Contact Code Step Modal -->
