export class AddNetworkGroupDtoRequest {

  public networkGroupId: number;
  public name: string;
  public networkDetailIds: number[];

  constructor(networkGroupId: number, name: string, networkDetailIds: number[]) {
    this.networkGroupId = networkGroupId;
    this.name = name;
    this.networkDetailIds = networkDetailIds;
  }
}
