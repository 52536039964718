export class CopyContactCriteriaGroupDto {
    selectedMessageCodeId: number;
    clientId: number;
    messageCodeFolderId: number;
    listId: number;
    loggedInUserId: number;

    constructor(init: { selectedMessageCodeId: number, clientId: number,  messageCodeFolderId: number, listId: number, loggedInUserId: number}) {

        this.selectedMessageCodeId = init.selectedMessageCodeId;
        this.clientId = init.clientId;
        this.messageCodeFolderId = init.messageCodeFolderId;
        this.listId = init.listId;
        this.loggedInUserId = init.loggedInUserId;
    }
}