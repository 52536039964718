import { Injectable } from '@angular/core';
import { EmailContactModel } from './email-contact-model';

@Injectable({
  providedIn: 'root'
})

export class EmailContactModelAdapter {

  createEmpty() {
    return new EmailContactModel({
      ContactID: 0,
      Email: '',
    });
  }

  adaptArray(init: Array<any>): EmailContactModel[] {
    if (init && init.length > 0) {
      return init.length > 1
      ? init.map(table => new EmailContactModel(table))
      : [new EmailContactModel(init[0])];
    } else {
      return [];
    }
  }
}
