<form [formGroup]="_filterSelectForm">
  <app-md-select label="Select Filter"
                 formControlName="selectedFilter"
                 [options]="_queryFiltersList">
  </app-md-select>
</form>
<div id="builder" class="builder"></div>

<div *ngIf="this._isCct">
  <form [formGroup]="_cctForm">
    <div class="row">
      <div class="col">
        <label for="ccTrue" class="input-label ml-0">Text If Condition Matches:</label>
        <textarea id="cctTrue" name="cctTrue" formControlName="cctTrue" class="form-control" rows="5"></textarea>
      </div>
      <div class="col">
        <label for="cctFalse" class="input-label ml-0">Text If Condition NOT Matches:</label>
        <textarea id="cctFalse" name="cctFalse" formControlName="cctFalse" class="form-control" rows="5"></textarea>
      </div>
    </div>
  </form>
</div>

<button *ngIf="_updateFilterButton" class="btn btn-primary save pull-left" (click)="displaySaveFilterModel(true)">Save As Filter</button>
<button *ngIf="!_updateFilterButton" class="btn btn-primary save pull-left" (click)="displaySaveFilterModel(false)">Save Filter</button>
<button *ngIf="_updateFilterButton" class="btn btn-primary save pull-left" (click)="updateFilter()">Update Filter</button>
<button class="btn btn-primary save pull-right" (click)="savePressed()" id="queryBuilderSaveBtn">Save</button>

<app-query-builder-filter #queryBuilderFilter
                          *ngIf="_showFilterModel"
                          [(_parentSqlQuery)]="_sqlQuery"
                          [(_parentPageTitle)]="_pageTitle"
                          [(_isSaveAsButton)]="_isSaveAsButton"
                          [(_queryFiltersList)]="_queryFiltersList"
                          [(_parentSelectedFilter)]="_parentSelectedFilter"
                          (closeRefreshEvent)="refreshQueryBuilderFilter($event)"
                          (modelOpenEvent)="_showFilterModel = $event">
</app-query-builder-filter>
