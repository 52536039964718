import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
  ElementRef,
  AfterContentInit
} from '@angular/core';
import {
  UploadInput,
  UploadFile,
  MDBDatePickerComponent,
  ClockPickerComponent,
  ModalDirective
} from 'ng-uikit-pro-standard';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PostsService } from 'src/app/_services/social-media/posts/posts.service';
import { NetworkService } from 'src/app/_services/social-media/networks/network.service';
import { NetworkDto } from 'src/app/_models/social-media/networks/network-dto';
import { NetworkAdapter } from 'src/app/_models/social-media/networks/network-adapter';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { PostsModel, PostsModelAdapter } from 'src/app/_models/social-media/posts/posts-model';
import { ShortLinkModel, ShortLinkModelAdapter } from 'src/app/_models/social-media/posts/short-link-model';
import { NetworkTypeEnum } from 'src/app/_models/social-media/posts/network-type-enum';
import { NotificationService } from 'src/app/_services/notification.service';
import { PermissionsService } from 'src/app/_services/system/Permissions/permissions.service';
import { PermissionsModel } from 'src/app/_models/system/permissions/permissons-model';
import { PostModel, PostModelAdapter } from 'src/app/_models/social-media/posts/post-model';
import { NetworkGroupService } from 'src/app/_services/social-media/network-groups/network-group.service';
import { NetworkGroupAdapter } from 'src/app/_models/social-media/network-groups/network-group-adapter';
import { NetworkGroupDto } from 'src/app/_models/social-media/network-groups/network-group-dto';
import { Router } from '@angular/router';
import { MediaServerService } from 'src/app/_services/media-server/media-server.service';
import { PostAttachmentModel } from 'src/app/_models/social-media/posts/post-attachment-model';
import { S3ObjectsModel } from 'src/app/_models/media-server/S3Models/S3Objects.model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ErrorAlertComponent } from 'src/app/views/shared/error-alert/error-alert.component';
import { MediaServerValidationRequest } from 'src/app/_models/media-server/media-server-validation-request';
import SocialMediaErrorMessages from 'src/app/views/shared/constants/app-constants';
import { Observable } from 'rxjs';
import { LinkModelAdapter } from '../../../../_models/social-media/posts/link-model';
import { LinkImageModelAdapter } from '../../../../_models/social-media/posts/link-image-model';
import { switchMap } from 'rxjs/operators';
import { IconPath } from '../../constants/social-media-constants';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';

@Component({
  selector: 'app-create-edit-post',
  templateUrl: './create-edit-post.component.html',
  styleUrls: ['./create-edit-post.component.scss'],
  providers: [DatePipe],
})
export class CreateEditPostComponent implements OnInit, AfterContentInit {
  public _statusOptions = [
    {value: 0, label: 'Schedule'},
    {value: 5, label: 'Draft'},
  ];

  public snapshotData;
  private dateTimeFormatString = 'yyyy-MM-ddTHH:mm';
  public errorMessages = SocialMediaErrorMessages;
  file: File;
  filesToUpload: Array<File>;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  postsForm: UntypedFormGroup;
  _scheduleSeparately = false;
  _gaSettings = false;
  networkGroups: Array<any>;
  _networks: NetworkDto[] = [];
  _postsModel: PostsModel;
  _account: NetworkDto[] = [];
  _networksDropdown: Array<any> = [];
  _networkGroupsDropdown: Array<any> = [];
  _selectedNetworks = new Set();
  _selectedFbPages: Array<any> = [];
  _selectedTwPages: Array<any> = [];
  _selectedIgPages: Array<any> = [];
  _selectedLiPages: Array<any> = [];
  _shrinkBtnActivation = false;
  _linkToShrink: ShortLinkModel;
  _userPermissions: PermissionsModel;
  creatingEditingPost = false;
  _customLinkImages: Array<any> = [];
  _customLinkImagesPaginated: Array<string> = [];
  _currentCustomLinkImage = '';
  _buttonPage = 0;
  _buttonPageSize = 10;
  _customLinkImageStartIndex: number;
  _customLinkImageStopIndex: number;
  _useCustomLinkImage: boolean;
  _isPickerOpen = false;
  _showMediaServer = false;
  _mediaServerOpenedByNetwork: NetworkTypeEnum;
  generalFiles: Array<PostAttachmentModel> = [];
  _facebookFiles: Array<PostAttachmentModel> = [];
  _twitterFiles: Array<PostAttachmentModel> = [];
  _instagramFiles: Array<PostAttachmentModel> = [];
  _linkedInFiles: Array<PostAttachmentModel> = [];
  _fromCustomLink = false;
  _isEmojiPickerVisible = false;
  _isFacebookEmojiPickerVisible = false;
  _isTwitterEmojiPickerVisible = false;
  _isInstagramEmojiPickerVisible = false;
  _isLinkedinEmojiPickerVisible = false;
  _isCustomiseEachNetworkSelected = false;
  _twitterCharLimit = 279;
  facebookVideoFileSizeError = false;
  facebookImageFileSizeError = false;
  facebookVideoLengthError = false;
  twitterVideoFileSizeError = false;
  twitterImageFileSizeError = false;
  twitterVideoLengthError = false;
  instagramVideoFileSizeError = false;
  instagramImageFileSizeError = false;
  instagramMediaPortraitAspectRatioError = false;
  instagramMediaLandScapeAspectRatioError = false;
  instagramMediaSquareAspectRatioError = false;
  instagramVideoLengthError = false;
  linkedInVideoFileSizeError = false;
  linkedInImageFileSizeError = false;
  linkedInVideoLengthError = false;
  _minDate: string;
  isEditMode: boolean;  

  get facebookFileValidator(): boolean {
    return this.facebookVideoFileSizeError || this.facebookImageFileSizeError || this.facebookVideoLengthError;
  }

  get instagramFileValidator(): boolean {
    return this.instagramVideoFileSizeError || this.instagramImageFileSizeError
      || this.instagramMediaPortraitAspectRatioError || this.instagramMediaLandScapeAspectRatioError
      || this.instagramMediaSquareAspectRatioError || this.instagramVideoLengthError;
  }

  get twitterFileValidator(): boolean {
    return this.twitterVideoFileSizeError || this.twitterImageFileSizeError || this.twitterVideoLengthError;
  }

  get linkedInFileValidator(): boolean {
    return this.linkedInImageFileSizeError || this.linkedInVideoLengthError || this.linkedInVideoFileSizeError;
  }

  @ViewChild('dualDatePicker', {static: true}) dualDatePicker: MDBDatePickerComponent;
  @ViewChild('dualTimePicker', {static: true}) dualTimePicker: ClockPickerComponent;
  @ViewChild('facebookDatePicker', {static: true}) facebookDatePicker: MDBDatePickerComponent;
  @ViewChild('facebookTimePicker', {static: true}) facebookTimePicker: ClockPickerComponent;
  @ViewChild('twitterDatePicker', {static: true}) twitterDatePicker: MDBDatePickerComponent;
  @ViewChild('twitterTimePicker', {static: true}) twitterTimePicker: ClockPickerComponent;
  @ViewChild('mediaServerModal', {static: true}) mediaServerModal: ModalDirective;
  @ViewChild('toggleEmoji') toggleEmoji: ElementRef;
  @ViewChild('toggleEmojiFacebook') toggleEmojiFacebook: ElementRef;
  @ViewChild('toggleEmojiTwitter') toggleEmojiTwitter: ElementRef;
  @ViewChild('toggleEmojiInstagram') toggleEmojiInstagram: ElementRef;
  @ViewChild('toggleEmojiLinkedIn') toggleEmojiLinkedIn: ElementRef;
  @ViewChild('errorAlert', {static: true}) errorAlert: ErrorAlertComponent;

  constructor(
    private fb: UntypedFormBuilder,
    public datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private postsService: PostsService,
    private networkService: NetworkService,
    private shortLinkModelAdapter: ShortLinkModelAdapter,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
    private postModelAdapter: PostModelAdapter,
    private postsModelAdapter: PostsModelAdapter,
    private networkGroupService: NetworkGroupService,
    private networkGroupAdapter: NetworkGroupAdapter,
    private router: Router,
    private networkAdapter: NetworkAdapter,
    public mediaServerService: MediaServerService,
    private linkModelAdapter: LinkModelAdapter,
    private linkImageModelAdapter: LinkImageModelAdapter,
    private localStorageService: LocalStorageService,
  ) {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.filesToUpload = [];
    this._minDate = null;
    this.snapshotData = this.router.getCurrentNavigation().extras.state;
    this.isEditMode = !!this.snapshotData;
  }

  ngOnInit() {
    this._userPermissions = this.permissionService.getPermissionsModel();
    this.permissionService.permissionsModel.subscribe((permissions: PermissionsModel) => {
      this._userPermissions = permissions;
    });

    this.getPostsModel();
    this.populateNetworkGroupsDropdown();
    this.populateNetworkDropdown();
    this.createPostsForm();
    this.onUploadedFileChanges();
    this.onInstagramAspectRatioCheck();

    if (this.isEditMode) {
      this.initData();
    }

    if (!this.isEditMode) {
      this.localStorageService.setSocialSearchTerms = null;
    }

    // For Phase 2 - Location Search
    // this.onChanges();
  }

  initData(): void {
    this.postsForm.get('multiDateTime').setValue(this.datePipe.transform(this.snapshotData.postDate, this.dateTimeFormatString));
    this.postsForm.get('generalMessage').setValue(this.snapshotData.postText);
    this.postsForm.get('dualPostStatus').setValue(this.snapshotData.postStatus);
    this.generalFiles = this.snapshotData.attachments;
    this.postsForm.get('generalFiles').setValue(this.generalFiles);
  }

  ngAfterContentInit() {
    this._minDate = this.returnMinimumDate();
  }

  returnMinimumDate() {
    const isoString = new Date().toISOString();
    return isoString.substring(0, (isoString.indexOf('T') || 0) + 6 || 0);
  }

  createPostsForm() {
    this.postsForm = this.fb.group({
      networkGroups: [{value: '', disabled: this.isEditMode}],
      networks: [{value: '', disabled: this.isEditMode}],
      facebookText: [''],
      facebookDateTime: [],
      twitterText: [''],
      twitterDateTime: [],
      generalMessage: [''],
      globalSchedule: [false],
      multiDateTime: [],
      instagramText: [''],
      instagramFirstComment: [],
      instagramLocation: [],
      instagramDateTime: [],
      linkedInText: [''],
      linkedInDateTime: [],
      removeUtm: [false],
      campaignName: [],
      campaignContent: [],
      link: [],
      dualPostStatus: [],
      customiseForEachNetworkSelected: [false],
      facebookNetworksSelected: [false],
      twitterNetworksSelected: [false],
      instagramNetworksSelected: [false],
      linkedinNetworksSelected: [false],
      albumName: [''],
      linkedInFiles: [[]],
      instagramFiles: [[]],
      twitterFiles: [[]],
      facebookFiles: [[]],
      generalFiles: [[]],
    },
      {
        validator: [
          this.networkRequired,
          this.postContentValidator,
          this.albumNameValidator,
          this.fileTypeValidator,
          this.messageValidators.bind(this)
        ]
      }
    );
  }

  albumNameValidator(g: UntypedFormGroup) {
    const albumName: string = g.get('albumName').value;

    if ((g.get('customiseForEachNetworkSelected').value && g.get('facebookNetworksSelected').value)
      || g.get('facebookNetworksSelected').value) {

      const files = g.get('facebookFiles').value;
      const generalFiles = g.get('generalFiles').value;
      
      if (!files || !generalFiles) {
        return false;
      }

      if ((files.length <= 1 && !g.get('facebookNetworksSelected').value)
        || generalFiles.length <= 1 && g.get('facebookNetworksSelected').value)
      {
       return false;
      }

      if (files.length > 1 && albumName !== '') {
        return false;
      }

      if (generalFiles.length > 1 && albumName !== '') {
        return false;
      }
    
      return {albumNameError: true};
    }
  }

  messageValidators(g: AbstractControl) {
    const linkedInMessage: string = g.get('linkedInText').value;
    const instagramMessage: string = g.get('instagramText').value;
    const twitterMessage: string = g.get('twitterText').value;
    const facebookMessage: string = g.get('facebookText').value;
    const generalMessage: string = g.get('generalMessage').value;

    const facebookCharLimit = 63206;
    const instagramCharLimit = 2199;
    const linkedinCharLimit = 2999;
    const twitterCharLimit = 279;

    if (linkedInMessage) {
      return linkedInMessage.length > linkedinCharLimit ? {linkedInTooManyChars: true} : null;
    }

    if (instagramMessage) {
      return instagramMessage.length > instagramCharLimit ? {instagramTooManyChars: true} : null;
    }

    if (twitterMessage) {
      return twitterMessage.length > twitterCharLimit ? {twitterTooManyChars: true} : null;
    }

    if (facebookMessage) {
      return facebookMessage.length > facebookCharLimit ? {facebookTooManyChars: true} : null;
    }

    if (generalMessage) {
      return this._selectedTwPages.length && generalMessage.length > twitterCharLimit ? {TooManyChars: true} : null;
    }

    return null;
  }

  // Aspect Ratio Check for Instagram
  onInstagramAspectRatioCheck() {
    const instagramSquareAspectRatioRequirements = [1, 1];
    const instagramLandScapeAspectRatioRequirements = [1.91, 1];
    const instagramPortraitAspectRatioRequirements = [4, 5];

    this.postsForm.get('generalFiles').valueChanges.subscribe(generalFiles => {
      this.instagramMediaPortraitAspectRatioError = this.instagramMediaLandScapeAspectRatioError = this.instagramMediaSquareAspectRatioError = false;

      // Get the Greatest Common Divisior
      const gcd = (a, b) => {
        return b ? gcd(b, a % b) : a;
      };

      if (generalFiles) {
        const mp4FileExtension = generalFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mp4');
        const movFileExtension = generalFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mov');

        if (mp4FileExtension.length > 0 || movFileExtension.length > 0) {
          generalFiles.forEach((file) => {
            const video = document.createElement('video');
            video.src = file.FileUrl;
            video.onloadedmetadata = (() => {
              const height = video.videoHeight;
              const width = video.videoWidth;

              const widthAspectRatio = parseFloat((width / height).toFixed(2));
              const heightAspectRatio = parseFloat((height / width).toFixed(2));

              if (width > height) {
                // Landscape
                if (widthAspectRatio <= instagramLandScapeAspectRatioRequirements[0] && heightAspectRatio <= instagramLandScapeAspectRatioRequirements[1]) { this.instagramMediaLandScapeAspectRatioError = false; }
                else { this.instagramMediaLandScapeAspectRatioError = true; }
              } else if (width < height) {
                // Portrait
                if (widthAspectRatio <= instagramPortraitAspectRatioRequirements[0] && heightAspectRatio <= instagramPortraitAspectRatioRequirements[1]) { this.instagramMediaPortraitAspectRatioError = false; }
                else { this.instagramMediaPortraitAspectRatioError = true; }
              } else {
                // Square
                if (widthAspectRatio <= instagramSquareAspectRatioRequirements[0] && heightAspectRatio <= instagramSquareAspectRatioRequirements[1]) { this.instagramMediaSquareAspectRatioError = false; }
                else { this.instagramMediaSquareAspectRatioError = true; }
              }
            });
          });
        } else {
          generalFiles.forEach((file) => {
            var img = new Image();
            img.src = file.FileUrl;
            img.onload = (() => {
                            
              const widthAspectRatio = parseFloat((img.width / img.height).toFixed(2));
              const heightAspectRatio = parseFloat((img.height / img.width).toFixed(2));

              if (img.width > img.height) {
                // Landscape
                if (widthAspectRatio <= instagramLandScapeAspectRatioRequirements[0] && heightAspectRatio <= instagramLandScapeAspectRatioRequirements[1]) { this.instagramMediaLandScapeAspectRatioError = false; }
                else { this.instagramMediaLandScapeAspectRatioError = true; }
              } else if (img.width < img.height) {
                // Portrait
                if (widthAspectRatio <= instagramPortraitAspectRatioRequirements[0] && heightAspectRatio <= instagramPortraitAspectRatioRequirements[1]) { this.instagramMediaPortraitAspectRatioError = false; }
                else { this.instagramMediaPortraitAspectRatioError = true; }
              } else {
                // Square
                if ( widthAspectRatio <= instagramSquareAspectRatioRequirements[0] && heightAspectRatio <= instagramSquareAspectRatioRequirements[1]) { this.instagramMediaSquareAspectRatioError = false; }
                else { this.instagramMediaSquareAspectRatioError = true; }
              }
            });
          });
        }
      }
    });

    this.postsForm.get('instagramFiles').valueChanges.subscribe(instagramFiles => {
      this.instagramMediaPortraitAspectRatioError = this.instagramMediaLandScapeAspectRatioError = this.instagramMediaSquareAspectRatioError = false;

      // Get the Greatest Common Divisior
      const gcd = (a, b) => {
        return b ? gcd(b, a % b) : a;
      };

      if (instagramFiles) {
        var mp4FileExtension = instagramFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mp4');
        var movFileExtension = instagramFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mov');

        if (mp4FileExtension.length > 0 || movFileExtension.length > 0) {
          instagramFiles.forEach((file) => {
            const video = document.createElement('video');
            video.src = file.FileUrl;
            video.onloadedmetadata = (() => {
              const height = video.videoHeight;
              const width = video.videoWidth;

              const widthAspectRatio = parseFloat((width / height).toFixed(2));
              const heightAspectRatio = parseFloat((height / width).toFixed(2));

              if (width > height) {
                // Landscape
                if (widthAspectRatio <= instagramLandScapeAspectRatioRequirements[0] && heightAspectRatio <= instagramLandScapeAspectRatioRequirements[1]) { this.instagramMediaLandScapeAspectRatioError = false; }
                else { this.instagramMediaLandScapeAspectRatioError = true; }
              } else if (width < height) {
                // Portrait
                if (widthAspectRatio <= instagramPortraitAspectRatioRequirements[0] && heightAspectRatio <= instagramPortraitAspectRatioRequirements[1]) { this.instagramMediaPortraitAspectRatioError = false; }
                else { this.instagramMediaPortraitAspectRatioError = true; }
              } else {
                // Square
                if (widthAspectRatio <= instagramSquareAspectRatioRequirements[0] && heightAspectRatio <= instagramSquareAspectRatioRequirements[1]) { this.instagramMediaSquareAspectRatioError = false; }
                else { this.instagramMediaSquareAspectRatioError = true; }
              }
            });
          });
        } else {
          instagramFiles.forEach((file) => {
            var img = new Image();
            img.src = file.FileUrl;
            img.onload = (() => {

              const widthAspectRatio = parseFloat((img.width / img.height).toFixed(2));
              const heightAspectRatio = parseFloat((img.height / img.width).toFixed(2));

              if (img.width > img.height) {
                // Landscape
                if (widthAspectRatio <= instagramLandScapeAspectRatioRequirements[0] && heightAspectRatio <= instagramLandScapeAspectRatioRequirements[1]) { this.instagramMediaLandScapeAspectRatioError = false; }
                else { this.instagramMediaLandScapeAspectRatioError = true; }
              } else if (img.width < img.height) {
                // Portrait
                if (widthAspectRatio <= instagramPortraitAspectRatioRequirements[0] && heightAspectRatio <= instagramPortraitAspectRatioRequirements[1]) { this.instagramMediaPortraitAspectRatioError = false; }
                else { this.instagramMediaPortraitAspectRatioError = true; }
              } else {
                // Square
                if (widthAspectRatio <= instagramSquareAspectRatioRequirements[0] && heightAspectRatio <= instagramSquareAspectRatioRequirements[1]) { this.instagramMediaSquareAspectRatioError = false; }
                else { this.instagramMediaSquareAspectRatioError = true; }
              }
            });
          });
        }
      }
    });
  }

  // Extra Validator when uploading files - Used mainly to ensure file size is within range
  onUploadedFileChanges() {
    const facebookImageBytesLimit = 45000000;
    const facebookVideoBytesLimit = 10000000000;
    const facebookVideoLengthLimitSeconds = 240;

    const twitterImageBytesLimit = 5000000;
    const twitterVideoBytesLimit = 1000000000;
    const twitterVideoLengthLimitSeconds = 140;

    const instagramImageBytesLimit = 30000000;
    const instagramVideoBytesLimit = 4000000000;
    const instagramVideoLengthLimitSeconds = 60;

    const linkedInImageBytesLimit = 8000000;
    const linkedInVideoBytesLimit = 200000000;
    const linkedInVideoLengthLimitSeconds = 600;

    // Subscribe to General Post Control
    this.postsForm.get('generalFiles').valueChanges.subscribe(generalFiles => {
      this.facebookVideoFileSizeError = this.facebookImageFileSizeError = false;
      this.twitterVideoFileSizeError = this.twitterImageFileSizeError = false;
      this.instagramVideoFileSizeError = this.instagramImageFileSizeError = false;
      this.linkedInVideoFileSizeError = this.linkedInImageFileSizeError = false;

      this.facebookVideoLengthError = this.twitterVideoLengthError = this.instagramVideoLengthError = this.linkedInVideoLengthError = false;

      if (generalFiles.length) {
        const mp4FileExtension = generalFiles
          .filter((file) => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mp4');

        generalFiles.forEach((file) => {
          const mediaServerFileHeader = new MediaServerValidationRequest({
            url: file.FileUrl,
            fileName: file?.FileName
          });

          this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
            if (executionResultDto.executionResult === ExecutionResult.success) {
              const facebookError = mp4FileExtension.length ? 'facebookVideoFileSizeError' : 'facebookImageFileSizeError';
              const twitterError = mp4FileExtension.length ? 'twitterVideoFileSizeError' : 'twitterImageFileSizeError';
              const instagramError = mp4FileExtension.length ? 'instagramVideoFileSizeError' : 'instagramImageFileSizeError';
              const linkedInError = mp4FileExtension.length ? 'linkedInVideoFileSizeError' : 'linkedInImageFileSizeError';

              if (executionResultDto.data > facebookVideoBytesLimit) {
                this[facebookError] = true;
              } else {
                this[facebookError] = false;
              }

              if (executionResultDto.data > twitterVideoBytesLimit) {
                this[twitterError] = true;
              } else {
                this[twitterError] = false;
              }

              if (executionResultDto.data > instagramVideoBytesLimit) {
                this[instagramError] = true;
              } else {
                this[instagramError] = false;
              }

              if (executionResultDto.data > linkedInVideoBytesLimit) {
                this[linkedInError] = true;
              } else {
                this[linkedInError] = false;
              }
            } else {
              this.notificationService.showError(executionResultDto.message);
            }
          });

          if (mp4FileExtension.length) {
            this.mediaServerService.getVideoDuration(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                this.facebookVideoLengthError = executionResultDto.data > facebookVideoLengthLimitSeconds;
                this.twitterVideoLengthError = executionResultDto.data > twitterVideoLengthLimitSeconds;
                this.instagramVideoLengthError = executionResultDto.data > instagramVideoLengthLimitSeconds;
                this.linkedInVideoLengthError = executionResultDto.data > linkedInVideoLengthLimitSeconds;
              } else {
                this.notificationService.showError(executionResultDto.message);
              }
            });
          }
        });
      }
    });

    // Subscribe to Facebook Control
    this.postsForm.get('facebookFiles').valueChanges.subscribe(facebookFiles => {
      this.facebookVideoFileSizeError = this.facebookImageFileSizeError = this.facebookVideoLengthError = false;

      if (facebookFiles) {
        const mp4FileExtension = facebookFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mp4');
        const movFileExtension = facebookFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mov');

        if (mp4FileExtension.length > 0 || movFileExtension.length > 0) {
          // Restrict FileSizes for Videos to 10GB - Param Sizes are in Bytes
          facebookFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({
              url: file.FileUrl,
              fileName: file.FileName
            });
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > facebookVideoBytesLimit) {
                  this.facebookVideoFileSizeError = true;
                } else {
                  this.facebookVideoFileSizeError = false;
                }
              } else {
                this.notificationService.showError(executionResultDto.message);
                this.facebookVideoFileSizeError = false;
              }
            });
            this.mediaServerService.getVideoDuration(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > facebookVideoLengthLimitSeconds) { this.facebookVideoLengthError = true; }
                else { this.facebookVideoLengthError = false; }
              } else {
                this.notificationService.showError(executionResultDto.message);
              }
            });
          });
        } else {
          // Restrict FileSizes for Images to 45MB - Param Sizes are in Bytes
          facebookFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({url: file.FileUrl});
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {

                if (executionResultDto.executionResult === ExecutionResult.success) {
                  if (executionResultDto.data > facebookImageBytesLimit) {
                    this.facebookImageFileSizeError = true;
                  } else {
                    this.facebookImageFileSizeError = false;
                  }
                } else {
                  this.notificationService.showError(executionResultDto.message);
                  this.facebookImageFileSizeError = false;
                }
              }
            );
          });
        }
      }
    });

    // Subscribe to Twitter Control
    this.postsForm.get('twitterFiles').valueChanges.subscribe(twitterFiles => {
      this.twitterVideoFileSizeError = this.twitterImageFileSizeError = this.twitterVideoLengthError = false;

      if (twitterFiles) {
        var mp4FileExtension = twitterFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mp4');

        if (mp4FileExtension.length > 0) {
          // Restrict FileSizes for Videos to 1GB - Param Sizes are in Bytes
          twitterFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({
              url: file.FileUrl,
              fileName: file.FileName
            });
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > twitterVideoBytesLimit) {
                  this.twitterVideoFileSizeError = true;
                } else {
                  this.twitterVideoFileSizeError = false;
                }
              } else {
                this.notificationService.showError(executionResultDto.message);
                this.twitterVideoFileSizeError = false;
              }
            });
            this.mediaServerService.getVideoDuration(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > twitterVideoLengthLimitSeconds) { this.twitterVideoLengthError = true; }
                else { this.twitterVideoLengthError = false; }
              } else {
                this.notificationService.showError(executionResultDto.message);
              }
            });
          });
        } else {
          // Restrict FileSizes for Images to 5MB - Param Sizes are in Bytes
          twitterFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({url: file.FileUrl});
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
                if (executionResultDto.executionResult === ExecutionResult.success) {
                  if (executionResultDto.data > twitterImageBytesLimit) {
                    this.twitterImageFileSizeError = true;
                  } else {
                    this.twitterImageFileSizeError = false;
                  }
                } else {
                  this.notificationService.showError(executionResultDto.message);
                  this.twitterImageFileSizeError = false;
                }
              }
            );
          });
        }
      }
    });

    // Subscribe to Instagram Control
    this.postsForm.get('instagramFiles').valueChanges.subscribe(instagramFiles => {
      this.instagramVideoFileSizeError = this.instagramImageFileSizeError = this.instagramVideoLengthError = false;

      if (instagramFiles) {
        var mp4FileExtension = instagramFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mp4');
        var movFileExtension = instagramFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mov');

        if (mp4FileExtension.length > 0 || movFileExtension.length > 0) {
          // Restrict FileSizes for Videos to 4GB - Param Sizes are in Bytes
          instagramFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({
              url: file.FileUrl,
              fileName: file.FileName
            });
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > instagramVideoBytesLimit) {
                  this.instagramVideoFileSizeError = true;
                } else {
                  this.instagramVideoFileSizeError = false;
                }
              } else {
                this.notificationService.showError(executionResultDto.message);
                this.instagramVideoFileSizeError = false;
              }
            });
            this.mediaServerService.getVideoDuration(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > instagramVideoLengthLimitSeconds) { this.instagramVideoLengthError = true; }
                else { this.instagramVideoLengthError = false; }
              } else {
                this.notificationService.showError(executionResultDto.message);
              }
            });
          });
        } else {
          // Restrict FileSizes for Images to 30MB - Param Sizes are in Bytes
          instagramFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({url: file.FileUrl});
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
                if (executionResultDto.executionResult === ExecutionResult.success) {
                  if (executionResultDto.data > instagramImageBytesLimit) {
                    this.instagramImageFileSizeError = true;
                  } else {
                    this.instagramImageFileSizeError = false;
                  }
                } else {
                  this.notificationService.showError(executionResultDto.message);
                  this.instagramImageFileSizeError = false;
                }
              }
            );
          });
        }
      }
    });

    // Subscribe to LinkedInFiles Control
    this.postsForm.get('linkedInFiles').valueChanges.subscribe(linkedInFiles => {
      this.linkedInVideoFileSizeError = this.linkedInImageFileSizeError = this.linkedInVideoLengthError = false;

      if (linkedInFiles) {
        var mp4FileExtension = linkedInFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mp4');
        var movFileExtension = linkedInFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) == 'mov');

        if (mp4FileExtension.length > 0 || movFileExtension.length > 0) {
          // Restrict FileSizes for Videos to 200MB - Param Sizes are in Bytes
          linkedInFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({
              url: file.FileUrl,
              fileName: file.FileName
            });
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > linkedInVideoBytesLimit) {
                  this.linkedInVideoFileSizeError = true;
                } else {
                  this.linkedInVideoFileSizeError = false;
                }
              } else {
                this.notificationService.showError(executionResultDto.message);
                this.linkedInVideoFileSizeError = false;
              }
            });
            this.mediaServerService.getVideoDuration(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
              if (executionResultDto.executionResult === ExecutionResult.success) {
                if (executionResultDto.data > linkedInVideoLengthLimitSeconds) { this.linkedInVideoLengthError = true; }
                else { this.linkedInVideoLengthError = false; }
              } else {
                this.notificationService.showError(executionResultDto.message);
              }
            });
          });
        } else {
          // Restrict FileSizes for Images to 8MB - Param Sizes are in Bytes
          linkedInFiles.forEach((file) => {
            const mediaServerFileHeader = new MediaServerValidationRequest({url: file.FileUrl});
            this.mediaServerService.getFileSizeFromURL(mediaServerFileHeader).subscribe((executionResultDto: ExecutionResultDto) => {
                if (executionResultDto.executionResult === ExecutionResult.success) {
                  if (executionResultDto.data > linkedInImageBytesLimit) {
                    this.linkedInVideoFileSizeError = true;
                  } else {
                    this.linkedInVideoFileSizeError = false;
                  }
                } else {
                  this.notificationService.showError(executionResultDto.message);
                  this.linkedInVideoFileSizeError = false;
                }
              }
            );
          });
        }
      }
    });
  }

  // Checks to see if onUploadedFileChanges and onInstagramAspectRatioCheck is not returning any errors so that users can post after completion of requirements
  fileValidatorErrors(): boolean {
    if (this.postsForm.get('instagramNetworksSelected').value) {
      return this.facebookFileValidator
        || this.instagramFileValidator
        || this.twitterFileValidator
        || this.linkedInFileValidator;
    } else {
      return this.facebookFileValidator
        || this.linkedInFileValidator
        || this.twitterFileValidator;
    }
  }

  fileTypeValidator(g: UntypedFormGroup) {
    const linkedInFiles: Array<PostAttachmentModel> = g.get('linkedInFiles').value;
    const instagramFiles: Array<PostAttachmentModel> = g.get('instagramFiles').value;
    const twitterFiles: Array<PostAttachmentModel> = g.get('twitterFiles').value;
    const facebookFiles: Array<PostAttachmentModel> = g.get('facebookFiles').value;
    const generalFiles: Array<PostAttachmentModel> = g.get('generalFiles').value;

    // defaut array of accepted attachment formats
    const commonFileExtensions: string[] = ['jpg', 'jpeg', 'png', 'mp4'];

    if (linkedInFiles && g.get('linkedinNetworksSelected').value) {
      const statuses = linkedInFiles.map((file: PostAttachmentModel) => {
        var extension = file.FileName.substring(file.FileName.lastIndexOf('.') + 1);

        // Add allowed extensions for LinkedIn Specifically
        commonFileExtensions.push('mov');
        if (commonFileExtensions.includes(extension)) { return true; }
        else { return false; }
      });

      if (statuses.some((value) => value === false)) {
        return {linkedInFileTypeError: true};
      }

      var videoFiles = linkedInFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mp4' ||
        file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mov');
      if (videoFiles.length > 1) { return {linkedInTooManyVideosError: true}; }

      if (linkedInFiles.length > 9) { return {linkedInTooManyPhotoVideosError: true}; }
    }

    if (instagramFiles && g.get('instagramNetworksSelected').value) {
      const statuses = instagramFiles.map((file: PostAttachmentModel) => {
        var extension = file.FileName.substring(file.FileName.lastIndexOf('.') + 1);

        commonFileExtensions.push('mov');

        if (commonFileExtensions.includes(extension)) { return true; }
        else { return false; }
      });

      if (statuses.some((value) => value === false)) {
        return {instagramFileTypeError: true};
      }
      var videoFiles = instagramFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mp4' ||
      file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mov');
      if (videoFiles.length > 1) { return {instagramTooManyMP4sError: true}; }
      
      if ((videoFiles.length >= 1 && instagramFiles.length >= 2)) { return {instagramTooManyFileFormatsError: true}; }

      if (g.get('customiseForEachNetworkSelected').value && instagramFiles.length == 0) { return {instagramRequiresPhotosVideosError: true}; }
      if (instagramFiles.length > 10) { return {InstagramCanOnlyAcceptTenSelectedMediaWhenPostingToCarouselsError: true}; }
    }

    if (twitterFiles && g.get('twitterNetworksSelected').value) {
      const statuses = twitterFiles.map((file: PostAttachmentModel) => {
        var extension = file.FileName.substring(file.FileName.lastIndexOf('.') + 1);

        commonFileExtensions.push('gif');
        if (commonFileExtensions.includes(extension)) { return true; }
        else { return false; }
      });

      if (statuses.some((value) => value === false)) {
        return {twitterFileTypeError: true};
      }

      var mp4Files = twitterFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mp4');
      if (mp4Files.length > 1) { return {twitterTooManyMP4sError: true}; }

      var gifFiles = twitterFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'gif');
      if (gifFiles.length > 1) { return {twitterTooManyGifsError: true}; }

      if (twitterFiles.length > 4) { return {twitterTooManyPhotosError: true}; }

      if ((mp4Files.length >= 1 && gifFiles.length >= 1) ||
        (mp4Files.length >= 1 && twitterFiles.length >= 2) ||
        (twitterFiles.length >= 2 && gifFiles.length >= 1)) { return {twitterTooManyFileFormatsError: true}; }
    }

    if (facebookFiles && g.get('facebookNetworksSelected').value) {
      const statuses = facebookFiles.map((file: PostAttachmentModel) => {
        var extension = file.FileName.substring(file.FileName.lastIndexOf('.') + 1);

        commonFileExtensions.push('mov', 'gif');
        if (commonFileExtensions.includes(extension)) { return true; }
        else { return false; }
      });

      if (statuses.some((value) => value === false)) {
        return {facebookFileTypeError: true};
      }

      var videoFiles = facebookFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mp4' ||
        file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mov');
      if (videoFiles.length > 1) { return {facebookTooManyMP4sError: true}; }

      var gifFiles = facebookFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'gif');
      if (gifFiles.length > 1) { return {facebookTooManyGifsError: true}; }

      if (facebookFiles.length > 6) { return {facebookTooManyPhotosVideosError: true}; }

      if ((videoFiles.length >= 1 && gifFiles.length >= 1) ||
        (videoFiles.length >= 1 && facebookFiles.length >= 2) ||
        (facebookFiles.length >= 2 && gifFiles.length >= 1)) { return {facebookTooManyFileFormatsError: true}; }
    }

    if (generalFiles && !g.get('customiseForEachNetworkSelected').value) {
      const statuses = generalFiles.map((file: PostAttachmentModel) => {
        var extension = file.FileName.substring(file.FileName.lastIndexOf('.') + 1);

        commonFileExtensions.push('mov');
        if (commonFileExtensions.includes(extension)) { return true; }
        else { return false; }
      });

      if (statuses.some((value) => value === false)) {
        return {FileTypeError: true};
      }

      var videoFiles = generalFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mp4' ||
        file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'mov');
      if (videoFiles.length > 1) { return {TooManyVideoFilesError: true}; }

      var gifFiles = generalFiles.filter(file => file.FileName.substring(file.FileName.lastIndexOf('.') + 1) === 'gif');

      if (g.get('linkedinNetworksSelected').value && generalFiles.length > 9) { return {TooManyPhotosVideosForLinkedInError: true}; }

      if (g.get('instagramNetworksSelected').value && generalFiles.length == 0) { return {RequiresPhotosVideosForInstagramError: true}; }
      if (g.get('instagramNetworksSelected').value && generalFiles.length > 10) { return { InstagramCanOnlyAcceptTenSelectedMediaWhenPostingToCarouselsError: true}; }

      if (g.get('twitterNetworksSelected').value && generalFiles.length > 4) { return {TooManyPhotosVideosForTwitterError: true}; }
      if (g.get('twitterNetworksSelected').value && gifFiles.length > 1) { return {TooManyGifsError: true}; }

      if (g.get('facebookNetworksSelected').value && generalFiles.length > 6) { return {TooManyPhotosVideosForFacebookError: true}; }
      if (g.get('facebookNetworksSelected').value && gifFiles.length > 1) { return {TooManyGifsError: true}; }

      if ((videoFiles.length >= 1 && gifFiles.length >= 1) ||
        (videoFiles.length >= 1 && generalFiles.length >= 2) ||
        (generalFiles.length >= 2 && gifFiles.length >= 1)) { return {TooManyFileFormatsError: true}; }
    }

    return null;
  }

  networkRequired(g: UntypedFormGroup) {
    const networkField: Array<any> = g.get('networks').value;
    const networkGroupField: Array<any> = g.get('networkGroups').value;
    return (networkField.length > 0 || networkGroupField.length > 0) ? null : {requiredNetworks: true};
  }

  // Overall Page Validation
  postContentValidator(g: UntypedFormGroup) {
    var isDateSchedulingValid = false;
    var isMessagesValid = false;
    var isStatusSelected = false;

    // Check if the Schedule Seperately Form Control is checked, if not each network schedule date must be filled in
    const globalSchedule: boolean = g.get('globalSchedule').value;

    if (!globalSchedule) {
      if (g.get('multiDateTime').value == null) {
        return {scheduledDateTimeRequired: true};
      } else {
        isDateSchedulingValid = true;
      }
    } else {
      if (g.get('facebookNetworksSelected').value && g.get('facebookDateTime').value == null) {
        return {scheduledDateTimeFacebookNetworkRequired: true};
      } else if (g.get('twitterNetworksSelected').value && g.get('twitterDateTime').value == null) {
        return {scheduledDateTimeTwitterNetworkRequired: true};
      } else if (g.get('instagramNetworksSelected').value && g.get('instagramDateTime').value == null) {
        return {scheduledDateTimeInstagramNetworkRequired: true};
      } else if (g.get('linkedinNetworksSelected').value && g.get('linkedInDateTime').value == null) {
        return {scheduledDateTimeLinkedInNetworkRequired: true};
      } else {
        isDateSchedulingValid = true;
      }
    }

    // Check if the customise for each network button is clicked, if so then each message needs to be validated
    const customForEachNetwork: boolean = g.get('customiseForEachNetworkSelected').value;

    if (!customForEachNetwork) {
      if (g.get('generalMessage').value == '') {
        return {generalMessageFieldRequired: true};
      } else {
        isMessagesValid = true;
      }
    } else {
      if (g.get('facebookNetworksSelected').value && g.get('facebookText').value == '') {
        return {facebookPostFieldsRequired: true};
      } else if (g.get('twitterNetworksSelected').value && g.get('twitterText').value == '') {
        return {twitterPostFieldsRequired: true};
      } else if (g.get('instagramNetworksSelected').value && g.get('instagramText').value == '') {
        return {instagramPostFieldsRequired: true};
      } else if (g.get('linkedinNetworksSelected').value && g.get('linkedInText').value == '') {
        return {linkedInPostFieldsRequired: true};
      } else {
        isMessagesValid = true;
      }
    }

    // Ensure status is selected
    if (g.get('dualPostStatus').value == null) {
      return {postStatusRequired: true};
    } else {
      isStatusSelected = true;
    }

    if (isDateSchedulingValid && isMessagesValid && isStatusSelected) {
      return true;
    }

    return false;
  }

  // For Phase 2 - Instagram Location
  // onChanges() {
  //   //Allows user to type and select instagram location
  //   this.postsForm.get('instagramLocation').valueChanges.subscribe(data => {
  //     if (this._selectedIgPages && this._selectedIgPages.length > 0 && data.length > 3) {
  //       //Get the first network detail Id
  //       console.log(this._selectedIgPages);
  //       var networkId = this._selectedIgPages[0].value;

  //       this.facebookPageService.getLocations(data, networkId).subscribe((executionResultDto: ExecutionResultDto) => {
  //         if (executionResultDto.executionResult === ExecutionResult.success) {
  //           //Populate drop down here
  //           console.log(executionResultDto);
  //         } else {
  //           this.notificationService.showError(executionResultDto.message);
  //         }
  //       });
  //     }
  //   });
  // }

  countNetworks(networkTypeEnum: NetworkTypeEnum) {
    switch (networkTypeEnum) {
      case NetworkTypeEnum['Facebook Page']:
        var facebookCounter: Array<number> = [];
        this._selectedFbPages.forEach(network => {
          if (network instanceof NetworkDto) {
            if (!facebookCounter.some(id => id === network.networkDetailId)) {
              facebookCounter.push(network.networkDetailId);
            }
          } else {
            if (!facebookCounter.some(id => id === network.value)) {
              facebookCounter.push(network.value);
            }
          }
        });
        return facebookCounter.length;
      case NetworkTypeEnum.Twitter:
        var twitterCounter: Array<number> = [];
        this._selectedTwPages.forEach(network => {
          if (network instanceof NetworkDto) {
            if (!twitterCounter.some(id => id === network.networkDetailId)) {
              twitterCounter.push(network.networkDetailId);
            }
          } else {
            if (!twitterCounter.some(id => id === network.value)) {
              twitterCounter.push(network.value);
            }
          }
        });
        return twitterCounter.length;
      case NetworkTypeEnum.Instagram:
        var instagramCounter: Array<number> = [];
        this._selectedIgPages.forEach(network => {
          if (network instanceof NetworkDto) {
            if (!instagramCounter.some(id => id === network.networkDetailId)) {
              instagramCounter.push(network.networkDetailId);
            }
          } else {
            if (!instagramCounter.some(id => id === network.value)) {
              instagramCounter.push(network.value);
            }
          }
        });
        return instagramCounter.length;
      case NetworkTypeEnum.LinkedIn:
        var linkedinCounter: Array<number> = [];
        this._selectedLiPages.forEach(network => {
          if (network instanceof NetworkDto) {
            if (!linkedinCounter.some(id => id === network.networkDetailId)) {
              linkedinCounter.push(network.networkDetailId);
            }
          } else {
            if (!linkedinCounter.some(id => id === network.value)) {
              linkedinCounter.push(network.value);
            }
          }
        });
        return linkedinCounter.length;
      default:
        break;
    }
  }

  // When Customise for each network is clicked copy over text and images to each network
  customiseForEachNetwork() {
    this.postsForm.get('customiseForEachNetworkSelected').setValue(true);

    const generalPostMessage = this.postsForm.get('generalMessage').value;
    this.postsForm.get('generalMessage').reset();
    const generalPostImages = this.generalFiles;

    this.postsForm.get('facebookText').setValue(generalPostMessage);
    this.postsForm.get('facebookFiles').setValue(generalPostImages);
    this._facebookFiles = this.generalFiles;

    this.postsForm.get('twitterText').setValue(generalPostMessage.slice(0, 279));
    this.postsForm.get('twitterFiles').setValue(generalPostImages);
    this._twitterFiles = this.generalFiles;

    this.postsForm.get('instagramText').setValue(generalPostMessage);
    this.postsForm.get('instagramFiles').setValue(generalPostImages);
    this._instagramFiles = this.generalFiles;

    this.postsForm.get('linkedInText').setValue(generalPostMessage);
    this.postsForm.get('linkedInFiles').setValue(generalPostImages);
    this._linkedInFiles = this.generalFiles;

    this._isCustomiseEachNetworkSelected = true;

    this.postsForm.updateValueAndValidity();
  }

  // Allow user to click outside the emoji box to close it
  @HostListener('document:click', ['$event'])
  generalEmojiPicker(event: Event) {
    const eventNodeName = (event.target as HTMLElement).nodeName;

    if (!this.toggleEmoji.nativeElement.contains(event.target) && (eventNodeName === 'DIV' || eventNodeName === 'TEXTAREA')) {
      this._isEmojiPickerVisible = false;
    }

    if (!this.toggleEmojiFacebook.nativeElement.contains(event.target) && (eventNodeName === 'DIV' || eventNodeName === 'TEXTAREA')) {
      this._isFacebookEmojiPickerVisible = false;
    }

    if (!this.toggleEmojiTwitter.nativeElement.contains(event.target) && (eventNodeName === 'DIV' || eventNodeName === 'TEXTAREA')) {
      this._isTwitterEmojiPickerVisible = false;
    }

    if (!this.toggleEmojiInstagram.nativeElement.contains(event.target) && (eventNodeName === 'DIV' || eventNodeName === 'TEXTAREA')) {
      this._isInstagramEmojiPickerVisible = false;
    }

    if (!this.toggleEmojiLinkedIn.nativeElement.contains(event.target) && (eventNodeName === 'DIV' || eventNodeName === 'TEXTAREA')) {
      this._isLinkedinEmojiPickerVisible = false;
    }
  }

  // Append Emoji to the correct place via Switch
  addEmojiToMessage(event, networkType) {   
    var emoji = event.emoji.native;

    switch (networkType) {
      case 0:
        var currentMessage = this.postsForm.get('generalMessage').value;
        this.postsForm.get('generalMessage').setValue(`${ currentMessage }${ emoji }`);
        this._isEmojiPickerVisible = false;
        break;
      case NetworkTypeEnum['Facebook Page']:
        var currentMessage = this.postsForm.get('facebookText').value;
        this.postsForm.get('facebookText').setValue(`${ currentMessage }${ emoji }`);
        this._isFacebookEmojiPickerVisible = false;
        break;
      case NetworkTypeEnum.Twitter:
        var currentMessage = this.postsForm.get('twitterText').value;
        this.postsForm.get('twitterText').setValue(`${ currentMessage }${ emoji }`);
        this._isTwitterEmojiPickerVisible = false;
        break;
      case NetworkTypeEnum.Instagram:
        var currentMessage = this.postsForm.get('instagramText').value;
        this.postsForm.get('instagramText').setValue(`${ currentMessage }${ emoji }`);
        this._isInstagramEmojiPickerVisible = false;
        break;
      case NetworkTypeEnum.LinkedIn:
        var currentMessage = this.postsForm.get('linkedInText').value;
        this.postsForm.get('linkedInText').setValue(`${ currentMessage }${ emoji }`);
        this._isLinkedinEmojiPickerVisible = false;
        break;
    }
  }

  // Clear individual messages and images based on the network via switch
  clearPosts(networkType) {
    switch (networkType) {
      case 0:
        this.postsForm.get('generalMessage').setValue('');
        this.postsForm.get('generalFiles').reset();
        this.generalFiles = [];
        break;
      case NetworkTypeEnum['Facebook Page']:
        this.postsForm.get('facebookText').setValue('');
        this.postsForm.get('facebookDateTime').setValidators(null);
        this.postsForm.get('facebookDateTime').reset();
        this.postsForm.get('facebookFiles').reset();
        this._facebookFiles = [];
        break;
      case NetworkTypeEnum.Twitter:
        this.postsForm.get('twitterText').setValue('');
        this.postsForm.get('twitterDateTime').setValidators(null);
        this.postsForm.get('twitterDateTime').reset();
        this.postsForm.get('twitterFiles').reset();
        this._twitterFiles = [];
        break;
      case NetworkTypeEnum.Instagram:
        this.postsForm.get('instagramText').setValue('');
        this.postsForm.get('instagramFirstComment').setValue('');
        this.postsForm.get('instagramLocation').setValue('');
        this.postsForm.get('instagramDateTime').setValidators(null);
        this.postsForm.get('instagramDateTime').reset();
        this.postsForm.get('instagramFiles').reset();
        this._instagramFiles = [];
        break;
      case NetworkTypeEnum.LinkedIn:
        this.postsForm.get('linkedInText').setValue('');
        this.postsForm.get('linkedInDateTime').setValidators(null);
        this.postsForm.get('linkedInDateTime').reset();
        this.postsForm.get('linkedInFiles').reset();
        this._linkedInFiles = [];
        break;
    }
  }

  getPostsModel() {
    this.postsService.getPostsModel().subscribe((data) => {
      this._postsModel = this.postsModelAdapter.adapt(data.data);
    });
  }

  postNetworkType() {
    var networkType: Array<number> = [];

    this._selectedNetworks.forEach(network => {
      if (network == NetworkTypeEnum[NetworkTypeEnum['Facebook Page']]) {
        networkType.push(NetworkTypeEnum['Facebook Page']);
      } else if (network == NetworkTypeEnum[NetworkTypeEnum.Twitter]) {
        networkType.push(NetworkTypeEnum.Twitter);
      } else if (network == NetworkTypeEnum[NetworkTypeEnum.Instagram]) {
        networkType.push(NetworkTypeEnum.Instagram);
      } else if (network == NetworkTypeEnum[NetworkTypeEnum.LinkedIn]) {
        networkType.push(NetworkTypeEnum.LinkedIn);
      }
    });

    return networkType;
  }

  createPost(): void {
    this.creatingEditingPost = true;
    this._postsModel.Posts = [];
    this._postsModel.SelectedNetworkGroups = this.postsForm.value.networkGroups;
    this._postsModel.SelectedNetworks = this.postsForm.value.networks;

    var networkType: number[] = [];
    var networkType = this.postNetworkType();

    const multiDateTime = new Date(this.postsForm.value.multiDateTime).toUTCString();
    const facebookDateTime = new Date(this.postsForm.value.facebookDateTime).toUTCString();
    const twitterDateTime = new Date(this.postsForm.value.twitterDateTime).toUTCString();
    const instagramDateTime = new Date(this.postsForm.value.instagramDateTime).toUTCString();
    const linkedInDateTime = new Date(this.postsForm.value.linkedInDateTime).toUTCString();

    // Build Each Post depending on the networks selected
    networkType.forEach(network => {
      switch (network) {
        case (NetworkTypeEnum['Facebook Page']):
          var buildFacebookPost = {
            PostID: 0,
            NetworkType: network,
            ...!this._scheduleSeparately ?
              {PostDateTime: multiDateTime} :
              {PostDateTime: facebookDateTime},
            PostText: this.postsForm.value.customiseForEachNetworkSelected ?
              this.postsForm.value.facebookText : this.postsForm.value.generalMessage,
            Attachments: this.postsForm.value.customiseForEachNetworkSelected ?
              (this._facebookFiles.length != 0 ? this._facebookFiles : []) :
              (this.generalFiles.length != 0 ? this.generalFiles : []),
            PostAttachments: '',
            AlbumName: this.postsForm.value.albumName,
            CheckRelatedPosts: false,
            Status: this.postsForm.value.dualPostStatus
          };
          var facebookPost = this.postModelAdapter.adapt(buildFacebookPost);
          this._postsModel.Posts.push(facebookPost);
          break;
        case (NetworkTypeEnum.Twitter):
          var buildTwitterPost = {
            PostID: 0,
            NetworkType: network,
            ...!this._scheduleSeparately ?
              {PostDateTime: multiDateTime} :
              {PostDateTime: twitterDateTime },
            PostText: this.postsForm.value.customiseForEachNetworkSelected ?
              this.postsForm.value.twitterText : this.postsForm.value.generalMessage,
            Attachments: this.postsForm.value.customiseForEachNetworkSelected ?
              (this._twitterFiles.length != 0 ? this._twitterFiles : []) :
              (this.generalFiles.length != 0 ? this.generalFiles : []),
            PostAttachments: '',
            CheckRelatedPosts: false,
            Status: this.postsForm.value.dualPostStatus
          };
          var twitterPost = this.postModelAdapter.adapt(buildTwitterPost);
          this._postsModel.Posts.push(twitterPost);
          break;
        case (NetworkTypeEnum.Instagram):
          var buildInstagramPost = {
            PostID: 0,
            NetworkType: network,
            ...!this._scheduleSeparately ?
              {PostDateTime: multiDateTime} :
              {PostDateTime: instagramDateTime },
            PostText: this.postsForm.value.customiseForEachNetworkSelected ?
              this.postsForm.value.instagramText : this.postsForm.value.generalMessage,
            Attachments: this.postsForm.value.customiseForEachNetworkSelected ?
              (this._instagramFiles.length != 0 ? this._instagramFiles : []) :
              (this.generalFiles.length != 0 ? this.generalFiles : []),
            PostAttachments: '',
            CheckRelatedPosts: false,
            Status: this.postsForm.value.dualPostStatus
          };
          var instagramPost = this.postModelAdapter.adapt(buildInstagramPost);
          this._postsModel.Posts.push(instagramPost);
          break;
        case (NetworkTypeEnum.LinkedIn):
          var buildLinkedInPost = {
            PostID: 0,
            NetworkType: network,
            ...!this._scheduleSeparately ?
              {PostDateTime: multiDateTime} :
              {PostDateTime: linkedInDateTime },
            PostText: this.postsForm.value.customiseForEachNetworkSelected ?
              this.postsForm.value.linkedInText : this.postsForm.value.generalMessage,
            Attachments: this.postsForm.value.customiseForEachNetworkSelected ?
              (this._linkedInFiles.length != 0 ? this._linkedInFiles : []) :
              (this.generalFiles.length != 0 ? this.generalFiles : []),
            PostAttachments: '',
            CheckRelatedPosts: false,
            Status: this.postsForm.value.dualPostStatus
          };
          var linkedInPost = this.postModelAdapter.adapt(buildLinkedInPost);
          this._postsModel.Posts.push(linkedInPost);
          break;
      }
    });

    this.postsService.addPost(this._postsModel).subscribe(result => {
      this.creatingEditingPost = false;
      this.notificationService.showSuccess(result.message);
      this.router.navigate(['posts']);
    }, error => {
      console.log(error);
      this.creatingEditingPost = false;
    });
  }

  editPost() {
    if (this.postsForm.valid) {
      this.creatingEditingPost = true;
      this.generateUpdatePostModel()
        .pipe(
          switchMap((postsModel) => {
            return this.postsService.editPost(postsModel);
          })
        ).subscribe((data) => {
        this.notificationService.showSuccess(data.message);
        this.router.navigate(['posts']);
      });
    }
  }

  createEditPost() {
    this.isEditMode ? this.editPost() : this.createPost();
  }

  populateNetworkDropdown() {
    this.networkService.get().subscribe((result: ExecutionResultDto) => {

      result.data.Networks.forEach((networks: NetworkDto) => {
        this._account = [...this._account, this.networkAdapter.adapt(networks)];
      });

      this._account.forEach((account: NetworkDto) => {
        this._networks = [...this._networks, account];
        account.subNetworks.forEach((network: NetworkDto) => {
          this._networks = [...this._networks, network];
        });
      });

      this._networks.forEach((network) => {
        if (this._userPermissions?.Social.Posts.Create) {
          this._networksDropdown = [...this._networksDropdown, {
            value: network.networkDetailId,
            label: network.name,
            type: network.type,
            icon: IconPath[network.type],
            network,
          }];
        }
      });

      this._networksDropdown.sort(function(a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      if (this.snapshotData?.networkId) {
        const currenNetworks = this.snapshotData.networkId.map((id) => this._networksDropdown.find((el) => el.value === id));
        currenNetworks.forEach((el) => this.networkSelect(el));
        this.postsForm.get('networks').setValue(this.snapshotData.networkId);
      }
    });
  }

  populateNetworkGroupsDropdown() {
    this.networkGroupService.get().subscribe((result: ExecutionResultDto) => {
      this.networkGroups = this.networkGroupAdapter.adapt(result.data);
      this.networkGroups.forEach((network: NetworkGroupDto) => {
        this._networkGroupsDropdown = [...this._networkGroupsDropdown, {
          value: network.id,
          label: network.name,
          networks: network.networks,
          icon: './assets/images/list-flat.png',
        }];
      });
    });
  }

  networkSelect(event) {
    if (event.type === NetworkTypeEnum['Facebook Page']) {
      const networkToAdd = this._selectedFbPages.indexOf(event);
      if (networkToAdd === -1) {
        this._selectedFbPages.push(event);
        this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum['Facebook Page']]);
        this.postsForm.get('facebookNetworksSelected').setValue(true);
      }
    } else if (event.type === NetworkTypeEnum.Twitter) {
      const networkToAdd = this._selectedTwPages.indexOf(event);
      if (networkToAdd === -1) {
        this._selectedTwPages.push(event);
        this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.Twitter]);
        this.postsForm.get('twitterNetworksSelected').setValue(true);
      }
    } else if (event.type === NetworkTypeEnum.Instagram) {
      const networkToAdd = this._selectedIgPages.indexOf(event);
      if (networkToAdd === -1) {
        this._selectedIgPages.push(event);
        this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.Instagram]);
        this.postsForm.get('instagramNetworksSelected').setValue(true);
      }
    } else if (event.type === NetworkTypeEnum.LinkedIn) {
      const networkToAdd = this._selectedLiPages.indexOf(event);
      if (networkToAdd === -1) {
        this._selectedLiPages.push(event);
        this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.LinkedIn]);
        this.postsForm.get('linkedinNetworksSelected').setValue(true);
      }
    }
    this.shrinkBtnActivation();
  }

  networkDeselect(event) {
    if (event.type === NetworkTypeEnum['Facebook Page']) {
      const networkToAdd = this._selectedFbPages.indexOf(event);
      if (networkToAdd !== -1) {
        this._selectedFbPages.splice(networkToAdd, 1);
        this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum['Facebook Page']]);
        if (this._selectedFbPages.length === 0) {
          this.postsForm.get('facebookNetworksSelected').setValue(false);
        }
        else if (this._selectedFbPages.find(e => e.type == NetworkTypeEnum['Facebook Page']) != undefined) {
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum['Facebook Page']]);
        }
      }
    } else if (event.type === NetworkTypeEnum.Twitter) {
      const networkToAdd = this._selectedTwPages.indexOf(event);
      if (networkToAdd !== -1) {
        this._selectedTwPages.splice(networkToAdd, 1);
        this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum.Twitter]);
        if (this._selectedTwPages.length === 0) {
          this.postsForm.get('twitterNetworksSelected').setValue(false);
        }
        else if (this._selectedTwPages.find(e => e.type == NetworkTypeEnum.Twitter) != undefined) {
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.Twitter]);
        }
      }
    } else if (event.type === NetworkTypeEnum.Instagram) {
      const networkToAdd = this._selectedIgPages.indexOf(event);
      if (networkToAdd !== -1) {
        this._selectedIgPages.splice(networkToAdd, 1);
        this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum.Instagram]);
        if (this._selectedIgPages.length === 0) {
          this.postsForm.get('instagramNetworksSelected').setValue(false);
        }
        else if (this._selectedIgPages.find(e => e.type == NetworkTypeEnum.Instagram) != undefined) {
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.Instagram]);
        }
      }
    } else if (event.type === NetworkTypeEnum.LinkedIn) {
      const networkToAdd = this._selectedLiPages.indexOf(event);
      if (networkToAdd !== -1) {
        this._selectedLiPages.splice(networkToAdd, 1);
        this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum.LinkedIn]);
        if (this._selectedLiPages.length === 0) {
          this.postsForm.get('linkedinNetworksSelected').setValue(false);
        }
        else if (this._selectedLiPages.find(e => e.type == NetworkTypeEnum.LinkedIn) != undefined) {
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.LinkedIn]);
        }
      }
    }
    this.shrinkBtnActivation();
  }

  networkGroupSelect(event) {
    event.networks.forEach(network => {
      if (network.type === NetworkTypeEnum['Facebook Page']) {
        const networkToAdd = this._selectedFbPages.indexOf(network);
        if (networkToAdd === -1) {
          this._selectedFbPages.push(network);
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum['Facebook Page']]);
          this.postsForm.get('facebookNetworksSelected').setValue(true);
        }
      } else if (network.type === NetworkTypeEnum.Twitter) {
        const networkToAdd = this._selectedTwPages.indexOf(network);
        if (networkToAdd === -1) {
          this._selectedTwPages.push(network);
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.Twitter]);
          this.postsForm.get('twitterNetworksSelected').setValue(true);
        }
      } else if (network.type === NetworkTypeEnum.Instagram) {
        const networkToAdd = this._selectedIgPages.indexOf(network);
        if (networkToAdd === -1) {
          this._selectedIgPages.push(network);
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.Instagram]);
          this.postsForm.get('instagramNetworksSelected').setValue(true);
        }
      } else if (network.type === NetworkTypeEnum.LinkedIn) {
        const networkToAdd = this._selectedLiPages.indexOf(network);
        if (networkToAdd === -1) {
          this._selectedLiPages.push(network);
          this._selectedNetworks.add(NetworkTypeEnum[NetworkTypeEnum.LinkedIn]);
          this.postsForm.get('linkedinNetworksSelected').setValue(true);
        }
      }
    });
    this.shrinkBtnActivation();
  }

  networkGroupDeselect(event) {
    event.networks.forEach(network => {
      if (network.type === NetworkTypeEnum['Facebook Page']) {
        const networkToAdd = this._selectedFbPages.indexOf(network);
        if (networkToAdd !== -1) {
          this._selectedFbPages.splice(networkToAdd, 1);
          this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum['Facebook Page']]);
          if (this._selectedFbPages.length === 0) {
            this.postsForm.get('facebookNetworksSelected').setValue(false);
          }
        }
      } else if (network.type === NetworkTypeEnum.Twitter) {
        const networkToAdd = this._selectedTwPages.indexOf(network);
        if (networkToAdd !== -1) {
          this._selectedTwPages.splice(networkToAdd, 1);
          this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum.Twitter]);
          if (this._selectedTwPages.length === 0) {
            this.postsForm.get('twitterNetworksSelected').setValue(false);
          }
        }
      } else if (network.type === NetworkTypeEnum.Instagram) {
        const networkToAdd = this._selectedIgPages.indexOf(network);
        if (networkToAdd !== -1) {
          this._selectedIgPages.splice(networkToAdd, 1);
          this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum.Instagram]);
          if (this._selectedIgPages.length === 0) {
            this.postsForm.get('instagramNetworksSelected').setValue(false);
          }
        }
      } else if (network.type === NetworkTypeEnum.LinkedIn) {
        const networkToAdd = this._selectedLiPages.indexOf(network);
        if (networkToAdd !== -1) {
          this._selectedLiPages.splice(networkToAdd, 1);
          this._selectedNetworks.delete(NetworkTypeEnum[NetworkTypeEnum.LinkedIn]);
          if (this._selectedLiPages.length === 0) {
            this.postsForm.get('linkedinNetworksSelected').setValue(false);
          }
        }
      }
    });
    this.shrinkBtnActivation();
  }

  shrinkBtnActivation() {
    if (this._selectedLiPages.length > 0 || this._selectedIgPages.length > 0 || this._selectedTwPages.length > 0 || this._selectedFbPages.length > 0) {
      this._shrinkBtnActivation = true;
    } else {
      this._shrinkBtnActivation = false;
    }
  }

  shrinkLink() {
    if (this.postsForm.value.link === null || this.postsForm.value.link === undefined) {
      this.notificationService.showError('Link cannot be empty.');
    } else {
      // Get the Network Types Selected
      var networkType = this.postNetworkType();

      this._linkToShrink = this.shortLinkModelAdapter.adapt({
        LongUrl: this.postsForm.value.link,
        NetworkTypes: networkType,
        RemoveUTM: this.postsForm.value.removeUtm,
        CampaignName: this.postsForm.value.campaignName,
        CampaignContent: this.postsForm.value.campaignContent,
      });

      this.postsService.shrinkLink(this._linkToShrink).subscribe(result => {
        result.data.forEach(networkLink => {

          if (networkLink.Item2 !== null) {
            if (this._selectedNetworks.size == 1 && !this.postsForm.get('customiseForEachNetworkSelected').value) {
              this.postsForm.get('generalMessage').setValue(`${ this.postsForm.get('generalMessage').value } ${ networkLink.Item2 }`);
            }

            if (this._selectedNetworks.size > 1 && this.postsForm.get('customiseForEachNetworkSelected').value) {
              if (networkLink.Item1 == NetworkTypeEnum['Facebook Page']) {
                this.postsForm.get('facebookText').setValue(`${ this.postsForm.get('facebookText').value } ${ networkLink.Item2 }`);
              } else if (networkLink.Item1 == NetworkTypeEnum.Twitter) {
                this.postsForm.get('twitterText').setValue(`${ this.postsForm.get('twitterText').value } ${ networkLink.Item2 }`);
              } else if (networkLink.Item1 == NetworkTypeEnum.Instagram) {
                this.postsForm.get('instagramText').setValue(`${ this.postsForm.get('instagramText').value } ${ networkLink.Item2 }`);
              } else if (networkLink.Item1 == NetworkTypeEnum.LinkedIn) {
                this.postsForm.get('linkedInText').setValue(`${ this.postsForm.get('linkedInText').value } ${ networkLink.Item2 }`);
              }
            }
          }

          this.postsForm.get('link').setValue('');
        });
      }, error => {
        console.log(error);
      });
    }
  }

  customImagePaginateButtons() {
    var customLinkImages: Array<string> = [];
    this._customLinkImageStartIndex = (this._buttonPage * this._buttonPageSize);
    var arrayLength = this._customLinkImages.length;
    this._customLinkImageStopIndex = (this._customLinkImageStartIndex + this._buttonPageSize);
    this._customLinkImageStopIndex = (this._customLinkImageStopIndex > arrayLength) ? arrayLength : this._customLinkImageStopIndex;

    for (let index = this._customLinkImageStartIndex; index < this._customLinkImageStopIndex; index++) {
      customLinkImages.push(this._customLinkImages[index].FileUrl);
    }
    this._customLinkImagesPaginated = customLinkImages;
  }

  nextPageButtonClick() {
    this._buttonPage++;
    this.customImagePaginateButtons();
  }

  previousPageButtonClick() {
    this._buttonPage--;
    this.customImagePaginateButtons();
  }

  pickerIsOpen(isOpen: boolean) {
    this._isPickerOpen = isOpen;
  }

  // ------ Media Server integration
  openMediaServerModal(networkType) {
    this._mediaServerOpenedByNetwork = networkType;
    this._showMediaServer = true;
    this.mediaServerModal.show();
  }

  applyImagesToPost() {
    if (this._fromCustomLink) {
      this._fromCustomLink = false;
      var firstFile = null;

      this.mediaServerService.selectedFiles.forEach((file: S3ObjectsModel, index: number) => {
        var extension = file.Key.substring(file.Key.lastIndexOf('.') + 1);
        switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            if (firstFile === null) {
              firstFile = file;
            }

            this._customLinkImages.push({FileUrl: this.mediaServerService.getFilePublicUrl(file), IsMediaServer: true});
            break;
          default:
            this.notificationService.showWarning('Not able to add file ' + file.Key.substring(file.Key.lastIndexOf('/') + 1, file.Key.length) + ' as custom link images can only be of type png, jpg and jpeg');
            break;
        }
      });

      if (firstFile !== null) {
        this._currentCustomLinkImage = this.mediaServerService.getFilePublicUrl(firstFile);
      }
      this._useCustomLinkImage = true;
      this.customImagePaginateButtons();

    } else {
      if (this._mediaServerOpenedByNetwork === NetworkTypeEnum['Facebook Page']) {
        this._facebookFiles = [];
      } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.Twitter) {
        this._twitterFiles = [];
      } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.Instagram) {
        this._instagramFiles = [];
      } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.LinkedIn) {
        this._linkedInFiles = [];
      } else {
        this.generalFiles = [];
      }

      this.mediaServerService.selectedFiles.forEach((file: S3ObjectsModel, index: number) => {
        const postAttachement: PostAttachmentModel = new PostAttachmentModel({
          PostID: 0,
          PostAttachmentID: 0,
          OrderID: index,
          ContentType: '',
          FileName: file.Key,
          FileUrl: this.mediaServerService.getFilePublicUrl(file),
          VideoThumbnailFileUrl: file.VideoThumbnailUrl
        });
        if (this._mediaServerOpenedByNetwork === NetworkTypeEnum['Facebook Page']) {
          this._facebookFiles.push(postAttachement);
        } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.Twitter) {
          this._twitterFiles.push(postAttachement);
        } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.Instagram) {
          this._instagramFiles.push(postAttachement);
        } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.LinkedIn) {
          this._linkedInFiles.push(postAttachement);
        } else {
          this.generalFiles.push(postAttachement);
        }
      });

      if (this._mediaServerOpenedByNetwork === NetworkTypeEnum['Facebook Page']) {
        this.postsForm.get('facebookFiles').setValue(this._facebookFiles);
      } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.Twitter) {
        this.postsForm.get('twitterFiles').setValue(this._twitterFiles);
      } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.Instagram) {
        this.postsForm.get('instagramFiles').setValue(this._instagramFiles);
      } else if (this._mediaServerOpenedByNetwork === NetworkTypeEnum.LinkedIn) {
        this.postsForm.get('linkedInFiles').setValue(this._linkedInFiles);
      } else {
        this.postsForm.get('generalFiles').setValue(this.generalFiles);
      }
    }

    this._showMediaServer = false;
    this._mediaServerOpenedByNetwork = null;
    this.mediaServerModal.hide();
  }

  closeMediaServer() {
    if (this._fromCustomLink) {
      this._fromCustomLink = false;
    }
    this._showMediaServer = false;
    this._mediaServerOpenedByNetwork = null;
    this.mediaServerService.selectedFiles = [];
    this.mediaServerModal.hide();
  }

  networkTypeEnum() {
    return NetworkTypeEnum;
  }

  deleteFile(file: PostAttachmentModel, networkType) {
    if (networkType === NetworkTypeEnum['Facebook Page']) {
      this._facebookFiles = this._facebookFiles.filter((filteredFile: PostAttachmentModel) => filteredFile.FileUrl !== file.FileUrl);
      this.postsForm.get('facebookFiles').setValue(this._facebookFiles);
    } else if (networkType === NetworkTypeEnum.Twitter) {
      this._twitterFiles = this._twitterFiles.filter((filteredFile: PostAttachmentModel) => filteredFile.FileUrl !== file.FileUrl);
      this.postsForm.get('twitterFiles').setValue(this._twitterFiles);
    } else if (networkType === NetworkTypeEnum.Instagram) {
      this._instagramFiles = this._instagramFiles.filter((filteredFile: PostAttachmentModel) => filteredFile.FileUrl !== file.FileUrl);
      this.postsForm.get('instagramFiles').setValue(this._instagramFiles);
    } else if (networkType === NetworkTypeEnum.LinkedIn) {
      this._linkedInFiles = this._linkedInFiles.filter((filteredFile: PostAttachmentModel) => filteredFile.FileUrl !== file.FileUrl);
      this.postsForm.get('linkedInFiles').setValue(this._linkedInFiles);
    } else {
      this.generalFiles = this.generalFiles.filter((filteredFile: PostAttachmentModel) => filteredFile.FileUrl !== file.FileUrl);
      this.postsForm.get('generalFiles').setValue(this.generalFiles);
    }
  }

  backToPosts() {
    this.router.navigate(['/posts']);
  }

  // Display Network Post based on whats selected
  displayNetworkSection(networkType: NetworkTypeEnum) {
    switch (networkType) {
      case NetworkTypeEnum['Facebook Page']:
        return this._isCustomiseEachNetworkSelected && this._selectedNetworks.has(NetworkTypeEnum[NetworkTypeEnum['Facebook Page']]);
      case NetworkTypeEnum.Twitter:
        return this._isCustomiseEachNetworkSelected && this._selectedNetworks.has(NetworkTypeEnum[NetworkTypeEnum.Twitter]);
      case NetworkTypeEnum.Instagram:
        return this._isCustomiseEachNetworkSelected && this._selectedNetworks.has(NetworkTypeEnum[NetworkTypeEnum.Instagram]);
      case NetworkTypeEnum.LinkedIn:
        return this._isCustomiseEachNetworkSelected && this._selectedNetworks.has(NetworkTypeEnum[NetworkTypeEnum.LinkedIn]);
    }
  }

  generateUpdatePostModel(): Observable<PostsModel> {
    return new Observable<PostsModel>((observer) => {
      let Posts: PostsModel;
      let Post: PostModel;

      this.postsService.getPostsModel().subscribe((model: ExecutionResultDto) => {
        Posts = model.data;
        Posts.SelectedNetworks = this.snapshotData.networkId || [];
        Posts.SelectedNetworkGroups = this.snapshotData.networkGroupId || [];
        let newCustomLink = false;
        if (this.postsForm.get('link').value !== '' && this.postsForm.get('link').value !== null) {
          if (this.snapshotData.postLinkId === null) {
            this.snapshotData.postLink = this.linkModelAdapter.createEmpty();
            newCustomLink = true;
          }

          this.snapshotData.postLink.URL = this.postsForm.get('link').value;
          this.snapshotData.postLink.RemoveUTM = this.postsForm.get('removeUtm').value;
          this.snapshotData.postLink.UtmCampaign = this.postsForm.get('campaignName').value;
          this.snapshotData.postLink.UtmContent = this.postsForm.get('campaignContent').value;
          if (this._useCustomLinkImage) {
            if (!this.snapshotData.postLink.LinkImage) {
              this.snapshotData.postLink.LinkImage = this.linkImageModelAdapter.createEmpty();
            }

            if (this.snapshotData.postLink.LinkImage.FileUrl !== this._currentCustomLinkImage) {
              this.snapshotData.postLink.LinkImage.FileUrl = this._currentCustomLinkImage;
              const image = this._customLinkImages.filter((imageLink) => imageLink.FileUrl === this._currentCustomLinkImage)[0];
              this.snapshotData.postLink.LinkImage.MediaServer = image.IsMediaServer;
              this.snapshotData.postLink.LinkImage.FileName = this.mediaServerService.getFileName(this.snapshotData.postLink.LinkImage.FileUrl);
            } else {
              this.snapshotData.postLink.LinkImage.MediaServer = true;
            }
          } else {
            this.snapshotData.postLink.LinkImage = null;
          }
        }

        Post = this.postModelAdapter.adapt({
          PostID: this.snapshotData.postId,
          NetworkType: this.snapshotData.networkType,
          PostDateTime: new Date(this.postsForm.get('multiDateTime').value).toUTCString(),
          PostText: this.postsForm.get('generalMessage').value,
          CustomLink: this.snapshotData.postLink,
          CheckRelatedPosts: this.snapshotData.CheckRelatedPost,
          AlbumName: this.postsForm.get('albumName').value,
          Attachments: this.generalFiles,
          PostAttachments: '',
          Status: this.postsForm.get('dualPostStatus').value
        });

        if (newCustomLink) {
          this.postsService.addCustomLink(Post.CustomLink).subscribe((result: ExecutionResultDto) => {
            const customLink = result.data;
            customLink.LinkSaved = true;
            Post.CustomLink = customLink;
            Posts.Posts.push(Post);
            observer.next(Posts);
            observer.complete();
          });
        } else {
          Posts.Posts.push(Post);
          observer.next(Posts);
          observer.complete();
        }
      });
    });
  }
}
