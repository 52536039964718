import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { UserPermissionAdapter, UserPermissionDto } from '../../../_models/admin/users/user-permission-model';
import {
  PermissionsAllAvailableDto,
  PermissionsAllAvailableAdapter
} from '../../../_models/admin/users/permissions-all-available-model';
import { environment } from '../../../../environments/environment';
import { ExecutionResultDto, ExecutionResultAdapter } from '../../../_models/execution-result-model';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  baseUrl = environment.apiURL;
  userPermissions = new Subject<PermissionsAllAvailableDto>();

  constructor(
    private http: HttpClient,
    private userPermissionAdapter: UserPermissionAdapter,
    private permissionsAllAvailableAdapter: PermissionsAllAvailableAdapter,
    private executionResultAdapter: ExecutionResultAdapter
  ) { }

  getAllAvailable(): Observable<PermissionsAllAvailableDto> {
    return this.http
      .get(this.baseUrl + 'UserPermission')
      .pipe(map((data: any) => this.permissionsAllAvailableAdapter.adapt(JSON.parse(data))));
  }

  getEmptyPermissionsModel(): Observable<PermissionsAllAvailableDto> {
    return this.http
      .get(this.baseUrl + 'UserPermission/EmptyPermissionModel')
      .pipe(map((data: any) => this.permissionsAllAvailableAdapter.adapt(JSON.parse(data))));
  }

  post(userPermissionDto: UserPermissionDto): Observable<ExecutionResultDto> {
    return this.http.post(this.baseUrl + 'UserPermission', userPermissionDto)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  delete(id: number): Observable<unknown> {
    return this.http
      .delete(this.baseUrl + `UserPermission/${ id }`);
  }

  get(userId: number): Observable<UserPermissionDto[]> {
    return this.http
      .get(this.baseUrl + `UserPermission/${ userId }`)
      .pipe(map((data: any) => data.map((item: any) => this.userPermissionAdapter.adapt(item))));
  }
}
