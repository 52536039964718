import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ListsService } from '../../../../_services/messaging/lists-and-contacts/lists/lists.service';
import { NotificationService } from '../../../../_services/notification.service';
import { LoadingSpinnerService } from '../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-clear-list',
  templateUrl: './clear-list-modal.component.html',
})
export class ClearListModalComponent implements OnInit {
  title: string;
  name: string;

  constructor(
    private listsService: ListsService,
    private notificationService: NotificationService,
    private loadingSpinnerService: LoadingSpinnerService,
    public dialogRef: MatDialogRef<ClearListModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    this.title = 'Clear List';
    this.name = this.data.ListName;
  }

  clearList(): void {
    this.loadingSpinnerService.loading();
    this.listsService.clearList(this.data.ListID).subscribe(result => {
      this.notificationService.showSuccess(result.message);
      this.loadingSpinnerService.stopLoading();
      this.dialogRef.close();
    }, error => {
      this.loadingSpinnerService.stopLoading();
      this.notificationService.showError(error);
    });
  }
}
