import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { NotificationService } from '../../notification.service';
import { ExecutionResultAdapter } from 'src/app/_models/execution-result-model';
import { LocalStorageService } from '../localStorage/localStorage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GlobalHttpInterceptorService implements HttpInterceptor {

  _clientId: number = 0;

  // both forms of login page that need ignoring when displaying http errors
  _routesToIgnoreErrors: string[] = ['/', '/login'];

  constructor(
    private notificationService: NotificationService,
    private executionResultAdapter: ExecutionResultAdapter,
    private localStorageService: LocalStorageService,
    private router: Router) {
    localStorageService.CurrentClientId.subscribe((ClientId: number) => {
      this._clientId = +ClientId;
    });
  }

  intercept(request: HttpRequest<any>, httpHandler: HttpHandler):
    Observable<HttpEvent<any>> {

    if (this.localStorageService.getToken && this.localStorageService.getUsername && this.localStorageService.getCurrentClientId && request.url.startsWith(environment.apiURL)) {
      request = request.clone({
        setHeaders: {
          Token: this.localStorageService.getToken,
          Username: this.localStorageService.getUsername,
          ClientId: this.localStorageService.getCurrentClientId
        }
      });
    }

    if (request.url.startsWith(environment.vehicleValidationAPIUrl)) {
      request = request.clone({
        setHeaders: {
          "api-key": environment.vehicleValidationAPIKey
          // "Access-Control-Allow-Origin": '*'
        }
      });
    }

    // hide httperrors on login page as errors will display when a session expires etc.
    // and we do not want the clients thinking that an error is occuring on the app when it isn't
    return httpHandler.handle(request).pipe(
      catchError((error) => {
        console.log("Interceptor error", error);
        if (error instanceof HttpErrorResponse) {
          if (error.error) {
            const executionResult = this.executionResultAdapter.adapt(error.error);
            this.notificationService.showError(executionResult.message);
          } else {
            if (this._routesToIgnoreErrors.indexOf(this.router.url) === -1) {
              this.notificationService.showError(error.message);
            }
          }
        }
        return throwError(error);
      })
    );
  }
}
