import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectListOption } from '../../../_models/system/select-list-option';

@Injectable({
  providedIn: 'root'
})
export class MessagingHeaderService {
  public selectFolder$: Subject<number> = new Subject<number>();
  folders: SelectListOption[];
  lists: SelectListOption[];

  setFolder(id): void {
    this.selectFolder$.next(id);
  }

  selectingFolder(): Observable<number> {
    return this.selectFolder$.asObservable();
  }
}
