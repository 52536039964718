export enum PermissionsPageTypesEnum {
  View = 1,
  Edit = 2,
  Create = 4,
  Delete = 8,
  Send = 16,
  Draft = 32,
  Pause = 64,
  Approve = 128,
  TopLevelAccountAccess = 256
}
