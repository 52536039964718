export enum PostsStatuesEnum {
        Scheduled = 0,
        InProgress = 1,
        Error = 2,
        NoActiveNetworks = 3,
        Success = 4,
        Draft = 5,
        Paused = 6,
}


export namespace PostsStatuesEnum {
  export function values() {
    return Object.keys(PostsStatuesEnum).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
  export function GetStatusLabelValues() {
    var statues: Array<any> = [];
    Object.keys(PostsStatuesEnum).forEach(element => {
      if (isNaN(<any>element) && element !== 'GetStatusLabelValues' && element !== 'values')
      statues.push({ label: element, value: PostsStatuesEnum[element]})
    });
    return statues;
}
}
