export class LightweightMessageModel {

  public MessageID: number;
  public FolderID: number;
  public MessageName: string;

  constructor(init: any) {
    this.MessageID = init.MessageID;
    this.FolderID = init.FolderID;
    this.MessageName = init.MessageName;
  }
}
